import { Base } from '@app/shared/model';
import { Course } from '.';
import { Unity } from '@app/shared/enum/Unity';

export class Product extends Base {
    name: string;
    unity: Unity;
    value: number;
    monthlyAmount: number;
    courses: Array<Course>;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.name = contentResponse.name;
        this.unity = parseInt(contentResponse.unity);
        this.value = parseFloat(contentResponse.value);
        this.monthlyAmount = parseFloat(contentResponse.monthlyAmount);

        if (contentResponse.courses && contentResponse.courses.lenght > 0) {
            this.courses = contentResponse.courses;
        }
    }
}
