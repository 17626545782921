export enum AuditEventType {
    Login = 1,
    FailedLogin = 2,
    LogOut = 3,
    NotAllowed = 4,
    Error = 5
}

export const AuditEventTypeLabel = new Map<number, string>([
    [AuditEventType.Login, 'Login'],
    [AuditEventType.FailedLogin, 'Falha de Login'],
    [AuditEventType.LogOut, 'Logout'],
    [AuditEventType.NotAllowed, 'Não Autorizado'],
    [AuditEventType.Error, 'Erro']
]);

export const AuditEventTypeCss = new Map<number, string>([
    [AuditEventType.Login, 'audit-event-login'],
    [AuditEventType.FailedLogin, 'audit-event-failed-login'],
    [AuditEventType.LogOut, 'audit-event-logout'],
    [AuditEventType.NotAllowed, 'audit-event-not-allowed'],
    [AuditEventType.Error, 'audit-event-error']
]);

