import { Base } from '@app/shared/model';

export class User extends Base {
    firstName: string;    
    lastName: string;
    lockoutEnd: string;
    twoFactorEnabled: boolean;
    phoneNumberConfirmed: boolean;
    phoneNumber: string;
    concurrencystamp: string;
    securityStamp: string;
    passwordHash: string;
    emailConfirmed: boolean;
    normalizedEmail: string;
    email: string;
    normalizedUserName: string;
    userName: string;
    lockoutEnabled: boolean;
    accessfailedcount: number;
    passwordSalt: string;
    userGuid: string;
}
