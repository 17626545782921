export enum ClassAction {
    Confirmed = 0,
    Canceled = 1,
    Registration = 2,
    StatusReset = 3,
    ConfirmWithParams = 4,
    ChangeTeacher = 5,
    ReEnrollment = 6,
    ChangeTeacherRecurrence = 7,
    Reschedule = 8,
    Delayed = 9,
    Episode = 10
}

export const ClassActionLabel = new Map<number, string>([
    [ClassAction.Confirmed, 'Confirmada'],
    [ClassAction.Canceled, 'Cancelada'],
    [ClassAction.Registration, 'Matrícula'],
    [ClassAction.StatusReset, 'Restauração de Status'],
    [ClassAction.ConfirmWithParams, 'Confirmação com Justificativa'],
    [ClassAction.ChangeTeacher, 'Troca de Professor(a)'],
    [ClassAction.ReEnrollment, 'Rematrícula'],
    [ClassAction.ChangeTeacherRecurrence, 'Troca de Professor(a) na recorrência'],
    [ClassAction.Reschedule, 'Reagendamento'],
    [ClassAction.Delayed, 'Atrasada'],
    [ClassAction.Episode, 'Episódio']
]);

export const ClassActionCss = new Map<number, string>([
    [ClassAction.Confirmed, 'class-action-confirmed'],
    [ClassAction.Canceled, 'class-action-canceled'],
    [ClassAction.Registration, 'class-action-registration'],
    [ClassAction.StatusReset, 'class-action-status-reset'],
    [ClassAction.ConfirmWithParams, 'class-action-confirm-with-params'],
    [ClassAction.ChangeTeacher, 'class-action-change-teacher'],
    [ClassAction.ReEnrollment, 'class-action-re-enrollment'],
    [ClassAction.ChangeTeacherRecurrence, 'class-action-change-teacher-recurrence'],
    [ClassAction.Reschedule, 'class-action-reschedule'],
    [ClassAction.Delayed, 'class-action-delayed'],
    [ClassAction.Episode, 'class-action-episode']
]);
