import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class InstallmentsService extends BaseService {
  constructor(private http2: HttpClient) {
      super(http2, "financial", "installments");
  }
}
