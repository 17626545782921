<div class="modal-header">
    <h3 class="modal-title">Aplicação do teste de escrita</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
</div>
<div class="modal-body">
    <div class="row mb-3">
        <div class="col-12">
            <label class="form-label" for="coursesDataValue">Turma<span class="text-danger">*</span> </label>
            <br>
            <select class="form-control" id="coursesDataValue" placeholder="Turma" [(ngModel)]="coursesDataValue"
                [ngClass]="{ 'is-invalid': !validCourse }" (change)="setCourse($event.target.value);">
                <option *ngFor="let course of coursesDataList" [value]="course.id" [selected]="coursesDataValue == course.id">
                    {{course.id + " | " + course.name }}
                </option>
            </select>
        </div>
    </div>
    <div class="row mb-3" *ngIf="validCourse">
        <div class="col-12">
            <label class="form-label" for="studentCourseDataValue">Alunos<span class="text-danger">*</span> </label>
            <br>
            <select class="form-control" id="studentCourseDataValue" placeholder="Aluno(a)" [(ngModel)]="studentCourseDataValue"
                [ngClass]="{ 'is-invalid': !validStudentCourse }" (change)="setStudentCourse($event.target.value);">
                <option *ngFor="let studentCourse of studentCourseDataList" [value]="studentCourse.id" [selected]="studentCourseDataValue == studentCourse.id">
                    {{studentCourse.id + " | " + studentCourse.student.name }}
                </option>
            </select>
        </div>
    </div>
    <div class="row mb-3" *ngIf="validStudentCourse">
        <div class="col-12">
            <label class="form-label" for="studentClassesCourseDataValue">Aulas<span class="text-danger">*</span> </label>
            <br>
            <select class="form-control" id="studentClassesCourseDataValue" placeholder="Aula" [(ngModel)]="studentClassesCourseDataValue"
                [ngClass]="{ 'is-invalid': !validStudentClassesCourse }" (change)="setStudentClassesCourse($event.target.value);">
                <option *ngFor="let studentClassesCourse of studentClassesCourseItemsArray" [value]="studentClassesCourse.id" [selected]="studentClassesCourseDataValue == studentClassesCourse.id">
                    {{studentClassesCourse.text}}
                </option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validSave">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
                {{errorMessage}}
            </div>
            <div *ngIf="listErrorMessage && !validSave" class="invalid-feedback">
                <div *ngFor="let error of listErrorMessage">{{error}}</div>
            </div>
            <hr>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="cancel()">
        <i class="fa fa-times-circle mr-1"></i> Cancelar
    </button>
    <button type="button" class="btn btn-sm btn-success waves-effect waves-themed" (click)="done()">
        <i class="fa fa-check mr-1"></i> Confirmar
    </button>
</div>
