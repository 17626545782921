import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import {
  InitAdminService,
  StudentCourseService,
  TermOfUseService,
} from '@app/admin/services';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';
import { default as swal } from 'sweetalert2';
import { PaymentStatusCss, PaymentStatusLabel } from '@app/shared/enum';
import { v4 as uuidv4 } from 'uuid';
import { Course, TermOfUse } from '@app/admin/model';
import { UtilService } from '@app/shared/services';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'studentcourse-list',
  templateUrl: './studentcourse-list.component.html',
  providers: [DatePipe],
})
export class StudentCourseListComponent implements OnInit, AfterViewInit {
  @ViewChild('mdCourseselect') mdCourseselect: any;
  closeResult: string;

  public studentCourseEditMode: boolean = false;
  public studentCourseIdentifier: string;
  public studentCourseId: number = 0;
  public courseId: number = 0;
  public course: Course;
  public studentCourseList: any = [];
  public studentCourseList$ = new BehaviorSubject([]);
  public showTable: boolean;
  public returnMessage = '';
  public errorMessageIncluded = '';
  public dateNow = new Date();
  dataResult: boolean = false;
  urlReloadList: string;
  returnUrl: string;
  insertUrl: string;
  studentCourseTarget: any = null;

  public submittedRegExt: boolean = false;

  public newEndDate: Date = new Date();

  iconInstallments = 'fas fa-indent';
  titleInstallments = 'Pagamentos';

  iconClasses = 'fas fa-calendar-check';
  titleClasses = 'Aulas';

  iconRelocation = 'fas fa-exchange';
  titleRelocation = 'Remanejamento de Turma';

  iconRegistrationExtension = 'fas fa-calendar-edit text-white';
  titleRegistrationExtension = 'Prorrogação de matrícula';

  public columns: Array<any> = [
    { title: 'Aluno', name: 'studentName', sort: 'asc' },
    { title: 'Curso', name: 'courseName', sort: 'asc' },
    {
      title: 'Início',
      name: 'startDate',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
    {
      title: 'Fim',
      name: 'endDate',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
    {
      title: 'Total de<br>Aulas',
      name: 'totalClasses',
      sort: 'asc',
      className: 'text-center text-info',
    },
    {
      title: 'Aulas<br>Realizadas',
      name: 'confirmedClasses',
      sort: 'asc',
      className: 'text-center text-info',
    },
    {
      title: 'Aulas<br>Restante',
      name: 'remainingClasses',
      sort: 'asc',
      className: 'text-center text-info',
    },
    {
      title: 'Status<br>Financeiro',
      name: 'designPaymentStatus',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-primary',
    },
    // {
    //   title: "Termo<br>gerado?",
    //   name: "termOfUseGenerated",
    //   sort: "asc",
    //   className: "text-center text-primary"
    // },
    // {
    //   title: "Termo<br>enviado?",
    //   name: "termOfUseSent",
    //   sort: "asc",
    //   className: "text-center text-primary"
    // }
  ];

  constructor(
    private initAdmin: InitAdminService,
    private modalService: NgbModal,
    private studentCourseService: StudentCourseService,
    private termOfUseService: TermOfUseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loading: MessageService
  ) {}

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/courselist';
    this.insertUrl = '/admin/studentCourse';
    this.urlReloadList = '/admin/studentCourselist';

    this.activatedRoute.params.subscribe((params) => {
      this.courseId = params['courseId'];
      if (this.courseId > 0) {
        this.loadStudentsCourse(this.courseId);
      } else {
        this.loading.showLoading();
        this.router.navigate([this.returnUrl]);
      }
    });
    this.loading.hideLoading();
    let myuuid = uuidv4();
    console.info('Your UUID is: ' + myuuid);
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  ngAfterViewInit() {
    if (this.courseId > 0) return;

    this.open(this.mdCourseselect);
  }

  loadStudentsCourse(courseId: any) {
    this.loading.showLoading();
    this.studentCourseService.getResultGridByCourseId(courseId).subscribe(
      (response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.studentCourseList = response.data;
          this.designPaymentStatus(this.studentCourseList);
          this.studentCourseList$.next(this.studentCourseList);
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.loading.hideLoading();
      },
      (error: any) => {
        this.loading.hideLoading();
        this.showTable = false;
        this.returnMessage = 'Erro ao carregar os dados dos bancos.';
      }
    );
  }

  designPaymentStatus(lista: any[]) {
    lista.forEach(function (e) {
      if (e.paymentStatus >= 0) {
        let paymentStatusCss = PaymentStatusCss.get(e.paymentStatus);
        let paymentStatusStr = PaymentStatusLabel.get(e.paymentStatus);
        e.designPaymentStatus =
          "<span class='badge badge-pill w-100 font-weight-bold " +
          paymentStatusCss +
          "'>" +
          paymentStatusStr +
          '</span>';
      }
    });
    this.studentCourseList = lista;
  }

  newStudent(content: any) {
    this.loading.showLoading();
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();

      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  viewInstallmentsStudentCourse(row: any, content: any) {
    this.studentCourseId = row.id;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  viewClassesStudentCourse(row: any, content: any) {
    this.router.navigate([
      '/admin/studentClassesCourseList',
      { id: row.identifier },
    ]);
    // this.studentCourseIdentifier = row.identifier;
    // this.modalService.dismissAll();
    // this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade'}).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  showInfoStudentCourse(row: any, content: any) {
    this.studentCourseIdentifier = row.identifier;
    this.studentCourseEditMode = false;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  studentCourseRelocationAdd(row: any, content: any) {
    this.studentCourseIdentifier = row.identifier;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  registrationExtension(row: any, content: any) {
    this.studentCourseIdentifier = row.identifier;
    this.studentCourseTarget = row;
    this.newEndDate = new Date(row.endDate);
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'sm',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onCancel(): void {
    this.submittedRegExt = false;
    this.dismissModal();
    this.returnUrlList();
  }

  onEndDateChange(newEndDate: any) {
    if (newEndDate) {
      this.newEndDate = new Date(newEndDate + 'GMT-0300');
    }
  }

  editStudentCourse(row: any, content: any) {
    this.studentCourseIdentifier = row.identifier;
    this.studentCourseEditMode = true;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  sendTermOfUse(target: any): void {
    if (target.termOfUseGenerated || target.termOfUseSent) {
      return this.confirmReprint(target);
    } else {
      return this.generateTermOfUse(target);
    }
  }

  generateTermOfUse(target: any): void {
    swal({
      title: 'Termo de Ativação de Matrícula',
      html:
        'Confirme abaixo como será gerado o termo de ativação<br>de matrícula do(a) aluno(a) <strong>' +
        target.studentName +
        '</strong>.<br><br>' +
        '<small>Caso prefira o envio por e-mail o usuário receberá um link<br>direcionando para o download do termo em PDF.</small>',
      type: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Enviar por e-mail',
      showCancelButton: true,
      cancelButtonColor: '#868e96',
      cancelButtonText: 'Somente gerar PDF',
      showCloseButton: true,
    }).then(
      (willSend) => {
        if (willSend.dismiss && willSend.dismiss != swal.DismissReason.cancel) {
          this.loading.hideLoading();
          return;
        } else if (
          willSend.dismiss &&
          willSend.dismiss == swal.DismissReason.cancel
        ) {
          this.loading.showLoading();
          return this.termGenerate(target.studentId, false, false);
        } else {
          this.loading.showLoading();
          return this.termGenerate(target.studentId, true, false);
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          return this.sendTermFailed('', TypeResponse.Error);
        }
      }
    );
  }

  confirmReprint(target: any) {
    swal({
      title: 'Termo de Ativação de Matrícula',
      html:
        'O termo do(a) aluno(a) <strong>' +
        target.studentName +
        '</strong> já foi gerado.<br><br>' +
        '<small>Deseja gerar novamente ou somente reimprimir?</small>',
      type: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Gerar novamente',
      showCancelButton: true,
      cancelButtonColor: '#868e96',
      cancelButtonText: 'Somente reimpressão',
      showCloseButton: true,
    }).then(
      (willSend) => {
        if (willSend.dismiss && willSend.dismiss != swal.DismissReason.cancel) {
          this.loading.hideLoading();
          return;
        } else if (
          willSend.dismiss &&
          willSend.dismiss == swal.DismissReason.cancel
        ) {
          this.loading.showLoading();
          return this.termGenerate(target.studentId, false, true);
        } else {
          this.loading.hideLoading();
          return this.generateTermOfUse(target);
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          return this.sendTermFailed('', TypeResponse.Error);
        }
      }
    );
  }

  private termGenerate(
    studentId: number,
    sendMail: boolean,
    onlyPrint: boolean
  ) {
    let termOfUse: TermOfUse = {
      studentId,
      sendByMail: sendMail,
      onlyPrint: onlyPrint,
    };
    this.termOfUseService.Generate(termOfUse).subscribe(
      (response: any) => {
        this.loading.hideLoading();
        if (
          'typeResponse' in response &&
          response['typeResponse'] === TypeResponse.Success
        ) {
          let msg = onlyPrint ? 'impresso' : 'gerado';
          swal({
            type: 'success',
            title: 'Termo de ativação de matrícula ' + msg + ' com sucesso!',
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            let base64String = response.data.file;
            let fileName =
              'Termo de ativação de matrícula ' +
              response.data.termOfUse.identifier;
            this.downloadPdf(base64String, fileName);
            this.loading.hideLoading();
            window.location.reload();
          });
        } else {
          this.sendTermFailed(response.message, response['typeResponse']);
        }
      },
      () => {
        this.sendTermFailed('', TypeResponse.Error);
      }
    );
  }

  private returnUrlList() {
    this.router.navigate([this.urlReloadList, { courseId: this.courseId }]);
  }

  private sendTermFailed(message: string, type: TypeResponse) {
    if (type === TypeResponse.Alert) {
      swal({
        type: 'warning',
        title: 'Termo não foi gerado!',
        html: message,
        showConfirmButton: true,
      }).then(() => {
        this.loading.hideLoading();
      });
    } else {
      swal({
        type: 'error',
        title: 'Erro ao gerar o termo de ativação de matrícula!',
        html: 'Ocorreu um erro ao gerar o termo.<br>Valide as informações e tente novamente.',
        showConfirmButton: true,
      }).then(() => {
        this.loading.hideLoading();
      });
    }
  }

  downloadPdf(base64String: any, fileName: any) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }

  courseSelectModal() {
    this.modalService.dismissAll();
    this.modalService
      .open(this.mdCourseselect, {
        backdrop: 'static',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'Save') {
            console.log('result = save');
            // this.submitChangePassword();
          }
        },
        (reason) => {
          this.closeResult = `Dismissed`;
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onSaveRegistrationExtension() {
    this.loading.showLoading();
    this.submittedRegExt = true;
    let newDate = new Date(this.newEndDate);
    let oldEndDate = new Date(this.studentCourseTarget.endDate);
    if (oldEndDate >= newDate) {
      swal({
        type: 'warning',
        title: 'Não foi possível prorrogar a matrícula!',
        text: 'A nova data de término precisa ser maior que a data vigente.',
        showConfirmButton: true,
      }).then((result) => {
        this.onCancel();
        this.loadStudentsCourse(this.courseId);
        this.loading.hideLoading();
      });
      return;
    }

    newDate.setMinutes(59);
    newDate.setHours(23);
    let newDateStr = formatDate(newDate, 'yyyy-MM-dd HH:mm:ss', 'en-US');
    if (this.studentCourseTarget?.id && newDateStr) {
      this.studentCourseService
        .newEndDate(this.studentCourseTarget.id, newDateStr)
        .subscribe(
          (response: any) => {
            this.loading.hideLoading();
            if (
              'typeResponse' in response &&
              response['typeResponse'] === TypeResponse.Success
            ) {
              swal({
                type: 'success',
                title: 'Matrícula prorrogada com sucesso!',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.onCancel();
                this.loadStudentsCourse(this.courseId);
                this.loading.hideLoading();
              });
            } else if (
              'typeResponse' in response &&
              response['typeResponse'] === TypeResponse.Alert
            ) {
              swal({
                type: 'warning',
                title: 'Não foi possível prorrogar a matrícula!',
                text: response.message,
                showConfirmButton: true,
              }).then((result) => {
                this.onCancel();
                this.loadStudentsCourse(this.courseId);
                this.loading.hideLoading();
              });
            } else if (
              'typeResponse' in response &&
              response['typeResponse'] === TypeResponse.Error
            ) {
              swal({
                type: 'error',
                title: 'Erro ao tentar prorrogar a matrícula.',
                text: response.message,
                showConfirmButton: true,
              }).then((result) => {
                this.onCancel();
                this.loadStudentsCourse(this.courseId);
                this.loading.hideLoading();
              });
            }
          },
          (error) => {
            swal({
              type: 'error',
              title: 'Erro ao tentar prorrogar a matrícula.',
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.loading.hideLoading();
              window.location.reload();
            });
          }
        );
    } else {
      swal({
        type: 'warning',
        title: 'Dados inválidos',
        text: 'Não foi possível prorrogar a matrícula!',
        showConfirmButton: true,
      }).then((result) => {
        this.loading.hideLoading();
        window.location.reload();
      });
    }
  }

  delete(target: any): void {
    swal({
      title: 'Excluir Matrícula',
      html:
        'Confirma a exclusão da matrícula de <strong>' +
        target.studentName +
        '</strong>?<br>' +
        'Ao confirmar a matrícula e aulas programadas serão excluídas permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.studentCourseService.remove(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.loadStudentsCourse(this.courseId);
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir a matrícula!',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir a matrícula.',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                  this.loadStudentsCourse(this.courseId);
                });
              }
            },
            (error) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir a matrícula.',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir a matrícula.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }
}
