import { CourseService, StudentClassesCourseService, StudentCourseService, TeacherService, WritingTestApplyService, WritingTestService } from '@app/admin/services';
import { TypeResponse, StatusClass, StatusClassLabel, AvgScore, WritingTestStatus, WritingTestReview } from '@app/shared/enum';
import { Component, Input, OnInit } from '@angular/core';
import { MessageService, UtilService } from '@app/shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { Select2OptionData } from 'ng-select2';
import { default as swal } from 'sweetalert2'
import { Options } from 'select2';
import { Course, StudentClassesCourse, StudentCourse, Teacher, WritingTest } from '@app/admin/model';
import { InitTeacherService } from '@app/teacher/services';
import { first } from 'rxjs/operators';
import { DatePipe, formatDate } from '@angular/common';
import { WritingTestApply } from '@app/admin/model/writingTestApply';
import { Router } from '@angular/router';

@Component({
  selector: 'writing-test-apply',
  templateUrl: './writing-test-apply.component.html',
  styleUrls: ['./writing-test-apply.component.css']
})
export class WritingTestApplyComponent implements OnInit {
  @Input("writingTestId") writingTestId: any;
  private writingTest: WritingTest;
  private teacher: Teacher;

  submitted = false;
  returnUrl = '/teacher/writingtestlist';

  public returnMessage: string = '';
  public options: Options;
  public errorMessage: string;
  public validSave: boolean = true;
  public editMode: boolean = false;
  public listErrorMessage: any[];

  public courseId: any;
  public courseData: any;
  public coursesDataList: any = [];
  public coursesDataValue: any;
  public validCourse: boolean = true;

  public studentClassesCourseId: any;
  public studentClassesCourseData: any;
  public studentClassesCourseDataList: any = [];
  public studentClassesCourseItemsArray: any = [];
  public studentClassesCourseDataValue: any;
  public validStudentClassesCourse: boolean = true;

  public studentCourseId: any;
  public studentCourseData: any;
  public studentCourseDataList: any = [];
  public studentCourseDataValue: any;
  public validStudentCourse: boolean = true;

  constructor(
    private studentClassesCourseService: StudentClassesCourseService,
    private writingTestApplyService: WritingTestApplyService,
    private studentCourseService: StudentCourseService,
    private writingTestService: WritingTestService,
    private initTeacher: InitTeacherService,
    private courseService: CourseService,
    private UtilService: UtilService,
    private loading: MessageService,
    public datepipe: DatePipe,
    private router: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getUserData();
  }

  private getUserData() {
    this.initTeacher.getTeacherUser().pipe(first()).subscribe((response: any) => {
      this.teacher = response;
      this.getWritingTest();
      this.getCoursesByTeacher(this.teacher.id);
      this.loading.hideLoading();
    }, (error: any) => {
      this.returnMessage = 'Erro ao carregar os testes disponíveis.';
      this.loading.hideLoading();
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  getWritingTest() {
    this.loading.showLoading();
    this.writingTestService.getAll().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.writingTest = response.data;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
      }
      this.loading.hideLoading();
    }, (error: any) => {
      this.returnMessage = 'Erro ao carregar os testes disponíveis.';
      this.loading.hideLoading();
    });
  }

  getCoursesByTeacher(teacherId: any) {
    this.courseService.getByTeacherId(teacherId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.coursesDataList = response.data;
      } else {
        this.coursesDataList = new Array<Course>();
      }
    });
  }

  setCourse(value: any) {
    if (value) {
      this.courseId = parseInt(value)
      this.courseData = this.coursesDataList.find((x: any) => x.id == this.courseId);
      this.getStudentCourseByCourse(this.courseId);
    }
  }

  getStudentCourseByCourse(courseId: any) {
    this.studentCourseService.getResultGridByCourseId(courseId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.studentCourseDataList = response.data;
      } else {
        this.studentCourseDataList = new Array<StudentCourse>();
      }
    });
  }

  setStudentCourse(value: any) {
    if (value) {
      this.studentCourseId = parseInt(value);
      this.studentCourseData = this.studentCourseDataList.find((x: any) => x.id == this.studentCourseId);
      this.getStudentClassesCourseByCourse(this.studentCourseId);
    }
  }

  getStudentClassesCourseByCourse(studentCourseId: any) {
    this.studentClassesCourseService.getByStudentCourseId(studentCourseId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.studentClassesCourseDataList = response.data;
        this.studentClassesCourseItemsArray = this.getSelectOptionStudentClassesCourse(this.studentClassesCourseDataList);
      } else {
        this.studentClassesCourseDataList = new Array<StudentClassesCourse>();
        this.studentClassesCourseItemsArray = new Array<any>();
      }
    });
  }

  setStudentClassesCourse(value: any) {
    if (value) {
      this.studentClassesCourseId = parseInt(value);
      this.studentClassesCourseData = this.studentClassesCourseDataList.find((x: any) => x.id == value);
      //this.getStudentClassesCourseByCourse(this.courseId);
    }
  }
  
  getSelectOptionStudentClassesCourse(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      let arrayList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        let expectedDate = new Date(data[i].expectedDate);
        const element: any = {
          id: data[i].id,
          text: "Aula: " + data[i].numberClass + " | Data/hora: " + expectedDate.toLocaleDateString('pt-BR') +' '+ expectedDate.toLocaleTimeString('pt-BR')
        };
        arrayList.push(element);
      }
      return arrayList;
    }
    return new Array<any>();
  }

  formatDate(datetime: any): string {
    let dateReturn = new Date(datetime);
    return this.datepipe.transform(dateReturn, 'dd/MM/yyyy HH:mm');
  }

  done(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validSave = true;

    // Errors Cleaning
    this.errorMessage = '';

    let writingTestApply = new WritingTestApply({
      studentClassesCourseId: this.studentClassesCourseId,
      writingTestId: this.writingTestId,
      teacherAppliedId: this.teacher.id,
      scoreResult: 0,
      availabilityDate: new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US')),
      avgScore: AvgScore.Basic,
      writingTestStatus: WritingTestStatus.Pending,
      additionalInformation: '',
      writeContentStudentHtml: '',
      contentReviewHtml: '',
      writingTestReview: WritingTestReview.Pending
    });


    if (!this.writingTestApplyValidation(writingTestApply)) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validSave = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.writingTestApplyService.save(0, writingTestApply, this.editMode)
    .pipe(first()).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.submitted = false;
        this.loading.hideLoading();
        this.dismissModal();
        this.validSave = true;
        
        swal({
          type: 'success',
          title: 'Aplicação de teste realizado com sucesso!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          window.location.reload();
        });
      } else {
        this.validSave = false;
        this.errorMessage = response.message;
        if (response.data && response.data.errors && response.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= response.data.errors.length; i++) {
            this.listErrorMessage.push(response.data.errors[i].Description);
          }
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (error: any) => {
      this.validSave = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = "Erro ao aplicar o teste.";
    });
  }

  writingTestApplyValidation(data: any): boolean {
    return true;
  }

  cancel() {
    this.dismissModal();
  }

  changedClassSuccess(status: StatusClass) {
    swal({
      type: 'success',
      title: `Aula ${StatusClassLabel.get(status)} com sucesso!`,
      showConfirmButton: false,
      timer: 2200
    });
  }

  changedClassFailed(status: StatusClass) {
    swal({
      type: 'warning',
      title: `Não foi possível manter a aula como ${StatusClassLabel.get(status)}!`,
      showConfirmButton: true,
    });
  }
}
