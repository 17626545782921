import { Base } from "@app/shared/model";
import { SummaryClassRunningByDay } from "./summaryClassRunningByDay";

export class GroupClassRunningByDay extends Base {
    past: Array<SummaryClassRunningByDay> = [];
    present: Array<SummaryClassRunningByDay> = [];
    future: Array<SummaryClassRunningByDay> [];

    constructor(contentResponse: any) {
        super(contentResponse);
        
        if (contentResponse.past && contentResponse.past.lenght > 0) {
            this.past = contentResponse.past;
        }

        if (contentResponse.present && contentResponse.present.lenght > 0) {
            this.present = contentResponse.present;
        }

        if (contentResponse.future && contentResponse.future.lenght > 0) {
            this.future = contentResponse.future;
        }
    }
}
