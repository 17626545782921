import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { default as swal } from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { first } from 'rxjs/operators';

import { StudentService, AddressService } from '@app/admin/services';
import { Student, State } from '@app/admin/model';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { AlertService, MessageService } from '@app/shared/services';
import { UtilService } from '@app/shared/services/util.service';
import { InitStudentService } from '@app/student/services';
import { UserService } from '@app/auth/services';
import { AccountType } from '@app/shared/enum';

@Component({ templateUrl: 'update.component.html' })
export class UpdateComponent implements OnInit, AfterViewInit {
  @ViewChild('mdUpdateMessage') modal: any;
  public userId: any;
  private student: Student;
  public studentForm: any;
  submitted = false;
  public flagForeignPerson: boolean = false;
  returnUrl: string = '/student/home';

  public foreignPersonDisableFields = false;

  public validStudent: boolean;
  public errorMessage = '';
  public listErrorMessage: any[];
  public updateStudent: boolean = false;
  public phoneMask: Array<string | RegExp>;
  public cepMask: Array<string | RegExp>;
  public enumAccountType: AccountType;
  
  public editMode: boolean;
  public stateList: any = [];
  public citiesList: any = [];
  public idStudent: number;

  public cepSearch: boolean = false;
  private datePipe = new DatePipe('pt-BR');

  requiredValidator = Validators.required;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private studentService: StudentService,
    private initStudent: InitStudentService,
    private utilService: UtilService,
    private loading: MessageService,
    private userService: UserService,
    private addressService: AddressService
  ) {
    this.phoneMask = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.cepMask = [/[0-9]/, /\d/ ,/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    this.onInitForm();
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initStudent.setConfigPage(true);
    this.checkHaveStudent();
  }

  checkHaveStudent() {
    if (this.initStudent.user != null && this.initStudent.user.id > 0) {
      this.studentService.getByUserId(this.initStudent.user.id).subscribe((response: { id: number; }) => {
        if (response != null && response.id > 0) {
          this.redirectHome();
        } else {
          this.initialyze();
        }
      }, (error: any) => {
        console.log(error);
        this.initialyze();
      });
    } else {
      this.initialyze();
    }
  }

  initialyze() {
    this.getStates();
    this.editMode = false;
    this.userId = this.initStudent.user.id;

    if (this.userId > 0) {
      this.getStudentByUser(this.userId);
    }

    this.getUserData(this.userId);
    //this.onSetEventsForm();
    this.loading.hideLoading();
  }

  ngAfterViewInit(){
    this.viewUpdateMessage(this.modal);
  }

  onInitForm() {
    this.validStudent = true;
    this.flagForeignPerson = false;
    this.studentForm = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: [{value: '', disabled: this.foreignPersonDisableFields}, [Validators.required, Validators.maxLength(15)]],
      cpf: [{value: '', disabled: this.foreignPersonDisableFields}, Validators.required],
      zipCode: ['', [Validators.maxLength(8)]],
      street: ['', [Validators.required]],
      number: [''],
      complement: [''],
      neighborhood: ['', [Validators.required]],
      userId: ['', [Validators.required]],
      cityId: ['', [Validators.required]],
      cityName: [''],
      stateId: ['', [Validators.required]],
      stateUf: [''],
      country: [''],
      status: [''],
      createdOn: [''],
      updatedOn: [''],
      foreignPerson: [false]
    });

    this.studentForm.controls['cpf'].setErrors(null);
    this.studentForm.controls['phone'].setErrors(null);
    this.studentForm.controls['birthDate'].setErrors(null);
  }
  
  onSetEventsForm() {
    this.f.phone.valueChanges.subscribe((value: string) => {
      var strPhone = value.toString().replace('(', '').replace(')', '').replace('-', '').replace('_', '').replace(' ', '');
      var phoneNumber = parseInt(strPhone);
      if (!(phoneNumber >= 11111111111 && phoneNumber <= 99999999999)) {
        value = phoneNumber.toString();
        this.f.phone.status = "INVALID";
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.studentForm ? this.studentForm.controls : null;
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validStudent = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.studentForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validStudent = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.student = new Student(this.studentForm.value);
    this.student.foreignPerson = this.flagForeignPerson;
    
    this.studentService.save(this.student, this.editMode, true)
    .pipe(first()).subscribe((dataReturn: any) => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.validStudent = true;
        swal({
          type: 'success',
          title: 'Dados atualizados com sucesso!',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.router.navigate([this.returnUrl]);
        });
      } else {
        this.validStudent = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (error: any) => {
      console.log(error);
      this.validStudent = false;
      this.submitted = false;
      this.loading.hideLoading();
      if (error && error.status == 404) {
        this.errorMessage = error.error.message;
      } else {
        this.errorMessage = error == "OK" ? "Erro ao salvar o aluno." : error;
      }
    });
  }

  getStates() {
    this.addressService.getStates().subscribe((response: { typeResponse: TypeResponse; data: any; }) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.stateList = response.data;
      } else {
        this.stateList = new Array<State>();
      }
    });
  }

  getCitiesByStateId(stateId: number) {
    if(stateId == null || stateId == undefined || stateId == 0) {
      stateId = this.student.stateId;
    }
    this.student.cityId = null;

    this.addressService.getCityByStateId(stateId).subscribe((response: any) => {
      this.citiesList = response;
    });
  }

  getUserData(userId: string) {
    if (userId) {
      this.userService.getById(userId).subscribe((response: { typeResponse: TypeResponse; data: { name: any; email: any; }; }) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.studentForm.controls['name'].setValue(response.data.name);
          this.studentForm.controls['email'].setValue(response.data.email);
        } else {
          this.studentForm.controls['name'].setValue('');
          this.studentForm.controls['email'].setValue('');
        }
        this.studentForm.controls['userId'].setValue(userId);
        this.loading.hideLoading();
      }, (error: { error: { error: string; }; }) => {
        console.log(error);
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
        this.loading.hideLoading();
      });
    }
  }

  getStudentByUser(userId: any) {
    this.studentService.getByUserId(userId).subscribe((response: { typeResponse: TypeResponse; }) => {
      if (response && response.typeResponse == TypeResponse.Success) {
        return this.redirectHome();
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  checkStudentByCpf(input: { target: { value: any; }; }) {
    if (this.f.foreignPerson.value) {
      this.studentForm.controls['cpf'].setErrors(null);
      this.validStudent = true;
    } else {
      if (!(input && input.target.value) || !this.utilService.isValidCPF(input.target.value)) {
        this.validStudent = false;
        this.studentForm.controls['cpf'].setErrors({'incorrect': true});
        this.errorMessage = "Valide todos os campos antes de prosseguir."
        return;
      }
  
      let cpf = input.target.value;
      this.studentService.checkStudentByCpf(cpf).subscribe((response: { typeResponse: TypeResponse; data: any; }) => {
        if (response.typeResponse == TypeResponse.Success && response.data == null) {
          this.studentForm.controls['cpf'].setErrors(null);
          this.validStudent = true;
        } else {
          this.studentForm.controls['cpf'].setErrors({'already_exists': true});
          this.validStudent = false;
        }
      }, (error: any) => {
        this.validStudent = false;
        this.errorMessage = "Valide todos os campos antes de prosseguir."
        this.listErrorMessage.push(error);
      });
    }
  }

  loadStudent(id: string) {
    this.loading.showLoading();
    this.studentService.getById(id)
    .subscribe((response: { typeResponse: TypeResponse; data: any; }) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validStudent = true;
        this.student = new Student(response.data);
        this.setFormByEntity(this.student);
      } else {
        //this.returnMessage = response.message;
        this.validStudent = true;
      }
      //this.flagSearchUser = true;
      this.loading.hideLoading();
      }, (error: { error: { error: string; }; }) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
        this.loading.hideLoading();
      }
    );
  }

  loadAddressByCep(zipCode: any) {
    this.cepSearch = true;
    if (zipCode.value && parseInt(zipCode.value) && zipCode.value.length == 8) {
      let cep = zipCode.value;
      this.addressService.getAddressByCep(cep).subscribe((response: { erro: any; data: { uf: string; logradouro: any; bairro: any; ibge: string; }; }) => {
        if (response.erro) {
          this.f().age.setErrors({invalidNumber:true})
        }
        this.addressService.getCityByUf(response.data.uf).subscribe((p: { data: any; }) => {
          this.citiesList = p.data;
          this.studentForm.controls['street'].setValue(response.data.logradouro);
          this.studentForm.controls['neighborhood'].setValue(response.data.bairro);

          this.addressService.getCityByIbgeCode(response.data.ibge).subscribe((p: { data: { id: any; stateId: any; }; }) => {
            this.studentForm.controls['cityId'].setValue(p.data.id);
            this.studentForm.controls['stateId'].setValue(p.data.stateId);
            this.cepSearch = false;
            this.studentForm.controls['number'].focus();
          });
        });
      });
    } else {
      swal({
        type: 'warning',
        html: 'Por favor informe um CEP válido para buscar.',
        title: 'CEP inválido',
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 3000
      }).then(() => {
        this.getStates();
        this.cepSearch = false;
      });
    }
  }

  setFormByEntity(data: any) {
    this.studentForm = this.formBuilder.group({
      id: new FormControl(data.id),
      name: new FormControl(data.name),
      birthDate: new FormControl(this.datePipe.transform(data.birthDate, "yyyy-MM-dd", '-0300', 'pt-BR')),
      email: new FormControl(data.email),
      phone: new FormControl(data.phone),
      cpf: new FormControl(data.cpf),
      zipCode: new FormControl(data.zipCode),
      street: new FormControl(data.street),
      number: new FormControl(data.number),
      complement: new FormControl(data.complement),
      neighborhood: new FormControl(data.neighborhood),
      userId: new FormControl(data.userId),
      cityId: new FormControl(data.cityId),
      cityName: new FormControl(data.cityName),
      stateId: new FormControl(data.stateId),
      stateUf: new FormControl(data.stateUf),
      country: new FormControl(data.country),
      status: new FormControl(data.status),
      foreignPerson: new FormControl(data.foreignPerson)
    });
    this.studentForm.controls['userId'].setValue(data.userId);
    this.studentForm.controls['stateId'].setValue(data.stateId);
    this.addressService.getCityByUf(data.state.uf).subscribe((p: { data: any; }) => {
      this.citiesList = p.data;
      this.studentForm.controls['cityId'].setValue(data.cityId);
    });
  }

  onForeignPerson(input: { currentTarget: { checked: any; }; }) {
    let uForeignPerson = input.currentTarget.checked;
    this.studentForm.foreignPerson = uForeignPerson;
    this.flagForeignPerson = uForeignPerson;
    this.studentForm.controls['foreignPerson'].value = uForeignPerson;

    let reqListFkPerson = ['cityName', 'stateUf', 'country']
    let notReqListFkPerson = ['zipCode', 'cpf', 'neighborhood', 'cityId', 'stateId', 'number']

    if (uForeignPerson === true) {
      for (let i = 0; i < notReqListFkPerson.length; i++) {
        this.studentForm.get(notReqListFkPerson[i]).clearValidators();
        this.studentForm.get(notReqListFkPerson[i]).updateValueAndValidity();
      }
      for (let i = 0; i < reqListFkPerson.length; i++) {
        this.studentForm.get(reqListFkPerson[i]).setValidators([Validators.required]);
        this.studentForm.get(reqListFkPerson[i]).updateValueAndValidity();
      }
    } else {
      for (let i = 0; i < reqListFkPerson.length; i++) {
        this.studentForm.get(reqListFkPerson[i]).clearValidators();
        this.studentForm.get(reqListFkPerson[i]).updateValueAndValidity();
      }
      for (let i = 0; i < notReqListFkPerson.length; i++) {
        this.studentForm.get(notReqListFkPerson[i]).setValidators([Validators.required]);
        this.studentForm.get(notReqListFkPerson[i]).updateValueAndValidity();
      }
    }
  }

  redirectHome() {
    this.alertService.showWarning("Já existe um aluno vinculado a este usuário. Você será redirecionado para a Home!", "Aluno vinculado!");
    this.router.navigate([this.returnUrl]);
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  onUpdate() {
    this.modalService.dismissAll();
  }

  viewUpdateMessage(content: any) {
    this.modalService.dismissAll();
    this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade'});
  }
}
