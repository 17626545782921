import { formatDate } from '@angular/common';
import { User } from '@app/auth/model';
import { MessageType } from '@app/shared/enum';
import { Base } from '@app/shared/model';

export class MessageQueue extends Base {
    sidNotification: string;
    
    userId: number;
    user: User;

    notificationId: number;
    notification: Notification;

    messageType: MessageType;
    needConfirmation: boolean;
    notificationDatetime: Date;
    description: string;

    confirmed: boolean;
    confirmedDatetime: Date;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.sidNotification = contentResponse.sidNotification;

        this.userId = parseInt(contentResponse.userId);
        if (contentResponse && contentResponse.userId > 0) {
            this.user = contentResponse.user;
        }

        this.notificationId = parseInt(contentResponse.notificationId);
        if (contentResponse && contentResponse.notificationId > 0) {
            this.notification = contentResponse.notification;
        }

        this.messageType = parseInt(contentResponse.messageType);
        this.needConfirmation = contentResponse.needConfirmation;
        this.notificationDatetime = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        this.description = contentResponse.description;

        this.confirmed = contentResponse.confirmed;
        this.confirmedDatetime = new Date(contentResponse.confirmedDatetime);
    }
}
