import { Component, Input, OnInit } from '@angular/core';
import { StudentClassesCourseActionService } from '@app/admin/services';
import { TypeResponse, ModuleTypeCss, ModuleTypeLabel } from '@app/shared/enum';
import { ClassActionCss, ClassActionLabel } from '@app/shared/enum/ClassAction';
import { MessageService } from '@app/shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'studentclassescourse-auditing-changes',
  templateUrl: './studentclassescourse-auditing-changes.component.html'
})
export class StudentClassesCourseAuditingChangesComponent implements OnInit {
  @Input("studentClassesCourseId") studentClassesCourseId: string = '782';
  public flagSearchInfo = false;
  public studentClassesCourseActionData: any;
  public errorMessage: string = '';
  public showTableActions: boolean = false;

  public actionsDataSource$ = new BehaviorSubject([]);

  // Pagination
  currentMessage = null;
  currentIndex = -1;
  page = 1;
  pageSize = 3;
  totalPages = 0;
  totalItems = 0;
  actionsCount = 0;

  constructor(private loading: MessageService,
    private modalService: NgbModal,
    private studentClassesCourseActionService: StudentClassesCourseActionService) {
      this.loading.showLoading();
    }

  ngOnInit(): void {
    if (this.studentClassesCourseId) {
      this.retrieveActions(this.studentClassesCourseId);
    }
  }

  handlePageChange(event: any): void {
    this.page = event;
    this.retrieveActions(this.studentClassesCourseId);
  }

  retrieveActions(studentClassesCourseId: any) {
    this.flagSearchInfo = true;
    this.studentClassesCourseActionService.getByPage(studentClassesCourseId, this.page, this.pageSize)
    .subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.designActions(response.data.items);
        this.actionsCount = response.data.metadata.count;
        this.totalPages = response.data.metadata.totalPages;
        this.totalItems = response.data.metadata.totalItems;
        this.showTableActions = true;
        this.errorMessage = '';
        this.loading.hideLoading();
      } else {
        this.errorMessage = response.message;
        this.showTableActions = false;
        this.studentClassesCourseActionData = null;
        this.loading.hideLoading();
      }
    }, (error: any) => {
      this.showTableActions = false;
      this.studentClassesCourseActionData = null;
      this.errorMessage = 'Erro ao carregar as ações de logs.';
      this.loading.hideLoading();
    });
  }
  
  designActions(lista: any[]) {
    lista.forEach(function(e) {
      if (e.studentClassesCourse && e.studentClassesCourse?.expectedDate instanceof Date) {
        let expectedDate = new Date(e.studentClassesCourse?.expectedDate);
        e.designExpectedDate = "<span class='badge border border-primary text-primary'>"+
          "<i class='fas fa-solid fa-calendar'></i> "+ expectedDate.toLocaleString() +"</span>";
      }

      if (e.date) {
        let datetime = new Date(e.date);
        e.designDatetime = "<span class='badge border border-primary text-primary'>"+
          "<i class='fas fa-solid fa-calendar'></i> "+ datetime.toLocaleString() +"</span>";
      }

      if (e.module >= 0) {
        let moduleTypeCss = ModuleTypeCss.get(e.module);
        let moduleTypeStr = ModuleTypeLabel.get(e.module);
        e.designModule = "<span class='badge badge-pill w-30 pull-right ml-2 "
          + moduleTypeCss +"'>"
          + moduleTypeStr +"</span>";
      }

      if (e.classAction >= 0) {
        let classActionCss = ClassActionCss.get(e.classAction);
        let classActionStr = ClassActionLabel.get(e.classAction);
        e.designAction = "<span class='badge badge-pill w-30 pull-right mt-1 ml-2 "
          + classActionCss +"'>"
          + classActionStr +"</span>";
      }
    });
    this.studentClassesCourseActionData = lista;
    this.actionsDataSource$.next(lista);
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
}
