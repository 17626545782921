import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-by-teacher-list',
  templateUrl: './course-by-teacher-list.component.html',
  styleUrls: ['./course-by-teacher-list.component.css']
})
export class CourseByTeacherListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
