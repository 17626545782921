import { MessageService } from '@app/shared/services/message.service';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AvgScoreCss, AvgScoreLabel, TypeResponse } from '@app/shared/enum';
import { default as swal } from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { InitTeacherService } from '@app/teacher/services';
import { WritingTestService } from '@app/admin/services';
import { first } from 'rxjs/operators';
import { Teacher } from '@app/admin/model';

@Component({
  selector: 'writing-test-list',
  templateUrl: './writing-test-list.component.html',
  styleUrls: ['./writing-test-list.component.css'],
  providers: [DatePipe],
})
export class WritingTestListComponent implements OnInit {
  public dataList: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  closeResult: string;
  public contentHtmlSafeView: any;
  private teacher: Teacher;
  public writingTestId: any;

  iconWritingTestListApplied = 'fa fa-list-alt';
  titleWritingTestListApplied = 'Lista testes aplicados';

  iconApplyWritingTest = 'fa fa-file-alt';
  titleApplyWritingTest = 'Aplicação teste de escrita';

  iconWritingTestAppliedReview = 'fa fa-file-check';
  titleWritingTestAppliedReview = 'Lista testes aplicados';

  public columns: Array<any> = [
    { title: 'Código', name: 'id', sort: 'asc', className: 'text-center' },
    {
      title: 'Nome',
      name: 'name',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-left font-weight-bold',
    },
    {
      title: 'Ordem',
      name: 'order',
      sort: 'asc',
      className: 'text-center text-info font-weight-bold',
    },
    {
      title: 'Nº de Aplicações',
      name: 'usageCount',
      sort: 'asc',
      className: 'text-center text-primary font-weight-bold',
    },
    // {
    //   title: 'Nota Média',
    //   name: "designAvgScore",
    //   sort: "asc",
    //   isHtmlData: true,
    //   className: "text-center text-primary"
    // },
    {
      title: 'Criado em',
      name: 'createdOn',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
    {
      title: 'Modificado em',
      name: 'updatedOn',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
  ];
  constructor(
    private writingTestService: WritingTestService,
    private initTeacher: InitTeacherService,
    private sanitizer: DomSanitizer,
    private loading: MessageService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loading.showLoading();
    this.dataResult = false;
    this.getUserData();
  }

  private getUserData() {
    this.initTeacher
      .getTeacherUser()
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.teacher = response;
          this.loadWritingTests(this.teacher?.id ?? 0);
          this.loading.hideLoading();
        },
        (error: any) => {
          this.showTable = false;
          this.returnMessage = 'Erro ao carregar os testes disponíveis.';
          this.loading.hideLoading();
        }
      );
  }

  loadWritingTests(teacherId: any) {
    this.loading.showLoading();
    this.writingTestService.getByTeacherId(teacherId).subscribe(
      (response: any) => {
        if (response.typeResponse === TypeResponse.Success) {
          this.dataList = response.data;
          this.designAvgScore(this.dataList);
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.loading.hideLoading();
      },
      (error: any) => {
        this.showTable = false;
        this.returnMessage = 'Erro ao carregar os testes disponíveis.';
        this.loading.hideLoading();
      }
    );
  }

  designAvgScore(lista: any[]) {
    lista.forEach(function (e) {
      if (e.avgScore >= 0) {
        const avgScoreCss = AvgScoreCss.get(e.avgScore);
        const avgScoreStr = AvgScoreLabel.get(e.avgScore);
        e.designAvgScore =
          "<span class='badge badge-pill w-100 font-weight-bold " +
          avgScoreCss +
          "'>" +
          avgScoreStr +
          '</span>';
      } else {
        e.designAvgScore =
          "<span class='badge badge-pill w-100 font-weight-bold score-basic'>0,00</span>";
      }
    });
    this.dataList = lista;
  }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/writingtest', { id: row.id }]);
  }

  delete(target: any): void {
    swal({
      title: 'Excluir Testes de Escrita(a)',
      html:
        'Confirma a exclusão <strong>' +
        target.name +
        '</strong>?<br>' +
        'Ao confirmar o testes de escrita será excluído permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.writingTestService.remove(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.loadWritingTests(this.initTeacher.teacher.id);
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir o teste de escrita!',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir o teste de escrita.',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  this.loadWritingTests(this.initTeacher.teacher.id);
                });
              }
            },
            (error) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir o teste de escrita.',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir o teste de escrita.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  create(content: any) {
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'Save') {
          }
        },
        (reason) => {
          this.closeResult = `Dismissed`;
        }
      );
  }

  preview(row: any, content: any) {
    this.contentHtmlSafeView = this.sanitizer.bypassSecurityTrustHtml(
      row.contentHtml
    );
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  viewWritingTestApplies(row: any, content: any) {
    this.writingTestId = row.id;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      console.log(reason);
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  applyWritingTest(row: any, content: any) {
    this.writingTestId = row.id;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
}
