export enum StatusLead {
  Comercial = 0,
  Concluido = 1,
  AulaAgendada = 2,
  Finalizado = 3,
  AnalisandoValores = 4,
  NaoCompareceu = 5,
  Desmarcou = 6,
  SemRetorno = 7,
  EnviarTermo = 8,
  TermoEnviado = 9
}

export const StatusLeadLabel = new Map<number, string>([
  [StatusLead.Comercial, 'Comercial'],
  [StatusLead.Concluido, 'Concluído'],
  [StatusLead.AulaAgendada, 'Aula agendada'],
  [StatusLead.Finalizado, 'Finalizado'],
  [StatusLead.AnalisandoValores, 'Analisando Valores'],
  [StatusLead.NaoCompareceu, 'Não compareceu'],
  [StatusLead.Desmarcou, 'Desmarcou'],
  [StatusLead.SemRetorno, 'Sem retorno'],
  [StatusLead.EnviarTermo, 'Enviar termo'],
  [StatusLead.TermoEnviado, 'Termo enviado']
]);

export const StatusLeadCss = new Map<number, string>([
  [StatusLead.Comercial, 'status-comercial'],
  [StatusLead.Concluido, 'status-concluido'],
  [StatusLead.AulaAgendada, 'status-aula-agendada'],
  [StatusLead.Finalizado, 'status-finalizado'],
  [StatusLead.AnalisandoValores, 'status-analisando-valores'],
  [StatusLead.NaoCompareceu, 'status-nao-compareceu'],
  [StatusLead.Desmarcou, 'status-desmarcou'],
  [StatusLead.SemRetorno, 'status-sem-retorno'],
  [StatusLead.EnviarTermo, 'status-enviar-termo'],
  [StatusLead.TermoEnviado, 'status-termo-enviado']
]);

export const StatusLeadDescription = new Map<number, string>([
  [StatusLead.Comercial, 'Aguardando passar preço dos pacotes'],
  [StatusLead.Concluido, 'Não tem condição financeira no momento'],
  [StatusLead.AulaAgendada, 'Aguardando aula experimental'],
  [StatusLead.Finalizado, 'Ingressou como aluno(a) da English Coffee'],
  [StatusLead.AnalisandoValores, 'Analisando preço dos pacotes já enviado'],
  [StatusLead.NaoCompareceu, 'Não compareceu na aula experimental'],
  [StatusLead.Desmarcou, 'Desmarcou a aula experimental'],
  [StatusLead.SemRetorno, 'Não obtivemos retorno por parte do aluno(a)'],
  [StatusLead.EnviarTermo, 'Enviar termo de ativação e fazer os procedimentos'],
  [StatusLead.TermoEnviado, 'Aguardando termo preenchido']
]);
