import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InitAdminService, TeacherService } from '@app/admin/services';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { AlertService, MessageService } from '@app/shared/services';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { ContactBySiteService } from '@app/marketing/services/contactBySite.service';
import { first } from 'rxjs/operators';
import { default as swal } from 'sweetalert2';
//import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { StatusCrm, StatusCrmCss, StatusCrmLabel } from '@app/shared/enum';
import { ContactBySite, Teacher } from '@app/admin/model';
import { DatePipe, formatDate } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({ templateUrl: 'contactBySite.component.html' })
export class ContactBySiteComponent implements OnInit {
  public contactForm: any;
  public submitted = false;
  private returnUrl: string = '/marketing/contactbysitelist';
  public validContact: boolean;
  public errorMessage = '';
  closeResult: string;
  public listErrorMessage: any[];
  public cancelButtonText: string = 'Cancelar';

  public editMode: boolean = false;
  public previewMode: boolean = false;
  public contactBySite: ContactBySite;
  public contactId: number;

  public validTeacher: boolean;
  public teacherList: any = [];
  public selectTeacherList: any[];
  public flagTeacherSelected: boolean = false;
  public teacher: Teacher;

  public contactData: ContactBySite;
  public statusCrmDataList: Array<any>;
  public statusInitialValue: any;

  public commentsCount: number = 0;
  public commentsArray: Array<any> = [];

  public experimentalDateValue: any;// = '2023-03-29';
  public experimentalTimeValue: any;// = '18:20';
  public experimentalClass = new Date();
  public experimentalClassTeacherId: number;

  // public experimentalDate: any;
  // public experimentalTime: any;
  // public experimentalClass = new Date();

  public newStatusCrm: any;
  public newStatusCrmId: number;
  public newStatusCrmStr: string;
  public newStatusCrmCss: string;
  public options: Options = {
    width: '300',
    //triggerChange: true,
    allowClear: true
  };

  public initialValueDate: any;

  constructor(
    private contactBySiteService: ContactBySiteService,
    private activatedRoute: ActivatedRoute,
    private teacherService: TeacherService,
    private initAdmin: InitAdminService,
    private formBuilder: FormBuilder,
    private loading: MessageService,
    private modalService: NgbModal,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.contactId = params['id'];
      this.previewMode = params['preview'] == 'true';
    });

    // Set status
    this.getStatusCrmList();

    // Initialize the register form
    this.onInitForm();
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();

    if (this.contactId > 0) {
      this.editMode = true;
      this.onLoadTeachers();
      this.loadContactById(this.contactId);
    } else {
      this.cancelButtonText = 'Fechar';
      this.editMode = false;
    }

    // Check phone valid
    this.onSetEventsForm();

    this.loading.hideLoading();
  }

  setNewStatus(value: any) {
    this.newStatusCrmId = Number.parseInt(value);
    this.newStatusCrm = this.statusCrmDataList.find((x: any) => x.id == value);
    this.newStatusCrmStr = StatusCrmLabel.get(value);
    this.newStatusCrmCss = StatusCrmCss.get(value);
    this.statusInitialValue = this.newStatusCrm;
  }

  onInitForm() {
    this.validContact = true;
    this.contactForm = this.formBuilder.group({
      id: [0],
      name: ['', [ Validators.required, Validators.maxLength(100), Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.maxLength(150), Validators.minLength(5)]],
      phone: ['', [Validators.required, Validators.maxLength(15)]],
      message: ['', [Validators.required]],
      statusCrm: [0, [Validators.required]],
      experimentalClassTeacherId: [],
      ipAddress: [''],
      createdOn: [''],
      updatedOn: [''],
      experimentalClass: [''],
      status: [0]
    });

    this.setNewStatus(StatusCrm.SDR);
    this.experimentalDateValue = '';//newDateStr.toString().split(' ')[0];
    this.experimentalTimeValue = '';//newDateStr.toString().split(' ')[1];
  }

  onSetEventsForm() {
    this.f.phone.valueChanges.subscribe((value: string) => {
      var strPhone = value.toString().replace('(', '').replace(')', '').replace('-', '').replace('_', '').replace(' ', '');
      var phoneNumber = parseInt(strPhone);
      if (!(phoneNumber >= 0 && phoneNumber <= 99999999999)) {
        value = phoneNumber.toString();
        this.f.phone.status = "INVALID";
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    if (this.contactForm) {
      return this.contactForm.controls;
    }
    return null;
  }

  getControl(controlName: string) {
    return this.contactForm.get(controlName);
    //return this.contactForm.controls;
  }

  getFormattedMessage(messageValue: string): any {
    return messageValue?.replace(/\n/g, "<br />");
  }

  onSubmit() {
    this.loading.showLoading();
    this.submitted = true;
    this.validContact = true;

    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validContact = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.contactBySite = new ContactBySite(this.contactForm.value);
    if (!this.editMode && !this.previewMode) {
      this.contactBySite.ipAddress = "0.0.0.0";
    }
    this.contactBySite.statusCrm = this.newStatusCrmId;

    if (this.experimentalDateValue) {
      let newDate = new Date(this.experimentalDateValue + 'GMT-0300');
      newDate.setMinutes(this.experimentalTimeValue.split(':')[1]);
      newDate.setHours(this.experimentalTimeValue.split(':')[0]);
      let newDateStr = formatDate(newDate, 'yyyy-MM-dd HH:mm:ss', 'en-US');
      if (newDateStr) {
        newDateStr += ' -03:00';
        this.contactBySite.experimentalClass = new Date(newDateStr + 'GMT-0300');
      }
    }

    this.contactBySiteService.save(this.contactBySite, this.editMode && this.contactBySite.id > 0)
    .pipe(first()).subscribe((dataReturn: any) => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.validContact = true;
        swal({
          type: 'success',
          title: 'Registro atualizado com sucesso!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          this.router.navigate([this.returnUrl]);
        });
      } else {
        this.validContact = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (error: any) => {
      this.validContact = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = error.message;
      if (error.status >= 400) {
        this.errorMessage = error.error.message;
      }
    });
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  getStatusCrmList() {
    const statusList: Array<object> = [];
    for (var value in StatusCrm) {
      if (Number.isInteger(Number.parseInt(value))) {
        statusList.push({
          value: Number.parseInt(value),
          text: StatusCrmLabel.get(Number.parseInt(value))
        });
      }
    }
    this.statusCrmDataList = this.getTemplateStatusList(statusList);
    this.setNewStatus(this.statusCrmDataList[0].id);
  }

  onLoadTeachers() {
    this.teacherService.all().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.teacherList = response.data;
        this.getInputCourseList(this.teacherList);
      } else {
        this.teacherList = new Array<Teacher>();
      }
    });
  }

  getInputCourseList(data: Array<any>) {
    this.selectTeacherList = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let item = {
          id: data[i].id.toString(),
          text: data[i].id.toString() + " | " + data[i].name,// + " | " + data[i].email,
        };
        this.selectTeacherList.push(item);
      }
    }
  }

  getTemplateStatusList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      let arrayStatusList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].value,
          name: data[i].text
        };
        arrayStatusList.push(element);
      }
      return arrayStatusList;
    }
    return new Array<any>();
  }

  loadContactById(id: any) {
    this.loading.showLoading();
    this.contactBySiteService.getById(id).pipe(first()).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validContact = true;
        this.designChangeStatus(response.data);
        //this.setFormByEntity(response.data);
      } else {
        this.errorMessage = response.message;
      }
      this.loading.hideLoading();
      }, (error: any) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
      }
    );
  }

  designChangeStatus(item: any) {
    if (item?.contactBySiteStatusChanges) {
      item.contactBySiteStatusChanges.forEach(function(e: any) {
        if (e.oldStatus >= 0) {
          let oldStatusStr = StatusCrmLabel.get(e.oldStatus);
          let oldStatusCss = StatusCrmCss.get(e.oldStatus);
          e.designOldStatus = "<span class='badge w-100 "
            + oldStatusCss +" badge-pill py-1 px-3'>"
            + oldStatusStr +"</span>";
        }

        if (e.newStatus >= 0) {
          let newStatusStr = StatusCrmLabel.get(e.newStatus);
          let newStatusCss = StatusCrmCss.get(e.newStatus);
          e.designNewStatus = "<span class='badge w-100 "
            + newStatusCss +" badge-pill py-1 px-3'>"
            + newStatusStr +"</span>";
        }
        e.datetime = new Date(e.createdOn);
      });
      this.commentsArray = item?.contactBySiteStatusChanges.sort((a: any, b: any) => {
        return b.datetime.getTime() - a.datetime.getTime();
      });
      this.commentsCount = item?.contactBySiteStatusChanges.length;

      if (item.experimentalClass) {
        let newDateStr = formatDate(item.experimentalClass, 'yyyy-MM-dd HH:mm:ss', 'en-US');
        this.experimentalDateValue = newDateStr.toString().split(' ')[0];
        this.experimentalTimeValue = newDateStr.toString().split(' ')[1];
      } else {
        this.experimentalDateValue = '';
        this.experimentalTimeValue = '';
      }
    }
    this.setFormByEntity(item);
  }

  setFormByEntity(data: any) {
    this.contactBySite = new ContactBySite(data);

    this.contactForm = this.formBuilder.group({
      id: new FormControl(this.contactBySite.id),
      name: new FormControl(this.contactBySite.name),
      email: new FormControl(this.contactBySite.email),
      phone: new FormControl(this.contactBySite.phone),
      message: new FormControl(this.contactBySite.message),
      statusCrm: new FormControl(this.contactBySite.statusCrm),
      ipAddress: new FormControl(this.contactBySite.ipAddress),
      createdOn: new FormControl(this.contactBySite.createdOn),
      updatedOn: new FormControl(this.contactBySite.updatedOn),
      createdBy: new FormControl(this.contactBySite.createdBy),
      updatedBy: new FormControl(this.contactBySite.updatedBy),
      status: new FormControl(this.contactBySite.status),
      experimentalClassTeacherId: new FormControl(this.contactBySite.experimentalClassTeacherId),
      experimentalClass: new FormControl(this.contactBySite.experimentalClass)
    });
    this.initialValueDate = formatDate(this.contactBySite.experimentalClass, 'yyyy-MM-dd', 'en-US');
    this.setNewStatus(this.contactBySite.statusCrm);
  }

  updateDate(event: any) {
    this.initialValueDate = this.datePipe.transform(event.target.value, "yyyy-MM-dd");
    if (this.initialValueDate) {
      this.experimentalDateValue = new Date(this.initialValueDate+ 'GMT-0300');
    }
  }

  setValueTime(input: any) {
    this.experimentalTimeValue = input.value;
  }

  setValueDate(input: any) {
    this.experimentalDateValue = input.value;
  }

  onTeacherChange(teacherId: number) {
    this.flagTeacherSelected = false;
    if (teacherId > 0) {
      this.teacherService.get(teacherId).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.teacher = response.data;
          this.flagTeacherSelected = true;
        } else {
          this.teacher = new Teacher(null);
        }
      });
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();

      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }

  commentsView(content: any) {
    this.modalService.dismissAll();
    this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade zindex'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  selectEventStatus(item: any) {
    // do something with selected item
    this.setNewStatus(item.id);
  }

  onChangeSearchStatus(val: string) {
    console.log(val);
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocusedStatus(e :any) {
    console.log(e);
    // do something when input is focused
  }
}
