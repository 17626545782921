import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseResult } from '@app/auth/model';
import { Product } from '@app/admin/model';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private route: string = "admin";
  private controller: string = "product";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  all(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  get(id: number): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }
  
  remove(id: any): Observable<any> {
    return this.http.delete<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }
  
  save(product: Product, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/update`, product);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/add`, product);
    }
  }
}
