import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseResult } from '@app/auth/model';
import { CheckList } from '@app/admin/model';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private route: string = "admin";
  private controller: string = "configuration";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getall(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getById(checkListId: string): any {
    let params = new HttpParams();
    params = params.append('id', checkListId);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getById`, {params: params});
  }
  
  remove(id): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.delete<ResponseResult>(`${this.endpointPrefix}/remove`, {params: params});
  }
  
  save(checkList: CheckList, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/update`, checkList);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/add`, checkList);
    }
  }
}
