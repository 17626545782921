import { formatDate } from '@angular/common';
import { GeneralStatus } from '@app/shared/enum';

export class Base {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    createdBy: string;
    updatedBy: string;
    status: GeneralStatus;

    constructor(contentResponse: any) {
        if (contentResponse) {
            this.id = parseInt(contentResponse.id ?? 0);
            
            if (contentResponse.createdOn && this.checkDate(contentResponse.createdOn.toString())) {
                this.createdOn = new Date(contentResponse?.createdOn);
            } else {
                this.createdOn = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'pt-BR', 'GMT'));
            }

            if (contentResponse.updatedOn && this.checkDate(contentResponse.updatedOn.toString())) {
                this.updatedOn = new Date(contentResponse.updatedOn);
            } else {
                this.updatedOn = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'pt-BR', 'GMT'));
            }
            
            this.createdBy = '';
            this.updatedBy = '';
            this.status = contentResponse.status ? parseInt(contentResponse.status) : GeneralStatus.Active;
        }
    }

    checkDate(date: any): boolean {
        var parsedDate = Date.parse(date);
        return (isNaN(date) && !isNaN(parsedDate));
    }
}
