import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Chart } from 'chart.js';

import { User } from '@app/auth/model';
import { AccountService } from '@app/auth/services';

// Services
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { ToastrService } from 'ngx-toastr';
import { CourseGrid, DashboardCount } from '@app/admin/model';
import { CourseService, DashboardService } from '@app/admin/services';
import { first } from 'rxjs/operators';
import { CourseTypeCss, CourseTypeLabel, StatusClass, StatusClassCss, StatusClassLabel, StatusCrm, StatusCrmCss, StatusCrmLabel, TypeResponse } from '@app/shared/enum';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { MessageService, UtilService } from '@app/shared/services';
import { BehaviorSubject } from 'rxjs';

@Component({ templateUrl: 'home.component.html', providers: [DatePipe] })
export class HomeComponent implements OnInit {
    private src_script = '//js-na1.hs-scripts.com/24078910.js';
    loadAPI: Promise<any>;

    public user: User = new User();
    private userRoles: Array<any>;

    public dateNow = new Date();
    public showTable: boolean;
    public gridList: CourseGrid[];
    public returnMessage = '';
    closeResult: string;

    public auxIcon = "fa fa-users";
    public auxTitle = "Alunos";

    public auxIcon2 = "fas fa-user-plus";
    public auxTitle2 = "Incluir Aluno";

    public courseId: number = 0;

    public dashboardCount: DashboardCount;
    public dashboardCount$ = new BehaviorSubject(null);
    public statusClassesChartList: any = [];

    public dashboardContentCount: any;
    public dashboardContentCount$ = new BehaviorSubject(null);

    plannedClassesCount: number = 0;
    confirmedClassesCount: number = 0;
    canceledClassesCount: number = 0;

    public columns: Array<any> = [
        { title: "Código", name: "id", sort: "asc", className: "text-center" },
        { title: "Nome", name: "name", sort: "asc", isHtmlData: true },
        { 
          title: "Pacote", 
          name: "product.name", 
          sort: "asc",
          isHtmlData: true,
          className: "text-left fw-700"
        },
        { 
          title: "Professor", 
          name: "teacher.name", 
          sort: "asc",
          className: "text-left fw-700"
        },
        { 
          title: "Tipo", 
          name: "designCourseType", 
          sort: "asc",
          isHtmlData: true,
          className: "text-center"
        },
        { 
          title: "Total<br>de <strong>Meses</strong>", 
          name: "quantityMonth", 
          sort: "asc",
          className: "text-center text-info fw-700"
        },
        { 
          title: "Aulas<br><strong>Semanais</strong>", 
          name: "weeklyFrequency", 
          sort: "asc",
          className: "text-center text-info fw-700"
        },
        { 
            title: "<strong>Limite</strong><br>de Alunos", 
            name: "quantityStudent", 
            sort: "asc",
            className: "text-center text-info fw-700"
        },
        { 
          title: "<strong>Alunos</strong><br>Matriculados", 
          name: "registeredStudents", 
          sort: "asc",
          className: "text-center text-info fw-700"
        },
        { 
          title: "<strong>Posições</strong><br>Disponíveis", 
          name: "availablePositions", 
          sort: "asc",
          className: "text-center text-success fw-700"
        }
    ];

    constructor(
        private dashboardService: DashboardService,
        private courseService: CourseService,
        private utilService: UtilService,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private accountService: AccountService,
        private initAdmin: InitAdminService,
        private router: Router,
        private loading: MessageService) {
        this.loading.showLoading();
        this.initAdmin.setConfigPage(false, false);
        this.user = this.accountService.getUserData();
        this.userRoles = this.accountService.getRolesByUser();
        this.gridList = new Array<CourseGrid>();
        this.dashboardCount = new DashboardCount();
    }

    ngOnInit() {
        this.dateNow = new Date();
        this.loadCounters();
        this.buildChartHeader();
        this.loadCourses();
        this.calcClassCount();
        this.loadDashboardContentCount();
        this.loading.hideLoading();
        // this.getContadoresPorStatusSintetico();
        // this.getContadoresPorTipo();
        // this.getIndicadoresInspetores();
        // this.initGraficos();
    }

    getStatusList() {
        this.statusClassesChartList = [];
        for (var value in StatusClass) {
            if (Number.isInteger(Number.parseInt(value))) {
                this.statusClassesChartList.push({
                    value: Number.parseInt(value),
                    text: StatusClassLabel.get(Number.parseInt(value)),
                    percent: 0,
                    css: StatusClassCss.get(Number.parseInt(value)),
                    id: 'chartPlannedClasses-'+StatusClassCss.get(Number.parseInt(value))
                });
            }
        }
    }

    // getStatusClasses() {
    //     lista.forEach(function(e) {
    //         if (e.courseType >= 0) {
    //             let courseTypeCss = CourseTypeCss.get(e.courseType);
    //             let courseTypeStr = CourseTypeLabel.get(e.courseType)
    //             e.designCourseType = "<span class='badge badge-pill w-100 "
    //                 + courseTypeCss +"'>"
    //                 + courseTypeStr +"</span>";
    //             }
    //     });
    //     this.gridList = lista;
    // }

    private calcClassCount() {
        this.plannedClassesCount = this.utilService.RandomNumber(30, 150);
        this.confirmedClassesCount = Math.round(this.plannedClassesCount * (this.utilService.RandomNumber(0, 20)/100));
        this.canceledClassesCount = this.plannedClassesCount - this.confirmedClassesCount;
    }

    loadCounters() {
        this.dashboardService.get().pipe(first())
        .subscribe((response: any) => {
            if (response.typeResponse == TypeResponse.Success) {
                this.dashboardCount = response.data;
                this.dashboardCount$.next(this.dashboardCount);
            } else {
                this.dashboardCount = new DashboardCount();
            }
        }, (error: any) => {
            this.returnMessage = 'Erro ao carregar os dados dos contadores.';
        });
    }

    getCrmStatusCss(statusCrm: StatusCrm) {
        return StatusCrmCss.get(statusCrm);
    }
    
    getCrmStatusStr(statusCrm: StatusCrm) {
        return StatusCrmLabel.get(statusCrm);
    }

    loadDashboardContentCount() {
        this.dashboardService.contentSeriesAndEpisodes().pipe(first())
        .subscribe((response: any) => {
            if (response.typeResponse == TypeResponse.Success) {
                this.dashboardContentCount = response.data;
                this.dashboardContentCount$.next(this.dashboardContentCount);
                this.loadContentChart(this.dashboardContentCount);
            }
        }, (error: any) => {
            this.returnMessage = 'Erro ao carregar os dados dos contadores de conteúdo.';
        });
    }

    loadCourses() {
        this.courseService.list().pipe(first())
        .subscribe((response: any) => {
            if (response.typeResponse == TypeResponse.Success) {
                this.gridList = response.data;
                this.designCourseType(this.gridList);
                this.showTable = true;
                this.returnMessage = '';
            } else {
                this.returnMessage = response.message;
                this.showTable = false;
            }
        }, (error: any) => {
            this.showTable = false;
            this.returnMessage = 'Erro ao carregar os dados dos cursos.';
        });
    }

    designCourseType(lista: any[]) {
        lista.forEach(function(e) {
            if (e.courseType >= 0) {
                let courseTypeCss = CourseTypeCss.get(e.courseType);
                let courseTypeStr = CourseTypeLabel.get(e.courseType)
                e.designCourseType = "<span class='badge badge-pill w-100 "
                    + courseTypeCss +"'>"
                    + courseTypeStr +"</span>";
                }
        });
        this.gridList = lista;
    }

    addStudentCourse(row: any, content: any) {
        this.courseId = row.id;
        this.modalService.dismissAll();
        this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    manageStudents(row: any) {
        this.courseId = row.id;
        this.router.navigate(['/admin/studentCourselist', { courseId: this.courseId }]);
    }

    loadContacts(statusCrm: string) {
        this.router.navigate(['/marketing/contactbysitelist', { statusCrm: statusCrm }]);
    }

    showToaster(){
        this.toastr.success("Hello, I'm the toastr message.")
    }

    private getDismissReason(reason: any): string {
        if (reason === 3) {
          return 'by opening next modal';
        } else {
          console.log(reason);
          if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else if (reason === 2) {
            return 'by clicking on a close button';
          } else {
            return  `with: ${reason}`;
          }
        }
    }

    buildChartHeader() {
        var chartPlanned_Scheduled = 'chartPlanned_Scheduled';
        // And for a doughnut chart
        var myDoughnut_chartPlanned_Scheduled = new Chart(chartPlanned_Scheduled, {
            type: 'doughnut',
            data: {
                datasets: [{
                    label: '# aulas',
                    data: [70.07, 29.93],
                    backgroundColor: [
                        'rgba(47,125,213, 1)',
                        'rgba(111,165,226, 0.5)'
                    ],
                    borderWidth: 0
                }]
            }
        });

        var chartPlanned_Done = 'chartPlanned_Done';
        // And for a doughnut chart
        var myDoughnut_chartPlanned_Done = new Chart(chartPlanned_Done, {
            type: 'doughnut',
            data: {
                datasets: [{
                    label: '# aulas',
                    data: [6.22, 93.78],
                    backgroundColor: [
                        'rgba(40,139,168, 1)',
                        'rgba(147,197,211, 0.5)'
                    ],
                    borderWidth: 0
                }]
            }
        });

        var chartPlanned_ChangedTeacher = 'chartPlanned_ChangedTeacher';
        // And for a doughnut chart
        var myDoughnut_chartPlanned_ChangedTeacher = new Chart(chartPlanned_ChangedTeacher, {
            type: 'doughnut',
            data: {
                datasets: [{
                    label: '# aulas',
                    data: [0.17, 99.83],
                    backgroundColor: [
                        'rgba(136,106,181, 1)',
                        'rgba(195,180,218, 0.5)'
                    ],
                    borderWidth: 0
                }]
            }
        });

        var chartPlanned_Reschedule = 'chartPlanned_Reschedule';
        // And for a doughnut chart
        var myDoughnut_chartPlanned_Reschedule = new Chart(chartPlanned_Reschedule, {
            type: 'doughnut',
            data: {
                datasets: [{
                    label: '# aulas',
                    data: [0.66, 99.34],
                    backgroundColor: [
                        'rgba(255,194,65, 1)',
                        'rgba(255,224,160, 0.5)'
                    ],
                    borderWidth: 0
                }]
            }
        });

        var chartPlanned_PartialConfirmed = 'chartPlanned_PartialConfirmed';
        // And for a doughnut chart
        var myDoughnut_chartPlanned_PartialConfirmed = new Chart(chartPlanned_PartialConfirmed, {
            type: 'doughnut',
            data: {
                datasets: [{
                    label: '# aulas',
                    data: [0.66, 99.34],
                    backgroundColor: [
                        'rgba(100,150,129, 1)',
                        'rgba(145,182,166, 0.5)'
                    ],
                    borderWidth: 0
                }]
            }
        });

        var chartPlanned_Unchecked = 'chartPlanned_Unchecked';
        // And for a doughnut chart
        var myDoughnut_chartPlanned_Unchecked = new Chart(chartPlanned_Unchecked, {
            type: 'doughnut',
            data: {
                datasets: [{
                    label: '# aulas',
                    data: [0.66, 99.34],
                    backgroundColor: [
                        'rgba(159,176,175, 1)',
                        'rgba(201,211,210, 0.5)'
                    ],
                    borderWidth: 0
                }]
            }
        });

        var chartAulasProfessores = new Chart('chartAulasProfessores', {
            type: 'doughnut',
            data: {
                datasets: [{
                    label: '# session',
                    data: [
                        3646,
                        1714,
                        1642,
                        1556,
                        1358,
                        1310,
                        1288,
                        1077,
                        835,
                        706,
                        667,
                        589,
                        320,
                        276,
                        247,
                        246,
                        160,
                        158,
                        121,
                        68,
                        68,
                        34,
                        4                        
                    ],
                    backgroundColor: [
                        '#017F8D',
                        '#6A4C93',
                        '#8B2449',
                        '#78909C',
                        '#00A6A6',
                        '#B4D9E7',
                        '#B19CD9',
                        '#444B54',
                        '#F3B0C3',
                        '#C0C0C0',
                        '#98FF98',
                        '#87CEEB',
                        '#E6E6FA',
                        '#708090',
                        '#556B2F',
                        '#6D9BF1',
                        '#8A2BE2',
                        '#4682B4',
                        '#6A5ACD',
                        '#C5C3C6',
                        '#2E8B57',
                        '#6A5ACD',
                        '#C0C0C0'
                    ],
                    borderColor: [
                        '#017F8D',
                        '#6A4C93',
                        '#8B2449',
                        '#78909C',
                        '#00A6A6',
                        '#B4D9E7',
                        '#B19CD9',
                        '#444B54',
                        '#F3B0C3',
                        '#C0C0C0',
                        '#98FF98',
                        '#87CEEB',
                        '#E6E6FA',
                        '#708090',
                        '#556B2F',
                        '#6D9BF1',
                        '#8A2BE2',
                        '#4682B4',
                        '#6A5ACD',
                        '#C5C3C6',
                        '#2E8B57',
                        '#6A5ACD',
                        '#C0C0C0'
                    ],
                    borderWidth: 1
                }],
                labels: [
                    'Mateus Gimenez Leal (3646 aulas)',
                    'Laura Santo (1714 aulas)',
                    'Lucas Pascoal (1642 aulas)',
                    'Giancarlo Soncini (1556 aulas)',
                    'Ezequiel Fernandes (1358 aulas)',
                    'Thais Galeano (1310 aulas)',
                    'Leonardo Kempe (1288 aulas)',
                    'Caroline Bianchi (1077 aulas)',
                    'João Victor Silva Brito (835 aulas)',
                    'Gabriel Bueno  (706 aulas)',
                    'Helen Cristina Nascimento (667 aulas)',
                    'Leticia Gomes (589 aulas)',
                    'Heloísa Quintana  (320 aulas)',
                    'Melissa Freitas (276 aulas)',
                    'Elis Ribeiro Lemos de Pádua (247 aulas)',
                    'Luisa Andrade (246 aulas)',
                    'Tamiris de Oliveira C. de Araujo (160 aulas)',
                    'Ezequiel C Fernandes Teacher 7 (158 aulas)',
                    'Jéssica Tinti Bozzi (121 aulas)',
                    'Giulliano Alvaro Andrade Pinheiro (68 aulas)',
                    'Mayara Paz (68 aulas)',
                    'Leticia Hee (34 aulas)',
                    'Laura Cecilia (4 aulas)',
                ]
            },
            options:
            {
                responsive: true,
                maintainAspectRatio: false,
                legend:
                {
                    display: true,
                    position: 'right',
                }
            }
        });

        var chartTargetProfit = new Chart('chartTargetProfit', {
            type: 'bar',
            data: {
                labels: ['01/08/2023', '02/08/2023', '03/08/2023', '04/08/2023', '05/08/2023', '06/08/2023', '07/08/2023', '08/08/2023', '09/08/2023', '10/08/2023', '11/08/2023', '12/08/2023', '13/08/2023', '14/08/2023', '15/08/2023', '16/08/2023', '17/08/2023', '18/08/2023', '20/08/2023', '21/08/2023', '22/08/2023', '25/08/2023', '27/08/2023', '28/08/2023', '30/08/2023'],
                datasets: [{
                    backgroundColor: '#05b3c5',
                    borderColor: '#05b3c5',
                    //barPercentage: 0.5,
                    //barThickness: 6,
                    maxBarThickness: 30,
                    minBarLength: 6,
                    data: [10124.44, 538.00, 2855.81, 1928.50, 29497.07, 852.50, 389.00, 2200.24, 927.03, 71733.12, 2242.00, 2661.00, 269.00, 269.00, 17418.76, 1953.53, 893.00, 969.20, 17229.52, 3256.78, 807.02, 3334.50, 3543.50, 269.00, 624.00], label: 'Novos visitantes'
                }, {
                    backgroundColor: '#05c578',
                    borderColor: '#05c578',
                    //barPercentage: 0.5,
                    //barThickness: 6,
                    maxBarThickness: 30,
                    minBarLength: 6,
                    data: [28, 48, 40, 19, 86, 27, 90], label: 'Novos estudantes'
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false
            }
        });

        var chartMarketingReturning = new Chart('chartMarketingReturning', {
            type: 'line',
            data: {
                labels: ['01/08/2023', '02/08/2023', '03/08/2023', '04/08/2023', '05/08/2023', '06/08/2023', '07/08/2023', '08/08/2023', '09/08/2023', '10/08/2023', '11/08/2023', '12/08/2023', '13/08/2023', '14/08/2023', '15/08/2023', '16/08/2023', '17/08/2023', '18/08/2023', '20/08/2023', '21/08/2023', '22/08/2023', '25/08/2023', '27/08/2023', '28/08/2023', '30/08/2023'],
                datasets: [
                {
                    label: "Mensalidades recebidas",
                    backgroundColor: 'rgba(3,111,122, 0.2)',
                    borderColor: '#05B3C5',
                    pointBackgroundColor: '#05B3C5',
                    pointBorderColor: 'rgba(0, 0, 0, 0)',
                    pointBorderWidth: 1,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHoverRadius: 4,
                    data: [10124.44, 538.00, 2855.81, 1928.50, 29497.07, 852.50, 389.00, 2200.24, 927.03, 71733.12, 2242.00, 2661.00, 269.00, 269.00, 17418.76, 1953.53, 893.00, 969.20, 17229.52, 3256.78, 807.02, 3334.50, 3543.50, 269.00, 624.00],
                    fill: true
                },
                {
                    label: "Mensalidades planejadas",
                    backgroundColor: 'rgba(120,5,196, 0.2)',
                    borderColor: '#A11DF9',
                    pointBackgroundColor: '#A11DF9',
                    pointBorderColor: 'rgba(0, 0, 0, 0)',
                    pointBorderWidth: 1,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHoverRadius: 4,
                    data: [8819.00, 928.00, 3027.00, 911.00, 22736.00, 509.00, 75.00, 3770.00, 523.00, 93740.00, 1291.00, 1103.00, 355.00, 384.00, 10979.00, 1459.00, 823.00, 842.00, 19296.00, 5507.00, 1103.00, 179.00, 5946.00, 280.00, 735.00],
                    fill: true
                }]
            },
            options:
            {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: false,
                    text: 'Area Chart'
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: false,
                                labelString: '6 months forecast'
                            },
                            gridLines: {
                                display: true,
                                color: 'rgba(242,242,242, 0.2)',
                            },
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: false,
                                labelString: 'Profit margin (approx)'
                            },
                            gridLines: {
                                display: true,
                                color: 'rgba(242,242,242, 0.2)',
                            },
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    ]
                }
            }
        });
    }

    loadContentChart(dashboardContentCount: any) {
        let colorsContentSeries: string[] = [];
        let contentSerieData = dashboardContentCount?.contentSeries?.map((r: any) => r.count);
        let contentSerieLabels = dashboardContentCount?.contentSeries?.map((r: any) => r.serie?.name + `(${r.count})`);

        let colorsPalette = this.utilService.generateChartColors(dashboardContentCount?.contentSeries?.length);
        for (let i = 0; i < colorsPalette.length; i++) {
            colorsContentSeries.push(colorsPalette[i % colorsPalette.length]);
        }

        var chartContentSeriesObj = new Chart('chartContentSeries', {
            type: 'doughnut',
            data: {
                datasets: [{
                    label: '# session',
                    data: contentSerieData,
                    backgroundColor: colorsContentSeries,
                    borderColor: colorsContentSeries,
                    borderWidth: 1
                }],
                labels: contentSerieLabels
            },
            options:
            {
                responsive: true,
                maintainAspectRatio: false,
                legend:
                {
                    display: true,
                    position: 'right',
                },
                plugins: {
                    datalabels: {
                        formatter: (value, ctx) => (ctx.chart.data.labels[ctx.dataIndex]),
                        color: '#fff',
                    }
                },
                animation: {
                    duration: 500,
                    easing: "easeOutQuart",
                    onComplete: function () {
                      var ctx = this.chart.ctx;
                      ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
                      ctx.textAlign = 'center';
                      ctx.textBaseline = 'bottom';
                
                      this.data.datasets.forEach(function (dataset) {
                
                        for (var i = 0; i < dataset.data.length; i++) {
                          var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                              total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                              mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                              start_angle = model.startAngle,
                              end_angle = model.endAngle,
                              mid_angle = start_angle + (end_angle - start_angle)/2;
                
                          var x = mid_radius * Math.cos(mid_angle);
                          var y = mid_radius * Math.sin(mid_angle);
                
                          ctx.fillStyle = '#fff';
                          if (i == 3){ // Darker text color for lighter background
                            ctx.fillStyle = '#444';
                          }
                          var percent = String(Math.round(dataset.data[i]/total*100)) + "%";
                          ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                          // Display percent in another line, line break doesn't work for fillText
                          ctx.fillText(percent, model.x + x, model.y + y + 15);
                        }
                      });               
                    }
                  }
            }
        });

        let colorsContentEpisodes: string[] = [];
        let episodesTop20 = dashboardContentCount?.contentEpisodes?.sort(function(a,b) {
            return b.count - a.count
        });
        episodesTop20 = episodesTop20.slice(0,20);

        let contentEpisodesData = episodesTop20?.map((r: any) => r.count);
        let contentEpisodesLabels = episodesTop20?.map((r: any) => r.episode?.name + `(${r.count})`);

        colorsPalette = this.utilService.generateChartColors(episodesTop20?.length);
        for (let i = 0; i < colorsPalette.length; i++) {
            colorsContentEpisodes.push(colorsPalette[i % colorsPalette.length]);
        }

        var chartContentEpisodesObj = new Chart('chartContentEpisodes', {
            type: 'bar',
            data: {
                labels: contentEpisodesLabels,
                datasets: [
                {
                    label: "Episódios Visualizados",
                    backgroundColor: 'rgba(120,5,196, 0.2)',
                    borderColor: '#A11DF9',
                    pointBackgroundColor: '#A11DF9',
                    pointBorderColor: 'rgba(0, 0, 0, 0)',
                    pointBorderWidth: 1,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHoverRadius: 4,
                    data: contentEpisodesData,
                    fill: true
                }]
            },
            options:
            {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: false,
                    text: 'Area Chart'
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: false,
                                labelString: '6 months forecast'
                            },
                            gridLines: {
                                display: true,
                                color: 'rgba(242,242,242, 0.2)',
                            },
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                                display: false,
                                labelString: 'Profit margin (approx)'
                            },
                            gridLines: {
                                display: true,
                                color: 'rgba(242,242,242, 0.2)',
                            },
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    ]
                }
            }
        });
    }
    
    logout() {
        this.loading.showLoading();
        this.accountService.logout();
        this.loading.hideLoading();
    }

    canSeeHome() {
        return this.checkHavePermission('ADM1DA1DA1');
    }

    checkHavePermission(role: any): boolean {
        if (role && this.userRoles != null && this.userRoles.length > 0) {
            return this.userRoles.some(x => x.keyDefault == role);
        } else {
            return false;
        }
    }

    public loadHubSpot() {
        if (!this.checkHavePermission('ADM0AT23CH40'))
            return;

        let node = document.createElement('script');
        node.src = this.src_script;
        node.id = 'hs-script-loader';
        node.type = 'text/javascript';
        node.async = true;
        node.defer = true;
        document.getElementsByTagName('head')[0].appendChild(node);
    }
}

/*
public colors = ['#FD1F5E','#1EF9A1','#7FFD1F','#68F000'];

// public colors = [{ 
//     fillColor: 'rgba(59, 89, 152,0.2)',
//     strokeColor: 'rgba(59, 89, 152,1)',
//     pointColor: 'rgba(59, 89, 152,1)',
//     pointStrokeColor: '#fff',
//     pointHighlightFill: '#fff',
//     pointHighlightStroke: 'rgba(59, 89, 152,0.8)'
// }];

// Chart
//public doughnutChartLabels = ['Sales Q1', 'Sales Q2', 'Sales Q3', 'Sales Q4'];
public doughnutChartData = [120, 150, 180, 90];
public doughnutChartColor = ['#FD1F5E','#1EF9A1','#7FFD1F','#68F000'];
public doughnutChartType = 'doughnut';

//public doughnutChartLabels2 = ['Sales Q1', 'Sales Q2', 'Sales Q3', 'Sales Q4'];
public doughnutChartData2 = [120, 150, 180, 90];
public doughnutChartColor2 = ['#FD1F5E','#1EF9A1','#7FFD1F','#68F000'];
public doughnutChartType2 = 'doughnut';

var myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }
});*/