import { ModuleType, ModuleTypeLabel, ModuleTypeLabelEn } from '@app/shared/enum/ModuleType';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';
import { User, Register, TokenData, ResponseResult } from '@app/auth/model';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { Confirmation } from '../model/confirmation';
import { Forget } from '@app/auth/model/forget';
import { ResetPassword } from '../model/resetPassword';
import jwt_decode from "jwt-decode";
import { LocalService } from './local.service';
import { ChangePassword } from '../model/changePassword';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Guid } from 'guid-typescript';
import { OnStatus } from '@app/shared/enum/OnStatus';

@Injectable({ providedIn: 'root' })
export class AccountService {
    jwtPayload: any;
    private readonly JWT_TOKEN = 'app.englishcoffee.token';
    private readonly USER_ROLES = 'app.englishcoffee.userRoles';

    constructor(
        private router: Router,
        private http: HttpClient,
        private toastr: ToastrService,
        private tokenService: LocalService
    ) {}

    private isLoggedIn(): boolean {
        let tkData: TokenData = this.getJwtTokenData();
        if (tkData)
            return true;

        this.removeToken();
        return false;
    }

    public accessIsValid(): boolean {
        return this.isLoggedIn() && this.accessValidAndNotExpiration();
    }

    public userHaveAccess(module: number, role: any): boolean {
        if (module != null && module >= 0) {
            var enumModule: ModuleType = (<any>ModuleType)[module];
            if (enumModule) {
                var user = this.getUserDataFull();
                if (role && user.userRoles != null) {
                    return (user.module == enumModule.toString()) &&
                        (user.userRoles as Array<any>).some(x => x.KeyDefault == role);
                } else {
                    return user.module == enumModule.toString();
                }
            }
        }
        this.toastr.warning("Você não possui permissão para acessar essa área!", "Acesso Negado!");
        return false;
    }

    public redirectToModule(authenticated: boolean) {
        return this.router.navigate([this.getUrlByModule(authenticated)]);
    }

    private accessValidAndNotExpiration(): boolean {
        let tkData: TokenData = this.getJwtTokenData();

        // Check token expiration
        let expirationDate = parseInt(tkData.exp);
        let todayDate = Math.floor(new Date().getTime()/1000);
        
        if (expirationDate >= todayDate)
            return true;
        
        return false;
    }

    setStatus(userId: string, newStatus: OnStatus) {
        var call = this.http.get<ResponseResult>(`${environment.urlAPI}/auth/account/status/${userId}/status/${newStatus}`);
        return call.pipe(map(response => {
            if (response.typeResponse != TypeResponse.Success) {
                return response.data;
            }
            return null;
        }, () => {
            return null;
        }));
    }

    private getUrlByModule(authenticated: boolean): String {
        var user = this.getUserDataFull();
        return authenticated ? `/${user.module.toLowerCase()}/home` : '/auth/login'
    }
    
    authenticated(userid: string) {
        let params = new HttpParams();
        params = params.append('userid', userid);
        var call = this.http.get<ResponseResult>(`${environment.urlAPI}/auth/account/authenticated`, {params: params});

        return call.pipe(map(response => {
            return response.typeResponse != TypeResponse.Success
        }, () => {
            return false;
        }));
    }

    login(username: any, password: any, rememberme: boolean, ipAddress: string) {
        var call = this.http.post<ResponseResult>(`${environment.urlAPI}/auth/account/authenticate`, { 
            "Username": username,
            "Password": password,
            "RememberMe": rememberme,
            "IpAddress": ipAddress,
            "GrantType": "password"
        });

        return call.pipe(map(response => {
            this.removeToken();
            if (response.typeResponse != TypeResponse.Success) {
                return { 
                    authenticated: false,
                    message: response.message,
                    route: this.getUrlByModule(false) 
                };
            } else {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.storeJwtToken(response.data, rememberme);
                return { 
                    authenticated: true,
                    message: null,
                    route: this.getUrlByModule(true)
                };
            }
        }, (error: any) => {
            console.log(error);
            return { authenticated: false };
        }), catchError(error => of(error)));
    }

    logout() {
        if (this.isLoggedIn()) {
            let refreshToken = this.getTokenRefresh();
            let tkData: TokenData = this.getJwtTokenData();
            if (tkData && refreshToken) {
                var call = this.http.post<ResponseResult>(`${environment.urlAPI}/auth/account/logout`, { 
                    "Username": tkData.email,
                    "RefreshToken": refreshToken
                });
                call.subscribe(response => {
                    if (response.typeResponse == TypeResponse.Success) {
                        this.removeToken();
                        this.router.navigate(['auth/login']);
                    }
                    return;
                }, error => {
                    console.log(error);
                    this.router.navigate(['auth/login']);
                    //return { authenticated: false };
                }), catchError(error => of(error));
            }
        } else {
            this.router.navigate(['auth/login']);
        }
    }

    register(register: Register) {
        var call = this.http.post<ResponseResult>(`${environment.urlAPI}/auth/account/register`, register);

        return call.pipe(map((response: any) => {
            return response;
        }),
        catchError(error => {
            if (error.status == 400) {
                if (error.error) {
                    return Observable.of(error.error);
                }
            } else if (error.status == 404) {
                if (error.error) {
                    return Observable.of(error.error);
                }
            }
            return error;
        }));
    }

    refreshToken(username: any, hash: any, token: any) {
        var call = this.http.post<ResponseResult>(`${environment.urlAPI}/auth/account/authenticate`, { 
            "Username": username,
            "Password": hash,
            "RefreshToken": token,
            "GrantType": "refresh_token"
        });

        return call.pipe(map(response => {
            if (response.typeResponse != TypeResponse.Success) {
                this.removeToken();
                return { 
                    authenticated: false, 
                    message: response.message
                };
            } else {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.storeJwtToken(response.data, true);
                return { 
                    authenticated: true,
                    message: null
                };
            }
        }, (error: any) => {
            console.log(error);
            this.logout();
            return;
        }));
    }
    
    confirmRegister(confirmation: Confirmation) {
        var call = this.http.post<ResponseResult>(`${environment.urlAPI}/auth/account/confirmation`, confirmation);
        return call.subscribe(response => {
            if (response.typeResponse == TypeResponse.Success) {
                return response;
            } else {
                return null;
            }
        });
    }

    checkResetPassword(resetPassword: ResetPassword) {
        let params = new HttpParams();
        params = params.append('userid', resetPassword.userid);
        params = params.append('code', resetPassword.code);
        return this.http.get<ResponseResult>(`${environment.urlAPI}/auth/account/checkResetPassword`, {params: params});
    }

    resetPassword(password: any, confirmPassword: any, email: any, userId: any, code: any) {
        var call = this.http.post<ResponseResult>(`${environment.urlAPI}/auth/account/resetpassword`, { 
            "Password": password,
            "ConfirmPassword": confirmPassword,
            "Email": email,
            "UserId": userId,
            "Code": code
        });

        return call.pipe(map(response => {
            if (response.typeResponse != TypeResponse.Success) {
                this.removeToken();
                return { 
                    resetSuccess: false,
                    data: response.data,
                    message: response.message
                };
            } else {
                return { 
                    resetSuccess: true,
                    message: ''
                };
            }
        }, (error: any) => {
            console.log(error);
            return { authenticated: false };
        }), catchError(error => of(error)));
    }

    forgetPassword(forget: Forget) {
        var call = this.http.post<ResponseResult>(`${environment.urlAPI}/auth/account/forget`, forget);

        return call.pipe(map(response => {
            if (response.typeResponse != TypeResponse.Success) {
                this.removeToken();
                return { 
                    resetSuccess: false,
                    message: response.message
                };
            } else {
                return { 
                    resetSuccess: true,
                    message: ''
                };
            }
        }, (error: any) => {
            console.log(error);
            return { authenticated: false };
        }), catchError(error => of(error)));
    }

    changePassword(changePassword: ChangePassword) {
        var call = this.http.post<ResponseResult>(`${environment.urlAPI}/auth/account/changepassword`, changePassword);

        return call.pipe(map(response => {
            return response;
        }, (error: any) => {
            console.log(error);
            return { 
                resetSuccess: false,
                message: error
            };
        }), catchError(error => of(error)));
    }

    getRolesByUser(): Array<any> {
        if (this.isLoggedIn()) {
            var dkUserRoles = this.tokenService.getValue(this.USER_ROLES);
            if (dkUserRoles) {
                return (JSON.parse(dkUserRoles) as Array<any>);
            } else {
                this.logout();
            }
        }
        return null;
    }

    public userRolesStore() {
        let user = this.getUserData();

        let params = new HttpParams();
        params = params.append('userid', user.userId);
        var call = this.http.get<ResponseResult>(`${environment.urlAPI}/auth/account/getUserRoles`, {params: params});

        return call.pipe(map(response => {
            if (response.typeResponse == TypeResponse.Success) {
                let userRoles = (response.data.userRoles as Array<any>);
                if (userRoles) {
                    this.setTokenUserRoles(JSON.stringify(userRoles));
                    return userRoles;
                }
            }
            this.setTokenUserRoles(null);
            return null;
        }, (error: any) => {
            this.setTokenUserRoles(null);
            return null;
        }));
    }

    public getJwtTokenString(): string {
        var token = this.getJwtTokenData();
        if (token)
          return token.accessToken
        return null;
    }

    // JWT Token
    private getJwtTokenData(): TokenData {
        // Get the user data
        var lsToken = this.tokenService.getValue(this.JWT_TOKEN);
        if (lsToken) {
            var token: any = jwt_decode(lsToken);
            token.accessToken = lsToken;
            return token;
        }
        return null;
    }

    private async storeJwtToken(jwt: string, keepingtoken: boolean) {
        var decoded: any = jwt_decode(jwt);
        decoded.accessToken = jwt;
        // Set the User data
        this.tokenService.setValue(this.JWT_TOKEN, jwt, keepingtoken);
    }

    private setTokenUserRoles(userRoles: string) {
        if (userRoles) {
            this.tokenService.setValue(this.USER_ROLES, userRoles, true);
        } else {
            this.tokenService.setValue(this.USER_ROLES, null, true);
        }
    }

    private removeToken() {
        // Clear the localStorage
        this.tokenService.clearStorage();
    }
    
    // Refresh Token
    private getTokenRefresh(): string {
        let token = this.getJwtTokenData();
        if (token)
            return token.jti;
    
        return null;
    }

    public userHaveRole(roleKeyDefault: any) {
        if (roleKeyDefault) {
            let userRoles = this.getRolesByUser();
            if (userRoles) {
                return userRoles.some(x => x.keyDefault == roleKeyDefault);
            }
        }
        return false;
    }

    public getUserData() {
        return this.getUserDataFull();
    }

    private getUserDataFull(): User {
        var user = new User();
        if (this.isLoggedIn()) {
            var token = this.getJwtTokenData();
            if (token) {
                user.name = token.given_name; //ok
                user.username = token.email; //ok
                user.id = parseInt(token.sid); //ok
                user.userId = token.at_hash; //ok
                //user.userId = token.rsa; //ok
                user.city = "";
                user.state = "";
                user.password = "";
                user.refreshToken = token.jti; //ok

                let role = token["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]; //ok
                if (role) {
                    user.role = role;
                }

                let groupsid = token["http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid"]; //ok
                if (groupsid) {
                    user.module = groupsid;
                    user.primarysid = groupsid;
                }

                user.userKids = token.nonce == 'True'; //ok
            }
        }
        return user;
    }

    setTokenUpdate(moduleType: ModuleType, valueUserId: Number) {
        if (moduleType != null && valueUserId != null) {
            var currentTimeInMilliseconds = Math.floor(new Date().getTime()/1000); // unix timestamp in milliseconds
            let moduleTypeStr = ModuleTypeLabelEn.get(moduleType);
            var iUpdate: any = {
                valueUserId,
                dtExpiration: currentTimeInMilliseconds
            }
            let keyValueToken = (`update_user_${moduleTypeStr}_${Guid.create()}`).toLowerCase();
            this.tokenService.setValue(keyValueToken, JSON.stringify(iUpdate), true);
            return keyValueToken;
        }
        return null;
    }

    checkValidUpdate(moduleType: ModuleType, keyValueToken: string) {
        if (moduleType != null && keyValueToken != null) {
            let tokenData = this.tokenService.getValue(keyValueToken);
            if (tokenData != null) {
                let iUpdate = JSON.parse(tokenData) as any;
                let expDate = Math.floor(iUpdate.dtExpiration);
                let todayDate = Math.floor(new Date().getTime()/1000);
                console.log(expDate);
                console.log(todayDate);
                if (expDate >= todayDate)
                    return null;
                
                return iUpdate.valueUserId;
            }
        }
        return null;
    }
}
