export enum FinancialType {
  Debit = 0,
  Credit = 1
}

export const FinancialTypeLabel = new Map<number, string>([
  [FinancialType.Debit, 'Débito'],
  [FinancialType.Credit, 'Crédito']
]);

export const FinancialTypeLabelEn = new Map<number, string>([
  [FinancialType.Debit, 'debit'],
  [FinancialType.Credit, 'credit']
]);

export const FinancialTypeCss = new Map<number, string>([
  [FinancialType.Debit, 'financial-type-debit'],
  [FinancialType.Credit, 'financial-type-credit']
]);
