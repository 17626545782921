import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { default as swal } from 'sweetalert2';
import { TransactionService } from '@app/financial/services';
import { MessageService } from '@app/shared/services';
import { FinancialType, FinancialTypeCss, FinancialTypeLabel } from '@app/shared/enum';

@Component({ templateUrl: 'transaction-list.component.html', providers: [DatePipe] })
export class TransactionListComponent implements OnInit {
  //@ViewChild('mdContactPreRegister') mdContactPreRegister: any;
  public transactions: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  closeResult: string;
  contactId: number;

  public columns: Array<any> = [
    { title: "Código", name: "id", sort: "asc", className: "text-center" },
    { title: "Descrição", name: "description", sort: "asc" },
    { title: "Operação", name: "designFinancialType", sort: "asc", className: "text-center fw-700", isHtmlData: true },
    { title: "Provedor", name: "financialProvider", sort: "asc", className: "text-center" },
    {
      title: "Emissão",
      name: "issuance",
      sort: "asc",
      className: "text-center text-primary datetime"
    },
    { 
      title: "Valor",
      name: "totalValue",
      className: "text-center text-primary currency fw-700",
      sort: "asc"
    },
    {
      title: "Criado em",
      name: "createdOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    },
    {
      title: "Modificado em",
      name: "updatedOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    }
  ];

  constructor(
    private transactionService: TransactionService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadTransactions();
  }

  loadTransactions() {
    this.loading.showLoading();
    this.transactionService.getByFinancialType(FinancialType.Debit).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.designType(response.data);
        this.showTable = true;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
        this.showTable = false;
      }
      this.loading.hideLoading();
    }, (error: any) => {
      this.loading.hideLoading();
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar os dados dos contatos.';
    });
  }

  designType(lista: any[]) {
    lista.forEach(function(e) {
      if (e.financialType >= 0) {
        let financialTypeStr = FinancialTypeLabel.get(e.financialType);
        let financialTypeCss = FinancialTypeCss.get(e.financialType);
        e.designFinancialType = "<span class='badge w-100 "
          + financialTypeCss +" badge-pill'>"
          + financialTypeStr +"</span>";
      }
    });
    this.transactions = lista;
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();
  
      console.log(reason);
  
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }

  edit(row:any): void {
    this.loading.showLoading();
    this.router.navigate(['/financial/transaction', { id: row.id, edit: true }]);
  }

  preview(row:any): void {
    this.loading.showLoading();
    this.router.navigate(['/financial/transaction', { id: row.id, preview: true }]);
  }

  delete(target: any): void {
    swal({
      title: "Exclusão da transação",
      html: 'Confirma a exclusão da transação <strong>'+target.name+'</strong>?<br>' +
        'Ao confirmar a transação será excluída permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir'
    }).then(willDelete => {
      if (willDelete.value === true) {
        this.transactionService.delete(target.id)
        .subscribe(response => {
          if ("typeResponse" in response && response["typeResponse"] === TypeResponse.Success) {
            swal({
              type: 'success',
              title: 'Registro excluído com sucesso!',
              showConfirmButton: false,
              timer: 1500
            }).then((result) => {
              this.loadTransactions();
            });
          } else if ("typeResponse" in response && response["typeResponse"] === TypeResponse.Alert) {
            swal({
              type: 'warning',
              title: 'Não foi possível excluir a transação!',
              text: response.message,
              showConfirmButton: true,
              //timer: 1500
            }).then((result) => {
            });
          } else if ("typeResponse" in response && response["typeResponse"] === TypeResponse.Error) {
            swal({
              type: 'error',
              title: 'Erro ao tentar excluir a transação.',
              text: response.message,
              showConfirmButton: true,
              //timer: 1500
            }).then((result) => {
              this.loadTransactions();
            });
          }
        }, error => {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir a transação.',
            showConfirmButton: false,
            timer: 1500
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        });
      }
    },
    error => {// expiração do token
      if (error.error.error === 'invalid_token') {
        this.router.navigate(['/auth/login']);         
      } else {
        swal({
          type: 'error',
          title: 'Erro ao tentar excluir a transação.',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          this.loading.hideLoading();
          window.location.reload();
        });
      }
    });
  }

  onAuxClick(row: any, content: any) {
    this.contactId = row.id;
    this.modalService.dismissAll();
    this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open(content: any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
