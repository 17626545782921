import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public isloading = new BehaviorSubject<boolean>(false);

  showLoading() {
    this.isloading.next(true);
  }

  hideLoading() {
    this.isloading.next(false);
  }

  displayHubSpot(show: boolean = true) {
    return;
    if (document.getElementById('hubspot-messages-iframe-container')) {
      document.getElementById('hubspot-messages-iframe-container').classList.remove('invisible');
      document.getElementById('hubspot-messages-iframe-container').classList.remove('visible');
      document.getElementById('hubspot-messages-iframe-container').classList.add(show ? 'visible' : 'invisible');
    }
  }
}
