<div class="form-group row mb-2">
    <div class="col-12" *ngIf="!showTableActions">
        <div class="alert alert-warning m-2" role="alert">{{errorMessage}}</div>
    </div>
    <div class="col-12" *ngIf="showTableActions">
        <div class="panel" *ngFor="let action of (actionsDataSource$ | async) | paginate : {
            itemsPerPage: pageSize,
            currentPage: page,
            totalItems: actionsCount
        }; let i = index" [class.active]="i == currentIndex">
            <div class="panel-hdr">
                <h2>
                    Usuário: <span class="fw-700"><i>{{action.user?.userName}}</i></span>
                    <span [innerHtml]="action.designModule"></span>
                </h2>
                <div class="panel-toolbar ml-2">
                    <span class="fs-md text-muted mt-1" [innerHtml]="action.designDatetime"></span>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag" [innerHtml]="action.info"></div>
                </div>
                <!-- panel footer with utility classes -->
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <span class="h5">Ação Realizada:</span> <div [innerHtml]="action.designAction"></div>
                    <!-- <span class="h5 ml-auto mr-2">Data Prevista da Aula:</span> <div [innerHtml]="action.designAction"></div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <pagination-controls class="ngx-pagination responsive pagination justify-content-center" 
        previousLabel="Anterior" nextLabel="Próxima" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
<!-- 
    <div class="col-12 hidden-sm-down" *ngIf="showTableActions">
        <div class="table-responsive text-nowrap">
            <table class="table dataTable m-0" role="grid" style="width: 100%;">
                <thead>
                <tr role="row">
                    <th class="text-center text-primary">Aula</th>
                    <th class="text-center text-primary">Data Prevista</th>
                    <th class="text-center text-primary">Módulo</th>
                    <th class="text-center text-primary">Ação</th>
                    <th class="text-center text-primary">Data/hora</th>
                    <th class="text-center text-primary">Usuário</th>
                    <th class="text-center text-primary">Info</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let action of (actionsDataSource$ | async)'>
                        <td class="text-center" [innerHtml]="action.studentClassesCourse?.numberClass"></td>
                        <td class="text-center" [innerHtml]="action.designExpectedDate"></td>
                        <td class="text-center" [innerHtml]="action.designModule"></td>
                        <td class="text-center" [innerHtml]="action.designAction"></td>
                        <td class="text-center" [innerHtml]="action.designDatetime"></td>
                        <td class="text-center text-primary fw-700" [innerHtml]="action.user?.userName"></td>
                        <td class="text-center" [innerHtml]="action.info"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div> -->
</div>