import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { User } from '@app/auth/model';
import { AccountService } from '@app/auth/services';

// Services
import { InitTeacherService } from '@app/teacher/services/init-teacher.service';
import { WritingTestApplyService } from '@app/admin/services';
import { Teacher, WritingTestAppliedPending } from '@app/admin/model';
import { first } from 'rxjs/operators';
import {
  TypeResponse,
  WritingTestReviewCss,
  WritingTestReviewLabel,
  WritingTestStatus,
  WritingTestStatusCss,
  WritingTestStatusLabel,
} from '@app/shared/enum';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '@app/shared/services';
import { BehaviorSubject } from 'rxjs';
import { default as swal } from 'sweetalert2';
import { Router } from '@angular/router';

@Component({ templateUrl: 'home.component.html', providers: [DatePipe] })
export class HomeComponent implements OnInit {
  private src_script = '//js-na1.hs-scripts.com/24078910.js';
  loadAPI: Promise<any>;

  private teacher: Teacher;
  public dateNow = new Date();
  public nextClass = new Date();
  public user: User = new User();
  private userRoles: Array<any>;

  closeResult: string;

  public gridWritingTestPending$ = new BehaviorSubject([]);
  public gridWritingTestPending: WritingTestAppliedPending[];
  public showTableWritingTestPending: boolean = false;
  public errorWritingTestPending: string =
    'Nenhum teste disponível para revisão.';

  public writingTestApplyId: number = 0;
  public writingTestApplyView: boolean = false;

  public auxTitle: string = 'Revisar teste';
  public auxIcon: string = 'fa fa-file-check';

  public auxTitle2: string = 'Disponbilizar novamente';
  public auxIcon2: string = 'fa fa-refresh';

  public columns: Array<any> = [
    {
      title: 'Turma',
      name: 'course.name',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-left text-default fw-700',
    },
    {
      title: 'Aluno',
      name: 'student.name',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-left text-default fw-700',
    },
    {
      title: 'Status<br>do Teste',
      name: 'designWritingTestStatus',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-default',
    },
    {
      title: 'Disponibilizado em',
      name: 'availabilityDate',
      sort: 'asc',
      className: 'text-center text-info datetime',
    },
    {
      title: 'Finalizado em',
      name: 'finishDate',
      sort: 'asc',
      className: 'text-center text-info datetime',
    },
    {
      title: 'Status<br>de Revisão',
      name: 'designWritingTestReview',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-primary',
    },
    {
      title: 'Revisado em',
      name: 'reviewDate',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
    // { title: "Nota", name: "scoreResult", sort: "asc", className: "text-center text-primary fw-700" }
  ];

  constructor(
    private writingTestApplyService: WritingTestApplyService,
    private initTeacher: InitTeacherService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private router: Router,
    private loading: MessageService
  ) {
    this.initTeacher.setConfigPage(false, false);
    this.teacher = this.initTeacher.teacher;
    this.getUserData();
  }

  ngOnInit() {
    this.loading.showLoading();
    this.dateNow = new Date();
    this.nextClass.setDate(
      this.dateNow.getDate() + Math.floor(Math.random() * 7 + 1)
    );
    this.loading.hideLoading();
  }

  private loadChat() {
    this.loadAPI = new Promise((resolve) => {
      //this.loadHubSpot();
    });
  }

  private getUserData() {
    this.initTeacher
      .getTeacherUser()
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.teacher = response;
          this.user = this.initTeacher.user;
          this.userRoles = this.accountService.getRolesByUser();
          this.loadWritingTestAppliedPending(this.teacher?.id ?? 0);
          this.loadChat();
          this.loading.hideLoading();
        },
        (error: any) => {
          this.errorWritingTestPending = 'Erro ao carregar o calendário.';
          this.loading.hideLoading();
        }
      );
  }

  loadWritingTestAppliedPending(teacherId: any) {
    this.writingTestApplyService
      .toReviewByTeacher(teacherId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.designWritingTestApplied(response.data);
            this.showTableWritingTestPending = true;
            this.errorWritingTestPending = '';
          } else {
            this.errorWritingTestPending = response.message;
            this.showTableWritingTestPending = false;
          }
        },
        (error: any) => {
          this.showTableWritingTestPending = false;
          this.errorWritingTestPending =
            'Erro ao carregar os dados dos testes.';
        }
      );
  }

  designWritingTestApplied(lista: any[]) {
    lista.forEach(function (e) {
      if (e.writingTestStatus >= 0) {
        let writingTestStatusCss = WritingTestStatusCss.get(
          e.writingTestStatus
        );
        let writingTestStatusStr = WritingTestStatusLabel.get(
          e.writingTestStatus
        );
        e.designWritingTestStatus =
          "<span class='badge badge-pill w-100 font-weight-bold " +
          writingTestStatusCss +
          "'>" +
          writingTestStatusStr +
          '</span>';
      }

      if (e.writingTestReview >= 0) {
        let writingTestReviewCss = WritingTestReviewCss.get(
          e.writingTestReview
        );
        let writingTestReviewStr = WritingTestReviewLabel.get(
          e.writingTestReview
        );
        e.designWritingTestReview =
          "<span class='badge badge-pill w-100 font-weight-bold " +
          writingTestReviewCss +
          "'>" +
          writingTestReviewStr +
          '</span>';
      }
    });
    this.gridWritingTestPending = lista;
    this.gridWritingTestPending$.next(this.gridWritingTestPending);
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      console.log(reason);
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  writingTestReview(row: any, content: any) {
    this.writingTestApplyId = row.id;
    this.writingTestApplyView = row.writingTestStatus != WritingTestStatus.Done;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  checkHavePermission(role: any): boolean {
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some((x) => x.keyDefault == role);
    } else {
      return false;
    }
  }

  public loadHubSpot() {
    if (!this.checkHavePermission('PRO2AT25CH42')) return;

    let node = document.createElement('script');
    node.src = this.src_script;
    node.id = 'hs-script-loader';
    node.type = 'text/javascript';
    node.async = true;
    node.defer = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  writingTestReset(target: any): void {
    swal({
      title: 'Disponibilizar novamente?',
      html:
        'Deseja disponibilizar novamente o teste para preenchimento do(a) aluno(a) ' +
        '<strong class="fw-700">' +
        target.student.name +
        '</strong>?' +
        '<br><br>' +
        '<small>Ao confirmar esta ação o(a) aluno(a) deverá refazer o teste!</small>',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, disponibilizar novamente',
    }).then(
      (resetConfirm: any) => {
        if (resetConfirm.value === true) {
          this.writingTestApplyService.writingTestReset(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Teste disponibilizado novamente para preenchimento!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.loading.hideLoading();
                  window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title:
                    'Não foi possível disponibilizar novamente o teste selecionado!',
                  text: response.message,
                  showConfirmButton: true,
                  timer: 1500,
                }).then((result) => {
                  this.loading.hideLoading();
                  window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                this.errorResponse(
                  'Erro ao tentar disponibilizar novamente o teste selecionado.'
                );
              }
            },
            (error) => {
              this.errorResponse(
                'Erro ao tentar disponibilizar novamente o teste selecionado.'
              );
            }
          );
        }
      },
      (error) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          this.errorResponse(
            'Erro ao tentar disponibilizar novamente o teste selecionado.'
          );
        }
      }
    );
  }

  deleteWritingTest(target: any): void {
    swal({
      title: 'Excluir Teste Aplicado?',
      html:
        'Confirma a exclusão do teste aplicado ao aluno(a) ' +
        '<strong class="fw-700">' +
        target.student.name +
        '</strong>?' +
        '<br><br>' +
        '<small>Ao confirmar o teste será excluído permanentemente da lista de pendências do teacher e do(a) aluno(a)!</small>',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete: any) => {
        if (willDelete.value === true) {
          this.writingTestApplyService.remove(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.loading.hideLoading();
                  window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir o teste selecionado!',
                  text: response.message,
                  showConfirmButton: true,
                  timer: 1500,
                }).then((result) => {
                  this.loading.hideLoading();
                  window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                this.errorResponse(
                  'Erro ao tentar excluir o teste selecionado.'
                );
              }
            },
            (error) => {
              this.errorResponse('Erro ao tentar excluir o teste selecionado.');
            }
          );
        }
      },
      (error) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          this.errorResponse('Erro ao tentar excluir o teste selecionado.');
        }
      }
    );
  }

  errorResponse(msg: string) {
    swal({
      type: 'error',
      title: msg,
      showConfirmButton: false,
      timer: 1500,
    }).then((result) => {
      this.loading.hideLoading();
      window.location.reload();
    });
  }
}
