import { Component, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import { ToastrService } from 'ngx-toastr';
import { default as swal } from 'sweetalert2'

@Component({
  selector: 'managed-calendar',
  templateUrl: './calendar.component.html'
})
export class CalendarComponent implements OnInit {
  calendarPlugins = [dayGridPlugin]; // important!

  // references the #calendar in the template
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  constructor(private toastr: ToastrService) { }

  ngOnInit(): void { }

  calendarEvents = [
    { title: 'event 1', date: '2019-04-01' }
  ];

  addEvent(newEvent: any) {
    newEvent = { title: 'event 2', date: '2019-04-02' };
    this.calendarEvents.push(newEvent);
  }

  modifyTitle(eventIndex: any, newTitle: any) {
    let calendarEvents = this.calendarEvents.slice(); // a clone
    let singleEvent = Object.assign({}, calendarEvents[eventIndex]); // a clone
    singleEvent.title = newTitle;
    calendarEvents[eventIndex] = singleEvent;
    this.calendarEvents = calendarEvents; // reassign the array
  }

  handleDateClick(arg: any) { // handler method
    this.toastr.show(arg.dateStr, "Evento");
    swal({
      type: 'success',
      title: arg.dateStr,
      showConfirmButton: false
    });
  }

  someMethod() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.next();
  }

  eventRender(e) {
    e.find('div.fc-title').html(e.find('div.fc-title').text()) ;
    //e.el.querySelectorAll('.fc-title')[0].innerHTML = e.el.querySelectorAll('.fc-title')[0].innerText;
  }
}
