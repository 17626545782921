import { InitAdminService, ModuleService } from '@app/admin/services';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertService, MessageService } from '@app/shared/services';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { ModuleType } from '@app/shared/enum/ModuleType';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Module } from '@app/admin/model';
import { default as swal } from 'sweetalert2'
import { Register } from '@app/auth/model';
import { first } from 'rxjs/operators';
import { RoleService, UserService } from '@app/auth/services';
import { environment } from '@environments/environment';
import { StatusCrm, StatusCrmLabel } from '@app/shared/enum';
import { BehaviorSubject } from 'rxjs';

@Component({ templateUrl: 'user.component.html' })
export class UserComponent implements OnInit {
  userForm;
  submitted = false;
  returnUrl: string = '/admin/userlist';
  public validUser: boolean = true;
  public randomPwd: boolean;
  public moduleType: number = 0;
  public flagUserKids: boolean = false;
  public errorMessage = '';
  public listErrorMessage: any[];
  private register: Register;
  public phoneMask: Array<string | RegExp>;
  public cepMask: Array<string | RegExp>;

  public crmControlStatusModel: any;
  public crmControlStatus: boolean = false;
  public crmControlStatusDropdownSettings: any = {};
  public crmControlStatusDropdownList: any[] = [];
  public crmControlStatusSelected: any[] = [];
  public crmControlStatusSelected$ = new BehaviorSubject([]);
  public statusCrmValid: boolean = false;

  public flagForeignPerson: boolean = false;

  public editMode: boolean;
  public moduleList: any = [];
  public roleGroupList: any = [];
  public roleGroupListCount: number = 0;
  public roleGroupSelectedList: any = [];
  public idUser: number;
  private urlConfirmation: string;

  public optionsArray: any = [];
  public selectedValues: Array<string> = [];
  public selectedValuesCount: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private roleService: RoleService,
    private moduleService: ModuleService,
    private alertService: AlertService,
    private initAdmin: InitAdminService,
    private loading: MessageService
  ) { 
    this.phoneMask = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.urlConfirmation = environment.urlConfirmation;
    this.onInitForm();
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/userlist';
    this.randomPwd = true;
    this.getStatusList();
    this.crmControlStatusDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Marcar Todos',
      unSelectAllText: 'Desmarcar Todos',
      searchPlaceholderText: 'Selecionar status permitidos',
      noDataAvailablePlaceholderText: 'Nenhum dado disponível',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.activatedRoute.params.subscribe(params => {
      this.moduleService.getOnlyVisible().subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.moduleList = response.data.sort((a,b) => a.name.localeCompare(b.name));
        } else {
          this.moduleList = new Array<Module>();
        }
      });

      this.idUser = params['id'];
      if (this.idUser > 0) {
        this.editMode = true;
        this.loadUser(this.idUser);
      } else {
        this.editMode = false;
      }
    });
    this.loading.hideLoading();
  }

  getStatusList() {
    const statusList: Array<object> = [];
    for (var value in StatusCrm) {
      if (Number.isInteger(Number.parseInt(value))) {
        statusList.push({
          value: Number.parseInt(value),
          text: StatusCrmLabel.get(Number.parseInt(value))
        });
      }
    }
    this.crmControlStatusDropdownList = this.getTemplateUserList(statusList);
  }

  getTemplateUserList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      let arrayStatusList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].value,
          text: data[i].text
        };
        arrayStatusList.push(element);
      }

      return arrayStatusList.sort(function(a: any, b: any) {
        var textA = a.text.toLowerCase();
        var textB = b.text.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    }
    return new Array<any>();
  }

  change = () => {
    console.log("changed");
  };

  onSelectStatusItem(item: any) {
    if (item?.id && !this.crmControlStatusSelected.some((a: any) => a.id == item?.id)) {
      this.crmControlStatusSelected.push(item);
    }
    this.crmControlStatusSelected$.next(this.crmControlStatusSelected);
  }

  onDeSelectStatusItem(item: any) {
    if (item?.id && this.crmControlStatusSelected.some(a => a.id == item?.id)) {
      let itemFiltered = this.crmControlStatusSelected.find(a => a.id == item?.id);
      let newArray = this.crmControlStatusSelected.filter(e => e.id !== itemFiltered.id);
      this.crmControlStatusSelected = newArray;
    }
    this.crmControlStatusSelected$.next(this.crmControlStatusSelected);
  }

  onSelectAllStatusItems(items: any) {
    if (items?.length > 0) {
      this.crmControlStatusSelected = new Array<any>();
      for (let i = 0; i < items.length; i++) {
        this.crmControlStatusSelected.push(items[i]);
      }
    }
    this.crmControlStatusSelected$.next(this.crmControlStatusSelected);
  }

  onDeSelectAllStatusItems(items: any) {
    if (items?.length > 0 || this.crmControlStatusSelected.length > 0) {
      this.crmControlStatusSelected = new Array<any>();
    }
    this.crmControlStatusSelected$.next(this.crmControlStatusSelected);
  }

  onInitForm() {
    this.validUser = true;
    this.randomPwd = true;
    this.flagUserKids = false;
    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      crmControlStatus: [''],
      password: [''],//, [Validators.required, Validators.minLength(8), , Validators.maxLength(20)]],
      confirmpassword: [''],//, [Validators.required, Validators.minLength(8), , Validators.maxLength(20)]],
      phone: ['', [Validators.required, Validators.minLength(1)]],
      terms: [true, Validators.requiredTrue],
      newsletter: [false],
      randomPwd: [true],
      userKids: [false],
      foreignPerson: [false],
      moduleType: [0, [Validators.required]],
      id: [0],
      userGuid: [''],
      createdOn: [''],
      updatedOn: ['']
    }, {validator: this.checkIfMatchingPasswords('password', 'confirmpassword')});

    this.userForm.controls['foreignPerson'].value = false;
    this.moduleType = 0;
    this.onGetRolesByModuleType(this.moduleType, true);
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];

      if (!this.userService.checkIfMatchingPasswords(passwordInput.value, passwordConfirmationInput.value) && !this.randomPwd) {
        return passwordConfirmationInput.setErrors({notEquivalent: true})
      } else {
          return passwordConfirmationInput.setErrors(null);
      }
    }
  }
  
  checkPhoneValues(input: any) {
    let value = input.value;

    if (this.f.foreignPerson.value === true) {
      var strPhone = value.toString().replace('(', '').replace(')', '').replace('-', '').replace('_', '').replace(' ', '');
      var phoneNumber = parseInt(strPhone);
      if (!(phoneNumber >= 0 && phoneNumber <= 999999999999999)) {
        value = phoneNumber.toString();
        this.f.phone.status = "INVALID";
        return;
      }
    } else {
      var strPhone = value.toString().replace('(', '').replace(')', '').replace('-', '').replace('_', '').replace(' ', '');
      var phoneNumber = parseInt(strPhone);
      if (!(phoneNumber >= 11111111111 && phoneNumber <= 99999999999)) {
        value = phoneNumber.toString();
        this.f.phone.status = "INVALID";
        return;
      }
    }

    this.f.phone.setErrors(null);
    this.f['phone'].value = value;
    this.userForm.value.phone = value;
    if (this.register) {
      this.register.phone = value;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.userForm?.controls ?? {};
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validUser = true;
    this.listErrorMessage = new Array<string>();

    // Errors Cleaning
    this.errorMessage = '';

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.userForm.invalid) {
      this.setMessageError("Confirme os dados e tente novamente.")
      this.submitted = false;
      this.validUser = false;
      this.loading.hideLoading();
      return;
    }

    this.register = this.userForm.value;
    this.register.UrlCallback = this.urlConfirmation;
    if (this.selectedValues && this.selectedValues.length > 0) {
      this.selectedValues = this.onlyRolesToModule(this.selectedValues);
    }

    this.register.roles = this.selectedValues && this.selectedValues.length > 0
      ? this.selectedValues
      : new Array<string>();
    this.selectedValuesCount = this.selectedValues.length;

    if (!this.randomPwd) {
      this.register.password = this.f.password.value;
      this.register.confirmPassword = this.f.confirmpassword.value;
    }
    this.register.moduleType = parseInt(this.register.moduleType.toString());
    this.register.statusControlCrm = this.crmControlStatusSelected.map(s => s.id).join(',');

    this.userService.saveByRegister(this.register, this.editMode)
    .pipe(first()).subscribe((dataReturn: any) => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        swal({
          type: 'success',
          title: this.editMode ? 'Usuário atualizado com sucesso!' : 'Novo usuário criado com sucesso!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          this.validUser = true;
          this.router.navigate([this.returnUrl]);
        });
      } else {
        this.setMessageError(dataReturn.message);
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.setMessageErrorList(dataReturn.data.errors);
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (error: any) => {
      this.validUser = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = error == "OK" ? "Erro ao salvar o usuário(a)." : error;
    });
  }

  onlyRolesToModule(allUserRoles: any[]) {
    let rolesOnlyModule = [];
    for (const element of this.roleGroupList) {
      if (element != null) {
        for (const role of element.roles) {
          if (role != null) {
            rolesOnlyModule.push(role);
          }
        }
      }
    }
    allUserRoles = allUserRoles.filter((role: any) => rolesOnlyModule.some((r: any) => r.name == role));
    return allUserRoles;
  }

  loadUser(id: any) {
    this.loading.showLoading();
    this.userService.getById(id).subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validUser = true;
        if (response.data.roles) {
          this.selectedValues = response.data.roles;
        }
        this.selectedValuesCount = this.selectedValues.length;
        this.setFormByEntity(response.data);
      } else {
        this.validUser = true;
      }
      this.loading.hideLoading();
      }, error => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
      }
    );
  }

  permissionChecked(groupId: any, permission: any) {
    this.setCheckGroup(groupId);
    let checked = this.selectedValues.some(x => x == permission);

    if (permission.includes('CRM'))
      this.crmControlStatus = checked;

    return checked
  }

  setFormByEntity(data: any) {
    this.register = new Register();//.new(response.data);
    this.register.id = data.id;
    this.register.name = data.name;
    this.register.email = data.email;
    this.register.statusControlCrm = data.statusControlCrm;
    this.register.phone = data.phoneNumber;
    this.register.userGuid = data.userGuid;
    this.register.randomPwd = data.randomPwd;
    this.register.moduleType = data.moduleType;
    this.register.password = this.register.getDefaultPassword();
    this.register.confirmPassword = this.register.password;
    this.register.userKids = this.register.moduleType == ModuleType.Student && data.kidsUser;
    this.register.foreignPerson = data.foreignPerson == true;

    if (data.statusControlCrm && data.statusControlCrm.length > 0) {
      const statusValues = data.statusControlCrm.split(',');
      this.crmControlStatusSelected = statusValues.map((id: any) => this.crmControlStatusDropdownList.find((el) => el.id == id));
      this.crmControlStatusSelected$.next(this.crmControlStatusSelected);
    }

    this.userForm = this.formBuilder.group({
      id: new FormControl(this.register.id),
      name: new FormControl(this.register.name),
      password: new FormControl(this.register.password),
      statusControlCrm: new FormControl(this.crmControlStatusSelected.map(s => s.id).join(',')),
      confirmpassword: new FormControl(this.register.confirmPassword),
      email: new FormControl(this.register.email),
      phone: new FormControl(this.register.phone),
      createdOn: new FormControl(data.createdOn),
      updatedOn: new FormControl(data.updatedOn),
      randomPwd: new FormControl(this.register.randomPwd ?? true),
      foreignPerson: new FormControl(this.register.foreignPerson == true),
      userKids: new FormControl(this.register.userKids),
      userGuid: new FormControl(this.register.userGuid),
      moduleType: new FormControl(this.register.moduleType)
    });

    this.checkPhoneValues({value: this.register.phone});
    this.onModuleChange(this.register.moduleType);

    this.f['phone'].value = this.register.phone;
    this.userForm.value.phone = this.register.phone;

    this.statusCrmValid = true;
  }

  onGetModuleType(userModule: { moduleId: string; }): any {
    this.moduleService.getById(userModule.moduleId).subscribe(
      (response: { typeResponse: TypeResponse; data: { moduleType: number; }; }) => {
      if (response.typeResponse == TypeResponse.Success) {
        return response.data.moduleType;
      } else {
        return ModuleType.Admin;
      }
    });
  }
  
  onGetUserRoles(userId: string): any {
    this.userService.userRoles(userId).subscribe(
      (response: { typeResponse: TypeResponse; data: any; }) => {
      return response.typeResponse == TypeResponse.Success
        ? response.data
        : null;
    });
  }

  onGetRolesByModuleType(enumModule: ModuleType, setCount: boolean = false) {
    if (enumModule != null && enumModule >= 0) {
      this.handleItemChangeValue(null, 'all', 0);
      this.roleService.getRoleGroupByModule(enumModule).subscribe((response: { typeResponse: TypeResponse; data: any; }) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.roleGroupList = response.data;
        } else {
          this.roleGroupList = [];
        }
        if (setCount) {
          this.roleGroupListCount = 0;
          this.roleGroupList.forEach((rGroup: any) => {
            this.roleGroupListCount += rGroup.roles.length;
          });  
        }
      });
    }
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  onRandomPwd(input: any): void {
    this.randomPwd = input.currentTarget.checked;
    if (this.randomPwd) {
      this.userForm.controls['password'].value = '';
      this.userForm.controls['confirmpassword'].value = '';
    }
  }

  onUserKids(input: any) {
    let uKids = input.currentTarget.checked;
    if (uKids)
      this.register.userKids = uKids;

    this.userForm.controls['userKids'].value = uKids;
  }

  onForeignPerson(input: any) {
    let uForeignPerson = input.currentTarget.checked;
    if (uForeignPerson && this.register)
      this.register.foreignPerson = uForeignPerson;

    this.userForm.controls['foreignPerson'].value = uForeignPerson;
  }

  onModuleChange(value: number) {
    if (value) {
      this.flagUserKids = value == ModuleType.Student;
      if (this.f['userKids']) {
        this.f['userKids'].value = this.flagUserKids;
      }
      this.onGetRolesByModuleType(value, true);
    }
  }

  setMessageErrorList(listErrors: any): void {
    this.validUser = false;
    this.listErrorMessage = new Array<string>();
    for (const element of listErrors) {
      this.listErrorMessage.push(element.description);
    }
  }

  setMessageError(errorMessage: string): void {
    this.validUser = false;
    this.errorMessage = '';
    if (errorMessage) {
      this.errorMessage = errorMessage;
    }
  }

  public handleItemChangeValue(input: any, keyValue: any, groupId: number): void {
    let value = false;
    if (input) {
      value = input.currentTarget.checked;

      if (value == true && keyValue.includes('CRM'))
        this.crmControlStatus = true;
    }

    let groupRoles = Array<any>();

    if (keyValue === 'all') {
      groupRoles = groupId === 0 
        ? this.roleGroupList
        : this.roleGroupList.filter((a: { id: number; }) => a.id == groupId);
    } else {
      this.itemChangeValue(keyValue, value, groupId);  
      return;
    }

    groupRoles.forEach((group: any) => {
      if (group && group.roles) {
        group.roles.forEach((role: any) => {
          this.itemChangeValue(role.name, value, group.id);  
        });
      }
    });
  }

  private itemChangeValue(keyValue: string, value: boolean, groupId: number) {
    let inputRole = document.getElementById(keyValue);
    if (inputRole) {
      inputRole['checked'] = value;
      if (value === true && !this.selectedValues.some((item) => item == keyValue)) {
        this.selectedValues.push(keyValue);
      } else if (value === false && this.selectedValues.some((item) => item == keyValue)) {
        this.selectedValues = this.selectedValues.filter(obj => obj !== keyValue);
      }
      this.selectedValuesCount = this.selectedValues.length;
      this.setCheckGroup(groupId);
    }
  }

  private setCheckGroup(groupId: number) {
    let inputGroup = document.getElementById(groupId + '_all');
    inputGroup['checked'] = this.checkAllGroup(groupId);
    this.setCheckAllGroup();
  }

  
  private setCheckAllGroup() {
    let inputGroup = document.getElementById('select_all');
    if (inputGroup) {
      inputGroup['checked'] = this.checkAllGroupChecked();
    }
  }

  private checkAllGroup(groupId: number): boolean {
    let groupItem = this.roleGroupList.filter((a: { id: number; }) => a.id == groupId);
    if (groupItem.length > 0) {
      groupItem = groupItem[0];
      var listNews =  groupItem.roles.map((a: { name: any; }) => a.name);
      let totalChecked = 0;
      listNews.forEach((e: string) => {
        let index = this.selectedValues.indexOf(e);
        totalChecked += index >= 0 ? 1 : 0;
      });
      return totalChecked === listNews.length;
    }
    this.selectedValuesCount = this.selectedValues.length;
    return false;
  }

  private checkAllGroupChecked(): boolean {
    let totalChecked = 0;
    this.roleGroupList.forEach((rGroup: any) => {
      let inputGroup = document.getElementById(rGroup.id + '_all');
      if (inputGroup) {
        totalChecked += inputGroup['checked'] === true ? 1 : 0;
      }
    });
    return totalChecked == this.roleGroupList.length;
  }
}