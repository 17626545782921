import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseResult } from '@app/auth/model';
import { Responsible } from '@app/admin/model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResponsibleService {

  private route: string = "admin";
  private controller: string = "responsible";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getall(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getById(id: string): any {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getById`, {params: params});
  }
  
  remove(id): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.delete<ResponseResult>(`${this.endpointPrefix}/remove`, {params: params});
  }
  
  save(responsible: Responsible, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/update`, responsible);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/add`, responsible);
    }
  }
}
