import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';
import { GroupNotificationService } from '@app/marketing/services';
import { default as swal } from 'sweetalert2';

@Component({ templateUrl: './groupNotification-list.component.html' })
export class GroupNotificationListComponent implements OnInit {
  public datalist: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public columns: Array<any> = [
    { title: 'Código', name: 'id', sort: 'asc', className: 'text-center' },
    { title: 'Nome', name: 'name', sort: 'asc' },
    { title: 'E-mail', name: 'email', sort: 'asc' },
    {
      title: 'Criado em',
      name: 'createdOn',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
    {
      title: 'Modificado em',
      name: 'updatedOn',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
  ];

  constructor(
    private initAdmin: InitAdminService,
    private groupNotificationService: GroupNotificationService,
    private router: Router,
    private loading: MessageService
  ) {}

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadAll();
  }

  // :: Generic ::
  validAfterSubmit(dataReturn) {
    if (dataReturn.typeResponse == TypeResponse.Success) {
      this.datalist = dataReturn.data;
      this.showTable = true;
      this.returnMessage = '';
    } else {
      this.returnMessage = dataReturn.message;
      this.showTable = false;
    }
    this.loading.hideLoading();
  }

  loadAll() {
    this.loading.showLoading();
    this.groupNotificationService.getAll().subscribe(
      (response) => {
        this.validAfterSubmit(response);
      },
      (error) => {
        this.loading.hideLoading();
        this.showTable = false;
        this.returnMessage = 'Erro ao carregar os grupos.';
      }
    );
  }

  delete(target: any): void {
    swal({
      title: 'Excluir Grupo',
      html:
        'Confirma a exclusão <strong>' +
        target.name +
        '</strong>?<br>' +
        'Ao confirmar o grupo será excluído permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.groupNotificationService.delete(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir o grupo!',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {});
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir o grupo.',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  this.loadAll();
                });
              }
            },
            (error) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir o grupo.',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir o grupo.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/marketing/groupnotification', { id: row.id }]);
  }
}
