import { formatDate } from "@angular/common";
import { Base } from "@app/shared/model";
import { ClassRunningByDay } from "./classRunningByDay";

export class SummaryClassRunningByDay extends Base {
    expectedDate: Date;
    timeStart: Date;
    timeEnd: Date;
    duration: Date;
    classesRunning: Array<ClassRunningByDay>;

    constructor(contentResponse: any, fixDate: boolean = false) {
        super(contentResponse);
        
        this.expectedDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        this.timeStart = fixDate ? contentResponse.timeStart
            : this.getTimeByString(contentResponse.timeStart);
        this.timeEnd = fixDate ? contentResponse.timeEnd
            : this.getTimeByString(contentResponse.timeEnd);
        if (contentResponse.classesRunning && contentResponse.classesRunning.lenght > 0) {
            this.classesRunning = contentResponse.classesRunning;
        }            
    }

    getTimeByString(strTime: any) {
        if (strTime != null && strTime.length > 0) {
            let dateTime = new Date();
            dateTime.setMilliseconds(0);
            dateTime.setSeconds(0);
            dateTime.setMinutes(strTime.split(':')[1]);
            dateTime.setHours(strTime.split(':')[0]);
            return dateTime;
        }
    }
}
