export class WritingTestAppliedStudent {
    writingTestApplyId: number;
    finishDate: Date;
    writingTestStatus: number;
    writeContentStudentHtml: string;
    additionalInformation: string;

    constructor(contentResponse: any) {
        this.writingTestApplyId = parseInt(contentResponse.writingTestApplyId);
        this.finishDate = new Date(contentResponse.finishDate),
        this.writingTestStatus = parseInt(contentResponse.writingTestStatus);
        this.additionalInformation = contentResponse.additionalInformation;
        this.writeContentStudentHtml = contentResponse.writeContentStudentHtml;
    }
}
