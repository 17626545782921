<!-- <div class="contact-card">
    <p>{{ contact.name }} ( {{ contact.age }} )</p>
    <p>{{ contact.email }}</p>
</div> -->

<!-- <div class="card border m-auto m-lg-0" style="max-width: 18rem;" *ngFor="let serie of content.series; let i = index">
    <img [src]="(serie.contentPath + serie.folderName +'/'+ serie.thumbnail)" class="card-img-top" alt="...">
    <div class="card-body">
        <h5 class="card-title">{{serie.name}}</h5>
        <p class="card-text text-justify text-muted">{{serie.description}}</p>
    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-sm btn-primary btn-block waves-effect waves-themed" (click)="showContent(serie.item)">
            Acessar
            <span class="fal fa-arrow-right mr-1"></span>
        </button>
    </div>
</div> -->

<div class="card border m-auto m-lg-0" style="max-width: 18rem;">
    <!-- <img [src]="(serie.contentPath + serie.folderName +'/'+ serie.thumbnail)" class="card-img-top" alt="..."> -->
    <div class="card-body">
        <h5 class="card-title">{{ contact.name }} ( {{ contact.age }} )</h5>
        <p class="card-text text-justify text-muted">{{contact.email}}</p>
    </div>
    <div class="card-footer">
        <button type="button" class="btn btn-sm btn-primary btn-block waves-effect waves-themed">
            Acessar
            <span class="fal fa-arrow-right mr-1"></span>
        </button>
    </div>
</div>