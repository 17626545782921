import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { AccountService } from '@app/auth/services';
import { CryptoService } from '@app/shared/services';
import { CalendarUtilService, MessageService } from '@app/shared/services';
import { InitStudentService } from '@app/student/services/init-student.service';
import {
  StudentClassesCourseService,
  StudentCourseService,
  StudentService,
} from '@app/admin/services';
import { User } from '@app/auth/model';
import { Student } from '@app/admin/model';
import { first } from 'rxjs/operators';
import {
  ModuleType,
  StatusClass,
  StatusClassCss,
  StatusClassLabel,
  TypeResponse,
} from '@app/shared/enum';
import { CalendarService } from '@app/student/services/calendar.service';
import { default as swal } from 'sweetalert2';

import { environment } from '@environments/environment';

@Component({ templateUrl: 'home.component.html', providers: [DatePipe] })
export class HomeComponent {
  private src_script = '//js-na1.hs-scripts.com/24078910.js';
  loadAPI: Promise<any>;
  public allowMeeting: boolean = false;
  @ViewChild('mdInfoDetails') mdInfoDetails: any;
  @ViewChild('mdSchoolBreak') mdSchoolBreak: any;
  @ViewChild('mdSchoolTerms') mdSchoolTerms: any;
  private student: Student;
  public dateNow = new Date();
  public nextClass = new Date();
  newHome: boolean = false;
  testHome: boolean = false;
  returnUrl: string = '/student/update';
  closeResult: string;
  public returnMessage = '';

  public calendarVisible: boolean = false;

  public user: User = new User();
  private userRoles: Array<any>;

  public nextClassEvents: any;

  public studentCourseLinks: any = [];
  public studentCourseLinksMessage = '';
  public studentCourseLinksShow: boolean = false;

  private routeMeetingUrl = `${environment.urlLocal}/#/student/meeting`;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private cryptoService: CryptoService,
    private studentClassesCourseService: StudentClassesCourseService,
    private calendarUtilService: CalendarUtilService,
    private calendarService: CalendarService,
    private studentService: StudentService,
    private studentCourseService: StudentCourseService,
    private accountService: AccountService,
    private initStudent: InitStudentService,
    private loading: MessageService
  ) {
    this.loading.showLoading();
    this.initStudent.setConfigPage(false, false);
    this.getUserData();
  }

  private loadChat() {
    this.loadAPI = new Promise((resolve) => {
      //this.loadHubSpot();
    });
  }

  private getUserData() {
    this.initStudent
      .getStudentUser()
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.student = response;
          this.user = this.initStudent.user;
          this.userRoles = this.accountService.getRolesByUser();
          this.checkHaveStudent();
          this.loadChat();
          this.loadnextClassEvents();
          this.loading.hideLoading();
        },
        (error: any) => {
          this.returnMessage = 'Erro ao carregar o calendário.';
          this.loading.hideLoading();
        }
      );
  }

  loadnextClassEvents() {
    this.loading.showLoading();
    if (this.student) {
      this.calendarService.nextClassEvent(this.student.id).subscribe(
        (response) => {
          if (response.typeResponse === TypeResponse.Success) {
            this.nextClassEvents = response.data;
            this.returnMessage = '';
            this.calendarVisible = true;
          } else {
            this.calendarVisible = false;
            this.returnMessage = response.message;
          }
          this.loading.hideLoading();
        },
        (error: any) => {
          this.loading.hideLoading();
          this.calendarVisible = false;
          this.returnMessage = 'Erro ao carregar os dados da agenda.';
          console.log(error);
        }
      );
    } else {
      this.loading.hideLoading();
    }
  }

  checkClassRoomVisible(event: any) {
    if (event) {
      return (
        this.checkHavePermission('EST1AL7AV44') &&
        new Date(event.date).toDateString() === new Date().toDateString()
      );
    }
    return false;
  }

  getClassRoomUrl(linkClassRoom: string) {
    if (linkClassRoom) {
      return `https://meet.jit.si/ec-class-room-${linkClassRoom}`;
    }
    return 'javascript:void(0);';
  }

  getRouteClassRoom(classRoom: any) {
    let classRoomCrypto = this.cryptoService.encrypt(classRoom);
    return `${this.routeMeetingUrl};classRoom=${classRoomCrypto}`;
  }

  checkHaveStudent() {
    if (this.initStudent.user != null && this.initStudent.user.id > 0) {
      this.studentService.getByUserId(this.initStudent.user.id).subscribe(
        (response: { id: number }) => {
          this.loading.hideLoading();
          if (response != null && response.id > 0) {
            this.initialyze();
            this.getStudentCourseLinks(response.id);
          } else {
            this.redirectUpdate();
          }
          this.loading.hideLoading();
        },
        (error: any) => {
          console.log(error);
          this.initialyze();
        }
      );
    } else {
      this.redirectUpdate();
    }
  }

  getStudentCourseLinks(studentId: any) {
    this.loading.showLoading();
    this.studentCourseService.getLinksByStudentId(studentId).subscribe(
      (response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          //this.studentCourseLinks = response.data;
          this.checkStudentCourseLinks(response.data);
          this.studentCourseLinksShow = true;
          this.studentCourseLinksMessage = '';
        } else {
          this.studentCourseLinksMessage = response.message;
          this.studentCourseLinksShow = false;
        }
        this.loading.hideLoading();
      },
      (error: any) => {
        this.studentCourseLinksShow = false;
        this.studentCourseLinksMessage =
          'Erro ao carregar os links disponíveis.';
        this.loading.hideLoading();
      }
    );
  }

  checkStudentCourseLinks(lista: any[]) {
    lista.forEach(function (e) {
      e.showLinkSkype = false;
      e.showLinkGoogle = false;

      e.showLinkSkype = e.skype != null;
      e.showLinkGoogle = e.studentCourse?.googleDriveLink != null;
    });
    this.studentCourseLinks = lista;
  }

  redirectUpdate() {
    this.router.navigate([this.returnUrl]);
  }

  private initialyze() {
    this.dateNow = new Date();
    this.nextClass.setDate(
      this.dateNow.getDate() + Math.floor(Math.random() * 7 + 1)
    );
    this.user = this.accountService.getUserData();
    this.testHome = this.user.username == 'giullianoalvaro@gmail.com';
    this.newHome = this.user.username == 'giullianoalvaro@gmail.com';
    this.loading.hideLoading();
  }

  ngAfterViewInit() {
    this.showWelcomeMessage(this.mdSchoolTerms);
  }

  showWelcomeMessage(content: any) {
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();

      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  checkEditByStatus(event: any) {
    return (
      event?.extendedProps?.status == StatusClass.ChangedTeacher ||
      event?.extendedProps?.status == StatusClass.Scheduled ||
      event?.extendedProps?.status == StatusClass.PartialConfirmed ||
      event?.extendedProps?.status == StatusClass.Done ||
      event?.extendedProps?.status == StatusClass.Reschedule
    );
  }

  designStatusClass(event: any) {
    let status: StatusClass = event?.extendedProps?.status;
    let statusClassCss = StatusClassCss.get(status);
    let statusClassStr = StatusClassLabel.get(status);
    return (
      "<span class='badge badge-pill w-100 " +
      statusClassCss +
      " font-weight-bold p-2 fs-md'>" +
      statusClassStr +
      '</span>'
    );
  }

  checkHavePermission(role: any): boolean {
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some((x) => x.keyDefault == role);
    } else {
      return false;
    }
  }

  public loadHubSpot() {
    if (!this.checkHavePermission('EST1AT24CH41')) return;

    let node = document.createElement('script');
    node.src = this.src_script;
    node.id = 'hs-script-loader';
    node.type = 'text/javascript';
    node.async = true;
    node.defer = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  checkConfirmClass(target: any): void {
    this.loading.showLoading();
    swal({
      title: 'Confirmação da aula',
      html: 'Deseja confirmar a participação na aula ao vivo?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, confirmar minha presença',
      cancelButtonText: 'Não',
    }).then(
      (willConfirm) => {
        if (willConfirm.value === true) {
          var link = this.getClassRoomUrl(target?.extendedProps?.classRoom);
          if (
            target?.extendedProps &&
            this.initStudent.CheckHavePermission('EST1AL7MT35')
          ) {
            this.allowMeeting = true;
            link = this.getRouteClassRoom(target?.extendedProps?.classRoom);
          }
          this.changedClass(target?.id, 1, link);
        } else {
          this.dismissModal();
          this.loading.hideLoading();
          // swal({
          //   type: 'info',
          //   title: `Lembre-se de confirmar a aula sempre que finalizar!`,
          //   showConfirmButton: true,
          // }).then(() => {
          //   // var linkAoVivo = this.getClassRoomUrl(target.classRoom);
          //   // window.open(linkAoVivo, '_blank');
          //   this.dismissModal();
          //   this.loading.hideLoading();
          // });
        }
      },
      (error) => {
        this.loading.hideLoading();
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title:
              'Erro ao tentar entrar na aula. Atualize a página e tente novamente!',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            //window.location.reload();
          });
        }
      }
    );
  }

  changedClass(
    studentClassesCourseId: any,
    statusClass: StatusClass,
    linkAoVivo: string = ''
  ) {
    this.dismissModal();
    this.loading.showLoading();
    this.studentClassesCourseService
      .changedClass(studentClassesCourseId, statusClass, ModuleType.Student, 0)
      .subscribe(
        (response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            if (response.data === true) {
              this.changedClassSuccess(statusClass, linkAoVivo);
            } else {
              this.changedClassFailed(statusClass);
            }
          }
        },
        (error: any) => {
          this.loading.hideLoading();
          this.changedClassFailed(statusClass);
        }
      );
  }

  changedClassSuccess(status: StatusClass, linkAoVivo: string = '') {
    swal({
      type: 'success',
      title: linkAoVivo
        ? `Presença registrada na aula com sucesso!`
        : `Atualização realizada com sucesso!`,
      html:
        linkAoVivo && !this.allowMeeting
          ? '<small>Caso a aba do seu navegador não abra automaticamente, ' +
            'clique no link abaixo ou copie e cole no seu navegador para entrar na sala!</small><br><br>' +
            '<a href="' +
            linkAoVivo +
            '" target="_blank">' +
            linkAoVivo +
            '</a><br>'
          : '',
      showConfirmButton: true,
      confirmButtonText: 'Entrar na sala',
      //timer: 3000
    }).then((result: any) => {
      this.loading.hideLoading();
      if (result.value === true && linkAoVivo) {
        window.open(linkAoVivo, '_blank');
        setTimeout(() => {
          this.loading.hideLoading();
          window.location.reload();
        }, 5000);
      }
    });
  }

  changedClassFailed(status: StatusClass) {
    swal({
      type: 'warning',
      title: `Não foi possível realizar a atualização!`,
      showConfirmButton: true,
    }).then(() => {
      this.loading.hideLoading();
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
}
