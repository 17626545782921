import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from '@app/admin/services';
import { UserService } from '@app/auth/services';
import { CourseTypeCss, CourseTypeLabel, TypeResponse } from '@app/shared/enum';
import { ModuleType } from '@app/shared/enum/ModuleType';
import { MessageService } from '@app/shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { Select2OptionData } from 'ng-select2';
import { first } from 'rxjs/operators';
import { Options } from 'select2';

@Component({
  selector: 'app-course-select',
  templateUrl: './course-select.component.html',
  styleUrls: ['./course-select.component.css']
})
export class CourseSelectComponent implements OnInit {
  public flagSearchCourse = false;
  public validCourse: boolean = true;
  public options: Options;
  public errors: any = Array<string>();

  private urlStudentCourseList: string = '/admin/studentCourselist';
  private urlAddStudentCourse: string = '/admin/studentCourse';

  public courseDataList: Array<any>;
  public courseData: any;
  public courseList: any = [];
  public courseId: number;

  //this.router.navigate(['/admin/studentCourselist', { courseId: this.courseId }]);

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private loading: MessageService,
    private courseService: CourseService) {
  }

  ngOnInit(): void {
    this.options = {
      width: '300'
    };
    this.getAllCourses()
  }
  
  getAllCourses() {
    this.flagSearchCourse = true;
    this.courseService.list().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.courseDataList = this.getTemplateUserList(response.data);
      } else {
        this.courseDataList = new Array<any>();
      }
      this.flagSearchCourse = false;
    }, (error: any) => {
      this.flagSearchCourse = false;
    });
  }

  setCourse(value: any) {
    this.courseId = value;
    this.courseData = this.courseList.find((x: { id: any; }) => x.id == value);
  }

  done() {
    this.loading.showLoading();
    if (this.courseId > 0) {
      this.router.navigate([this.urlAddStudentCourse, { courseId: this.courseId }]);
    }

    this.dismissModal();
  }

  getTemplateUserList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.courseList = data;
      let arrayUserList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id,
          text: data[i].id + " | " + data[i].name,
          additional: {
            teacher: data[i].teacher,
            product: data[i].product,
            courseType: this.designCourseType(data[i].courseType),
          }
        };
        arrayUserList.push(element);
      }
      return arrayUserList;
    }
    return new Array<any>();
  }

  designCourseType(courseType: any) {
    let courseTypeCss = CourseTypeCss.get(courseType);
    let courseTypeStr = CourseTypeLabel.get(courseType)
    let designCourseType = "<span class='badge badge-pill w-100 "
      + courseTypeCss +"'>"
      + courseTypeStr +"</span>";
    return designCourseType;
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  cancel() {
    this.dismissModal();
  }
}
