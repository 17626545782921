import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { Injectable } from '@angular/core';
import { ChatMessage } from '../model';

@Injectable({
  providedIn: 'root'
})
export class ChatMessageService {
  private route: string = "message";
  private controller: string = "chatMessage";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  contactsByStudent(studentId: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/contactsByStudent/${studentId}`);
  }

  contactsByTeacher(teacherId: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/contactsByTeacher/${teacherId}`);
  }

  addMessage(chatMessage: ChatMessage): any {
    return this.http.post<ResponseResult>(`${this.endpointPrefix}/addMessage`, chatMessage);
  }

  getMessages(idSenderUser: number, idRecipientUser: number): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/messages/sender/${idSenderUser}/recipient/${idRecipientUser}`);
  }

  readMessages(idSenderUser: number, idRecipientUser: number): any {
    return this.http.post<ResponseResult>(`${this.endpointPrefix}/read/sender/${idSenderUser}/recipient/${idRecipientUser}`, true);
  }

  checkNewMessages(idSenderUser: number, idRecipientUser: number): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/check/sender/${idSenderUser}/recipient/${idRecipientUser}`);
  }

  newMessagesCount(recipientUserGuid: string): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/newMessagesCount/${recipientUserGuid}`);
  }
}
