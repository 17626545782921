import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MessageQueueService } from '@app/marketing/services';

import { AccountService } from '../services/account.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService,
        private messageQueueService: MessageQueueService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.accountService.accessIsValid()) {
            //this.messageQueueService.checkHaveUserMessage();

            if (route != null && route.data != null) {
                let module = route.data.module != null && route.data.module >= 0
                    ? route.data.module : null;
                let role = route.data.role != null ? route.data.role : null;
                
                if (module != null || role != null) {
                    return this.accountService.userHaveAccess(module, role);
                } else {
                    this.accountService.redirectToModule(true);
                }
                return true;
                // if (module != null && !this.accountService.userHaveAccessModule(route.data.module) == true) {
                // }
                // return true;
            }
        }
        this.accountService.logout();
        // not logged in so redirect to login page with the return url
        //this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}