<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item">
      <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
  </li>
  <li class="breadcrumb-item active">Grupo de Notificações</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div class="row">
  <!-- begin col-12 -->
  <div class="col-xl-12">
      <div id="panel-8" class="panel">
          <div class="panel-hdr bg-primary-600 bg-primary-gradient">
              <h2>Lista de Grupos</h2>
              <div class="panel-toolbar ml-2">
                  <a type="button" class="btn btn-success btn-xs" data-toggle="tooltip" title="Novo Grupo" [routerLink]="['/marketing/groupnotification']">Novo</a>
              </div>
          </div>
          <div class="panel-container show">
              <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                  <div class="col-sm-12" *ngIf="!showTable">
                      <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                  </div>
                  <div class="col-sm-12" *ngIf="showTable">
                      <managed-table [dataTitle]="" [rows]="datalist" [columns]="columns" [hasActions]="true" [ngTableFiltering]="true" [showFilterRow]="false"
                          [previewEnable]="false" 
                          [editEnable]="true" (editClick)="edit($event)"
                          [deleteEnable]="true" (deleteClick)="delete($event)"
                          [hasFilter]="true">
                      </managed-table>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- end col-12 -->
</div>
<!-- end row -->
