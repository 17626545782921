import { Unity } from '@app/shared/enum/Unity';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CheckListService } from '@app/admin/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';
import { default as swal } from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({ templateUrl: 'checkList-list.component.html', providers: [DatePipe] })
export class CheckListListComponent implements OnInit {

  public checkLists: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  public Unity = Unity;
  public unityList = Object.keys(this.Unity);

  public columns: Array<any> = [
    { title: "Código", name: "id", sort: "asc", className: "text-center" },
    { title: "Nome", name: "name", sort: "asc" },
    { title: "Módulo", name: "module", sort: "asc", className: "text-center" },
    // { title: "Itens", sort: "asc" },
    // { title: "Alunos", sort: "asc" },
    {
      title: "Criado em",
      name: "createdOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    },
    {
      title: "Modificado em",
      name: "updatedOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    }
  ];
  
  constructor(
    private initAdmin: InitAdminService,
    private checkListService: CheckListService,
    private router: Router,
    private loading: MessageService
  ) { }

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadCheckList();
  }

  loadCheckList() {
    this.loading.showLoading();
    this.checkListService.getall()
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.checkLists = response.data;
        this.showTable = true;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
        this.showTable = false;
      }
      this.loading.hideLoading();
    },
    error => {
      this.loading.hideLoading();
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar os dados dos checkList.';
    });
  }

  delete(id): void {
    swal({
      title: "Deseja realmente excluir este registro?",
      text: "Ao clicar ok, este será excluído permanentemente!",
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true     
    })
    .then((willDelete)=> {
      if (willDelete.value === true) {
        // To delete
      }
    });
  }

  edit(row:any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/checkList', { id: row.id }]);
  }
}
