<!-- <div class="modal-header">
    <h5 class="modal-title">Selecione um(a) professor(a)</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
           
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="cancel()">
        <i class="fa fa-times-circle mr-1"></i> Cancelar
    </button>
    <button type="button" class="btn btn-sm btn-success waves-effect waves-themed" (click)="done()">
        <i class="fa fa-check mr-1"></i> Confirmar
    </button>
</div> -->

<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScwQUwwkXcMJYHrKD2ZylWdT9jKOSPLhCU2HBhUsEKoop-yiA/viewform?embedded=true" width="640" height="1481" frameborder="0" marginheight="0" marginwidth="0">Carregando…</iframe>