<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item">
    <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
  </li>
  <li class="breadcrumb-item">
    <a
      href="javascript:void(0);"
      [routerLink]="['/marketing/contactbysitelist']"
      >Gestão de Interessados</a
    >
  </li>
  <li class="breadcrumb-item active">Alteração de Status</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol>
<div class="row">
  <div class="col-12">
    <div id="panel-1" class="panel">
      <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
          Alterar <span class="fw-300"><i>Status</i></span>
        </h2>
        <div class="panel-toolbar">
          <a
            href="javascript:void(0);"
            class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
            data-action="panel-collapse"
            data-toggle="tooltip"
            data-offset="0,10"
            data-original-title="Collapse"
          >
            <i class="fal fa-minus text-white fw-900"></i>
          </a>
          <a
            href="javascript:void(0);"
            class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
            data-action="panel-collapse"
            data-toggle="tooltip"
            data-offset="0,10"
            data-original-title="Fullscreen"
          >
            <i class="fal fa-expand text-primary fw-900"></i>
          </a>
          <a
            href="javascript:void(0);"
            class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
            data-action="panel-collapse"
            data-toggle="tooltip"
            data-offset="0,10"
            data-original-title="Close"
          >
            <i class="fal fa-times text-white fw-900"></i>
          </a>
        </div>
      </div>
      <div class="panel-container show">
        <div class="panel-content">
          <div
            class="row justify-content-between mb-3"
            *ngIf="
              contactId > 0 &&
              this.contactData != null &&
              this.contactData.id > 0
            "
          >
            <div class="col-md-6 col-sm-12">
              <div class="row mb-3">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label text-muted">Id</label>
                    <input
                      type="text"
                      class="form-control"
                      disabled=""
                      [value]="contactData.id"
                    />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label class="form-label text-muted">Nome</label>
                    <input
                      type="text"
                      class="form-control"
                      disabled=""
                      [value]="contactData.name"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label text-muted text-center mb-2"
                      >Status atual</label
                    >
                    <span
                      class="badge badge-pill w-100 d-block d-sm-none mb-2 py-1 px-3"
                      [ngClass]="contactData.statusCrmCss"
                      >{{ contactData.statusCrmStr }}</span
                    >
                    <span
                      class="badge badge-pill w-100 d-none d-sm-block mt-2 py-1 px-3"
                      [ngClass]="contactData.statusCrmCss"
                      >{{ contactData.statusCrmStr }}</span
                    >
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label class="form-label text-muted">E-mail</label>
                    <input
                      type="text"
                      class="form-control"
                      disabled=""
                      [value]="contactData.email"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label text-muted">Telefone</label>
                    <input
                      type="text"
                      class="form-control"
                      disabled=""
                      [value]="contactData.phone"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label text-muted"
                      >Data de Cadastro</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      disabled=""
                      [value]="
                        contactData.createdOn | date : 'dd/MM/yyyy HH:mm:ss'
                      "
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="form-label text-muted"
                      >Data de Atualização</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      disabled=""
                      [value]="
                        contactData.updatedOn | date : 'dd/MM/yyyy HH:mm:ss'
                      "
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label text-muted">Mensagem</label>
                    <div
                      class="panel-tag mb-1"
                      [innerHTML]="getFormattedMessage(contactData?.message)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-sm-12">
              <div class="row mb-3">
                <div class="col-md-12">
                  <span
                    role="status"
                    aria-hidden="true"
                    class="spinner-border spinner-border-sm pull-left mr-2 text-primary"
                    *ngIf="flagSearchContact"
                  ></span>
                  <span class="text-primary pull-left" *ngIf="flagSearchContact"
                    >Carregando contato</span
                  >
                </div>
                <div class="col-md-12">
                  <label class="form-label" for="newStatusCrm"
                    >Novo Status <span class="text-danger">*</span>
                  </label>
                  <br />
                  <select
                    class="form-control"
                    id="newStatusCrm"
                    placeholder="Status CRM"
                    (change)="setNewStatusCrm($event?.target?.value)"
                    [(ngModel)]="newStatusCrmId"
                  >
                    <option
                      *ngFor="let item of statusCrmDataList"
                      [value]="item.id"
                    >
                      {{ item.text }}
                    </option>
                  </select>

                  <!-- <ng-select2 class="w-100" [data]="statusCrmDataList" [width]="200" style="z-index: 1000;" id="newStatusCrm" [(ngModel)]="newStatusCrmId"
                                        (valueChanged)="setNewStatusCrm($event);" [options]="options"></ng-select2> -->
                  <span
                    class="badge badge-pill col-8 d-block d-sm-none mt-2 py-1 px-3"
                    [ngClass]="newStatusCrmId >= 0 ? newStatusCrmCss : ''"
                    >{{ newStatusCrmStr }}</span
                  >
                  <span
                    class="badge badge-pill col-md-3 d-none d-sm-block mt-2 py-1 px-3"
                    [ngClass]="newStatusCrmId >= 0 ? newStatusCrmCss : ''"
                    >{{ newStatusCrmStr }}</span
                  >
                  <div
                    *ngIf="!validContact && errorMessage"
                    class="invalid-feedback"
                  >
                    <div>{{ errorMessage }}.</div>
                  </div>
                </div>
                <input
                  type="hidden"
                  id="experimentalClass"
                  formControlName="experimentalClass"
                />
                <div class="col-md-6 mt-3" *ngIf="!showExperimentalDate">
                  <div class="subheader">
                    <div class="subheader-block d-lg-flex align-items-center">
                      <div
                        class="d-inline-flex flex-column justify-content-center mr-3"
                      >
                        <span class="fw-300 fs-xs d-block text-muted"
                          >Data/hora da aula experimental:</span
                        >
                        <span
                          class="fw-500 fs-xl d-block color-primary-500"
                          *ngIf="
                            contactId > 0 && contactData?.experimentalClass
                          "
                          >{{
                            contactData?.experimentalClass
                              | date : "dd/MM/yyyy HH:mm:ss"
                          }}</span
                        >
                        <span
                          class="badge badge-pill badge-secondary full-width mt-1"
                          *ngIf="!contactData?.experimentalClass"
                          >Data não informada</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-3" *ngIf="showExperimentalDate">
                  <div class="form-group">
                    <label class="form-label" for="experimentalDateValue"
                      >Data da aula experimental
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      name="experimentalDateValue"
                      data-date-format="dd/MM/YYYY"
                      placeholder="dd/mm/yyyy"
                      [(ngModel)]="date"
                      class="form-control"
                      (change)="updateDate($event)"
                      min="2000-01-01"
                      max="2030-12-31"
                    />
                  </div>
                </div>
                <div class="col-md-6 mt-3" *ngIf="showExperimentalDate">
                  <div class="form-group">
                    <label class="form-label" for="experimentalTimeValue"
                      >Horário da aula experimental
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      id="experimentalTimeValue"
                      required
                      [ngModel]="experimentalTimeValue"
                      class="form-control"
                      placeholder="Horário da aula experimental"
                      (change)="setValueTime($event.target)"
                    />
                  </div>
                </div>
                <div class="col-md-12 mt-3" *ngIf="showExperimentalDate">
                  <div class="form-group">
                    <label class="form-label" for="experimentalClassTeacherId"
                      >Teacher <span class="text-danger">*</span></label
                    >
                    <br />
                    <div class="ng-autocomplete w-100">
                      <ng-autocomplete
                        class="w-100"
                        [data]="teacherDataList"
                        [initialValue]="teacherInitialValue"
                        searchKeyword="name"
                        placeholder="Selecione um teacher para a aula"
                        (selected)="selectEventTeacher($event)"
                        (inputChanged)="onChangeSearchTeacher($event)"
                        (inputFocused)="onFocusedTeacher($event)"
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                      >
                      </ng-autocomplete>

                      <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.name"></a>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                    <!-- <select class="form-control" id="teacherId" placeholder="Selecione um(a) professor(a) para matrícula"
                                            (change)="onTeacherChange($event?.target?.value);" [(ngModel)]="selectedTeacher">
                                            <option *ngFor="let item of teacherDataList" [value]="item.id">
                                                {{ item.text }}
                                            </option>
                                        </select> -->
                    <!--<ng-select2
                                            [data]="teacherDataList"
                                            [(ngModel)]="selectedTeacher"
                                            [options]="options"
                                            (valueChanged)="onTeacherChange($event)"
                                            [placeholder]="'Selecione um(a) professor(a) para matrícula'">
                                        </ng-select2> -->
                    <div *ngIf="previewMode">
                      <label class="form-control font-weight-bold disabled">{{
                        contactBySite.experimentalClassTeacher.name
                      }}</label>
                    </div>
                  </div>
                  <div class="form-group row mb-2" *ngIf="flagTeacherSelected">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title text-primary fw-700">
                            Dados do(a) Professor(a)
                          </h5>
                          <div class="form-group row mb-2">
                            <div class="col-8">
                              <label class="form-label">Nome</label>
                              <p class="card-text fw-700">
                                {{ teacher.name }}
                              </p>
                            </div>
                            <div class="col-4">
                              <label class="form-label">Localidade</label>
                              <p class="card-text fw-700">
                                {{ teacher.cityName }} / {{ teacher.stateUf }}
                              </p>
                            </div>
                            <div class="col-6">
                              <label class="form-label">Telefone</label>
                              <p class="card-text fw-700">
                                {{ teacher.phone }}
                              </p>
                            </div>
                            <div class="col-6">
                              <label class="form-label">E-mail</label>
                              <p class="card-text fw-700">
                                {{ teacher.email }}
                              </p>
                            </div>
                            <div class="col-6">
                              <label class="form-label">Graduação</label>
                              <p class="card-text fw-700">
                                {{ teacher.universityGraduate }}
                              </p>
                            </div>
                            <div class="col-6">
                              <label class="form-label">Mais Informações</label>
                              <p class="card-text fw-700">
                                {{ teacher.moreInformation }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-3">
                  <label class="form-label" for="comments">Comentários</label>
                  <textarea
                    rows="3"
                    id="comments"
                    [(ngModel)]="comments"
                    placeholder="Escreva seus comentários"
                    class="form-control rounded-top border-bottom-left-radius-0 border-bottom-right-radius-0 border"
                  ></textarea>
                </div>
                <!-- <div class="col-md-12">
                                    <div class="form-row" *ngIf="!validContact">
                                        <div class="col-md-12">
                                            <div class="alert alert-warning alert-dismissible text-left fade show mb-0 mt-3" role="alert" *ngIf="!validContact">
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                                </button>
                                                {{errorMessage}}
                                            </div>
                                            <div *ngIf="listErrorMessage && !validContact" class="invalid-feedback">
                                                <br>
                                                <div *ngFor="let error of listErrorMessage">{{error}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
              </div>
              <hr />
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="subheader">
                    <div class="subheader-block d-lg-flex align-items-center">
                      <div
                        class="d-inline-flex flex-column justify-content-center mr-3"
                      >
                        <span class="fw-300 fs-xs d-block text-muted"
                          >Data de Criação:</span
                        >
                        <span
                          class="fw-500 fs-xl d-block color-primary-500"
                          *ngIf="contactId > 0"
                          >{{
                            contactBySite?.createdOn
                              | date : "dd/MM/yyyy HH:mm:ss"
                          }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3"
                    >
                      <div
                        class="d-inline-flex flex-column justify-content-center mr-3"
                      >
                        <span class="fw-300 fs-xs d-block text-muted"
                          >Data de Atualização:</span
                        >
                        <span
                          class="fw-500 fs-xl d-block color-primary-500"
                          *ngIf="contactId > 0"
                          >{{
                            contactBySite?.updatedOn
                              | date : "dd/MM/yyyy HH:mm:ss"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 ml-auto text-right">
                  <div class="btn-group btn-panel">
                    <button
                      id="js-login-btn"
                      class="btn btn-primary"
                      [disabled]="submitted"
                      (click)="done()"
                    >
                      <span
                        *ngIf="submitted"
                        class="spinner-border spinner-border-sm mr-1"
                      ></span>
                      <i class="fa fa-check mr-1"></i> Confirmar
                    </button>
                    <button
                      id="js-cancel-btn"
                      class="btn btn-default"
                      (click)="cancel()"
                    >
                      <i class="fa fa-times-circle mr-1"></i> Cancelar
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mb-3" *ngIf="!validContact">
                <hr />
                <div class="col-md-md-12">
                  <div
                    class="alert alert-warning alert-dismissible text-left fade show mt-3"
                    role="alert"
                    *ngIf="!validContact"
                  >
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"
                        ><i class="fal fa-times"></i
                      ></span>
                    </button>
                    {{ errorMessage }}
                  </div>
                  <br />
                  <div
                    *ngIf="listErrorMessage && !validContact"
                    class="invalid-feedback"
                  >
                    <div *ngFor="let error of listErrorMessage">
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
