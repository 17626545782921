import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InitAdminService } from '@app/admin/services';

@Component({
    selector: 'app-grades',
    templateUrl: './grades.component.html',
    styleUrls: ['./grades.component.css']
})
export class GradesComponent implements OnInit {
    // myForm:FormGroup;
    // disabled = false;
    // ShowFilter = false;
    // limitSelection = false;
    // cities: any = [];
    // selectedItems: any = [];
    // dropdownSettings: any = {};
    
    title = "ng-multiselect-dropdown";
    countries: Array<any> = [];
    selCountries = [
        {
        item_id: 1,
        item_text: "India",
        //image: "http://www.sciencekids.co.nz/images/pictures/flags96/India.jpg"
        },
        {
        item_id: 5,
        item_text: "Israel",
        //image: "http://www.sciencekids.co.nz/images/pictures/flags96/Israel.jpg"
        }
    ];
    dropdownSettings: any = {};

    constructor(private fb: FormBuilder,
        private initAdmin: InitAdminService) {
            this.initAdmin.setConfigPage();
        }

    ngOnInit() {
        // this.dropdownSettings = {
        //     singleSelection: false,
        //     idField: 'item_id',
        //     textField: 'item_text',
        //     selectAllText: 'Select All',
        //     unSelectAllText: 'UnSelect All',
        //     itemsShowLimit: 3,
        //     //allowSearchFilter: this.ShowFilter
        // };

        // this.cities = [
        //     { item_id: 1, item_text: 'New Delhi' },
        //     { item_id: 2, item_text: 'Mumbai' },
        //     { item_id: 3, item_text: 'Bangalore' },
        //     { item_id: 4, item_text: 'Pune' },
        //     { item_id: 5, item_text: 'Chennai' },
        //     { item_id: 6, item_text: 'Navsari' }
        // ];
        // //this.selectedItems = [{ item_id: 4, item_text: 'Pune' }, { item_id: 6, item_text: 'Navsari' }];
        
        // this.myForm = this.fb.group({
        //     city: [this.selectedItems]
        // });

        this.countries = [
            {
                item_id: 1,
                item_text: "India",
                image: "fa-circle text-success"
                //image: "http://www.sciencekids.co.nz/images/pictures/flags96/India.jpg"
            },
            {
                item_id: 2,
                item_text: "Spain",
                image: "fa-circle text-danger"
                //image: "http://www.sciencekids.co.nz/images/pictures/flags96/Spain.jpg"
            },
            {
                item_id: 3,
                item_text: "United Kingdom",
                image: "fa-circle text-success"
                //image: "http://www.sciencekids.co.nz/images/pictures/flags96/United_Kingdom.jpg"
            },
            {
                item_id: 4,
                item_text: "Canada",
                image: "fa-circle text-success",
                //image: "http://www.sciencekids.co.nz/images/pictures/flags96/Canada.jpg",
                isDisabled: true
            },
            {
                item_id: 5,
                item_text: "Israel",
                image: "fa-circle text-warning"
                //image: "http://www.sciencekids.co.nz/images/pictures/flags96/Israel.jpg"
            },
            {
                item_id: 6,
                item_text: "Brazil",
                image: "fa-circle text-success",
                //image: "http://www.sciencekids.co.nz/images/pictures/flags96/Brazil.jpg"
            },
            {
                item_id: 7,
                item_text: "Barbados",
                image: "fa-circle text-success",
                //image: "http://www.sciencekids.co.nz/images/pictures/flags96/Barbados.jpg"
            },
            {
                item_id: 8,
                item_text: "Mexico",
                image: "fa-circle text-success",
                //image: "http://www.sciencekids.co.nz/images/pictures/flags96/Mexico.jpg"
            }
        ];

        this.dropdownSettings = {
            singleSelection: false,
            idField: "item_id",
            textField: "item_text",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }

    get getItems() {
        return this.countries.reduce((acc, curr) => {
            acc[curr.item_id] = curr;
            return acc;
        }, {});
    }

    onItemSelect(item: any) {
        console.log("onItemSelect", item);
    }
    
    onSelectAll(items: any) {
        console.log("onSelectAll", items);
    }
}

// onSelectAll(items: any) {
//     console.log('onSelectAll', items);
// }
// toogleShowFilter() {
//     this.ShowFilter = !this.ShowFilter;
//     this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
// }

// handleLimitSelection() {
//     if (this.limitSelection) {
//         this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
//     } else {
//         this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
//     }
// }
//}
