import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JitsiService } from '@app/content/services';
import { MessageService } from '@app/shared/services';
declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-classroom',
  templateUrl: './classroom.component.html',
  styleUrls: ['./classroom.component.css']
})
export class ClassRoomComponent implements OnInit, AfterViewInit {

  domain: string = "meet.englishcoffee.com.br"; // For self hosted use your domain
  room: any;
  options: any;
  api: any;
  user: any;

  // For Custom Controls
  isAudioMuted = false;
  isVideoMuted = false;

  constructor(
      private router: Router
  ) { }

  ngOnInit(): void {
      this.room = 'bwb-bfqi-vmh'; // Set your room name
      this.user = {
          name: 'Teacher Admin' // Set your username
      }
  }

  ngAfterViewInit(): void {
      this.options = {
          roomName: this.room,
          width: 900,
          height: 500,
          configOverwrite: { prejoinPageEnabled: false },
          interfaceConfigOverwrite: {
              // overwrite interface properties
          },
          parentNode: document.querySelector('#jitsi-iframe'),
          userInfo: {
              displayName: this.user.name
          }
      }

      this.api = new JitsiMeetExternalAPI(this.domain, this.options);

       // Event handlers
      this.api.addEventListeners({
          readyToClose: this.handleClose,
          participantLeft: this.handleParticipantLeft,
          participantJoined: this.handleParticipantJoined,
          videoConferenceJoined: this.handleVideoConferenceJoined,
          videoConferenceLeft: this.handleVideoConferenceLeft,
          audioMuteStatusChanged: this.handleMuteStatus,
          videoMuteStatusChanged: this.handleVideoStatus
      });
  }

  handleClose = () => {
    console.log("handleClose");
  }

  handleParticipantLeft = async (participant) => {
      console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
      const data = await this.getParticipants();
  }

  handleParticipantJoined = async (participant) => {
      console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
      const data = await this.getParticipants();
  }

  handleVideoConferenceJoined = async (participant) => {
      console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
      const data = await this.getParticipants();
  }

  handleVideoConferenceLeft = () => {
      console.log("handleVideoConferenceLeft");
      this.router.navigate(['/thank-you']);
  }

  handleMuteStatus = (audio) => {
      console.log("handleMuteStatus", audio); // { muted: true }
  }

  handleVideoStatus = (video) => {
      console.log("handleVideoStatus", video); // { muted: true }
  }

  getParticipants() {
      return new Promise((resolve, reject) => {
          setTimeout(() => {
              resolve(this.api.getParticipantsInfo()); // get all participants
          }, 500)
      });
  }

  executeCommand(command: string) {
    this.api.executeCommand(command);;
    if(command == 'hangup') {
        this.router.navigate(['/thank-you']);
        return;
    }

    if(command == 'toggleAudio') {
        this.isAudioMuted = !this.isAudioMuted;
    }

    if(command == 'toggleVideo') {
        this.isVideoMuted = !this.isVideoMuted;
    }
  }
}


  // @Input("courseData") courseData: any;
  
  // public course: any;
  // public isAudioMuted: boolean = false;
  // public isVideoMuted: boolean = false;

  // public waitLoading: boolean = true;

  // private nameRoom: string;
  // private courseName: string;

  // constructor(
  //   private router: Router,
  //   private loading: MessageService,
  //   private activatedRoute: ActivatedRoute,
  //   private jitsiService: JitsiService) {}
  
  // ngOnInit(): void {
  //   this.waitLoading = true;
  //   this.activatedRoute.params.subscribe(params => {
  //     this.nameRoom = params['nameRoom'];
  //     this.courseName = params['courseName'];
  //     if (this.nameRoom && this.courseName) {
  //       this.jitsiService.moveRoom(this.nameRoom, this.courseName, true, '');
  //     } else {
  //       return this.router.navigate(['/']);
  //     }
  //   });
  //   // if (this.courseData) {
  //   //   this.loading.showLoading();
  //   //   this.course = JSON.parse(this.courseData);
  //   //   //let classRoom = this.courseData.classRoom
  //   //   this.jitsiService.moveRoom(this.course.classRoom, this.course.name, true, this.course.classRoomPwd);
  //   //   this.loading.hideLoading();
  //   //   this.waitLoading = false;
  //   // }
  // }

  // executeCommand(data: any) {
  //   console.log(
  //     'this.jitsiService.getParticipants():',
  //     this.jitsiService.getParticipants()
  //   );

  //   this.jitsiService.api.executeCommand(
  //     'sendEndpointTextMessage',
  //     this.jitsiService.getParticipants(),
  //     'mover a principal'
  //   );
  // }
