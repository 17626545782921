import { Base } from '@app/shared/model';
import { Course, Teacher } from '.';

export class CourseRecurrence extends Base {
    timeStart: Date;
    timeEnd: Date;
    duration: Date;
    dayOfWeek: number;

    courseId: Number;
    course: Course;

    teacherId: Number;
    teacher: Teacher;

    constructor(contentResponse: any, fixDate: boolean = false) {
        super(contentResponse);

        this.timeStart = fixDate ? contentResponse.timeStart
            : this.getTimeByString(contentResponse.timeStart);
        this.timeEnd = fixDate ? contentResponse.timeEnd
            : this.getTimeByString(contentResponse.timeEnd);
        this.duration = fixDate ? contentResponse.duration
            : this.getTimeByString(contentResponse.duration);

        this.dayOfWeek = parseInt(contentResponse.dayOfWeek),

        this.courseId = parseInt(contentResponse.courseId ? contentResponse.courseId : 0);
        if (contentResponse && contentResponse.courseId > 0) {
            this.course = contentResponse.course;
        }

        this.teacherId = parseInt(contentResponse.teacherId ? contentResponse.teacherId : 0);
        if (contentResponse && contentResponse.teacherId > 0) {
            this.teacher = contentResponse.teacher;
        }
    }

    getTimeByString(strTime: any) {
        if (strTime != null && strTime.length > 0) {
            let dateTime = new Date();
            dateTime.setMilliseconds(0);
            dateTime.setSeconds(0);
            dateTime.setMinutes(strTime.split(':')[1]);
            dateTime.setHours(strTime.split(':')[0]);
            return dateTime;
        }
    }
}
