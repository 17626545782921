<div class="form-group row">
    <div class="col-12">
        <form [formGroup]="studentCourseRelocationForm" novalidate class="needs-validation" (ngSubmit)="onSubmit()">
            <div class="panel-content">
                <div class="form-row">
                    <h2>Dados da Matrícula</h2>
                    <div class="col-12">
                        <div class="form-row mb-3 mt-3 px-1">
                            <div class="col-4">
                                <label>Id</label>
                                <label class="form-control font-weight-bold disabled">{{studentCourseData?.id}}</label>
                            </div>
                            <div class="col-8">
                                <label>Identifier</label>
                                <label class="form-control font-weight-bold disabled">{{studentCourseData?.identifier}}</label>
                            </div>
                        </div>
                        <div class="form-row mb-3 mt-3 px-1">
                            <div class="col-12">
                                <label>Nome do aluno:</label>
                                <label class="form-control font-weight-bold disabled">{{studentCourseData?.studentName}}</label>
                            </div>
                        </div>
                        <div class="form-row mb-3 mt-3 px-1">
                            <div class="col-6">
                                <label>E-mail:</label>
                                <label class="form-control font-weight-bold disabled">{{studentCourseData?.student?.email}}</label>
                            </div>
                            <div class="col-6">
                                <label>Telefone:</label>
                                <label class="form-control font-weight-bold disabled">{{studentCourseData?.student?.phone}}</label>
                            </div>
                        </div>
                        <div class="form-row mb-3 mt-3 px-1">
                            <div class="col-6">
                                <label>Turma:</label>
                                <label class="form-control font-weight-bold disabled">{{studentCourseData?.courseName}}</label>
                            </div>
                            <div class="col-6">
                                <label>Nome do professor:</label>
                                <label class="form-control font-weight-bold disabled">{{studentCourseData?.teacherName}}</label>
                            </div>
                        </div>
                        <div class="form-row mb-3 mt-3 px-1">
                            <div class="col-3 pr-4">
                                <label>Status do Pagamento</label><br>
                                <div [innerHtml]="getStatusPagamento(studentCourseData?.paymentStatus)" class="mb-3"></div>
                            </div>
                        </div>
            
                        <div class="form-row mb-3 mt-3 px-1">
                            <div class="col-12 pr-4">
                                <div class="subheader">
                                    <div class="subheader-block d-lg-flex align-items-center">
                                        <div class="d-inline-flex flex-column justify-content-center mr-3">
                                            <span class="fw-300 fs-xs d-block text-muted">1ª parcela:</span>
                                            <span class="fw-500 fs-xl d-block color-info-500">{{studentCourseData?.installmentFirstDue | date: 'dd/MM/yyyy'}}</span>
                                        </div>
                                    </div>
                                    <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                        <div class="d-inline-flex flex-column justify-content-center mr-3">
                                            <span class="fw-300 fs-xs d-block text-muted">Demais vencimentos:</span>
                                            <span class="fw-500 fs-xl d-block color-info-500">{{studentCourseData?.dueDay}}</span>
                                        </div>
                                    </div>
                                    <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                        <div class="d-inline-flex flex-column justify-content-center mr-3">
                                            <span class="fw-300 fs-xs d-block text-muted">Início das aulas:</span>
                                            <span class="fw-500 fs-xl d-block color-primary-500">{{ getFormatDate(studentCourseData?.startDate) }}</span>
                                        </div>
                                    </div>
                                    <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                        <div class="d-inline-flex flex-column justify-content-center mr-3">
                                            <span class="fw-300 fs-xs d-block text-muted">Término das aulas:</span>
                                            <span class="fw-500 fs-xl d-block color-primary-500">{{ getFormatDate(studentCourseData?.endDate) }}</span>
                                        </div>
                                    </div>
                                    <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                        <div class="d-inline-flex flex-column justify-content-center mr-3">
                                            <span class="fw-300 fs-xs d-block text-muted">Duração:</span>
                                            <span class="fw-500 fs-xl d-block color-primary-500">{{studentCourseData?.monthTotal}} meses</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="form-row mb-3 mt-3 px-1">
                            <div class="col-3">
                                <label>Total de aulas</label>
                                <label class="form-control font-weight-bold text-primary disabled">{{studentCourseData?.totalClasses}}</label>
                            </div>
                            <div class="col-3">
                                <label>Aulas por semana</label>
                                <label class="form-control font-weight-bold text-info disabled">{{studentCourseData?.classesPerWeek}}</label>
                            </div>
                            <div class="col-3">
                                <label>Aulas confirmadas</label>
                                <label class="form-control font-weight-bold text-success disabled">{{studentCourseData?.confirmedClasses}}</label>
                            </div>
                            <div class="col-3">
                                <label>Aulas restante</label>
                                <label class="form-control font-weight-bold text-info disabled">{{studentCourseData?.remainingClasses}}</label>
                            </div>
                        </div>
                        <hr>
                        <div class="form-group row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label" for="newCourseId">Nova Turma <span class="text-danger">*</span> </label>
                                    <input type="hidden" id="newCourseId" [value]="courseId" formControlName="newCourseId"/>
                                    <br>
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete 
                                          [data]="selectCourseList"
                                          searchKeyword="name"
                                          placeholder="Selecione a turma"
                                          (selected)='selectEvent($event)'
                                          (inputChanged)='onChangeSearch($event)'
                                          (inputFocused)='onFocused($event)'
                                          [itemTemplate]="itemTemplate"
                                          [notFoundTemplate]="notFoundTemplate">
                                        </ng-autocomplete>
                                        
                                        <ng-template #itemTemplate let-item>
                                            <a [innerHTML]="item.name"></a>
                                        </ng-template>
                                        
                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>

                                    <!-- <select class="form-control" id="course" placeholder="Selecione uma turma para matrícula" (change)="onCourseChange($event?.target?.value);" [(ngModel)]="selectedCourse">
                                        <option *ngFor="let item of selectCourseList" [value]="item.id">
                                            {{ item.text }}
                                        </option>
                                    </select> -->
                                    <!-- <br> -->
                                    <span class="text-primary small">Somente turmas com vagas disponíveis.</span>
                                    <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!showAvailableCourses">
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                        </button>
                                        {{errorMessagePreReg}}
                                    </div>
                                </div>
                                <div class="row mb-2" *ngIf="flagCourseSelected === true">
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="card-title text-primary fw-700">Dados do Curso</h5>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <p class="card-text">
                                                            Nome do Curso: <span class="fw-700">{{course?.name}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12">
                                                        <p class="card-text">
                                                            Professor: <span class="fw-700">{{course?.teacher.name}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-6">
                                                        <p class="card-text" [ngClass]="{ 'text-danger': course?.quantityMonth == 0 }">
                                                            Nº de meses: <span class="fw-700">{{course?.quantityMonth}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-6">
                                                        <p class="card-text" [ngClass]="{ 'text-danger': course?.quantityMonth == 0 }">
                                                            Nº de alunos: <span class="fw-700">{{course?.quantityStudent}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12">
                                                        <p class="card-text">
                                                            Pacote: <span class="fw-700">{{course?.product.name}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12">
                                                        <p class="card-text">
                                                            Valor Total: <span class="fw-700">{{course?.product.value | currency : 'R$'}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12" *ngIf="validCourseListMessage && !validCourse">
                                                        <hr>
                                                        <p *ngFor="let error of validCourseListMessage" class="card-text mb-1">
                                                            <small class="text-danger">{{error}}</small>
                                                        </p>
                                                        <br>
                                                        <button type="button" class="btn btn-info btn-sm" title="Revisar Curso" (click)="onReviewCourse()">
                                                            <span class="far fa-edit mr-1"></span>
                                                            Revisar Curso
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form-group row">
                            <div class="col-6">
                                <label class="form-label" for="startDate">Início na turma <span class="text-danger">*</span> </label>
                                <input type="date" id="startDate" formControlName="startDate" class="form-control" placeholder="Início do curso"
                                    [ngClass]="{ 'is-invalid': !validStudentCourse && f.startDate.errors }" required (change)="autoCalcEndDate(null)">
                                <div *ngIf="!validStudentCourse && f.startDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.startDate.errors.required">Por favor, informe a data de início.</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label class="form-label" for="endDate">Fim na turma <span class="text-danger">*</span> </label>
                                <input type="date" id="endDate" formControlName="endDate" class="form-control" placeholder="Fim do curso"
                                    [ngClass]="{ 'is-invalid': !validStudentCourse && f.endDate.errors }" required>
                                <div *ngIf="!validStudentCourse && f.endDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.endDate.errors.required">Por favor, informe a data de término.</div>
                                    <div *ngIf="f.endDate.errors.invalidValue">A data de término precisa ser maior que a data de início.</div>
                                </div>
                            </div>
                            <!-- <div class="col-4">
                                <label class="form-label" for="dueDay">Dia de vencimento <span class="text-danger">*</span> </label>
                                <input type="number" id="dueDay" formControlName="dueDay" class="form-control" placeholder="Dia de vencimento das demais parcelas" 
                                    minlength="1" maxlength="31" [ngClass]="{ 'is-invalid': !validCourse && f.dueDay.errors }" required>
                                    <div class="text-info small mt-1">
                                    <div>Dia de vencimento das demais parcelas</div>
                                </div>
                                <div *ngIf="!validCourse && f.dueDay.errors" class="invalid-feedback">
                                    <div *ngIf="f.dueDay.errors.required">Por favor, informe a dia de vencimento das demais parcelas.</div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validStudentCourse">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true"><i class="fal fa-times"></i></span>
                    </button>
                    {{errorMessage}}
                </div>
                <div *ngIf="listErrorMessage && !validStudentCourse" class="invalid-feedback">
                    <div *ngFor="let error of listErrorMessage">{{error}}</div>
                </div>
                <hr>
                <div class="row no-gutters">
                    <div class="col-12 ml-auto text-right">
                        <div class="btn-group btn-panel">
                            <button id="js-login-btn" class="btn btn-primary" [disabled]="submitted">
                                <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                Salvar
                            </button>
                            <button id="js-cancel-btn" class="btn btn-default" (click)="onCancel()">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>