<!--Table rows-->
<div id="panel-3" class="panel">
    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
            <span class="fw-300"><i>Aulas</i></span>
        </h2>
    </div>
    <div class="panel-container show">
        <div class="panel-content">
            <h5 class="frame-heading">
                Aluno: <i>João Victor Silva Teixeira</i>
            </h5>
            <div class="frame-wrap">
                <!--The content below is only a placeholder and can be replaced.-->
                <div class="container">
                    <div>
                    <h3>
                        {{ title }}
                    </h3>
                    <h4>Custom Theme</h4>
                    <a href="https://github.com/NileshPatel17/ng-multiselect-dropdown"
                        >Github Code</a
                    >
                    </div>
                    <div class="container">
                    <div class="row">
                        <div class="col">
                        <ng-multiselect-dropdown
                            name="country"
                            [settings]="dropdownSettings"
                            [(ngModel)]="selCountries"
                            [data]="countries"
                        >
                            <ng-template
                            #optionsTemplate
                            let-item
                            let-option="option"
                            let-id="id"
                            let-isSelected="isSelected"
                            >
                            <div
                                style="
                                padding: 1px 10px;
                                margin-right: 10px;
                                right: 2px;
                                top: -2px;
                                "
                            >
                                <i class="fa fa-circle text-sucess"></i>
                                <!-- [src]=""
                                style="width: 18px; margin: 0 20px;"
                                /> -->
                                <label
                                style="color: #333; min-width: 130px; font-weight: normal;"
                                [ngStyle]="{ 'font-weight': isSelected? 'bold':' normal','color': getItems[id].isDisabled? 'lightgrey': ''}"
                                ><i class="fa fa-circle text-sucess"></i> {{getItems[id].item_text}}</label
                                >
                            </div>
                            </ng-template>
                            <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                                <div>
                                    <i class="fa fa-circle text-sucess"></i>
                                    <!-- <img
                                    [src]="getItems[id].image"
                                    style="width: 18px; margin-right: 2px;"
                                    /> -->
                                    {{option}}
                                </div>
                            </ng-template>
                        </ng-multiselect-dropdown>
                        </div>
                    </div>
                    </div>
                </div>  
                <!-- <form [formGroup]="myForm">
                    <ng-multiselect-dropdown
                        name="city"
                        [settings]="dropdownSettings"
                        [(ngModel)]="selectedItems"
                        [data]="cities"
                        (onSelect)="onItemSelect($event)">
                    </ng-multiselect-dropdown>
                </form> -->
                <!-- <table class="table table-striped m-0">
                    <thead>
                        <tr>
                            <th class="text-center">Nº da Aula</th>
                            <th class="text-center">Professor</th>
                            <th class="text-center">Data Prevista</th>
                            <th class="text-center">Data Realizada</th>
                            <th class="text-center">Aula Confirmada?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="text-center" scope="row">1</th>
                            <td class="text-center text-primary fw-700">Vanessa Costa Suarez</td>
                            <td class="text-center">23/03/2021</td>
                            <td class="text-center text-info fw-700">23/03/2021</td>
                            <td class="text-center">
                                <span class="badge badge-success badge-pill">Confirmado</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">2</th>
                            <td class="text-center text-primary fw-700">João Gabriel da Silva</td>
                            <td class="text-center">30/03/2021</td>
                            <td class="text-center text-info fw-700">30/03/2021</td>
                            <td class="text-center">
                                <span class="badge badge-success badge-pill">Confirmado</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">3</th>
                            <td class="text-center text-primary fw-700">Victor Paulino Andrade</td>
                            <td class="text-center">06/04/2021</td>
                            <td class="text-center text-info fw-700">03/04/2021</td>
                            <td class="text-center">
                                <span class="badge badge-info badge-pill">Antecipado</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">4</th>
                            <td class="text-center text-primary fw-700">Sandra Valentino Pires</td>
                            <td class="text-center">13/04/2021</td>
                            <td class="text-center text-info fw-700">-</td>
                            <td class="text-center">
                                <span class="badge badge-warning badge-pill text-white">Pendente</span>
                            </td>
                        </tr>
                    </tbody>
                </table> -->
            </div>
        </div>
    </div>
</div>
