import { User } from "@app/auth/model";
import { ContentSerie } from "@app/content/model";
import { Base } from "@app/shared/model";

export class SerieComment extends Base {
    grade: number;
    comment: string;
    recommendationFlag: boolean;

    contentSerieId: number;
    contentSerie: ContentSerie;

    userId: number;
    user: User;

    constructor(contentResponse: any) {
        super(contentResponse);
        
        this.grade = parseInt(contentResponse.grade);
        this.comment = contentResponse.comment;
        this.recommendationFlag = contentResponse.recommendationFlag;

        this.contentSerieId = parseInt(contentResponse.contentSerieId);
        if (contentResponse && contentResponse.contentSerieId > 0) {
            this.contentSerie = contentResponse.contentSerie;
        }

        this.userId = parseInt(contentResponse.userId);
        if (contentResponse && contentResponse.userId > 0) {
            this.user = contentResponse.user;
        }
    }
}
