import { Base } from '@app/shared/model';
import { User } from '@app/auth/model';
import { GroupNotification } from '.';

export class UserGroupNotification extends Base {
    groupNotificationId: number;
    groupNotification: GroupNotification;
    userId: number;
    user: User;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.groupNotificationId = parseInt(contentResponse.groupNotificationId);
        if (contentResponse && contentResponse.groupNotificationId > 0) {
            this.groupNotification = contentResponse.groupNotification;
        }

        this.userId = parseInt(contentResponse.userId);
        if (contentResponse && contentResponse.userId > 0) {
            this.user = contentResponse.user;
        }
    }
}
