import { Base } from '@app/shared/model';
import { CheckList, StudentCheckListItem } from '.';

export class CheckListItem extends Base {
    order: number;
    description: string;
    
    checkListId: number;
    checkList: CheckList;

    studentCheckListItems: Array<StudentCheckListItem>;

    constructor(contentResponse: any) {
        super(contentResponse);
        this.order = contentResponse.order;
        this.description = contentResponse.description;

        this.checkListId = parseInt(contentResponse.checkListId);
        if (contentResponse && contentResponse.checkListId > 0) {
            this.checkList = contentResponse.checkList;
        }

        if (contentResponse.studentCheckListItems && contentResponse.studentCheckListItems.lenght > 0) {
            this.studentCheckListItems = contentResponse.studentCheckListItems;
        }
    }
}
