import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StudentCourseResume } from '@app/admin/model';
import { StudentCourseService } from '@app/admin/services';
import { TypeResponse, PaymentStatusCss, PaymentStatusLabel } from '@app/shared/enum';
import { MessageService } from '@app/shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { default as swal } from 'sweetalert2'

@Component({
  selector: 'studentcourse-info',
  templateUrl: './studentcourse-info.component.html'
})
export class StudentCourseInfoComponent implements OnInit {
  @Input("studentCourseIdentifier") studentCourseIdentifier: string;
  @Input("courseId") courseId: number;
  @Input("editMode") editMode: boolean;
  public flagSearchInfo = false;
  public validInfo: boolean = true;
  public studentCourseData: any;
  public googleDriveLink: string = '';
  public submitted: boolean = false;
  public errorMessage: string = '';
  private returnUrl = '/admin/studentCourselist';
  public previewMode: boolean = false;

  constructor(private loading: MessageService,
    private router: Router,
    private modalService: NgbModal,
    private studentCourseService: StudentCourseService) {
      this.studentCourseData = new StudentCourseResume();
      this.previewMode = this.editMode?.toString() !== 'true';
    }

  ngOnInit(): void {
    this.previewMode = this.editMode?.toString() !== 'true';
    if (this.studentCourseIdentifier) {
      this.getStudentCourseInfo(this.studentCourseIdentifier);
    }
  }

  getStudentCourseInfo(studentCourseIdentifier: any) {
    this.flagSearchInfo = true;
    this.studentCourseService.getInfo(studentCourseIdentifier).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.studentCourseData = response.data;
        this.googleDriveLink = this.studentCourseData.googleDriveLink;
        this.studentCourseData.designStatusPayment = this.getStatusPagamento(this.studentCourseData.paymentStatus);
      } else {
        this.studentCourseData = null;
      }
      this.flagSearchInfo = false;
      this.loading.hideLoading();
    }, (error: any) => {
      this.flagSearchInfo = false;
      this.loading.hideLoading();
    });
  }

  getStatusPagamento(paymentStatus: any) {
    if (paymentStatus >= 0) {
      let paymentStatusCss = PaymentStatusCss.get(paymentStatus);
      let paymentStatusStr = PaymentStatusLabel.get(paymentStatus)
      return "<span class='badge badge-pill w-100 "
        + paymentStatusCss +"'>"
        + paymentStatusStr +"</span>";
    }
  }

  onSubmit() {
    this.loading.showLoading();
    this.submitted = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    let studentCourseNewLink = {
      id: this.studentCourseData.id,
      googleDriveLink: this.googleDriveLink
    };

    this.studentCourseService.setNewLink(this.studentCourseData.id, studentCourseNewLink).pipe(first())
    .subscribe((dataReturn: any) => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.loading.hideLoading();
        this.dismissModal();
        
        swal({
          type: 'success',
          title: 'Alteração realizada com sucesso!',
          showConfirmButton: false,
          timer: 2500
        }).then(() => {
          return this.returnUrlList();
        });
      } else {
        this.errorMessage = dataReturn.message;
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (error: any) => {
      this.submitted = false;
      this.loading.hideLoading();
      if (error.status == 400) {
        this.errorMessage = 'Erro ao alterar o link do Google Drive! Verifique as informações e tente novamente.';
      } else {
        this.errorMessage = error;
      }
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private returnUrlList() {
    this.router.navigate([this.returnUrl, { courseId: this.courseId }]);
  }
}
