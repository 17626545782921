import { Base } from '@app/shared/model';
import { CheckListItem, Student, StudentCheckList } from '.';

export class StudentCheckListItem extends Base {
    studentCheckListId: number;
    studentCheckList: StudentCheckList;

    checkListItemId: number;
    checkListItem: CheckListItem;

    studentId: number;
    student: Student;

    checked: boolean;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.studentCheckListId = parseInt(contentResponse.studentCheckListId);
        if (contentResponse && contentResponse.studentCheckListId > 0) {
            this.studentCheckList = contentResponse.studentCheckList;
        }

        this.checkListItemId = parseInt(contentResponse.checkListItemId);
        if (contentResponse && contentResponse.checkListItemId > 0) {
            this.checkListItem = contentResponse.checkListItem;
        }

        this.studentId = parseInt(contentResponse.studentId);
        if (contentResponse && contentResponse.studentId > 0) {
            this.student = contentResponse.student;
        }

        this.checked = contentResponse.checked;
    }
}
