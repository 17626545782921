import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';

// Bootstrap
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';

// Core Module
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';

// Full Calendar
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AdminModule } from './admin/admin.module';
import { StudentModule } from './student/student.module';
import { TeacherModule } from './teacher/teacher.module';
import { FinancialModule } from './financial/financial.module';
import { MarketingModule } from './marketing/marketing.module';
import { ContentModule } from './content/content.module';
import { AuditModule } from './audit/audit.module';
import { MessageModule } from './message/message.module';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// Main Components
import { AppComponent } from './app.component';

// Helpers
import { TextMaskModule } from './_helpers/mask/text.mask';
import { ErrorInterceptor } from './_helpers/';
import { JwtInterceptor } from './auth/helpers/'
import { EventService } from './shared/components/calendar/event.service';

// Imported Syncfusion RichTextEditorModule from Rich Text Editor package
import { NgxSummernoteModule } from 'ngx-summernote';
import { ReportsModule } from './reports/reports.module';

// Sentry

registerLocaleData(localePt);

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AdminModule,
    AuthModule,
    StudentModule,
    MessageModule,
    TeacherModule,
    FinancialModule,
    MarketingModule,
    AuditModule,
    ContentModule,
    ReportsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TextMaskModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(122,89,173,0.5)',
      backdropBorderRadius: '4px',
      primaryColour: '#2196F3', 
      secondaryColour: '#0d8aee', 
      tertiaryColour: '#0c7cd5'
    }),
    PdfViewerModule,
	  ToastrModule.forRoot(),
    FullCalendarModule, // register FullCalendar with you app
    NgxSummernoteModule
  ],
  
  providers: [
    Title, EventService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue : '/' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        var dataTitle = this.route.snapshot.firstChild.data['title']
          ? ' | ' + this.route.snapshot.firstChild.data['title']
          : '';
        var title = 'English Coffee' + dataTitle;
        this.titleService.setTitle(title);
      }
    });
  }
}
