<div class="modal-header">
    <h5 class="modal-title">Dados de pré-matrícula</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group row">
        <div class="col-12">
            <div class="form-group">
                <label class="form-label" for="courseId">Turma <span class="text-danger">*</span> </label>
                <br>
                <select class="form-control" id="statusCrm" placeholder="Selecione uma turma para matrícula" (change)="onCourseChange($event?.target?.value);" [(ngModel)]="selectedCourse">
                    <option *ngFor="let item of selectCourseList" [value]="item.id">
                        {{ item.text }}
                    </option>
                </select>

                <!-- <ng-select2
                    [data]="selectCourseList"
                    [(ngModel)]="selectedCourse"
                    [options]="options"
                    (valueChanged)="onCourseChange($event)"
                    [placeholder]="'Selecione uma turma para matrícula'"
                    >
                </ng-select2> -->
                <br>
                <span class="text-primary small">Somente turmas com vagas disponíveis.</span>

                <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!showAvailableCourses">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true"><i class="fal fa-times"></i></span>
                    </button>
                    {{errorMessagePreReg}}
                </div>
            </div>
            <div class="row mb-2" *ngIf="flagCourseSelected === true">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-primary fw-700">Dados do Curso</h5>
                            <div class="row">
                                <div class="col-12">
                                    <p class="card-text">
                                        Nome do Curso: <span class="fw-700">{{course.name}}</span>
                                    </p>
                                </div>
                                <div class="col-12">
                                    <p class="card-text">
                                        Professor: <span class="fw-700">{{course.teacher.name}}</span>
                                    </p>
                                </div>
                                <div class="col-6">
                                    <p class="card-text" [ngClass]="{ 'text-danger': course.quantityMonth == 0 }">
                                        Nº de meses: <span class="fw-700">{{course.quantityMonth}}</span>
                                    </p>
                                </div>
                                <div class="col-6">
                                    <p class="card-text" [ngClass]="{ 'text-danger': course.quantityMonth == 0 }">
                                        Nº de alunos: <span class="fw-700">{{course.quantityStudent}}</span>
                                    </p>
                                </div>
                                <div class="col-12">
                                    <p class="card-text">
                                        Pacote: <span class="fw-700">{{course.product.name}}</span>
                                    </p>
                                </div>
                                <div class="col-12">
                                    <p class="card-text">
                                        Valor Total: <span class="fw-700">{{course.product.value | currency : 'R$'}}</span>
                                    </p>
                                </div>
                                <div class="col-12" *ngIf="validCourseListMessage && !validCourse">
                                    <hr>
                                    <p *ngFor="let error of validCourseListMessage" class="card-text mb-1">
                                        <small class="text-danger">{{error}}</small>
                                    </p>
                                    <br>
                                    <button type="button" class="btn btn-info btn-sm" title="Revisar Curso" (click)="onReviewCourse()">
                                        <span class="far fa-edit mr-1"></span>
                                        Revisar Curso
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6 col-xs-12 mt-3">
            <div class="form-group">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="isPriceCustom" [(ngModel)]="isPriceCustom" (change)="onSetCustomPrice($event)">
                    <label class="custom-control-label" for="isPriceCustom">Pacote/valores diferenciados?</label>
                </div>
            </div>
        </div>
        <div class="col-6 col-xs-12 mt-3" *ngIf="flagCustomPrice">
            <label class="form-label" for="customPriceValue">Preço customizado do pacote <span class="text-danger">*</span> </label>
            <input type="text" id="customPriceValue" currencyMask class="form-control"
                placeholder="Preço customizado do pacote" [(ngModel)]="customPriceValue">
            <p class="card-text text-right mt-2 mb-1">
                Valor Total: <span class="fw-700">{{customPriceValue | currency : 'R$'}}</span>
            </p>
            <p class="card-text text-right mt-2 mb-1">
                Nº de meses: <span class="fw-700">{{numberOfMonth}}</span>
            </p>
            <p class="card-text text-right mt-2 mb-1">
                Valor mensal: <span class="fw-700">{{customPriceValue / numberOfMonth | currency : 'R$'}}</span>
            </p>
        </div>
    </div>
    <hr>
    <div class="form-group row">
        <div class="col-6">
            <label class="form-label" for="startDate">Início do curso <span class="text-danger">*</span> </label>
            <br>
            <input type="date" id="startDate" name="startDate" data-date-format="dd/MM/YYYY" placeholder="dd/mm/yyyy"
                (change)="updateDateStartDate($event)" min="2000-01-01" max="2030-12-31" placeholder="Início do curso"
                [ngClass]="{ 'is-invalid': !validPreRegContact }">
            <!-- <input type="date" id="startDate" class="form-control" placeholder="Início do curso"
                (change)="onMonthCalcChange($event.target.value)" [ngClass]="{ 'is-invalid': !validPreRegContact }" [(ngModel)]="startDate" required> -->
        </div>
        <div class="col-6">
            <label class="form-label" for="endDate">Fim do curso <span class="text-danger">*</span> </label>
            <br>
            <input type="date" id="endDate" name="endDate" data-date-format="dd/MM/YYYY" placeholder="dd/mm/yyyy"
                (change)="updateDateEndDate($event)" min="2000-01-01" max="2030-12-31" placeholder="Início do curso"
                [ngClass]="{ 'is-invalid': !validPreRegContact }">
            <!-- <input type="date" id="endDate" class="form-control" placeholder="Fim do curso"
                (change)="onMonthCalcChange($event.target.value)" [ngClass]="{ 'is-invalid': !validPreRegContact }" [(ngModel)]="endDate" required> -->
        </div>
    </div>
    <div class="form-group row">
        <div class="col-6">
            <label class="form-label" for="installmentFirstDue">Vencimento da 1ª mensalidade <span class="text-danger">*</span> </label>
            <br>
            <input type="date" id="installmentFirstDue" name="installmentFirstDue" data-date-format="dd/MM/YYYY" placeholder="dd/mm/yyyy"
                (change)="updateDateInstallmentFirstDue($event)" min="2000-01-01" max="2030-12-31" placeholder="Vencimento da 1ª mensalidade"
                [ngClass]="{ 'is-invalid': !validPreRegContact }">
            <!-- <input type="date" id="installmentFirstDue" class="form-control" placeholder="Vencimento da 1ª mensalidade"
                [ngClass]="{ 'is-invalid': !validPreRegContact }" [(ngModel)]="installmentFirstDue" required> -->
        </div>
        <div class="col-6">
            <label class="form-label" for="dueDay">Dia de vencimento das demais parcelas <span class="text-danger">*</span> </label>
            <input type="number" id="dueDay" class="form-control" placeholder="Dia de vencimento das demais parcelas" minlength="1" maxlength="31"
                [ngClass]="{ 'is-invalid': !validPreRegContact }" [(ngModel)]="dueDay" required>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-12">
            <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validPreRegContact || !showTable">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
                {{errorMessage}}
            </div>
        </div>
    </div>
    <div class="form-group row">
        <hr>
        <div class="col-12" *ngIf="showTable">
            <div class="table-responsive text-nowrap">
                <table class="table table-striped m-0">
                    <thead>
                        <tr>
                            <th class="text-left">Turma</th>
                            <th class="text-center">Tipo</th>
                            <th class="text-center">Início</th>
                            <th class="text-center">Fim</th>
                            <th class="text-center">1ª<br>Mensalidade</th>
                            <th class="text-center">Dia de<br>Vencimento</th>
                            <th class="text-center">Preço<br>Customizado?</th>
                            <th class="text-center">Valor<br>Customizado</th>
                            <th class="text-center">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor='let reg of preRegDataList'>
                            <td class="text-left text-primary fw-700">{{reg.course.name}}</td>
                            <td class="text-center" [innerHTML]="reg.designCourseType"></td>
                            <td class="text-center text-primary fw-700">{{reg.startDate | date:'dd/MM/yyyy'}}</td>
                            <td class="text-center text-primary fw-700">{{reg.endDate | date:'dd/MM/yyyy'}}</td>
                            <td class="text-center text-info fw-700">{{reg.installmentFirstDue | date:'dd/MM/yyyy'}}</td>
                            <td class="text-center">
                                <span class="badge badge-primary badge-pill p-2">{{reg.dueDay}}</span>
                            </td>
                            <td class="text-center">
                                <i *ngIf="reg.isPriceCustom" class="fa fa-2x fa-check text-success" title="Preço é customizado"></i>
                                <i *ngIf="!reg.isPriceCustom" class="fa fa-2x fa-times text-danger" title="Preço não é customizado"></i>
                            </td>
                            <td class="text-center text-primary currency fw-700" [innerHTML]="formatCurrency(reg.customPriceValue)"></td>
                            <td class="grid-col-fixed-right">
                                <p class="text-center m-0">
                                    <a (click)="delete(reg)" title="Excluir" href="javascript:;" class="btn btn-danger btn-icon btn-circle btn-sm m-x-5">
                                        <i class="fa fa-times"></i>
                                    </a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="cancel()">
        <i class="fa fa-times-circle mr-1"></i> Cancelar
    </button>
    <button type="button" class="btn btn-sm btn-success waves-effect waves-themed" (click)="done()" [disabled]="submitted">
        <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
        <i class="fa fa-check mr-1"></i> Confirmar
    </button>
</div>
