<div class="page-wrapper">
    <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
            <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                <div class="d-flex align-items-center container p-0">
                    <header-logo></header-logo>
                    <a class="btn btn-sm btn-outline-primary text-white ml-auto" href="javascript:void(0)" (click)="logout();">
                        <span>Sair</span>
                    </a>
                    <!-- <a href="#/auth/register" class="btn btn-sm btn-outline-primary text-white ml-auto">
                        Criar uma conta
                    </a> -->
                </div>
            </div>
            <div class="d-flex flex-1" style="background: url(/assets/img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
                <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0 text-white d-flex align-items-center justify-content-center">
                    <form id="js-login" action="intel_analytics_dashboard.html" role="form" class="text-center text-white mb-5 pb-5">
                        <div class="py-3">
                            <img src="/assets/img/avatars/avatar-admin-lg.png" class="img-responsive rounded-circle img-thumbnail" alt="thumbnail">
                        </div>
                        <div class="form-group">
                            <h3 class="mb-5">
                                {{user.name}}
                                <small>
                                    {{user.username}}
                                </small>
                            </h3>
                            <h3 class="text-white opacity-75 mb-5">Selecione um módulo para continuar</h3>
                            <div class="row">
                                <div class="col-12">
                                    <div class="card-deck">
                                        <div class="card border-info">
                                            <div class="w-100 bg-info rounded-top" style="padding: 20px 0 20px;">
                                                <i class="fa fa-users fa-4x"></i>
                                            </div>
                                            <div class="card-body text-info">
                                                <h5 class="card-title fw-900">ALUNO</h5>
                                                <p class="card-text">Área exclusiva para o(a) aluno(a)</p>
                                            </div>
                                            <div class="card-footer">
                                                <button type="button" class="btn btn-info btn-pills btn-block waves-effect waves-themed">
                                                    <span>Acessar</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card border-success">
                                            <div class="w-100 bg-success rounded-top" style="padding: 20px 0 20px;">
                                                <i class="fa fa-university fa-4x"></i>
                                            </div>
                                            <div class="card-body text-success">
                                                <h5 class="card-title fw-900">PROFESSOR</h5>
                                                <p class="card-text">Área exclusiva para o(a) professor(a)</p>
                                            </div>
                                            <div class="card-footer">
                                                <button type="button" class="btn btn-success btn-pills btn-block waves-effect waves-themed">
                                                    <span>Acessar</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card border-warning">
                                            <div class="w-100 bg-warning rounded-top" style="padding: 20px 0 20px;">
                                                <i class="fa fa-home fa-4x"></i>
                                            </div>
                                            <div class="card-body text-warning">
                                                <h5 class="card-title fw-900">ADMINISTRATIVO</h5>
                                                <p class="card-text">Área exclusiva para administração.</p>
                                            </div>
                                            <div class="card-footer">
                                                <button type="button" class="btn btn-warning btn-pills btn-block waves-effect waves-themed text-white">
                                                    <span>Acessar</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card border-danger disabled">
                                            <div class="w-100 bg-danger rounded-top" style="padding: 20px 0 20px;">
                                                <i class="fa fa-book fa-4x"></i>
                                            </div>
                                            <div class="card-body text-danger">
                                                <h5 class="card-title fw-900">BIBLIOTECA</h5>
                                                <p class="card-text">Área contendo material de leitura e pesquisa.</p>
                                            </div>
                                            <div class="card-footer">
                                                <button type="button" class="btn btn-danger btn-pills btn-block waves-effect waves-themed disabled">
                                                    <span>Acessar</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <a href="#/auth/login" class="text-white opacity-90">Não é <b>{{user.name}}</b>? Clique aqui</a>
                        </div>
                    </form>
                    <footer-auth></footer-auth>
                </div>
            </div>
        </div>
    </div>
</div>
