<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item"><a href="javascript:void(0);">English Coffee</a></li>
    <li class="breadcrumb-item active">Home</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>

<div class="d-md-none">
    <div class="subheader mb-2">
        <h1 class="subheader-title">
            <i class='subheader-icon fal fa-home'></i> Principal <span class='fw-300'>Dashboard</span>
        </h1>
    </div>
    <div class="subheader mb-2">
        <h1>
            <small class="mb-2">
                Portal administrativo de conteúdo e dados dos módulos English Coffee 
            </small>
        </h1>
    </div>
    <div class="subheader mb-2 row" *ngIf="canSeeHome()">
        <div class="col-4">
            <div class="mr-2">
                <h1 class="display-3 text-center text-info fw-800 mb-0">{{plannedClassesCount}}</h1>
            </div>
            <div class="text-center">
                <label class="fs-sm mb-0 mt-2 mt-md-0">Aulas<br>Planejadas</label>
                <h4 class="font-weight-bold mb-0">0% / mês</h4>
            </div>
        </div>
        <div class="col-4">
            <div class="mr-2">
                <h1 class="display-3 text-center text-primary fw-800 mb-0">{{confirmedClassesCount}}</h1>
            </div>
            <div class="text-center">
                <label class="fs-sm mb-0 mt-2 mt-md-0">Aulas<br>Confirmadas</label>
                <h4 class="font-weight-bold mb-0">0% / mês</h4>
            </div>
        </div>
        <div class="col-4">
            <div class="mr-2">
                <h1 class="display-3 text-center text-danger fw-800 mb-0">{{canceledClassesCount}}</h1>
            </div>
            <div class="text-center">
                <label class="fs-sm mb-0 mt-2 mt-md-0">Aulas<br>Canceladas</label>
                <h4 class="font-weight-bold mb-0">0 / mês</h4>
            </div>
        </div>
    </div>
</div>

<div class="d-none d-md-block">
    <div class="subheader">
        <h1 class="subheader-title">
            <i class='subheader-icon fal fa-home'></i> Principal <span class='fw-300'>Dashboard</span>
            <small>
                Portal administrativo de conteúdo e dados dos módulos English Coffee 
            </small>
        </h1>
    </div>
</div>

<div class="row mb-3" *ngIf="canSeeHome()">
    <div class="d-flex mr-4 ml-4 col">
        <div class="mr-2">
            <canvas id="chartPlanned_Scheduled" width="45" height="45"></canvas>
        </div>
        <div>
            <label class="fs-sm mb-0 mt-2 mt-md-0">Agendada</label>
            <h4 class="font-weight-bold mb-0">70,07%</h4>
        </div>
    </div>
    <div class="d-flex mr-4 ml-4 col">
        <div class="mr-2">
            <canvas id="chartPlanned_Done" width="45" height="45"></canvas>
        </div>
        <div>
            <label class="fs-sm mb-0 mt-2 mt-md-0">Concluída</label>
            <h4 class="font-weight-bold mb-0">6,22%</h4>
        </div>
    </div>
    <div class="d-flex mr-4 ml-4 col">
        <div class="mr-2">
            <canvas id="chartPlanned_ChangedTeacher" width="45" height="45"></canvas>
        </div>
        <div>
            <label class="fs-sm mb-0 mt-2 mt-md-0">Professor(a) Alterado(a)</label>
            <h4 class="font-weight-bold mb-0">0,17%</h4>
        </div>
    </div>
    <div class="d-flex mr-4 ml-4 col">
        <div class="mr-2">
            <canvas id="chartPlanned_Reschedule" width="45" height="45"></canvas>
        </div>
        <div>
            <label class="fs-sm mb-0 mt-2 mt-md-0">Reagendada</label>
            <h4 class="font-weight-bold mb-0">0,66%</h4>
        </div>
    </div>
    <div class="d-flex mr-4 ml-4 col">
        <div class="mr-2">
            <canvas id="chartPlanned_PartialConfirmed" width="45" height="45"></canvas>
        </div>
        <div>
            <label class="fs-sm mb-0 mt-2 mt-md-0">Confirmada Parcial</label>
            <h4 class="font-weight-bold mb-0">22,72%</h4>
        </div>
    </div>
    <div class="d-flex mr-4 ml-4 col">
        <div class="mr-2">
            <canvas id="chartPlanned_Unchecked" width="45" height="45"></canvas>
        </div>
        <div>
            <label class="fs-sm mb-0 mt-2 mt-md-0">Desmarcada</label>
            <h4 class="font-weight-bold mb-0">0,17%</h4>
        </div>
    </div>
</div>

<div class="row" *ngIf="canSeeHome()">
    <div class="col-sm-6 col-xl-3">
        <div class="p-3 bg-info-300 rounded overflow-hidden position-relative text-white mb-g">
            <div class="">
                <h3 class="display-4 d-block l-h-n m-0 fw-500">
                    {{(dashboardCount$ | async)?.studentsCount}}
                    <small class="m-0 l-h-n">Total de Alunos</small>
                </h3>
            </div>
            <i class="fal fa-user position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1" style="font-size:6rem"></i>
        </div>
    </div>
    <div class="col-sm-6 col-xl-3">
        <div class="p-3 bg-success-400 rounded overflow-hidden position-relative text-white mb-g">
            <div class="">
                <h3 class="display-4 d-block l-h-n m-0 fw-500">
                    {{(dashboardCount$ | async)?.studentCourseCount}}
                    <small class="m-0 l-h-n">Total de Matrículas</small>
                </h3>
            </div>
            <i class="fal fa-user position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1" style="font-size: 6rem;"></i>
        </div>
    </div>
    <div class="col-sm-6 col-xl-3">
        <div class="p-3 bg-warning-200 rounded overflow-hidden position-relative text-white mb-g">
            <div class="">
                <h3 class="display-4 d-block l-h-n m-0 fw-500">
                    {{(dashboardCount$ | async)?.coursesCount}}
                    <small class="m-0 l-h-n">Turmas Ativas</small>
                </h3>
            </div>
            <i class="fal fa-user position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1" style="font-size:6rem"></i>
        </div>
    </div>
    <div class="col-sm-6 col-xl-3">
        <div class="p-3 bg-primary-200 rounded overflow-hidden position-relative text-white mb-g">
            <div class="">
                <h3 class="display-4 d-block l-h-n m-0 fw-500">
                    {{(dashboardCount$ | async)?.sdrLeadsCount}}
                    <small class="m-0 l-h-n">Leads Pendentes (SDR)</small>
                </h3>
            </div>
            <i class="fal fa-user position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1" style="font-size: 6rem;"></i>
        </div>
    </div>
</div>

<div class="row" *ngIf="canSeeHome()">
    <div class="col-lg-6">
        <div id="panel-3" class="panel panel-locked" data-panel-sortable data-panel-collapsed data-panel-close>
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>
                    Conteúdo <span class="fw-300"><i>Visualização de Séries</i></span>
                </h2>
            </div>
            <div class="panel-container show">
                <div class="panel-content poisition-relative">
                    <div class="panel-tag">
                        Este gráfico ilustra a visualização de séries nas aulas por alunos e professores, oferecendo uma visão abrangente do engajamento com o conteúdo ao longo do tempo.
                    </div>
                    <div style="max-height: 300px !important;">
                        <canvas id="chartContentSeries" style="width: 100%;"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div id="panel-2" class="panel panel-locked" data-panel-sortable data-panel-collapsed data-panel-close>
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>
                    Conteúdo <span class="fw-300"><i>Visualização de Episódios</i></span>
                </h2>
            </div>
            <div class="panel-container show">
                <div class="panel-content poisition-relative">
                    <div class="panel-tag">
                        Este gráfico exibe os top 20 episódios mais visualizados pelos alunos e professores durante as aulas, fornecendo uma visão dos conteúdos mais populares e seu impacto no engajamento.
                    </div>
                    <div style="max-height: 300px !important;">
                        <canvas id="chartContentEpisodes" style="width: 100%;"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="canSeeHome()">
    <div class="col-lg-12">
        <div id="panel-2" class="panel panel-locked" data-panel-sortable data-panel-collapsed data-panel-close>
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>
                    CRM <span class="fw-300"><i>Contadores de Status</i></span>
                </h2>
            </div>
            <div class="panel-container show">
                <div class="panel-content poisition-relative">
                    <div class="panel-tag">
                        Os contadores de status nesta seção oferecem uma rápida visualização dos dados do CRM, permitindo uma análise instantânea do desempenho e das métricas-chave.
                    </div>
                    <div class="card-columns">
                        <div *ngFor="let ct of (dashboardCount$ | async)?.crmStatusCount"
                            class="card bg-primary text-white text-center p-3" role="button"
                            (click)="loadContacts(ct.statusCrm)"
                            [ngClass]="getCrmStatusCss(ct.statusCrm)">
                            <h1 class="">{{ ct.count }}</h1>
                            <p class="">{{ getCrmStatusStr(ct.statusCrm) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="canSeeHome()">
<!-- 
    <div class="col-lg-12">
        <div id="panel-2" class="panel panel-locked" data-panel-sortable data-panel-collapsed data-panel-close>
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>
                    Mensalidades <span class="fw-300"><i>Planejadas vs. Recebidas</i></span>
                </h2>
            </div>
            <div class="panel-container show">
                <div class="panel-content poisition-relative">
                    <div class="panel-tag">
                        Balanço financeiro público é a demonstração contábil pública que evidencia os valores das receitas e despesas orçamentárias e extra-orçamentárias executadas, bem como os saldos das disponibilidades que foram recebidas do exercício anterior e os que serão passados para o exercício seguinte.
                    </div>
                    <div style="max-height: 300px !important;">
                        <canvas id="areaChartMarketingReturning" style="width: 100%;"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
     -->
    <!-- <div class="col-lg-12">
        <div id="panel-3" class="panel panel-locked" data-panel-sortable data-panel-collapsed data-panel-close>
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>
                    Aulas por <span class="fw-300"><i>Professores</i></span>
                </h2>
            </div>
            <div class="panel-container show">
                <div class="panel-content poisition-relative">
                    <div class="panel-tag">
                        Lorem ipsum lobortis tellus est donec quam, fusce quam curabitur class feugiat varius leo, posuere pharetra mi vivamus donec. phasellus bibendum malesuada habitant ac pretium dapibus tempor orci in convallis, placerat tincidunt habitant arcu sed aliquet dui dapibus nisi tempor vivamus, eros bibendum tincidunt sociosqu nec facilisis ante malesuada elementum. 
                    </div>
                    <div id="areaChartAulasProfessores" style="max-height: 300px !important;">
                        <canvas id="chartAulasProfessores" style="width: 100%;"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="col-lg-12">
        <div id="panel-1" class="panel">
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>
                    Posições disponíveis
                </h2>
            </div>
            <div class="panel-container show">
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="col-sm-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-sm-12" *ngIf="showTable">
                        <managed-table [dataTitle]="" [rows]="gridList" [columns]="columns" [sorting]="false" [hasActions]="true" [ngTableFiltering]="false" [showFilterRow]="false"
                            [previewEnable]="false" [editEnable]="false" [deleteEnable]="false" [hasFilter]="false"
                            [auxEnable]="true" [auxIcon]="auxIcon" [titleAux]="auxTitle" (auxClick)="manageStudents($event)">
                        </managed-table>
                    </div>
                </div>
            </div>
            <ng-template #mdAddStudentCourse let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h5 class="modal-title">Incluir aluno na turma</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
                        <span aria-hidden="true"><i class="fal fa-times"></i></span>
                    </button>
                </div>
                <div class="modal-body">
                    <studentcourse-add courseId="{{courseId}}"></studentcourse-add>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Close</button>
                </div>
            </ng-template>
        </div>
    </div>
</div>
