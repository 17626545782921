import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Helpers
import { AuthGuard } from '../auth/helpers/auth.guard';

//Components
import { HomeComponent } from '@app/student/components/home/home.component';
import { CourseComponent } from './components/course/course.component';
import { ContentListComponent } from './components/content/content-list/content-list.component';
import { ContentViewComponent } from './components/content/content-view/content-view.component';
import { ModuleType } from '@app/shared/enum/ModuleType';
import { CalendarComponent } from './components/calendar/calendar.component';
import { UpdateComponent } from './components/update/update.component';
import { NpsComponent } from './nps/nps.component';
import { WritingTestSolveComponent } from './components/writing-test/writing-test-solve/writing-test-solve.component';
import { WritingTestListComponent } from './components/writing-test/writing-test-list/writing-test-list.component';
import { ContentViewStreamComponent } from './components/content/content-view-stream/content-view-stream.component';
import { CalendarAsyncComponent } from './components/calendarasync/calendarasync.component';
import { ProfileComponent } from './components/profile/profile.component';
import { JitsiMeetingComponent } from './components/jitsi-meeting/jitsi-meeting.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Student, role: 'EST1DA6DA19', title: 'Home' },
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Student,
      role: 'EST1AG9AG22',
      title: 'Calendário',
    },
  },
  {
    path: 'calendarasync',
    component: CalendarAsyncComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Student,
      role: 'EST1AG9AG22',
      title: 'Calendário Async',
    },
  },
  {
    path: 'course',
    component: CourseComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Student, role: 'EST1AL7AL20', title: 'Curso' },
  },
  {
    path: 'contentlist',
    component: ContentListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Student, role: 'EST1AL7AL20', title: 'Cursos' },
  },
  {
    path: 'contentview',
    component: ContentViewComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Student,
      role: 'EST1AL7AL20',
      title: 'Visualização',
    },
  },
  {
    path: 'contentview-streaming',
    component: ContentViewStreamComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Student,
      role: 'EST1AL7AL20',
      title: 'Visualização',
    },
  },
  {
    path: 'update',
    component: UpdateComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Student, title: 'Atualização de perfil' },
  },
  {
    path: 'nps',
    component: NpsComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Student, role: 'EST1FB8AV38', title: 'NPS' },
  },
  {
    path: 'writingtestsolve',
    component: WritingTestSolveComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Student,
      role: 'EST1WT7AL37',
      title: 'Teste de escrita',
    },
  },
  {
    path: 'writingtestlist',
    component: WritingTestListComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Student,
      role: 'EST1WT7AL37',
      title: 'Teste de escrita',
    },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Student, role: 'EST1DA6DA19', title: 'Perfil' },
  },
  {
    path: 'meeting',
    component: JitsiMeetingComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Student,
      role: 'EST1AL7MT35',
      title: 'Aula ao Vivo',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StudentRoutingRoutes {}
