<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/marketing/contactbysitelist']">Gestão de Interessados</a>
    </li>
    <li class="breadcrumb-item active">Visualizar Contato</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div id="panel-2" class="panel">
    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
            Visualizar <span class="fw-300"><i>Contato</i></span>
        </h2>
        <div class="panel-toolbar">
            <a href="javascript:void(0);" class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse">
                <i class="fal fa-minus text-white fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen">
                <i class="fal fa-expand text-primary fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Close">
                <i class="fal fa-times text-white fw-900"></i>
            </a>
        </div>
    </div>
    <div class="panel-container show">
        <div class="panel-content p-0">
            <form [formGroup]="contactForm" novalidate class="needs-validation" (ngSubmit)="onSubmit()">
                <div class="panel-content">
                    <div class="form-row">
                        <input type="hidden" id="id" formControlName="id" *ngIf="!editMode"/>
                        <input type="hidden" id="createdOn" formControlName="createdOn" *ngIf="!editMode"/>
                        <input type="hidden" id="updatedOn" formControlName="updatedOn" *ngIf="!editMode"/>
                    </div>

                    <div class="form-row mb-3">
                        <!-- <div class="col-md-2" *ngIf="previewMode">
                            <label>Id</label>
                            <label class="form-control font-weight-bold disabled">{{f.id.value}}</label>
                        </div> -->
                        <div class="col-md-8">
                            <label class="form-label" for="name">Nome <span class="text-danger">*</span> </label>
                            <div *ngIf="previewMode">
                                <label class="form-control font-weight-bold disabled">{{f.name.value}}</label>
                            </div>
                            <div *ngIf="!previewMode">
                                <input type="text" id="name" formControlName="name" class="form-control" placeholder="Nome do Contato"
                                [ngClass]="{ 'is-invalid': !validContact && f.name.errors }" required>
                                <div *ngIf="!validContact && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Por favor, informe o nome do contato.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4" *ngIf="previewMode || editMode == false">
                            <label class="form-label text-center w-100" for="statusCrm">Status</label>
                            <br>
                            <span class="badge badge-pill w-100 status-sdr mt-2" *ngIf="previewMode"
                                [ngClass]="contactBySite?.id > 0 ? contactBySite?.statusCrmCss : ''">{{contactBySite?.statusCrmStr}}</span>
                            <span class="badge badge-pill w-100 status-sdr mt-2" *ngIf="!previewMode" [ngClass]="newStatusCrmCss">{{newStatusCrmStr}}</span>
                        </div>
                        <div class="col-md-4" *ngIf="!previewMode && this.contactBySite?.id > 0">
                            <label class="form-label" for="statusCrm">Status <span class="text-danger">*</span> </label>
                            <div class="ng-autocomplete w-100">
                                <ng-autocomplete class="w-100"
                                  [data]="statusCrmDataList"
                                  [initialValue]="statusInitialValue"
                                  searchKeyword="name"
                                  placeholder="Seleção de status"
                                  (selected)='selectEventStatus($event)'
                                  (inputChanged)='onChangeSearchStatus($event)'
                                  (inputFocused)='onFocusedStatus($event)'
                                  [itemTemplate]="itemTemplate"
                                  [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>
                                
                                <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.name"></a>
                                </ng-template>
                                
                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                            <span class="badge badge-pill w-100 status-sdr mt-2" *ngIf="!previewMode" [ngClass]="newStatusCrmCss">{{newStatusCrmStr}}</span>
                            <div *ngIf="!validUser && errors" class="invalid-feedback">
                                <div *ngFor="let e of errors">{{e}}.</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-3">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label" for="email">E-mail</label>
                                <div *ngIf="previewMode">
                                    <label class="form-control font-weight-bold disabled">{{f.email.value}}</label>
                                </div>
                                <div *ngIf="!previewMode">
                                    <input type="text" id="email" formControlName="email" class="form-control" placeholder="E-mail do contato"
                                        [ngClass]="{ 'is-invalid': !validContact && f.email.errors }" required>
                                    <div *ngIf="!validContact && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Por favor, informe o e-mail do contato.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label" for="phone">Telefone</label>
                                <div *ngIf="previewMode">
                                    <label class="form-control font-weight-bold disabled">{{f.phone.value}}</label>
                                </div>
                                <div *ngIf="!previewMode">
                                    <input type="text" id="phone" formControlName="phone" class="form-control" placeholder="Telefone do contato"
                                        [ngClass]="{ 'is-invalid': !validContact && f.phone.errors }" required>
                                    <div *ngIf="!validContact && f.phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">Por favor, informe o telefone do contato.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="previewMode">
                            <div class="form-group">
                                <label class="form-label" for="ipAddress">Endereço IP</label>
                                <label class="form-control font-weight-bold disabled">{{f.ipAddress.value}}</label>
                                <!-- <div *ngIf="!previewMode">
                                    <input type="text" id="ipAddress" formControlName="ipAddress" class="form-control" placeholder="Endereço IP"
                                        [ngClass]="{ 'is-invalid': !validContact && f.ipAddress.errors }" required>
                                    <div *ngIf="!validContact && f.ipAddress.errors" class="invalid-feedback">
                                        <div *ngIf="f.ipAddress.errors.required">Por favor, informe o endereço IP do contato.</div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-3" *ngIf="previewMode || editMode == true">
                        <div class="col-md-6" *ngIf="previewMode">
                            <div class="form-group">
                                <label class="form-label" for="experimentalClass">Aula experimental</label>
                                <label class="form-control font-weight-bold disabled">{{f.experimentalClass.value}}</label>
                            </div>
                        </div>

                        <div class="col-md-3" *ngIf="!previewMode">
                            <div class="form-group">
                                <input type="hidden" id="experimentalClass" formControlName="experimentalClass" />
                                <label class="form-label" for="experimentalDateValue">Data da aula experimental <span class="text-danger">*</span> </label>
                                <input type="date" name="experimentalDateValue" data-date-format="dd/MM/YYYY" placeholder="dd/mm/yyyy"
                                    [value]="experimentalDateValue" class="form-control" (change)="updateDate($event)" min="2000-01-01" max="2030-12-31">
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="!previewMode">
                            <div class="form-group">
                                <label class="form-label" for="experimentalTimeValue">Horário da aula experimental <span class="text-danger">*</span> </label>
                                <input type="time" id="experimentalTimeValue" required [ngModel]="experimentalTimeValue" 
                                    [value]="experimentalTimeValue" class="form-control" placeholder="Horário da aula experimental" (change)="setValueTime($event.target)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label" for="experimentalClassTeacherId">Professor(a)</label>
                                <select *ngIf="!previewMode" class="form-control" id="experimentalClassTeacherId" for="experimentalClassTeacherId" formControlName="experimentalClassTeacherId" placeholder="Professor(a)"
                                    [ngClass]="{ 'is-invalid': !validTeacher && f.experimentalClassTeacherId.errors }" (change)="onTeacherChange(f.experimentalClassTeacherId.value)">
                                    <option *ngFor="let teacher of teacherList" [ngValue]="teacher.id">
                                        {{ teacher.name }}
                                    </option>
                                </select>
                                <div *ngIf="previewMode">
                                    <label class="form-control font-weight-bold disabled">{{contactBySite?.experimentalClassTeacher?.name}}</label>
                                </div>
                            </div>
                            <div class="form-group row mb-2" *ngIf="flagTeacherSelected">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title text-primary fw-700">Dados do Professor</h5>
                                            <div class="form-group row mb-2">
                                                <div class="col-8">
                                                    <label class="form-label">Nome</label>
                                                    <p class="card-text fw-700">
                                                        {{teacher.name}}
                                                    </p>
                                                </div>
                                                <div class="col-4">
                                                    <label class="form-label">Localidade</label>
                                                    <p class="card-text fw-700">
                                                        {{teacher.cityName}} / {{teacher.stateUf}}
                                                    </p>
                                                </div>
                                                <div class="col-6">
                                                    <label class="form-label">Telefone</label>
                                                    <p class="card-text fw-700">
                                                        {{teacher.phone}}
                                                    </p>
                                                </div>
                                                <div class="col-6">
                                                    <label class="form-label">E-mail</label>
                                                    <p class="card-text fw-700">
                                                        {{teacher.email}}
                                                    </p>
                                                </div>
                                                <div class="col-6">
                                                    <label class="form-label">Graduação</label>
                                                    <p class="card-text fw-700">
                                                        {{teacher.universityGraduate}}
                                                    </p>
                                                </div>
                                                <div class="col-6">
                                                    <label class="form-label">Mais Informações</label>
                                                    <p class="card-text fw-700">
                                                        {{teacher.moreInformation}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="form-row mb-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="message">Mensagem</label>
                                <div *ngIf="previewMode">
                                    <div class="panel-tag" [innerHTML]="getFormattedMessage(f?.message?.value)"></div>
                                </div>
                                <div *ngIf="!previewMode">
                                    <textarea rows="3" id="message" formControlName="message" placeholder="Mensagem do contato"
                                    class="form-control rounded-top border-bottom-left-radius-0 border-bottom-right-radius-0 border"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row no-gutters">
                        <div class="col-12 mb-2">
                            <button type="button" class="btn btn-info btn-sm" data-toggle="tooltip" title="Visualizar Comentários" (click)="commentsView(mdComentsView);">Visualizar Comentários</button>
                        </div>
                        <div class="frame-wrap w-100">
                            <div class="accordion" id="js_demo_accordion-4">
                                <div class="card">
                                    <div class="card-header">
                                        <a href="javascript:void(0);" class="card-title text-primary collapsed" data-toggle="collapse" data-target="#js_demo_accordion-4b" aria-expanded="false">
                                            <i class="fal fa-comments width-2 fs-xl mr-2"></i>
                                            <span class="badge badge-icon badge-primary pos-top pos-left ml-5 mt-2">{{commentsCount}}</span>
                                            Comentários
                                            <span class="ml-auto">
                                                <span class="collapsed-reveal">
                                                    <i class="fal fa-minus-circle text-danger fs-xl"></i>
                                                </span>
                                                <span class="collapsed-hidden">
                                                    <i class="fal fa-plus-circle text-primary fs-xl"></i>
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                    <div id="js_demo_accordion-4b" class="collapse" data-parent="#js_demo_accordion-4">
                                        <div class="card-body py-0 px-4 border-faded border-right-0 border-bottom-0 border-left-0" *ngFor="let item of commentsArray; let i = index;">
                                            <div class="d-flex flex-column align-items-center">
                                                <div class="d-flex flex-row w-100 py-4">
                                                    <!-- <div class="d-inline-block align-middle status status-sm status-success mr-3">
                                                        <span class="profile-image profile-image-md rounded-circle d-block mt-1" style="background-image:url('../assets/img/avatars/avatar_new_profile.png'); background-size: cover;"></span>
                                                    </div> -->
                                                    <div class="mb-0 flex-1 text-dark">
                                                        <div class="d-flex">
                                                            <!-- <a href="javascript:void(0);" class="text-dark fw-500">
                                                                Sarah McBrook
                                                            </a> -->
                                                            <span class="text-muted fs-xs opacity-70 ml-auto">{{item.datetime | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                                                        </div>
                                                        <p class="mb-0">{{item.comments}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row no-gutters">
                                                <div class="col-md-6" *ngIf="previewMode">
                                                    <div class="subheader">
                                                        <div class="subheader-block d-lg-flex align-items-center">
                                                            <div class="d-inline-flex flex-column justify-content-center mr-3">
                                                                <span class="fw-300 fs-xs d-block text-muted">Status anterior:</span>
                                                                <span [innerHTML]="item.designOldStatus"></span>
                                                            </div>
                                                        </div>
                                                        <div class="subheader-block d-lg-flex align-items-center">
                                                            <div class="d-inline-flex flex-column justify-content-center mr-3">
                                                                <span class="fw-300 fs-xs d-block text-muted">Novo Status:</span>
                                                                <span [innerHTML]="item.designNewStatus"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row no-gutters">
                        <div class="col-md-6">
                            <div class="subheader">
                                <div class="subheader-block d-lg-flex align-items-center">
                                    <div class="d-inline-flex flex-column justify-content-center mr-3">
                                        <span class="fw-300 fs-xs d-block text-muted">Data de Criação:</span>
                                        <span class="fw-500 fs-xl d-block color-primary-500" *ngIf="contactId > 0">{{contactBySite?.createdOn | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                                    </div>
                                </div>
                                <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                    <div class="d-inline-flex flex-column justify-content-center mr-3">
                                        <span class="fw-300 fs-xs d-block text-muted">Data de Atualização:</span>
                                        <span class="fw-500 fs-xl d-block color-primary-500" *ngIf="contactId > 0">{{contactBySite?.updatedOn | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 ml-auto text-right">
                            <div class="btn-group btn-panel">
                                <button id="js-login-btn" class="btn btn-primary" [disabled]="submitted" *ngIf="!previewMode">
                                    <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                    Salvar
                                </button>
                                <button id="js-cancel-btn" class="btn btn-default" (click)="onCancel()">{{cancelButtonText}}</button>
                            </div>
                        </div>
                    </div>

                    <div class="form-row" *ngIf="!validContact">
                        <hr>
                        <div class="col-md-md-12">
                            <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validContact">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                </button>
                                {{errorMessage}}
                            </div>
                            <br>
                            <div *ngIf="listErrorMessage && !validContact" class="invalid-feedback">
                                <div *ngFor="let error of listErrorMessage">{{error}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<ng-template #mdComentsView let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Comentários</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
            <span aria-hidden="true"><i class="fa fa-times"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="card-body py-0 px-4 border-faded border-right-0 border-bottom-0 border-left-0" *ngFor="let item of commentsArray; let i = index;">
                    <div class="d-flex flex-column align-items-center">
                        <div class="d-flex flex-row w-100 py-4">
                            <div class="mb-0 flex-1 text-dark">
                                <div class="d-flex">
                                    <span class="text-muted fs-xs opacity-70 ml-auto">{{item.datetime | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                                </div>
                                <p class="mb-0">{{item.comments}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-6" *ngIf="previewMode">
                            <div class="subheader">
                                <div class="subheader-block d-lg-flex align-items-center">
                                    <div class="d-inline-flex flex-column justify-content-center mr-3">
                                        <span class="fw-300 fs-xs d-block text-muted">Status anterior:</span>
                                        <span [innerHTML]="item.designOldStatus"></span>
                                    </div>
                                </div>
                                <div class="subheader-block d-lg-flex align-items-center">
                                    <div class="d-inline-flex flex-column justify-content-center mr-3">
                                        <span class="fw-300 fs-xs d-block text-muted">Novo Status:</span>
                                        <span [innerHTML]="item.designNewStatus"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">
            <i class="fa fa-times-circle mr-1"></i> Fechar
        </button>
    </div>    
</ng-template>
