// Angular Components
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { TextMaskModule } from '@app/_helpers/mask/text.mask';
import { SharedModule } from '@app/shared/shared.module';
import { FinancialRoutingRoutes } from './financial-routing.module';

// Components
import { CategoryComponent } from './components/category/category/category.component';
import { CategoryListComponent } from './components/category/category-list/category-list.component';
import { TransactionComponent } from './components/transaction/transaction/transaction.component';
import { TransactionListComponent } from './components/transaction/transaction-list/transaction-list.component';
import { InstallmentsComponent } from './components/installments/installments/installments.component';
import { InstallmentsListComponent } from './components/installments/installments-list/installments-list.component';

@NgModule({
  declarations: [
    CategoryComponent,
    CategoryListComponent,
    TransactionComponent,
    TransactionListComponent,
    InstallmentsComponent,
    InstallmentsListComponent
  ],
  imports: [
    ChartsModule,
    TextMaskModule,
    ArchwizardModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    FinancialRoutingRoutes
  ],
  exports: [
    InstallmentsComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [ DatePipe ]
})
export class FinancialModule { }
