import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grades-list',
  templateUrl: './grades-list.component.html',
  styleUrls: ['./grades-list.component.css']
})
export class GradesListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
