import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Student } from '@app/admin/model';
import { User } from '@app/auth/model';
import { AccountService } from '@app/auth/services/account.service';
import { ModuleType, TypeResponse } from '@app/shared/enum';
import { ConfigurationService, MessageService } from '@app/shared/services';
import { InitStudentService } from '@app/student/services';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

@Component({
  selector: 'menu-student',
  templateUrl: './menu-student.component.html',
  styleUrls: ['./menu-student.component.css']
})
export class MenuStudentComponent {
  public user: User = new User();
  private userRoles: Array<any>;
  public student: Student;
  public module = ModuleType.Student;
  
  urlSafe: SafeResourceUrl;
  showFeedback: boolean = false;
  closeResult: string;
  returnMessage: any;
  url: string;
  public activeMenu: string = 'nav.student.dashboard';

  constructor(
    private router: Router, 
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private loading: MessageService,
    private accountService: AccountService,
    private initStudent: InitStudentService,
    private configurationService: ConfigurationService) {
      initStudent.setConfigPage();
      this.getUserData();
      this.loadConfiguration();
  }

  private getUserData() {
    this.loading.showLoading();
    this.initStudent.getStudentUser().pipe(first()).subscribe((response: any) => {
      this.student = response;
      this.user = this.initStudent.user;
      this.userRoles = this.accountService.getRolesByUser();
      this.loading.hideLoading();
    }, (error: any) => {
      console.log('Erro ao carregar os dados do usuário.');
      this.loading.hideLoading();
    });
  }

  menuClick(url: any, dataNav: string): void {
    if (url) {
      this.activeMenu = dataNav;
      this.router.navigate([url]);
    }
    return;
  }

  checkHavePermission(role: any): boolean {
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some(x => x.keyDefault == role);
    } else {
      return false;
    }
  }

  checkIsStudent(): boolean {
    return this.user?.username == 'giullianoalvaro@gmail.com';
  }

  modalShow(content: any) {
    this.modalService.dismissAll();
    this.modalService.open(content, {
      size:'lg',
      backdrop: 'static',
      windowClass: 'animated fade modal-backdrop-transparent'
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }
  
  loadConfiguration() {
    this.loading.showLoading();
    this.configurationService.getall().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        let config = response.data[0];
        this.url = config.npsForm;
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        this.showFeedback = this.url !== '';
      }
      this.returnMessage = '';
      this.loading.hideLoading();
    }, (error: any) => {
      this.returnMessage = 'Erro ao carregar os dados dos professores.';
      this.loading.hideLoading();
    });
  }

  studentNpsFormShow(content: any) {
    this.modalService.dismissAll();
    this.modalService.open(content, {
      size:'lg',
      backdrop: 'static',
      windowClass: 'animated fade modal-backdrop-transparent'
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
