export enum WritingTestStatus {
  Scheduled = 0,
  Done = 1,
  Canceled = 2,
  Pending = 3
}

export const WritingTestStatusLabel = new Map<number, string>([
  [WritingTestStatus.Scheduled, 'Programado'],
  [WritingTestStatus.Done, 'Concluído'],
  [WritingTestStatus.Canceled, 'Cancelado'],
  [WritingTestStatus.Pending, 'Pendente'],
]);

export const WritingTestStatusCss = new Map<number, string>([
  [WritingTestStatus.Scheduled, 'writing-test-scheduled'],
  [WritingTestStatus.Done, 'writing-test-done'],
  [WritingTestStatus.Canceled, 'writing-test-canceled'],
  [WritingTestStatus.Pending, 'writing-test-pending'],
]);
