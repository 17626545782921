import { formatDate } from '@angular/common';
import { Base } from '@app/shared/model';
import { StudentClassesCourseAction, StudentCourse, Teacher } from '.';
import { StatusClassParams } from '@app/shared/enum/StatusClassParams';

export class StudentClassesCourse extends Base {
    studentCourse: StudentCourse;
    studentCourseId: number;

    teacher: Teacher;
    teacherConfirmationId: number;

    numberClass: number;
    expectedDate: Date;
    additionalInformation: string;

    confirmedClassByStudent: boolean;
    confirmedDateByStudent: Date;

    confirmedClassByTeacher: boolean;
    confirmedDateByTeacher: Date;
    confirmedClassByTeacherStatusClassParams: StatusClassParams;
    confirmedClassByTeacherWithParams: string;

    canceledClass: boolean;
    canceledDate: Date;

    studentClassesCourseAction: Array<StudentClassesCourseAction>;

    constructor(contentResponse: any) {
        super(contentResponse);
    
        this.studentCourseId = parseInt(contentResponse.studentCourseId);
        if (contentResponse && contentResponse.studentCourseId > 0) {
            this.studentCourse = contentResponse.studentCourse;
        }

        this.teacherConfirmationId = parseInt(contentResponse.teacherConfirmationId);
        if (contentResponse && contentResponse.teacherConfirmationId > 0) {
            this.teacher = contentResponse.teacher;
        }

        this.additionalInformation = contentResponse.numberClass;
        this.numberClass = parseInt(contentResponse.numberClass);
        this.expectedDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

        this.confirmedClassByStudent = contentResponse.confirmedClassByStudent;
        this.confirmedDateByStudent = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

        this.confirmedClassByTeacher = contentResponse.confirmedClassByTeacher;
        this.confirmedDateByTeacher = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        this.confirmedClassByTeacherStatusClassParams = parseInt(contentResponse.confirmedClassByTeacherStatusClassParams);
        this.confirmedClassByTeacherWithParams = contentResponse.confirmedClassByTeacherWithParams;

        this.canceledClass = contentResponse.canceledClass;
        this.canceledDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

        if(contentResponse.studentClassesCourseAction && contentResponse.studentClassesCourseAction.lenght > 0) {
            this.studentClassesCourseAction = contentResponse.studentClassesCourseAction;
        }
    }
}
