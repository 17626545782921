export enum TimeDay {
    Morning = 0,
    Afternoon = 1,
    Night = 2,
    Dawn = 3
}

export const TimeDayLabel = new Map<number, string>([
    [TimeDay.Morning, 'Manhã'],
    [TimeDay.Afternoon, 'Tarde'],
    [TimeDay.Night, 'Noite'],
    [TimeDay.Dawn, 'Madrugada']
]);
