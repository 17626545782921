import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CategoryService } from '@app/financial/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';

@Component({ templateUrl: 'category-list.component.html', providers: [DatePipe] })
export class CategoryListComponent implements OnInit {

  public categories: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public columns: Array<any> = [
    { title: "Código", name: "id", sort: "asc", className: "text-center" },
    { title: "Nome", name: "name", sort: "asc" },
    {
      title: "Criado em",
      name: "createdOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    },
    {
      title: "Modificado em",
      name: "updatedOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    }
  ];
  
  constructor(
    private initAdmin: InitAdminService,
    private categoryService: CategoryService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private loading: MessageService
  ) { }

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadCategories();
  }

  loadCategories() {
    this.categories = [];
    this.returnMessage = 'Nenhum dado disponível.';
    //this.loading.showLoading();
    // this.categoryService.getAll().subscribe((response:any) => {
    //   if (response.typeResponse == TypeResponse.Success) {
    //     this.categories = response.data;
    //     this.showTable = true;
    //     this.returnMessage = '';
    //   } else {
    //     this.returnMessage = response.message;
    //     this.showTable = false;
    //   }
    //   this.loading.hideLoading();
    // },
    // error => {
    //   this.loading.hideLoading();
    //   this.showTable = false;
    //   this.returnMessage = 'Erro ao carregar os dados das categorias.';
    // });
  }
}
