import { Injectable } from '@angular/core';
import { MessageService } from '@app/shared/services';

// Helpers
import pageSettings from '@helpers/page-settings';

@Injectable({
  providedIn: 'root'
})
export class InitAdminService {
  pageSettings = pageSettings;

  constructor(private messageService: MessageService) {
    this.setConfigPage();
  }

  async setConfigPage(update: boolean = false, showHubSpot: boolean = true) {
    this.pageSettings.pageEmpty = false;
    this.pageSettings.pageSidebar = true;
    this.pageSettings.pageWithoutFooter = true;
    this.pageSettings.pageContentBgTransparent = false;
    this.pageSettings.pageContentBrandGradient = false;

    this.messageService.displayHubSpot(showHubSpot);
  }
}
