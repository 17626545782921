import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';
import { NotificationService } from '@app/marketing/services';
import { MessageTypeCss, MessageTypeLabel, ModuleTypeCss, ModuleTypeLabel } from '@app/shared/enum';

@Component({ templateUrl: 'notification-list.component.html' })
export class NotificationListComponent implements OnInit {
  @Input("userId") userId: any;
  @Input("moduleId") moduleId: any;

  public datalist: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public columns: Array<any> = [
    { title: "Código", name: "id", sort: "asc", className: "text-center" },
    { 
      title: "Título", 
      name: "title", 
      sort: "asc",
      className: "text-left fw-700"
    },
    { 
      title: "Grupo de Notificação", 
      name: "groupNotification.name", 
      sort: "asc",
      className: "text-left fw-700"
    },
    { title: "Tipo da Mensagem", name: "designMessageType", sort: "asc", className: "text-center fw-700", isHtmlData: true },
    { title: "Módulo", name: "designModule", sort: "asc", className: "text-center fw-700", isHtmlData: true },
    { title: "Confirmação", name: "confirmation", sort: "asc", className: "text-center" },
    {
      title: "Data da notificação",
      name: "notificationReading",
      sort: "asc",
      className: "text-center font-weight-bold text-primary font-weight-bold datetime"
    },
    // {
    //   title: "Criado em",
    //   name: "createdOn",
    //   sort: "asc",
    //   className: "text-center text-primary datetime"
    // },
    // {
    //   title: "Modificado em",
    //   name: "updatedOn",
    //   sort: "asc",
    //   className: "text-center text-primary datetime"
    // }
  ];

  constructor(
    private initAdmin: InitAdminService,
    private notificationService: NotificationService,
    private router: Router,
    private loading: MessageService
  ) { }

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadNotifications();
  }

  loadNotifications() {
    this.loading.showLoading();
    this.notificationService.getAll().subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.datalist = response.data;
        this.designItems(response.data);
        this.showTable = true;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
        this.showTable = false;
      }
      this.loading.hideLoading();
    }, (error: any) => {
      this.loading.hideLoading();
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar as notificações.';
    });
  }

  designItems(lista: any[]) {
    lista.forEach(function(e) {
      if (e.messageType >= 0) {
        let messageTypeStr = MessageTypeLabel.get(e.messageType);
        let messageTypeCss = MessageTypeCss.get(e.messageType);
        e.designMessageType = "<span class='badge w-100 "
          + messageTypeCss +" badge-pill'>"
          + messageTypeStr +"</span>";
      }

      if (e.module >= 0) {
        let moduleTypeStr = ModuleTypeLabel.get(e.module);
        let moduleTypeCss = ModuleTypeCss.get(e.module);
        e.designModule = "<span class='badge w-100 "
          + moduleTypeCss +" badge-pill'>"
          + moduleTypeStr +"</span>";
      }
    });
    this.datalist = lista;
  }

  delete(id: number): void { }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/marketing/notification', { id: row.id }]);
  }

  preview(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/marketing/notification', { id: row.id, preview: true }]);
  }
}
