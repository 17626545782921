import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  private route = 'teacher';
  private controller = 'calendar';
  private endpointPrefix = '';

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getById(teacherId: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/${teacherId}`);
  }
  
  mockFake(teacherId: any): any {
    return this.http.get('https://mocki.io/v1/f7cdb3b1-5572-4503-9d61-a1671432d08b');
  }

  mock(teacherId: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/mock/${teacherId}`);
  }

  mockRange(startDate: Date, endDate: Date): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/mockrange` +
    `/start/${formatDate(startDate, 'yyyy-MM-dd', 'en-US')}` + 
    `/end/${formatDate(endDate, 'yyyy-MM-dd', 'en-US')}`);
  }

  filter(teacherId: any, startDate: Date, endDate: Date): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/filter/${teacherId}` +
    `/start/${formatDate(startDate, 'yyyy-MM-dd', 'en-US')}` + 
    `/end/${formatDate(endDate, 'yyyy-MM-dd', 'en-US')}`);
  }
}
