import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

//import { environment } from '@environments/environment';
import { AccountService } from '@app/auth/services/account.service';
import { environment } from '@environments/environment';

// const httpOptions = {
//     headers: new HttpHeaders({ 
//       'Access-Control-Allow-Origin':'*',
//       'Authorization':'authkey',
//       'userid':'1'
//     });
// };

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private accountService: AccountService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isValidAccess = this.accountService.accessIsValid(); //Check if access is valid
        const isApiUrl = request.url.startsWith(environment.urlAPI);
        if (isValidAccess && isApiUrl) {
            // add auth header with jwt if user is logged in and request is to the api url
            var token = this.accountService.getJwtTokenString();
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
            request.headers.set('Access-Control-Allow-Origin', '*');
        }
        return next.handle(request);
    }
}