import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'content-episode-list',
  templateUrl: './episode-list.component.html',
  styleUrls: ['./episode-list.component.css']
})
export class EpisodeListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
