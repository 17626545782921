import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'content-episode-item',
  templateUrl: './episode-item.component.html',
  styleUrls: ['./episode-item.component.css']
})
export class EpisodeItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
