<div class="modal-header">
    <h5 class="modal-title">Informações da Matrícula</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
</div>
<div class="modal-body">
    <!--Table rows-->
    <div id="panel-3" class="panel mb-0">
        <div class="panel-hdr bg-primary-600 bg-primary-gradient">
            <h2>
                <span class="fw-300"><i>Informações</i></span>
            </h2>
        </div>
        <div class="panel-container show">
            <div class="panel-content p-0">
                <div class="form-row mb-3 mt-3 px-4">
                    <div class="col-4">
                        <label>Id</label>
                        <label class="form-control font-weight-bold disabled">{{studentCourseData.id}}</label>
                    </div>
                    <div class="col-8">
                        <label>Identifier</label>
                        <label class="form-control font-weight-bold disabled">{{studentCourseData.identifier}}</label>
                    </div>
                </div>
                <div class="form-row mb-3 mt-3 px-4">
                    <div class="col-12">
                        <label>Nome do aluno:</label>
                        <label class="form-control font-weight-bold disabled">{{studentCourseData.studentName}}</label>
                    </div>
                </div>
                <div class="form-row mb-3 mt-3 px-4">
                    <div class="col-6">
                        <label>E-mail:</label>
                        <label class="form-control font-weight-bold disabled">{{studentCourseData.student.email}}</label>
                    </div>
                    <div class="col-6">
                        <label>Telefone:</label>
                        <label class="form-control font-weight-bold disabled">{{studentCourseData.student.phone}}</label>
                    </div>
                </div>
                <div class="form-row mb-3 mt-3 px-4">
                    <div class="col-12">
                        <label>Turma:</label>
                        <label class="form-control font-weight-bold disabled">{{studentCourseData.courseName}}</label>
                    </div>
                </div>
                <div class="form-row mb-3 mt-3 px-4">
                    <div class="col-12">
                        <label>Nome do professor:</label>
                        <label class="form-control font-weight-bold disabled">{{studentCourseData.teacherName}}</label>
                    </div>
                </div>
                <div class="form-row mb-3 mt-3 px-4">
                    <div class="col-12">
                        <label>Link do Google Drive:</label>
                        <label class="form-control font-weight-bold disabled" *ngIf="previewMode">{{studentCourseData.googleDriveLink}}</label>
                        <input type="text" id="googleDriveLink" class="form-control" placeholder="Link do Google Drive" [(ngModel)]="googleDriveLink" *ngIf="!previewMode">
                    </div>
                </div>
                <div class="form-row mb-3 mt-3 px-4">
                    <div class="col-3 pr-4">
                        <label>Status do Pagamento</label><br>
                        <div [innerHtml]="getStatusPagamento(studentCourseData.paymentStatus)" class="mb-3"></div>
                    </div>
                </div>

                <div class="form-row mb-3 mt-3 px-4">
                    <div class="col-12 pr-4">
                        <div class="subheader">
                            <div class="subheader-block d-lg-flex align-items-center">
                                <div class="d-inline-flex flex-column justify-content-center mr-3">
                                    <span class="fw-300 fs-xs d-block text-muted">1ª parcela:</span>
                                    <span class="fw-500 fs-xl d-block color-info-500">{{studentCourseData.installmentFirstDue | date: 'dd/MM/yyyy'}}</span>
                                </div>
                            </div>
                            <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                <div class="d-inline-flex flex-column justify-content-center mr-3">
                                    <span class="fw-300 fs-xs d-block text-muted">Demais vencimentos:</span>
                                    <span class="fw-500 fs-xl d-block color-info-500">{{studentCourseData.dueDay}}</span>
                                </div>
                            </div>
                            <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                <div class="d-inline-flex flex-column justify-content-center mr-3">
                                    <span class="fw-300 fs-xs d-block text-muted">Início das aulas:</span>
                                    <span class="fw-500 fs-xl d-block color-primary-500">{{studentCourseData.startDate | date: 'dd/MM/yyyy'}}</span>
                                </div>
                            </div>
                            <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                <div class="d-inline-flex flex-column justify-content-center mr-3">
                                    <span class="fw-300 fs-xs d-block text-muted">Término das aulas:</span>
                                    <span class="fw-500 fs-xl d-block color-primary-500">{{studentCourseData.endDate | date: 'dd/MM/yyyy'}}</span>
                                </div>
                            </div>
                            <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                <div class="d-inline-flex flex-column justify-content-center mr-3">
                                    <span class="fw-300 fs-xs d-block text-muted">Duração:</span>
                                    <span class="fw-500 fs-xl d-block color-primary-500">{{studentCourseData.monthTotal}} meses</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-row mb-3 mt-3 px-4">
                    <div class="col-3">
                        <label>Total de aulas</label>
                        <label class="form-control font-weight-bold text-primary disabled">{{studentCourseData.totalClasses}}</label>
                    </div>
                    <div class="col-3">
                        <label>Aulas por semana</label>
                        <label class="form-control font-weight-bold text-info disabled">{{studentCourseData.classesPerWeek}}</label>
                    </div>
                    <div class="col-3">
                        <label>Aulas confirmadas</label>
                        <label class="form-control font-weight-bold text-success disabled">{{studentCourseData.confirmedClasses}}</label>
                    </div>
                    <div class="col-3">
                        <label>Aulas restante</label>
                        <label class="form-control font-weight-bold text-info disabled">{{studentCourseData.remainingClasses}}</label>
                    </div>
                </div>
                <hr *ngIf="!previewMode">
                <div class="form-row mb-3 mt-3 px-4" *ngIf="!previewMode">
                    <div class="col-md-12 ml-auto text-right">
                        <div class="btn-group btn-panel">
                            <button id="js-login-btn" class="btn btn-primary" [disabled]="submitted" (click)="onSubmit()">
                                <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                Salvar
                            </button>
                            <button id="js-cancel-btn" class="btn btn-default" (click)="onCancel()">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer" *ngIf="previewMode">
    <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Fechar</button>
</div>
