export class Episode {
    name: string
}




// {
//     "local": "/pecm",
//     "contents": {
//         "kids": {
//             "series": {
//                 "episodes": {

//                 }
//             }
//         },
//         "regular": {
//             "series": [
//                 {
//                     "name": "Everybody Hates Chris",
//                     "description": "Everybody Hates Chris",
//                     "folderName": "1_everybody_hates_chris",
//                     "order": 1,
//                     "thumbnail": "thumbnail.jpg",
//                     "episodes": [
//                         "EHC0101",
//                         "EHC0102",
//                         "EHC0103",
//                         "EHC0104",
//                         "EHC0105",
//                         "EHC0106",
//                         "EHC0107",
//                         "EHC0108",
//                         "EHC0109",
//                         "EHC0110",
//                         "EHC0111",
//                         "EHC0112",
//                         "EHC0113",
//                         "EHC0114",
//                         "EHC0115",
//                         "EHC0116",
//                         "EHC0117",
//                         "EHC0118",
//                         "EHC0119",
//                         "EHC0120",
//                         "EHC0121",
//                         "EHC0122",
//                         "EHC0201",
//                         "EHC0202",
//                         "EHC0101"
//                     ]
//                 },
//                 {
//                     "name": "Baby Daddy",
//                     "description": "Baby Daddy",
//                     "folderName": "2_baby_daddy",
//                     "order": 2,
//                     "thumbnail": "thumbnail.jpg",
//                     "episodes": [
//                         "BD0101",
//                         "BD0102",
//                         "BD0103",
//                         "BD0104",
//                         "BD0105",
//                         "BD0106",
//                         "BD0107",
//                         "BD0108",
//                         "BD0109",
//                         "BD0110",
//                         "BD0201",
//                         "BD0202",
//                         "BD0203",
//                         "BD0204",
//                         "BD0205",
//                         "BD0206",
//                         "BD0207",
//                         "BD0208",
//                         "BD0209",
//                         "BD0210",
//                         "BD0211",
//                         "BD0212",
//                         "BD0213",
//                         "BD0214"
//                     ]
//                 },
//                 {
//                     "name": "How I met your mother",
//                     "description": "How I met your mother",
//                     "folderName": "3_how_i_met_your_mother",
//                     "order": 3,
//                     "thumbnail": "thumbnail.jpg",
//                     "episodes": [
//                         "HIMYM0101",
//                         "HIMYM0102",
//                         "HIMYM0103",
//                         "HIMYM0104",
//                         "HIMYM0105",
//                         "HIMYM0106",
//                         "HIMYM0107",
//                         "HIMYM0108",
//                         "HIMYM0109",
//                         "HIMYM0110",
//                         "HIMYM0111",
//                         "HIMYM0112",
//                         "HIMYM0113",
//                         "HIMYM0114",
//                         "HIMYM0115",
//                         "HIMYM0116",
//                         "HIMYM0117",
//                         "HIMYM0118",
//                         "HIMYM0119",
//                         "HIMYM0120",
//                         "HIMYM0121",
//                         "HIMYM0122",
//                         "HIMYM0201",
//                         "HIMYM0202"
//                     ]
//                 }
//             ]
//         }
//     }
// }
