<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Meu perfil</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div id="panel-2" class="panel">
    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
            Meu <span class="fw-300"><i>Perfil</i></span>
        </h2>
        <div class="panel-toolbar">
            <a href="javascript:void(0);" class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse">
                <i class="fal fa-minus text-white fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen">
                <i class="fal fa-expand text-primary fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Close">
                <i class="fal fa-times text-white fw-900"></i>
            </a>
        </div>
    </div>
    <div class="panel-container show">
        <div class="panel-content p-0">
            <div class="panel-content">
                <div class="row">
                    <div class="col-lg-6 col-xl-3 order-lg-1 order-xl-1">
                        <!-- profile summary -->
                        <div class="card mb-g rounded-top">
                            <div class="row no-gutters row-grid">
                                <div class="col-12">
                                    <div class="d-flex flex-column align-items-center justify-content-center p-4">
                                        <img src="assets/img/demo/avatars/avatar-m.png" class="rounded-circle shadow-2 img-thumbnail" alt="">
                                        <h5 class="mb-0 fw-700 text-center mt-3">
                                            {{this.user.name}}
                                            <small class="text-muted mb-0">{{this.user.username}}</small>
                                        </h5>
                                        <div class="mt-4 text-center demo">
                                            <a href="javascript:void(0);" class="fs-xl" style="color:#3b5998">
                                                <i class="fab fa-facebook"></i>
                                            </a>
                                            <a href="javascript:void(0);" class="fs-xl" style="color:#38A1F3">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                            <a href="javascript:void(0);" class="fs-xl" style="color:#db3236">
                                                <i class="fab fa-google-plus"></i>
                                            </a>
                                            <a href="javascript:void(0);" class="fs-xl" style="color:#0077B5">
                                                <i class="fab fa-linkedin-in"></i>
                                            </a>
                                            <a href="javascript:void(0);" class="fs-xl" style="color:#000000">
                                                <i class="fab fa-reddit-alien"></i>
                                            </a>
                                            <a href="javascript:void(0);" class="fs-xl" style="color:#00AFF0">
                                                <i class="fab fa-skype"></i>
                                            </a>
                                            <a href="javascript:void(0);" class="fs-xl" style="color:#0063DC">
                                                <i class="fab fa-flickr"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="text-center py-3">
                                        <h5 class="mb-0 fw-700">
                                            0
                                            <small class="text-muted mb-0">Conexões</small>
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="text-center py-3">
                                        <h5 class="mb-0 fw-700">
                                            0
                                            <small class="text-muted mb-0">Aulas</small>
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="p-3 text-center">
                                        <a href="javascript:void(0);" class="btn-link fw-700">Professores</a> <span class="text-primary d-inline-block mx-3">&#9679;</span>
                                        <a href="javascript:void(0);" class="btn-link fw-700">Mensagens</a> <span class="text-primary d-inline-block mx-3">&#9679;</span>
                                        <a href="javascript:void(0);" class="btn-link fw-700">Trocar Senha</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validEntity">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true"><i class="fal fa-times"></i></span>
                    </button>
                    {{errorMessage}}
                </div>
                <div *ngIf="listErrorMessage && !validEntity" class="invalid-feedback">
                    <div *ngFor="let error of listErrorMessage">{{error}}</div>
                </div>
                <hr>
                <div class="row no-gutters">
                    <div class="col-md-12 ml-auto text-right">
                        <div class="btn-group btn-panel">
                            <button id="js-login-btn" class="btn btn-primary" [disabled]="submitted">
                                <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                Salvar
                            </button>
                            <button id="js-cancel-btn" class="btn btn-default" (click)="onCancel()">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>