import { RecurrenceDay } from "@app/shared/enum";

export class TeacherWithAvailabilityItem {
    dayOfWeek: RecurrenceDay;
    hours: Array<string>;

    constructor(contentResponse: any) {
        if (contentResponse) {
            this.dayOfWeek = contentResponse.dayOfWeek;
           
            if(contentResponse.hours && contentResponse.hours.length > 0) {
                this.hours = contentResponse.hours;
            }
        } else {
            this.dayOfWeek = RecurrenceDay.Sunday;
            this.hours = [];
        }
    }
}
