import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { Observable } from 'rxjs';

export class BaseService {
  private endpointPrefix: string;

  constructor(private http: HttpClient, route: string, controller: string) {
    this.endpointPrefix = `${environment.urlAPI}/${route}/${controller}`;
  }

  /**
  * Returns a list of all of the objects.
  */
  getAll(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  /**
  * Returns a specific object by id.
  */
  getById(id: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }

  /**
  * Save specific object with update or post
  */
  save(id: number, model: any, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/${id}`, model);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/add`, model);
    }
  }
  
  delete(id: any): Observable<any> {
    return this.http.delete<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }
}
