import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '@app/auth/services';
import { AlertService, MessageService } from '@app/shared/services'
import { Register } from '@app/auth/model/register';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { environment } from '@environments/environment';

@Component({ templateUrl: 'register.component.html' })
export class RegisterComponent implements OnInit {
  submitted = false;
  returnUrl: string;
  urlConfirmation: string;
  registerForm;
  public validRegister: boolean = true;
  public errorMessage = '';

  public listErrorMessage = new Array<string>();
  private registerUser: any = new Register();
  public phoneMask: Array<string | RegExp>;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private loading: MessageService
  ) {
    this.phoneMask = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    this.registerForm = this.formBuilder.group({
      name: '',
      email: '',
      password: '',
      confirmpassword: '',
      phone: '',
      terms: false,
      newsletter: false
    });
  }

  ngOnInit() {
    // Errors Cleaning
    this.errorMessage = '';
    this.listErrorMessage = new Array<string>();
    this.validRegister = true;

    // Initialize the register form
    this.onInitForm();

    // Check phone valid
    this.onSetEventsForm();

    // get return url from route parameters or default to '/'
    this.returnUrl = '/auth/login';
    this.urlConfirmation = environment.urlConfirmation;
  }
  
  onInitForm() {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8), , Validators.maxLength(20)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(8), , Validators.maxLength(20)]],
      phone: ['', [Validators.required, Validators.minLength(15), , Validators.maxLength(15)]],
      terms: [false, Validators.requiredTrue],
      newsletter: [false]
    }, {validator: this.checkIfMatchingPasswords('password', 'confirmpassword')});
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];

      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true})
      } else {
          return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  onSetEventsForm() {
    this.f.phone.valueChanges.subscribe(value => {
      var strPhone = value.toString().replace('(', '').replace(')', '').replace('-', '').replace('_', '').replace(' ', '');
      var phoneNumber = parseInt(strPhone);
      if (!(phoneNumber >= 11111111111 && phoneNumber <= 99999999999)) {
        value = phoneNumber.toString();
        this.f.phone.status = "INVALID";
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  private registermail: string = "";

  onSubmit() {
    this.submitted = true;
    this.loading.showLoading();

    // Errors Cleaning
    this.errorMessage = '';
    this.validRegister = true;
    this.listErrorMessage = new Array<string>();

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.setMessageError("Confirme os dados e tente novamente.")
      this.submitted = false;
      this.validRegister = false;
      this.loading.hideLoading();
      return;
    }
    
    this.registerUser = this.registerForm.value;
    this.registerUser.UrlCallback = this.urlConfirmation;

    this.accountService.register(this.registerUser)
    .pipe(first())
    .subscribe(data => {
        if (data.typeResponse == TypeResponse.Success) {
          this.validRegister = true;
          this.router.navigate([this.returnUrl]);
        } else {
          this.setMessageError(data.message);
          if (data.data && data.data.errors && data.data.errors.length > 0) {
            this.setMessageErrorList(data.data.errors);
          }
          this.validRegister = false;
        }
        this.submitted = false;
        this.loading.hideLoading();
      },
      error => {
        this.submitted = false;
        this.validRegister = false;
        this.loading.hideLoading();
        this.setMessageError(error);
      });
  }

  setMessageErrorList(listErrors: any): void {
    this.validRegister = false;
    this.listErrorMessage = new Array<string>();
    for (var i = 0; i < listErrors.length; i++) {
      this.listErrorMessage.push(listErrors[i].description);
    }
  }

  setMessageError(errorMessage: string): void {
    this.validRegister = false;
    this.errorMessage = '';
    if (errorMessage) {
      this.errorMessage = errorMessage;
    }
  }
}