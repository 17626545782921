import { formatDate } from '@angular/common';
import { Base } from '@app/shared/model';

export class StudentCourseAdd extends Base {
    studentId: number;
    courseId: number;
    dueDay: number;
    googleDriveLink: string;
    installmentFirstDue: Date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
    startDate: Date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
    endDate: Date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
    isPriceCustom: boolean;
    customPriceValue: number;

    constructor(contentResponse: any) {
        super(contentResponse);
    
        this.googleDriveLink = contentResponse.googleDriveLink;
        this.studentId = parseInt(contentResponse.studentId);
        this.courseId = parseInt(contentResponse.courseId);
        this.dueDay = parseInt(contentResponse.dueDay);
        this.startDate = new Date(contentResponse.startDate + 'GMT-0300');
        this.endDate = new Date(contentResponse.endDate + 'GMT-0300');
        this.installmentFirstDue = new Date(contentResponse.installmentFirstDue + 'GMT-0300');
        this.isPriceCustom = contentResponse.isPriceCustom;
        this.customPriceValue = contentResponse.customPriceValue;
    }
}
