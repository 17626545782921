import { Base } from '@app/shared/model';
import { CheckList, Student, StudentCheckListItem } from '.';

export class StudentCheckList extends Base {
    checkListId: number;
    checkList: CheckList;

    studentId: number;
    student: Student;

    studentCheckListItems: Array<StudentCheckListItem>;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.checkListId = parseInt(contentResponse.checkListId);
        if (contentResponse && contentResponse.checkListId > 0) {
            this.checkList = contentResponse.checkList;
        }

        this.studentId = parseInt(contentResponse.studentId);
        if (contentResponse && contentResponse.studentId > 0) {
            this.student = contentResponse.student;
        }

        if (contentResponse.studentCheckListItems && contentResponse.studentCheckListItems.lenght > 0) {
            this.studentCheckListItems = contentResponse.studentCheckListItems;
        }
    }
}
