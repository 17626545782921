import { formatDate } from '@angular/common';
import { Course, StudentCourse } from '.';

export class StudentCourseRelocation {
    actualStudentCourse: StudentCourse;
    actualStudentCourseId: number;

    newCourse: Course;
    newCourseId: number;

    dueDay: number;
    startDate: Date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
    endDate: Date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

    constructor(contentResponse: any) {
        if (contentResponse) {
            this.actualStudentCourseId = parseInt(contentResponse.actualStudentCourseId);
            if (contentResponse && contentResponse.actualStudentCourseId > 0) {
                this.actualStudentCourse = contentResponse.actualStudentCourse;
            }
    
            this.newCourseId = parseInt(contentResponse.newCourseId);
            if (contentResponse && contentResponse.newCourseId > 0) {
                this.newCourse = contentResponse.newCourse;
            }
    
            this.dueDay = parseInt(contentResponse.dueDay);
            this.startDate = new Date(contentResponse.startDate + 'GMT-0300');
            this.endDate = new Date(contentResponse.endDate + 'GMT-0300');
        } else {
            this.actualStudentCourseId = 0;
            this.newCourseId = 0;
            this.dueDay = 1;
            this.startDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
            this.endDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        }
    }
}
