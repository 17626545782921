import { InitAdminService } from '@app/admin/services/init-admin.service';
import { AuditEventTypeCss, AuditEventTypeLabel, ModuleTypeCss, ModuleTypeLabel } from '@app/shared/enum';
import { MessageService } from '@app/shared/services/message.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { UserAuditService } from '@app/audit/services';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'content-audit-list',
  templateUrl: './user-audit-list.component.html',
  styleUrls: ['./user-audit-list.component.css'],
  providers: [DatePipe]
})
export class UserAuditListComponent implements OnInit {
  public list: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public columns: Array<any> = [
    { title: "Código", name: "id", sort: "asc", className: "text-center" },
    { title: "Usuário", name: "user.name", sort: "asc" },
    { title: "Módulo", name: "designModule", sort: "asc", className: "text-center fw-700", isHtmlData: true },
    { title: "Evento", name: "designAuditEvent", sort: "asc", className: "text-center fw-700", isHtmlData: true },
    { title: "Endereço IP", name: "ipAddress", sort: "asc" },
    {
      title: "Data de Ocorrência",
      name: "timestamp",
      sort: "asc",
      className: "text-center text-primary datetime"
    }
  ];

  constructor(
    private initAdmin: InitAdminService,
    private userAuditService: UserAuditService,
    private loading: MessageService
  ) { }

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadUserAuditList();
  }

  loadUserAuditList() {
    this.loading.showLoading();
    this.userAuditService.getToGrid().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.designData(response.data);
        this.showTable = true;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
        this.showTable = false;
      }
      this.loading.hideLoading();
    }, (error: any) => {
      this.loading.hideLoading();
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar os dados de auditoria.';
    });
  }

  designData(data: any[]) {
    data.forEach(function(e) {
      if (e.auditEvent) {
        let auditEventCss = AuditEventTypeCss.get(e.auditEvent);
        let auditEventStr = AuditEventTypeLabel.get(e.auditEvent);
        e.designAuditEvent = "<span class='badge badge-pill w-100 "
          + auditEventCss +"'>"
          + auditEventStr +"</span>";
      } else {
        e.designAuditEvent = "<span class='badge badge-pill w-100 badge-secondary'>Evento não encontrado</span>";
      }

      if (e.userId >= 0 && e.user?.module?.moduleType >= 0) {
        let moduleTypeCss = ModuleTypeCss.get(e.user.module.moduleType);
        let moduleTypeStr = ModuleTypeLabel.get(e.user.module.moduleType);
        e.designModule = "<span class='badge badge-pill w-100 "
          + moduleTypeCss +"'>"
          + moduleTypeStr +"</span>";
      } else {
        e.designModule = "<span class='badge badge-pill w-100 badge-secondary'>Módulo não encontrado</span>";
      }
    });
    this.list = data;
  }
}
