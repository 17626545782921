<div class="row">
    <div class="col-12">
        <!-- <div *ngFor="let contact of contacts | async; trackBy: trackById; index as i; count as c;">
            <content-serie-item [contact]="contact"></content-serie-item>
        </div> -->
        <div class="card border m-auto m-lg-0" style="max-width: 18rem;" *ngFor="let contact of contacts | async; trackBy: trackById; index as i; count as c;">
            <!-- <img [src]="(serie.contentPath + serie.folderName +'/'+ serie.thumbnail)" class="card-img-top" alt="..."> -->
            <div class="card-body">
                <h5 class="card-title">{{ contact.name }} ( {{ contact.age }} )</h5>
                <p class="card-text text-justify text-muted">{{contact.email}}</p>
            </div>
            <div class="card-footer">
                <button type="button" class="btn btn-sm btn-primary btn-block waves-effect waves-themed">
                    Acessar
                    <span class="fal fa-arrow-right mr-1"></span>
                </button>
            </div>
        </div>

        <!-- <ul>
            <li *ngFor="let contact of contacts | async; trackBy: trackById; index as i; count as c;">
                Index: {{ i }}
                Count: {{ c }}
                <content-serie-item [contact]="contact"></content-serie-item>
            </li>
        </ul> -->
    </div>
</div>
