import { City, State, Bank, User, TeacherAvailability } from '@app/admin/model';

export class Profile {
    teacherId: number;
    name: string;
    birthDate: Date;

    email: string;
    phone: string;
    cpf: string;
    zipCode: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    
    cityId: number;
    cityName: string;
    city: City;
    
    stateId: number;
    stateUf: string;
    state: State;

    country: string;
    
    bankId: number;
    bank: Bank;

    accountType: number;
    agency: string;
    account: string;
    pix: string;

    userId: number;
    user: User;
    
    universityGraduate: string;
    moreInformation: string;

    foreignPerson: boolean;

    teacherAvailabilities: Array<TeacherAvailability>;

    constructor(contentResponse: any) {
        if (contentResponse) {
            this.teacherId = contentResponse.teacherId;
            this.name = contentResponse.name;
            this.birthDate = new Date(contentResponse.birthDate),

            this.email = contentResponse.email;
            this.phone = contentResponse.phone;
            this.cpf = contentResponse.cpf;
            this.zipCode = contentResponse.zipCode;
            this.street = contentResponse.street;
            this.number = contentResponse.number;
            this.complement = contentResponse.complement;
            this.neighborhood = contentResponse.neighborhood;
            this.foreignPerson = contentResponse.foreignPerson;

            this.stateId = parseInt(contentResponse.stateId);
            this.stateUf = contentResponse.stateUf;
            if (contentResponse.stateId > 0 && contentResponse.state) {
                this.state = contentResponse.state;
            }
    
            this.cityId = parseInt(contentResponse.cityId);
            this.cityName = contentResponse.cityName;
            if (contentResponse.cityId > 0 && contentResponse.city) {
                this.state = contentResponse.state;
            }

            this.country = contentResponse.country;

            this.bankId = parseInt(contentResponse.bankId);
            if (contentResponse.bankId > 0 && contentResponse.bank) {
                this.state = contentResponse.state;
            }
    
            this.accountType = parseInt(contentResponse.accountType);
            this.agency = contentResponse.agency;
            this.account = contentResponse.account;
            this.pix = contentResponse.pix;

            this.userId = parseInt(contentResponse.userId);
            if (contentResponse.userId > 0 && contentResponse.user) {
                this.user = contentResponse.user;
            }
            
            this.universityGraduate = contentResponse.universityGraduate;
            this.moreInformation = contentResponse.moreInformation;
            
            if(contentResponse.teacherAvailabilities && contentResponse.teacherAvailabilities.lenght > 0) {
                this.teacherAvailabilities = contentResponse.teacherAvailabilities;
            }
        }
    }
}
