import { formatDate } from '@angular/common';
import { Base } from '@app/shared/model';
import { ContactBySite, Course, Student, User } from '.';

export class StudentCoursePreReg extends Base {
    user: User;
    userId: number;

    contactBySite: ContactBySite;
    contactBySiteId: number;
    
    student: Student;
    studentId: number;

    course: Course;
    courseId: number;

    dueDay: number;
    installmentFirstDue: Date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
    startDate: Date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
    endDate: Date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));

    experimentalClass: Date;

    registration: boolean;
    registrationDatetime: Date;

    isPriceCustom: boolean;
    customPriceValue: number;

    constructor(contentResponse: any) {
        super(contentResponse);
        if (contentResponse) {
            this.userId = parseInt(contentResponse.userId);
            if (contentResponse && contentResponse.userId > 0) {
                this.user = contentResponse.user;
            }
    
            this.studentId = parseInt(contentResponse.studentId);
            if (contentResponse && contentResponse.studentId > 0) {
                this.student = contentResponse.student;
            }
    
            this.courseId = parseInt(contentResponse.courseId);
            if (contentResponse && contentResponse.courseId > 0) {
                this.course = contentResponse.course;
            }

            this.contactBySiteId = parseInt(contentResponse.contactBySiteId);
            if (contentResponse && contentResponse.contactBySiteId > 0) {
                this.contactBySite = contentResponse.contactBySite;
            }
    
            this.dueDay = parseInt(contentResponse.dueDay);
            this.startDate = new Date(contentResponse.startDate + 'GMT-0300');
            this.endDate = new Date(contentResponse.endDate + 'GMT-0300');
            this.installmentFirstDue = new Date(contentResponse.installmentFirstDue + 'GMT-0300');
    
            this.registration = contentResponse.registration;
    
            if (this.registration) {
                this.registrationDatetime = new Date(contentResponse.registrationDatetime + 'GMT-0300');
            }

            if (this.experimentalClass) {
                this.experimentalClass = new Date(contentResponse.experimentalClass + 'GMT-0300');
            }

            this.isPriceCustom = contentResponse.isPriceCustom;
            this.customPriceValue = contentResponse.customPriceValue;
        } else {
            this.userId = 0;
            this.studentId = 0;
            this.courseId = 0;
            this.dueDay = 1;
            this.installmentFirstDue = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
            this.startDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
            this.endDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
            this.experimentalClass = null;
            this.isPriceCustom = false;
            this.customPriceValue = 0;
        }
    }
}
