import { InitAdminService } from '@app/admin/services';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, MessageService, UtilService } from '@app/shared/services';
import { first } from 'rxjs/operators';
import { TypeResponse, TypeResponseLabel } from '@app/shared/enum/TypeResponse';
import { GroupNotification, Notification as Notice } from '@app/marketing/model';
import { ModuleType, ModuleTypeLabel } from '@app/shared/enum/ModuleType';
import { DatePipe } from '@angular/common';
import { GroupNotificationService, NotificationService } from '@app/marketing/services';

@Component({ templateUrl: 'notification.component.html' })
export class NotificationComponent implements OnInit {
  @Input("userId") userId: any;
  @Input("moduleId") moduleId: any;
  @Input("notificationId") notificationId: any;
  
  submitted = false;
  public errorMessage = '';
  public listErrorMessage: any[];

  entityForm;
  public editEntity: boolean;
  public validEntity: boolean;
  public entityId: number;

  public description: string = '';

  // :: Change Here ::
  private notification: Notice;
  returnUrl: string = '/marketing/notificationlist';
  
  public messageTypeList: any = [];
  public messageTypeValue: any;
  
  public moduleTypeList: any = [];
  public moduleTypeValue: any;

  public groupNotificationList: any = [];
  public groupNotificationValue: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private utilService: UtilService,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private groupNotificationService: GroupNotificationService
  ) { 
    this.getGroupNotification();
  }
  
  // :: Generic ::
  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl 
    
    this.onInitForm();

    this.activatedRoute.params.subscribe(params => {
      this.entityId = params['id'];
      if (this.entityId > 0) {
        this.editEntity = true;
        this.loadEntityById(this.entityId);
      } else {
        this.editEntity = false;
      }
    });

    // Check phone valid
    this.onSetEventsForm();

    this.loading.hideLoading();
  }

  // :: Generic ::
  get f() {
    return this.entityForm.controls;
  }

  // :: Generic ::
  onSetEventsForm() {
  }

  // :: Generic ::
  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  onDescriptionChange(desc: string) {
    this.description = desc;
  }

  // :: Generic ::
  validAfterSubmit(dataReturn: any) {
    if (dataReturn.typeResponse == TypeResponse.Success) {
      this.validEntity = true;
      this.router.navigate([this.returnUrl]);
    } else {
      this.validEntity = false;
      this.errorMessage = dataReturn.message;
      if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
        this.listErrorMessage = [];
        for (var i = 0; i <= dataReturn.data.errors.length; i++) {
          this.listErrorMessage.push(dataReturn.data.errors[i].Description);
        }
      }
    }
    this.submitted = false;
    this.loading.hideLoading();
  }

  onInitForm() {
    this.validEntity = true;
    this.entityForm = this.formBuilder.group({
      id: [0],
      messageType: [0, [Validators.required]],
      module: [0, [Validators.required]],
      confirmation: [false, [Validators.required]],
      groupNotificationId: [0, [Validators.required]],
      notificationReading: new FormControl(this.datePipe.transform(Date.now(),"yyyy-MM-dd")),
      description: ['', [Validators.required]],
      title: ['', [Validators.required]],
      createdOn: [''],
      updatedOn: ['']
    });
    this.messageTypeList = this.utilService.ToArray(TypeResponse, TypeResponseLabel);
    this.messageTypeValue = 0;
    this.moduleTypeList = this.utilService.ToArray(ModuleType, ModuleTypeLabel);
    this.moduleTypeValue = 0;
  }

  loadEntityById(id: any) {
    this.loading.showLoading();
    this.notificationService.getById(id)
    .pipe(first())
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validEntity = true;
        this.entityForm = this.formBuilder.group({
          id: new FormControl(response.data.id),
          messageType: new FormControl(response.data.messageType),
          module: new FormControl(response.data.module),
          groupNotificationId: new FormControl(response.data.groupNotificationId),
          confirmation: new FormControl(response.data.confirmation),
          notificationReading: new FormControl(this.datePipe.transform(response.data.notificationReading,"yyyy-MM-dd")),
          description: new FormControl(response.data.description),
          title: new FormControl(response.data.title),
          createdOn: new FormControl(response.data.createdOn),
          updatedOn: new FormControl(response.data.updatedOn)
        });
      } else {
        this.validEntity = true;
      }
      this.loading.hideLoading();
      }, (error: { error: { error: string; }; }) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
      }
    );
  }

  onSubmit() {
    this.loading.showLoading();
    this.submitted = true;
    this.validEntity = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.entityForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validEntity = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.notification = new Notice(this.entityForm.value);
    //this.notification.groupNotificationId = 1;
    //this.notification.messageType = MessageType.Success;

    this.notificationService.save(this.notification.id, this.notification, this.editEntity)
    .pipe(first())
    .subscribe((dataReturn: any) => {
      this.validAfterSubmit(dataReturn);
    }, (error: any) => {
      this.validEntity = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = error;
    });
  }

  getGroupNotification() {
    this.groupNotificationService.getAll().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.groupNotificationList = response.data;
      } else {
        this.groupNotificationList = new Array<GroupNotification>();
      }
    });
  }
}