import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BankService } from '@app/admin/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';

@Component({ templateUrl: 'bank-list.component.html', providers: [DatePipe] })
export class BankListComponent implements OnInit {
  public banks: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public columns: Array<any> = [
    { title: 'Código', name: 'id', sort: 'asc', className: 'text-center' },
    { title: 'Número', name: 'code', sort: 'asc', className: 'text-center' },
    { title: 'Nome', name: 'name', sort: 'asc' },
    {
      title: 'Criado em',
      name: 'createdOn',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
    {
      title: 'Modificado em',
      name: 'updatedOn',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
  ];

  constructor(
    private initAdmin: InitAdminService,
    private bankService: BankService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private loading: MessageService
  ) {}

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadBanks();
  }

  loadBanks() {
    this.loading.showLoading();
    this.bankService.getall().subscribe(
      (response) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.banks = response.data;
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.loading.hideLoading();
      },
      (error) => {
        this.loading.hideLoading();
        this.showTable = false;
        this.returnMessage = 'Erro ao carregar os dados dos bancos.';
      }
    );
  }

  delete(id): void {
    // Swal({
    //   title: "Deseja realmente excluir este registro?",
    //   text: "Ao clicar ok, este será excluído permanentemente!",
    //   type: 'warning',
    //   showConfirmButton: true,
    //   showCancelButton: true
    // })
    // .then((willDelete)=> {
    //   if(willDelete.value === true) {
    //   }
    // });
  }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/bank', { id: row.id }]);
  }
}
