export enum StatusClassParams {
    StudentReportedLate = 0,
    StudentNotAttend = 1,
    StudentReportedWouldNot = 2,
    Other = 3,
    AutoConfirmed = 4
}

export const StatusClassParamsLabel = new Map<number, string>([
    [StatusClassParams.StudentReportedLate, 'O aluno avisou fora do prazo das 3 horas'],
    [StatusClassParams.StudentNotAttend, 'O aluno não compareceu a aula'],
    [StatusClassParams.StudentReportedWouldNot, 'O aluno avisou que não faria no inicio da aula'],
    [StatusClassParams.Other, 'Outro motivo'],
    [StatusClassParams.AutoConfirmed, 'Confirmado automaticamente pela plataforma (24h)']
]);

export const StatusClassParamsCss = new Map<number, string>([
    [StatusClassParams.StudentReportedLate, 'status-class-params-student-reported-late'],
    [StatusClassParams.StudentNotAttend, 'status-class-params-student-not-attend'],
    [StatusClassParams.StudentReportedWouldNot, 'status-class-params-student-reported-would-not'],
    [StatusClassParams.Other, 'status-class-params-other'],
    [StatusClassParams.AutoConfirmed, 'status-class-params-auto-confirmed']
]);
