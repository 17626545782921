import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { ChatMessageWorkspaceComponent } from './components/chat/chat-message-workspace/chat-message-workspace.component';
import { ChatMessagePrivateComponent } from './components/chat/chat-message-private/chat-message-private.component';

const routes: Routes = [
  { path: 'workspace', component: ChatMessageWorkspaceComponent, data: { title: 'Workspace' } },
  { path: 'private', component: ChatMessagePrivateComponent, data: { title: 'Chat Privado' } }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MessageRoutingRoutes { }
