export enum Unity {
  Unity = 0,
  Package = 1,
  Box = 2
}
export const UnityLabel = new Map<number, string>([
  [Unity.Unity, 'Unidade'],
  [Unity.Package, 'Pacote'],
  [Unity.Box, 'Caixa']
]);
