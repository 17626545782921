import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { RecipeService } from '@app/admin/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';
import { default as swal } from 'sweetalert2'
@Component({ templateUrl: 'recipe-list.component.html', providers: [DatePipe] })
export class RecipeListComponent implements OnInit {

  public recipes: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public columns: Array<any> = [
    { title: "Código", name: "id", sort: "asc", className: "text-center" },
    {
      title: "Criado em",
      name: "createdOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    },
    {
      title: "Modificado em",
      name: "updatedOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    }
  ];
  
  constructor(
    private initAdmin: InitAdminService,
    private recipeService: RecipeService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private loading: MessageService
  ) { }

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadBanks();
  }

  loadBanks() {
    this.loading.showLoading();
    this.recipeService.getall()
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.recipes = response.data;
        this.showTable = true;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
        this.showTable = false;
      }
      this.loading.hideLoading();
    },
    error => {
      this.loading.hideLoading();
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar os dados dos recibos.';
    });
  }

  delete(id): void {
    swal({
      title: "Deseja realmente excluir este registro?",
      text: "Ao clicar ok, este será excluído permanentemente!",
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true     
    })
    .then((willDelete)=> {
      if (willDelete.value === true) {
        // To delete
      }
    });
  }

  edit(row:any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/product', { id: row.id }]);
  }
}
