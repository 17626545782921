import { Student, StudentClassesCourseConfirmedParams } from '@app/admin/model';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import {
  CalendarUtilService,
  MessageService,
  UtilService,
} from '@app/shared/services';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarService } from '@app/student/services/calendar.service';
import {
  ContentService,
  InitAdminService,
  StudentClassesCourseService,
  StudentService,
} from '@app/admin/services';
import { default as swal } from 'sweetalert2';
import { Router } from '@angular/router';
import moment from 'moment';
import {
  CourseTypeCss,
  CourseTypeLabel,
  ModuleType,
  StatusClass,
  StatusClassCss,
  StatusClassLabel,
} from '@app/shared/enum';

import { formatDate } from '@angular/common';

import { CalendarOptions, EventApi } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import Locale from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';

import { AccountService } from '@app/auth/services';
import {
  StatusClassParams,
  StatusClassParamsCss,
  StatusClassParamsLabel,
} from '@app/shared/enum/StatusClassParams';
import { ContentEpisode } from '@app/content/model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'teacher-calendar-admin',
  templateUrl: './studentCalendar.component.html',
  styleUrls: ['./studentCalendar.component.css'],
})
export class StudentCalendarComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('mdCalendarDetails') mdCalendarDetails: any;

  public dashboardUrl = '/admin/home';
  public calendarVisible: boolean = false;
  public confirmWithParams: boolean = false;

  public messageNoContentCalendar: string =
    'Validando informações do calendário... Aguarde!';

  public studentCourseClassId: any = 0;
  public courseType: any = 0;
  public studentClassesCourseExpectedDate: any;
  public studentClassesCourseReschedule = new Date();
  public studentClassesCourseExpectedTime: any;

  private userRoles: Array<any>;

  public episodeSetMessage: string = '';

  public statusClassParamsDataList: Array<any>;
  public statusClassParamsList: any = [];

  public newStatusClassParams: any;
  public newStatusClassParamsId: any;
  public newStatusClassParamsStr: string;
  public newStatusClassParamsCss: string;
  public statusClassParamsAddInfo: string = '';
  public needAddInfo: boolean = false;
  public validStatusClassParams: boolean = true;

  public errorStatusClassParamsMessage: string = '';
  public studentClassesCourseConfirmedParams: StudentClassesCourseConfirmedParams;

  calendarPlugins = [dayGridPlugin]; // important!
  calendarEvents: Array<any> = [];
  closeResult: string;
  dataResult = false;
  public returnMessage = '';
  public calendar: any;

  calendarOptions: CalendarOptions = {
    plugins: [interactionPlugin, dayGridPlugin, bootstrapPlugin],
    themeSystem: 'bootstrap',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    //dateClick: this.handleDateClick.bind(this), // MUST ensure `this` context is maintained
    navLinks: true, // can click day/week names to navigate views
    editable: true,
    initialView: 'listWeek',
    locale: Locale,
    //select: this.handleDateSelect.bind(this),
    eventClick: this.eventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventContent: function (info) {
      return { html: info.event.title };
    },
    titleFormat: {
      // will produce something like "Tuesday, September 18, 2018"
      month: 'long',
      year: 'numeric',
      day: 'numeric',
    },
    eventTimeFormat: {
      // like '14:30:00'
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
    },
  };

  private curr = new Date(); // get current date
  private first = this.curr.getDate() - this.curr.getDay(); // First day is the day of the month - the day of the week
  private last = this.first + 6; // last day is the first day + 6
  private firstday = new Date(this.curr.setDate(this.first));
  private lastday = new Date(this.curr.setDate(this.last));

  calendarOptions2$ = this.calendarService
    .filter(0, this.firstday, this.lastday)
    .pipe(
      map((data) => ({
        ...this.calendarOptions,
        events: data,
        initialDate: this.firstday,
        initialView: 'listWeek',
      }))
    );

  public statusClassList: any = [];

  eventDetails: any = {
    id: 0,
    title: '',
    start: new Date(),
    end: new Date(),
    duration: new Date(),
    course: '',
    teacher: '',
    student: '',
  };

  public episode: ContentEpisode;
  public serieId: number;
  public episodeId: number;

  public seriesDataList: Array<any>;
  public seriesList: any = [];

  public episodesDataList: Array<any>;
  public episodesList: any = [];

  public student: Student;
  public studentId: number;
  public haveStudentSelected = false;
  public studentDataList: Array<any>;
  public flagSearchStudent = false;
  public studentList: any = [];
  public flagFiltering: boolean = false;

  constructor(
    private studentClassesCourseService: StudentClassesCourseService,
    private calendarUtilService: CalendarUtilService,
    private changeDetector: ChangeDetectorRef,
    private contentService: ContentService,
    private calendarService: CalendarService,
    private initAdmin: InitAdminService,
    private accountService: AccountService,
    private studentService: StudentService,
    private utilService: UtilService,
    private loading: MessageService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.studentDataList = new Array<any>();
    this.initAdmin.setConfigPage();
    this.userRoles = this.accountService.getRolesByUser();
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.haveStudentSelected = false;
    this.onLoadStudents();
    this.loading.hideLoading();
    this.designConfig();
    setTimeout(() => {
      this.handleEvents(this.calendarEvents);
    }, 1000);
  }

  handleEvents(events: EventApi[]) {
    if (this.calendarComponent) {
      let calendarApi = this.calendarComponent.getApi();
      this.calendarOptions2$ = this.calendarService
        .filter(
          this.student?.id ?? 0,
          calendarApi.view.currentStart,
          calendarApi.view.currentEnd
        )
        .pipe(
          map((data) => ({
            ...this.calendarOptions,
            events: data,
            initialDate: calendarApi.view.currentStart,
            initialView: calendarApi.currentData.currentViewType,
          }))
        );
      this.designConfig();
      this.loading.hideLoading();
    }
    this.changeDetector.detectChanges();
  }

  getCalendarByStudent() {
    this.loading.showLoading();
    this.haveStudentSelected = true;
    this.handleEvents(null);
  }

  onLoadStudents() {
    this.studentService.getall().subscribe(
      (response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.studentDataList = this.getTemplateStudent(response.data);
          this.studentId = 0;
        } else {
          this.studentList = new Array<any>();
          this.studentDataList = new Array<any>();
        }
        this.flagSearchStudent = false;
      },
      (error: any) => {
        this.flagSearchStudent = false;
      }
    );
  }

  pad(num: any, size: any) {
    if (num > 0) {
      num = num.toString();
      while (num.length < size) num = '0' + num;
      return num;
    }
    return num;
  }

  setStudent(value: any) {
    if (value) {
      this.studentId = value;
      this.student = this.studentList.find((x: any) => x.id == value);
    }
  }

  selectEvent(item: any) {
    // do something with selected item
    this.setStudent(item.id);
  }

  onChangeSearch(val: string) {
    console.log(val);
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e: any) {
    console.log(e);
    // do something when input is focused
  }

  getTemplateStudent(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      let arrayValues: Array<any> = new Array<any>();
      this.studentList = data.sort(function (a: any, b: any) {
        var textA = a.name.toLowerCase();
        var textB = b.name.toLowerCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id,
          name:
            this.pad(data[i].id, 6) +
            ' | ' +
            data[i].name +
            ' | ' +
            data[i].email,
        };
        arrayValues.push(element);
      }
      return arrayValues;
    }
    return new Array<any>();
  }

  private isMobileScreen(): boolean {
    return window.screen.width < 640; // 768px portrait
  }

  waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
    var startTimeInMs = Date.now();
    (function loopSearch() {
      if (document.querySelector(selector) != null) {
        setTimeout(function () {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) return;
          callback();
        }, checkFrequencyInMs);
        return;
      } else {
        setTimeout(function () {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) return;
          loopSearch();
        }, checkFrequencyInMs);
      }
    })();
  }

  private designConfig() {
    // Call the below function
    this.waitForElementToDisplay(
      '#fullCalendarStudent',
      function () {
        if (window.screen.width < 640) {
          var toolCalendar =
            document.getElementsByClassName('fc-header-toolbar');
          var calendarObj =
            document.getElementsByClassName('fc-scroller-liquid');
          var btnGroupFilter = document.getElementsByClassName('btn-group');
          var rangeDataText =
            document.getElementsByClassName('fc-toolbar-title');
          if (toolCalendar?.length > 0) {
            toolCalendar[0].classList.remove('row');
            toolCalendar[0].classList.add('row');
            if (calendarObj?.length > 0) {
              calendarObj[0].classList.remove('overflow-visible');
              calendarObj[0].classList.add('overflow-visible');
            }
            if (btnGroupFilter?.length > 0) {
              for (let i = 0; i < btnGroupFilter.length; i++) {
                if (btnGroupFilter[i].children.length == 4) {
                  btnGroupFilter[i].classList.remove('w-100');
                  btnGroupFilter[i].classList.add('w-100');
                }
              }
            }
            if (rangeDataText?.length > 0) {
              rangeDataText[0].classList.remove('text-center');
              rangeDataText[0].classList.add('text-center');
            }
            var toolCalendarDiv =
              document.getElementsByClassName('fc-toolbar-chunk');
            if (toolCalendarDiv) {
              for (var i = 0; i <= toolCalendarDiv.length; i++) {
                if (toolCalendarDiv[i] && toolCalendarDiv[i]?.classList) {
                  toolCalendarDiv[i].classList.remove('col-12');
                  toolCalendarDiv[i].classList.add('col-12');

                  if (i == 1) {
                    toolCalendarDiv[i].classList.remove('small');
                    toolCalendarDiv[i].classList.remove('mt-3');
                    toolCalendarDiv[i].classList.remove('mb-3');
                    toolCalendarDiv[i].classList.remove('text-primary');
                    toolCalendarDiv[i].classList.add('small');
                    toolCalendarDiv[i].classList.add('mt-3');
                    toolCalendarDiv[i].classList.add('mb-3');
                    toolCalendarDiv[i].classList.add('text-primary');
                  }
                }
              }
            }
          }
        }
      },
      1000,
      9000
    );
  }

  getSeries() {
    this.contentService.getSeries().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        const items: Array<object> = [];
        items.push({
          value: 0,
          text: ' Selecione uma série',
        });
        response.data.forEach((element: any) => {
          items.push({
            value: Number.parseInt(element.id),
            text: `${element.name}`,
          });
        });

        this.seriesList = items;
        this.seriesDataList = this.getTemplateStudent(items);
      } else {
        this.seriesDataList = [];
        this.seriesList = [];
      }
    });
  }

  getEpisodesBySerieId(serieId: any) {
    if (serieId == null || serieId == undefined || serieId == 0) {
      serieId = this.episode.contentSerieId;
    }

    this.contentService
      .getEpisodesBySerie(serieId)
      .subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          const items: Array<object> = [];
          items.push({
            value: 0,
            text: ' Selecione um episódio',
          });
          response.data.forEach((element: any) => {
            items.push({
              value: Number.parseInt(element.id),
              text: `${element.contentSerie.name} | ${(
                '00' + element.order
              ).slice(-2)}`,
            });
          });

          this.episodesList = items;
          this.episodesDataList = this.getTemplateStudent(items);
        } else {
          this.episodesList = [];
          this.episodesDataList = [];
        }
      });
  }

  private getUserData() {
    this.loadCalendar(this.studentId);
  }

  loadCalendar(teacherId: number) {
    this.calendarEvents = [];
    this.calendarOptions.events = [];
    this.calendarService.getById(teacherId).subscribe(
      (response: any) => {
        if (response.typeResponse === TypeResponse.Success) {
          this.addManyEvents(response.data);
          this.calendarOptions.events = this.calendarEvents;
          this.returnMessage = '';
          this.messageNoContentCalendar = '';
          this.calendarVisible = true;
        } else {
          this.calendarVisible = false;
          this.messageNoContentCalendar = response.message;
          this.returnMessage = response.message;
        }
        this.loading.hideLoading();
      },
      (error: any) => {
        this.loading.hideLoading();
        this.calendarVisible = false;
        this.messageNoContentCalendar =
          'Calendário não está disponível. Verifique as turmas do teacher!';
        this.returnMessage = 'Erro ao carregar os dados da agenda.';
        console.log(error);
      }
    );
  }

  onDateClick(res: any) {
    alert('Clicked on date : ' + res.dateStr);
  }

  eventClick(arg: any) {
    this.eventDetails.id = arg.event.id;
    this.eventDetails.title = arg.event.title;
    this.eventDetails.start = arg.event.start;
    this.eventDetails.end = arg.event.end;
    this.eventDetails.duration = arg.event.extendedProps.duration;
    this.eventDetails.course = arg.event.extendedProps.course;
    this.eventDetails.teacher = arg.event.extendedProps.teacher;
    this.eventDetails.teacherEmail = arg.event.extendedProps.teacherEmail;
    this.eventDetails.teacherPhone = arg.event.extendedProps.teacherPhone;
    this.eventDetails.student = arg.event.extendedProps.student;
    this.eventDetails.studentEmail = arg.event.extendedProps.studentEmail;
    this.eventDetails.studentPhone = arg.event.extendedProps.studentPhone;
    this.eventDetails.class = arg.event.extendedProps.class;
    this.eventDetails.status = arg.event.extendedProps.status;
    this.eventDetails.designStatusClass =
      arg.event.extendedProps.designStatusClass;

    this.eventDetails.description = arg.event.extendedProps.description;

    this.eventDetails.courseType = arg.event.extendedProps.courseType;
    this.eventDetails.courseTypeCss = arg.event.extendedProps.courseTypeCss;
    this.eventDetails.courseTypeStr = arg.event.extendedProps.courseTypeStr;

    this.eventDetails.confirmedClassByStudent =
      arg.event.extendedProps.confirmedClassByStudent;
    this.eventDetails.confirmedDateByStudent =
      arg.event.extendedProps.confirmedDateByStudent;
    this.eventDetails.confirmedClassByTeacher =
      arg.event.extendedProps.confirmedClassByTeacher;
    this.eventDetails.confirmedDateByTeacher =
      arg.event.extendedProps.confirmedDateByTeacher;
    this.eventDetails.canceledClass = arg.event.extendedProps.canceledClass;
    this.eventDetails.canceledDate = arg.event.extendedProps.canceledDate;

    this.eventDetails.episode = arg.event.extendedProps.episode;

    this.eventDetails.courseIdentifier =
      arg.event.extendedProps.courseIdentifier;
    this.eventDetails.classRoom = arg.event.extendedProps.classRoom;

    this.modalService.dismissAll();
    this.modalService
      .open(this.mdCalendarDetails, {
        backdrop: 'static',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'Save') {
            console.log('result = save');
            // this.submitChangePassword();
          }
        },
        (reason) => {
          this.closeResult = `Dismissed`;
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onEventClick(event: any) {
    console.log(event);
  }

  addEvent() {
    this.calendarEvents = this.calendarEvents.concat({
      title: 'event 2',
      date: '2019-04-02',
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  addManyEvents(response: any) {
    if (
      response != null &&
      response.calendarEvents != null &&
      response.calendarEvents.length > 0
    ) {
      const data = response.calendarEvents;
      // Loop por datas
      for (let d = 0; d < data.length; d++) {
        const cItem = data[d]; // Date
        if (cItem != null) {
          const dFormat = moment(cItem.date).format('YYYY-MM-DD');

          let designCourseType = this.getCourseTypeDesign(
            cItem.course.courseType
          );

          const event: any = {
            id: cItem.studentClassesCourse.id,
            classNames: this.calendarUtilService.getDesignClassCss(
              cItem.statusClass
            ),
            title: `Aluno: ${cItem.student.name} | Turma: ${cItem.course.name} | Aula nº ${cItem.studentClassesCourse.numberClass}`,
            start: `${dFormat}T${cItem.startTime}`, //'2019-08-12T10:30:00',
            end: `${dFormat}T${cItem.endTime}`,
            description: `Aula nº ${cItem.studentClassesCourse.numberClass}`,
            extendedProps: {
              duration: `${dFormat}T${cItem.duration}`,
              teacher: cItem.teacher.name,
              student: cItem.student.name,
              episode: cItem.studentClassesCourse.contentEpisode,
              course: cItem.course.name,
              classRoom: cItem.course.classRoom,
              courseIdentifier: cItem.course.identifier,
              class: `Aula nº ${cItem.studentClassesCourse.numberClass}`,
              status: cItem.statusClass,
              courseTypeCss: designCourseType.courseTypeCss,
              courseTypeStr: designCourseType.courseTypeStr,
              designStatusClass: this.designStatusClass(cItem.statusClass),
              confirmedClassByStudent:
                cItem.studentClassesCourse.confirmedClassByStudent,
              confirmedDateByStudent: formatDate(
                cItem.studentClassesCourse.confirmedClassByStudent
                  ? cItem.studentClassesCourse.confirmedDateByStudent
                  : new Date(),
                'yyyy-MM-dd HH:mm:ss',
                'en-US'
              ),
              confirmedClassByTeacher:
                cItem.studentClassesCourse.confirmedClassByTeacher,
              confirmedDateByTeacher: formatDate(
                cItem.studentClassesCourse.confirmedClassByTeacher
                  ? cItem.studentClassesCourse.confirmedDateByTeacher
                  : new Date(),
                'yyyy-MM-dd HH:mm:ss',
                'en-US'
              ),
              canceledClass: cItem.studentClassesCourse.canceledClass,
              canceledDate: formatDate(
                cItem.studentClassesCourse.canceledDate
                  ? cItem.studentClassesCourse.canceledDate
                  : new Date(),
                'yyyy-MM-dd HH:mm:ss',
                'en-US'
              ),
              confirmedClassByTeacherAddInfo:
                cItem.studentClassesCourse.confirmedClassByTeacherAddInfo,
              confirmedClassByTeacherStatusClassParams:
                cItem.studentClassesCourse
                  .confirmedClassByTeacherStatusClassParams,
              confirmedClassByTeacherStatusClassParamsStr:
                StatusClassParamsLabel.get(
                  cItem.studentClassesCourse
                    .confirmedClassByTeacherStatusClassParams
                ),
            },
          };
          this.calendarEvents.push(event);
        }
      }
    }
  }

  getStatusClassList() {
    this.statusClassList = this.utilService.ToArray(
      StatusClass,
      StatusClassLabel
    );
    this.statusClassList.forEach((statusClass: any) => {
      statusClass.css = StatusClassCss.get(statusClass.value);
    });
  }

  designStatusClass(status: StatusClass) {
    let statusClassCss = StatusClassCss.get(status);
    let statusClassStr = StatusClassLabel.get(status);
    return (
      "<span class='badge badge-pill w-100 font-weight-bold " +
      statusClassCss +
      "'>" +
      statusClassStr +
      '</span>'
    );
  }

  modifyTitle(eventIndex: number, newTitle: string) {
    let calendarEvents = this.calendarEvents.slice(); // a clone
    let singleEvent = Object.assign({}, calendarEvents[eventIndex]); // a clone
    singleEvent.title = newTitle;
    calendarEvents[eventIndex] = singleEvent;
    this.calendarEvents = calendarEvents; // reassign the array
  }

  changedClass(
    studentClassesCourseId: any,
    statusClass: StatusClass,
    linkAoVivo: string = ''
  ) {
    this.dismissModal();
    this.loading.showLoading();
    this.studentClassesCourseService
      .changedClass(studentClassesCourseId, statusClass, ModuleType.Student, 0)
      .subscribe(
        (response: any) => {
          this.loading.hideLoading();
          if (response.typeResponse == TypeResponse.Success) {
            if (response.data === true) {
              this.changedClassSuccess(statusClass, linkAoVivo);
            } else {
              this.changedClassFailed(statusClass);
            }
          }
        },
        (error: any) => {
          this.loading.hideLoading();
          this.changedClassFailed(statusClass);
        }
      );
  }

  changedClassSuccess(status: StatusClass, linkAoVivo: string = '') {
    if (status == StatusClass.Reschedule)
      return this.changedClassSuccessGlobal();

    swal({
      type: 'success',
      title: linkAoVivo
        ? `Presença registrada na aula com sucesso!`
        : `Atualização realizada com sucesso!`,
      html: linkAoVivo
        ? '<small>Caso a aba do seu navegador não abra automaticamente, ' +
          'clique no link abaixo ou copie e cole no seu navegador para entrar na sala!</small><br><br>' +
          '<a href="' +
          linkAoVivo +
          '" target="_blank">' +
          linkAoVivo +
          '</a><br>'
        : '',
      showConfirmButton: status != StatusClass.Unchecked,
      confirmButtonText:
        status != StatusClass.Unchecked ? 'Entrar na sala' : '',
      timer: status != StatusClass.Unchecked ? null : 3000,
    }).then((result: any) => {
      if (result.value === true && linkAoVivo) {
        window.open(linkAoVivo, '_blank');
        setTimeout(() => {
          this.loading.hideLoading();
          window.location.reload();
        }, 5000);
      }
    });
  }

  changedClassSuccessGlobal() {
    swal({
      type: 'success',
      title: `Atualização realizada com sucesso!`,
      showConfirmButton: false,
      timer: 2200,
    });
  }

  changedClassFailed(status: StatusClass) {
    swal({
      type: 'warning',
      title: `Não foi possível realizar a atualização!`,
      showConfirmButton: true,
    }).then(() => {
      //window.location.reload();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  getClassRoomUrl(classRoom: any) {
    return `https://meet.jit.si/ec-class-room-${classRoom}`;
  }

  getClassRoomUrl2(row: any) {
    return `/#/content/classroom;nameRoom=${row.classRoom};courseName=${row.course}`;
  }

  private checkHavePermission(role: any): boolean {
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some((x) => x.keyDefault == role);
    } else {
      return false;
    }
  }

  checkClassRoomAccess(event: any) {
    let checkDate: Date;
    checkDate = new Date(event.start);
    checkDate.setHours(0, 0, 0, 0);

    let dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    return (
      this.checkHavePermission('EST1AL7AV44') &&
      checkDate.toDateString() === dateNow.toDateString()
    );
  }

  checkCancelButton(event: any) {
    return event.status == StatusClass.Delayed;
  }

  checkRescheduleButton(event: any) {
    return (
      event.status == StatusClass.ChangedTeacher ||
      event.status == StatusClass.Scheduled
    );
  }

  private checkDateIsValid(date: Date) {
    var targetDate = date.setHours(-1);
  }

  getCourseTypeDesign(courseType: any) {
    let courseTypeCss = CourseTypeCss.get(courseType);
    let courseTypeStr = CourseTypeLabel.get(courseType);
    return {
      courseTypeStr,
      courseTypeCss,
    };
  }

  private checkAllowReschedule(event: any): boolean {
    var diff = (event.start.getTime() - new Date().getTime()) / 3600000;
    return diff >= 3;
  }

  studentCourseClassReschedule(event: any, content: any) {
    if (event.status == StatusClass.Reschedule) {
      return this.rescheduleClassFailed();
    }

    if (!this.checkAllowReschedule(event))
      return this.rescheduleNotAllow(event);

    this.studentCourseClassId = event.id;
    this.studentClassesCourseExpectedDate = event.start;
    this.courseType = event.courseType;
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'sm',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  rescheduleClassFailed() {
    swal({
      type: 'warning',
      title: `Não é possível reagendar uma aula que já foi reagendada!`,
      showConfirmButton: true,
    }).then(() => {
      this.dismissModal();
    });
  }

  rescheduleNotAllow(event: any) {
    swal({
      type: 'warning',
      title: 'Limite de remarcação excedido!',
      html: `Essa aula já passou do horário limite para remarcação 😔<br><small>Remarcação de aulas são permitidas com 3 horas de antecedência.</small>`,
      showConfirmButton: true,
    });
  }

  setValueTime(input: any) {
    this.studentClassesCourseExpectedTime = input.value;
  }

  setValueDate(input: any) {
    this.studentClassesCourseExpectedDate = input.value;
  }

  willChangeClassReschedule(
    willChangeClassReschedule: any,
    courseType: any
  ): void {
    this.modalService.dismissAll();
    if (courseType == 1) {
      return this.confirmChangeClassReschedule(courseType);
    }

    swal({
      title: 'Sugerir remarcação da aula',
      html:
        'Ao confirmar essa ação, será enviado uma notificação para o(a) professor(a) ' +
        'solicitando a remarcação da aula conforme proposto.' +
        '<br><strong>Confirma a notificação para o(a) professor(a)?</strong>',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, notificar professor(a)',
    }).then(
      (willConfirm) => {
        if (willConfirm.value === true) {
          this.confirmChangeClassReschedule(this.courseType);
        } else {
          this.loading.hideLoading();
          return;
        }
      },
      (error) => {
        this.loading.hideLoading();
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar cancelar a aula.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
          });
        }
      }
    );
  }

  private confirmChangeClassReschedule(courseType: any) {
    this.dismissModal();
    this.loading.showLoading();
    let newDateStr = '0';

    if (this.courseType == 0) {
      let newDate = new Date(
        this.studentClassesCourseExpectedDate + 'GMT-0300'
      );
      newDate.setMinutes(this.studentClassesCourseExpectedTime.split(':')[1]);
      newDate.setHours(this.studentClassesCourseExpectedTime.split(':')[0]);
      newDateStr = formatDate(newDate, 'yyyy-MM-dd HH:mm:ss', 'en-US');
    }

    this.studentClassesCourseService
      .rescheduleSuggestion(this.studentCourseClassId, newDateStr)
      .subscribe(
        (response: any) => {
          this.loading.hideLoading();
          if (response.typeResponse == TypeResponse.Success) {
            if (response.data === true) {
              this.changedClassSuccess(
                courseType == 0 ? StatusClass.Reschedule : StatusClass.Unchecked
              );
            } else {
              this.changedClassFailed(
                courseType == 0 ? StatusClass.Reschedule : StatusClass.Unchecked
              );
            }
          }
        },
        (error: any) => {
          this.loading.hideLoading();
          this.changedClassFailed(
            courseType == 0 ? StatusClass.Reschedule : StatusClass.Unchecked
          );
        }
      );
  }

  checkConfirmClass(target: any): void {
    var link = this.getClassRoomUrl(target.classRoom);
    window.open(link, '_blank');
    // swal({
    //   title: "Confirmação da aula",
    //   html: 'Deseja confirmar a participação na aula ao vivo?',
    //   type: 'warning',
    //   showConfirmButton: true,
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   confirmButtonText: 'Sim, confirmar minha presença',
    //   cancelButtonText: 'Não'
    // }).then(willConfirm => {
    //   if (willConfirm.value === true) {
    //     var link = this.getClassRoomUrl(target.classRoom);
    //     this.changedClass(target.id, 1, link);
    //   } else {
    //     this.dismissModal();
    //     this.loading.hideLoading();
    //   }
    // },
    // error => {
    //   this.loading.hideLoading();
    //   if (error.error.error === 'invalid_token') {
    //     this.router.navigate(['/auth/login']);
    //   } else {
    //     swal({
    //       type: 'error',
    //       title: 'Erro ao tentar entrar na aula. Atualize a página e tente novamente!',
    //       showConfirmButton: false,
    //       timer: 1500
    //     }).then((result) => {
    //       this.loading.hideLoading();
    //       //window.location.reload();
    //     });
    //   }
    // });
  }
}
