import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Student, Teacher } from '@app/admin/model';
import { InitAdminService } from '@app/admin/services';
import { User } from '@app/auth/model';
import { AccountService } from '@app/auth/services';
import { ChatMessageService } from '@app/message/services';
import { ModuleType, TypeResponse } from '@app/shared/enum';
import { MessageService } from '@app/shared/services';
import { InitStudentService } from '@app/student/services';
import { InitTeacherService } from '@app/teacher/services';
import { parse } from 'path';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'chat-message-private',
  templateUrl: './chat-message-private.component.html',
  styleUrls: ['./chat-message-private.component.css']
})
export class ChatMessagePrivateComponent implements OnInit {
  // @Input("senderUserId") senderUserId: any;
  // @Input("recipientUserId") recipientUserId: any;
  
  public module: ModuleType;
  private returnUrl = '/';

  public user: User = new User();
  private userRoles: Array<any>;
  private student: Student;
  private teacher: Teacher;

  public returnMessage = '';

  public contacts: Array<User> = [];
  public contactsSource$ = new BehaviorSubject([]);

  constructor(private router: Router,
    private loading: MessageService,
    private activatedRoute: ActivatedRoute,
    private initTeacher: InitTeacherService,
    private initStudent: InitStudentService,
    private chatMessageService: ChatMessageService,
    private accountService: AccountService) {
      let user = accountService.getUserData();
      this.module = ModuleType[user.module];

      this.loading.showLoading();
      if (this.module == ModuleType.Teacher) {
        this.initTeacher.setConfigPage();
      } else if (this.module == ModuleType.Student) {
        this.initStudent.setConfigPage();  
      } else {
        this.router.navigate([this.returnUrl]);
      }
      this.getUserData(this.module);
      this.loading.hideLoading();
  }

  ngOnInit(): void { }

  private getUserData(module: ModuleType) {
    var endpoint = module == ModuleType.Student
      ? this.initStudent.getStudentUser().pipe(first())
      : this.initTeacher.getTeacherUser().pipe(first());

    endpoint.subscribe((response: any) => {
      if (module == ModuleType.Student) {
        this.student = response;
        this.user = this.initStudent.user;  
      } else if (module == ModuleType.Teacher) {
        this.teacher = response;
        this.user = this.initTeacher.user;
      }
    
      this.getContacts(module);
      //this.userRoles = this.accountService.getRolesByUser();
      this.loading.hideLoading();
    }, (error: any) => {
      this.returnMessage = 'Erro ao carregar o calendário.';
      this.loading.hideLoading();
    });
  }

  getContacts(module: ModuleType) {
    this.loading.showLoading();
    var endpoint = module == ModuleType.Student
      ? this.chatMessageService.contactsByStudent(this.student.id)
      : this.chatMessageService.contactsByTeacher(this.teacher.id);

    endpoint.subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.contacts = response.data;
      } else {
        this.contacts = [];
      }
      this.contactsSource$.next(this.contacts);
      this.loading.hideLoading();
    }, (error: any) => {
      this.returnMessage = 'Erro ao carregar os contatos.';
      this.loading.hideLoading();
    });
  }
}
