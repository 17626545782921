import { Unity, UnityLabel } from '@app/shared/enum/Unity';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ProductService } from '@app/admin/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';
import { default as swal } from 'sweetalert2';
@Component({
  templateUrl: 'product-list.component.html',
  providers: [DatePipe],
})
export class ProductListComponent implements OnInit {
  public products: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  public Unity = Unity;
  public unityList = Object.keys(this.Unity);

  public columns: Array<any> = [
    { title: 'Código', name: 'id', sort: 'asc', className: 'text-center' },
    {
      title: 'Nome',
      name: 'name',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-left fw-700',
    },
    {
      title: 'Un',
      name: 'unityString',
      sort: 'asc',
      className: 'text-center text-info fw-700',
    },
    {
      title: 'Valor mensal',
      name: 'monthlyAmount',
      sort: 'asc',
      className: 'text-center text-primary currency',
    },
    {
      title: 'Valor',
      name: 'value',
      sort: 'asc',
      className: 'text-center text-primary currency',
    },
    // {
    //   title: "Criado em",
    //   name: "createdOn",
    //   sort: "asc",
    //   className: "text-center text-primary datetime"
    // },
    // {
    //   title: "Modificado em",
    //   name: "updatedOn",
    //   sort: "asc",
    //   className: "text-center text-primary datetime"
    // }
  ];

  constructor(
    private initAdmin: InitAdminService,
    private productService: ProductService,
    private router: Router,
    private loading: MessageService
  ) {}

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadProducts();
  }

  loadProducts() {
    this.loading.showLoading();
    this.productService.all().subscribe(
      (response) => {
        if (response.typeResponse == TypeResponse.Success) {
          response.data.forEach((element: any) => {
            if (element.unity >= 0) {
              element.unityString = UnityLabel.get(element.unity);
            }
          });
          this.products = response.data;
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.loading.hideLoading();
      },
      (error) => {
        this.loading.hideLoading();
        this.showTable = false;
        this.returnMessage = 'Erro ao carregar os dados dos pacotes.';
      }
    );
  }

  delete(target): void {
    swal({
      title: 'Excluir Prduto',
      html:
        'Confirma a exclusão <strong>' +
        target.name +
        '</strong>?<br>' +
        'Ao confirmar o pacote será excluído permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.productService.remove(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.loadProducts();
                  //this.loadUsers();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir o pacote!',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir o pacote.',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                  this.loadProducts();
                });
              }
            },
            (error) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir o pacote.',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir o pacote.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/package', { id: row.id }]);
  }
}
