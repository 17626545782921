import { InitAdminService } from '@app/admin/services/init-admin.service';
import { MessageService } from '@app/shared/services/message.service';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  TeacherService,
  WritingTestApplyService,
  WritingTestService,
} from '@app/admin/services';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {
  TypeResponse,
  WritingTestReviewCss,
  WritingTestReviewLabel,
  WritingTestStatusCss,
  WritingTestStatusLabel,
} from '@app/shared/enum';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Teacher, WritingTestAppliedPending } from '@app/admin/model';
import { default as swal } from 'sweetalert2';

@Component({
  selector: 'writing-test-applied-list',
  templateUrl: './writing-test-applied-list.component.html',
  styleUrls: ['./writing-test-applied-list.component.css'],
  providers: [DatePipe],
})
export class WritingTestAppliedListComponent implements OnInit {
  public dataList: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  closeResult: string;
  public contentHtmlView: string = '';

  public validTeacher: boolean;
  public teacherList: any = [];
  public flagTeacherSelected: boolean = false;
  public teacher: Teacher;
  public teacherId: number = 0;

  public writingTestApply: any;
  public writingTestApplyId: number = 0;

  public gridWritingTestPending$ = new BehaviorSubject([]);
  public gridWritingTestPending: WritingTestAppliedPending[];
  public showTableWritingTestPending: boolean = false;
  public errorWritingTestPending: string =
    'Nenhum teste disponível para revisão.';

  iconTeacherHtml = 'fas fa-user';
  titleTeacherHtml = 'Troca de teacher';

  public columns: Array<any> = [
    {
      title: 'Turma',
      name: 'course.name',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-left text-default fw-700',
    },
    {
      title: 'Aluno',
      name: 'student.name',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-left text-default fw-700',
    },
    {
      title: 'Status<br>do Teste',
      name: 'designWritingTestStatus',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-default',
    },
    {
      title: 'Disponibilizado em',
      name: 'availabilityDate',
      sort: 'asc',
      className: 'text-center text-info datetime',
    },
    {
      title: 'Finalizado em',
      name: 'finishDate',
      sort: 'asc',
      className: 'text-center text-info datetime',
    },
    {
      title: 'Status<br>de Revisão',
      name: 'designWritingTestReview',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-primary',
    },
    {
      title: 'Revisado em',
      name: 'reviewDate',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
  ];

  constructor(
    private writingTestService: WritingTestService,
    private writingTestApplyService: WritingTestApplyService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private teacherService: TeacherService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.onLoadTeachers();
    this.showTable = false;
    this.loading.hideLoading();
  }

  onLoadTeachers() {
    this.teacherService.all().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.teacherList = response.data;
      } else {
        this.teacherList = new Array<Teacher>();
      }
    });
  }

  loadWritingTestAppliedPending(teacherId: any) {
    this.writingTestApplyService
      .toReviewByTeacher(teacherId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.designWritingTestApplied(response.data);
            this.showTableWritingTestPending = true;
            this.errorWritingTestPending = '';
            this.showTable = true;
          } else {
            this.errorWritingTestPending = response.message;
            this.showTableWritingTestPending = false;
            this.showTable = false;
          }
          this.loading.hideLoading();
        },
        (error: any) => {
          this.showTableWritingTestPending = false;
          this.errorWritingTestPending =
            'Erro ao carregar os dados dos testes.';
          this.showTable = false;
          this.loading.hideLoading();
        }
      );
  }

  designWritingTestApplied(lista: any[]) {
    lista.forEach(function (e) {
      if (e.writingTestStatus >= 0) {
        let writingTestStatusCss = WritingTestStatusCss.get(
          e.writingTestStatus
        );
        let writingTestStatusStr = WritingTestStatusLabel.get(
          e.writingTestStatus
        );
        e.designWritingTestStatus =
          "<span class='badge badge-pill w-100 font-weight-bold " +
          writingTestStatusCss +
          "'>" +
          writingTestStatusStr +
          '</span>';
      }

      if (e.writingTestReview >= 0) {
        let writingTestReviewCss = WritingTestReviewCss.get(
          e.writingTestReview
        );
        let writingTestReviewStr = WritingTestReviewLabel.get(
          e.writingTestReview
        );
        e.designWritingTestReview =
          "<span class='badge badge-pill w-100 font-weight-bold " +
          writingTestReviewCss +
          "'>" +
          writingTestReviewStr +
          '</span>';
      }
    });
    this.gridWritingTestPending = lista;
    this.gridWritingTestPending$.next(this.gridWritingTestPending);
  }

  changeTeacher(row: any, content: any) {
    this.writingTestApply = row;
    this.writingTestApplyId = row.id;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  onTeacherChange(teacherId: number) {
    if (teacherId > 0) {
      this.loading.showLoading();
      this.teacherService.get(teacherId).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.teacher = response.data;
          this.loadWritingTestAppliedPending(teacherId);
          this.flagTeacherSelected = true;
        } else {
          this.teacher = new Teacher(null);
          this.loading.hideLoading();
        }
      });
    }
  }

  selectEvent(item: any) {
    this.onTeacherChange(item.id);
  }

  onChangeSearch(val: string) {
    console.log(val);
  }

  onFocused(e: any) {
    console.log(e);
  }

  selectEventTeacher(item: any) {
    this.teacherId = item.id;
  }

  onChangeSearchTeacher(val: string) {
    console.log(val);
  }

  onFocusedTeacher(e: any) {
    console.log(e);
  }

  doneChangeTeacher() {
    this.writingTestApplyService
      .changeTeacher(this.writingTestApplyId, this.teacherId)
      .subscribe(
        (response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.changeTeacherSuccess();
          } else {
            this.changeTeacherFailed();
          }
          this.loading.hideLoading();
        },
        (error: any) => {
          this.changeTeacherFailed();
          this.loading.hideLoading();
        }
      );
  }

  changeTeacherSuccess() {
    swal({
      type: 'success',
      title: `Teste de escrita atualizado com sucesso!`,
      showConfirmButton: false,
      timer: 2000,
    }).then((result) => {
      this.loading.hideLoading();
      window.location.reload();
    });
  }

  changeTeacherFailed() {
    swal({
      type: 'warning',
      title: `Não foi possivel atualizar o teste de escrita!`,
      showConfirmButton: true,
    });
  }
}
