import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/auth/helpers';
import { ModuleType } from '@app/shared/enum';
import { ContactBySiteListComponent } from './components/contactBySite/contactBySite-list/contactBySite-list.component';
import { ContactBySiteComponent } from './components/contactBySite/contactBySite/contactBySite.component';
import { MessageQueueComponent } from './components/messageQueue/messageQueue.component';
import { NotificationListComponent } from './components/notification/notification-list/notification-list.component';
import { NotificationComponent } from './components/notification/notification/notification.component';
import { GroupNotificationListComponent } from './components/groupNotification/groupNotification-list/groupNotification-list.component';
import { GroupNotificationComponent } from './components/groupNotification/groupNotification/groupNotification.component';
import { ChangeStatusComponent } from './components/contactBySite/change-status/change-status.component';

const routes: Routes = [
  { path: 'contactbysitelist', component: ContactBySiteListComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0MK4CS14', title: 'Contatos' } },
  { path: 'contactbysite', component: ContactBySiteComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0MK4CS14', title: 'Contato' } },
  { path: 'contactbysitechangestatus', component: ChangeStatusComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0MK4CS14', title: 'Troca de status' } },

  { path: 'groupnotificationlist', component: GroupNotificationListComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0MK4GN15', title: 'Grupos de Notificações' } },
  { path: 'groupnotification', component: GroupNotificationComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0MK4GN15', title: 'Grupo de Notificação' } },

  { path: 'notificationlist', component: NotificationListComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0MK4NT16', title: 'Notificações' } },
  { path: 'notification', component: NotificationComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0MK4NT16', title: 'Notificação' } },

  { path: 'messagequeue', component: MessageQueueComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0MK4NT16', title: 'Mensagens' } },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketingRoutingRoutes { }
