import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'managed-table',
  templateUrl: './managed-table.component.html',
  styleUrls: ['./managed-table.component.css'],
})
export class ManagedTableComponent implements OnInit {
  searchForm;
  public showFilter: boolean = true;
  public page: number = 1;
  @Input('pageSize') pageSize: number = 10;
  @Input('maxSize') maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  public filtering: string = '';
  public auxConfig: any;
  private data: Array<any>;
  public fixColumns: Array<any>;
  public config: any;

  public dataSource$ = new BehaviorSubject([]);
  public columnSource$ = new BehaviorSubject([]);
  public lengthAsync$ = new BehaviorSubject(0);

  @Input('rows') rows: Array<any> = [];
  @Input('columns') columns: Array<any> = [];
  @Input('hasActions') hasActions: any;
  @Input('showFilterRow') showFilterRow: boolean = false;
  @Input('hasFilter') hasFilter: any;
  @Input('dataTitle') dataTitle: any;

  @Input('editEnable') editEnable: any;
  @Input('deleteEnable') deleteEnable: any;
  @Input('previewEnable') previewEnable: any;

  @Input('auxEnable') auxEnable: any;
  @Input('titleAux') titleAux: any;
  @Input('auxIcon') auxIcon: any;

  @Input('auxEnable2') auxEnable2: any;
  @Input('titleAux2') titleAux2: any;
  @Input('auxIcon2') auxIcon2: any;

  @Input('auxEnable3') auxEnable3: any;
  @Input('titleAux3') titleAux3: any;
  @Input('auxIcon3') auxIcon3: any;

  @Input('auxEnable4') auxEnable4: any;
  @Input('titleAux4') titleAux4: any;
  @Input('auxIcon4') auxIcon4: any;

  @Input('auxEnable5') auxEnable5: any;
  @Input('titleAux5') titleAux5: any;
  @Input('auxIcon5') auxIcon5: any;

  @Input('auxEnable6') auxEnable6: any;
  @Input('titleAux6') titleAux6: any;
  @Input('auxIcon6') auxIcon6: any;

  @Output('editClick') editClick = new EventEmitter();
  @Output('deleteClick') deleteClick = new EventEmitter();
  @Output('previewClick') previewClick = new EventEmitter();

  @Output('auxClick') auxClick = new EventEmitter();
  @Output('auxClick2') auxClick2 = new EventEmitter();
  @Output('auxClick3') auxClick3 = new EventEmitter();
  @Output('auxClick4') auxClick4 = new EventEmitter();
  @Output('auxClick5') auxClick5 = new EventEmitter();
  @Output('auxClick6') auxClick6 = new EventEmitter();

  constructor(public datepipe: DatePipe) {
    this.config = {
      paging: true,
      sorting: { columns: new Array<any>() },
      filtering: { filterString: '' },
      className: ['table-striped', 'table-bordered'],
    };
  }

  setConfig() {
    this.config.sorting.columns = this.columns;
    // if (this.isMobile()) {
    //   this.pageSize = 5;
    // }
  }

  private isMobile(): boolean {
    return window.screen.width < 640;
  }

  ngOnInit() {
    this.setConfig();
    this.dataSource$.next(this.rows);
    this.columnSource$.next(this.columns);
    this.dataTitle = this.dataTitle;
    this.data = this.rows;
    this.fixColumns = this.columns;
    this.config.paging = true;
    this.showFilter = this.hasFilter;
    this.onChangeSort(this.config);
  }

  public changePage(page: any, data: Array<any> = this.data): Array<any> {
    this.numPages = Math.ceil(data.length / this.pageSize);
    page = page.page ? page.page : page;
    let start = (page - 1) * this.pageSize;
    let end = this.pageSize > -1 ? start + this.pageSize : data.length;
    return data.slice(start, end);
  }

  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    let columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (
        columns[i].sort !== '' &&
        columns[i].sort !== false &&
        columns[i].sort !== undefined
      ) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    let dataSorted = data.sort((previous: any, current: any) => {
      if (
        this.resolve(columnName, previous) > this.resolve(columnName, current)
      ) {
        return sort === 'desc' ? -1 : 1;
      } else if (
        this.resolve(columnName, previous) < this.resolve(columnName, current)
      ) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
    return dataSorted;
  }

  public changeFilter(data: any, config: any): any {
    let filteredData: Array<any> = data;
    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          return item[column.name] === null || item[column.name] === undefined
            ? ''.match(column.filtering.filterString)
            : item[column.name]
                .toUpperCase()
                .match(column.filtering.filterString.toUpperCase());
        });
      }
    });

    if (!config.filtering) {
      return filteredData;
    }

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(
          this.config.filtering.filterString
        )
      );
    }

    let tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (
          this.resolve(column.name, item) === null ||
          this.resolve(column.name, item) === undefined
            ? ''.toString().match(this.config.filtering.filterString)
            : this.resolve(column.name, item)
                .toString()
                .toUpperCase()
                .match(this.config.filtering.filterString.toUpperCase())
        ) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  public onChangeSort(
    config: any,
    page: any = { page: this.page, pageSize: this.pageSize }
  ): any {
    this.clearSorting(config);
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }

    let filteredData = this.changeFilter(this.data, this.config);
    let sortedData = this.changeSort(filteredData, this.config);
    //this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData
    this.rows = page ? this.changePage(page, sortedData) : sortedData;
    this.length = sortedData.length;
  }

  public onChangeFilter(
    config: any,
    page: any = { page: this.page, pageSize: this.pageSize }
  ): any {
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }

    let filteredData = this.changeFilter(this.data, this.config);
    let sortedData = this.changeSort(filteredData, this.config);
    //this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData
    this.rows = page ? this.changePage(page, sortedData) : sortedData;
    this.length = sortedData.length;
  }

  public onChangePage(
    config: any,
    page: any = { page: this.page, pageSize: this.pageSize }
  ): any {
    let filteredData = this.changeFilter(this.data, this.config);
    let sortedData = this.changeSort(filteredData, this.config);
    //this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData
    this.rows = page ? this.changePage(page, sortedData) : sortedData;
    this.length = sortedData.length;
  }

  public onCellClick(event: any, events: any) {}

  public clicked(event: any) {}

  public getData(row: any, propertyName: string): string {
    let data = propertyName
      .split('.')
      .reduce((prev: any, curr: string) => prev[curr], row);
    if (data === null || data === undefined) {
      return '';
    } else {
      return data;
    }
  }

  public getTitle(
    row: any,
    propertyName: string,
    className: string,
    icon: any
  ): string {
    let title = '';
    let p_column = this.columns.find((obj) => {
      title = obj.title;
      return obj.name === propertyName;
    });

    if (p_column && p_column.isHtmlData) {
      return '';
    }
  }

  //Caso o conteudo tenha mais de 30 caracteres a string é cortada e é adicionado "..."
  public getDataSubstring(
    row: any,
    propertyName: string,
    className: string,
    icon: any
  ): string {
    let title = '';
    let p_column = this.columns.find((obj) => {
      title = obj.title;
      return obj.name === propertyName;
    });

    if (p_column && p_column.isHtmlData) {
      return this.getData(row, propertyName);
      return '';
    }

    let data = propertyName
      .split('.')
      .reduce((prev: any, curr: string) => prev[curr], row);

    if (data !== null && data !== undefined) {
      if (data.toString() == 'true' || data.toString() == 'false') {
        if (icon) {
          let strIcon = /true/i.test(data)
            ? icon && icon.iconTrue
              ? icon.iconTrue
              : icon
            : icon && icon.iconFalse
            ? icon.iconFalse
            : icon;

          data = `<i class="fal fa-2x fa-${strIcon} . ${
            /true/i.test(data) ? 'text-success' : 'text-danger'
          }" title="${title}"></i>`;
        } else {
          data = `<i class="fa fa-2x fa-${
            /true/i.test(data) ? 'check text-success' : 'times text-danger'
          }" title="${title}"></i>`;
        }
      } else if (data.length > 50 && p_column.shorData === true) {
        data = data.substring(0, 45) + '...';
      } else {
        if (
          className != null &&
          className != undefined &&
          className.includes('datetime', 0)
        ) {
          data =
            data === '0001-01-01T00:00:00'
              ? '-'
              : this.formatDate(
                  data,
                  className != null &&
                    className != undefined &&
                    className.includes('onlydate', 0)
                );
        } else if (
          className != null &&
          className != undefined &&
          className.includes('currency', 0)
        ) {
          data = this.formatCurrency(data);
        } else {
          data = data;
        }
      }
    }
    return data;
  }

  formatDate(datetime: string, onlydate: boolean = false): string {
    let dateReturn = new Date(datetime);
    return this.datepipe.transform(
      dateReturn,
      onlydate ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm:ss'
    );
  }

  formatCurrency(currency: any): string {
    if (currency) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(currency);
    }
    return '';
  }

  editClicked(row: any) {
    this.editClick.emit(row);
  }

  deleteClicked(row: any) {
    this.deleteClick.emit(row);
  }

  previewClicked(row: any) {
    this.previewClick.emit(row);
  }

  auxClicked(row: any) {
    this.auxClick.emit(row);
  }

  auxClicked2(row: any) {
    this.auxClick2.emit(row);
  }

  auxClicked3(row: any) {
    this.auxClick3.emit(row);
  }

  auxClicked4(row: any) {
    this.auxClick4.emit(row);
  }

  auxClicked5(row: any) {
    this.auxClick5.emit(row);
  }

  auxClicked6(row: any) {
    this.auxClick6.emit(row);
  }

  clearSorting(config: any) {
    for (let i = 0; i < this.columns.length; i++) {
      if (
        this.columns[i].sort !== '' &&
        this.columns[i].sort !== false &&
        this.columns[i].sort !== undefined &&
        this.columns[i].name !== config.name
      ) {
        this.columns[i].sort = undefined;
      }
    }
  }

  public onChangeTable(
    config: any,
    page: any = { page: this.page, pageSize: this.pageSize }
  ): any {
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }

    let filteredData = this.changeFilter(this.data, this.config);
    let sortedData = this.changeSort(filteredData, this.config);
    this.rows =
      page && config.paging ? this.changePage(page, sortedData) : sortedData;
    this.length = sortedData.length;
  }

  resolve(path, obj = self, separator = '.') {
    var properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  }

  public getColumnTitle(column: any) {
    let title = column.title;
    if (column.sort) {
      title += this.getSortIcon(column.sort);
    }
    return title;
  }

  public trackItem(index: number, item: any) {
    return item.id;
  }

  private getSortIcon(sort: string) {
    if (sort === 'asc') {
      return "<i class='pull-right fa fa-chevron-up'></i>";
    } else {
      return "<i class='pull-right fa fa-chevron-down'></i>";
    }
  }

  // window.setMobileTable = function(selector) {
  //   // if (window.innerWidth > 600) return false;
  //   const tableEl = document.querySelector(selector);
  //   const thEls = tableEl.querySelectorAll('thead th');
  //   const tdLabels = Array.from(thEls).map(el => el.innerText);
  //   tableEl.querySelectorAll('tbody tr').forEach( tr => {
  //     Array.from(tr.children).forEach(
  //       (td, ndx) =>  td.setAttribute('label', tdLabels[ndx])
  //     );
  //   });
  // }
}
