export enum CourseType {
    Vip = 0,
    SemiVip = 1
  }
  
  export const CourseTypeLabel = new Map<number, string>([
    [CourseType.Vip, 'VIP'],
    [CourseType.SemiVip, 'SEMI-VIP'],
  ]);
  
  export const CourseTypeCss = new Map<number, string>([
    [CourseType.Vip, 'course-type-vip'],
    [CourseType.SemiVip, 'course-type-semivip'],
  ]);
  