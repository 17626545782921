<div class="page-wrapper">
    <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
            <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                <div class="d-flex align-items-center container p-0">
                    <header-logo></header-logo>
                    <span class="text-white opacity-50 ml-auto mr-2 hidden-sm-down">
                        Já possui uma conta?
                    </span>
                    <a href="#/auth/login" class="btn btn-sm btn-outline-primary text-white ml-sm-0">
                        Entrar
                    </a>
                </div>
            </div>
            <div class="flex-1" style="background: url(/assets/img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
                <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                    <div class="row">
                        <div class="col-xl-12">
                            <h2 class="fs-xxl fw-500 mt-4 text-white text-center">
                                Esqueceu sua senha?
                                <small class="h3 fw-300 mt-3 mb-5 text-white opacity-60 hidden-sm-down">
                                    Sem problemas, isso acontece com todos nós. Basta usar o formulário abaixo para redefini-la!
                                </small>
                            </h2>
                        </div>
                        <div class="col-xl-6 ml-auto mr-auto">
                            <div class="card p-4 rounded-plus bg-faded">
                                <form [formGroup]="forgetForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group" *ngIf="!validReset">
                                        <label class="form-label" for="email">E-mail</label>
                                        <input type="email" id="email" formControlName="email" class="form-control" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required>
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Por favor, informe o seu e-mail.</div>
                                        </div>
                                        <div class="help-block">Enviaremos as instruções por e-mail</div>
                                    </div>
                                    <div class="form-group" *ngIf="validReset">
                                        <div class="card p-4 rounded-plus bg-faded">
                                            <div class="alert alert-primary text-dark" role="alert">
                                                <strong>Pronto!</strong><br>Enviamos um e-mail com todas as informações para você redefinir sua senha.<br>
                                            </div>
                                            <a href="javascript:void(0);" class="h4" (click)="goLogin();">
                                                <i class="fal fa-chevron-right mr-2"></i> Voltar para Login
                                            </a>
                                        </div>
                                    </div>
                                    <div class="alert alert-warning alert-dismissible text-center fade show mt-3" role="alert" *ngIf="!validData">
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                        </button>
                                        {{errorMessage}}
                                    </div>
                                    <div class="row no-gutters" *ngIf="!validReset">
                                        <div class="col-md-4 ml-auto text-right">
                                            <button id="js-login-btn" class="btn btn-block btn-danger btn-lg mt-3" [disabled]="submitted">
                                                <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                                Recuperar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <footer-auth></footer-auth>
            </div>
        </div>
    </div>
</div>
