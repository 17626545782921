// Angular Components
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { TextMaskModule } from '@app/_helpers/mask/text.mask';
import { SharedModule } from '@app/shared/shared.module';
import { TeacherRoutingRoutes } from './teacher-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
//import { NgSelect2Module } from 'ng-select2';

// Components
import { HomeComponent } from './components/home/home.component';
import { CourseComponent } from './components/course/course.component';
import { ContentListComponent } from './components/content/content-list/content-list.component';
import { ContentViewComponent } from './components/content/content-view/content-view.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { WritingTestListComponent } from './components/writing-test/writing-test-list/writing-test-list.component';
import { WritingTestComponent } from './components/writing-test/writing-test/writing-test.component';
import { WritingTestApplyComponent } from './components/writing-test/writing-test-apply/writing-test-apply.component';
import { WritingTestListAppliedComponent } from './components/writing-test/writing-test-list-applied/writing-test-list-applied.component';
import { CalendarTeacherChangesComponent } from './components/calendar-teacher-changes/calendar-teacher-changes.component';
import { SafePipe } from '@app/_helpers/safe/safe.pipe';
import { NgxSummernoteModule } from 'ngx-summernote';
import { WritingTestAppliedReviewComponent } from './components/writing-test/writing-test-applied-review/writing-test-applied-review.component';
import { CourseByTeacherListComponent } from './components/courseByTeacher/course-by-teacher-list/course-by-teacher-list.component';
import { CourseByTeacherDetailComponent } from './components/courseByTeacher/course-by-teacher-detail/course-by-teacher-detail.component';
import { JitsiMeetingComponent } from './components/jitsi-meeting/jitsi-meeting.component';
import { AdminModule } from '@app/admin/admin.module';
import { ProfileComponent } from './components/profile/profile.component';

@NgModule({
  declarations: [
    HomeComponent,
    ProfileComponent,
    CourseComponent,
    JitsiMeetingComponent,
    ContentListComponent,
    ContentViewComponent,
    CalendarComponent,
    WritingTestComponent,
    WritingTestListComponent,
    WritingTestApplyComponent,
    WritingTestListAppliedComponent,
    CalendarTeacherChangesComponent,
    WritingTestAppliedReviewComponent,
    CourseByTeacherListComponent,
    CourseByTeacherDetailComponent,
  ],
  imports: [
    ChartsModule,
    TextMaskModule,
    ArchwizardModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    TeacherRoutingRoutes,
    PdfViewerModule,
    AdminModule,
    //NgSelect2Module,
    NgxSummernoteModule,
    FullCalendarModule, // for FullCalendar!
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe, SafePipe],
})
export class TeacherModule {
  stepDefault: any = 0;
  constructor() {}
}
