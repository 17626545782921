<!-- BEGIN PRIMARY NAVIGATION -->
<nav id="js-primary-nav" class="primary-nav" role="navigation">
    <div class="nav-filter">
        <div class="position-relative">
            <input type="text" id="nav_filter_input" placeholder="Filter menu" class="form-control" tabindex="0">
            <a href="javascript:void(0);" onclick="return false;" class="btn-primary btn-search-close js-waves-off" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar">
                <i class="fal fa-chevron-up"></i>
            </a>
        </div>
    </div>
    <div class="info-card">
        <img src="assets/img/avatars/img-avatar-man-blank.png" class="profile-image rounded-circle" alt="">
        <div class="info-card-text">
            <a href="javascript:void(0);" class="d-flex align-items-center text-white">
                <span class="text-truncate text-truncate-sm d-inline-block">
                    {{user?.name}}
                </span>
            </a>
            <span class="d-inline-block text-truncate text-truncate-sm mt-1 fw-300">{{student?.city?.name +', '+ student?.state?.uf}}</span>
            <br>
            <span class="d-inline-block text-truncate text-truncate-xs mt-1 fw-700 small">{{user?.module}}</span>
            <!-- <br>
            <span class="d-inline-block text-truncate text-truncate-sm">Tempo de curso</span> -->
        </div>
        <img src="assets/img/card-backgrounds/cover-2-lg.png" class="cover" alt="cover">
        <a href="javascript:void(0);" onclick="return false;" class="pull-trigger-btn" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar" data-focus="nav_filter_input">
            <i class="fal fa-angle-down"></i>
        </a>
    </div>
    <ul id="js-nav-menu" class="nav-menu">
        <li class="active" [ngClass]="{ 'active': activeMenu == 'nav.student.dashboard' }">
            <a href="javascript:void" *ngIf="checkHavePermission('EST1DA6DA19');" title="Dashboard" data-filter-tags="dashboard" (click)="menuClick('/student/home', 'nav.student.dashboard')">
                <i class="fa fa-chart-line"></i>
                <span class="nav-link-text" data-i18n="nav.student.dashboard">Dashboard</span>
            </a>
        </li>
        <li class="active" [ngClass]="{ 'active': activeMenu == 'nav.student.profile' }">
            <a href="javascript:void" *ngIf="checkHavePermission('EST1DA6DA19');" title="Meu Perfil" data-filter-tags="perfil" (click)="menuClick('/student/profile', 'nav.student.profile')">
                <i class="fa fa-user-alt"></i>
                <span class="nav-link-text" data-i18n="nav.teacher.class">Meu Perfil</span>
                <span class="dl-ref label bg-success-600 text-white ml-2">Novo</span>
            </a>
        </li>
        <li class="active" [ngClass]="{ 'active': activeMenu == 'nav.student.class' }">
            <a href="javascript:void" *ngIf="checkHavePermission('EST1AL7AL20');" title="Aulas" data-filter-tags="aula" (click)="menuClick('/student/course', 'nav.student.class')">
                <i class="fa fa-book"></i>
                <span class="nav-link-text" data-i18n="nav.student.class">Biblioteca</span>
            </a>
        </li>
        <li class="active" [ngClass]="{ 'active': activeMenu == 'nav.student.schedule' }">
            <a href="javascript:void" *ngIf="checkHavePermission('EST1AG9AG22');" title="Agenda" data-filter-tags="agenda" (click)="menuClick('/student/calendar', 'nav.student.schedule')">
                <i class="fa fa-calendar"></i>
                <span class="nav-link-text" data-i18n="nav.student.schedule">Agenda</span>
            </a>
        </li>
        <!-- <li class="active" [ngClass]="{ 'active': activeMenu == 'nav.student.schedule.async' }">
            <a href="javascript:void" *ngIf="checkIsStudent();" title="Agenda Async" data-filter-tags="agenda" (click)="menuClick('/student/calendarasync', 'nav.student.schedule.async')">
                <i class="fa fa-calendar"></i>
                <span class="nav-link-text" data-i18n="nav.student.schedule.async">Agenda v2</span>
            </a>
        </li> -->
        <li class="active" [ngClass]="{ 'active': activeMenu == 'nav.student.chat' }">
            <a href="javascript:void" *ngIf="checkHavePermission('EST1AT24CH41');" title="Chat" data-filter-tags="chat" (click)="menuClick('/message/workspace', 'nav.student.chat')">
                <i class="fa fa-comments"></i>
                <span class="nav-link-text" data-i18n="nav.student.chat">Chat</span>
            </a>
        </li>
        <li class="active" [ngClass]="{ 'active': activeMenu == 'nav.student.nps' }">
            <a href="javascript:void" *ngIf="showFeedback && checkHavePermission('EST1FB8AV38');" title="Feedback" data-filter-tags="feedback" (click)="studentNpsFormShow(mdStudentNpsForm)">
                <i class="fa fa-comments"></i>
                <span class="nav-link-text" data-i18n="nav.student.nps">Feedback</span>
            </a>
        </li>
        <li class="active" [ngClass]="{ 'active': activeMenu == 'nav.student.writingtest' }">
            <a href="javascript:void" *ngIf="checkHavePermission('EST1WT7AL37');" title="Teste de escrita" data-filter-tags="writingtest" (click)="menuClick('/student/writingtestlist', 'nav.student.writingtest')">
                <i class="fas fa-file-edit"></i>
                <span class="nav-link-text" data-i18n="nav.student.writingtest">Teste de escrita</span>
            </a>
        </li>
        <li class="active" [ngClass]="{ 'active': activeMenu == 'nav.student.finance' }">
            <a href="javascript:void" *ngIf="checkHavePermission('EST1DA6DA19');" title="Financeiro" data-filter-tags="financeiro">
                <i class="fa fa-money-bill"></i>
                <span class="nav-link-text disabled" data-i18n="nav.student.finance">Financeiro</span>
                <span class="dl-ref label bg-primary-100 text-white ml-2">Em breve</span>
            </a>
            <ul class="p-0">
                <li>
                    <a href="javascript:void(0);" *ngIf="checkHavePermission('EST1DA6DA19');" title="Contrato" data-filter-tags="financeiro contrato">
                        <i class="fal fa-file-check"></i>
                        <span class="nav-link-text disabled" data-i18n="nav.student.financeiro.contrato">Contrato</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" *ngIf="checkHavePermission('EST1DA6DA19');" title="Pagamentos" data-filter-tags="financeiro pagamento">
                        <i class="fal fa-money-bill-alt"></i>
                        <span class="nav-link-text disabled" data-i18n="nav.student.financeiro.pagamento">Pagamentos</span>
                    </a>
                </li>
            </ul>
        </li>
        <!-- <li class="disabled">
            <a href="javascript:void" *ngIf="checkHavePermission('EST1AV8AV21');" title="Avisos" data-filter-tags="avisos">
                <i class="fal fa-bell"></i>
                <span class="nav-link-text disabled" data-i18n="nav.student.news">Avisos</span>
                <span class="dl-ref label bg-primary-100 text-white ml-2">Em breve</span>
            </a>
        </li> -->
        <!-- <li class="disabled">
            <a href="javascript:void" *ngIf="checkHavePermission('EST1FI10FI23');" title="Financeiro" data-filter-tags="financeiro">
                <i class="fa fa-money-bill"></i>
                <span class="nav-link-text disabled" data-i18n="nav.student.financeiro">Financeiro</span>
                <span class="dl-ref label bg-primary-100 text-white ml-2">Em breve</span>
            </a>
        </li>
        <li class="disabled">
            <a href="javascript:void" *ngIf="checkHavePermission('EST1BI11BI24');" title="Biblioteca" data-filter-tags="biblioteca">
                <i class="fa fa-book"></i>
                <span class="nav-link-text text-muted" data-i18n="nav.student.library">Biblioteca</span>
                <span class="dl-ref label bg-primary-100 text-white ml-2">Em breve</span>
            </a>
        </li>
        <li class="disabled">
            <a href="javascript:void" *ngIf="checkHavePermission('EST1FO12FO25');" title="Fórum" data-filter-tags="forum">
                <i class="fa fa-comments"></i>
                <span class="nav-link-text text-muted" data-i18n="nav.student.forum">Fórum</span>
                <span class="dl-ref label bg-primary-100 text-white ml-2">Em breve</span>
            </a>
        </li> -->
    </ul>
    <div class="filter-message js-filter-message bg-success-600"></div>
</nav>
<!-- END PRIMARY NAVIGATION -->

<ng-template #mdStudentNpsForm let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Formulário de Feedback!</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c('Close click')">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
    </div>
    <div class="modal-body pb-0">
        <div class="col-12">
            <iframe [src]="urlSafe" width="640" height="1481" marginheight="0" marginwidth="0" frameborder="0" allowFullScreen="true">Carregando…</iframe>
        </div>
    </div>
    <div class="modal-footer pt-0">
        <button id="js-cancel-btn" class="btn btn-default" (click)="c('Close click')">Fechar</button>
    </div>
</ng-template>
