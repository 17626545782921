<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="[byRoute]">Home</a>
    </li>
    <li class="breadcrumb-item active">Chat na plataforma</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div class="row">
    <div class="col-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>Chat Workspace</h2>
                <!-- button for mobile -->
                <a href="javascript:void(0);" class="d-flex d-lg-none align-items-center justify-content-center mr-2 btn btn-xs btn-success btn-pills waves-effect waves-themed"
                    data-action="toggle" data-class="slide-on-mobile-left-show" data-target="#js-chat-contact">
                    Contatos/Conversas
                </a>
                <!-- end button for mobile -->
            </div>
            <div class="panel-container show">
                <div class="panel-content py-0 px-0 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="d-flex flex-grow-1 p-0 border-faded shadow-4">
                        <!-- left slider -->
                        <div id="js-chat-contact" class="flex-wrap position-relative slide-on-mobile slide-on-mobile-left border-faded border-left-0 border-top-0 border-bottom-0">
                            <!-- BEGIN msgr-list -->
                            <div class="d-flex flex-column bg-faded position-absolute pos-top pos-bottom w-100">
                                <div class="px-3 py-4">
                                    <input type="text" class="form-control bg-white" placeholder="Buscar contato" (keydown)="filterContacts($event)" (keyup)="filterContacts($event)">
                                </div>
                                <div class="flex-1 h-100 custom-scrollbar">
                                    <div class="w-100">
                                        <div class="nav-title m-0 px-3 text-muted">Contatos</div>
                                        <ul class="list-unstyled notification m-0">
                                            <li *ngFor='let con of (contactsSource$ | async)' [ngClass]="{ 'unread': con.haveNewMessage }">
                                                <div class="d-flex w-100 px-3 py-2 text-dark hover-white cursor-pointer show-child-on-hover" (click)="chatRoom(con.userId)">
                                                    <div class="profile-image-md rounded-circle" [ngClass]="{ 'status status-sm status-success': con.haveNewMessage }"
                                                        style="background-image:url('assets/img/demo/avatars/avatar-m.png'); background-size: cover;"></div>
                                                    <div class="px-2 flex-1">
                                                        <div class="text-truncate text-truncate-md">
                                                            {{con.name}}
                                                            <small class="d-block text-muted text-truncate text-truncate-md">
                                                                {{con.lastMessageDatetime | date:'dd/MM/yyyy HH:mm:ss'}}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- END msgr-list -->
                        </div>
                        <div class="slide-backdrop" data-action="toggle" data-class="slide-on-mobile-left-show" data-target="#js-chat-contact"></div> <!-- end left slider -->
                        <!-- inbox container -->
                        <div class="d-flex flex-column flex-grow-1 bg-white">
                            <!-- inbox header -->
                            <div class="flex-grow-0" >
                                <!-- inbox title -->
                                <div class="d-flex align-items-center p-0 border-faded border-top-0 border-left-0 border-right-0 flex-shrink-0">
                                    <div class="d-flex align-items-center w-100 pl-3 px-lg-4 py-2 position-relative">
                                        <div class="d-flex flex-row align-items-center mt-1 mb-1">
                                            <a href="javascript:void(0);" class="px-3 py-2 d-flex d-lg-none align-items-center justify-content-center mr-2 btn" 
                                                *ngIf="recipientUser?.name" data-action="toggle" data-class="slide-on-mobile-left-show" data-target="#js-chat-contact">
                                                <i class="fal fa-arrow-left h1 mb-0 "></i>
                                            </a>
                                            <div class="mr-2 d-inline-block">
                                                <span class="rounded-circle profile-image d-block" style="background-image:url('assets/img/demo/avatars/avatar-m.png'); background-size: cover;"></span>
                                            </div>
                                            <div class="info-card-text">
                                                <a href="javascript:void(0);" class="fs-lg text-truncate text-truncate-lg" data-toggle="dropdown" aria-expanded="false">
                                                    {{recipientUser?.name}}
                                                </a>
                                                <span class="text-truncate text-truncate-md opacity-80">{{recipientUser?.module?.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end inbox title -->
                            </div>
                            <!-- end inbox header -->
                            <!-- inbox message -->
                            <div class="flex-wrap align-items-center flex-grow-1 position-relative bg-gray-50" *ngIf="!recipientUserVisible && errorMessage">
                                <div class="position-absolute pos-top pos-bottom w-100 overflow-hidden">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="alert alert-warning alert-dismissible text-left fade show m-3" role="alert">
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                            </button>
                                            {{errorMessage}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-wrap align-items-center flex-grow-1 position-relative bg-gray-50" *ngIf="recipientUserVisible">
                                <div class="position-absolute pos-top pos-bottom w-100 overflow-hidden">
                                    <div class="d-flex h-100 flex-column">
                                        <!-- BEGIN msgr -->
                                        <div class="msgr d-flex h-100 flex-column bg-white">
                                            <!-- BEGIN custom-scroll -->
                                            <div #msgScroll id="msg-scroll" class="custom-scroll flex-1 h-100"
                                                data-scrollHeight="300" 
                                                data-scrollSize="4px" 
                                                data-scrollPosition="right" 
                                                data-scrollColor="rgba(0,0,0,0.6)" 
                                                data-alwaysVisible="true" 
                                                data-distance="4px" 
                                                data-railVisible="false" 
                                                data-railColor="#fafafa">
                                                <div id="chat_container" class="w-100 p-4">
                                                    <!-- start .chat-segment -->
                                                    <!-- <div class="chat-segment">
                                                        <div class="time-stamp text-center mb-2 fw-400">
                                                            Jun 19
                                                        </div>
                                                    </div> -->
                                                    <!--  end .chat-segment -->
                                                    <!-- start .chat-segment -->
                                                    <div *ngFor='let msg of (messagesSource$ | async)'
                                                        [ngClass]="msg.sent ? 'chat-segment chat-segment-sent' : 'chat-segment chat-segment-get'">
                                                        <div class="chat-message">
                                                            <p>{{msg.textMessage}}</p>
                                                        </div>
                                                        <div class="fw-300 text-muted mt-1 fs-xs"
                                                            [ngClass]="msg.sent ? 'text-right' : 'text-left'">
                                                            {{msg.createdOn | date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </div>
                                                    </div>
                                                    <!-- <div class="chat-segment chat-segment-sent">
                                                        <div class="chat-message">
                                                            <p>
                                                                Hey Ching, did you get my files?
                                                            </p>
                                                        </div>
                                                        <div class="text-right fw-300 text-muted mt-1 fs-xs">
                                                            3:00 pm
                                                        </div>
                                                    </div> -->
                                                    <!--  end .chat-segment -->                                                    
                                                </div>
                                            </div>
                                            <!-- END custom-scroll  -->
                                            <!-- BEGIN msgr__chatinput -->
                                            <div class="d-flex flex-column">
                                                <div class="border-faded border-right-0 border-bottom-0 border-left-0 flex-1 mr-3 ml-3 position-relative shadow-top">
                                                    <div class="pt-3 pb-1 pr-0 pl-0 rounded-0" tabindex="-1">
                                                        <textarea rows="3" id="msgr_input" [(ngModel)]="textMsg" placeholder="Mensagem..." maxlength="300" (keydown)="triggerSendMsg($event, $event.target.value)"
                                                        class="form-control rounded-top border-bottom-left-radius-0 border-bottom-right-radius-0 border"></textarea>
                                                        <span class="text-info"><small>[Segure o <span class="fw-700">CTRL + ENTER</span> para enviar uma mensagem.]</small></span>
                                                        <!-- <input type="" id="msgr_input" contenteditable="true" maxlength="300" [(ngModel)]="textMsg" 
                                                        data-placeholder="Mensagem..." class="height-10 form-content-editable"></div> -->
                                                    </div>
                                                </div>
                                                <div class="height-8 px-3 d-flex flex-row align-items-center flex-wrap flex-shrink-0">
                                                    <!-- <a href="javascript:void(0);" class="btn btn-icon fs-xl width-1 mr-1" data-toggle="tooltip" data-original-title="More options" data-placement="top">
                                                        <i class="fal fa-ellipsis-v-alt color-fusion-300"></i>
                                                    </a>
                                                    <a href="javascript:void(0);" class="btn btn-icon fs-xl mr-1" data-toggle="tooltip" data-original-title="Attach files" data-placement="top">
                                                        <i class="fal fa-paperclip color-fusion-300"></i>
                                                    </a>
                                                    <a href="javascript:void(0);" class="btn btn-icon fs-xl mr-1" data-toggle="tooltip" data-original-title="Insert photo" data-placement="top">
                                                        <i class="fal fa-camera color-fusion-300"></i>
                                                    </a> --> 
                                                    <div class="ml-auto">
                                                        <a href="javascript:void(0);" class="btn btn-info" (click)="sendMsg()">
                                                            <i class="fal fa-send"></i> Enviar
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- END msgr__chatinput -->
                                        </div>
                                        <!-- END msgr -->
                                        <!-- end message list -->
                                    </div>
                                </div>
                            </div>
                            <!-- end inbox message -->
                        </div>
                        <!-- end inbox container -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
