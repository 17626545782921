import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TeacherService } from '@app/admin/services/teacher.service';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services';
import { default as swal } from 'sweetalert2';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleType } from '@app/shared/enum/ModuleType';

@Component({
  templateUrl: 'teacher-list.component.html',
  providers: [DatePipe],
})
export class TeacherListComponent implements OnInit {
  public teachers: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  closeResult: string;
  public teacherId: any;
  public moduleType: ModuleType = ModuleType.Teacher;

  iconAvailability = 'fal fa-calendar-alt';
  titleAvailability = 'Disponibilidade';

  public columns: Array<any> = [
    { title: 'Código', name: 'id', sort: 'asc', className: 'text-center' },
    { title: 'Nome', name: 'name', sort: 'asc' },
    { title: 'E-mail', name: 'email', sort: 'asc' },
    { title: 'telefone', name: 'phone', sort: 'asc', className: 'text-center' },
    {
      title: 'Valor/Hora',
      name: 'hourlyRate',
      sort: 'asc',
      className: 'text-center text-primary currency',
    },
    {
      title: 'Usuário Vinculado?',
      name: 'haveUser',
      sort: 'asc',
      icon: {
        iconTrue: 'user-alt',
        iconFalse: 'user-times',
      },
      className: 'text-center text-primary fw-700',
    },
  ];

  constructor(
    private initAdmin: InitAdminService,
    private teacherService: TeacherService,
    private loading: MessageService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadTeachers();
    this.loading.hideLoading();
  }

  loadTeachers() {
    this.loading.showLoading();
    this.teacherService.all().subscribe(
      (response) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.teachers = response.data;
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.loading.hideLoading();
      },
      (error) => {
        this.showTable = false;
        this.returnMessage = 'Erro ao carregar os dados dos professores.';
        this.loading.hideLoading();
      }
    );
  }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/teacher', { id: row.id }]);
  }

  delete(target): void {
    swal({
      title: 'Excluir Professor(a)',
      html:
        'Confirma a exclusão <strong>' +
        target.name +
        '</strong>?<br>' +
        'Ao confirmar o professor(a) será excluído permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.teacherService.remove(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.loadTeachers();
                  //window.location.reload();
                  //this.loadUsers();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir o professor(a)!',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir o professor(a).',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  this.loadTeachers();
                });
              }
            },
            (error) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir o professor(a).',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir o professor(a).',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  preview(id: any): void {
    //this.router.navigate(['/inspecao/inspetor/',idInspetor['id'],idInspetor['area']]);
  }

  create(content: any) {
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        //size: 'sm',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'Save') {
            //this.submitChangePassword();
          }
        },
        (reason) => {
          this.closeResult = `Dismissed`;
          //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();

      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  configTeacherAvailability(row: any, content: any) {
    this.teacherId = row.id;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
}
