export enum RecurrenceDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wenesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export const RecurrenceDayLabel = new Map<number, string>([
  [RecurrenceDay.Sunday, 'Domingo'],
  [RecurrenceDay.Monday, 'Segunda-feira'],
  [RecurrenceDay.Tuesday, 'Terça-feira'],
  [RecurrenceDay.Wenesday, 'Quarta-feira'],
  [RecurrenceDay.Thursday, 'Quinta-feira'],
  [RecurrenceDay.Friday, 'Sexta-feira'],
  [RecurrenceDay.Saturday, 'Sábado']
]);

export const RecurrenceDayCss = new Map<number, string>([
  [RecurrenceDay.Sunday, 'day-sunday'],
  [RecurrenceDay.Monday, 'day-monday'],
  [RecurrenceDay.Tuesday, 'day-tuesday'],
  [RecurrenceDay.Wenesday, 'day-wenesday'],
  [RecurrenceDay.Thursday, 'day-thursday'],
  [RecurrenceDay.Friday, 'day-friday'],
  [RecurrenceDay.Saturday, 'day-saturday']
]);
