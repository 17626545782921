<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/teacher/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Biblioteca</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div class="row">
    <div class="col-12">
        <div class="card text-left">
            <div class="card-body">
                <h1 class="display-4">Biblioteca de conteúdo</h1>
                <p class="card-text">Nessa seção você encontra os conteúdos para as aulas.</p>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="row">
    <div class="col-12" *ngFor="let content of packageContent; let iContent = index">
        <div class="panel">
            <div class="panel-hdr">
                <h2>
                    <span class="fw-300 mr-2"><i>Conteúdo</i></span> {{replaceNameSerie(content.serieType) | uppercase}}
                </h2>
                <div class="panel-toolbar">
                    <button class="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                    <button class="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                    <button class="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="panel-tag">
                        Nesta seção estão reunidas as principais séries <strong>{{replaceNameSerie(content.serieType)}}</strong>.
                    </div>
                    <div class="card-group">
                        <div class="card border m-auto m-lg-0 mr-1" style="max-width: 18rem;" *ngFor="let serie of content.series; let iSerie = index">
                            <img [src]="(getContentPath(content.serieType, serie.folderName) + serie.thumbnail)" class="card-img-top" alt="..."/>
                            <!-- <img [src]="(contentPath + 'regular/' + item.folderName +'/'+ item.thumbnail)" class="card-img-top" alt="..."> -->
                            <div class="card-body">
                                <h5 class="card-title">{{serie.name}}</h5>
                                <p class="card-text text-justify text-muted">{{serie.description}}</p>
                            </div>
                            <div class="card-footer">
                                <button type="button" class="btn btn-sm btn-primary btn-block waves-effect waves-themed" (click)="showContent(content.serieType, serie.item)">
                                    Acessar
                                    <span class="fal fa-arrow-right mr-1"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
