<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/userlist']">Usuários</a>
    </li>
    <li class="breadcrumb-item active">Novo usuário(a)</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div id="panel-2" class="panel">
    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
            Novo <span class="fw-300"><i>Usuário(a)</i></span>
        </h2>
        <div class="panel-toolbar">
            <a href="javascript:void(0);" class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse" title="Minimizar painel">
                <i class="fal fa-minus text-white fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen" title="Maximizar painel">
                <i class="fal fa-expand text-primary fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Close" title="Fechar painel">
                <i class="fal fa-times text-white fw-900"></i>
            </a>
        </div>
    </div>
    <div class="panel-container show">
        <div class="panel-content p-0">
            <form [formGroup]="userForm" novalidate class="needs-validation" (ngSubmit)="onSubmit()">
                <div class="panel-content">
                    <div class="form-row m-3">
                        <div class="col-md-12">
                            <aw-wizard [navBarLayout]="'large-filled-symbols'" [awNavigationMode] navigateBackward="allow" [defaultStepIndex]="0"
                                navigateForward="allow" class="m-l-15 m-r-15">
                                <aw-wizard-step  id="basicData" stepTitle="Dados básicos" (click)="{stepIndex: 0}" [stepId]='1' [navigationSymbol]="{ symbol: '&#xf007;', fontFamily: 'FontAwesome' }">
                                    <hr>
                                    <div class="form-group row mb-2">
                                        <input type="hidden" id="id" formControlName="id"/>
                                        <input type="hidden" id="userGuid" formControlName="userGuid"/>
                                        <!-- <input type="hidden" id="status" formControlName="status"/> -->
                                        <input type="hidden" id="createdOn" formControlName="createdOn"/>
                                        <input type="hidden" id="updatedOn" formControlName="updatedOn"/>
                                        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label class="form-label" for="name">Nome <span class="text-danger">*</span></label>
                                                <input type="text" id="name" formControlName="name" class="form-control" placeholder="Nome do usuário(a)" 
                                                    maxlength="50" required>
                                                <div *ngIf="!validUser && f != null && f?.name?.errors" class="invalid-feedback">
                                                    <div *ngIf="f != null && f?.name?.errors?.required">Por favor, informe o nome do usuário(a).</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label class="form-label" for="moduleType">Módulo <span class="text-danger">*</span> </label>
                                                <select class="form-control" id="moduleType" for="moduleType" formControlName="moduleType" placeholder="Módulo" [(ngModel)]="moduleType"
                                                    [ngClass]="{ 'is-invalid': !validUser && f.moduleType.errors }" (change)="onModuleChange($event.target.value)">
                                                    <option *ngFor="let module of moduleList" [value]="module.moduleType" [selected]="moduleType == module.moduleType">
                                                        {{ module.name }}
                                                    </option>
                                                </select>
                                                <div *ngIf="!validUser && f.moduleType.errors" class="invalid-feedback">
                                                    <div *ngIf="f.moduleType.errors.required">Por favor, informe o módulo.</div>
                                                </div>
                                                <br>
                                                <div class="custom-control custom-switch" *ngIf="flagUserKids">
                                                    <input type="checkbox" class="custom-control-input" id="userKids" formControlName="userKids" checked="" (change)="onUserKids($event)">
                                                    <label class="custom-control-label" for="userKids">Usuário Kids</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div class="form-group row">
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label class="form-label" for="email">E-mail <span class="text-danger">*</span></label>
                                                <input type="email" id="email" formControlName="email" class="form-control" placeholder="E-mail" [ngClass]="{ 'is-invalid': !validUser && f.email.errors }" required>
                                                <div *ngIf="!validUser && f.email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.email.errors.required">Por favor, informe o seu e-mail.</div>
                                                </div>
                                                <div class="help-block">Seu e-mail também será o seu usuário</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <label class="form-label" for="phone">Telefone: <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="custom-control custom-switch pull-right">
                                                            <input type="checkbox" class="custom-control-input" id="foreignPerson" formControlName="foreignPerson" checked="" (change)="onForeignPerson($event)">
                                                            <label class="custom-control-label" for="foreignPerson">Usuário estrangeiro?</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <input type="tel" id="phone" name="phone" formControlName="phone" class="form-control" maxlength="15" 
                                                            placeholder="Telefone" [ngClass]="{ 'is-invalid': !validUser && f?.phone.errors }" (change)="checkPhoneValues($event.target);">

                                                        <div *ngIf="!validUser && f?.phone.errors" class="invalid-feedback">
                                                            <div *ngIf="f?.phone.errors.required">Por favor, informe o seu número de telefone.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div class="form-group row">
                                        <br>
                                        <hr>
                                        <div class="col-md-12 mb-3">
                                            <div class="form-group">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="randomPwd" formControlName="randomPwd" checked="" (change)="onRandomPwd($event)">
                                                    <label class="custom-control-label" for="randomPwd">Gerar automaticamente uma senha forte aleatória</label>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div class="col-md-6" [ngClass]="{ 'disabled': randomPwd }">
                                            <div class="form-group">
                                                <label class="form-label" for="password">Senha:</label><br>
                                                <span class="help-block text-primary fw-500">Não reutilize sua senha bancária e outras já utilizadas.</span>
                                                <input type="password" id="password" formControlName="password" class="form-control" placeholder="Mínimo de 8 caracteres" 
                                                [ngClass]="{ 'is-invalid': !validUser && f.password.errors }" maxlength="20" minlength="8">
                                                <div *ngIf="!validUser && f.password.errors" class="invalid-feedback">
                                                    <div *ngIf="f.password.errors.required">Por favor, informe a sua senha.</div>
                                                    <div *ngIf="f.password.errors.maxlength">A senha deve ter no máximo 20 caracteres.</div>
                                                    <div *ngIf="f.password.errors.minlength">A senha deve ter no mínimo 8 caracteres.</div>
                                                </div>
                                                <div class="help-block">Sua senha deve ter de 8 a 20 caracteres, contendo letras e números e não deve conter espaços, caracteres especiais ou emojis.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6" [ngClass]="{ 'disabled': randomPwd }">
                                            <div class="form-group">
                                                <label class="form-label" for="confirmpassword">Confirme sua senha:</label><br>
                                                <span class="help-block text-primary fw-500">Essa senha deve ser idêntica ao campo anterior.</span>
                                                <input type="password" id="confirmpassword" formControlName="confirmpassword" class="form-control" placeholder="Mínimo de 8 caracteres" 
                                                [ngClass]="{ 'is-invalid': !validUser && f.confirmpassword.errors }" maxlength="20" minlength="8">
                                                <div *ngIf="!validUser && f.confirmpassword.errors" class="invalid-feedback">
                                                    <div *ngIf="f.confirmpassword.errors.required">Por favor, informe a sua senha.</div>
                                                    <div *ngIf="f.confirmpassword.errors.maxlength">A senha deve ter no máximo 20 caracteres.</div>
                                                    <div *ngIf="f.confirmpassword.errors.minlength">A senha deve ter no mínimo 8 caracteres.</div>
                                                    <div *ngIf="f.confirmpassword.errors.notEquivalent">A confirmação de senha não confere.</div>
                                                </div>
                                                <div class="help-block">Sua senha deve ter de 8 a 20 caracteres, contendo letras e números e não deve conter espaços, caracteres especiais ou emojis.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-0">
                                        <div class="row">
                                            <div class="col-sm-12 text-right">
                                                <div id="btnButtonWizard" class="btn-group btn-group-sm btn-group-toggle text-right" data-toggle="buttons">
                                                    <button class="btn btn-info" data-action="toggle" data-class="nav-tabs-clean" disabled="true" awPreviousStep>Anterior</button>
                                                    <button class="btn btn-info active" data-action="toggle" data-class="nav-tabs-clean" awNextStep>Próximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aw-wizard-step>
                                <aw-wizard-step  id="rolesData" stepTitle="Permissões" (click)="{stepIndex: 1}" [stepId]='2' [navigationSymbol]="{ symbol: '&#xf015;', fontFamily: 'FontAwesome' }">
                                    <hr>
                                    <div class="form-group row justify-content-center">
                                        <div class="col-10">
                                            <div class="subheader mb-2 pull-right">
                                                <div class="subheader-block d-lg-flex align-items-center">
                                                    <div class="d-inline-flex flex-column justify-content-center mr-3">
                                                        <span class="fw-300 fs-xs d-block opacity-50">Disponíveis</span>
                                                        <span class="fw-500 fs-xl d-block color-primary-500">{{roleGroupListCount}}</span>
                                                    </div>
                                                    <span class="sparklines hidden-lg-down" sparkType="bar" sparkBarColor="#886ab5" sparkHeight="32px" sparkBarWidth="5px" values="3,4,3,6,7,3,3,6,2,6,4"></span>
                                                </div>
                                                <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                                    <div class="d-inline-flex flex-column justify-content-center mr-3">
                                                        <span class="fw-300 fs-xs d-block opacity-50">Selecionadas</span>
                                                        <span class="fw-500 fs-xl d-block color-danger-500">{{selectedValuesCount}}</span>
                                                    </div>
                                                    <span class="sparklines hidden-lg-down" sparkType="bar" sparkBarColor="#fe6bb0" sparkHeight="32px" sparkBarWidth="5px" values="1,4,3,6,5,3,9,6,5,9,7"></span>
                                                </div>
                                            </div>
                                            <div class="custom-control custom-switch text-left">
                                                <input type="checkbox" class="custom-control-input" id="select_all" (change)="handleItemChangeValue($event, 'all', 0)" (click)="handleItemChangeValue($event, 'all', 0)">
                                                <label class="custom-control-label" for="select_all">Selecionar todos</label>
                                            </div>
                                            <br>
                                            <hr>
                                            <ul class="nav nav-tabs nav-fill" role="tablist">
                                                <ng-container *ngFor="let rGroup of roleGroupList; let i = index;">
                                                    <li class="nav-item">
                                                        <a [ngClass]="i == 0 ? 'nav-link active' : 'nav-link'" data-toggle="tab" [href]="'#'+rGroup.name" role="tab">
                                                            <h4>{{rGroup.name}}</h4>
                                                        </a>
                                                    </li>
                                                </ng-container>
                                            </ul>
                                            <div class="tab-content p-3">
                                                <ng-container *ngFor="let rGroup of roleGroupList; let i = index;"> 
                                                    <div [ngClass]="i == 0 ? 'tab-pane fade show active' : 'tab-pane fade'" [id]="rGroup.name" role="tabpanel">
                                                        <div class="form-group mb-0">
                                                            <div class="row">
                                                                <div class="col-sm-12 text-right">
                                                                    <br>
                                                                    <div class="custom-control custom-switch text-left">
                                                                        <input type="checkbox" class="custom-control-input" [id]="rGroup.id+'_all'" [checked]="checkAllGroup(rGroup.id)" (change)="handleItemChangeValue($event, 'all', rGroup.id)" (click)="handleItemChangeValue($event, 'all', rGroup.id)">
                                                                        <label class="custom-control-label" [for]="rGroup.id+'_all'">Selecionar todos</label>
                                                                    </div>
                                                                    <hr>
                                                                    <ng-container *ngFor="let role of rGroup.roles; let j = index;">
                                                                        <div class="custom-control custom-switch text-left">
                                                                            <input type="checkbox" class="custom-control-input" [checked]="permissionChecked(rGroup.id, role.name)" [id]="role.name" (change)="handleItemChangeValue($event, role.name, rGroup.id)" (click)="handleItemChangeValue($event, role.name, rGroup.id)">
                                                                            <label class="custom-control-label" [for]="role.name">{{role.commonName}}</label>
                                                                        </div>
                                                                        <hr>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="rGroup?.moduleId == 1 && rGroup?.id == 4 && crmControlStatus">
                                                                <div class="col-4">
                                                                    <ng-multiselect-dropdown
                                                                        name="crmControlStatus"
                                                                        [settings]="crmControlStatusDropdownSettings"
                                                                        [data]="crmControlStatusDropdownList"
                                                                        [(ngModel)]="crmControlStatusSelected"
                                                                        (onSelect)="onSelectStatusItem($event)"
                                                                        (onDeSelect)="onDeSelectStatusItem($event)"
                                                                        (onSelectAll)="onSelectAllStatusItems($event)"
                                                                        (onDeSelectAll)="onDeSelectAllStatusItems($event)">
                                                                    </ng-multiselect-dropdown>
                                                                </div>
                                                                <div class="col-8">
                                                                    <span *ngFor="let item of (crmControlStatusSelected$ | async)" class="badge badge-primary text-white mr-2 fs-md">{{item.text}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </aw-wizard-step>
                            </aw-wizard>
                        </div>
                    </div>
                    <div class="form-row m-3" *ngIf="!validUser">
                        <hr>
                        <div class="col-md-12">
                            <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validUser">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                </button>
                                {{errorMessage}}
                                <br>
                                <div *ngFor="let error of listErrorMessage" class="text-danger">
                                    {{error}}
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <footer-form [auxSaveAction]="true" [auxCancelAction]="true" (saveClick)="onSubmit()" (cancelClick)="onCancel()"></footer-form>
    </div>
</div>
