import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CourseService, TeacherService } from '@app/admin/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseGrid, CourseRecurrence } from '@app/admin/model';
import { CourseTypeCss, CourseTypeLabel, RecurrenceDay, RecurrenceDayLabel, TimeDay, TimeDayLabel } from '@app/shared/enum';
import { CourseAvailablePositionsFilter } from '@app/admin/model/courseAvailablePositionsFilter';

@Component({
  selector: 'app-course-available-positions',
  templateUrl: './course-available-positions.component.html',
  providers: [DatePipe] })
export class CourseAvailablePositionsComponent implements OnInit {
  closeResult: string;
  public gridList: CourseGrid[];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = 'Nenhum registro disponível. Utilize o filtro...';
  public auxIcon = "fa fa-users";
  public auxTitle = "Alunos";

  public auxIcon2 = "fas fa-user-plus";
  public auxTitle2 = "Incluir Aluno";

  public teacherDataList: Array<any>;
  public flagTeacherSelected: boolean = false;
  public flagSearchTeacher = false;
  public teacherList: any = [];
  public teacherId: any;
  public teacherData: any;

  public courseDataList: Array<any>;
  public flagCourseSelected: boolean = false;
  public flagSearchCourse = false;
  public courseList: any = [];
  public courseId: any;
  public courseData: any;

  public dayOfWeekDataList: any = [];
  public dayOfWeek: RecurrenceDay;

  public timeDayDataList: any = [];
  public timeDay: any;

  public hourFilter: any;
  public onlyAvailable: boolean = true;
  public flagFiltering: boolean = false;

  public availablePositionsFilter: CourseAvailablePositionsFilter;

  public columns: Array<any> = [
    { title: "Código", name: "id", sort: "asc", className: "text-center" },
    { title: "Nome", name: "name", sort: "asc", isHtmlData: true },
    // { 
    //   title: "Pacote", 
    //   name: "product.name", 
    //   sort: "asc",
    //   isHtmlData: true,
    //   className: "text-left fw-700"
    // },
    { 
      title: "Professor", 
      name: "designTeachersHtml", 
      sort: "asc",
      className: "text-center fw-700"
    },
    { 
      title: "Recorrência", 
      name: "courseRecurrencesHtml", 
      sort: "asc",
      className: "text-center fw-700"
    },
    {
      title: "Tipo", 
      name: "designCourseType", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center"
    },
    { 
      title: "Total<br>de <strong>Meses</strong>", 
      name: "quantityMonth", 
      sort: "asc",
      className: "text-center text-info fw-700"
    },
    { 
      title: "Aulas<br><strong>Semanais</strong>", 
      name: "weeklyFrequency", 
      sort: "asc",
      className: "text-center text-info fw-700"
    },
    { 
        title: "<strong>Limite</strong><br>de Alunos", 
        name: "quantityStudent", 
        sort: "asc",
        className: "text-center text-info fw-700"
    },
    { 
      title: "<strong>Alunos</strong><br>Matriculados", 
      name: "registeredStudents", 
      sort: "asc",
      className: "text-center text-info fw-700"
    },
    { 
      title: "<strong>Posições</strong><br>Disponíveis", 
      name: "availablePositions", 
      sort: "asc",
      className: "text-center text-success fw-700"
    }
  ];
  
  constructor(
    private initAdmin: InitAdminService,
    private courseService: CourseService,
    private teacherService: TeacherService,
    private router: Router,
    private modalService: NgbModal,
    private loading: MessageService
  ) { 
    this.gridList = new Array<CourseGrid>();
    this.availablePositionsFilter = new CourseAvailablePositionsFilter(null);
  }

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadTeachers();
    this.loadDayOftWeekList();
    this.loadTimeDayList();
  }

  filter() {
    this.loading.showLoading();
    this.flagFiltering = true;
    this.returnMessage = '';

    if (this.teacherId > 0) {
      this.availablePositionsFilter.teacherId = this.teacherId;
    } else {
      this.availablePositionsFilter.teacherId = 0;
    }

    if (this.courseId > 0) {
      this.availablePositionsFilter.courseId = this.courseId;
    } else {
      this.availablePositionsFilter.courseId = 0;
    }

    if (this.dayOfWeek !== null) {
      this.availablePositionsFilter.dayOfWeek = this.dayOfWeek;
    } else {
      this.availablePositionsFilter.dayOfWeek = null;
    }

    if (this.timeDay !== null) {
      this.availablePositionsFilter.timeDay = this.timeDay;
    } else {
      this.availablePositionsFilter.timeDay = null;
    }

    if (this.hourFilter !== null) {
      this.availablePositionsFilter.hourFilter = this.getTimeByString(this.hourFilter);
    } else {
      this.availablePositionsFilter.hourFilter = null;
    }

    this.availablePositionsFilter.onlyAvailable = this.onlyAvailable;

    this.courseService.availablePositions(this.availablePositionsFilter).pipe(first())
    .subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.customDesignListCourse(response.data);
        this.showTable = true;
        this.returnMessage = '';
        this.loading.hideLoading();
        this.flagFiltering = false;
      } else {
        this.returnMessage = response.message;
        this.showTable = false;
        this.loading.hideLoading();
        this.flagFiltering = false;
      }
    }, (error: any) => {
      this.showTable = false;
      this.loading.hideLoading();
      this.flagFiltering = false;
      this.returnMessage = 'Erro ao carregar os dados dos cursos.';
    });
  }

  getTimeByString(strTime: any) {
    if (strTime != null && strTime.length > 0) {
        let dateTime = new Date();
        dateTime.setMilliseconds(0);
        dateTime.setSeconds(0);
        dateTime.setMinutes(strTime.split(':')[1]);
        dateTime.setHours(strTime.split(':')[0]);
        return dateTime;
    }
  }
  
  loadDayOftWeekList() {
    this.dayOfWeekDataList = new Array<any>();
    for(var n in RecurrenceDay) {
      if (typeof RecurrenceDay[n] === 'number') {
        this.dayOfWeekDataList.push({
          id: n,
          name: RecurrenceDayLabel.get(<any>RecurrenceDay[n]).toUpperCase()
        });
      }
    }
  }

  loadTimeDayList() {
    this.timeDayDataList = new Array<any>();
    for(var n in TimeDay) {
      if (typeof TimeDay[n] === 'number') {
        this.timeDayDataList.push({
          id: n,
          name: TimeDayLabel.get(<any>TimeDay[n]).toUpperCase()
        });
      }
    }
  }

  loadTeachers() {
    this.teacherService.all().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.teacherDataList = this.getTemplateDataList(response.data);
        this.teacherId = 0;
      } else {
        this.teacherList = new Array<any>();
        this.teacherDataList = new Array<any>();
      }
      this.flagSearchTeacher = false;
    }, (error: any) => {
      this.flagSearchTeacher = false;
    });
  }

  setTeacher(value: any) {
    if (value) {
      this.teacherId = value.id;
      this.onLoadCoursesByTeacherId(value.id);
    } else {
      this.teacherId = 0;
      this.courseId = 0;
      this.courseDataList = [];
    }
  }

  onLoadCoursesByTeacherId(teacherId: any) {
    this.courseService.getByTeacherId(teacherId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.courseDataList = this.getTemplateDataList(response.data);
        this.courseId = 0;
      } else {
        this.courseDataList = new Array<any>();
      }
      this.flagSearchCourse = false;
    }, (error: any) => {
      this.flagSearchCourse = false;
    });
  }

  setCourse(item: any) {
    if (item) {
      this.courseId = item.id;
    } else {
      this.courseId = 0;
    }
  }

  setOnlyAvailable(input: any) {
    this.onlyAvailable = input.currentTarget.checked;
  }

  getTemplateDataList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      let newArray: Array<any> = new Array<any>();
      this.teacherList = data.sort(function(a: any, b: any) {
        var textA = a.name.toLowerCase();
        var textB = b.name.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id,
          name: (this.pad(data[i].id, 6) + " | " + data[i].name).toUpperCase(),
        };
        newArray.push(element);
      }
      return newArray;
    }
    return new Array<any>();
  }

  pad(num: any, size: any) {
    if (num > 0) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    }
    return num;
  }

  setTimeDay(value: any) {
    if (value) {
      var timeDay : TimeDay = TimeDay[value.id as keyof typeof TimeDay];
      this.timeDay = timeDay;
    } else {
      this.timeDay = null;
    }
  }

  setDayOfWeek(value: any) {
    if (value) {
      var dayOfWeek : RecurrenceDay = RecurrenceDay[value.id as keyof typeof RecurrenceDay];
      this.dayOfWeek = dayOfWeek;
    } else {
      this.dayOfWeek = null;
    }
  }

  customDesignListCourse(lista: any[]) {
    lista.forEach(function(e) {
      if (e.courseType >= 0) {
        let courseTypeCss = CourseTypeCss.get(e.courseType);
        let courseTypeStr = CourseTypeLabel.get(e.courseType)
        e.designCourseType = "<span class='badge badge-pill w-100 "
          + courseTypeCss +"'>"
          + courseTypeStr +"</span>";
      }

      e.courseRecurrencesHtml = '';
      e.designTeachersHtml = '';
      if (e.courseRecurrencesData && e.courseRecurrencesData.length > 0) {
        e.courseRecurrencesData.forEach((crd: any, index: number) => {
          let rec = new CourseRecurrence(crd, false);
          if (rec) {
            if (rec.dayOfWeek >= 0 && rec.timeStart instanceof Date) {
              let recurrenceDaySrt = RecurrenceDayLabel.get(rec.dayOfWeek)
              let timeStart = new Date(rec.timeStart);
              let timeEnd = new Date(rec.timeEnd);
              let duration = new Date(rec.duration);

              let timeStartStr = timeStart.toTimeString().split(':')[0] +":"+ timeStart.toTimeString().split(':')[1];
              let timeEndStr = timeEnd.toTimeString().split(':')[0] +":"+ timeEnd.toTimeString().split(':')[1];
              let durationStr = duration.toTimeString().split(':')[0] +"h"+ duration.toTimeString().split(':')[1];

              e.courseRecurrencesHtml += "<span class='badge border border-primary text-primary'><i class='fas fa-solid fa-clock'></i> "
                + recurrenceDaySrt.substring(0,3) + " | " + timeStartStr + " à " + timeEndStr + " | " + durationStr + "</span>";

              e.designTeachersHtml += "<span class='badge border border-primary text-primary fw-700 w-100'>"
              +"<i class='fas fa-solid fa-user'></i> " + rec.teacher?.name + "</span>"
              // "<span class='fw-700 text-primary'>"+rec.teacher.name+"</span>";

              if (e.courseRecurrencesData.length > (index + 1)) {
                e.courseRecurrencesHtml += "<br>";
                e.designTeachersHtml += "<br>";
              }
            }
          }
        });
      } else {
        e.courseRecurrencesHtml = "<span class='badge badge-pill badge-secondary w-100'>Não há recorrências</span>";
        e.designTeachersHtml = "<span class='badge badge-pill badge-secondary w-100'>Não há professor(a) vinculado(a).</span>";
      }
    });
    this.gridList = lista;
  }

  edit(row:any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/course', { id: row.id }]);
  }

  addStudentCourse(row: any, content) {
    this.courseId = row.id;
    this.modalService.dismissAll();
    this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  manageStudents(row: any) {
    this.courseId = row.id;
    this.router.navigate(['/admin/studentCourselist', { courseId: this.courseId }]);
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();
  
      console.log(reason);
  
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }

  showStudents(content) {
    this.modalService.dismissAll();
    this.modalService.open(content, { backdrop: 'static', windowClass:'animated fade'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if(result === 'Save'){
        //this.submitChangePassword();
      }
    }, (reason) => {
      this.closeResult = `Dismissed`;
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
