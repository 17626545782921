import { Base } from '@app/shared/model';
import { CheckListItem, StudentCheckList } from '.';

export class StudentCheckListItemLog extends Base {
    name: string;
    module: number;
    checkListItems: Array<CheckListItem>;
    studentCheckLists: Array<StudentCheckList>;

    constructor(contentResponse: any) {
        super(contentResponse);
        this.name = contentResponse.name;
        this.module = contentResponse.module;

        if (contentResponse.checkListItems && contentResponse.checkListItems.lenght > 0) {
            this.checkListItems = contentResponse.checkListItems;
        }
        if (contentResponse.studentCheckLists && contentResponse.studentCheckLists.lenght > 0) {
            this.studentCheckLists = contentResponse.studentCheckLists;
        }
    }
}
