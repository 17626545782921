import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentClassesCourseConfirmedClassesService {
  private route: string = "reports";
  private controller: string = "StudentClassesCourseConfirmedClasses";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  confirmedClassesAnalytic(filter: any): any {
    return this.http.post<ResponseResult>(`${this.endpointPrefix}/confirmedClassesAnalytic`, filter);
  }

  confirmedClassesSynthetic(filter: any): any {
    return this.http.post<ResponseResult>(`${this.endpointPrefix}/ConfirmedClassesSynthetic`, filter);
  }

  previewSynthetic(teacherId: any, expectedDate: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/PreviewSynthetic/teacher/${teacherId}/expectedDate/${expectedDate}`);
  }
}
