import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private route: string = "teacher";
  private controller: string = "chat";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  contactsByTeacher(teacherId: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/contactsByTeacher/${teacherId}`);
  }
}
