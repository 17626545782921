import { Component, Input, OnInit } from '@angular/core';
import { CourseRecurrenceService, CourseService, StudentClassesCourseService, TeacherService } from '@app/admin/services';
import { TypeResponse, StatusClass, StatusClassLabel, RecurrenceDayCss, RecurrenceDayLabel } from '@app/shared/enum';
import { MessageService } from '@app/shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { default as swal } from 'sweetalert2'

@Component({
  selector: 'studentclassescourse-teacher-changes',
  templateUrl: './studentclassescourse-teacher-changes.component.html',
  styleUrls: ['./studentclassescourse-teacher-changes.component.css']
})
export class StudentclassescourseTeacherChangesComponent implements OnInit {
  @Input("studentCourseClassId") studentCourseClassId: any;
  @Input("courseRecurrenceId") courseRecurrenceId: any;
  @Input("courseId") courseId: any;
  
  public returnMessage: string = '';
  public options: Options;
  public flagSearchTeacher = false;
  public validTeacher: boolean = true;
  public errorMessage: string;
  public teacherDataList: any[];
  public teacherData: any;
  public teacherList: any = [];
  public teacherId: number = 0;

  public courseRecurrenceLoaded: any = null;
  public flagTeacherLoaded: boolean = false;

  constructor(
    private teacherService: TeacherService,
    private courseRecurrenceService: CourseRecurrenceService,
    private studentClassesCourseService: StudentClassesCourseService,
    private courseService: CourseService,
    private loading: MessageService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.options = {
      width: '300'
    };
    if ((this.courseId && this.courseRecurrenceId) || (this.studentCourseClassId)) {
      this.getRecurrenceInfo(this.courseRecurrenceId);
    } else {
      window.location.reload();
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  loadTeachers(teacherId: any) {
    this.loading.showLoading();
    var service = teacherId
      ? this.teacherService.getWithoutMe(teacherId)
      : this.teacherService.all();

    service.subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.teacherDataList = this.getTemplateTeacherList(response.data);
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
      }
      this.loading.hideLoading();
    }, (error: any) => {
      this.returnMessage = 'Erro ao carregar os dados dos professores.';
      this.loading.hideLoading();
    });
  }

  getRecurrenceInfo(recId: any) {
    this.courseRecurrenceService.getById(recId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.courseRecurrenceLoaded = response.data ;
        this.designRecurrence(this.courseRecurrenceLoaded);
        this.loadTeachers(this.courseRecurrenceLoaded.teacherId);
        this.flagTeacherLoaded = true;
      }
      this.loading.hideLoading();
    }, (error: any) => {
      this.flagTeacherLoaded = true;
      this.loadTeachers(null);
      this.loading.hideLoading();
    });
  }

  private designRecurrence(rec: any) {
    if (rec.dayOfWeek >= 0) {
      let recurrenceDayCss = RecurrenceDayCss.get(rec.dayOfWeek);
      let recurrenceDaySrt = RecurrenceDayLabel.get(rec.dayOfWeek)
      rec.designDayOfWeek = "<span class='badge badge-pill w-100 "
        + recurrenceDayCss +"'>"
        + recurrenceDaySrt +"</span>";

      if (rec.timeStart) {
        let timeStart = new Date();
        timeStart.setMilliseconds(0);
        timeStart.setSeconds(0);
        timeStart.setMinutes(rec.timeStart.split(':')[1]);
        timeStart.setHours(rec.timeStart.split(':')[0]);
        rec.timeStart = timeStart;
      }
      if (rec.timeEnd) {
        let timeEnd = new Date();
        timeEnd.setMilliseconds(0);
        timeEnd.setSeconds(0);
        timeEnd.setMinutes(rec.timeEnd.split(':')[1]);
        timeEnd.setHours(rec.timeEnd.split(':')[0]);
        rec.timeEnd = timeEnd;
      }
      if (rec.duration) {
        let duration = new Date();
        duration.setMilliseconds(0);
        duration.setSeconds(0);
        duration.setMinutes(rec.duration.split(':')[1]);
        duration.setHours(rec.duration.split(':')[0]);
        rec.duration = duration;
      }
      
      if (rec.timeStart instanceof Date) {
        let timeStart = new Date(rec.timeStart);
        rec.designTimeStart = "<span class='badge border border-primary text-primary w-100'>"+
          "<i class='fas fa-solid fa-clock'></i> "+ timeStart.toTimeString().split(' ')[0] +"</span>";

        let timeEnd = new Date(rec.timeEnd);
        rec.designTimeEnd = "<span class='badge border border-primary text-primary w-100'>"+
          "<i class='fas fa-solid fa-clock'></i> "+ timeEnd.toTimeString().split(' ')[0] +"</span>";
        
        let duration = new Date(rec.duration);
        rec.designDuration = "<span class='badge border border-info text-info w-100'>"+
          "<i class='fas fa-solid fa-clock'></i> "+ duration.toTimeString().split(' ')[0] +"</span>";
      }
    }
  }

  getTemplateTeacherList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.teacherList = data;
      let arrayTeacherList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id.toString(),
          name: data[i].id + " | " + data[i].name + " | " + data[i].email
        };
        arrayTeacherList.push(element);
      }
      return arrayTeacherList;
    }
    return new Array<any>();
  }

  setTeacher(value: any) {
    this.teacherId = value;
    this.teacherData = this.teacherList.find(x => x.id == this.teacherId);
  }

  done(confirmed: boolean = false) {
    this.loading.showLoading();
    this.errorMessage = '';
    if (this.teacherId > 0) {
      this.teacherData = this.teacherList.find((x: any) => x.id == this.teacherId);
      var endpoint = this.courseRecurrenceId > 0 
        ? this.courseService.changeTeacherByCourseAndRecurrence(this.courseId, this.courseRecurrenceId, this.teacherData)
        : this.studentClassesCourseService.changeTeacher(this.studentCourseClassId, this.teacherId, confirmed);
      endpoint.subscribe((response: any) => {
        this.loading.hideLoading();
        if (response.typeResponse == TypeResponse.Success) {
          this.changedClassSuccess(StatusClass.ChangedTeacher);
        } else {
          if (response?.message?.includes('Teacher já possui')) {
            this.changeTeacherFailedExceeded(response.message);
            return;
          }
          this.changedClassFailed(StatusClass.ChangedTeacher);
        }
      }, (error: any) => {
        this.loading.hideLoading();
        this.changedClassFailed(StatusClass.ChangedTeacher);
      });
      this.cancel();
    } else {
      this.loading.hideLoading();
      this.validTeacher = false;
      this.errorMessage = "Nenhum(a) professor(a) selecionado.";
    }
  }

  cancel() {
    this.dismissModal();
  }

  changeTeacherFailedExceeded(message: string) {
    swal({
      title: "Limite de aluno excedido",
      html: message,
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, prosseguir'
    }).then(willDelete => {
      if (willDelete.value === true) {
        return this.done(true);
      }
    },
    error => {// expiração do token
      swal({
        type: 'error',
        title: 'Erro ao tentar atualizar a aula.',
        showConfirmButton: false,
        timer: 1500
      }).then((result) => {
        this.loading.hideLoading();
        window.location.reload();
      });
    });
  }

  changedClassSuccess(status: StatusClass) {
    swal({
      type: 'success',
      title: `Alteração de professor(a) realizada com sucesso!`,
      text: `Todas as aulas agendadas já foram atualizadas para o(a) novo(a) professor(a).`,
      showConfirmButton: true
    }).then((result) => {
      this.loading.hideLoading();
      window.location.reload();
    });
  }

  changedClassFailed(status: StatusClass) {
    swal({
      type: 'warning',
      title: `Não foi possível alterar o(a) professor(a) da turma!`,
      showConfirmButton: true,
    });
  }

  selectEvent(target: any) {
    this.setTeacher(target?.id);
  }

  onChangeSearch(val: string) {
    console.log(val);
  }

  onFocused(e :any) {
    console.log(e);
  }
}
