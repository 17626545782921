<!--Table rows-->
<div id="panel-3" class="panel">
    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
            <span class="fw-300"><i>Parcelas</i></span>
        </h2>
    </div>
    <div class="panel-container show">
        <div class="panel-content">
            <div class="frame-wrap">
                <table class="table table-striped m-0">
                    <thead>
                        <tr>
                            <th class="text-center">Nº da Parcela</th>
                            <th class="text-center">Emissão</th>
                            <th class="text-center">Vencimento</th>
                            <th class="text-center">Valor</th>
                            <th class="text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="text-center" scope="row">1</th>
                            <td class="text-center">01/01/2021</td>
                            <td class="text-center">10/01/2021</td>
                            <td class="text-center text-primary fw-700">R$ 300,00</td>
                            <td class="text-center">
                                <span class="badge badge-success badge-pill">Pago</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">2</th>
                            <td class="text-center">01/02/2021</td>
                            <td class="text-center">10/02/2021</td>
                            <td class="text-center text-primary fw-700">R$ 300,00</td>
                            <td class="text-center">
                                <span class="badge badge-info badge-pill">Processando</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">3</th>
                            <td class="text-center">01/03/2021</td>
                            <td class="text-center">10/03/2021</td>
                            <td class="text-center text-primary fw-700">R$ 300,00</td>
                            <td class="text-center">
                                <span class="badge badge-danger badge-pill">Pendente</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">4</th>
                            <td class="text-center">01/04/2021</td>
                            <td class="text-center">10/04/2021</td>
                            <td class="text-center text-primary fw-700">R$ 300,00</td>
                            <td class="text-center">
                                <span class="badge badge-danger badge-pill">Pendente</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>