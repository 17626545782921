import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { User } from '@app/auth/model';

//requiring path and fs modules
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from '@app/shared/services';
import { AccountService } from '@app/auth/services';
import { InitStudentService } from '@app/student/services';
import { SerieType } from '@app/shared/enum';

@Component({
  selector: 'student-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {
  private contentFile: string = `${environment.contentFiles}`;
  private structureFile: string;
  public showTable: boolean = true;
  public returnMessage: string = '';
  public packageContentRegular: any = {
    series: []
  };
  public packageContentKids: any = {
    series: []
  };

  public showRegularContent: boolean = false;
  public showKidsContent: boolean = false;

  public contentPathRegular: string;
  public contentPathKids: string;

  public imgFileName: string;
  user: User;
  private userRoles: Array<any>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private loading: MessageService,
    private accountService: AccountService,
    private initStudent: InitStudentService) {
      this.user = this.accountService.getUserData();
      this.userRoles = this.accountService.getRolesByUser();
      this.structureFile = `${this.contentFile}/structure-files.json`;
  }

  ngOnInit(): void {
    this.initStudent.setConfigPage();
    this.getJSON().subscribe(data => {
      let contentKidsData = data.contents.find((x: { serieType: string; }) => x.serieType == 'kids');
      let contentRegularData = data.contents.find((x: { serieType: string; }) => x.serieType == 'regular');
      if (this.canSeeAllSeries()) {
        this.showKidsContent = true;
        this.showRegularContent = true;
        this.contentKids(contentKidsData);
        this.contentRegular(contentRegularData);
      } else if (this.user.userKids) {
        this.showKidsContent = true;
        this.showRegularContent = false;
        this.contentKids(contentKidsData);
      } else {
        this.showKidsContent = false;
        this.showRegularContent = true;
        this.contentRegular(contentRegularData);
      }
    });
  }

  private contentKids(content: any) {
    this.packageContentKids = content;
    this.contentPathKids = `${this.contentFile}/pecm/kids/`;  
  }

  private contentRegular(content: any) {
    this.packageContentRegular = content;
    this.contentPathRegular = `${this.contentFile}/pecm/regular/`;
  }

  public getJSON(): Observable<any> {
    var call = this.http.get<any>(this.structureFile);
    return call.pipe(map(response => {
      return response
    }));
  }

  showContent(itemSerie: any, serieType: SerieType): void {
    this.loading.showLoading();
    this.router.navigate(['/student/contentlist', { serie: itemSerie, serieType: serieType }]);
  }

  canSeeAllSeries(): boolean {
    let role = "EST1TS7AL36";
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some(x => x.keyDefault == role);
    } else {
      return false;
    }
  }
}
