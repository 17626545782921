<!-- BEGIN PRIMARY NAVIGATION -->
<nav id="js-primary-nav" class="primary-nav" role="navigation">
  <div class="nav-filter">
    <div class="position-relative">
      <input
        type="text"
        id="nav_filter_input"
        placeholder="Filter menu"
        class="form-control"
        tabindex="0"
      />
      <a
        href="javascript:void(0);"
        onclick="return false;"
        class="btn-primary btn-search-close js-waves-off"
        data-action="toggle"
        data-class="list-filter-active"
        data-target=".page-sidebar"
      >
        <i class="fal fa-chevron-up"></i>
      </a>
    </div>
  </div>
  <div class="info-card">
    <img
      src="assets/img/demo/avatars/avatar-m.png"
      class="profile-image rounded-circle"
      alt=""
    />
    <div class="info-card-text">
      <a
        href="javascript:void(0);"
        class="d-flex align-items-center text-white"
      >
        <span class="text-truncate text-truncate-sm d-inline-block">
          {{ user.name }}
        </span>
      </a>
      <span
        class="d-inline-block text-truncate text-truncate-xs mt-1 fw-700 small"
        >{{ user.module }}</span
      >
      <span class="d-inline-block text-truncate text-truncate-sm">{{
        user.username
      }}</span>
      <a
        href="javascript:void(0);"
        *ngIf="checkHavePermission('')"
        (click)="menuClick('/admin/profile')"
        title="Perfil do usuário"
        class="d-flex align-items-center text-white"
      >
        <span
          class="text-success fw-500 text-truncate text-truncate-sm d-inline-block"
          >Meu Perfil</span
        >
      </a>
    </div>
    <img
      src="assets/img/card-backgrounds/cover-2-lg.png"
      class="cover"
      alt="cover"
    />
  </div>
  <ul id="js-nav-menu" class="nav-menu">
    <li class="active">
      <a
        href="javascript:void"
        title="Dashboard"
        data-filter-tags="dashboard"
        (click)="menuClick('/admin/home')"
      >
        <i class="fa fa-chart-line"></i>
        <span class="nav-link-text" data-i18n="nav.administrativo.dashboard"
          >Dashboard</span
        >
      </a>
    </li>
    <li class="nav-title text-white fw-700">
      <i class="fa fa-home"></i> Menu Administrativo
    </li>
    <li class="">
      <a href="javascript:void" title="Cadastros" data-filter-tags="cadastro">
        <i class="fa fa-list-alt"></i>
        <span class="nav-link-text" data-i18n="nav.administrativo.cadastro"
          >Cadastros</span
        >
        <span class="dl-ref label bg-success-600 text-white mr-2">Novo</span>
      </a>
      <ul class="p-0">
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2AL2')"
            (click)="menuClick('/admin/studentlist')"
            title="Aluno"
            data-filter-tags="cadastro aluno"
          >
            <i class="fal fa-graduation-cap"></i>
            <span class="nav-link-text" data-i18n="nav.cadastro_aluno"
              >Aluno</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2CT34')"
            (click)="menuClick('/admin/contentSerielist')"
            title="Conteúdo"
            data-filter-tags="cadastro conteudo"
          >
            <i class="fal fa-video"></i>
            <span class="nav-link-text" data-i18n="nav.cadastro_conteudo"
              >Conteúdo</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2CU3')"
            (click)="menuClick('/admin/courselist')"
            title="Turma"
            data-filter-tags="cadastro turma"
          >
            <i class="fal fa-box"></i>
            <span class="nav-link-text" data-i18n="nav.cadastro_turma"
              >Turma</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2DP4')"
            (click)="menuClick('/admin/departmentlist')"
            title="Banco"
            data-filter-tags="cadastro departamento"
          >
            <i class="fas fa-building"></i>
            <span class="nav-link-text" data-i18n="nav.cadastro_departamento"
              >Departamento</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2CU3')"
            (click)="menuClick('/audit/holidayList')"
            title="Feriado"
            data-filter-tags="cadastro feriado"
          >
            <i class="fas fa-truck"></i>
            <span class="nav-link-text" data-i18n="nav.cadastro_feriado"
              >Feriado</span
            >
            <span class="dl-ref label bg-success-600 text-white ml-2"
              >Novo</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2FN5')"
            (click)="menuClick('/admin/supplierlist')"
            title="Fornecedor"
            data-filter-tags="cadastro fornecedor"
          >
            <i class="fas fa-truck"></i>
            <span class="nav-link-text" data-i18n="nav.cadastro_fornecedor"
              >Fornecedor</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2PR6')"
            (click)="menuClick('/admin/packagelist')"
            title="Pacote"
            data-filter-tags="cadastro pacote"
          >
            <i class="fas fa-shopping-basket"></i>
            <span class="nav-link-text" data-i18n="nav.cadastro_product"
              >Pacote</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2PF7')"
            (click)="menuClick('/admin/teacherlist')"
            title="Professor"
            data-filter-tags="cadastro professor"
          >
            <i class="fas fa-user-circle"></i>
            <span class="nav-link-text" data-i18n="nav.cadastro_professor"
              >Professor</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2PR6')"
            (click)="menuClick('/admin/writingtestlist')"
            title="Writing Test"
            data-filter-tags="cadastro writing test"
          >
            <i class="fas fa-laptop"></i>
            <span class="nav-link-text" data-i18n="nav.cadastro_writing_test"
              >Writing Test</span
            >
          </a>
        </li>
      </ul>
    </li>
    <li class="">
      <a href="javascript:void" title="Gerencial" data-filter-tags="gerencial">
        <i class="fa fa-cube"></i>
        <span class="nav-link-text" data-i18n="nav.administrativo.gerencial"
          >Gerencial</span
        >
        <span class="dl-ref label bg-success-600 text-white mr-2">Novo</span>
      </a>
      <ul class="p-0">
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2AL2')"
            (click)="menuClick('/admin/courselist')"
            title="Turma"
            data-filter-tags="cadastro turma"
          >
            <i class="fas fa-tasks"></i>
            <span class="nav-link-text" data-i18n="nav.cadastro_turma"
              >Aulas</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void"
            *ngIf="checkHavePermission('ADM0GR26PD47')"
            title="Student Calendar"
            data-filter-tags="agenda"
            (click)="menuClick('/admin/studentCalendar')"
          >
            <i class="fa fa-calendar"></i>
            <span class="nav-link-text" data-i18n="nav.student.schedule"
              >Agenda Aluno</span
            >
            <span class="dl-ref label bg-success-600 text-white ml-2"
              >Novo</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void"
            *ngIf="checkHavePermission('ADM0GR26PD47')"
            title="Teacher Calendar"
            data-filter-tags="agenda"
            (click)="menuClick('/admin/teacherCalendar')"
          >
            <i class="fa fa-calendar"></i>
            <span class="nav-link-text" data-i18n="nav.teacher.schedule"
              >Agenda Teacher</span
            >
            <span class="dl-ref label bg-success-600 text-white ml-2"
              >Novo</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2AL2')"
            (click)="menuClick('/admin/checklistlist')"
            title="Check-list"
            data-filter-tags="gerencial checklist"
          >
            <i class="fal fa-tasks"></i>
            <span class="nav-link-text" data-i18n="nav.gerencial_checklist"
              >Checklist</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2AL2')"
            (click)="menuClick('/admin/studentCourselist')"
            title="Matrícula"
            data-filter-tags="matrícula turma"
          >
            <i class="fas fa-id-card"></i>
            <span class="nav-link-text" data-i18n="nav.matricula_turma"
              >Matrícula</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0GR26PD47')"
            (click)="menuClick('/admin/courseavailablepositions')"
            title="Relatório de Posições Disponíveis"
            data-filter-tags="relatorio posicoes disponiveis"
          >
            <i class="fal fa-file-alt"></i>
            <span
              class="nav-link-text"
              data-i18n="nav.gerencial_course_available_positions"
              >Posições Disponíveis</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0GR26AC46')"
            (click)="menuClick('/reports/confirmedClasses-analytic')"
            title="Relatório Analítico de Aulas Confirmadas"
            data-filter-tags="relatorio aula confirmada analitico"
          >
            <i class="fal fa-file-alt"></i>
            <span
              class="nav-link-text"
              data-i18n="nav.gerencial_course_confirmed_classes"
              >Aulas Confirmadas<br /><small>(Analítico)</small></span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0GR26AC46')"
            (click)="menuClick('/reports/confirmedClasses-synthetic')"
            title="Relatório Sintético de Aulas Confirmadas"
            data-filter-tags="relatorio aula confirmada sintetico"
          >
            <i class="fal fa-file-alt"></i>
            <span
              class="nav-link-text"
              data-i18n="nav.gerencial_course_confirmed_classes"
              >Aulas Confirmadas<br /><small>(Sintético)</small></span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2AL2')"
            (click)="menuClick('/reports/classroom-in-progress')"
            title="Relatório de Aulas em Andamento"
            data-filter-tags="relatório aulas andamento"
          >
            <i class="fal fa-file-alt"></i>
            <span
              class="nav-link-text"
              data-i18n="nav.gerencial_aulas_andamento"
              >Painel de Aulas<br /><small>(Em andamento)</small></span
            >
            <span class="dl-ref label bg-success-600 text-white ml-2"
              >Novo</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2AL2')"
            (click)="menuClick('/reports/classes-absences')"
            title="Relatório de Faltas por Aluno(a)"
            data-filter-tags="relatório faltas aluno"
          >
            <i class="fal fa-file-alt"></i>
            <span
              class="nav-link-text"
              data-i18n="nav.gerencial_aulas_andamento"
              >Relatório de Faltas<br /><small>(por aluno/turma)</small></span
            >
            <span class="dl-ref label bg-success-600 text-white ml-2"
              >Novo</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AD2PR6')"
            (click)="menuClick('/admin/writingtestappliedlist')"
            title="Writing Test Aplicados"
            data-filter-tags="cadastro writing test aplicados"
          >
            <i class="fas fa-laptop"></i>
            <span
              class="nav-link-text"
              data-i18n="nav.cadastro_writing_test_applied"
              >Writing Test<br /><small>(aplicados pelo teacher)</small></span
            >
            <span class="dl-ref label bg-success-600 text-white ml-2"
              >Novo</span
            >
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-title text-white fw-700">
      <i class="fa fa-dollar"></i> Financeiro
    </li>
    <li class="">
      <a href="javascript:void" title="Cadastros" data-filter-tags="cadastro">
        <i class="fa fa-list-alt"></i>
        <span class="nav-link-text" data-i18n="nav.financeiro.cadastro"
          >Cadastros</span
        >
      </a>
      <ul class="p-0">
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0FI3BC8')"
            (click)="menuClick('/admin/banklist')"
            title="Banco"
            data-filter-tags="financeiro banco"
          >
            <i class="fal fa-bank"></i>
            <span class="nav-link-text" data-i18n="nav.financeiro_banco"
              >Banco</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0FI3CA9')"
            (click)="menuClick('/financial/categories')"
            title="Recibos"
            data-filter-tags="financeiro categoria"
          >
            <i class="fal fa-file-alt"></i>
            <span class="nav-link-text" data-i18n="nav.admin_categoria"
              >Categoria</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0FI3NF10')"
            (click)="menuClick('/admin/invoice')"
            title="Notas Fiscais"
            data-filter-tags="financeiro notas fiscais"
          >
            <i class="fal fa-file-alt"></i>
            <span class="nav-link-text" data-i18n="nav.admin_notas_fiscais"
              >Notas Fiscais</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0FI3RC11')"
            (click)="menuClick('/admin/recipe')"
            title="Recibos"
            data-filter-tags="financeiro recibos"
          >
            <i class="fal fa-file-alt"></i>
            <span class="nav-link-text" data-i18n="nav.admin_recibos"
              >Recibos</span
            >
          </a>
        </li>
      </ul>
    </li>
    <li class="">
      <a href="javascript:void" title="Transações" data-filter-tags="transacao">
        <i class="fa fa-exchange"></i>
        <span class="nav-link-text" data-i18n="nav.financeiro.transacao"
          >Transações</span
        >
      </a>
      <ul class="p-0">
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0FI3CP12')"
            (click)="menuClick('/financial/transactions')"
            title="Contas a Pagar"
            data-filter-tags="financeiro contas pagar"
          >
            <i class="fal fa-money-bill-alt"></i>
            <span class="nav-link-text" data-i18n="nav.financeiro_contas_pagar"
              >Contas a Pagar</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0FI3CR13')"
            (click)="menuClick('/financial/transactions')"
            title="Contas a Receber"
            data-filter-tags="financeiro contas receber"
          >
            <i class="fal fa-money-bill-alt"></i>
            <span
              class="nav-link-text"
              data-i18n="nav.financeiro_contas_receber"
              >Contas a Receber</span
            >
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-title text-white fw-700">
      <i class="fa fa-lightbulb"></i> Marketing
    </li>
    <li class="">
      <a
        href="javascript:void"
        title="Comunicação"
        data-filter-tags="comunicacao"
      >
        <i class="fa fa-bullhorn"></i>
        <span class="nav-link-text" data-i18n="nav.marketing.comunicacao"
          >Comunicação</span
        >
        <span
          class="dl-ref status-sdr hidden-nav-function-minify hidden-nav-function-top"
          >{{ sdr_count }}</span
        >
        <!-- <span class="dl-ref bg-danger-500 hidden-nav-function-minify hidden-nav-function-top">{{awaiting_count}}</span> -->
      </a>
      <ul class="p-0">
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0MK4CS14')"
            (click)="menuClick('/marketing/contactbysitelist')"
            title="Gestão de Interessados"
            data-filter-tags="marketing contato site"
          >
            <i class="fal fa-users"></i>
            <span class="nav-link-text" data-i18n="nav.marketing_contato"
              >CRM</span
            >
            <span
              class="dl-ref status-sdr hidden-nav-function-minify hidden-nav-function-top"
              >{{ sdr_count }}</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0MK4GN15')"
            (click)="menuClick('/marketing/groupnotificationlist')"
            title="Grupos de Notificações"
            data-filter-tags="marketing grupo notificação alerta"
          >
            <i class="fal fa-bullhorn"></i>
            <span
              class="nav-link-text"
              data-i18n="nav.marketing_groupnotification"
              >Grupos de Notificações</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0MK4NT16')"
            (click)="menuClick('/marketing/notificationlist')"
            title="Notificações"
            data-filter-tags="marketing notificação alerta"
          >
            <i class="fal fa-bell"></i>
            <span class="nav-link-text" data-i18n="nav.marketing_notification"
              >Notitificações</span
            >
          </a>
        </li>
      </ul>
    </li>
    <!-- <li class="nav-title text-white fw-700"><i class="fa fa-paper-plane"></i> Planejamento</li>
        <li class="">
            <a href="javascript:void" title="Cadastros" data-filter-tags="cadastro">
                <i class="fa fa-list-alt"></i>
                <span class="nav-link-text" data-i18n="nav.planejamento.cadastro">Cadastros</span>
            </a>
        </li> -->
    <li class="nav-title text-white fw-700">
      <i class="fa fa-shield"></i> Segurança
    </li>
    <li class="">
      <a href="javascript:void" title="Cadastros" data-filter-tags="cadastro">
        <i class="fa fa-list-alt"></i>
        <span class="nav-link-text" data-i18n="nav.seguranca.cadastro"
          >Cadastros</span
        >
      </a>
      <ul class="p-0">
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0SG5US17')"
            (click)="menuClick('/admin/userlist')"
            title="Usuário"
            data-filter-tags="seguranca usuario"
          >
            <i class="fal fa-user"></i>
            <span class="nav-link-text" data-i18n="nav.seguranca_usuario"
              >Usuário</span
            >
          </a>
        </li>
      </ul>
    </li>
    <li class="">
      <a href="javascript:void" title="Auditoria" data-filter-tags="auditoria">
        <i class="fal fa-user-secret"></i>
        <span class="nav-link-text" data-i18n="nav.auditoria">Auditoria</span>
      </a>
      <ul class="p-0">
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AU21CA48')"
            (click)="menuClick('/audit/ContentAuditList')"
            title="Auditoria de Conteúdo"
            data-filter-tags="auditoria conteudo"
          >
            <i class="fal fa-video"></i>
            <span class="nav-link-text" data-i18n="nav.auditoria.conteudo"
              >Auditoria de Conteúdo</span
            >
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            *ngIf="checkHavePermission('ADM0AU21CA48')"
            (click)="menuClick('/audit/UserAuditList')"
            title="Auditoria de Acesso"
            data-filter-tags="auditoria acesso"
          >
            <i class="fal fa-eye"></i>
            <span class="nav-link-text" data-i18n="nav.auditoria.acesso"
              >Logs de Acesso</span
            >
          </a>
        </li>
      </ul>
    </li>
    <!-- <li class="nav-title text-white fw-700"><i class="fa fa-wrench"></i> Configurações</li>
        <li class="disabled">
            <a href="javascript:void" title="configuracoes" data-filter-tags="configuracoes">
                <i class="fa fa-wrench"></i>
                <span class="nav-l
                ink-text" data-i18n="nav.configuracoes.configuracoes">Configurações</span>
            </a>
        </li> -->
  </ul>
  <div class="filter-message js-filter-message bg-success-600"></div>
</nav>
<!-- END PRIMARY NAVIGATION -->
