import { Base } from './../../shared/model/base';
import { State, City } from '.';

export class Supplier extends Base {
    type: number;
    socialName: string;
    nickName: string;

    birthDate: Date;
    email: string;
    phone: string;
    cpf: string;
    zipCode: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;

    city: City;
    cityId: number;
    
    state: State;
    stateId: number;
    
    constructor(contentResponse: any) {
        super(contentResponse);

        this.type = contentResponse.type;
        this.socialName = contentResponse.socialName;
        this.nickName = contentResponse.nickName;
        this.birthDate = new Date(contentResponse.birthDate),
        this.email = contentResponse.email;
        this.phone = contentResponse.phone;
        this.cpf = contentResponse.cpf;
        this.zipCode = contentResponse.zipCode;
        this.street = contentResponse.street;
        this.number = contentResponse.number;
        this.complement = contentResponse.complement;
        this.neighborhood = contentResponse.neighborhood;
        
        this.cityId = parseInt(contentResponse.cityId);
        if (contentResponse && contentResponse.cityId > 0) {
            this.city = contentResponse.city;
        }

        this.stateId = parseInt(contentResponse.stateId);
        if (contentResponse && contentResponse.stateId > 0) {
            this.state = contentResponse.state;
        }
    }
}
