<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item">
    <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
  </li>
  <li class="breadcrumb-item">
    <a href="javascript:void(0);" [routerLink]="['/admin/studentlist']"
      >Alunos</a
    >
  </li>
  <li class="breadcrumb-item active">Novo aluno</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol>
<div id="panel-2" class="panel">
  <div class="panel-hdr bg-primary-600 bg-primary-gradient">
    <h2>
      Novo <span class="fw-300"><i>Aluno</i></span>
    </h2>
    <div class="panel-toolbar">
      <a
        href="javascript:void(0);"
        class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Collapse"
        title="Minimizar painel"
      >
        <i class="fal fa-minus text-white fw-900"></i>
      </a>
      <a
        href="javascript:void(0);"
        class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Fullscreen"
        title="Maximizar painel"
      >
        <i class="fal fa-expand text-primary fw-900"></i>
      </a>
      <a
        href="javascript:void(0);"
        class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Close"
        title="Fechar painel"
      >
        <i class="fal fa-times text-white fw-900"></i>
      </a>
    </div>
  </div>
  <div class="panel-container show">
    <div class="panel-content p-0">
      <form
        [formGroup]="studentForm"
        novalidate
        class="needs-validation"
        (ngSubmit)="onSubmit()"
      >
        <div class="panel-content">
          <div class="form-row">
            <div class="col-md-12">
              <aw-wizard
                [navBarLayout]="'large-filled-symbols'"
                [awNavigationMode]
                navigateBackward="allow"
                [defaultStepIndex]="0"
                navigateForward="allow"
                class="m-l-15 m-r-15"
              >
                <aw-wizard-step
                  id="basicData"
                  stepTitle="Dados básicos"
                  (click)="({ stepIndex: 0 })"
                  [stepId]="1"
                  [navigationSymbol]="{ fontFamily: 'FontAwesome' }"
                >
                  <hr />
                  <div class="form-group row">
                    <input type="hidden" id="id" formControlName="id" />
                    <input
                      type="hidden"
                      id="createdOn"
                      formControlName="createdOn"
                      *ngIf="!editMode"
                    />
                    <input
                      type="hidden"
                      id="updatedOn"
                      formControlName="updatedOn"
                      *ngIf="!editMode"
                    />
                    <div class="col-lg-9 col-md-4 col-sm-12 col-xs-12">
                      <label class="form-label" for="name"
                        >Nome <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        formControlName="name"
                        class="form-control"
                        placeholder="Nome do aluno"
                        maxlength="50"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.name?.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validStudent && f?.name?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.name?.errors.required">
                          Por favor, informe o nome do aluno.
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-8 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="form-label">.</label>
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="foreignPerson"
                            formControlName="foreignPerson"
                            checked=""
                            (change)="onForeignPerson($event)"
                          />
                          <label
                            class="custom-control-label"
                            for="foreignPerson"
                            >Estrangeiro?</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-3">
                      <label class="form-label" for="email"
                        >E-mail <span class="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        formControlName="email"
                        class="form-control"
                        placeholder="exemplo@email.com.br"
                        maxlength="100"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.email?.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validStudent && f?.email?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.email?.errors.required">
                          Por favor, informe o e-mail do aluno.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="form-label" for="phone"
                        >Telefone <span class="text-danger">*</span>
                      </label>
                      <input
                        type="phone"
                        id="phone"
                        name="phone"
                        formControlName="phone"
                        class="form-control"
                        maxlength="17"
                        (change)="setPhone($event)"
                        placeholder="(00) 00000-0000"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.phone?.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validStudent && f?.phone?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.phone?.errors.required">
                          Por favor, informe o telefone do aluno.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="form-label" for="cpf"
                        >CPF <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="cpf"
                        formControlName="cpf"
                        class="form-control"
                        placeholder="CPF"
                        maxlength="15"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.cpf?.errors
                        }"
                        (focusout)="checkStudentCpf($event)"
                        required
                      />
                      <div
                        *ngIf="!validStudent && f?.cpf?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.cpf?.errors.required">
                          Por favor, informe o CPF do aluno.
                        </div>
                        <div *ngIf="f?.cpf?.errors.incorrect">
                          O CPF informado é inválido!
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="form-label" for="birthDate"
                        >Data de nascimento <span class="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        id="birthDate"
                        formControlName="birthDate"
                        class="form-control"
                        placeholder="Data de nascimento"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.birthDate?.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validStudent && f?.birthDate?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.birthDate?.errors.required">
                          Por favor, informe a data de nascimento.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label class="form-label" for="studentLevel"
                        >Nível do Aluno <span class="text-danger">*</span>
                      </label>
                      <br />
                      <select
                        class="form-control"
                        id="studentLevel"
                        placeholder="Nível do aluno"
                        (change)="setStudentLevel($event?.target?.value)"
                        [(ngModel)]="studentLevelId"
                      >
                        <option
                          *ngFor="let item of studentLevelDataList"
                          [value]="item.id"
                          [selected]="item.id == studentLevelId"
                        >
                          {{ item.text }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="flagForeignPerson">
                    <div class="col-md-12">
                      <label class="form-label" for="street"
                        >Endereço completo <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="street"
                        formControlName="street"
                        class="form-control"
                        placeholder="Endereço completo"
                        maxlength="100"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.street?.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validStudent && f?.street?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.street?.errors.required">
                          Por favor, informe o endereço completo.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0" *ngIf="!flagForeignPerson">
                    <div class="row">
                      <div class="col-sm-12 text-right">
                        <div
                          id="btnButtonWizard"
                          class="btn-group btn-group-sm btn-group-toggle text-right"
                          data-toggle="buttons"
                        >
                          <button
                            class="btn btn-info"
                            data-action="toggle"
                            data-class="nav-tabs-clean"
                            disabled="true"
                            awPreviousStep
                          >
                            Anterior
                          </button>
                          <button
                            class="btn btn-info active"
                            data-action="toggle"
                            data-class="nav-tabs-clean"
                            awNextStep
                          >
                            Próximo
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </aw-wizard-step>

                <aw-wizard-step
                  id="addressData"
                  stepTitle="Endereço"
                  (click)="({ stepIndex: 1 })"
                  [stepId]="2"
                  [navigationSymbol]="{ fontFamily: 'FontAwesome' }"
                  *ngIf="!flagForeignPerson"
                >
                  <hr />
                  <div class="form-group row">
                    <div class="col-md-3">
                      <label class="form-label" for="zipCode"
                        >CEP <span class="text-danger">*</span>
                      </label>
                      <div class="input-group">
                        <input
                          #zipCode
                          type="text"
                          id="zipCode"
                          formControlName="zipCode"
                          class="form-control"
                          placeholder="CEP"
                          aria-label="CEP"
                          (change)="setZipCode($event)"
                          (onfocusout)="setZipCode($event)"
                          maxlength="8"
                          [ngClass]="{
                            'is-invalid': !validStudent && f?.zipCode?.errors
                          }"
                          required
                          aria-describedby="btnFindzipCode"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-primary waves-effect waves-themed"
                            type="button"
                            id="btnFindzipCode"
                            (click)="loadAddressByCep(zipCode)"
                          >
                            <i class="fal fa-search"></i>
                          </button>
                        </div>
                      </div>
                      <div
                        *ngIf="!validStudent && f?.zipCode?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.zipCode?.errors.required">
                          Por favor, informe o CEP.
                        </div>
                        <div *ngIf="f?.zipCode?.errors.invalidNumber">
                          CEP inválido. Por favor, digite outro CEP.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label class="form-label" for="street"
                        >Endereço <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="street"
                        formControlName="street"
                        class="form-control"
                        placeholder="Endereço"
                        maxlength="100"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.street?.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validStudent && f?.street?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.street?.errors.required">
                          Por favor, informe o endereço.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="form-label" for="number"
                        >Número <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="number"
                        formControlName="number"
                        class="form-control"
                        placeholder="Número"
                        maxlength="10"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.number?.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validStudent && f?.number?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.number?.errors.required">
                          Por favor, informe o número.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="form-label" for="complement"
                        >Complemento
                      </label>
                      <input
                        type="text"
                        id="complement"
                        formControlName="complement"
                        class="form-control"
                        placeholder="Complemento"
                        maxlength="30"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.complement?.errors
                        }"
                      />
                      <div
                        *ngIf="!validStudent && f?.complement?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.complement?.errors.required">
                          Por favor, informe o complemento.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label class="form-label" for="neighborhood"
                        >Bairro <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="neighborhood"
                        formControlName="neighborhood"
                        class="form-control"
                        placeholder="Bairro"
                        maxlength="100"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.neighborhood?.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validStudent && f?.neighborhood?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.neighborhood?.errors.required">
                          Por favor, informe o bairro.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label class="form-label" for="stateId"
                        >Estado <span class="text-danger">*</span>
                      </label>
                      <select
                        class="form-control"
                        id="stateId"
                        for="stateId"
                        formControlName="stateId"
                        placeholder="Estado"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.stateId?.errors
                        }"
                        (change)="getCitiesByStateId(f?.stateId?.value)"
                      >
                        <option
                          *ngFor="let state of stateList$ | async"
                          [ngValue]="state?.id"
                        >
                          {{ state?.uf }}
                        </option>
                      </select>
                      <div
                        *ngIf="!validStudent && f?.state?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.stateId?.errors.required">
                          Por favor, informe o estado.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label" for="cityId"
                        >Cidade <span class="text-danger">*</span>
                      </label>
                      <select
                        class="form-control"
                        id="cityId"
                        for="cityId"
                        formControlName="cityId"
                        placeholder="Cidade"
                        [ngClass]="{
                          'is-invalid': !validStudent && f?.cityId?.errors
                        }"
                      >
                        <option
                          *ngFor="let ct of citiesList$ | async"
                          [ngValue]="ct?.id"
                        >
                          {{ ct?.name }}
                        </option>
                      </select>
                      <div
                        *ngIf="!validStudent && f?.cityId?.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f?.cityId?.errors.required">
                          Por favor, informe a cidade.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="row">
                      <div class="col-sm-12 text-right">
                        <div
                          id="btnButtonWizard"
                          class="btn-group btn-group-sm btn-group-toggle text-right"
                          data-toggle="buttons"
                        >
                          <button
                            class="btn btn-info"
                            data-action="toggle"
                            data-class="nav-tabs-clean"
                            awPreviousStep
                          >
                            Anterior
                          </button>
                          <button
                            class="btn btn-info active"
                            data-action="toggle"
                            data-class="nav-tabs-clean"
                            disabled="true"
                            awNextStep
                          >
                            Próximo
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </aw-wizard-step>
              </aw-wizard>
            </div>
          </div>
          <div class="form-row" *ngIf="!validStudent">
            <hr />
            <div class="col-md-12">
              <div
                class="alert alert-warning alert-dismissible text-left fade show mt-3"
                role="alert"
                *ngIf="!validStudent"
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
                {{ errorMessage }}
              </div>
              <br />
              <div
                *ngIf="listErrorMessage && !validStudent"
                class="invalid-feedback"
              >
                <div *ngFor="let error of listErrorMessage">{{ error }}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <footer-form
      *ngIf="
        (editMode == false && idStudent == null) ||
        (editMode == true && this.idStudent > 0)
      "
      [auxSaveAction]="true"
      [auxCancelAction]="true"
      (saveClick)="onSubmit()"
      (cancelClick)="onCancel()"
    ></footer-form>

    <div
      class="panel-content d-flex rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted m-3"
      *ngIf="editMode == false && this.idStudent > 0"
    >
      <div class="btn-group btn-panel">
        <button type="button" class="btn btn-default" (click)="onCancel()">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>
