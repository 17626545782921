import { Teacher } from "./teacher";
import { TeacherWithAvailabilityItem } from "./teacherWithAvailabilityItem";

export class TeacherWithAvailability {
    teacherId: number;
    teacher: Teacher;

    teacherAvailabilityItems: Array<TeacherWithAvailabilityItem>;

    constructor(contentResponse: any) {
        if (contentResponse) {
            this.teacherId = parseInt(contentResponse.teacherId);
            if (contentResponse && contentResponse.teacherId > 0) {
                this.teacher = contentResponse.teacher;
            }
            
            if(contentResponse.teacherAvailabilityItems && contentResponse.teacherAvailabilityItems.length > 0) {
                this.teacherAvailabilityItems = contentResponse.teacherAvailabilityItems;
            }
        } else {
            this.teacher = new Teacher(null);
            this.teacherId = 0;
            this.teacherAvailabilityItems = [];
        }
    }
}
