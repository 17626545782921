<div class="modal-header">
    <h3 class="modal-title">Testes de escrita <span class="fw-300"><i>Aplicados</i></span></h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
</div>
<div class="modal-body">
    <div id="panel-2" class="panel" *ngIf="!showAppliedReview">
        <div class="panel-hdr">
            <h2>
                Testes de escrita <span class="fw-300"><i>Aplicados</i></span>
            </h2>
        </div>
        <div class="panel-container show">
            <div class="panel-content">
                <h5 class="frame-heading text-primary fw-700">
                    Teste de escrita: <i>{{ writingTestName }}</i>
                </h5>
                <h5 class="frame-heading text-primary fw-700">
                    Professor: <i>{{teacherAppliedName}}</i>
                </h5>
                <div class="row">
                    <div class="col-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-12" *ngIf="showTable">
                        <div class="accordion accordion-outline" id="js_demo_accordion-3">
                            <div class="card" *ngFor="let wt of writingTestAppliedList; let i = index">
                                <div class="card-header">
                                    <a href="javascript:void(0);" class="card-title" [ngClass]="{ 'collapsed' : i == 0 }" data-toggle="collapse" data-target="{{getCardName(wt, index)}}" aria-expanded="true">
                                        <i class="fal fa-file-medical-alt width-2 fs-xl"></i> Informações do teste
                                        <span class="ml-auto">
                                            <span class="collapsed-reveal">
                                                <i class="fal fa-minus fs-xl"></i>
                                            </span>
                                            <span class="collapsed-hidden">
                                                <i class="fal fa-plus fs-xl"></i>
                                            </span>
                                        </span>
                                    </a>
                                </div>
                                <div class="collapse show" data-parent="#js_demo_accordion-3">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <h4>Dados da Turma</h4>
                                            </div>
                                            <div class="col-6">
                                                <p class="card-text mb-1 mt-2">Turma:</p>
                                                <span class="fw-700">{{wt.course.name}}</span>
                                            </div>
                                            <div class="col-6">
                                                <p class="card-text mb-1 mt-2">Pacote:</p>
                                                <span class="fw-700">{{wt.course.product.name}}</span>
                                            </div>
                                            <div class="col-6">
                                                <p class="card-text mb-1 mt-2">Aluno:</p>
                                                <span class="fw-700">{{wt.student.name}}</span>
                                            </div>
                                            <div class="col-6">
                                                <p class="card-text mb-1 mt-2">Professor:</p>
                                                <span class="fw-700">{{wt.teacherApplied.name}}</span>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-12">
                                                <h4>Dados da Aula</h4>
                                            </div>
                                            <div class="col-4">
                                                <p class="card-text mb-1 mt-2">Aula:</p>
                                                <span class="fw-700">{{wt.studentClassesCourse.numberClass}}</span>
                                            </div>
                                            <div class="col-4">
                                                <p class="card-text mb-1 mt-2">Data prevista:</p>
                                                <span class="fw-700" *ngIf="wt.studentClassesCourse.expectedDate">{{wt.studentClassesCourse.expectedDate | date:'dd/MM/yyyy'}}</span>
                                                <span class='badge badge-pill badge-secondary w-100' *ngIf="!wt.studentClassesCourse.expectedDate">Não realizada</span>
                                            </div>
                                            <div class="col-4">
                                                <p class="card-text mb-1 mt-2">Aula realizada:</p>
                                                <span class="fw-700" *ngIf="wt.studentClassesCourse.confirmedDate">{{wt.studentClassesCourse.confirmedDate | date:'dd/MM/yyyy'}}</span>
                                                <span class='badge badge-pill badge-secondary w-100' *ngIf="!wt.studentClassesCourse.confirmedDate">Não realizada</span>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-12">
                                                <h4>Dados do Teste</h4>
                                            </div>
                                            <div class="col-12">
                                                <p class="card-text mb-1 mt-2">Ordem/Teste:</p>
                                                <span class="fw-700">{{wt.writingTest.order + " / " + wt.writingTest.name}}</span>
                                            </div>
                                            <div class="col-4">
                                                <p class="card-text mb-1 mt-2">Data de disponibilização:</p>
                                                <span class="fw-700" *ngIf="wt.availabilityDate">{{wt.availabilityDate | date:'dd/MM/yyyy'}}</span>
                                                <span class='badge badge-pill badge-secondary w-100' *ngIf="!wt.availabilityDate">Não disponibilizado</span>
                                            </div>
                                            <div class="col-4">
                                                <p class="card-text mb-1 mt-2">Data de realização:</p>
                                                <span class="fw-700" *ngIf="wt.finishDate">{{wt.finishDate | date:'dd/MM/yyyy'}}</span>
                                                <span class='badge badge-pill badge-secondary w-100' *ngIf="!wt.finishDate">Não realizado</span>
                                            </div>
                                            <div class="col-4">
                                                <p class="card-text mb-1 mt-2">
                                                    Status do teste:
                                                </p>
                                                <div [innerHTML]="wt.designWritingTestStatus"></div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-12">
                                                <h4 class="text-primary fw-700">Revisão do professor</h4>
                                            </div>
                                            <div class="col-3">
                                                <p class="card-text mt-2 mb-1">Status da revisão:</p>
                                                <div [innerHTML]="wt.designWritingTestStatus"></div>
                                            </div>
                                            <div class="col-3">
                                                <p class="card-text mb-1 mt-2">Data de revisão:</p>
                                                <span class="fw-700" *ngIf="wt.reviewDate">{{wt.reviewDate | date:'dd/MM/yyyy'}}</span>
                                                <span class='badge badge-pill badge-secondary w-100' *ngIf="!wt.reviewDate">Não revisado</span>
                                            </div>
                                            <div class="col-3">
                                                <p class="card-text mb-1 mt-2">Resultado da avaliação:</p>
                                                <div [innerHTML]="wt.designAvgScore"></div>
                                            </div>
                                            <div class="col-3">
                                                <p class="card-text mb-1 mt-2">Score:</p>
                                                <span class="fw-700">{{wt.scoreResult}}</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <button type="button" class="btn btn-info waves-effect waves-themed" data-dismiss="modal" (click)="writingTestAppliedReview(wt, mdWritingTestAppliedReview)" [disabled]="!wt.writingTestReviewShow" *ngIf="!writingTestApplyPreviewMode">
                                                        <i class="fa fa-file-check mr-2"></i> Avaliar
                                                    </button>
                                                    <button type="button" class="btn btn-info waves-effect waves-themed" data-dismiss="modal" (click)="writingTestAppliedReview(wt, mdWritingTestAppliedReview)" [disabled]="!wt.writingTestReviewShow" *ngIf="writingTestApplyPreviewMode">
                                                        <i class="fa fa-file-search mr-2"></i> Visualizar revisão
                                                    </button>
                                                    <!-- <button class="btn btn-primary me-md-2" type="button">Button</button>
                                                    <button class="btn btn-primary" type="button">Button</button> -->
                                                  </div>
                                                <!-- <div class="btn-group btn-panel text-right"></div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Fechar</button>
</div>

<ng-template #mdWritingTestAppliedReview let-c="close" let-d="dismiss">
    <writing-test-applied-review writingTestApplyId="{{targetWritingTestId}}" writingTestApplyPreviewMode="{{writingTestApplyPreviewMode}}"></writing-test-applied-review>
</ng-template>
