// Angular Components
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
//import { ListViewModule } from '@syncfusion/ej2-angular-lists';
//import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
// import { AngularBootstrapCheckboxGroupModule } from 'angular-bootstrap-checkbox-group';
//import { NgxDropzoneModule } from 'ngx-dropzone';
//import { NgSelect2Module } from 'ng-select2';
// Imported Syncfusion RichTextEditorModule from Rich Text Editor package
//import { RichTextEditor, RichTextEditorAllModule, RichTextEditorComponent, RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';

// Modules
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { FinancialModule } from './../financial/financial.module';

// Components
import { TeacherCalendarComponent } from './components/teacherCalendar/teacherCalendar.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { StudentListComponent } from './components/student/student-list/student-list.component';
import { StudentComponent } from './components/student/student/student.component';
import { BankListComponent } from './components/bank/bank-list/bank-list.component';
import { BankComponent } from './components/bank/bank/bank.component';
import { CourseListComponent } from './components/course/course-list/course-list.component';
import { CourseComponent } from './components/course/course/course.component';
import { DepartmentListComponent } from './components/department/department-list/department-list.component';
import { DepartmentComponent } from './components/department/department/department.component';
import { TeacherListComponent } from './components/teacher/teacher-list/teacher-list.component';
import { TeacherComponent } from './components/teacher/teacher/teacher.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { UserComponent } from './components/user/user/user.component';
import { InvoiceListComponent } from './components/invoice/invoice-list/invoice-list.component';
import { InvoiceComponent } from './components/invoice/invoice/invoice.component';
import { ProductComponent } from './components/product/product/product.component';
import { ProductListComponent } from './components/product/product-list/product-list.component';
import { RecipeListComponent } from './components/recipe/recipe-list/recipe-list.component';
import { RecipeComponent } from './components/recipe/recipe/recipe.component';
import { GradesComponent } from './components/grades/grades/grades.component';
import { GradesListComponent } from './components/grades/grades-list/grades-list.component';
import { SupplierComponent } from './components/supplier/supplier/supplier.component';
import { SupplierListComponent } from './components/supplier/supplier-list/supplier-list.component';
import { StudentCourseListComponent } from './components/studentCourse/studentcourse-list/studentcourse-list.component';
import { StudentCourseComponent } from './components/studentCourse/studentcourse/studentcourse.component';
import { UserSelectComponent } from './components/userSelect/userSelect.component';
import { CheckListListComponent } from './components/checkList/checkList-list/checkList-list.component';
import { CheckListComponent } from './components/checkList/checkList/checkList.component';
import { StudentCourseRelocationAddComponent } from './components/StudentCourseRelocation/studentCourseRelocation-add/studentCourseRelocation-add.component';
import { CourseSelectComponent } from './components/course/course-select/course-select.component';
import { StudentCourseInfoComponent } from './components/studentCourse/studentcourse-info/studentcourse-info.component';
import { CourseAvailablePositionsComponent } from './components/course/course-available-positions/course-available-positions.component';
import { StudentClassesCourseListComponent } from './components/studentClassesCourse/studentclassescourse-list/studentclassescourse-list.component';
import { StudentClassesCourseAuditingChangesComponent } from './components/studentClassesCourse/studentclassescourse-auditing-changes/studentclassescourse-auditing-changes.component';
import { StudentclassescourseTeacherChangesComponent } from './components/studentClassesCourse/studentclassescourse-teacher-changes/studentclassescourse-teacher-changes.component';
import { TeacherAvailabilityComponent } from './components/teacher/teacher-availability/teacher-availability.component';
import { WritingTestListComponent } from './components/writing-test/writing-test-list/writing-test-list.component';
import { WritingTestComponent } from './components/writing-test/writing-test/writing-test.component';

// Helpers
import { TextMaskModule } from '../_helpers/mask/text.mask';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { NgxSummernoteModule } from 'ngx-summernote';
import { SafePipe } from '@app/_helpers/safe/safe.pipe';
import { ContentModule } from '@app/content/content.module';
import { JitsiService } from '@app/content/services';
import { NgxPaginationModule } from 'ngx-pagination';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { StudentCalendarComponent } from './components/studentCalendar/studentCalendar.component';
import { WritingTestAppliedListComponent } from './components/writing-test/writing-test-applied-list/writing-test-applied-list.component';

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};

@NgModule({
  declarations: [
    HomeComponent,
    ProfileComponent,
    StudentComponent,
    StudentListComponent,
    BankListComponent,
    BankComponent,
    CheckListComponent,
    CheckListListComponent,
    CourseListComponent,
    CourseComponent,
    DepartmentListComponent,
    DepartmentComponent,
    TeacherListComponent,
    TeacherComponent,
    UserListComponent,
    UserComponent,
    GradesComponent,
    GradesListComponent,
    InvoiceListComponent,
    InvoiceComponent,
    ProductComponent,
    ProductListComponent,
    RecipeListComponent,
    RecipeComponent,
    SupplierComponent,
    SupplierListComponent,
    StudentCourseListComponent,
    StudentCourseComponent,
    TeacherCalendarComponent,
    StudentCalendarComponent,
    UserSelectComponent,
    CourseSelectComponent,
    StudentCourseInfoComponent,
    CourseAvailablePositionsComponent,
    StudentClassesCourseListComponent,
    StudentClassesCourseAuditingChangesComponent,
    StudentclassescourseTeacherChangesComponent,
    WritingTestListComponent,
    WritingTestComponent,
    StudentCourseRelocationAddComponent,
    TeacherAvailabilityComponent,
    WritingTestAppliedListComponent,
  ],
  imports: [
    ChartsModule,
    TextMaskModule,
    ArchwizardModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    FinancialModule,
    HttpClientModule,
    AdminRoutingModule,
    NgxPaginationModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule.forRoot(),
    //ListViewModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxSummernoteModule,
    ContentModule,
    FullCalendarModule, // for FullCalendar!
  ],
  exports: [StudentclassescourseTeacherChangesComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe, SafePipe, JitsiService],
})
export class AdminModule {
  stepDefault: any = 0;

  constructor() {}
}
