import { Component, Input, OnInit } from '@angular/core';
import { TeacherWithAvailability } from '@app/admin/model';
import { TeacherService } from '@app/admin/services';
import { RecurrenceDay, RecurrenceDayLabel, TypeResponse } from '@app/shared/enum';
import { MessageService } from '@app/shared/services';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { Select2OptionData } from 'ng-select2';
import { BehaviorSubject } from 'rxjs';
import { default as swal } from 'sweetalert2'
@Component({
  selector: 'teacher-availability',
  templateUrl: './teacher-availability.component.html',
  styleUrls: ['./teacher-availability.component.css']
})
export class TeacherAvailabilityComponent implements OnInit {
  @Input("teacherId") teacherId: number;

  public daysOfWeekDataList: Array<any>;
  public daysOfWeekDataList$ = new BehaviorSubject([]);
  public daysOfWeekList: Array<any> = [];
  public hoursOfDay: Array<any> = [];
  //public teacherAvailability: Array<any> = [];
  //public teacherAvailabilityArray: TeacherAvailability;
  private teacherWithAvailability: TeacherWithAvailability;

  public validAvailability: boolean = true;
  public listErrorMessage: Array<any> = [];
  public errorMessage: string;

  public submitted = false;

  constructor(private modalService: NgbModal,
    private teacherService: TeacherService,
    private loading: MessageService) {
    if (!(this.teacherId > 0)) {
      this.cancel();
    }
  }

  ngOnInit(): void {
    this.getDaysOfWeek();
    this.getHoursOfDay();
    //this.getTeacherAvailability();
    this.loadTeacherAvailability(this.teacherId);
  }

  loadTeacherAvailability(teacherId: any) {
    this.loading.showLoading();
    this.teacherService.getAvailability(teacherId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.teacherWithAvailability = response.data;
      } else {
        this.teacherWithAvailability = null;
      }
      this.daysOfWeekDataList$.next(this.daysOfWeekDataList);
      this.loading.hideLoading();
    }, (error: any) => {
      this.loading.hideLoading();
    });
  }

  // getTeacherAvailability() {
  //   for (let i = 0; i < this.daysOfWeekList.length; i++) {
  //     const rndIntList = this.randomIntFromInterval(0, 24);
  //     this.teacherAvailability.push({
  //       dayOfWeek: i,
  //       hours: rndIntList
  //     })
  //   }
  //   this.daysOfWeekDataList$.next(this.daysOfWeekDataList);
  // }

  setTeacherAvailability(dayOfWeek: number, hour: any) {
    if (hour && dayOfWeek >= 0) {
      let item = this.teacherWithAvailability.teacherAvailabilityItems.find(x => x.dayOfWeek == dayOfWeek);
      if (item) {
        const index = item.hours.indexOf(hour, 0);
        if (index > -1) {
          item.hours.splice(index, 1);
        } else {
          item.hours.push(hour);
        }
      } else {
        let newItem = {
          dayOfWeek: dayOfWeek,
          hours: []
        };
        newItem.hours.push(hour);
        this.teacherWithAvailability.teacherAvailabilityItems.push(newItem);
      }
    }
    this.daysOfWeekDataList$.next(this.daysOfWeekDataList);
  }

  checkAvailability(dayOfWeek: number, hour: any) {
    let item = this.teacherWithAvailability.teacherAvailabilityItems.find(x => x.dayOfWeek === dayOfWeek);
    return item?.hours?.some(y => y === hour);
  }

  randomIntFromInterval(min: number, max: number): Array<number> { // min and max included 
    let hours: Array<any> = [];
    let grade = Math.floor(Math.random() * (12 - min + 1) + min);
    for (let i = 0; i < grade; i++) {
      let hour = Math.floor(Math.random() * (max - min + 1) + min);
      let hours_str = hour < 10 ? "0"+hour : hour;
      hours.push(hours_str+':00');
    }
    return hours;
  }

  getDaysOfWeek() {
    const daysOfWeekList: Array<object> = [];
    for (var value in RecurrenceDay) {
      if (Number.isInteger(Number.parseInt(value))) {
        daysOfWeekList.push({
          value: Number.parseInt(value), 
          text: RecurrenceDayLabel.get(Number.parseInt(value))
        });
      }
    }
    this.daysOfWeekDataList = this.getTemplateDaysOfWeekList(daysOfWeekList);
  }

  getTemplateDaysOfWeekList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.daysOfWeekList = data;
      let arrayDayOfWeekList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].value,
          text: data[i].text
        };
        arrayDayOfWeekList.push(element);
      }
      return arrayDayOfWeekList;
    }
    return new Array<any>();
  }

  getHoursOfDay() {
    // Loop from current hour number to 23
    for(var i = 0; i < 24; i++) {
      var hour = this.toHHMMSS(i*60*60);
      var hour_split = hour.split(':');
      // Put loop counter into array with "00" next to it
      this.hoursOfDay.push(hour);
      this.hoursOfDay.push(hour_split[0] + ':30');
    }
  }

  toHHMMSS(number: any) {
    var sec_num = parseInt(number, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    //var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    //var seconds = sec_num - (hours * 3600) - (minutes * 60);
    let hours_str = hours < 10 ? "0"+hours : hours;
    //let minutes_str = hours < 10 ? "0"+minutes : minutes+"0";
    return hours_str+':00'///+minutes_str;
  }

  done() {
    swal({
      title: "Atualização de disponibilidade",
      html: 'Confirma a atualização de disponibilidade do(a) professor(a)?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, confirmar'
    }).then(confirmed => {
      if (confirmed.value === true) {
        this.loading.showLoading();
        this.submitted = true;
        this.validAvailability = true;
        
        // Errors Cleaning
        this.errorMessage = '';
    
        // stop here if form is invalid
        if (!this.validAvailability) {
          this.loading.hideLoading();
          this.submitted = false;
          this.errorMessage = 'Disponibilidade informada é inválida.';
          return;
        }
    
        this.teacherWithAvailability = this.teacherWithAvailability.teacherAvailabilityItems !== null 
          ? new TeacherWithAvailability({
            teacherId: this.teacherId,
            teacherAvailabilityItems: this.teacherWithAvailability.teacherAvailabilityItems
          })
          : new TeacherWithAvailability(null);
    
        this.teacherService.setAvailability(this.teacherId, this.teacherWithAvailability)
        .subscribe((response: any) => {
          this.loading.hideLoading();
          if (response.typeResponse == TypeResponse.Success) {
            this.afterSuccess();
          } else {
            this.afterFailed();
          }
        }, (error: any) => {
          this.loading.hideLoading();
          this.afterFailed();
        });
      } else {
        this.cancel();
      }
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();
  
      console.log(reason);
  
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }

  cancel() {
    this.dismissModal();
  }

  cleanAvailability() {
    if (this.teacherWithAvailability.teacherAvailabilityItems) {
      this.teacherWithAvailability.teacherAvailabilityItems = [];
    }
    this.daysOfWeekDataList$.next(this.daysOfWeekDataList);
  }

  afterSuccess() {
    swal({
      type: 'success',
      title: `Disponibilidade atualizada com sucesso!`,
      showConfirmButton: true
    }).then((result) => {
      this.loading.hideLoading();
      this.cancel();
    });
  }

  afterFailed() {
    swal({
      type: 'warning',
      title: `Não foi possível atualizar a disponibilidade!`,
      showConfirmButton: true,
    }).then((result) => {
      this.loading.hideLoading();
      this.cancel();
    });
  }
}
