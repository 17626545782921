<div class="d-flex align-items-center flex-1 text-muted">
    <span class="hidden-md-down fw-700">
        2020 © English Coffee by&nbsp;<a href='http://www.englishcoffee.com.br' class='text-white opacity-40 fw-300' title='englishcoffee.com.br' target='_blank'>englishcoffee.com.br</a>
    </span>
    <br>
    <app-managed-table></app-managed-table>
</div>
<!-- <div>
    <ul class="list-table m-0">
        <li><a href="intel_introduction.html" class="text-secondary fw-700">About</a></li>
        <li class="pl-3"><a href="info_app_licensing.html" class="text-secondary fw-700">License</a></li>
        <li class="pl-3"><a href="info_app_docs.html" class="text-secondary fw-700">Documentation</a></li>
        <li class="pl-3 fs-xl"><a href="https://wrapbootstrap.com/user/MyOrange" class="text-secondary" target="_blank"><i class="fal fa-question-circle" aria-hidden="true"></i></a></li>
    </ul>
</div> -->
