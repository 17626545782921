// Angular Components
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { NgxSummernoteModule } from 'ngx-summernote';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
//import { ListViewModule } from '@syncfusion/ej2-angular-lists';
//import { NgxDropzoneModule } from 'ngx-dropzone';
//import { NgSelect2Module } from 'ng-select2';
import {RouterModule} from '@angular/router';

// Modules

// Components
import { StudentclassescourseConfirmedClassesAnalyticComponent } from './components/confirmed-classes/studentclassescourse-confirmed-classes-analytic/studentclassescourse-confirmed-classes-analytic.component';
import { StudentclassescourseConfirmedClassesSyntheticComponent } from './components/confirmed-classes/studentclassescourse-confirmed-classes-synthetic/studentclassescourse-confirmed-classes-synthetic.component';

// Helpers
import { TextMaskModule } from '../_helpers/mask/text.mask';
import { SafePipe } from '@app/_helpers/safe/safe.pipe';
import { SharedModule } from '@app/shared/shared.module';
import { ReportsRoutingRoutes } from './reports-routing.module';
import { ClassroomInProgressComponent } from './components/classroom-in-progress/classroom-in-progress.component';
import { ClassesAbsencesReportComponent } from './components/classes-absences/classes-absences-report.component';

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
    ClassroomInProgressComponent,
    ClassesAbsencesReportComponent,
    StudentclassescourseConfirmedClassesAnalyticComponent,
    StudentclassescourseConfirmedClassesSyntheticComponent
  ],
  imports: [
    RouterModule,
    //NgxDropzoneModule,
    ReportsRoutingRoutes,
    ChartsModule,
    TextMaskModule,
    ArchwizardModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    //ListViewModule,
    //NgSelect2Module,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxSummernoteModule
  ],
  exports: [
    ClassroomInProgressComponent,
    StudentclassescourseConfirmedClassesAnalyticComponent,
    StudentclassescourseConfirmedClassesSyntheticComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [ DatePipe, SafePipe ]
})

export class ReportsModule {
  stepDefault: any = 0;

  constructor() {
  }
}