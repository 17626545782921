import { ClassAction } from '@app/shared/enum/ClassAction';
import { ModuleType } from '@app/shared/enum';
import { formatDate } from '@angular/common';
import { Base } from '@app/shared/model';
import { StudentClassesCourse, User } from '.';

export class StudentClassesCourseAction extends Base {
    studentClassesCourse: StudentClassesCourse;
    studentClassesCourseId: number;

    classAction: ClassAction;
    date: Date;
    module: ModuleType;
    info: string;

    user: User;
    userId: number;

    constructor(contentResponse: any) {
        super(contentResponse);
    
        this.studentClassesCourseId = parseInt(contentResponse.studentClassesCourseId);
        if (contentResponse && contentResponse.studentClassesCourseId > 0) {
            this.studentClassesCourseId = contentResponse.studentClassesCourse;
        }

        this.userId = parseInt(contentResponse.userId);
        if (contentResponse && contentResponse.userId > 0) {
            this.user = contentResponse.user;
        }

        this.date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        this.classAction = parseInt(contentResponse.classAction);
        this.module = parseInt(contentResponse.module);

        this.module = contentResponse.info;
    }
}
