<h5 class="frame-heading">
  Aluno: <i>{{ studentCourseData.studentName }}</i>
</h5>
<h5 class="frame-heading">
  Professor titular: <i>{{ studentCourseData.teacherName }}</i>
</h5>
<h5 class="frame-heading">
  Curso: <i>{{ studentCourseData.courseName }}</i>
</h5>

<h4>Legenda & Cores</h4>
<div class="subheader">
  <div class="subheader-block d-lg-flex align-items-center">
    <div class="d-inline-flex flex-column justify-content-center mr-3">
      <span
        class="badge badge-xs badge-primary badge-pill fs-sm d-block text-white fw-500"
      >
        Confirmar Aula
      </span>
    </div>
  </div>
  <!-- <div class="subheader-block d-lg-flex align-items-center">
        <div class="d-inline-flex flex-column justify-content-center mr-3">
            <span class="badge badge-xs badge-danger badge-pill fs-sm d-block text-white fw-500">
                Cancelar Aula
            </span>
        </div>
    </div> -->
  <div class="subheader-block d-lg-flex align-items-center">
    <div class="d-inline-flex flex-column justify-content-center mr-3">
      <span
        class="badge badge-xs badge-success badge-pill fs-sm d-block text-white fw-500"
      >
        Remarcação da Aula
      </span>
    </div>
  </div>
  <div class="subheader-block d-lg-flex align-items-center">
    <div class="d-inline-flex flex-column justify-content-center mr-3">
      <span
        class="badge badge-xs badge-info badge-pill fs-sm d-block text-white fw-500"
      >
        Troca de Professor
      </span>
    </div>
  </div>
  <div class="subheader-block d-lg-flex align-items-center">
    <div class="d-inline-flex flex-column justify-content-center mr-3">
      <span
        class="badge badge-xs badge-dark badge-pill fs-sm d-block text-white fw-500"
      >
        Restaurar Status
      </span>
    </div>
  </div>
</div>

<div id="panel-8" class="panel">
  <div class="panel-hdr bg-primary-600 bg-primary-gradient">
    <h2>Gestão de aulas e histórico da matrícula</h2>
    <br />
  </div>
  <div class="panel-container show">
    <div
      class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex"
    >
      <div class="col-sm-12" *ngIf="!showTableClasses">
        <div class="alert alert-warning m-2" role="alert">
          {{ returnMessage }}
        </div>
      </div>
      <div class="col-sm-12" *ngIf="showTableClasses">
        <managed-table
          [dataTitle]=""
          [rows]="classesCalendarEventData$ | async"
          [columns]="columns"
          [ngTableFiltering]="true"
          [showFilterRow]="false"
          [hasFilter]="true"
          [hasActions]="true"
          [auxEnable]="true"
          [auxIcon]="iconClassConfirmed"
          [titleAux]="titleClassConfirmed"
          (auxClick)="changedClass($event, 1)"
          [auxEnable2]="true"
          [auxIcon2]="iconClassReschedule"
          [titleAux2]="titleClassReschedule"
          (auxClick2)="
            studentCourseClassReschedule($event, mdStudentCourseClassReschedule)
          "
          [auxEnable3]="true"
          [auxIcon3]="iconClassTeacherChange"
          [titleAux3]="titleClassTeacherChange"
          (auxClick3)="
            studentCourseClassTeacherChange(
              $event,
              mdStudentCourseClassTeacherChange
            )
          "
          [auxEnable4]="true"
          [auxIcon4]="iconResetStatusClass"
          [titleAux4]="titleResetStatusClass"
          (auxClick4)="resetStatusClass($event)"
          [auxEnable5]="true"
          [auxIcon5]="iconClassActionsLog"
          [titleAux5]="titleClassActionsLog"
          (auxClick5)="
            studentCourseClassActionsLog($event, mdStudentCourseClassActionsLog)
          "
        >
        </managed-table>
      </div>

      <ng-template
        #mdStudentCourseClassTeacherChange
        let-c="close"
        let-d="dismiss"
      >
        <studentclassescourse-teacher-changes
          studentCourseClassId="{{ studentCourseClassId }}"
        ></studentclassescourse-teacher-changes>
      </ng-template>

      <ng-template
        #mdStudentCourseClassActionsLog
        let-c="close"
        let-d="dismiss"
      >
        <div class="modal-header">
          <h5 class="modal-title">Logs de ações da aula</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="dismissModal()"
          >
            <span aria-hidden="true"><i class="fa fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <studentclassescourse-auditing-changes
            studentClassesCourseId="{{ studentCourseClassId }}"
          ></studentclassescourse-auditing-changes>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-secondary waves-effect waves-themed"
            data-dismiss="modal"
            (click)="dismissModal()"
          >
            <i class="fa fa-times-circle mr-1"></i> Fechar
          </button>
        </div>
      </ng-template>

      <ng-template
        #mdStudentCourseClassReschedule
        let-c="close"
        let-d="dismiss"
      >
        <div class="modal-header">
          <h5 class="modal-title">Reagendamento da aula</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="dismissModal()"
          >
            <span aria-hidden="true"><i class="fa fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <div class="col-12 mb-3">
              <input
                type="hidden"
                id="studentClassesCourseExpectedDate"
                formControlName="studentClassesCourseExpectedDate"
              />
              <label class="form-label" for="studentClassesCourseReschedule"
                >Nova data da aula <span class="text-danger">*</span>
              </label>
              <input
                type="date"
                id="studentClassesCourseReschedule"
                [ngModel]="studentClassesCourseReschedule | date : 'yyyy-MM-dd'"
                class="form-control"
                placeholder="Nova data da aula"
                required
                (change)="setValueDate($event.target)"
              />
            </div>
            <div class="col-12 mb-3">
              <label class="form-label" for="studentClassesCourseExpectedTime"
                >Horário <span class="text-danger">*</span>
              </label>
              <input
                type="time"
                id="studentClassesCourseExpectedTime"
                required
                [ngModel]="studentClassesCourseExpectedTime"
                class="form-control"
                placeholder="Horário de início da aula"
                (change)="setValueTime($event.target)"
              />
            </div>
            <div class="col-12 text-right">
              <button
                type="button"
                class="btn btn-sm btn-info waves-effect waves-themed"
                (click)="findCoursesByDatetime(studentClassesCourseReschedule)"
              >
                <i class="fa fa-search mr-1"></i> Localizar turma
              </button>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 mb-3">
              <hr />
              <div class="form-group">
                <label class="form-label" for="courseId"
                  >Selecione uma turma para reposição</label
                >
                <span class="fw-700 ml-1">(Opcional)</span>
                <div class="ng-autocomplete w-100">
                  <ng-autocomplete
                    [data]="courseList"
                    [initialValue]="course"
                    searchKeyword="name"
                    placeholder="Selecione uma turma (opcional)"
                    (selected)="selectEventCourse($event)"
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                  >
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>
                <div
                  *ngIf="!validCourse && f.courseId.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.courseId.errors.required">
                    Por favor, informe o curso.
                  </div>
                  <div *ngIf="f.courseId.errors.invalidValue">
                    Curso selecionado é inválido.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm btn-secondary waves-effect waves-themed"
            data-dismiss="modal"
            (click)="dismissModal()"
          >
            <i class="fa fa-times-circle mr-1"></i> Cancelar
          </button>
          <button
            type="button"
            class="btn btn-sm btn-success waves-effect waves-themed"
            (click)="willChangeClassReschedule(studentClassesCourseReschedule)"
          >
            <i class="fa fa-check mr-1"></i> Confirmar
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
