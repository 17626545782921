import { ContentType } from "@app/shared/enum/ContentType";
import { Base } from "@app/shared/model";

export class Comment extends Base {
    contentType: ContentType;
    contentId: number;
    userId: number;
    grade: number;
    comment: string;
    recommendationFlag: boolean;

    constructor(contentResponse: any) {
        super(contentResponse);
        
        this.contentId = parseInt(contentResponse.contentId);
        this.userId = parseInt(contentResponse.userId);
        this.contentType = contentResponse.contentType;
        this.grade = parseInt(contentResponse.grade);
        this.comment = contentResponse.comment;
        this.recommendationFlag = contentResponse.recommendationFlag;
    }
}
