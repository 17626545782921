<div class="row justify-content-between mb-5">
  <div class="col-md-4 text-left">
    <label class="col-form-label text-primary fw-700"
      >Total de registros: {{ length }}</label
    >
    <label class="col-form-label ml-5 pull-right" *ngIf="config.paging"
      >Página: {{ page }} / {{ numPages }}</label
    >
  </div>
  <div class="col-md-4 text-right">
    <div class="form-group" *ngIf="config.filtering">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="Buscar"
          aria-label="Buscar"
          aria-describedby="button-addon5"
          (keyup.enter)="onChangeFilter(config)"
          [(ngModel)]="config.filtering.filterString"
          (tableChanged)="onChangeFilter(config)"
        />
        <div class="input-group-append">
          <button
            class="btn btn-primary"
            type="button"
            id="btn-search"
            (click)="onChangeFilter(config)"
          >
            <i class="fal fa-search"></i>
          </button>
        </div>
      </div>
      <span class="help-block pull-left"
        >Pesquise algum registro usando o campo acima e pressione ENTER ou
        clique em BUSCAR.</span
      >
    </div>
  </div>
</div>

<div class="d-flex justify-content-center">
  <ng-container footer>
    <ngb-pagination
      *ngIf="config.paging"
      class="d-flex float-right"
      [(page)]="page"
      [maxSize]="maxSize"
      [boundaryLinks]="true"
      [collectionSize]="length"
      (pageChange)="onChangePage(config, $event)"
      (numPages)="numPages = $event"
      [pageSize]="pageSize"
    ></ngb-pagination>
  </ng-container>
</div>

<ng-container outsideBody>
  <div class="table-responsive text-nowrap">
    <table class="table dataTable m-0" role="grid" style="width: 100%">
      <thead>
        <tr role="row">
          <th
            *ngFor="let col of columnSource$ | async"
            [ngTableSorting]="config"
            [column]="col"
            style="cursor: pointer !important"
            (sortChanged)="onChangeSort($event)"
            ngClass="{{ col.className || '' }}"
            [innerHtml]="getColumnTitle(col)"
          >
            <i
              *ngIf="config && col.sort"
              class="pull-right fa"
              [ngClass]="{
                'fa-chevron-down': col.sort === 'desc',
                'fa-chevron-up': col.sort === 'asc'
              }"
            ></i>
          </th>
          <th
            *ngIf="hasActions"
            class="grid-col text-primary font-weight-bold fixed-right text-center"
          >
            Ações
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows">
          <td
            (click)="onCellClick(row, col.name)"
            *ngFor="let col of columnSource$ | async"
            [innerHtml]="
              getDataSubstring(row, col.name, col.className, col.icon)
            "
            style="cursor: pointer"
            ngClass="{{ col.className || '' }}"
          ></td>
          <td *ngIf="hasActions" class="grid-col-fixed-right">
            <p class="text-center m-0">
              <a
                *ngIf="previewEnable"
                (click)="previewClicked(row)"
                title="Mais informações"
                href="javascript:;"
                class="btn btn-success btn-icon btn-circle btn-sm m-x-5"
                style="margin: 0 5px"
              >
                <i class="fa fa-search-plus"></i>
              </a>
              <a
                *ngIf="editEnable"
                (click)="editClicked(row)"
                title="Alterar"
                href="javascript:;"
                class="btn btn-info btn-icon btn-circle btn-sm m-x-5"
                style="margin: 0 5px"
              >
                <i class="fa fa-pencil"></i>
              </a>
              <a
                *ngIf="deleteEnable"
                (click)="deleteClicked(row)"
                title="Excluir"
                href="javascript:;"
                class="btn btn-danger btn-icon btn-circle btn-sm m-x-5"
                style="margin: 0 5px"
              >
                <i class="fa fa-times"></i>
              </a>
              <a
                *ngIf="auxEnable"
                (click)="auxClicked(row)"
                [title]="titleAux"
                href="javascript:;"
                class="btn btn-primary btn-icon btn-circle btn-sm m-x-5"
                style="margin: 0 5px"
              >
                <i [class]="auxIcon"></i>
              </a>
              <a
                *ngIf="auxEnable2"
                (click)="auxClicked2(row)"
                [title]="titleAux2"
                href="javascript:;"
                class="btn btn-secondary btn-icon btn-circle btn-sm m-x-5"
                style="margin: 0 5px"
              >
                <i [class]="auxIcon2"></i>
              </a>
              <a
                *ngIf="auxEnable3"
                (click)="auxClicked3(row)"
                [title]="titleAux3"
                href="javascript:;"
                class="btn btn-dark btn-icon btn-circle btn-sm m-x-5"
                style="margin: 0 5px"
              >
                <i [class]="auxIcon3"></i>
              </a>
              <a
                *ngIf="auxEnable4"
                (click)="auxClicked4(row)"
                [title]="titleAux4"
                href="javascript:;"
                class="btn btn-warning btn-icon btn-circle btn-sm m-x-5"
                style="margin: 0 5px"
              >
                <i [class]="auxIcon4"></i>
              </a>
              <a
                *ngIf="auxEnable5"
                (click)="auxClicked5(row)"
                [title]="titleAux5"
                href="javascript:;"
                class="btn btn-dark btn-icon btn-circle btn-sm m-x-5"
                style="margin: 0 5px"
              >
                <i [class]="auxIcon5"></i>
              </a>
              <a
                *ngIf="auxEnable6"
                (click)="auxClicked6(row)"
                [title]="titleAux6"
                href="javascript:;"
                class="btn btn-success btn-icon btn-circle btn-sm m-x-5"
                style="margin: 0 5px"
              >
                <i [class]="auxIcon6"></i>
              </a>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
<br />
<div class="row">
  <div class="col-md-6 text-left">
    <label class="col-form-label text-primary" *ngIf="config.paging"
      >Página: {{ page }} / {{ numPages }}</label
    >
  </div>
  <div class="col-md-6 text-right">
    <ng-container footer>
      <ngb-pagination
        *ngIf="config.paging"
        class="d-flex float-right"
        [(page)]="page"
        [maxSize]="maxSize"
        [boundaryLinks]="true"
        [collectionSize]="length"
        (pageChange)="onChangePage(config, $event)"
        (numPages)="numPages = $event"
        [pageSize]="pageSize"
      ></ngb-pagination>
    </ng-container>
  </div>
</div>
