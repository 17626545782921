import { ResponseResult } from '@app/auth/model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddCourse, Course, Teacher } from '@app/admin/model';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { CourseAvailablePositionsFilter } from '../model/courseAvailablePositionsFilter';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private route: string = 'admin';
  private controller: string = 'course';
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getall(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getWithStudents(): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/getWithStudents`
    );
  }

  getById(courseId: string): any {
    let params = new HttpParams();
    params = params.append('id', courseId);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getById`, {
      params: params,
    });
  }

  getByIdentifier(identifier: string): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/GetByIdentifier/${identifier}`
    );
  }

  getByProductId(productId: string): any {
    let params = new HttpParams();
    params = params.append('productId', productId);
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/getByProductId`,
      { params: params }
    );
  }

  getByTeacherId(teacherId: string): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/teacher/${teacherId}`
    );
  }

  allowNewStudent(courseId: number): any {
    let params = new HttpParams();
    params = params.append('courseId', courseId.toString());
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/allowNewStudent`,
      { params: params }
    );
  }

  remove(id): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.delete<ResponseResult>(`${this.endpointPrefix}/remove`, {
      params: params,
    });
  }

  save(course: Course, editing: boolean = false): any {
    if (editing) {
      //update
      let params = new HttpParams();
      params = params.append('id', course.id.toString());
      return this.http.put<ResponseResult>(
        `${this.endpointPrefix}/update`,
        course,
        { params: params }
      );
    } else {
      //new
      return this.http.post<ResponseResult>(
        `${this.endpointPrefix}/add`,
        course
      );
    }
  }

  addWithRec(addCourse: AddCourse): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/add`,
      addCourse
    );
  }

  list(onlyAvailable: boolean = false): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/list/${onlyAvailable}`
    );
  }

  grid(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/grid`);
  }

  changeTeacher(courseId: number, teacher: Teacher): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/changeTeacher/${courseId}`,
      teacher
    );
  }

  changeTeacherByCourseAndRecurrence(
    courseId: number,
    courseRecurrenceId: number,
    teacher: Teacher
  ): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/ChangeTeacher/course/${courseId}/recurrence/${courseRecurrenceId}`,
      teacher
    );
  }

  availablePositions(filter: CourseAvailablePositionsFilter): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/AvailablePositions`,
      filter
    );
  }

  listByDatetime(datetime: any): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/listByDatetime/${datetime}`
    );
  }

  getClassRoom(classRoom: string): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/classRoom/${classRoom}`
    );
  }
}
