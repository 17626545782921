import { ItemSerie } from './itemSerie';
import { SerieType } from "../enum/SerieType";

export class SerieContent {
    serieType: SerieType;
    series: Array<ItemSerie>;

    constructor (values: Object = {}){
        Object.assign(this, values);
    }
}
