export enum StatusCrm {
  SDR = 0,
  ScheduledTrial = 1,
  ExperimentCanceled = 2,
  Commercial = 3,
  DoneBusiness = 4,
  LostBusiness = 5,
  SentUser = 6,
  AwaitingRegistration = 7,
  Done = 8,
  PendingArea = 9,
  ExternalCommercial = 10,
  Certificate = 11,
  RegistrationCanceled = 12,
  TrialRequested = 13
}

export const StatusCrmLabel = new Map<number, string>([
  [StatusCrm.SDR, 'SDR'],
  [StatusCrm.ScheduledTrial, 'Experimental Agendada'],
  [StatusCrm.ExperimentCanceled, 'Experimental Cancelada'],
  [StatusCrm.Commercial, 'Comercial'],
  [StatusCrm.DoneBusiness, 'Negócio Fechado'],
  [StatusCrm.LostBusiness, 'Negócio Perdido'],
  [StatusCrm.SentUser, 'Usuário Enviado'],
  [StatusCrm.AwaitingRegistration, 'Aguardando Matrícula'],
  [StatusCrm.Done, 'Concluído'],
  [StatusCrm.PendingArea, 'Área de Pendências'],
  [StatusCrm.ExternalCommercial, 'Comercial Externo'],
  [StatusCrm.Certificate, 'Certificado'],
  [StatusCrm.RegistrationCanceled, 'Matrícula Cancelada'],
  [StatusCrm.TrialRequested, 'Experimental Solicitada']
]);

export const StatusCrmCss = new Map<number, string>([
  [StatusCrm.SDR, 'status-sdr'],
  [StatusCrm.ScheduledTrial, 'status-scheduled-trial'],
  [StatusCrm.ExperimentCanceled, 'status-experiment-canceled'],
  [StatusCrm.Commercial, 'status-commercial'],
  [StatusCrm.DoneBusiness, 'status-done-business'],
  [StatusCrm.LostBusiness, 'status-lost-business'],
  [StatusCrm.SentUser, 'status-sent-user'],
  [StatusCrm.AwaitingRegistration, 'status-awaiting-registration'],
  [StatusCrm.Done, 'status-done'],
  [StatusCrm.PendingArea, 'status-pending-area'],
  [StatusCrm.ExternalCommercial, 'status-external-commercial'],
  [StatusCrm.Certificate, 'status-certificate'],
  [StatusCrm.RegistrationCanceled, 'status-registration-canceled'],
  [StatusCrm.TrialRequested, 'status-trial-requested']
]);
