import { Base } from '@app/shared/model';

export class Invoice extends Base {
    description: string;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.description = contentResponse.description;
    }
}
