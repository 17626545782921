import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClassesAbsencesReportService {
  private route: string = "reports";
  private controller: string = "ClassesAbsencesReport";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  classesAbsences(startDate: any, endDate: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/absences/${startDate}/${endDate}`);
  }
}
