import { MessageService } from '@app/shared/services/message.service';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { WritingTestApplyService } from '@app/admin/services';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  TypeResponse,
  WritingTestReviewCss,
  WritingTestReviewLabel,
  WritingTestStatus,
  WritingTestStatusCss,
  WritingTestStatusLabel,
} from '@app/shared/enum';
import { default as swal } from 'sweetalert2';
import { InitStudentService } from '@app/student/services';
import { Student } from '@app/admin/model';
import { first } from 'rxjs/operators';

@Component({
  selector: 'writing-test-list',
  templateUrl: './writing-test-list.component.html',
  styleUrls: ['./writing-test-list.component.css'],
  providers: [DatePipe],
})
export class WritingTestListComponent implements OnInit {
  public gridToDoStudentList: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  private student: Student;
  closeResult: string;
  public contentHtmlView: string = '';

  public writingTestApplyId: number = 0;
  public writingTestApplyPreviewMode: boolean = false;

  iconApplyTest = 'fas fa-file-edit';
  titleApplyTest = 'Fazer teste';

  public columns: Array<any> = [
    {
      title: 'Turma',
      name: 'course.name',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-left text-default fw-700',
    },
    {
      title: 'Professor',
      name: 'teacherApplied.name',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-left text-default fw-700',
    },
    {
      title: 'Status<br>do Teste',
      name: 'designWritingTestStatus',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-default',
    },
    {
      title: 'Disponibilizado em',
      name: 'availabilityDate',
      sort: 'asc',
      className: 'text-center text-info datetime',
    },
    {
      title: 'Finalizado em',
      name: 'finishDate',
      sort: 'asc',
      className: 'text-center text-info datetime',
    },
    {
      title: 'Status<br>de Revisão',
      name: 'designWritingTestReview',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-primary',
    },
    {
      title: 'Revisado em',
      name: 'reviewDate',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
    // { title: "Nota", name: "scoreResult", sort: "asc", className: "text-center text-primary fw-700" }
  ];

  constructor(
    private writingTestApplyService: WritingTestApplyService,
    private initStudent: InitStudentService,
    private loading: MessageService,
    private modalService: NgbModal
  ) {
    this.initStudent.setConfigPage();
    this.student = this.initStudent.student;
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.dataResult = false;
    this.getUserData();
    this.loading.hideLoading();
  }

  private getUserData() {
    this.initStudent
      .getStudentUser()
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.student = response;
          this.loadWritingTestsToDoStudent();
          this.loading.hideLoading();
        },
        (error: any) => {
          this.returnMessage = 'Erro ao carregar o calendário.';
          this.loading.hideLoading();
        }
      );
  }

  loadWritingTestsToDoStudent() {
    this.loading.showLoading();
    this.writingTestApplyService.toDoStudent(this.student.id).subscribe(
      (response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.designAllData(response.data);
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.loading.hideLoading();
      },
      (error: any) => {
        this.showTable = false;
        this.returnMessage = 'Erro ao carregar os testes disponíveis.';
        this.loading.hideLoading();
      }
    );
  }

  designAllData(lista: any[]) {
    lista.forEach(function (e) {
      if (e.writingTestStatus >= 0) {
        let writingTestStatusCss = WritingTestStatusCss.get(
          e.writingTestStatus
        );
        let writingTestStatusStr = WritingTestStatusLabel.get(
          e.writingTestStatus
        );
        e.designWritingTestStatus =
          "<span class='badge badge-pill w-100 font-weight-bold " +
          writingTestStatusCss +
          "'>" +
          writingTestStatusStr +
          '</span>';
      }

      if (e.writingTestReview >= 0) {
        let writingTestReviewCss = WritingTestReviewCss.get(
          e.writingTestReview
        );
        let writingTestReviewStr = WritingTestReviewLabel.get(
          e.writingTestReview
        );
        e.designWritingTestReview =
          "<span class='badge badge-pill w-100 font-weight-bold " +
          writingTestReviewCss +
          "'>" +
          writingTestReviewStr +
          '</span>';
      }
    });
    this.gridToDoStudentList = lista;
  }

  preview(row: any, content: any): void {
    this.writingTestApplyId = row.id;
    this.writingTestApplyPreviewMode = true;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  toApply(row: any, content: any): void {
    this.writingTestApplyId = row.id;
    this.writingTestApplyPreviewMode = false;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  toApplyStudentTest(row: any, content: any) {
    if (row.writingTestStatus == WritingTestStatus.Pending) {
      return this.toApply(row, content);
    } else if (row.writingTestStatus == WritingTestStatus.Scheduled) {
      swal({
        type: 'warning',
        title: 'O teste ainda não está disponível!',
        showConfirmButton: true,
      });
    } else {
      swal({
        type: 'warning',
        title: 'O teste já está finalizado, deseja visualizar?',
        showConfirmButton: true,
        showCancelButton: true,
      }).then((result) => {
        if (result.value === true) {
          return this.preview(row, content);
        }
      });
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();

      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }
}
