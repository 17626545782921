import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Student } from '@app/admin/model';
import { ContentAuditPost } from '@app/audit/model';
import { ContentAuditService } from '@app/audit/services';
import { MessageService } from '@app/shared/services';
import { InitStudentService } from '@app/student/services';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Declara a lib do videojs como externa ao angular
declare let videojs: any;

@Component({
  selector: 'content-view',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.css']
})
export class ContentViewComponent implements OnInit {
  private student: Student;
  
  private contentFile: string = `${environment.contentFiles}`;
  private structureFile: string;
  // Titulo do component
  title = 'Player com Video.JS';
  // Instancia do video.js.
  vidObj: any;
  // Poster para ser usado no video.js
  poster: string;
  // URL do video a ser reproduzido.
  video: string;
  // doc_pdf: string;
  videoType: string;
  // Subtitles
  subtitleEn: string;
  subtitlePt: string;
  serieName: string;
  episodeName: string;
  progressValue: number = 0;

  pdfSrc: string;
  public pdfSrcAsync$ = new BehaviorSubject("");

  // Acessa o elemento video do html5 via viewchild.
  @ViewChild('pdfViewer') pdfViewer: ElementRef;

  public itemIndex: number;
  public itemSerie: string;
  public itemEpisode: string;
  returnUrl: string;
  public serieContent: any;
  public contentPath: string;
  public imgFileName: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private contentAuditService: ContentAuditService,
    private activatedRoute: ActivatedRoute,
    private loading: MessageService,
    private initStudent: InitStudentService
  ) {
    this.loading.showLoading();
    this.initStudent.setConfigPage(false, false);
    this.student = this.initStudent.student;
    this.structureFile = `${this.contentFile}/structure-files.json`;
    this.returnUrl = '/student/course';
  }

  ngOnInit() {
    this.loading.hideLoading();
    this.activatedRoute.params.subscribe(params => {
      this.itemSerie = params['serie'];
      this.itemIndex = params['index'];
      this.itemEpisode = params['episode'];
      if (this.itemSerie && this.itemEpisode) {
        let obj = history.state.data;
        if (obj) {
          this.video = obj.video.replace('assets/content/pecm', 'https://d2km7a55p0pgkb.cloudfront.net').toString();
          this.videoType = obj.videoType.replace('assets/content/pecm', 'https://d2km7a55p0pgkb.cloudfront.net').toString();
          this.subtitleEn = obj.subtitleEn.replace('assets/content/pecm', 'https://d2km7a55p0pgkb.cloudfront.net').toString();
          this.subtitlePt = obj.subtitlePt.replace('assets/content/pecm', 'https://d2km7a55p0pgkb.cloudfront.net').toString();
          this.pdfSrc = obj.docPdf.replace('assets/content/pecm', 'https://d2km7a55p0pgkb.cloudfront.net').toString();
          this.pdfSrcAsync$.next(this.pdfSrc);
          this.serieName = obj.serieName;
          this.episodeName = obj.episodeName;

          var auditPost = new ContentAuditPost();
          auditPost.userId = this.student.userId;
          auditPost.episodeShortName = this.episodeName;
          this.contentAuditService.audit(auditPost);
        } else {
          this.callBackList(this.itemSerie);
        }
      } else {
        this.router.navigate([this.returnUrl]);
      }
    });
  }
  

  ngAfterViewInit() {
    const options = {
      controls: true,
      autoplay: false,
      preload: 'auto',
      responsive: true,
      techOrder: ['html5']
    };
  }

  public callBackList(itemSerie: string) {
    this.router.navigate(['/student/contentlist', { serie: itemSerie }]);
  }

  private getJSON(): Observable<any> {
    var call = this.http.get<any>(this.contentFile);
    return call.pipe(map(response => {
      return response
    }));
  }

  getPDF(url: string) {
    const fileurl = `${url}/pdf`;
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Accept': 'application/json',
          'responseType':'blob'
        }
      )
    };
    return this.http.get<any>(fileurl, httpOptions);
  }

  pdfViewerSelect() {
    this.loading.showLoading();
    setTimeout(() => {
      this.pdfViewer.nativeElement.click();
      this.loading.hideLoading();
    }, 1000);
  }
}
