import { Teacher } from "@app/admin/model";
import { StudentClassesCourseConfirmed } from ".";

export class StudentClassesCourseConfirmedGroup {
    teacher: Teacher;
    studentCoursesCount: number;
    doneClassesCount: number;
    studentClassesCourseConfirmed: Array<StudentClassesCourseConfirmed>;

    constructor() {
        this.doneClassesCount = 0;
        this.studentCoursesCount = 0;
        this.studentClassesCourseConfirmed = new Array<StudentClassesCourseConfirmed>();
    }
}
