import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AwaitService {

  constructor() { }

  public delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async waitUntil(condition: any) {
    return await new Promise(resolve => {
      const interval = setInterval(() => {
        if (condition) {
          resolve('foo');
          clearInterval(interval);
        };
      }, 1000);
    });
  }
}
