<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item">
    <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
  </li>
  <li class="breadcrumb-item">
    <a href="javascript:void(0);" [routerLink]="['/admin/courselist']"
      >Turmas</a
    >
  </li>
  <li class="breadcrumb-item active">Nova Turma</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol>
<div id="panel-2" class="panel">
  <div class="panel-hdr bg-primary-600 bg-primary-gradient">
    <h2>
      Nova <span class="fw-300"><i>Turma</i></span>
    </h2>
    <div class="panel-toolbar">
      <a
        href="javascript:void(0);"
        class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Collapse"
      >
        <i class="fal fa-minus text-white fw-900"></i>
      </a>
      <a
        href="javascript:void(0);"
        class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Fullscreen"
      >
        <i class="fal fa-expand text-primary fw-900"></i>
      </a>
      <a
        href="javascript:void(0);"
        class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
        data-action="panel-collapse"
        data-toggle="tooltip"
        data-offset="0,10"
        data-original-title="Close"
      >
        <i class="fal fa-times text-white fw-900"></i>
      </a>
    </div>
  </div>
  <div class="panel-container show">
    <div class="panel-content p-0">
      <form
        [formGroup]="courseForm"
        novalidate
        class="needs-validation"
        (ngSubmit)="onSubmit()"
      >
        <div class="panel-content">
          <div class="form-row">
            <div class="col-md-12">
              <aw-wizard
                [navBarLayout]="'large-filled-symbols'"
                [awNavigationMode]
                navigateBackward="allow"
                [defaultStepIndex]="0"
                navBarLocation="top"
                navigateForward="allow"
                class="m-l-15 m-r-15"
              >
                <aw-wizard-step
                  id="basicData"
                  class="m-3"
                  stepTitle="Dados básicos"
                  (click)="({ stepIndex: 0 })"
                  [stepId]="1"
                >
                  <ng-template awWizardStepTitle>
                    <span class="hidden-sm-down">Dados básicos</span>
                    <span class="hidden-md-up">Básico</span>
                  </ng-template>
                  <ng-template awWizardStepSymbol>
                    <i class="far fa-home"></i>
                  </ng-template>
                  <hr />
                  <input type="hidden" id="id" formControlName="id" />
                  <input
                    type="hidden"
                    id="createdOn"
                    formControlName="createdOn"
                  />
                  <input
                    type="hidden"
                    id="updatedOn"
                    formControlName="updatedOn"
                  />
                  <div class="form-group row" *ngIf="editMode === false">
                    <div class="col-md-6 mb-2">
                      <div class="form-group">
                        <label class="form-label" for="productId"
                          >Pacote <span class="text-danger">*</span>
                        </label>
                        <div class="ng-autocomplete">
                          <ng-autocomplete
                            [data]="productList"
                            searchKeyword="name"
                            placeholder="Selecione o pacote"
                            (selected)="selectEvent($event)"
                            (inputChanged)="onChangeSearch($event)"
                            (inputFocused)="onFocused($event)"
                            [itemTemplate]="itemTemplate"
                            [notFoundTemplate]="notFoundTemplate"
                          >
                          </ng-autocomplete>

                          <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.name"></a>
                          </ng-template>

                          <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                          </ng-template>
                        </div>
                      </div>
                      <div
                        class="form-group row mb-2"
                        *ngIf="flagProductSelected"
                      >
                        <div class="col-12">
                          <div class="card">
                            <div class="card-body">
                              <h5 class="card-title text-primary fw-700">
                                Dados do Pacote
                              </h5>
                              <div class="form-group row mb-2">
                                <div class="col-8 mb-3">
                                  <div class="form-group">
                                    <label class="form-label"
                                      >Nome do Pacote</label
                                    >
                                    <p class="card-text fw-700">
                                      {{ product.name }}
                                    </p>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="form-label">Valor Total</label>
                                  <p class="card-text fw-700">
                                    {{ formatCurrency(product.value) }}
                                  </p>
                                </div>
                                <!-- <div class="col-6">
                                                                    <label class="form-label">Valor Mensal</label>
                                                                    <p class="card-text fw-700">
                                                                        {{formatCurrency(product.monthlyAmount)}}
                                                                    </p>
                                                                </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-6 mb-2">
                                            <div class="form-group">
                                                <label class="form-label" for="teacherId">Professor(a) <span class="text-danger">*</span> </label>
                                                <select class="form-control" id="teacherId" for="teacherId" formControlName="teacherId" placeholder="Professor(a)"
                                                    [ngClass]="{ 'is-invalid': !validTeacher && f.teacherId.errors }" (change)="onTeacherChange(f.teacherId.value)">
                                                    <option *ngFor="let teacher of teacherList" [ngValue]="teacher.id">
                                                        {{ teacher.name }}
                                                    </option>
                                                </select>
                                                <div *ngIf="!validTeacher && f.teacherId.errors" class="invalid-feedback">
                                                    <div *ngIf="f.teacherId.errors.required">Por favor, informe o(a) professor(a).</div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-2" *ngIf="flagTeacherSelected">
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <h5 class="card-title text-primary fw-700">Dados do Professor</h5>
                                                            <div class="form-group row mb-2">
                                                                <div class="col-8">
                                                                    <label class="form-label">Nome</label>
                                                                    <p class="card-text fw-700">
                                                                        {{teacher.name}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-4">
                                                                    <label class="form-label">Localidade</label>
                                                                    <p class="card-text fw-700">
                                                                        {{teacher.cityName}} / {{teacher.stateUf}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-6">
                                                                    <label class="form-label">Telefone</label>
                                                                    <p class="card-text fw-700">
                                                                        {{teacher.phone}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-6">
                                                                    <label class="form-label">E-mail</label>
                                                                    <p class="card-text fw-700">
                                                                        {{teacher.email}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-6">
                                                                    <label class="form-label">Graduação</label>
                                                                    <p class="card-text fw-700">
                                                                        {{teacher.universityGraduate}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-6">
                                                                    <label class="form-label">Mais Informações</label>
                                                                    <p class="card-text fw-700">
                                                                        {{teacher.moreInformation}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                  </div>
                  <div class="form-group row" *ngIf="editMode === false">
                    <div class="col-6 mb-2">
                      <div class="form-group">
                        <label class="form-label" for="name"
                          >Nome da turma <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          formControlName="name"
                          class="form-control"
                          placeholder="Nome da turma"
                          maxlength="100"
                          [ngClass]="{
                            'is-invalid': !validCourse && f.name.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validCourse && f.name.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.name.errors.required">
                            Por favor, informe o nome da turma.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mb-2">
                      <label class="form-label" for="skypeLink"
                        >Link do Skype <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="skypeLink"
                        formControlName="skypeLink"
                        class="form-control"
                        placeholder="Link da aula no Skype"
                        [ngClass]="{
                          'is-invalid': !validCourse && f.skypeLink.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validCourse && f.skypeLink.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.skypeLink.errors.required">
                          Por favor, informe o link do Skype.
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="form-group row" *ngIf="editMode === true">
                    <div class="col-md-8 mb-2">
                      <div class="form-group">
                        <label class="form-label" for="name"
                          >Nome da turma <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          formControlName="name"
                          class="form-control"
                          placeholder="Nome da turma"
                          maxlength="100"
                          [ngClass]="{
                            'is-invalid': !validCourse && f.name.errors
                          }"
                          required
                        />
                        <div
                          *ngIf="!validCourse && f.name.errors"
                          class="invalid-feedback"
                        >
                          <div *ngIf="f.name.errors.required">
                            Por favor, informe o nome da turma.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-2">
                      <label class="form-label" for="quantityStudent"
                        >Quantidade de alunos <span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        id="quantityStudent"
                        formControlName="quantityStudent"
                        class="form-control"
                        placeholder="Quantidade de alunos"
                        minlength="1"
                        maxlength="5"
                        [ngClass]="{
                          'is-invalid': !validCourse && f.quantityStudent.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validCourse && f.quantityStudent.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.quantityStudent.errors.required">
                          Por favor, informe a quantidade de alunos.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mt-2">
                      <label class="form-label" for="skypeLink"
                        >Link do Skype <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="skypeLink"
                        formControlName="skypeLink"
                        class="form-control"
                        placeholder="Link da aula no Skype"
                        [ngClass]="{
                          'is-invalid': !validCourse && f.skypeLink.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validCourse && f.skypeLink.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.skypeLink.errors.required">
                          Por favor, informe o link do Skype.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mt-2">
                      <label class="form-label" for="classRoomUrl"
                        >Link da Aula</label
                      >
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control disabled"
                          disabled="disabled"
                          placeholder="Link da Aula"
                          title="Copiar link da aula ao vivo"
                          aria-label="Link da Aula"
                          aria-describedby="button-addon2"
                          [value]="getClassRoomUrl(courseData.classRoom)"
                          #classRoomUrlInput
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-primary waves-effect waves-themed"
                            type="button"
                            id="button-addon5"
                            (click)="copyInputMessage(classRoomUrlInput)"
                          >
                            <i class="fal fa-copy"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="editMode === false">
                    <div class="col-md-4 mb-2">
                      <label class="form-label" for="weeklyFrequency"
                        >Aulas por semana <span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        id="weeklyFrequency"
                        formControlName="weeklyFrequency"
                        class="form-control"
                        placeholder="Aulas por semana"
                        minlength="1"
                        maxlength="5"
                        [ngClass]="{
                          'is-invalid': !validCourse && f.weeklyFrequency.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validCourse && f.weeklyFrequency.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.weeklyFrequency.errors.required">
                          Por favor, informe a frequência semanal.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-2">
                      <label class="form-label" for="quantityStudent"
                        >Quantidade de alunos <span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        id="quantityStudent"
                        formControlName="quantityStudent"
                        class="form-control"
                        placeholder="Quantidade de alunos"
                        minlength="1"
                        maxlength="5"
                        [ngClass]="{
                          'is-invalid': !validCourse && f.quantityStudent.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validCourse && f.quantityStudent.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.quantityStudent.errors.required">
                          Por favor, informe a quantidade de alunos.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-2">
                      <label class="form-label" for="quantityMonth"
                        >Total de meses da turma
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        id="quantityMonth"
                        formControlName="quantityMonth"
                        class="form-control"
                        placeholder="Total de meses da turma"
                        minlength="1"
                        maxlength="5"
                        [ngClass]="{
                          'is-invalid': !validCourse && f.quantityMonth.errors
                        }"
                        required
                      />
                      <div
                        *ngIf="!validCourse && f.quantityMonth.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.quantityMonth.errors.required">
                          Por favor, informe a quantidade de meses.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-2" *ngIf="editMode === true">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title text-primary fw-700">
                            Dados do Pacote
                          </h5>
                          <div class="form-group row mb-2">
                            <div class="col-8 mb-3">
                              <div class="form-group">
                                <label class="form-label">Nome do Pacote</label>
                                <p class="card-text fw-700">
                                  {{ courseData?.product?.name }}
                                </p>
                              </div>
                            </div>
                            <div class="col-4">
                              <label class="form-label">Valor Total</label>
                              <p class="card-text fw-700">
                                {{ formatCurrency(courseData?.product?.value) }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="panel-content px-0 py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div
                          id="btnButtonWizard"
                          class="btn-group btn-group-sm btn-group-toggle hidden-md-up pull-right"
                        >
                          <a
                            class="page-link btn btn-info disabled"
                            href="javascript:void(0)"
                            aria-label="Previous"
                            awPreviousStep
                          >
                            <span aria-hidden="true">«</span>
                          </a>
                          <a
                            class="page-link btn btn-info active"
                            href="javascript:void(0)"
                            aria-label="Next"
                            awNextStep
                          >
                            <span aria-hidden="true">»</span>
                          </a>
                        </div>
                        <div
                          id="btnButtonWizard"
                          class="btn-group btn-group-sm btn-group-toggle pull-right hidden-sm-down pull-right"
                        >
                          <a
                            class="page-link btn btn-info disabled"
                            href="javascript:void(0)"
                            aria-label="Previous"
                            awPreviousStep
                          >
                            <span aria-hidden="true">«</span> Anterior
                          </a>
                          <a
                            class="page-link btn btn-info active"
                            href="javascript:void(0)"
                            aria-label="Next"
                            awNextStep
                          >
                            Próximo <span aria-hidden="true">»</span>
                          </a>
                        </div>

                        <div
                          class="btn-group btn-group-sm btn-group-toggle pull-left hidden-md-up"
                        >
                          <button
                            type="button"
                            class="btn btn-primary"
                            (click)="onSubmit()"
                          >
                            Salvar
                          </button>
                          <button
                            type="button"
                            class="btn btn-default"
                            (click)="onCancel()"
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </aw-wizard-step>

                <aw-wizard-step
                  id="courseRecurrence"
                  class="m-3"
                  stepTitle="Recorrência da Turma"
                  (click)="({ stepIndex: 1 })"
                  [stepId]="2"
                >
                  <ng-template awWizardStepTitle>
                    <span class="hidden-sm-down">Recorrência da Turma</span>
                    <span class="hidden-md-up">Recorrência</span>
                  </ng-template>
                  <ng-template awWizardStepSymbol>
                    <i class="far fa-clock"></i>
                  </ng-template>
                  <hr />
                  <div class="form-group row mb-2">
                    <div class="col-12">
                      <h1 class="subheader-title mb-4">
                        <i class="subheader-icon fal fa-chart-area"></i>
                        Recorrência da <span class="fw-300">Turma</span>
                      </h1>
                      <div class="subheader">
                        <div
                          class="subheader-block d-lg-flex align-items-center"
                        >
                          <div
                            class="d-inline-flex flex-column justify-content-center mr-3 w-100 text-center"
                          >
                            <span
                              class="fw-300 fs-xs d-block color-primary-500"
                            >
                              <span class="fs-xl d-block color-primary-500">
                                Aulas por semana
                              </span>
                            </span>
                            <span
                              class="fw-900 fs-xl d-block color-primary-500"
                            >
                              {{ f.weeklyFrequency.value }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3"
                        >
                          <div
                            class="d-inline-flex flex-column justify-content-center mr-3 w-100 text-center"
                          >
                            <span class="fw-300 fs-xs d-block color-danger-500">
                              <span class="fs-xl d-block color-danger-500">
                                Qtde de alunos
                              </span>
                            </span>
                            <span class="fw-900 fs-xl d-block color-danger-500">
                              {{ f.quantityStudent.value }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3"
                        >
                          <div
                            class="d-inline-flex flex-column justify-content-center mr-3 w-100 text-center"
                          >
                            <span class="fw-300 fs-xs d-block color-info-500">
                              <span class="fs-xl d-block color-info-500">
                                Meses total
                              </span>
                            </span>
                            <span class="fw-900 fs-xl d-block color-info-500">
                              {{ f.quantityMonth.value }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                    <div class="col-12">
                      <div class="card border mb-g w-100">
                        <div
                          class="card-header bg-default-700 pr-3 d-flex align-items-center flex-wrap"
                        >
                          <div class="card-title">Recorrência</div>
                          <button
                            type="button"
                            class="btn btn-sm btn-info ml-auto"
                            (click)="newRecurrence(mdAddCourseRecurrence)"
                          >
                            Nova recorrência
                          </button>
                        </div>
                        <div class="card-body">
                          <div class="form-group row mb-2">
                            <div class="col-12" *ngIf="!showTableRecurrence">
                              <div class="alert alert-warning m-2" role="alert">
                                {{ returnRecurrenceMessage }}
                              </div>
                            </div>
                            <div
                              class="col-12 hidden-md-up"
                              *ngIf="showTableRecurrence"
                            >
                              <div class="card-columns">
                                <div
                                  class="card"
                                  *ngFor="let rec of recSource$ | async"
                                >
                                  <div class="card-body">
                                    <h5 class="card-title text-center">
                                      Dia da Aula
                                      <span
                                        [innerHtml]="rec.designDayOfWeek"
                                      ></span>
                                    </h5>
                                    <div class="row">
                                      <div class="col-4">
                                        <p class="card-text text-center mb-1">
                                          Início
                                        </p>
                                        <p class="card-text">
                                          <span
                                            [innerHtml]="rec.designTimeStart"
                                          ></span>
                                        </p>
                                      </div>
                                      <div class="col-4">
                                        <p class="card-text text-center mb-1">
                                          Término
                                        </p>
                                        <p class="card-text">
                                          <span
                                            [innerHtml]="rec.designTimeEnd"
                                          ></span>
                                        </p>
                                      </div>
                                      <div class="col-4">
                                        <p class="card-text text-center mb-1">
                                          Duração
                                        </p>
                                        <p class="card-text">
                                          <span
                                            [innerHtml]="rec.designDuration"
                                          ></span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-12 hidden-sm-down"
                              *ngIf="showTableRecurrence"
                            >
                              <div class="table-responsive text-nowrap">
                                <table
                                  class="table dataTable m-0"
                                  role="grid"
                                  style="width: 100%"
                                >
                                  <thead>
                                    <tr role="row">
                                      <th class="text-center text-primary">
                                        Dia da Aula
                                      </th>
                                      <th class="text-center text-primary">
                                        Horário de Início
                                      </th>
                                      <th class="text-center text-primary">
                                        Horário de Término
                                      </th>
                                      <th class="text-center text-primary">
                                        Duração
                                      </th>
                                      <th class="text-center text-primary">
                                        Professor(a)
                                      </th>
                                      <th class="text-center text-primary">
                                        Troca de Professor(a)
                                      </th>
                                      <th class="text-center text-primary">
                                        Excluir
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let rec of recSource$ | async">
                                      <td
                                        class="text-center"
                                        [innerHtml]="rec.designDayOfWeek"
                                      ></td>
                                      <td
                                        class="text-center"
                                        [innerHtml]="rec.designTimeStart"
                                      ></td>
                                      <td
                                        class="text-center"
                                        [innerHtml]="rec.designTimeEnd"
                                      ></td>
                                      <td
                                        class="text-center"
                                        [innerHtml]="rec.designDuration"
                                      ></td>
                                      <td
                                        class="text-center text-primary fw-700"
                                        [innerHtml]="rec.teacher?.name"
                                      ></td>
                                      <td class="grid-col-fixed-right">
                                        <p class="text-center m-0">
                                          <a
                                            (click)="
                                              courseTeacherChange(
                                                rec,
                                                mdCourseTeacherChange
                                              )
                                            "
                                            title="Troca de professor(a)"
                                            href="javascript:;"
                                            class="btn btn-primary btn-icon btn-circle btn-sm m-x-5"
                                            style="margin: 0 5px"
                                          >
                                            <i class="fa fa-user"></i>
                                          </a>
                                        </p>
                                      </td>
                                      <td class="grid-col-fixed-right">
                                        <p class="text-center m-0">
                                          <a
                                            *ngIf="editMode == false"
                                            (click)="recDelete(rec)"
                                            title="Excluir"
                                            href="javascript:;"
                                            class="btn btn-danger btn-icon btn-circle btn-sm m-x-5"
                                            style="margin: 0 5px"
                                          >
                                            <i class="fa fa-times"></i>
                                          </a>
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="panel-content px-0 py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div
                          id="btnButtonWizard"
                          class="btn-group btn-group-sm btn-group-toggle hidden-md-up pull-right"
                        >
                          <a
                            class="page-link btn btn-info active"
                            href="javascript:void(0)"
                            aria-label="Previous"
                            awPreviousStep
                          >
                            <span aria-hidden="true">«</span>
                          </a>
                          <a
                            class="page-link btn btn-info disabled"
                            href="javascript:void(0)"
                            aria-label="Next"
                            awNextStep
                          >
                            <span aria-hidden="true">»</span>
                          </a>
                        </div>
                        <div
                          id="btnButtonWizard"
                          class="btn-group btn-group-sm btn-group-toggle pull-right hidden-sm-down pull-right"
                        >
                          <a
                            class="page-link btn btn-info active"
                            href="javascript:void(0)"
                            aria-label="Previous"
                            awPreviousStep
                          >
                            <span aria-hidden="true">«</span> Anterior
                          </a>
                          <a
                            class="page-link btn btn-info disabled"
                            href="javascript:void(0)"
                            aria-label="Next"
                            awNextStep
                          >
                            Próximo <span aria-hidden="true">»</span>
                          </a>
                        </div>

                        <div
                          class="btn-group btn-group-sm btn-group-toggle pull-left hidden-md-up"
                        >
                          <button
                            type="button"
                            class="btn btn-primary"
                            (click)="onSubmit()"
                          >
                            Salvar
                          </button>
                          <button
                            type="button"
                            class="btn btn-default"
                            (click)="onCancel()"
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </aw-wizard-step>
              </aw-wizard>
            </div>
          </div>
          <div class="form-row" *ngIf="!validCourse">
            <hr />
            <div class="col-md-12">
              <div
                class="alert alert-warning alert-dismissible text-left fade show mt-3"
                role="alert"
                *ngIf="!validCourse"
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
                {{ errorMessage }}
              </div>
              <br />
              <div
                *ngIf="listErrorMessage && !validCourse"
                class="invalid-feedback"
              >
                <div *ngFor="let error of listErrorMessage">{{ error }}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <footer-form
      class="hidden-sm-down"
      [auxSaveAction]="true"
      [auxCancelAction]="true"
      (saveClick)="onSubmit()"
      (cancelClick)="onCancel()"
    ></footer-form>
  </div>
</div>

<ng-template #mdAddCourseRecurrence let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Inclusão de recorrência da turma</h5>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="dismissModal()"
    >
      <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="courseRecurrenceForm"
      novalidate
      class="needs-validation"
    >
      <div class="form-group row mb-2">
        <div class="col-12 mb-2">
          <div class="form-group">
            <label class="form-label" for="teacherId"
              >Professor(a) <span class="text-danger">*</span>
            </label>
            <select
              class="form-control"
              id="teacherId"
              for="teacherId"
              formControlName="teacherId"
              placeholder="Professor(a)"
              [ngClass]="{
                'is-invalid': !validTeacherRec && frec.teacherId.errors
              }"
              (change)="onTeacherRecChange(frec.teacherId.value)"
            >
              <option
                *ngFor="let teacher of teacherRecList"
                [ngValue]="teacher.id"
              >
                {{ teacher.name }}
              </option>
            </select>
            <div
              *ngIf="!validTeacherRec && frec.teacherId.errors"
              class="invalid-feedback"
            >
              <div *ngIf="frec.teacherId.errors.required">
                Por favor, informe o(a) professor(a).
              </div>
            </div>
          </div>
          <div class="form-group row mb-2" *ngIf="flagTeacherRecSelected">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-primary fw-700">
                    Dados do Professor
                  </h5>
                  <div class="form-group row mb-2">
                    <div class="col-8">
                      <label class="form-label">Nome</label>
                      <p class="card-text fw-700">
                        {{ teacherRec.name }}
                      </p>
                    </div>
                    <div class="col-4">
                      <label class="form-label">Localidade</label>
                      <p class="card-text fw-700">
                        {{ teacherRec.cityName }} / {{ teacherRec.stateUf }}
                      </p>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Telefone</label>
                      <p class="card-text fw-700">
                        {{ teacherRec.phone }}
                      </p>
                    </div>
                    <div class="col-6">
                      <label class="form-label">E-mail</label>
                      <p class="card-text fw-700">
                        {{ teacherRec.email }}
                      </p>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Graduação</label>
                      <p class="card-text fw-700">
                        {{ teacherRec.universityGraduate }}
                      </p>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Mais Informações</label>
                      <p class="card-text fw-700">
                        {{ teacherRec.moreInformation }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-2">
          <label class="form-label" for="dayOfWeek"
            >Dia da semana <span class="text-danger">*</span></label
          >
          <select
            class="form-control"
            for="dayOfWeek"
            formControlName="dayOfWeek"
            placeholder="Dia da semana"
            (change)="onRecurrenceDayChange($event.target.value)"
          >
            <option *ngFor="let rec of dayOfWeekList" value="{{ rec.id }}">
              {{ rec.description }}
            </option>
          </select>
        </div>
        <div class="col-12 mb-2">
          <label class="form-label" for="timeStart"
            >Horário de início da aula <span class="text-danger">*</span>
          </label>
          <input
            type="time"
            id="timeStart"
            formControlName="timeStart"
            class="form-control"
            placeholder="Horário de início da aula"
            required
            (change)="onCalcDuration()"
          />
        </div>
        <div class="col-12 mb-2">
          <label class="form-label" for="timeEnd"
            >Horário de término da aula <span class="text-danger">*</span>
          </label>
          <input
            type="time"
            id="timeEnd"
            formControlName="timeEnd"
            class="form-control"
            placeholder="Horário de término da aula"
            required
            (change)="onCalcDuration()"
          />
        </div>
        <div class="col-12 mb-2">
          <label class="form-label" for="duration">Duração da aula</label>
          <input
            id="duration"
            formControlName="duration"
            class="form-control"
            placeholder="Duração da aula"
            disabled="disabled"
          />
        </div>
      </div>
      <div class="form-row" *ngIf="!validCourseRecurrence">
        <hr />
        <div class="col-md-12">
          <div
            class="alert alert-warning alert-dismissible text-left fade show mt-3"
            role="alert"
            *ngIf="!validCourseRecurrence"
          >
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
            {{ errorMessageRecurrence }}
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-success waves-effect waves-themed"
      (click)="submitRecurrence()"
      [disabled]="submittedRecurrence == true"
    >
      <i class="fa fa-check mr-1" *ngIf="!submittedRecurrence"></i>
      <i class="fa fa-spin fa-spinner mr-1" *ngIf="submittedRecurrence"></i>
      Confirmar
    </button>
    <button
      type="button"
      class="btn btn-sm btn-secondary waves-effect waves-themed"
      data-dismiss="modal"
      (click)="cancelRecurrence()"
    >
      <i class="fa fa-times-circle mr-1"></i> Cancelar
    </button>
  </div>
</ng-template>

<ng-template #mdCourseTeacherChange let-c="close" let-d="dismiss">
  <studentclassescourse-teacher-changes
    courseRecurrenceId="{{ courseRecurrenceId }}"
    courseId="{{ courseId }}"
  ></studentclassescourse-teacher-changes>
</ng-template>
