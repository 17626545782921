import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { Observable, of } from 'rxjs';
import { AccountService } from '@app/auth/services';
import { User } from '@app/auth/model';
import { catchError, map } from 'rxjs/operators';
import { TypeResponse } from '@app/shared/enum';
import { default as swal } from 'sweetalert2'
import { environment } from '@environments/environment';
import { MessageQueue } from '../model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class MessageQueueService {
  private route: string = "marketing";
  private controller: string = "messagequeue";
  private endpointPrefix: string;

  user: User;
  public dateNow = new Date();

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private accountService: AccountService,
  ) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
    this.user = this.accountService.getUserData();
  }

  getByPage(userGuid: any, page: number, pageSize: number): Observable<any> {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/user/${userGuid}/page/${page}/pageSize/${pageSize}`);
  }


  getall(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getById(messageQueueId: string): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/${messageQueueId}`);
  }

  getByUserGuid(userGuid: any, onlyNew: boolean = true): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/user/${userGuid}/onlyNew/${onlyNew}`);
  }
  
  remove(id: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.delete<ResponseResult>(`${this.endpointPrefix}/remove`, {params: params});
  }
  
  save(messageQueue: MessageQueue, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/update`, messageQueue);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/add`, messageQueue);
    }
  }

  checkHaveUserMessage() {
    this.user = this.accountService.getUserData();
    var call = this.getByUserGuid(this.user?.userId);

    return call.subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        var list: Array<any> = response.data;

        this.modalService.dismissAll();
        list.forEach(x => {
          swal({
            type: x.type,
            title: 'Notificação',
            html: x.description,
            showConfirmButton: x.needConfirmation
            //timer: 1500
          }).then((result) => {
            if (result.value == true) {
              this.confirmed(x.sidNotification, null);
              return;
            }
          });
        });
      }
    }, (error: any) => {
        console.log(error);
        return { 
            resetSuccess: false,
            message: error
        };
    }), catchError(error => of(error));
  }

  confirmed(notificationId: any, userId: any): any {
    return this.http.patch<ResponseResult>(`${this.endpointPrefix}/confirmed/notification/${notificationId}/userid/${userId}`, null);
  }

  newMessageQueueCount(userGuid: string): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/newMessageQueueCount/${userGuid}`);
  }
}
