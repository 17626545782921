import { CourseService, InitAdminService, StudentCourseService, StudentService } from '@app/admin/services';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService, UtilService } from '@app/shared/services';
import { Course, Student, StudentCourse, StudentCourseAdd } from '@app/admin/model';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { DatePipe, formatDate } from '@angular/common';
import {LOCALE_ID} from '@angular/core';
import { default as swal } from 'sweetalert2'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Options } from 'select2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'studentcourse',
  //providers: [],
  providers: [{
    provide: LOCALE_ID,
    useValue: "pt-BR"
  }, DatePipe],
  templateUrl: './studentcourse.component.html'
})
export class StudentCourseComponent implements OnInit {
  @Input("studentCourseIdentifier") studentCourseIdentifier: any = '';
  @Input("courseId") courseId: number = 0;
  @Input("editMode") editMode: boolean = false;
  public editMode$ = new BehaviorSubject(Boolean);

  public itemInitialValue = null;

  public options: Options = {
    multiple: false,
    closeOnSelect: true,
    width: '300'
  };

  private studentCourseAdd: StudentCourseAdd;
  public studentCourse: StudentCourse;
  public validStudentCourse: boolean;
  public studentCourseForm: any;
  public submitted = false;
  private returnUrl: string;
  private reviewCourseUrl: string;

  public errorMessage = '';
  public listErrorMessage: any[];
  public valueMask: Array<string | RegExp>;
  public dateNow = new Date();

  public student: Student;
  //public studentList: [];
  public studentList: Array<Student> = [];
  public selectStudentList: any[];
  public validStudent: boolean = true;
  public validStudentMessage: string;
  public flagStudentSelected: boolean = false;
  public studentId: number = 0;
  public validStudentListMessage: any[];

  public course: Course;
  public courseList: Array<Course> = [];
  public validCourse: boolean = true;
  public validCourseListMessage: any[];
  public flagCourseSelected: boolean = false;

  public recurrenceList: any = [];
  public recurrenceValue: any;

  public flagCustomPrice: boolean = false;
  public numberOfMonth: number = 1;

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private utilService: UtilService,
    private studentService: StudentService,
    private studentCourseService: StudentCourseService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private toastr: ToastrService,
  ) {
    this.loading.showLoading();
    this.valueMask = [/[0-9]/, /\d/ ,/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    this.onInitForm();
  }
  
  ngOnInit(): void {
    this.editMode = this.editMode.toString() === 'true';
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/studentCourselist';
    this.reviewCourseUrl = '/admin/course';
    this.validCourseListMessage = new Array<string>();

    //this.courseId = params['courseId'];
    if (!(this.courseId > 0)) {
      this.loading.showLoading();
      this.returnUrlList();
    }

    this.courseList = new Array<Course>();
    this.studentList = new Array<Student>();
    this.onLoadCourses(this.courseId);
    
    if (this.studentCourseIdentifier) {
      this.getStudentCourse(this.studentCourseIdentifier);
    } else {
      this.onLoadStudents(this.courseId);
      this.checkAllowNewStudent(this.courseId);
    }

    this.loading.hideLoading();
  }

  onInitForm() {
    this.flagStudentSelected = false;
    this.validStudentCourse = true;
    this.validStudent = true;
    this.studentCourseForm = this.formBuilder.group({
      id: [0],
      createdOn: [''],
      updatedOn: [''],
      studentId: [0, [Validators.required]],
      courseId: [0, [Validators.required]],
      dueDay: [0, [Validators.required]],
      isPriceCustom: [false],
      customPriceValue: [0, [Validators.required]],
      googleDriveLink: [''],
      installmentFirstDue: new FormControl(this.datePipe.transform(Date.now(),"yyyy-MM-dd")),
      startDate: new FormControl(this.datePipe.transform(Date.now(),"yyyy-MM-dd")),
      endDate: new FormControl(this.datePipe.transform(Date.now(),"yyyy-MM-dd"))
    }, {validator: this.checkAndValidation()});
  }

  setFormByEntity(data: any) {
    this.studentCourseForm = this.formBuilder.group({
      id: new FormControl(data.id),
      studentId: new FormControl(data.studentId),
      courseId: new FormControl(data.courseId),
      dueDay: new FormControl(data.dueDay),
      isPriceCustom: new FormControl(data.isPriceCustom),
      customPriceValue: new FormControl(data.customPriceValue),
      googleDriveLink: new FormControl(data.googleDriveLink),
      installmentFirstDue: new FormControl(this.datePipe.transform(data.installmentFirstDue,"yyyy-MM-dd")),
      startDate: new FormControl(this.datePipe.transform(data.startDate,"yyyy-MM-dd")),
      endDate: new FormControl(this.datePipe.transform(data.endDate,"yyyy-MM-dd"))
    }, {validator: this.checkAndValidation()});
    this.course = data.course;
    this.student = data.student;
    this.onLoadStudent(data.studentId);
    this.onMonthCalcChange(null);
  }

  getStudentCourse(studentCourseIdentifier: any) {
    this.studentCourseService.getInfo(studentCourseIdentifier).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.studentCourse = new StudentCourse(response.data);
        this.setFormByEntity(this.studentCourse);
      } else {
        this.studentCourse = null;
      }
      this.loading.hideLoading();
    }, (error: any) => {
      this.loading.hideLoading();
    });
  }

  checkAllowNewStudent(courseId: any) {
    this.loading.showLoading();
    this.courseService.allowNewStudent(courseId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        if (!response.data) {
          this.denyAddNewStudent();
        }
      }
    }, (error: any) => {
      this.denyAddNewStudent();
    });
  }

  denyAddNewStudent() {
    swal({
      type: 'warning',
      title: 'Limite atingido!',
      html: 'Não é permitido adicionar novas matrículas na turma.<br>Selecione outra turma para continuar.',
      showConfirmButton: true,
    }).then(() => {
      this.dismissModal();
      this.returnUrlList();
    });
  }

  checkAndValidation() {
    return (group: FormGroup) => {
       return this.checkValidationStudent() && this.checkValidationCourse() && this.checkValidationDates();
    }
  }

  checkValidationStudent() {
    if (this.f) {
      if (!(this.f.studentId.value > 0)) {
        this.validStudent = false;
        return this.f.studentId.setErrors({invalidValue: true})   
      } else {
        this.validStudent = true;
        return this.f.studentId.setErrors(null);
      }
    }
    return true;
  }

  checkValidationCourse() {
    if (this.f) {
      if (!this.course || !(this.f.courseId.value > 0)) {
        this.validCourse = false;
      } else {
        this.validCourse = true;
      }
      
      if (this.course && (!(this.course.quantityMonth > 0))) {
        this.validCourse = false;
        if (!this.validCourseListMessage.some(e => e === 'A QUANTIDADE DE MESES é inválida.')) {
          this.validCourseListMessage.push('A QUANTIDADE DE MESES é inválida.');
        }
      }
      
      if (this.course && (!(this.course.quantityStudent > 0))) {
        this.validCourse = false;
        if (!this.validCourseListMessage.some(e => e === 'A QUANTIDADE DE ALUNOS é inválida.')) {
          this.validCourseListMessage.push('A QUANTIDADE DE ALUNOS é inválida.');
        }
      }
  
      if (!this.validCourse) {
        return this.f.courseId.setErrors({invalidValue: true});
      } else {
        this.validCourseListMessage = new Array<string>();
        return this.f.courseId.setErrors(null);
      }
    }
    return true;
  }

  checkValidationDates() {
    let datesObj = this.getTotalDates();
    if (datesObj) {
      if (datesObj.checkValue > 0) {
        this.validStudentCourse = true;
        return this.f.endDate.setErrors(null);
      } else {
        this.validStudentCourse = false;
        return this.f.endDate.setErrors({invalidValue: true});
      }
    }

    return true;
  }

  getTotalDates() {
    if (this.f) {
      if (this.f.endDate.value && this.f.startDate.value) {
        let endDate = this.utilService.getOnlyDateDefaultTimeZone(this.f.endDate.value);
        let startDate = this.utilService.getOnlyDateDefaultTimeZone(this.f.startDate.value);
        let checkValue = endDate.getTime() - startDate.getTime();
        let months = this.utilService.DifferenceInMonths(startDate, endDate);
        return {
          startDate,
          endDate,
          months,
          checkValue
        }
      }
    }
    return null;
  }

  onLoadStudents(courseId: any) {
    this.studentCourseService.getStudentsNotInCourseId(courseId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.studentList = response.data;
        this.getInputStudentList(this.studentList);
      } else {
        this.studentList = [];
      }
    });
  }

  getInputStudentList(data: Array<any>) {
    this.selectStudentList = [];
    if (data) {
      var studentList = data.sort(function(a: any, b: any) {
        var textA = a.name.toLowerCase();
        var textB = b.name.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      for (let i = 0; i < studentList.length; i++) {
        let item = {
          id: studentList[i].id.toString(),
          name: this.pad(studentList[i].id, 3) + " | " + studentList[i].name,
        };
        this.selectStudentList.push(item);
      }
    }
  }

  pad(num: any, size: any) {
    if (num > 0) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    }
    return num;
  }

  onStudentChange(value: any) {
    if (value && parseInt(value) > 0) {
      this.studentService.getById(value).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.studentId = parseInt(value);
          this.f.studentId.setValue(parseInt(value));
          this.student = response.data;
          this.flagStudentSelected = true;
        } else {
          this.student = new Student(null);
          this.flagStudentSelected = false;
        }
      });
    }
  }

  onLoadStudent(studentId: any) {
    this.validStudent = true;
    this.validStudentMessage = '';
    this.studentService.getById(studentId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.student = response.data;
        this.studentList.push(this.student);
        //let studentData = [];
        //studentData.push(this.student);
        this.getInputStudentList(this.studentList);
        this.f.studentId.setValue(parseInt(studentId));
        this.flagStudentSelected = true;
      } else {
        this.validStudent = false;
        this.flagStudentSelected = false;
        this.studentList = [];
      }
    }, (error: any) => {
      this.validStudent = false;
      this.validStudentMessage = error.message;
      this.flagStudentSelected = false;
      this.studentList = [];
    });
  }

  onLoadCourses(courseId: any) {
    this.validCourse = true;
    this.validCourseListMessage = new Array<string>();
    this.courseService.getById(courseId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.course = response.data;
        this.courseList.push(this.course);
        this.f.courseId.setValue(parseInt(courseId));
        this.flagCourseSelected = true;
      } else {
        this.validCourse = false;
        this.flagCourseSelected = false;
        this.courseList = new Array<Course>();
      }
    });
  }

  onStartDateChange(target: any) {
    this.autoCalcEndDate(this.course?.quantityMonth ?? 0);
    this.onMonthCalcChange(target);
  }

  autoCalcEndDate(quantityMonth: any = 0) {
    if (this.f.endDate.value && this.f.startDate.value) {
      let startDate = this.utilService.getOnlyDateDefaultTimeZone(this.f.startDate.value);
      let newStartDate = new Date(startDate);
      let endDate = new Date(newStartDate.setMonth(newStartDate.getMonth() + quantityMonth));
      if (endDate) {
        let newEndDateStr = formatDate(endDate, 'yyyy-MM-dd', 'en-US');
        if (newEndDateStr) {
          this.f['endDate'].setValue(newEndDateStr);
          this.toastr.info("Data final foi calculada automaticamente!");
        }
      }
    }
  }

  onEndDateChange(target: any) {
    this.onMonthCalcChange(target);
  }

  onMonthCalcChange(target: any) {
    this.numberOfMonth = this.course?.quantityMonth ?? 1;
    let datesObj = this.getTotalDates();
    if (datesObj) {
      this.numberOfMonth = datesObj.months;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.studentCourseForm ? this.studentCourseForm.controls : null;
  }

  onSubmit() {
    this.submitted = true;
    this.validStudentCourse = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    this.checkValidationDates();

    // stop here if form is invalid
    if (this.studentCourseForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validStudentCourse = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.studentCourseAdd = new StudentCourseAdd(this.studentCourseForm.value);
    if (!this.editMode) {
      this.studentCourseAdd.id = 0
    };

    var endpoint = this.studentCourseService.saveByStudentIdCourseId(
      this.studentCourseAdd.id,
      this.studentCourseAdd,
      this.editMode);

    endpoint.pipe(first()).subscribe((dataReturn: any) => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.validStudentCourse = true;
        this.submitSuccess(this.editMode);
      } else {
        this.validStudentCourse = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (error: any) => {
      this.validStudentCourse = false;
      this.submitted = false;
      this.loading.hideLoading();
      if (error.status == 400) {
        this.errorMessage = 'Erro ao matricular o aluno no curso! Verifique as informações e tente novamente.';
      } else {
        this.errorMessage = error;
      }
    });
  }

  submitSuccess(update: boolean) {
    this.loading.hideLoading();
    this.dismissModal();

    swal({
      type: 'success',
      title: `Matrícula ${update ? 'atualizada' : 'realizada'} com sucesso!`,
      showConfirmButton: false,
      timer: 2200
    }).then((result) => {
      window.location.reload();
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  onCancel(): void {
    this.dismissModal();
    this.returnUrlList();
  }

  onReviewCourse(): void {
    this.router.navigate([this.reviewCourseUrl, { id: this.courseId }]);
  }

  private returnUrlList() {
    this.router.navigate([this.returnUrl, { courseId: this.courseId }]);
  }

  onSetCustomPrice(input: any): void {
    this.flagCustomPrice = input.currentTarget.checked;
  }

  selectEventCourse(item: any) {
    this.course = item;
    this.courseId = item.id;
    this.f.courseId.setValue(parseInt(item.id));
  }

  selectEventStudent(item: any) {
    this.student = item;
    this.studentId = item.id;
    this.f.studentId.setValue(parseInt(item.id));
  }
}