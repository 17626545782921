<div class="modal-header">
    <h5 class="modal-title">Selecione uma turma</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <span role="status" aria-hidden="true" class="spinner-border spinner-border-sm pull-left mr-2 text-primary" *ngIf="flagSearchCourse"></span>
            <span class="text-primary pull-left" *ngIf="flagSearchCourse">Carregando turmas</span>
        </div>
        <div class="col-12">
            <label class="form-label" for="courseId">Turma <span class="text-danger">*</span> </label>
            <br>
            <select class="form-control" id="course" placeholder="Turma" (change)="setCourse($event?.target?.value);" [(ngModel)]="courseId">
                <option *ngFor="let item of courseDataList" [value]="item.id">
                    {{ item.text }}
                </option>
            </select>
            <!-- <ng-select2 class="w-100" [data]="courseDataList" [width]="150" style="z-index: 1000;"
                (valueChanged)="setCourse($event);" [options]="options"></ng-select2> -->
            <div *ngIf="!validCourse && errors" class="invalid-feedback">
                <div *ngFor="let e of errors">{{e}}.</div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row" *ngIf="courseId > 0">
        <div class="col-12 d-flex">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{courseData.name}}</h5>
                    <p class="card-text">Professor: {{courseData.teacher.name}}</p>
                    <p class="card-text">Pacote: {{courseData.product.name}}</p>
                    <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="cancel()">
        <i class="fa fa-times-circle mr-1"></i> Cancelar
    </button>
    <button type="button" class="btn btn-sm btn-success waves-effect waves-themed" (click)="done()">
        <i class="fa fa-check mr-1"></i> Confirmar
    </button>
</div>
