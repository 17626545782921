import { Base } from '@app/shared/model';

export class State extends Base {
    name: string;
    uf: string;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.uf = contentResponse.uf;
        this.name = contentResponse.name;
    }
}