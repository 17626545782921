import { Component, OnInit } from '@angular/core';
import { User } from '@app/auth/model';
import { AccountService, InitAuthService } from '@app/auth/services';
import { MessageService } from '@app/shared/services';

@Component({
  selector: 'app-module-change',
  templateUrl: './module-change.component.html',
  styleUrls: ['./module-change.component.css'],
})
export class ModuleChangeComponent implements OnInit {
  user: User;

  constructor(
    private accountService: AccountService,
    private loading: MessageService,
    private initAuth: InitAuthService
  ) {
    this.user = this.accountService.getUserData();
    this.initAuth.setConfigPage();
  }

  ngOnInit(): void {}

  logout() {
    this.loading.showLoading();
    this.accountService.logout();
    this.loading.hideLoading();
  }
}
