import { Base } from '@app/shared/model';

export class Calendar extends Base {
    day: number;
    month: number;
    year: number;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.day = parseInt(contentResponse.day);
        this.month = parseInt(contentResponse.month);
        this.year = parseInt(contentResponse.year);
    }
}
