import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentEpisode, ContentSerie } from '../model';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private route: string = "content";
  private controller: string = "content";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getSeries(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/series`);
  }

  getEpisodesBySerie(serieId: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/episodesBySerie/${serieId}`);
  }

  remove(id: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.delete<ResponseResult>(`${environment.urlAPI}/admin/course/remove`, {params: params});
  }
  
  saveSerie(serie: ContentSerie, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/updateSerie`, serie);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/addSerie`, serie);
    }
  }

  saveEpisode(episode: ContentEpisode, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/updateEpisode`, episode);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/addEpisode`, episode);
    }
  }

  addComment(comment: Comment): any {
    return this.http.post<ResponseResult>(`${this.endpointPrefix}/addComment`, comment);
  }
}
