import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BankService } from '@app/admin/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';
import { InvoiceService } from '@app/admin/services/invoice.service';
import { default as swal } from 'sweetalert2'

@Component({ templateUrl: 'invoice-list.component.html', providers: [DatePipe] })
export class InvoiceListComponent implements OnInit {

  public invoices: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public columns: Array<any> = [
    { title: "Código", name: "id", sort: "asc", className: "text-center" },
    {
      title: "Criado em",
      name: "createdOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    },
    {
      title: "Modificado em",
      name: "updatedOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    }
  ];
  
  constructor(
    private initAdmin: InitAdminService,
    private invoiceService: InvoiceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private loading: MessageService
  ) { }

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadBanks();
  }

  loadBanks() {
    this.loading.showLoading();
    this.invoiceService.getall()
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.invoices = response.data;
        this.showTable = true;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
        this.showTable = false;
      }
      this.loading.hideLoading();
    },
    error => {
      this.loading.hideLoading();
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar os dados das notas.';
    });
  }

  delete(id): void {
    swal({
      title: "Deseja realmente excluir este registro?",
      text: "Ao clicar ok, este será excluído permanentemente!",
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true     
    })
    .then((willDelete)=> {
      if(willDelete.value === true) {
        // this.bankService.excluirRegion(idRegion['id']).subscribe(
        //   response => {      
        //     if ("typeResponse" in response && response['typeResponse'] === TypeResponse.Success) {
        //       let res = this.translate.instant('exclusao');
        //       this.toastr.success(res, "Mensagem do sistema!");
        //     } else 
        //     if ("typeResponse" in response && response['typeResponse'] === TypeResponse.Alert) {
        //       this.toastr.warning(response['message'], "Mensagem do sistema!");            
        //     }else 
        //     if ("typeResponse" in response && response['typeResponse'] === TypeResponse.Error) {
        //       let res = this.translate.instant('erroOperacao');           
        //       this.toastr.error(res, "Mensagem do sistema!");
        //     }
        //     this.loadRegion();
        //   }, error => {   
        //     if (error.error.error === 'invalid_token') {
        //       this.router.navigate(['/usomutuo/login']);           
        //     } else {    
        //       let res = this.translate.instant('erroOperacao');
        //       this.toastr.error(res, "Mensagem do sistema!");
        //     }
        //     this.loading.hideLoading();
        //   }      
        // )
      }
    });
  }

  edit(row:any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/invoice', { id: row.id }]);
  }
}
