// Angular Components
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { TextMaskModule } from '@app/_helpers/mask/text.mask';
import { SharedModule } from '@app/shared/shared.module';
import { StudentRoutingRoutes } from './student-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
//import { NgSelect2Module } from 'ng-select2';

// Components
import { HomeComponent } from './components/home/home.component';
import { CourseComponent } from './components/course/course.component';
import { ContentListComponent } from './components/content/content-list/content-list.component';
import { ContentViewComponent } from './components/content/content-view/content-view.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { UpdateComponent } from './components/update/update.component';
import { NpsComponent } from './nps/nps.component';
import { WritingTestListComponent } from './components/writing-test/writing-test-list/writing-test-list.component';
import { WritingTestSolveComponent } from './components/writing-test/writing-test-solve/writing-test-solve.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { WritingTestApplyStudentComponent } from './components/writing-test/writing-test-apply-student/writing-test-apply-student.component';
import { SafePipe } from '@app/_helpers/safe/safe.pipe';
import { ContentModule } from '@app/content/content.module';
import { JitsiService } from '@app/content/services';
import { ContentViewStreamComponent } from './components/content/content-view-stream/content-view-stream.component';
import { CalendarAsyncComponent } from './components/calendarasync/calendarasync.component';
import { ProfileComponent } from './components/profile/profile.component';
import { JitsiMeetingComponent } from './components/jitsi-meeting/jitsi-meeting.component';

@NgModule({
  declarations: [
    HomeComponent,
    CourseComponent,
    ContentListComponent,
    ContentViewComponent,
    CalendarComponent,
    CalendarAsyncComponent,
    UpdateComponent,
    NpsComponent,
    WritingTestListComponent,
    WritingTestSolveComponent,
    WritingTestApplyStudentComponent,
    ContentViewStreamComponent,
    ProfileComponent,
    JitsiMeetingComponent,
  ],
  imports: [
    ContentModule,
    ChartsModule,
    TextMaskModule,
    ArchwizardModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    StudentRoutingRoutes,
    PdfViewerModule,
    //NgSelect2Module,
    NgxSummernoteModule,
    FullCalendarModule, // for FullCalendar!
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe, SafePipe, JitsiService],
})
export class StudentModule {
  stepDefault: any = 0;
}
