import { City, State } from "@app/admin/model";
import { User } from "@app/auth/model";

export class Profile {
    studentId: number;
    name: string;
    birthDate: Date;

    email: string;
    phone: string;
    cpf: string;
    zipCode: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    
    cityId: number;
    cityName: string;
    city: City;
    
    stateId: number;
    stateUf: string;
    state: State;

    country: string;

    userId: number;
    user: User;

    foreignPerson: boolean;
    
    constructor(contentResponse: any) {
        if (contentResponse) {
            this.studentId = contentResponse.studentId;
            this.name = contentResponse.name;
            this.birthDate = new Date(contentResponse.birthDate),

            this.email = contentResponse.email;
            this.phone = contentResponse.phone;
            this.cpf = contentResponse.cpf;
            this.zipCode = contentResponse.zipCode;
            this.street = contentResponse.street;
            this.number = contentResponse.number;
            this.complement = contentResponse.complement;
            this.neighborhood = contentResponse.neighborhood;

            this.stateId = parseInt(contentResponse.stateId);
            this.stateUf = contentResponse.stateUf;
            if (contentResponse.stateId > 0 && contentResponse.state) {
                this.state = contentResponse.state;
            }
    
            this.cityId = parseInt(contentResponse.cityId);
            this.cityName = contentResponse.cityName;
            if (contentResponse.cityId > 0 && contentResponse.city) {
                this.state = contentResponse.state;
            }

            this.country = contentResponse.country;
            this.foreignPerson = contentResponse.foreignPerson;

            this.userId = parseInt(contentResponse.userId);
            if (contentResponse.userId > 0 && contentResponse.user) {
                this.user = contentResponse.user;
            }
        }
    }
}
