import { TeacherService, WritingTestApplyService, WritingTestService } from '@app/admin/services';
import { Course, StudentClassesCourse, Teacher, WritingTest } from '@app/admin/model';
import * as _enum from '@app/shared/enum';
import { Component, Input, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '@app/shared/services';
//import { Select2OptionData } from 'ng-select2';
import { first } from 'rxjs/operators';
import { Options } from 'select2';

@Component({
  selector: 'writing-test-list-applied',
  templateUrl: './writing-test-list-applied.component.html',
  styleUrls: ['./writing-test-list-applied.component.css']
})
export class WritingTestListAppliedComponent implements OnInit {
  @Input("writingTestId") writingTestId: any;
  @Input("teacherAppliedId") teacherAppliedId: any;

  public showAppliedReview: boolean = false;
  public writingTest: WritingTest;
  public writingTestName = '';
  public teacherApplied: Teacher;
  public teacherAppliedName = '';
  public course: Course;
  public studentClassesCourse: StudentClassesCourse;

  public returnMessage: string = '';
  public options: Options;
  public errorMessage: string;
  public validSave: boolean = true;
  public editMode: boolean = false;
  public showTable: boolean = false;
  public listErrorMessage: any[];
  
  closeResult: string;

  public writingTestApplyPreviewMode = false;
  public flagSearchWritingTest = false;
  public validWritingTest: boolean = true
  public targetWritingTestId: any;
  public writingTestAppliedList: any = [];
  public writingTestAppliedDataList: any = new Array<any>();

  constructor(
    private writingTestApplyService: WritingTestApplyService,
    private writingTestService: WritingTestService,
    private teacherService: TeacherService,
    private modalService: NgbModal,
    private loading: MessageService) {
  }

  ngOnInit(): void {
    this.options = {
      width: '300'
    };
    this.showTable = false;
    this.showAppliedReview = false;
    this.getWritingTest(this.writingTestId);
    this.loadTeacher(this.teacherAppliedId);
    this.getWritingTestListApplied(this.writingTestId, this.teacherAppliedId);
  }

  getWritingTestListApplied(writingTestId: any, teacherAppliedId: any) {
    this.loading.showLoading();
    this.writingTestApplyService.getByWritingTestIdAndTeacherAppliedId(writingTestId, teacherAppliedId).subscribe((response: any) => {
      if (response.typeResponse == _enum.TypeResponse.Success) {
        this.writingTestAppliedList = this.getDataItems(response.data);
        this.showTable = true;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
        this.showTable = false;
      }
      this.loading.hideLoading();
    }, (error: any) => {
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar os testes disponíveis.';
      this.loading.hideLoading();
    });
  }

  getCardName(wt: any, index: number) {
    return "js-" + wt.order + index;
  }

  loadTeacher(id: any) {
    this.teacherService.get(id).subscribe((response: any) => {
      if (response.typeResponse == _enum.TypeResponse.Success) {
        this.teacherApplied = response.data;
        this.teacherAppliedName = this.teacherApplied.name;
      }
    return null;
    }, (error: any) => {
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar os dados do(a) professor(a).';
      this.loading.hideLoading();
      return null;
    });
  }

  getWritingTest(id: any) {
    this.writingTestService.getById(id).pipe(first()).subscribe((response: any) => {
      this.writingTest = response.data;
      this.writingTestName = this.writingTest.name;
    }, (error: any) => {
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar os dados do teste.';
      this.loading.hideLoading();
      return null;
    });
  }

  getDataItems(lista: any[]) {
    lista.forEach(function(e) {
      const avgScoreCss = _enum.AvgScoreCss.get(e.avgScore);
      const avgScoreStr = _enum.AvgScoreLabel.get(e.avgScore);
      e.designAvgScore = "<span class='badge badge-pill w-100 font-weight-bold "
        + avgScoreCss +"'>" + avgScoreStr +"</span>";
      
      let writingTestStatusCss = _enum.WritingTestStatusCss.get(e.writingTestStatus);
      let writingTestStatusStr = _enum.WritingTestStatusLabel.get(e.writingTestStatus);
      e.designWritingTestStatus = "<span class='badge badge-pill w-100 font-weight-bold "
        + writingTestStatusCss +"'>" + writingTestStatusStr +"</span>";

      let writingTestReviewCss = _enum.WritingTestReviewCss.get(e.writingTestReview);
      let writingTestReviewLabel = _enum.WritingTestReviewLabel.get(e.writingTestReview);
      e.designWritingTestReview = "<span class='badge badge-pill w-100 font-weight-bold "
        + writingTestReviewCss +"'>" + writingTestReviewLabel +"</span>";

      e.writingTestReviewShow = e.writingTestStatus == _enum.WritingTestStatus.Done;
    });
    return lista;
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      console.log(reason);
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }

  writingTestAppliedReview(row: any, content: any) {
    this.targetWritingTestId = row.id;
    this.writingTestApplyPreviewMode = row.writingTestStatus == _enum.WritingTestStatus.Done
    this.modalService.dismissAll();
    this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
