import { AuditEventType } from '@app/shared/enum';
import { formatDate } from '@angular/common';
import { User } from '@app/auth/model';

export class UserAudit {
    id: number;
    
    user: User;
    userId: number;

    timestamp: Date;
    auditEvent: AuditEventType;
    ipAddress: string;

    constructor(contentResponse: any) {
        this.id = parseInt(contentResponse.id);

        this.userId = parseInt(contentResponse.userId);
        if (contentResponse && contentResponse.userId > 0) {
            this.user = contentResponse.user;
        }
        
        if (contentResponse.timestamp) {
            this.timestamp = new Date(formatDate(contentResponse.timestamp, 'yyyy-MM-dd', 'en-US'));
        } else {
            this.timestamp = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        }

        this.auditEvent = contentResponse.auditEvent;
        this.ipAddress = contentResponse.ipAddress;
    }
}
