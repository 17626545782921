<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Testes de Escrita</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div class="row">
    <div class="col-xl-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>Lista de Testes de Escrita</h2>
                <div class="panel-toolbar ml-2">
                    <button type="button" class="btn btn-success btn-xs" data-toggle="tooltip" title="Nova Avaliação Writing Test" [routerLink]="['/admin/writingtest']">Novo</button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="col-sm-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-sm-12" *ngIf="showTable">
                        <managed-table [dataTitle]="" [rows]="dataList" [columns]="columns" [hasActions]="true" [hasFilter]="true" [ngTableFiltering]="true"
                            [auxEnable]="true" [auxIcon]="iconContentHtml" [titleAux]="titleContentHtml" (auxClick)="viewContentHtml($event, mdContentHtmlWritingTest)"
                            [previewEnable]="false" (previewClick)="preview($event)"
                            [deleteEnable]="true" (deleteClick)="delete($event)"
                            [editEnable]="true" (editClick)="edit($event)">
                        </managed-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #mdContentHtmlWritingTest let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h3 class="modal-title">Visualização do teste de escrita</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <div [innerHTML]="contentHtmlView | safe: 'html'"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Close</button>
    </div>
</ng-template>
