export enum PaymentStatus {
  Pending = 0,
  Canceled = 1,
  Completed = 2,
  OnDay = 3,
  Processing = 4,
  Refunded = 5
}

export const PaymentStatusLabel = new Map<number, string>([
  [PaymentStatus.Pending, 'Pendente'],
  [PaymentStatus.Canceled, 'Cancelado'],
  [PaymentStatus.Completed, 'Quitado'],
  [PaymentStatus.OnDay, 'Em dia'],
  [PaymentStatus.Processing, 'Processando'],
  [PaymentStatus.Refunded, 'Reembolsado']
]);

export const PaymentStatusLabelEn = new Map<number, string>([
  [PaymentStatus.Pending, 'Pending'],
  [PaymentStatus.Canceled, 'Canceled'],
  [PaymentStatus.Completed, 'Completed'],
  [PaymentStatus.OnDay, 'On day'],
  [PaymentStatus.Processing, 'Processing'],
  [PaymentStatus.Refunded, 'Refunded']
]);

export const PaymentStatusCss = new Map<number, string>([
  [PaymentStatus.Pending, 'payment-pending'],
  [PaymentStatus.Canceled, 'payment-canceled'],
  [PaymentStatus.Completed, 'payment-completed'],
  [PaymentStatus.OnDay, 'payment-onday'],
  [PaymentStatus.Processing, 'payment-processing'],
  [PaymentStatus.Refunded, 'payment-refunded']
]);
