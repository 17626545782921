import { ModuleType } from './../shared/enum/ModuleType';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Helpers
import { AuthGuard } from '../auth/helpers/auth.guard';

//Components
import { TeacherCalendarComponent } from './components/teacherCalendar/teacherCalendar.component';

import { ProfileComponent } from './components/profile/profile.component';
import { HomeComponent } from './components/home/home.component';

import { StudentListComponent } from './components/student/student-list/student-list.component';
import { StudentComponent } from './components/student/student/student.component';

import { BankListComponent } from './components/bank/bank-list/bank-list.component';
import { BankComponent } from './components/bank/bank/bank.component';

import { CourseListComponent } from './components/course/course-list/course-list.component';
import { CourseComponent } from './components/course/course/course.component';

import { CheckListListComponent } from './components/checkList/checkList-list/checkList-list.component';
import { CheckListComponent } from './components/checkList/checkList/checkList.component';

import { DepartmentListComponent } from './components/department/department-list/department-list.component';
import { DepartmentComponent } from './components/department/department/department.component';

import { TeacherListComponent } from './components/teacher/teacher-list/teacher-list.component';
import { TeacherAvailabilityComponent } from './components/teacher/teacher-availability/teacher-availability.component';
import { TeacherComponent } from './components/teacher/teacher/teacher.component';

import { UserListComponent } from './components/user/user-list/user-list.component';
import { UserComponent } from './components/user/user/user.component';

import { GradesListComponent } from './components/grades/grades-list/grades-list.component';
import { GradesComponent } from './components/grades/grades/grades.component';

import { InvoiceComponent } from './components/invoice/invoice/invoice.component';
import { InvoiceListComponent } from './components/invoice/invoice-list/invoice-list.component';

import { ProductComponent } from './components/product/product/product.component';
import { ProductListComponent } from './components/product/product-list/product-list.component';

import { RecipeComponent } from './components/recipe/recipe/recipe.component';
import { RecipeListComponent } from './components/recipe/recipe-list/recipe-list.component';

import { SupplierListComponent } from './components/supplier/supplier-list/supplier-list.component';
import { SupplierComponent } from './components/supplier/supplier/supplier.component';

import { StudentCourseComponent } from './components/studentCourse/studentcourse/studentcourse.component';
import { StudentCourseListComponent } from './components/studentCourse/studentcourse-list/studentcourse-list.component';

import { StudentCourseInfoComponent } from './components/studentCourse/studentcourse-info/studentcourse-info.component';
import { CourseAvailablePositionsComponent } from './components/course/course-available-positions/course-available-positions.component';
import { StudentClassesCourseListComponent } from './components/studentClassesCourse/studentclassescourse-list/studentclassescourse-list.component';
import { StudentClassesCourseAuditingChangesComponent } from './components/studentClassesCourse/studentclassescourse-auditing-changes/studentclassescourse-auditing-changes.component';
import { StudentclassescourseTeacherChangesComponent } from './components/studentClassesCourse/studentclassescourse-teacher-changes/studentclassescourse-teacher-changes.component';
import { WritingTestListComponent } from './components/writing-test/writing-test-list/writing-test-list.component';
import { WritingTestComponent } from './components/writing-test/writing-test/writing-test.component';
import { StudentCalendarComponent } from './components/studentCalendar/studentCalendar.component';
import { WritingTestAppliedListComponent } from './components/writing-test/writing-test-applied-list/writing-test-applied-list.component';

const routes: Routes = [
  {
    path: 'banklist',
    component: BankListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0FI3BC8', title: 'Bancos' },
  },
  {
    path: 'bank',
    component: BankComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0FI3BC8', title: 'Banco' },
  },

  {
    path: 'teacherCalendar',
    component: TeacherCalendarComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: '', title: 'Teacher Calendar' },
  },
  {
    path: 'studentCalendar',
    component: StudentCalendarComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: '', title: 'Student Calendar' },
  },

  {
    path: 'checkListlist',
    component: CheckListListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2CU3', title: 'Check-List' },
  },
  {
    path: 'checkList',
    component: CheckListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2CU3', title: 'Check-List' },
  },

  {
    path: 'courselist',
    component: CourseListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2CU3', title: 'Cursos' },
  },
  {
    path: 'course',
    component: CourseComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2CU3', title: 'Curso' },
  },
  {
    path: 'courseavailablepositions',
    component: CourseAvailablePositionsComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2CU3',
      title: 'Relatório posições disponíveis',
    },
  },

  {
    path: 'departmentlist',
    component: DepartmentListComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2DP4',
      title: 'Departamentos',
    },
  },
  {
    path: 'department',
    component: DepartmentComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2DP4',
      title: 'Departamento',
    },
  },

  {
    path: 'gradeslist',
    component: GradesListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2AL33', title: 'Notas' },
  },
  {
    path: 'grades',
    component: GradesComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2AL33', title: 'Nota' },
  },

  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM1DA1DA1', title: 'Home' },
  },

  {
    path: 'invoicelist',
    component: InvoiceListComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0FI3NF10',
      title: 'Notas Fiscais',
    },
  },
  {
    path: 'invoice',
    component: InvoiceComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0FI3NF10',
      title: 'Nota Fiscal',
    },
  },

  {
    path: 'packagelist',
    component: ProductListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2PR6', title: 'Pacotes' },
  },
  {
    path: 'package',
    component: ProductComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2PR6', title: 'Pacotes' },
  },

  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: '', title: 'Perfil' },
  },

  {
    path: 'recipelist',
    component: RecipeListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0FI3RC11', title: 'Recibos' },
  },
  {
    path: 'recipe',
    component: RecipeComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0FI3RC11', title: 'Recibo' },
  },

  {
    path: 'studentlist',
    component: StudentListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2AL2', title: 'Alunos' },
  },
  {
    path: 'student',
    component: StudentComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2AL2', title: 'Aluno' },
  },

  {
    path: 'supplierlist',
    component: SupplierListComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2FN5',
      title: 'Fornecedores',
    },
  },
  {
    path: 'supplier',
    component: SupplierComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2FN5', title: 'Fornecedor' },
  },

  {
    path: 'teacheravailability',
    component: TeacherAvailabilityComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2PF7',
      title: 'Disponibilidade',
    },
  },
  {
    path: 'teacherlist',
    component: TeacherListComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2PF7',
      title: 'Professores',
    },
  },
  {
    path: 'teacher',
    component: TeacherComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2PF7', title: 'Professor' },
  },

  {
    path: 'userlist',
    component: UserListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0SG5US17', title: 'Usuários' },
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0SG5US17', title: 'Usuário' },
  },

  {
    path: 'studentCourselist',
    component: StudentCourseListComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2AL2',
      title: 'Alunos do curso',
    },
  },
  {
    path: 'studentCourseInfo',
    component: StudentCourseInfoComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2AL2',
      title: 'Informações da Matrícula',
    },
  },
  {
    path: 'studentCourse',
    component: StudentCourseComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2AL2',
      title: 'Alunos do curso',
    },
  },

  {
    path: 'studentClassesCourseList',
    component: StudentClassesCourseListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Admin, role: 'ADM0AD2AL2', title: 'Aulas' },
  },
  {
    path: 'studentClassesCourseAuditingChanges',
    component: StudentClassesCourseAuditingChangesComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2AL2',
      title: 'Alterações recentes',
    },
  },
  {
    path: 'studentClassesCourseTeacherChanges',
    component: StudentclassescourseTeacherChangesComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2AL2',
      title: 'Troca de professor',
    },
  },

  {
    path: 'writingtestlist',
    component: WritingTestListComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2PR6',
      title: 'Lista Writing Tests',
    },
  },
  {
    path: 'writingtest',
    component: WritingTestComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2PR6',
      title: 'Writing Test',
    },
  },
  {
    path: 'writingtestappliedlist',
    component: WritingTestAppliedListComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Admin,
      role: 'ADM0AD2PR6',
      title: 'Lista Writing Tests Aplicados pelo Teacher',
    },
  },

  //{ path: 'contentSerielist', component: ContentSerieListComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AD2CT34', title: 'Séries' } },
  //{ path: 'contentSerie', component: ContentSerieComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AD2CT34', title: 'Série' } },

  //{ path: 'contentEpisodiolist', component: ContentEpisodeListComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AD2CT34', title: 'Episódios' } },
  //{ path: 'contentEpisodio', component: ContentEpisodeComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AD2CT34', title: 'Episódio' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
