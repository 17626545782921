<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Turmas</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>

<h4>Legenda & Cores</h4>
<div class="subheader">
    <div class="subheader-block d-lg-flex align-items-center">
        <div class="flex-column justify-content-center mr-3">
            <a href="javascript:void(0);" class="btn btn-sm btn-info btn-icon position-relative js-waves-off">
                <i class="fal fa-pencil fs-md"></i>
            </a>
            Editar
        </div>
    </div>
    <div class="subheader-block d-lg-flex align-items-center">
        <div class="flex-column justify-content-center mr-3">
            <a href="javascript:void(0);" class="btn btn-sm btn-danger btn-icon position-relative js-waves-off">
                <i class="fal fa-times fs-md"></i>
            </a>
            Excluir
        </div>
    </div>
    <div class="subheader-block d-lg-flex align-items-center">
        <div class="flex-column justify-content-center mr-3">
            <a href="javascript:void(0);" class="btn btn-sm btn-primary btn-icon position-relative js-waves-off">
                <i class="fa fa-users fs-md"></i>
            </a>
            Alunos
        </div>
    </div>
    <div class="subheader-block d-lg-flex align-items-center">
        <div class="flex-column justify-content-center mr-3">
            <a href="javascript:void(0);" class="btn btn-sm btn-dark btn-icon position-relative js-waves-off">
                <i class="fa fa-video fs-md"></i>
            </a>
            Sala de aula ao vivo
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>Lista de Turmas</h2>
                <div class="panel-toolbar ml-2">
                    <button type="button" class="btn btn-success btn-xs" data-toggle="tooltip" title="Nova Turma" [routerLink]="['/admin/course']">Novo</button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="col-sm-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-sm-12" *ngIf="showTable">
                        <managed-table [dataTitle]="" [rows]="gridList" [columns]="columns" [sorting]="true" [hasActions]="true" [ngTableFiltering]="true" [showFilterRow]="false"
                            [previewEnable]="false" [editEnable]="true" [deleteEnable]="true" (editClick)="edit($event)" (deleteClick)="delete($event)" [hasFilter]="true"
                            [auxEnable]="true" [auxIcon]="auxIcon" [titleAux]="auxTitle" (auxClick)="manageStudents($event)">
                        </managed-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <ng-template #mdAddStudentCourse let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h5 class="modal-title">Incluir aluno na turma</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body">
                <studentcourse-add courseId="{{courseId}}"></studentcourse-add>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Close</button>
            </div>
        </ng-template>

        <ng-template #mdStudentCourseOpenClassRoom let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h5 class="modal-title">Sala de aula ao Vivo</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
                    <span aria-hidden="true"><i class="fa fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body">
                <app-classroom courseData={{courseData}}></app-classroom>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">
                    <i class="fa fa-times-circle mr-1"></i> Sair
                </button>
            </div>
        </ng-template>

        <ng-template #mdCourseTeacherChange let-c="close" let-d="dismiss">
            <studentclassescourse-teacher-changes courseId="{{courseId}}"></studentclassescourse-teacher-changes>
        </ng-template> 
    </div>
</div>
