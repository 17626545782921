import { TeacherService } from '@app/admin/services';
import { Injectable } from '@angular/core';
import pageSettings from '@helpers/page-settings';
import { User } from '@app/auth/model';
import { AccountService } from '@app/auth/services';
import { Teacher } from '@app/admin/model';
import { first } from 'rxjs/operators';
import { MessageService } from '@app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class InitTeacherService {
  pageSettings = pageSettings;
  returnUrl: string;
  public teacher: Teacher;
  public user: User = new User();
  public checkUpdateOk = true;
  private userRoles: Array<any>;

  constructor(
    private messageService: MessageService,
    private accountService: AccountService,
    private teacherService: TeacherService
  ) {
    this.setConfigPage();
    this.user = this.accountService.getUserData();
    this.userRoles = this.accountService.getRolesByUser();
  }

  async setConfigPage(
    update: boolean = false,
    showHubSpot: boolean = true,
    pageEmpty: boolean = false
  ) {
    this.pageSettings.pageEmpty = pageEmpty;
    this.pageSettings.pageSidebar = !update;
    this.pageSettings.pageWithoutFooter = true;
    this.pageSettings.pageContentBrandGradient = false;
    this.pageSettings.pageContentBgTransparent = false;

    this.user = this.accountService.getUserData();

    if (this.user !== null && this.user?.id > 0) {
      await this.getTeacherByUser();
    }

    this.messageService.displayHubSpot(showHubSpot);
  }

  getTeacherUser(): any {
    this.user = this.accountService?.getUserData();
    return this.teacherService?.getByUserId(this.user?.id);
  }

  async getTeacherByUser() {
    this.user = this.accountService.getUserData();
    this.teacherService
      ?.getByUserId(this.user?.id)
      .pipe(first())
      .subscribe(
        (response: Teacher) => {
          this.teacher = response;
        },
        (error: any) => {
          console.log(error);
          return null;
        }
      );
  }

  public CheckHavePermission(role: any): boolean {
    if (this.userRoles) {
      if (role && this.userRoles != null && this.userRoles.length > 0) {
        return this.userRoles.some((x) => x.keyDefault == role);
      } else {
        return false;
      }
    } else {
      this.userRoles = this.accountService.getRolesByUser();
    }
  }
}
