import { ProfileService, InitAdminService } from '@app/admin/services';
import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertService, MessageService } from '@app/shared/services';
import { first } from 'rxjs/operators';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { Profile } from '@app/admin/model';
import { User } from '@app/auth/model';
import { AccountService } from '@app/auth/services';

@Component({ templateUrl: 'profile.component.html' })
export class ProfileComponent implements OnInit {
  submitted = false;
  public errorMessage = '';
  public listErrorMessage: any[];

  entityForm;
  public editEntity: boolean;
  public validEntity: boolean;
  public entityId: number;
  public user: User = new User();
  
  // :: Change Here ::
  private profile: Profile;
  returnUrl: string = '/admin/home';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private profileService: ProfileService,
    private accountService: AccountService) {
  }
  
  // :: Generic ::
  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.user = this.accountService.getUserData();
    
    this.onInitForm();

    this.activatedRoute.params.subscribe(params => {
      this.entityId = params['id'];
      if (this.entityId > 0) {
        this.editEntity = true;
        this.loadEntityById(this.entityId);
      } else {
        this.editEntity = false;
      }
    });

    // Check phone valid
    this.onSetEventsForm();

    this.loading.hideLoading();
  }

  // :: Generic ::
  get f() {
    return this.entityForm.controls;
  }

  // :: Generic ::
  onSetEventsForm() {
  }

  // :: Generic ::
  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  // :: Generic ::
  validAfterSubmit(dataReturn) {
    if (dataReturn.typeResponse == TypeResponse.Success) {
      this.validEntity = true;
      this.router.navigate([this.returnUrl]);
    } else {
      this.validEntity = false;
      this.errorMessage = dataReturn.message;
      if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
        this.listErrorMessage = [];
        for (var i = 0; i <= dataReturn.data.errors.length; i++) {
          this.listErrorMessage.push(dataReturn.data.errors[i].Description);
        }
      }
    }
    this.submitted = false;
    this.loading.hideLoading();
  }

  onInitForm() {
    this.validEntity = true;
    this.entityForm = this.formBuilder.group({
      id: [0],
      imageName: ['', [Validators.required]],
      createdOn: [''],
      updatedOn: ['']
    });
  }

  loadEntityById(id) {
    this.loading.showLoading();
    this.profileService.getById(id)
    .pipe(first())
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validEntity = true;
        this.entityForm = this.formBuilder.group({
          id: new FormControl(response.data.id),
          imageName: new FormControl(response.data.imageName),
          createdOn: new FormControl(response.data.createdOn),
          updatedOn: new FormControl(response.data.updatedOn)
        });
      } else {
        this.validEntity = true;
      }
      this.loading.hideLoading();
      }, error => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
      }
    );
  }

  onSubmit() {
    this.router.navigate([this.returnUrl]);
  }
/*
  onSubmit() {
    this.loading.showLoading();
    this.submitted = true;
    this.validEntity = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.entityForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validEntity = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.profile = new Profile(this.entityForm.value);
    
    this.profileService.save(this.profile, this.editEntity)
    .pipe(first())
    .subscribe(dataReturn => {
      this.validAfterSubmit(dataReturn);
    }, error => {
      this.validEntity = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = error;
    });
  }
*/  
}