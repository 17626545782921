import { Component, Input, OnInit } from '@angular/core';
import { SerieContent } from '@app/shared/model/serieContent';

@Component({
  selector: 'content-serie-list',
  templateUrl: './serie-list.component.html',
  styleUrls: ['./serie-list.component.css']
})
export class SerieListComponent implements OnInit {
  @Input() contact: SerieContent;

  constructor() { }

  ngOnInit(): void {
  }
}
