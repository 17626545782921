import { Base } from '@app/shared/model';

export class SourceCrm extends Base {
    name: string;

    constructor(contentResponse: any) {
        super(contentResponse);
        this.name = contentResponse.name;
    }
}
