import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'content-serie-item',
  templateUrl: './serie-item.component.html',
  styleUrls: ['./serie-item.component.css']
})
export class SerieItemComponent implements OnInit {
  @Input() contact: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
