<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Relatório de aulas confirmadas'</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>

<div class="row">
    <div class="col-md-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr">
                <h2>Filtro</h2>
                <!-- <div class="panel-toolbar ml-2">
                    <button class="btn btn-sm btn-primary" (onclick)="getConfirmedClasses()">Filtrar</button>
                </div> -->
            </div>
            <div class="panel-container show">
                <div class="panel-content p-0">
                    <div class="panel-content">
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <span role="status" aria-hidden="true" class="spinner-border spinner-border-sm pull-left mr-2 text-primary" *ngIf="flagSearchTeacher"></span>
                                <span class="text-primary pull-left" *ngIf="flagSearchTeacher">Carregando professores</span>
                                <label class="form-label" for="teacherId">Professor(a) <span class="text-danger">*</span> </label>
                                <br>
                                <select class="form-control" id="teacherId" placeholder="Teacher" (change)="setTeacher($event?.target?.value);" [(ngModel)]="teacherId">
                                    <option *ngFor="let item of teacherDataList" [value]="item.id">
                                        {{ item.text }}
                                    </option>
                                </select>

                                <!-- <ng-select2 class="w-100" [data]="teacherDataList" [width]="150" style="z-index: 1000;" (valueChanged)="setTeacher($event);" [options]="options"></ng-select2> -->
                                <!--<select class="select2 form-control w-100" id="teacherId" placeholder="Professor" [(ngModel)]="teacherId" (change)="setTeacher($event.target)">
                                    <option value="0">Selecione um professor</option>
                                    <option *ngFor="let item of teacherDataList" [value]="item.id" [selected]="teacherId == item.id">
                                        {{item.text}}
                                    </option>
                                </select> -->
                            </div>
                            <div class="col-md-3 mb-3">
                                <label class="form-label" for="courseId">Turma</label>
                                <br>
                                <select class="form-control" id="courseId" placeholder="Turma" (change)="setCourse($event?.target?.value);" [(ngModel)]="courseId">
                                    <option *ngFor="let item of courseDataList" [value]="item.id">
                                        {{ item.text }}
                                    </option>
                                </select>
                                <!-- <ng-select2 class="w-100" [data]="courseDataList" [width]="150" style="z-index: 1000;" (valueChanged)="setCourse($event);" [options]="options"></ng-select2> -->
                                <!-- <br>
                                <select class="select2 form-control w-100" id="courseId" placeholder="Turma" [(ngModel)]="courseId">
                                    <option value="0">Selecione uma turma</option>
                                    <option *ngFor="let item of courseDataList" [value]="item.id" [selected]="courseId == item.id">
                                        {{item.text}}
                                    </option>
                                </select> -->
                            </div>
                            <div class="col-md-2 mb-3">
                                <label class="form-label" for="startDate">Data inicial</label>
                                <input type="date" id="startDate" [(ngModel)]="startDate" class="form-control" placeholder="Data inicial" (change)="setStartDate($event)"/>
                            </div>
                            <div class="col-md-2 mb-3">
                                <label class="form-label" for="endDate">Data final</label>
                                <input type="date" id="endDate" [(ngModel)]="endDate" class="form-control" placeholder="Data final" (change)="setEndDate($event)"/>
                            </div>
                            <!-- <div class="col-md-2 mb-3">
                                <label class="form-label" for="defaultReportFormat">Formato do Relatório</label><br>
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="defaultReportFormatAnalytic" name="defaultReportFormat" 
                                        [(ngModel)]="reportFormat" value="0">
                                    <label class="custom-control-label" for="defaultReportFormatAnalytic">Analítico</label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="defaultReportFormatSynthetic" name="defaultReportFormat"
                                        [(ngModel)]="reportFormat" value="1">
                                    <label class="custom-control-label" for="defaultReportFormatSynthetic">Sintético</label>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row">
                        <button class="btn btn-primary ml-auto" type="button" [disabled]="flagFiltering" (click)="getConfirmedClasses(null)">
                            <span *ngIf="flagFiltering" class="spinner-border spinner-border-sm mr-1"></span>
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div id="panel-8" class="panel">
            <div class="panel-container show">
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="col-md-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-md-12 mt-3" *ngIf="showTable">
                        <h4 class="">
                            Filtro por status
                            <small>Selecione uma das opções para visualizar as aulas por status</small>
                        </h4>
                        <div class="frame-wrap mb-0">
                            <div class="demo">
                                <button type="button" *ngFor='let status of statusClassList' class="btn" [ngClass]="status.css" 
                                    (click)="getConfirmedClasses(status.value)">
                                    {{status.label}}
                                    <span class="badge ml-2" [ngClass]="status.contrast">{{status.count}}</span>
                                </button>
                            </div>
                        </div>
                        <hr>
                        <managed-table [dataTitle]="" [rows]="confirmedGroup.studentClassesCourseConfirmed" [columns]="columns" 
                            [sorting]="false" [hasActions]="true" [ngTableFiltering]="false" [showFilterRow]="false"
                            [previewEnable]="true" (previewClick)="showInfoConfirmedClasses($event, mdInfoConfirmedClasses)"
                            [editEnable]="false" [deleteEnable]="false" [hasFilter]="false">
                        </managed-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <ng-template #mdInfoConfirmedClasses let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h5 class="modal-title">Incluir aluno na turma</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body">
                <div id="panel-3" class="panel">
                    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                        <h2>
                            <span class="fw-300"><i>Informações</i></span>
                        </h2>
                    </div>
                    <div class="panel-container show">
                        <div class="panel-content p-0">
                            <div class="form-row mb-3 mt-3 px-4">
                                <h3>
                                    <span class="fw-300"><i>Informações do Aluno</i></span>
                                </h3>
                            </div>
                            <div class="form-row mb-3 mt-3 px-4">
                                <div class="col-md-4">
                                    <label>Id</label>
                                    <label class="form-control font-weight-bold disabled">{{confirmedClassesData.student.id}}</label>
                                </div>
                                <div class="col-md-8">
                                    <label>Nome do aluno:</label>
                                    <label class="form-control font-weight-bold disabled">{{confirmedClassesData.student.name}}</label>
                                </div>
                            </div>
                            <div class="form-row mb-3 mt-3 px-4">
                                <div class="col-md-6">
                                    <label>E-mail:</label>
                                    <label class="form-control font-weight-bold disabled">{{confirmedClassesData.student.email}}</label>
                                </div>
                                <div class="col-md-6">
                                    <label>Telefone:</label>
                                    <label class="form-control font-weight-bold disabled">{{confirmedClassesData.student.phone}}</label>
                                </div>
                            </div>
                            <hr>
                            <div class="form-row mb-3 mt-3 px-4">
                                <h3>
                                    <span class="fw-300"><i>Informações da Turma</i></span>
                                </h3>
                            </div>
                            <div class="form-row mb-3 mt-3 px-4">
                                <div class="col-md-12">
                                    <label>Turma:</label>
                                    <label class="form-control font-weight-bold disabled">{{confirmedClassesData.course.name}}</label>
                                </div>
                            </div>
                            <div class="form-row mb-3 mt-3 px-4">
                                <div class="col-md-12">
                                    <label>Nome do professor:</label>
                                    <label class="form-control font-weight-bold disabled">{{confirmedGroup.teacher.name}}</label>
                                </div>
                            </div>
                            <hr>
                            <div class="form-row mb-3 mt-3 px-4">
                                <h3>
                                    <span class="fw-300"><i>Informações da Aula</i></span>
                                </h3>
                            </div>
                            <div class="form-row mb-3 mt-3 px-4">
                                <div class="col-md-12 pr-4">
                                    <div class="subheader">
                                        <div class="subheader-block d-lg-flex align-items-center col-3">
                                            <div class="d-inline-flex flex-column justify-content-center mr-3 w-100">
                                                <span class="fw-300 fs-xs d-block text-muted">Nº da aula:</span>
                                                <span class="fw-500 fs-xl d-block color-info-500">
                                                    {{ pad(confirmedClassesData.studentClassesCourseConfirmed.numberClass, 5)}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3 col-3">
                                            <div class="d-inline-flex flex-column justify-content-center mr-3 w-100">
                                                <span class="fw-300 fs-xs d-block text-muted">Data da aula:</span>
                                                <span class='badge border border-primary text-primary w-100'>
                                                    <i class='fas fa-solid fa-calendar'></i> {{ confirmedClassesData.studentClassesCourseConfirmed?.expectedDate | date: 'dd/MM/yyyy' }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3 col-3">
                                            <div class="d-inline-flex flex-column justify-content-center mr-3 w-100">
                                                <span class="fw-300 fs-xs d-block text-muted">Início da aula:</span>
                                                <span class='badge border border-primary text-primary w-100'>
                                                    <i class='fas fa-solid fa-clock'></i> {{ confirmedClassesData.studentClassesCourseConfirmed?.timeStart }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3 col-3">
                                            <div class="d-inline-flex flex-column justify-content-center mr-3 w-100">
                                                <span class="fw-300 fs-xs d-block text-muted">Término da aula:</span>
                                                <span class='badge border border-primary text-primary w-100'>
                                                    <i class='fas fa-solid fa-clock'></i> {{ confirmedClassesData.studentClassesCourseConfirmed?.timeEnd }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row mb-3 mt-3 px-4">
                                <div class="col-md-12 pr-4">
                                    <div class="subheader">
                                        <div class="subheader-block d-lg-flex align-items-center">
                                            <div class="d-inline-flex flex-column justify-content-center mr-3">
                                                <span class="fw-300 fs-xs d-block text-muted">1ª parcela:</span>
                                                <span class="fw-500 fs-xl d-block color-info-500">{{confirmedClassesData.studentCourse?.installmentFirstDue | date: 'dd/MM/yyyy'}}</span>
                                            </div>
                                        </div>
                                        <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                            <div class="d-inline-flex flex-column justify-content-center mr-3">
                                                <span class="fw-300 fs-xs d-block text-muted">Demais vencimentos:</span>
                                                <span class="fw-500 fs-xl d-block color-info-500">{{confirmedClassesData.studentCourse?.dueDay}}</span>
                                            </div>
                                        </div>
                                        <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                            <div class="d-inline-flex flex-column justify-content-center mr-3">
                                                <span class="fw-300 fs-xs d-block text-muted">Início das aulas:</span>
                                                <span class="fw-500 fs-xl d-block color-primary-500">{{confirmedClassesData.studentCourse?.startDate | date: 'dd/MM/yyyy'}}</span>
                                            </div>
                                        </div>
                                        <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                            <div class="d-inline-flex flex-column justify-content-center mr-3">
                                                <span class="fw-300 fs-xs d-block text-muted">Término das aulas:</span>
                                                <span class="fw-500 fs-xl d-block color-primary-500">{{confirmedClassesData.studentCourse?.endDate | date: 'dd/MM/yyyy'}}</span>
                                            </div>
                                        </div>
                                        <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                            <div class="d-inline-flex flex-column justify-content-center mr-3">
                                                <span class="fw-300 fs-xs d-block text-muted">Duração:</span>
                                                <span class="fw-500 fs-xl d-block color-primary-500">{{confirmedClassesData.studentCourse?.monthTotal}} meses</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row mb-3 mt-3 px-4">
                                <!-- <div class="col-md-3">
                                    <label>Total de aulas</label>
                                    <label class="form-control font-weight-bold text-primary disabled">{{confirmedClassesData.totalClasses}}</label>
                                </div>
                                <div class="col-md-3">
                                    <label>Aulas por semana</label>
                                    <label class="form-control font-weight-bold text-info disabled">{{confirmedClassesData.classesPerWeek}}</label>
                                </div>
                                <div class="col-md-3">
                                    <label>Aulas confirmadas</label>
                                    <label class="form-control font-weight-bold text-success disabled">{{confirmedClassesData.confirmedClasses}}</label>
                                </div>
                                <div class="col-md-3">
                                    <label>Aulas restante</label>
                                    <label class="form-control font-weight-bold text-info disabled">{{confirmedClassesData.remainingClasses}}</label>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Fechar</button>
            </div>
        </ng-template>
    </div>
</div>
