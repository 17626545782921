<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/teacherlist']">Professores</a>
    </li>
    <li class="breadcrumb-item active">Novo professor(a)</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div id="panel-2" class="panel">
    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
            Novo <span class="fw-300"><i>Professor(a)</i></span>
        </h2>
        <div class="panel-toolbar">
            <a href="javascript:void(0);" class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse" title="Minimizar painel">
                <i class="fal fa-minus text-white fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen" title="Maximizar painel">
                <i class="fal fa-expand text-primary fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Close" title="Fechar painel">
                <i class="fal fa-times text-white fw-900"></i>
            </a>
        </div>
    </div>
    <div class="panel-container show">
        <div class="panel-content p-0">
            <form [formGroup]="teacherForm" novalidate class="needs-validation" (ngSubmit)="onSubmit()">
                <div class="panel-content">
                    <div class="form-row">
                        <div class="col-12">
                            <aw-wizard [navBarLayout]="'large-filled-symbols'" [awNavigationMode] navigateBackward="allow" [defaultStepIndex]="0"
                                navigateForward="allow" class="m-l-15 m-r-15">
                                <aw-wizard-step  id="basicData" stepTitle="Dados básicos" (click)="{stepIndex: 0}" [stepId]='1' [navigationSymbol]="{ symbol: '&#xf007;', fontFamily: 'FontAwesome' }">
                                    <hr>
                                    <div class="form-group row">
                                        <input type="hidden" id="id" formControlName="id"/>
                                        <input type="hidden" id="userId" formControlName="userId"/>
                                        <input type="hidden" id="status" formControlName="status"/>
                                        <input type="hidden" id="createdOn" formControlName="createdOn"/>
                                        <input type="hidden" id="updatedOn" formControlName="updatedOn"/>
                                        <div class="col-12">
                                            <label class="form-label" for="name">Nome <span class="text-danger">*</span> </label>
                                            <input type="text" id="name" formControlName="name" class="form-control" placeholder="Nome do professor(a)" 
                                                maxlength="50" [ngClass]="{ 'is-invalid': !validTeacher && f?.name.errors }" required>
                                            <div *ngIf="!validTeacher && f?.name.errors" class="invalid-feedback">
                                                <div *ngIf="f?.name.errors.required">Por favor, informe o nome do professor(a).</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-3">
                                            <label class="form-label" for="email">E-mail <span class="text-danger">*</span> </label>
                                            <input type="email" id="email" formControlName="email" class="form-control" placeholder="exemplo@email.com.br"
                                                maxlength="100" [ngClass]="{ 'is-invalid': !validTeacher && f?.email.errors }" required>
                                            <div *ngIf="!validTeacher && f?.email.errors" class="invalid-feedback">
                                                <div *ngIf="f?.email.errors.required">Por favor, informe o e-mail do professor(a).</div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label class="form-label" for="phone">Telefone <span class="text-danger">*</span> </label>
                                            <input type="phone" id="phone" name="phone" formControlName="phone" class="form-control" maxlength="17"
                                                placeholder="(00) 00000-0000" [ngClass]="{ 'is-invalid': !validTeacher && f?.phone.errors }" required>
                                            <div *ngIf="!validTeacher && f?.phone.errors" class="invalid-feedback">
                                                <div *ngIf="f?.phone.errors.required">Por favor, informe o telefone do professor(a).</div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label class="form-label" for="cpf">CPF <span class="text-danger">*</span> </label>
                                            <input type="text" id="cpf" formControlName="cpf" class="form-control" placeholder="CPF" 
                                                maxlength="15" [ngClass]="{ 'is-invalid': !validTeacher && f?.cpf?.errors }" required>
                                            <div *ngIf="!validTeacher && f?.cpf?.errors" class="invalid-feedback">
                                                <div *ngIf="f?.cpf?.errors.required">Por favor, informe o CPF do professor(a).</div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label class="form-label" for="birthDate">Data de nascimento <span class="text-danger">*</span> </label>
                                            <input type="date" id="birthDate" formControlName="birthDate" class="form-control" placeholder="Data de nascimento"
                                                [ngClass]="{ 'is-invalid': !validTeacher && f?.birthDate.errors }" required>
                                            <div *ngIf="!validTeacher && f?.birthDate.errors" class="invalid-feedback">
                                                <div *ngIf="f?.birthDate.errors.required">Por favor, informe a data de nascimento.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-6">
                                            <label class="form-label" for="universityGraduate">Graduação <span class="text-danger">*</span> </label>
                                            <input type="text" id="universityGraduate" formControlName="universityGraduate" class="form-control" placeholder="Graduação" 
                                                maxlength="50" [ngClass]="{ 'is-invalid': !validTeacher && f?.universityGraduate.errors }" required>
                                            <div *ngIf="!validTeacher && f?.universityGraduate.errors" class="invalid-feedback">
                                                <div *ngIf="f?.universityGraduate.errors.required">Por favor, informe a gradução do professor(a).</div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <label class="form-label" for="moreInformation">Informações complementares <span class="text-danger">*</span> </label>
                                            <input type="text" id="moreInformation" formControlName="moreInformation" class="form-control" placeholder="Observações"
                                                maxlength="50" [ngClass]="{ 'is-invalid': !validTeacher && f?.moreInformation.errors }" required>
                                            <div *ngIf="!validTeacher && f?.moreInformation.errors" class="invalid-feedback">
                                                <div *ngIf="f?.moreInformation.errors.required">Por favor, informe os dados adicionais.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-0">
                                        <div class="row">
                                            <div class="col-sm-12 text-right">
                                                <div id="btnButtonWizard" class="btn-group btn-group-sm btn-group-toggle text-right" data-toggle="buttons">
                                                    <button class="btn btn-info" data-action="toggle" data-class="nav-tabs-clean" disabled="true" awPreviousStep>Anterior</button>
                                                    <button class="btn btn-info active" data-action="toggle" data-class="nav-tabs-clean" awNextStep>Próximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aw-wizard-step>

                                <aw-wizard-step  id="addressData" stepTitle="Endereço" (click)="{stepIndex: 1}" [stepId]='2' [navigationSymbol]="{ symbol: '&#xf015;', fontFamily: 'FontAwesome' }">
                                    <hr>
                                    <div class="form-group row">
                                        <div class="col-3">
                                            <label class="form-label" for="zipCode">CEP <span class="text-danger">*</span> </label>
                                            <span class="text-primary pull-right" *ngIf="flagSearchCep">Carregando endereço</span>
                                            <span role="status" aria-hidden="true" class="spinner-border spinner-border-sm pull-right mr-2 text-primary" *ngIf="flagSearchCep"></span>
                                            <div class="input-group">
                                                <input type="text" id="zipCode" formControlName="zipCode" class="form-control" placeholder="CEP" aria-label="CEP" #zipCode
                                                maxlength="8" [ngClass]="{ 'is-invalid': !validTeacher && f?.zipCode.errors }" required aria-describedby="btnFindzipCode" (focusout)="loadAddressByCep(zipCode)">
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary waves-effect waves-themed" type="button" id="btnFindzipCode" (click)="loadAddressByCep(zipCode)"><i class="fal fa-search"></i></button>
                                                </div>
                                            </div>
                                            <div *ngIf="!validTeacher && f?.zipCode.errors" class="invalid-feedback">
                                                <div *ngIf="f?.zipCode.errors.required">Por favor, informe o CEP.</div>
                                                <div *ngIf="f?.zipCode.errors.invalidNumber">CEP inválido. Por favor, digite outro CEP.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-6">
                                            <label class="form-label" for="street">Endereço <span class="text-danger">*</span> </label>
                                            <input type="text" id="street" formControlName="street" class="form-control" placeholder="Endereço" 
                                                maxlength="100" [ngClass]="{ 'is-invalid': !validTeacher && f?.street.errors }" required>
                                            <div *ngIf="!validTeacher && f?.name.errors" class="invalid-feedback">
                                                <div *ngIf="f?.street.errors.required">Por favor, informe o endereço.</div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label class="form-label" for="number">Número <span class="text-danger">*</span> </label>
                                            <input type="text" id="number" formControlName="number" class="form-control" placeholder="Número" #inputNumber
                                                maxlength="10" [ngClass]="{ 'is-invalid': !validTeacher && f?.number.errors }" required>
                                            <div *ngIf="!validTeacher && f?.number.errors" class="invalid-feedback">
                                                <div *ngIf="f?.number.errors.required">Por favor, informe o número.</div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label class="form-label" for="complement">Complemento </label>
                                            <input type="text" id="complement" formControlName="complement" class="form-control" placeholder="Complemento" 
                                                maxlength="30" [ngClass]="{ 'is-invalid': !validTeacher && f?.complement.errors }">
                                            <div *ngIf="!validTeacher && f?.complement.errors" class="invalid-feedback">
                                                <div *ngIf="f?.complement.errors.required">Por favor, informe o complemento.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-6">
                                            <label class="form-label" for="neighborhood">Bairro <span class="text-danger">*</span> </label>
                                            <input type="text" id="neighborhood" formControlName="neighborhood" class="form-control" placeholder="Bairro" 
                                                maxlength="100" [ngClass]="{ 'is-invalid': !validTeacher && f?.neighborhood.errors }" required>
                                            <div *ngIf="!validTeacher && f?.neighborhood.errors" class="invalid-feedback">
                                                <div *ngIf="f?.neighborhood.errors.required">Por favor, informe o bairro.</div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <label class="form-label" for="stateId">Estado <span class="text-danger">*</span> </label>
                                            <select class="form-control" id="stateId" for="stateId" formControlName="stateId" placeholder="Estado"
                                                [ngClass]="{ 'is-invalid': !validTeacher && f?.stateId.errors }" 
                                                (change)="getCitiesByStateId(f?.stateId.value)">
                                                <option *ngFor="let state of stateList" [ngValue]="state.id">
                                                    {{ state.uf }}
                                                </option>
                                            </select>
                                            <div *ngIf="!validTeacher && f?.stateId.errors" class="invalid-feedback">
                                                <div *ngIf="f?.stateId.errors.required">Por favor, informe o estado.</div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label" for="cityId">Cidade <span class="text-danger">*</span> </label>
                                            <select class="form-control" id="cityId" for="cityId" formControlName="cityId" placeholder="Cidade"
                                                [ngClass]="{ 'is-invalid': !validTeacher && f?.cityId.errors }">
                                                <option *ngFor="let ct of citiesList" [ngValue]="ct.id">
                                                    {{ ct.name }}
                                                </option>
                                            </select>
                                            <div *ngIf="!validTeacher && f?.cityId.errors" class="invalid-feedback">
                                                <div *ngIf="f?.cityId.errors.required">Por favor, informe a cidade.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-0">
                                        <div class="row">
                                            <div class="col-sm-12 text-right">
                                                <div id="btnButtonWizard" class="btn-group btn-group-sm btn-group-toggle text-right" data-toggle="buttons">
                                                    <button class="btn btn-info" data-action="toggle" data-class="nav-tabs-clean" awPreviousStep>Anterior</button>
                                                    <button class="btn btn-info active" data-action="toggle" data-class="nav-tabs-clean" awNextStep>Próximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aw-wizard-step>

                                <aw-wizard-step  id="bankData" stepTitle="Dados bancários" (click)="{stepIndex: 2}" [stepId]='3' [navigationSymbol]="{ symbol: '&#xf155;', fontFamily: 'FontAwesome' }">
                                    <div class="form-group row">
                                        <div class="col-6">
                                            <label class="form-label" for="bankId">Banco <span class="text-danger">*</span> </label>
                                            <select class="form-control" id="bankId" for="bankId" formControlName="bankId" placeholder="Banco"
                                                [ngClass]="{ 'is-invalid': !validTeacher && f?.bankId.errors }">
                                                <option *ngFor="let bank of bankList" [ngValue]="bank.id">
                                                    {{ bank.name + " " + bank.code }}
                                                </option>
                                            </select>
                                            <div *ngIf="!validTeacher && f?.bankId.errors" class="invalid-feedback">
                                                <div *ngIf="f?.bankId.errors.required">Por favor, informe o banco.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-6 mb-3">
                                            <label class="form-label" for="hourlyRate">Valor total <span class="text-danger">*</span> </label>
                                            <input class="form-control" currencyMask formControlName="hourlyRate"
                                                [ngClass]="{ 'is-invalid': !validTeacher && f.hourlyRate.errors }" required>
                                            <div *ngIf="!validTeacher && f.hourlyRate.errors" class="invalid-feedback">
                                                <div *ngIf="f.hourlyRate.errors.required">Por favor, informe o valor/hora do teacher.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12 mb-3">
                                            <label class="form-label">Tipo de Conta <span class="text-danger">*</span> </label>
                                            <br>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" class="custom-control-input" formControlName="accountType" 
                                                name="accountType" id="radioAccountTypeCc"
                                                [ngClass]="{ 'is-invalid': !validTeacher && f?.accountType.errors }" 
                                                value="0" (change)="onAccountTypeChecked($event)"/>
                                                <label class="custom-control-label" for="radioAccountTypeCc">Conta Corrente</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" class="custom-control-input" formControlName="accountType" 
                                                name="accountType" id="radioAccountTypeCp"
                                                [ngClass]="{ 'is-invalid': !validTeacher && f?.accountType.errors }" 
                                                value="1" (change)="onAccountTypeChecked($event)"/>
                                                <label class="custom-control-label" for="radioAccountTypeCp">Conta Poupança</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" class="custom-control-input" formControlName="accountType" 
                                                name="accountType" id="radioAccountTypeCs"
                                                [ngClass]="{ 'is-invalid': !validTeacher && f?.accountType.errors }" 
                                                value="2" (change)="onAccountTypeChecked($event)"/>
                                                <label class="custom-control-label" for="radioAccountTypeCs">Conta Salário</label>
                                            </div>
                                            <div *ngIf="!validTeacher && f?.accountType.errors" class="invalid-feedback">
                                                <div *ngIf="f?.accountType.errors.required">Por favor, informe o tipo de conta.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-4">
                                            <label class="form-label" for="agency">Agência </label>
                                            <input type="text" id="agency" formControlName="agency" class="form-control" placeholder="Agência" 
                                                maxlength="8" [ngClass]="{ 'is-invalid': !validTeacher && f?.agency.errors }">
                                            <div *ngIf="!validTeacher && f?.agency.errors" class="invalid-feedback">
                                                <div *ngIf="f?.agency.errors.required">Por favor, informe a agência.</div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label" for="account">Conta bancária </label>
                                            <input type="text" id="account" formControlName="account" class="form-control" placeholder="Conta bancária" 
                                                maxlength="10" [ngClass]="{ 'is-invalid': !validTeacher && f?.account.errors }">
                                            <div *ngIf="!validTeacher && f?.account.errors" class="invalid-feedback">
                                                <div *ngIf="f?.account.errors.required">Por favor, informe a conta bancária.</div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <label class="form-label" for="pix">Chave PIX </label>
                                            <input type="text" id="pix" formControlName="pix" class="form-control" placeholder="Chave PIX" 
                                                maxlength="100" [ngClass]="{ 'is-invalid': !validTeacher && f?.pix.errors }">
                                            <div *ngIf="!validTeacher && f?.pix.errors" class="invalid-feedback">
                                                <div *ngIf="f?.pix.errors.required">Por favor, informe a pix.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-0">
                                        <div class="row">
                                            <div class="col-sm-12 text-right">
                                                <div id="btnButtonWizard" class="btn-group btn-group-sm btn-group-toggle text-right" data-toggle="buttons">
                                                    <button class="btn btn-info" data-action="toggle" data-class="nav-tabs-clean" awPreviousStep>Anterior</button>
                                                    <button class="btn btn-info active" data-action="toggle" data-class="nav-tabs-clean" disabled="true" awNextStep>Próximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aw-wizard-step>

                            </aw-wizard>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="!validTeacher">
                        <hr>
                        <div class="col-12">
                            <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validTeacher">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                </button>
                                {{errorMessage}}
                            </div>
                            <br>
                            <div *ngIf="listErrorMessage && !validTeacher" class="invalid-feedback">
                                <div *ngFor="let error of listErrorMessage">{{error}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <footer-form [auxSaveAction]="true" [auxCancelAction]="true" (saveClick)="onSubmit()" (cancelClick)="onCancel()"></footer-form>
    </div>
</div>