import { InitAdminService } from '@app/admin/services/init-admin.service';
import { MessageService } from '@app/shared/services/message.service';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { WritingTestService } from '@app/admin/services';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { TypeResponse } from '@app/shared/enum';
import { default as swal } from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'writing-test-list',
  templateUrl: './writing-test-list.component.html',
  styleUrls: ['./writing-test-list.component.css'],
  providers: [DatePipe],
})
export class WritingTestListComponent implements OnInit {
  public dataList: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  closeResult: string;
  public contentHtmlView: string = '';

  iconContentHtml = 'fas fa-file-edit';
  titleContentHtml = 'Conteúdo do teste';

  public columns: Array<any> = [
    { title: 'Código', name: 'id', sort: 'asc', className: 'text-center' },
    {
      title: 'Nome',
      name: 'name',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-left fw-700',
    },
    {
      title: 'Ordem',
      name: 'order',
      sort: 'asc',
      className: 'text-center text-info fw-700',
    },
    {
      title: 'Nº de Aplicações',
      name: 'usageCount',
      sort: 'asc',
      className: 'text-center text-primary fw-700',
    },
    {
      title: 'Criado em',
      name: 'createdOn',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
    {
      title: 'Modificado em',
      name: 'updatedOn',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
  ];

  constructor(
    private writingTestService: WritingTestService,
    private initAdmin: InitAdminService,
    private sanitizer: DomSanitizer,
    private loading: MessageService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadWritingTests();
    this.loading.hideLoading();
  }

  loadWritingTests() {
    this.loading.showLoading();
    this.writingTestService.getAll().subscribe(
      (response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.dataList = response.data;
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.loading.hideLoading();
      },
      (error: any) => {
        this.showTable = false;
        this.returnMessage = 'Erro ao carregar os testes disponíveis.';
        this.loading.hideLoading();
      }
    );
  }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/writingtest', { id: row.id }]);
  }

  delete(target: any): void {
    swal({
      title: 'Excluir Testes de Escrita(a)',
      html:
        'Confirma a exclusão <strong>' +
        target.name +
        '</strong>?<br>' +
        'Ao confirmar o testes de escrita será excluído permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.writingTestService.remove(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.loading.hideLoading();
                  window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir o teste de escrita!',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir o teste de escrita.',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  this.loadWritingTests();
                });
              }
            },
            (error) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir o teste de escrita.',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir o teste de escrita.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  preview(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/writingtest', { id: row.id }]);
  }

  create(content: any) {
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        //size: 'sm',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'Save') {
            //this.submitChangePassword();
          }
        },
        (reason) => {
          this.closeResult = `Dismissed`;
          //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  viewContentHtml(row: any, content: any) {
    this.contentHtmlView = row.contentHtml;
    //this.contentHtmlView = this.sanitizer.bypassSecurityTrustHtml(row.contentHtml);
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();

      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }
}
