export class WritingTestAppliedReview {
    writingTestApplyId: number;
    scoreResult: number;
    finishDate: Date;
    avgScore: number;
    writingTestStatus: number;
    contentReviewHtml: string;
    additionalInformation: string;

    constructor(contentResponse: any) {
        this.writingTestApplyId = parseInt(contentResponse.writingTestApplyId);
        this.scoreResult = parseFloat(contentResponse.scoreResult);
        this.finishDate = new Date(contentResponse.finishDate),
        this.avgScore = parseInt(contentResponse.avgScore);
        this.writingTestStatus = parseInt(contentResponse.writingTestStatus);
        this.additionalInformation = contentResponse.additionalInformation;
        this.contentReviewHtml = contentResponse.contentReviewHtml;
    }
}
