import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';
import { UserAudit } from '../model';

@Injectable({
  providedIn: 'root'
})
export class UserAuditService {
  private route: string = "auditable";
  private controller: string = "UserAudit";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  get(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getToGrid(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/grid`);
  }

  getById(id: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }

  save(model: UserAudit, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/${model.id}`, model);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}`, model);
    }
  }
}
