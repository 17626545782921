import { Component, Input, OnInit } from '@angular/core';
import { SerieType } from '@app/shared/enum';
import { Observable } from 'rxjs';

@Component({
  selector: 'content-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class PackageComponent implements OnInit {
  @Input() serieType: SerieType;

  contacts: Observable<any[]>;

  constructor() { }

  ngOnInit(): void {
    this.loadDataFake();
  }

  private loadDataFake() {
    this.contacts = Observable.of([
      {
        "id": 1,
        "name": "Laura",
        "email": "lbutler0@latimes.com",
        "age": 47
      },
      {
        "id": 2,
        "name": "Walter",
        "email": "wkelley1@goodreads.com",
        "age": 37
      },
      {
        "id": 3,
        "name": "Walter",
        "email": "wgutierrez2@smugmug.com",
        "age": 49
      },
      {
        "id": 4,
        "name": "Jesse",
        "email": "jarnold3@com.com",
        "age": 47
      },
      {
        "id": 5,
        "name": "Irene",
        "email": "iduncan4@oakley.com",
        "age": 33
      }
    ]);
  }

  trackById(index: number, contact: any) {
    return contact.id;
  }  
}
