import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  private secretKey = environment.secretKeyEncrypt;

  // Função para criptografar a string
  encrypt(data: string): string {
    const encrypted = CryptoJS.AES.encrypt(data, this.secretKey).toString();

    // Converte para base64url (removendo '/', '+', '=')
    return this.base64ToBase64Url(encrypted);
  }

  // Função para descriptografar a string
  decrypt(encryptedData: string): string {
    // Converte de base64url para base64
    const base64 = this.base64UrlToBase64(encryptedData);
    const decrypted = CryptoJS.AES.decrypt(base64, this.secretKey);

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  // Converte base64 para base64url (remove '/' e '=')
  private base64ToBase64Url(base64: string): string {
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  // Converte base64url de volta para base64
  private base64UrlToBase64(base64Url: string): string {
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    while (base64.length % 4 !== 0) {
      base64 += '=';
    }
    return base64;
  }
}
