import { Course, Student } from "@app/admin/model";
import { Base } from "@app/shared/model";

export class ClassAbsencesData extends Base {
    student: Student;
    course: Course;
    scheduleClasses: number;
    absencesClasses: number;

    constructor(contentResponse: any) {
        super(contentResponse);

        if (contentResponse && contentResponse.courseId > 0) {
            this.course = contentResponse.course;
        }

        if (contentResponse && contentResponse.studentId > 0) {
            this.student = contentResponse.student;
        }

        this.scheduleClasses = contentResponse.scheduleClasses;
        this.absencesClasses = contentResponse.absencesClasses;
    }
}
