<!-- BEGIN Left Aside -->
<div class="page-logo">
    <a href="javascript:void(0);" class="page-logo-link press-scale-down d-flex align-items-center position-relative" data-toggle="modal" data-target="#modal-shortcut">
        <img src="assets/img/logo-english-coffee.png" alt="English Coffee App" aria-roledescription="logo">
        <span class="page-logo-text mr-1">English Coffee</span>
        <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
        <i class="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
    </a>
</div>

<menu-admin *ngIf="user.module == 'Admin'"></menu-admin>
<menu-student *ngIf="user.module == 'Student'"></menu-student>
<menu-teacher *ngIf="user.module == 'Teacher'"></menu-teacher>

<!-- NAV FOOTER -->
<!-- <div class="nav-footer shadow-top">
    <a href="javascript:void(0);" onclick="return false;" data-action="toggle" data-class="nav-function-minify" class="hidden-md-down">
        <i class="ni ni-chevron-right"></i>
        <i class="ni ni-chevron-right"></i>
    </a>
    <li class="nav-title"><i class="fa fa-support"></i> Suporte</li>
    <ul class="list-table m-auto nav-footer-buttons">
        <li>
            <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Por mensagem">
                <i class="fal fa-comments"></i>
            </a>
        </li>
        <li>
            <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Por telefone">
                <i class="fal fa-phone"></i>
            </a>
        </li>
        <li>
            <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Por WhatsApp">
                <i class="fa fa-whatsapp"></i>
            </a>
        </li>
    </ul>
</div>  -->
<!-- END NAV FOOTER -->
<!-- END Left Aside -->