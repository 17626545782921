export enum StatusClass {
  Scheduled = 0,
  Done = 1,
  // Canceled = 2,
  Delayed = 3,
  ChangedTeacher = 4,
  Reschedule = 5,
  PartialConfirmed = 6,
  Unchecked = 7
}

export const StatusClassLabel = new Map<number, string>([
  [StatusClass.Scheduled, 'Agendada'],
  [StatusClass.Done, 'Concluída'],
  // [StatusClass.Canceled, 'Cancelada'],
  [StatusClass.Delayed, 'Atrasada'],
  [StatusClass.ChangedTeacher, 'Professor Alterado'],
  [StatusClass.Reschedule, 'Reagendada'],
  [StatusClass.PartialConfirmed, 'Confirmada Parcial'],
  [StatusClass.Unchecked, 'Desmarcada']
]);

export const StatusClassCss = new Map<number, string>([
  [StatusClass.Scheduled, 'status-class-scheduled'],
  [StatusClass.Done, 'status-class-done'],
  // [StatusClass.Canceled, 'status-class-canceled'],
  [StatusClass.Delayed, 'status-class-delayed'],
  [StatusClass.ChangedTeacher, 'status-class-changed-teacher'],
  [StatusClass.Reschedule, 'status-class-reschedule'],
  [StatusClass.PartialConfirmed, 'status-class-partial-confirmed'],
  [StatusClass.Unchecked, 'status-class-unchecked']
]);

export const StatusClassCssContrast = new Map<number, string>([
  [StatusClass.Scheduled, 'status-class-scheduled-contrast'],
  [StatusClass.Done, 'status-class-done-contrast'],
  // [StatusClass.Canceled, 'status-class-canceled-contrast'],
  [StatusClass.Delayed, 'status-class-delayed-contrast'],
  [StatusClass.ChangedTeacher, 'status-class-changed-teacher-contrast'],
  [StatusClass.Reschedule, 'status-class-reschedule-contrast'],
  [StatusClass.PartialConfirmed, 'status-class-partial-confirmed-contrast'],
  [StatusClass.Unchecked, 'status-class-unchecked-contrast']
]);
