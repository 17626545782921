import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@app/shared/services';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseRecurrenceService extends BaseService {
  private customEndpointPrefix: string;
  constructor(private http2: HttpClient) {
      super(http2, "admin", "CourseRecurrence");
      this.customEndpointPrefix = `${environment.urlAPI}/admin/CourseRecurrence`;
  }

  getByCourseId(courseId: any): any {
    return this.http2.get<ResponseResult>(`${this.customEndpointPrefix}/course/${courseId}`);
  }
}
