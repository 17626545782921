import { HashLocationStrategy, LocationStrategy, CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
    {path: '', redirectTo: 'admin/home', pathMatch: 'full'},
    { path: 'home', redirectTo: 'admin/home', pathMatch: 'full' },
    {
        path: 'auth',
        children: [{
            path: '',
            loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
        }]
    },
    {
        path: 'admin',
        children: [{
            path: '',
            loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
        }]
    },
    {
        path: 'financial',
        children: [{
            path: '',
            loadChildren: () => import('./financial/financial.module').then(m => m.FinancialModule)
        }]
    },
    {
        path: 'marketing',
        children: [{
            path: '',
            loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule)
        }]
    },
    {
        path: 'reports',
        children: [{
            path: '',
            loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
        }]
    },
    {
        path: 'student',
        children: [{
            path: '',
            loadChildren: () => import('./student/student.module').then(m => m.StudentModule)
        }]
    },
    {
        path: 'teacher',
        children: [{
            path: '',
            loadChildren: () => import('./teacher/teacher.module').then(m => m.TeacherModule)
        }]
    },
    {
        path: 'content',
        children: [{
            path: '',
            loadChildren: () => import('./content/content.module').then(m => m.ContentModule)
        }]
    },
    {
        path: 'audit',
        children: [{
            path: '',
            loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule)
        }]
    },
    {
        path: 'message',
        children: [{
            path: '',
            loadChildren: () => import('./message/message.module').then(m => m.MessageModule)
        }]
    },
    { path: '404', component: PageNotFoundComponent }
    //{ path: '**', redirectTo: '/404' }
];

@NgModule({
    imports: [ CommonModule, RouterModule.forRoot(routes) ],
    exports: [ RouterModule ],
    providers: [
        [{ provide: LocationStrategy, useClass: HashLocationStrategy, useHash: true }]
    ],
    declarations: []
})

export class AppRoutingModule { }
