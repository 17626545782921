export enum MessageType {
    Success = 0,
    Info = 1,
    Alert = 2,
    Error = 3
}

export const MessageTypeLabel = new Map<number, string>([
    [MessageType.Success, 'Sucesso'],
    [MessageType.Info, 'Informação'],
    [MessageType.Alert, 'Alerta'],
    [MessageType.Error, 'Erro']
]);

export const MessageTypeCss = new Map<number, string>([
    [MessageType.Success, 'message-type-success'],
    [MessageType.Info, 'message-type-info'],
    [MessageType.Alert, 'message-type-alert'],
    [MessageType.Error, 'message-type-error']
]);
