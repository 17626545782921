import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';
import { ContactBySiteService } from '@app/marketing/services/contactBySite.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { default as swal } from 'sweetalert2';
import { StatusCrmCss, StatusCrmLabel } from '@app/shared/enum/StatusCrm';

@Component({
  templateUrl: 'contactBySite-list.component.html',
  providers: [DatePipe],
})
export class ContactBySiteListComponent implements OnInit {
  @ViewChild('mdContactPreRegister') mdContactPreRegister: any;
  public contacts: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  closeResult: string;
  contactId: number;
  public statusCrm: string = null;

  iconChangeStatus = 'fa fa-magic';
  titleChangeStatus = 'Trocar Status';

  iconLogs = 'fas fa-line-height';
  titleLogs = 'Alterações de Status';

  iconPreRegister = 'ni ni-book-open';
  titlePreRegister = 'Pré-matrícula';

  public columns: Array<any> = [
    { title: 'Código', name: 'id', sort: 'asc', className: 'text-center' },
    { title: 'Nome', name: 'name', sort: 'asc' },
    { title: 'E-mail', name: 'email', sort: 'asc', className: 'text-center' },
    { title: 'Telefone', name: 'phone', sort: 'asc', className: 'text-center' },
    {
      title: 'Status Atual',
      name: 'designStatus',
      sort: 'asc',
      className: 'text-center fw-700',
      isHtmlData: true,
    },
    {
      title: 'Usuário<br>Criado?',
      name: 'createdUser',
      sort: 'asc',
      className: 'text-center text-primary',
    },
    {
      title: 'Usuário<br>Já Acessou?',
      name: 'userLoggedIn',
      sort: 'asc',
      className: 'text-center text-primary',
    },
    {
      title: 'Matrícula<br>Efetivada?',
      name: 'registration',
      sort: 'asc',
      className: 'text-center text-primary',
    },
    {
      title: 'Alterações<br>de Status',
      name: 'statusCrmChangesCount',
      sort: 'asc',
      className: 'text-center text-primary fw-700',
    },
    {
      title: 'Aula Experimental',
      name: 'experimentalClass',
      sort: 'asc',
      className: 'text-center font-weight-bold text-primary datetime',
    },
    {
      title: 'Pré-Matrículas',
      name: 'preRegCount',
      sort: 'asc',
      className: 'text-center text-primary fw-700',
    },
    {
      title: 'Última<br>Atualização',
      name: 'updatedOn',
      sort: 'asc',
      className: 'text-center font-weight-bold text-info datetime',
    },
    // {
    //   title: "Pré-matrículas",
    //   name: "preRegCount",
    //   sort: "asc",
    //   isHtmlData: true,
    //   className: "text-center fw-700"
    // }
  ];

  constructor(
    private contactBySiteService: ContactBySiteService,
    private activatedRoute: ActivatedRoute,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.statusCrm = null;

    this.activatedRoute.params.subscribe((params) => {
      if (params['statusCrm'] != null && params['statusCrm'] != 'reset') {
        this.statusCrm = params['statusCrm'];
      }
      this.loadContacts('null');
    });
  }

  loadContacts(statusCrm: string) {
    this.loading.showLoading();
    this.contactBySiteService.getResultGrid(statusCrm).subscribe(
      (response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.contacts = response.data;
          this.designStatus(this.contacts);
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.loading.hideLoading();
      },
      (error: any) => {
        this.loading.hideLoading();
        this.showTable = false;
        this.returnMessage = 'Erro ao carregar os dados dos contatos.';
      }
    );
  }

  resetStatusPage() {
    this.loading.showLoading();
    window.location.replace(window.location.href.split(';')[0]);
    window.location.reload();
  }

  designStatus(lista: any[]) {
    lista.forEach(function (e) {
      if (e.statusCrm >= 0) {
        let statusCrmStr = StatusCrmLabel.get(e.statusCrm);
        let statusCrmCss = StatusCrmCss.get(e.statusCrm);
        e.designStatus =
          "<span class='badge w-100 " +
          statusCrmCss +
          " badge-pill py-1 px-3'>" +
          statusCrmStr +
          '</span>';
      }

      // if (e.preRegCount >= 0) {
      //   e.designPreReg = "<span class='badge badge-primary w-100 badge-pill'>"+ e.preRegCount +"</span>";
      // }
    });
    this.contacts = lista;
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();

      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate([
      '/marketing/contactbysite',
      { id: row.id, edit: true },
    ]);
  }

  preview(row: any): void {
    this.loading.showLoading();
    this.router.navigate([
      '/marketing/contactbysite',
      { id: row.id, preview: true },
    ]);
  }

  delete(target: any): void {
    swal({
      title: 'Excluir Contato',
      html:
        'Confirma a exclusão <strong>' +
        target.name +
        '</strong>?<br>' +
        'Ao confirmar o contato será excluído permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.contactBySiteService.remove(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.resetStatusPage();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir o contato!',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {});
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir o contato.',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  this.resetStatusPage();
                });
              }
            },
            (error: any) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir o contato.',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error: any) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir o contato.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  onChangeStatus(row: any): void {
    this.loading.showLoading();
    this.router.navigate([
      '/marketing/contactbysitechangestatus',
      { id: row.id, edit: true },
    ]);
  }

  // onChangeStatus(row: any, content: any) {
  //   this.onAuxClick(row, content);
  // }

  newContactPreRegister(row: any, content: any) {
    if (row.createdUser) {
      this.onAuxClick(row, content);
    } else {
      swal({
        type: 'warning',
        title: 'Pré-Matrícula não permitida!',
        text: 'Não é possível inserir uma Pré-Matrícula antes da criação do usuário.',
        showConfirmButton: true,
      });
    }
  }

  onAuxClick(row: any, content: any) {
    this.contactId = row.id;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
}
