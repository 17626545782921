import { ProductService, InitAdminService, CourseService } from '@app/admin/services';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Product, Course } from '@app/admin/model';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MessageService } from '@app/shared/services';
import { first } from 'rxjs/operators';
import { TypeResponse } from '@app/shared/enum';
import { default as swal } from 'sweetalert2'
@Component({ templateUrl: 'product.component.html' })
export class ProductComponent implements OnInit {
  productForm;
  submitted = false;
  returnUrl: string = '/admin/packagelist';
  public validProduct: boolean;
  public updateProduct: boolean = false;
  public errorMessage = '';
  public listErrorMessage: any[];
  private product: Product;
  public phoneMask: Array<string | RegExp>;
  public cepMask: Array<string | RegExp>;
  public producValue: any;

  public editMode: boolean;
  public coursesList: any = [];
  public citiesList: any = [];
  public idProduct: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private courseService: CourseService  ) {
    this.phoneMask = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.cepMask = [/[0-9]/, /\d/ ,/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/packagelist';
    
    this.activatedRoute.params.subscribe(params => {
      this.idProduct = params['id'];
      if (this.idProduct > 0) {
        this.editMode = true;
        this.loadProduct(this.idProduct);
      } else {
        this.editMode = false;
        // Initialize the register form
        this.onInitForm();
      }
    });

    this.loading.hideLoading();
  }

  onInitForm() {
    this.validProduct = true;
    this.productForm = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.required]],
      unity: [0, [Validators.required]],
      value: [0, [Validators.required]],
      monthlyAmount: [0, [Validators.required]],
      createdOn: [''],
      updatedOn: ['']
    });
  }
  bn
  // convenience getter for easy access to form fields
  get f() {
    return this.productForm.controls;
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validProduct = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.productForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validProduct = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.product = new Product(this.productForm.value);
    if (Number.isNaN(this.product.value)) {
      this.product.value = 0;
    }
    
    if (Number.isNaN(this.product.monthlyAmount)) {
      this.product.monthlyAmount = 0;
    }
    
    this.productService.save(this.product, this.editMode)
    .pipe(first())
    .subscribe(dataReturn => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        swal({
          type: 'success',
          title: this.editMode ? 'Pacote atualizado com sucesso!' : 'Novo pacote criado com sucesso!',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.validProduct = true;
          this.router.navigate([this.returnUrl]);
        });
      } else {
        this.validProduct = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (error: any) => {
      this.validProduct = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = !error.ok ? "Erro ao salvar o pacote." : error;
    });
  }

  loadProduct(id: any) {
    this.loading.showLoading();
    this.productService.get(id)
    .subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validProduct = true;
        this.product = new Product(response.data);
        this.setFormByEntity(this.product);
      } else {
        this.errorMessage = response.message;
        this.validProduct = false;
      }
      this.loading.hideLoading();
      }, (error: any) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          this.router.navigate([this.returnUrl]);
        }
      }
    );
  }

  loadCoursesByProduct(productId) {
    this.loading.showLoading();
    var courseRequest = productId > 0 
      ? this.courseService.getByProductId(productId)
      : this.courseService.getall();
      
    courseRequest.subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.coursesList = response.data;
      } else {
        this.coursesList = new Array<Course>();
      }
      this.loading.hideLoading();
      }, () => {
        this.coursesList = new Array<Course>();
        this.loading.hideLoading();
      }
    );
  }

  setFormByEntity(data: any) {
    this.productForm = this.formBuilder.group({
      id: new FormControl(data.id),
      name: new FormControl(data.name),
      unity: new FormControl(data.unity),
      value: new FormControl(data.value),
      monthlyAmount: new FormControl(data.value),
    });
  }

  finishFunction(){
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }
}