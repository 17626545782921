import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { JitsiService } from './services';
import { ClassRoomComponent } from './components/classroom/classroom.component';
import { ContentRoutingRoutes } from './content-routing.module';

@NgModule({
  declarations: [
    ClassRoomComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    ContentRoutingRoutes
  ],
  exports: [
    ClassRoomComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [ DatePipe, JitsiService ]
})
export class ContentModule { }
