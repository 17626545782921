import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { BankService, InitAdminService } from '@app/admin/services';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, MessageService } from '@app/shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Bank } from '@app/admin/model';
import { first } from 'rxjs/operators';

@Component({ templateUrl: 'bank.component.html' })
export class BankComponent implements OnInit {
  bankForm;
  submitted = false;
  returnUrl: string;
  public validBank: boolean;
  public errorMessage = '';
  public listErrorMessage: any[];

  public editBank: boolean;
  private bank: Bank;
  public bankId: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private bankService: BankService,
    private alertService: AlertService,
    private initAdmin: InitAdminService,
    private loading: MessageService
  ) { }
 
  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/banklist';
    
    this.activatedRoute.params.subscribe(params => {
      this.bankId = params['id'];
      if (this.bankId > 0) {
        this.editBank = true;
        this.loadBankById(this.bankId);
      } else {
        this.editBank = false;
        // Initialize the register form
        this.onInitForm();
      }
    });

    // Check phone valid
    this.onSetEventsForm();

    this.loading.hideLoading();
  }

  onInitForm() {
    this.validBank = true;
    this.bankForm = this.formBuilder.group({
      code: [0, [Validators.required]],
      name: ['', [Validators.required]]
    });
  }

  onSetEventsForm() {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.bankForm.controls;
  }

  onSubmit() {
    this.loading.showLoading();
    this.submitted = true;
    this.validBank = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.bankForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validBank = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.bank = new Bank(this.bankForm.value);
    
    this.bankService.save(this.bank, this.editBank)
    .pipe(first())
    .subscribe(dataReturn => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.validBank = true;
        //this.router.navigate([this.returnUrl]);
        this.router.navigate([this.returnUrl]);
      } else {
        this.validBank = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, error => {
      this.validBank = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = error;
    });
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  loadBankById(id) {
    this.loading.showLoading();
    this.bankService.getById(id)
    .pipe(first())
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validBank = true;
        this.bankForm = this.formBuilder.group({
          id: new FormControl(response.data.id),
          code: new FormControl(response.data.code),
          name: new FormControl(response.data.name),
        });
      } else {
        //this.returnMessage = response.message;
        this.validBank = true;
      }
      this.loading.hideLoading();
      }, error => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
      }
    );
  }
}