import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Helpers
import { AuthGuard } from '../auth/helpers/auth.guard';

//Components
import { InstallmentsListComponent } from './../financial/components/installments/installments-list/installments-list.component';
import { InstallmentsComponent } from './../financial/components/installments/installments/installments.component';

import { TransactionListComponent } from './../financial/components/transaction/transaction-list/transaction-list.component';
import { TransactionComponent } from './../financial/components/transaction/transaction/transaction.component';

import { CategoryListComponent } from './../financial/components/category/category-list/category-list.component';
import { CategoryComponent } from './../financial/components/category/category/category.component';
import { ModuleType } from '@app/shared/enum/ModuleType';

const routes: Routes = [
  { path: 'installmentslist', component: InstallmentsListComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0FI3CR13', title: 'Parcelas' } },
  { path: 'installments', component: InstallmentsComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0FI3CR13', title: 'Parcela' } },

  { path: 'transactions', component: TransactionListComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0FI3CR13', title: 'Transações Financeiras' } },
  { path: 'transaction', component: TransactionComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0FI3CR13', title: 'Transação Financeira' } },

  { path: 'categories', component: CategoryListComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0FI3CA9', title: 'Categorias' } },
  { path: 'category', component: CategoryComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0FI3CA9', title: 'Categoria' } },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinancialRoutingRoutes { }
