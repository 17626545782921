import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '@app/shared/services';
import { InitStudentService } from '@app/student/services';
import { AccountService } from '@app/auth/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@app/auth/model';
import { environment } from '@environments/environment';
import { SerieType } from '@app/shared/enum';

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.css']
})
export class ContentListComponent implements OnInit {
  private contentFile: string = `${environment.contentFiles}`;
  private structureFile: string;
  public itemSerie: string;
  public serieType: SerieType;
  returnUrl: string;
  public serieContent: any = {
    name: ''
  };
  public contentPath: string;
  public imgFileName: string;
  user: User;
  public contents: Array<any>;
  private userRoles: Array<any>;

  public videoStream: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private loading: MessageService,
    private initStudent: InitStudentService) {
      this.user = this.accountService.getUserData();
      this.userRoles = this.accountService.getRolesByUser();
      this.structureFile = `${this.contentFile}/structure-files.json`;
      this.activatedRoute.params.subscribe(params => {
        this.itemSerie = params['serie'];
        this.serieType = parseInt(params['serieType']);
        if (this.itemSerie) {
          this.loadSerieByItem(this.itemSerie);
        } else {
          this.router.navigate([this.returnUrl]);
        }
      });
  }

  ngOnInit(): void {
    this.loading.hideLoading();
    this.initStudent.setConfigPage();
    this.returnUrl = '/student/course';
    this.videoStream = this.user.username == 'giullianoalvaro@gmail.com';
  }

  loadSerieByItem(serie: string) {
    this.loading.showLoading();
    this.getSerieByItem(serie);
    this.loading.hideLoading();
  }

  private getSerieByItem(serie: string): any {
    this.getJSON().subscribe(data => {
      let content: any;
      if (this.serieType === SerieType.Kids) {
        content = data.contents.find((x: { serieType: string; }) => x.serieType == 'kids');
      } else {
        content = data.contents.find((x: { serieType: string; }) => x.serieType == 'regular');
      }

      for (let item of content.series) {
        if (item.item === serie) {
          this.serieContent = item;
          this.contentPath = `${this.contentFile}/pecm/${this.serieType === SerieType.Kids ? 'kids' : 'regular'}/${item.folderName}/`
          return;
        }
      }
    });
  }

  // private getSerieByItem(serie: string): any {
  //   this.getJSON().subscribe(data => {
  //     let content: any;
  //     if (this.canSeeAllSeries()) {
  //       content = data.contents.find((x: { serieType: string; }) => x.serieType == 'kids');
  //       content += data.contents.find((x: { serieType: string; }) => x.serieType == 'regular');
  //     } else {
  //       if (this.user.userKids) {
  //         content = data.contents.find((x: { serieType: string; }) => x.serieType == 'kids');
  //       } else {
  //         content = data.contents.find((x: { serieType: string; }) => x.serieType == 'regular');
  //       }
  //     }
      
  //     for (let item of content.series) {
  //       if (item.item === serie) {
  //         this.serieContent = item;
  //         this.contentPath = `${this.contentFile}/pecm/${this.user.userKids ? 'kids' : 'regular'}/${item.folderName}/`;
  //         return;
  //       }
  //     }
  //   });
  // }

  canSeeAllSeries(): boolean {
    let role = "EST1TS7AL36";
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some(x => x.keyDefault == role);
    } else {
      return false;
    }
  }

  private getJSON(): Observable<any> {
    var call = this.http.get<any>(this.structureFile);
    return call.pipe(map(response => {
      return response
    }));
  }

  viewClass(itemSerie: string, itemEpisode: string, index: number, videoStream: boolean = false): void {
    this.loading.showLoading();
    if (videoStream) {
      this.router.navigate(['/student/contentview-streaming', { serie: itemSerie, episode: itemEpisode, index: index+1 }], {state: { data: this.getEpisode(itemSerie, itemEpisode) }});
    } else {
      this.router.navigate(['/student/contentview', { serie: itemSerie, episode: itemEpisode, index: index+1 }], {state: { data: this.getEpisode(itemSerie, itemEpisode) }}); 
    }
    this.loading.hideLoading();
  }

  private getEpisode(itemSerie: string, itemEpisode: string): any {
    this.contentPath = `${this.contentFile}/pecm/${this.serieType === SerieType.Kids ? 'kids' : 'regular'}/${this.serieContent.folderName}/`;
    const video = this.contentPath + itemEpisode + '/' + itemEpisode + '.mp4';
    const docPdf = this.contentPath + itemEpisode + '/' + itemEpisode + 'AP.pdf';
    const videoType = 'video/mp4';
    const subtitleEn = this.contentPath + itemEpisode + '/' + itemEpisode + 'EN.vtt';
    const subtitlePt = this.contentPath + itemEpisode + '/' + itemEpisode + 'PT.vtt';

    return {
      video,
      docPdf,
      videoType,
      subtitleEn,
      subtitlePt,
      serieName: this.serieContent.name,
      episodeName: itemEpisode
    };
  }
}
