import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Student, Teacher } from '@app/admin/model';
import { User } from '@app/auth/model';
import { AccountService } from '@app/auth/services';
import { ChatMessage } from '@app/message/model';
import { ChatMessageService } from '@app/message/services';
import { ModuleType, ModuleTypeLabelEn, TypeResponse } from '@app/shared/enum';
import { MessageService } from '@app/shared/services';
import { InitStudentService } from '@app/student/services';
import { InitTeacherService } from '@app/teacher/services';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'chat-message-workspace',
  templateUrl: './chat-message-workspace.component.html',
  styleUrls: ['./chat-message-workspace.component.css']
})
export class ChatMessageWorkspaceComponent implements OnInit {
  @ViewChild('msgScroll') private msgScrollContainer: ElementRef;

  public module: ModuleType;
  public moduleStr: string;
  public byRoute: string; //'/admin/home'
  private returnUrl = '/';

  public contactStr: string = '';

  public user: User = new User();
  private userRoles: Array<any>;
  private student: Student;
  private teacher: Teacher;

  public errorMessage = '';

  public contacts: Array<User> = [];
  public contactsSource$ = new BehaviorSubject([]);

  public messages: Array<ChatMessage> = [];
  public messagesSource$ = new BehaviorSubject([]);

  public senderUser: User;
  public recipientUser: User;
  public recipientUserVisible: boolean = false;

  public textMsg: string;

  public contactFilterText: string = '';

  constructor(private router: Router,
    private loading: MessageService,
    private activatedRoute: ActivatedRoute,
    private initTeacher: InitTeacherService,
    private initStudent: InitStudentService,
    private chatMessageService: ChatMessageService,
    private accountService: AccountService) {
      let user = accountService.getUserData();
      this.module = ModuleType[user.module];
      this.moduleStr = ModuleTypeLabelEn.get(this.module);
      this.loading.showLoading();
      if (this.module == ModuleType.Teacher) {
        this.initTeacher.setConfigPage(false, false);
      } else if (this.module == ModuleType.Student) {
        this.initStudent.setConfigPage(false, false);
      } else {
        this.router.navigate([this.returnUrl]);
      }
      this.getUserData(this.module);
      this.loading.hideLoading();
  }

  ngOnInit() { 
    this.contactFilterText = '';
    this.scrollToBottom();
  }

  filterContacts({target: {value}}) {
    const filteredContacts = this.contacts.filter(filtered => filtered.name.toLowerCase().includes(value.toLowerCase()));
    this.contactFilterText = value;
    this.contactStr = filteredContacts.toString();
    this.contactsSource$.next(filteredContacts);
  }

  scrollToBottom(): void {
    try {
      this.msgScrollContainer.nativeElement.scrollTop = this.msgScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
  }

  private getUserData(module: ModuleType) {
    var endpoint = module == ModuleType.Student
      ? this.initStudent.getStudentUser().pipe(first())
      : this.initTeacher.getTeacherUser().pipe(first());

    endpoint.subscribe((response: any) => {
      if (module == ModuleType.Student) {
        this.student = response;
        this.user = this.initStudent.user;  
      } else if (module == ModuleType.Teacher) {
        this.teacher = response;
        this.user = this.initTeacher.user;
      }
      this.getContacts(module);
      this.loading.hideLoading();
    }, (error: any) => {
      this.errorMessage = 'Erro ao carregar os dados do usuário.';
      this.loading.hideLoading();
    });
  }

  getContacts(module: ModuleType) {
    if (this.contactFilterText)
      return;
    
    var endpoint = module == ModuleType.Student
      ? this.chatMessageService.contactsByStudent(this.student.id)
      : this.chatMessageService.contactsByTeacher(this.teacher.id);

    endpoint.subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.contacts = response.data;
      } else {
        this.contacts = [];
      }
      this.contactsSource$.next(this.contacts);

      setTimeout(() => {
        this.getContacts(module);
      }, 2800);

    }, (error: any) => {
      this.errorMessage = 'Erro ao carregar os contatos.';
    });
  }

  chatRoom(recipientUserId: any) {
    this.messages = [];
    this.messagesSource$.next(this.messages);
    if (recipientUserId) {
      this.chatMessageService.getMessages(this.user.id, recipientUserId)
      .subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.messages = response.data.messages;
          this.senderUser = response.data.senderUser;
          this.recipientUser = response.data.recipientUser;
          this.recipientUserVisible = true;
          this.readMessages();
        } else {
          this.messages = [];
          this.senderUser = null;
          this.recipientUser = null;
          this.recipientUserVisible = false;
        }
        this.messagesSource$.next(this.messages);
        this.getContacts(this.student != null ? ModuleType.Student : ModuleType.Teacher);
        setTimeout(() => {
          this.scrollToBottom();
          this.checkMessages();
        }, 300);
      }, (ex: any) => {
        if (ex.error) {
          this.errorMessage = ex.error.message;
        }
        this.recipientUserVisible = false;
      });
    }
  }

  triggerSendMsg(event: any, value: any) {
    console.log(event);
    if (event.ctrlKey && event.key === 'Enter') {
      /*
        cannot make textarea produce a next line.
      */
      var text = document.getElementById("msgr_input");
      if (text) {
        this.sendMsg();
      }
      event.preventDefault();
    }
    // else if (event.key === 'Enter') {
    //   event.preventDefault();
    //   console.log("submit!");
    // }
  }

  sendMsg() {
    if (this.textMsg && this.recipientUser && this.senderUser) {
      var chatMsg = {
        recipientUserId: this.recipientUser.id,
        senderUserId: this.senderUser.id,
        textMessage: this.textMsg,
      };
      var chatMessage = new ChatMessage(chatMsg);

      this.chatMessageService.addMessage(chatMessage)
      .pipe(first())
      .subscribe((response: any) => {
        this.messages = [];
        this.messagesSource$.next(this.messages);
        if (response.typeResponse == TypeResponse.Success) {
          this.messages = response.data.messages;
          this.senderUser = response.data.senderUser;
          this.recipientUser = response.data.recipientUser;
          this.recipientUserVisible = true;
        } else {
          this.messages = [];
          this.senderUser = null;
          this.recipientUser = null;
          this.recipientUserVisible = false;
        }
        this.textMsg = '';
        this.messagesSource$.next(this.messages);
        this.scrollToBottom();
        this.loading.hideLoading();
      }, (ex: any) => {
        if (ex.error) {
          this.errorMessage = ex.error.message;
        }
        this.recipientUserVisible = false;
        this.loading.hideLoading();
      });
    }
  }

  checkMessages() {
    if (this.recipientUser && this.senderUser) {
      this.chatMessageService.checkNewMessages(this.recipientUser.id, this.senderUser.id)
      .subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          if (response.data) {
            this.chatRoom(this.recipientUser.id);
            this.readMessages();
          } else {
            // setTimeout(() => {
            //   this.checkMessages();
            // }, 2400);
          }
        }
      }, (error: any) => {
        this.errorMessage = 'Erro ao verificar as mensagens.';
      });
    }
  }

  readMessages() {
    if (this.recipientUser && this.senderUser) {
      this.chatMessageService.readMessages(this.senderUser.id, this.recipientUser.id)
      .subscribe((response: any) => {
        return;
      }, (error: any) => {
        this.errorMessage = 'Erro ao verificar as mensagens.';
      });
    }
  }
}