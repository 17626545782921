import { formatDate } from '@angular/common';
import { User } from '@app/auth/model';
import { ContentEpisode } from '@app/content/model';
import { Base } from '@app/shared/model';

export class ContentAudit extends Base {
    user: User;
    userId: number;

    contentEpisode: ContentEpisode;
    contentEpisodeId: number;

    watchedDate: Date;
    duration: Date;

    constructor(contentResponse: any) {
        super(contentResponse);
    
        this.userId = parseInt(contentResponse.userId);
        if (contentResponse && contentResponse.userId > 0) {
            this.user = contentResponse.user;
        }

        this.contentEpisodeId = parseInt(contentResponse.contentEpisodeId);
        if (contentResponse && contentResponse.contentEpisodeId > 0) {
            this.contentEpisode = contentResponse.contentEpisode;
        }
        
        if (contentResponse.watchedDate) {
            this.watchedDate = new Date(formatDate(contentResponse.startDate, 'yyyy-MM-dd', 'en-US'));
        } else {
            this.watchedDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        }

        this.duration = contentResponse.duration;
    }
}
