import { Component, Input, OnInit } from '@angular/core';
import { StudentClassesCourseService, TeacherService } from '@app/admin/services';
import { TypeResponse, StatusClass, StatusClassLabel } from '@app/shared/enum';
import { MessageService } from '@app/shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { default as swal } from 'sweetalert2'

@Component({
  selector: 'calendar-teacher-changes',
  templateUrl: './calendar-teacher-changes.component.html',
  styleUrls: ['./calendar-teacher-changes.component.css']
})
export class CalendarTeacherChangesComponent implements OnInit {
  @Input("studentCourseClassId") studentCourseClassId: any;
  
  public returnMessage: string = '';
  public options: Options;
  public flagSearchTeacher = false;
  public validTeacher: boolean = true;
  public errorMessage: string;
  public teacherDataList: Array<any>;
  public teacherData: any;
  public teacherList: any = [];
  public teacherId: number = 0;

  constructor(
    private studentClassesCourseService: StudentClassesCourseService,
    private teacherService: TeacherService,
    private loading: MessageService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.options = {
      width: '300'
    };

    this.loadTeachers();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  loadTeachers() {
    this.loading.showLoading();
    this.teacherService.all().subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.teacherDataList = this.getTemplateTeacherList(response.data);
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
      }
      this.loading.hideLoading();
    }, error => {
      this.returnMessage = 'Erro ao carregar os dados dos professores.';
      this.loading.hideLoading();
    });
  }

  getTemplateTeacherList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.teacherList = data;
      let arrayTeacherList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id,
          text: data[i].id + " | " + data[i].name,// + " | " + data[i].email,
          additional: {
            email: data[i].email
          }
        };
        arrayTeacherList.push(element);
      }
      return arrayTeacherList;
    }
    return new Array<any>();
  }

  setTeacher(value: any) {
    this.teacherId = value;
    this.teacherData = this.teacherList.find(x => x.id == value);
  }

  done() {
    this.loading.showLoading();
    this.errorMessage = '';
    if (this.teacherId > 0) {
      this.studentClassesCourseService.changeTeacher(this.studentCourseClassId, this.teacherId).subscribe((response: any) => {
        this.loading.hideLoading();
        if (response.typeResponse == TypeResponse.Success) {
          if (response.data === true) {
            this.changedClassSuccess(StatusClass.ChangedTeacher);
          } else {
            this.changedClassFailed(StatusClass.ChangedTeacher);
          }
        }
      }, (error: any) => {
        this.loading.hideLoading();
        this.changedClassFailed(StatusClass.ChangedTeacher);
      });
      this.cancel();
    } else {
      this.loading.hideLoading();
      this.validTeacher = false;
      this.errorMessage = "Nenhum(a) professor(a) selecionado.";
    }    
  }

  cancel() {
    this.dismissModal();
  }

  changedClassSuccess(status: StatusClass) {
    swal({
      type: 'success',
      title: `Aula ${StatusClassLabel.get(status)} com sucesso!`,
      showConfirmButton: false,
      timer: 2200
    });
  }

  changedClassFailed(status: StatusClass) {
    swal({
      type: 'warning',
      title: `Não foi possível manter a aula como ${StatusClassLabel.get(status)}!`,
      showConfirmButton: true,
    });
  }
}
