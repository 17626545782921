<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/marketing/groupnotificationlist']">Grupos</a>
    </li>
    <li class="breadcrumb-item active">Novo grupo</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div id="panel-2" class="panel">
    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
            Novo <span class="fw-300"><i>Grupo</i></span>
        </h2>
        <div class="panel-toolbar">
            <a href="javascript:void(0);" class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse">
                <i class="fal fa-minus text-white fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen">
                <i class="fal fa-expand text-primary fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Close">
                <i class="fal fa-times text-white fw-900"></i>
            </a>
        </div>
    </div>
    <div class="panel-container show">
        <div class="panel-content p-0">
            <form [formGroup]="entityForm" novalidate class="needs-validation" (ngSubmit)="onSubmit()">
                <div class="panel-content">
                    <div class="form-row">
                        <div class="col-md-8 mb-3">
                            <div *ngIf="editEntity">
                                <label>Id</label>
                                <br/>
                                <input type="text" id="id" formControlName="id" class="form-control" disabled="disabled"/>
                                <input type="hidden" id="createdOn" formControlName="createdOn"/>
                                <input type="hidden" id="updatedOn" formControlName="updatedOn"/>    
                            </div>
                        </div>
                        <div class="col-md-4 mb-3 mt-2 pull-right">
                            <div class="form-group">
                                <div class="custom-control custom-switch text-right">
                                    <input type="checkbox" class="custom-control-input" id="confirmation" formControlName="confirmation" checked="">
                                    <label class="custom-control-label" for="confirmation">Necessário confirmação?</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12 mb-3">
                            <label class="form-label" for="title">Título da notificação <span class="text-danger">*</span> </label>
                            <input type="text" id="title" formControlName="title" class="form-control" placeholder="Título da notificação" 
                                maxlength="50" [ngClass]="{ 'is-invalid': !validEntity && f.title.errors }" required>
                            <div *ngIf="!validEntity && f.title.errors" class="invalid-feedback">
                                <div *ngIf="f.title.errors.required">Por favor, informe o título da notificação.</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-3 mb-3">
                            <div class="form-group">
                                <label class="form-label" for="type">Grupo de Notificação <span class="text-danger">*</span> </label>
                                <select class="form-control" id="groupNotificationId" for="groupNotificationId" formControlName="groupNotificationId" placeholder="Grupo de Notificação" [(ngModel)]="groupNotificationValue"
                                    [ngClass]="{ 'is-invalid': !validEntity && f.groupNotificationId.errors }">
                                    <option *ngFor="let group of groupNotificationList" [value]="group.id" [selected]="groupNotificationValue == group.id">
                                        {{ group.name }}
                                    </option>
                                </select>
                                <div *ngIf="!validEntity && f.groupNotificationId.errors" class="invalid-feedback">
                                    <div *ngIf="f.groupNotificationId.errors.required">Por favor, informe o grupo de notificação.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 mb-3">
                            <div class="form-group">
                                <label class="form-label" for="messageType">Tipo de notificação <span class="text-danger">*</span> </label>
                                <select class="form-control" id="messageType" for="messageType" formControlName="messageType" placeholder="Tipo de Notificação" [(ngModel)]="messageTypeValue"
                                    [ngClass]="{ 'is-invalid': !validEntity && f.messageType.errors }">
                                    <option *ngFor="let item of messageTypeList" [value]="item.value" [selected]="messageTypeValue == item.value">
                                        {{ item.name }}
                                    </option>
                                </select>
                                <div *ngIf="!validEntity && f.messageType.errors" class="invalid-feedback">
                                    <div *ngIf="f.messageType.errors.required">Por favor, informe o tipo da mensagem.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 mb-3">
                            <div class="form-group">
                                <label class="form-label" for="module">Módulo <span class="text-danger">*</span> </label>
                                <select class="form-control" id="module" for="module" formControlName="module" placeholder="Módulo" [(ngModel)]="moduleTypeValue"
                                    [ngClass]="{ 'is-invalid': !validEntity && f.module.errors }">
                                    <option *ngFor="let item of moduleTypeList" [value]="item.value" [selected]="moduleTypeValue == item.value">
                                        {{ item.name }}
                                    </option>
                                </select>
                                <div *ngIf="!validEntity && f.module.errors" class="invalid-feedback">
                                    <div *ngIf="f.module.errors.required">Por favor, informe o módulo.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 mb-3">
                            <div class="form-group">
                                <label class="form-label" for="notificationReading">Data da notificação <span class="text-danger">*</span> </label>
                                <input type="date" id="notificationReading" formControlName="notificationReading" class="form-control" placeholder="Data da notificação"
                                    [ngClass]="{ 'is-invalid': !validEntity && f.notificationReading.errors }" required>
                                <div *ngIf="!validEntity && f.notificationReading.errors" class="invalid-feedback">
                                    <div *ngIf="f.notificationReading.errors.required">Por favor, informe a data da notificação.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12 mb-3">
                            <label class="form-label" for="description">Texto da notificação <span class="text-danger">*</span> </label>
                            <textarea id="description" formControlName="description" class="form-control" placeholder="Texto da notificação" rows="5" maxlength="2000"
                                [ngClass]="{ 'is-invalid': !validEntity && f.description.errors }" required
                                (change)="onDescriptionChange($event.target.value)"
                                (keydown)="onDescriptionChange($event.target.value)"
                                (keyup)="onDescriptionChange($event.target.value)"></textarea>
                            <span class="badge badge-primary badge-pill pull-right text-white fs-md mt-2">2000 / {{2000 - description.length}}</span>
                            <div *ngIf="!validEntity && f.description.errors" class="invalid-feedback">
                                <div *ngIf="f.description.errors.required">Por favor, informe a descrição da notificação.</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validEntity">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true"><i class="fal fa-times"></i></span>
                        </button>
                        {{errorMessage}}
                    </div>
                    <div *ngIf="listErrorMessage && !validEntity" class="invalid-feedback">
                        <div *ngFor="let error of listErrorMessage">{{error}}</div>
                    </div>
                    <hr>
                    <div class="row no-gutters">
                        <div class="col-md-12 ml-auto text-right">
                            <div class="btn-group btn-panel">
                                <button id="js-login-btn" class="btn btn-primary" [disabled]="submitted">
                                    <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                    Salvar
                                </button>
                                <button id="js-cancel-btn" class="btn btn-default" (click)="onCancel()">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
