export enum WritingTestReview {
    Pending = 0,
    Done = 1
}

export const WritingTestReviewLabel = new Map<number, string>([
    [WritingTestReview.Done, 'Concluído'],
    [WritingTestReview.Pending, 'Pendente']
]);

export const WritingTestReviewCss = new Map<number, string>([
    [WritingTestReview.Done, 'review-done'],
    [WritingTestReview.Pending, 'review-pending']
]);
