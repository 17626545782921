<div class="page-wrapper">
    <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
            <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                <div class="d-flex align-items-center container p-0">
                    <header-logo></header-logo>
                    <span class="text-white opacity-50 ml-auto mr-2 hidden-sm-down">
                        Já possui uma conta?
                    </span>
                    <a href="#/auth/login" class="btn btn-sm btn-outline-primary text-white ml-sm-0">
                        Entrar
                    </a>
                </div>
            </div>
            <div class="flex-1" style="background: url(/assets/img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
                <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                    <div class="row">
                        <div class="col-xl-12">
                            <h2 class="fs-xxl fw-500 mt-4 text-white text-center">
                                Obrigado por registrar! Por favor, verifique seu e-mail.
                                <small class="h3 fw-300 mt-3 mb-5 text-white opacity-70 hidden-sm-down">
                                    Enviamos uma mensagem para <strong class="text-white">{{confirm}}</strong> com um link para ativar sua conta.
                                </small>
                            </h2>
                        </div>
                        <div class="col-xl-6 ml-auto mr-auto">
                            <div class="card p-4 rounded-plus bg-faded">
                                <div class="alert alert-primary text-dark" role="alert">
                                    O recebimento do e-mail de verificação pode sofrer um atraso em até 10 minutos.
                                </div>
                                <a href="javascript:void(0);" class="h4" (click)="send_again()">
                                    <i class="fal fa-chevron-right mr-2"></i> Não recebeu o e-mail? Clique aqui
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <footer-auth></footer-auth>
            </div>
        </div>
    </div>
</div>
