import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'footer-form',
  templateUrl: './footer-form.component.html',
  styleUrls: ['./footer-form.component.css']
})
export class FooterFormComponent implements OnInit {
  @Input("auxSaveAction") auxSaveAction: any;
  @Input("auxCancelAction") auxCancelAction: any;

  @Output("saveClick") saveClick = new EventEmitter();
  @Output("cancelClick") cancelClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  saveClicked() {
    this.saveClick.emit();
  }

  cancelClicked() {
    this.cancelClick.emit();
  }
}
