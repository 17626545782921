import { Component, OnInit } from '@angular/core';
import { AccountService } from '@app/auth/services';
import { User } from '@app/auth/model';
import { Router } from '@angular/router';
import { default as swal } from 'sweetalert2';
import { first, timeout } from 'rxjs/operators';
import {
  MessageTypeCss,
  MessageTypeLabel,
  ModuleType,
  ModuleTypeCss,
  ModuleTypeLabel,
  ModuleTypeLabelEn,
  TypeResponse,
} from '@app/shared/enum';
import { AlertService, MessageService } from '@app/shared/services';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ChangePassword } from '@app/auth/model/changePassword';
import { MessageQueueService } from '@app/marketing/services';
import { InitTeacherService } from '@app/teacher/services';
import { Teacher } from '@app/admin/model';
import { ChatMessageService } from '@app/message/services';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  public user: User;
  private returnUrl: string = '/admin/home';
  resetSuccess: any;
  closeResult: string;
  public validEntity: boolean;
  public errorMessage = '';
  public listErrorMessage: any = [];
  submitted = false;
  resetPasswordForm: any;
  adminUser: boolean = false;

  showUpgrade: boolean = false;
  showLibrary: boolean = false;

  private userRoles: Array<any>;

  private teacher: Teacher;
  private teacherId: number = 0;

  public returnMessage = '';
  // userId: any;
  moduleId: any = 0;
  notificationId: any = 0;
  sidNotification: any = '';

  newMessagesCount: number = 0;

  notificationCount: number = 0;
  notificationStr: string = 'notificação';

  public profileUrl: string = '';
  public availabilityUrl: string = '';

  public notificationlist: any = [];
  public showNotificationList: boolean;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private loading: MessageService,
    private alertService: AlertService,
    private accountService: AccountService,
    private initTeacher: InitTeacherService,
    private chatMessageService: ChatMessageService,
    private messageQueueService: MessageQueueService
  ) {
    this.user = this.accountService.getUserData();
  }

  ngOnInit(): void {
    this.resetSuccess = false;
    this.validEntity = true;
    this.errorMessage = '';
    this.listErrorMessage = [];
    this.onInitForm(this.user);
    this.setModuleId(this.user);
    this.loadNotifications();
    this.countNewMessages();
    this.newMessageQueueCount();
    if (this.moduleId == ModuleType.Teacher) {
      this.checkHaveTeacher();
    }
    this.profileUrl = `/${this.user.module}/profile`.toLowerCase();
    this.availabilityUrl = `/admin/availability`.toLowerCase();
    this.userRoles = this.accountService.getRolesByUser();
  }

  checkHaveTeacher() {
    if (this.initTeacher.user != null && this.initTeacher.user.id > 0) {
      this.initTeacher
        .getTeacherUser()
        .pipe(first())
        .subscribe(
          (response: any) => {
            this.teacher = response;
            this.teacherId = this.teacher?.id;
            this.user = this.initTeacher.user;
          },
          (error: any) => {
            console.log(error);
          }
        );
    }
  }

  countNewMessages() {
    if (this.user?.userId) {
      this.chatMessageService?.newMessagesCount(this.user?.userId).subscribe(
        (response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.newMessagesCount = response.data.count;
            this.returnMessage = '';
            if (response.data.changed === true) {
              this.alertService?.showInfo(
                'Há novas mensagens disponíveis no chat!',
                'Chat English Coffee'
              );
            }
          } else {
            this.returnMessage = response.message;
          }
          setTimeout(() => this.countNewMessages(), 2000);
        },
        (error: any) => {
          this.returnMessage = 'Erro ao carregar as mensagens.';
        }
      );
    }
  }

  newMessageQueueCount() {
    if (this.user?.userId) {
      this.messageQueueService
        .newMessageQueueCount(this.user?.userId)
        .subscribe(
          (response: any) => {
            if (response.typeResponse == TypeResponse.Success) {
              this.notificationCount = response.data.count;
              this.notificationStr =
                this.notificationCount > 1 ? 'notificações' : 'notificação';
              this.returnMessage = '';
              if (response.data.changed === true) {
                this.alertService.showInfo(
                  'Há novas notificações disponíveis!',
                  'Notificações'
                );
              }
            } else {
              this.returnMessage = response.message;
            }
            setTimeout(() => this.newMessageQueueCount(), 2000);
          },
          (error: any) => {
            this.returnMessage = 'Erro ao carregar as notificações.';
          }
        );
    }
  }

  public isTeacher() {
    return `${this.user.module}`.toLowerCase() === 'teacher';
  }

  public isStudent() {
    return `${this.user.module}`.toLowerCase() === 'student';
  }

  public getTeacherId() {
    if (this.isTeacher()) {
      return this.teacherId;
    }
    return 0;
  }

  public logout() {
    this.accountService.logout();
  }

  routerLinkModule(route: string, module: string = '') {
    let routeLink = `/${
      module !== '' ? module : this.user.module
    }/${route}`.toLowerCase();
    this.router.navigate([routeLink]);
  }

  menuClick(url: any): void {
    if (url) {
      this.router.navigate([url]);
    }
    return;
  }

  private setModuleId(user: User) {
    for (let enModule in ModuleType) {
      let moduleStr = ModuleTypeLabelEn.get(parseInt(enModule));
      if (user.module == moduleStr) {
        this.moduleId = parseInt(enModule);
      }
    }
  }

  loadNotifications() {
    this.messageQueueService.getByUserGuid(this.user?.userId, true).subscribe(
      (response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.designItems(response.data);
          this.notificationCount = response.data.length;
          this.notificationStr =
            this.notificationCount > 1 ? 'notificações' : 'notificação';
          this.showNotificationList = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showNotificationList = false;
        }
      },
      (error: any) => {
        this.showNotificationList = false;
        this.returnMessage = 'Erro ao carregar as notificações.';
      }
    );
  }

  designItems(lista: any[]) {
    lista.forEach(function (e) {
      if (e.messageType >= 0) {
        let messageTypeStr = MessageTypeLabel.get(e.messageType);
        let messageTypeCss = MessageTypeCss.get(e.messageType);
        e.designMessageType =
          "<span class='badge w-30 pull-right " +
          messageTypeCss +
          " badge-pill'>" +
          messageTypeStr +
          '</span>';
      }

      if (e.module >= 0) {
        let moduleTypeStr = ModuleTypeLabel.get(e.module);
        let moduleTypeCss = ModuleTypeCss.get(e.module);
        e.designModule =
          "<span class='badge w-30 pull-right " +
          moduleTypeCss +
          " badge-pill'>" +
          moduleTypeStr +
          '</span>';
      }
    });
    this.notificationlist = lista;
  }

  onInitForm(data: any) {
    this.resetPasswordForm = this.formBuilder.group(
      {
        email: new FormControl(data.email),
        userId: new FormControl(data.userId),
        oldPwd: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            ,
            Validators.maxLength(20),
          ],
        ],
        newPwd: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            ,
            Validators.maxLength(20),
          ],
        ],
        confirmNewPwd: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            ,
            Validators.maxLength(20),
          ],
        ],
      },
      { validator: this.checkIfMatchingPasswords('newPwd', 'confirmNewPwd') }
    );
  }

  checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];

      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  confirmedNotification(notificationId: any, close: boolean = true) {
    this.loading.showLoading();
    if (!this.user?.userId) {
      this.loading.hideLoading();
      this.modalService.dismissAll();
      window.location.reload();
    }

    this.messageQueueService
      .confirmed(notificationId, this.user?.userId)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (close) {
            this.loading.hideLoading();
            this.modalService.dismissAll();
            this.loadNotifications();
          }
        },
        (error: any) => {
          if (error.error.error === 'invalid_token') {
            this.router.navigate(['/auth/login']);
            return;
          }
          window.location.reload();
        }
      );
  }

  // :: Generic ::
  validAfterSubmit(dataReturn: any) {
    this.listErrorMessage = [];
    if (dataReturn.typeResponse == TypeResponse.Success) {
      this.validEntity = true;
      this.modalService.dismissAll();
      timeout(1000);
      swal({
        type: 'success',
        title: 'Senha alterada com sucesso!',
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.router.navigate([this.returnUrl]);
        return;
      });
    } else {
      this.validEntity = false;
      if (dataReturn.message) {
        this.errorMessage = dataReturn.message;
        this.listErrorMessage.push(dataReturn.message);
      } else if (
        dataReturn.data &&
        dataReturn.data.errors &&
        dataReturn.data.errors.length > 0
      ) {
        this.errorMessage =
          'Não foi possível trocar a senha. Verifique as informações abaixo:';
        for (var i = 0; i < dataReturn.data.errors.length; i++) {
          this.listErrorMessage.push(dataReturn.data.errors[i].description);
        }
      } else {
        this.errorMessage =
          'Não foi possível trocar a senha, por favor tente novamente.';
        this.listErrorMessage.push(this.errorMessage);
      }
    }
    this.submitted = false;
    this.loading.hideLoading();
  }

  changePassword(content: any) {
    this.onInitForm(this.user);
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'sm',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'Save') {
            this.submitChangePassword();
          }
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  submitChangePassword() {
    this.loading.showLoading();
    this.submitted = true;
    this.validEntity = true;

    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validEntity = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    let userData = {
      oldPassword: this.resetPasswordForm.value.oldPwd,
      newPassword: this.resetPasswordForm.value.newPwd,
      confirmNewPassword: this.resetPasswordForm.value.confirmNewPwd,
      userId: this.user.userId,
    };

    var change = new ChangePassword(userData);
    this.accountService
      .changePassword(change)
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.error && !response.ok) {
            this.validEntity = false;
            this.submitted = false;
            this.loading.hideLoading();
            this.errorMessage = response.error.message;
            //User user = await DataContext.Users.SingleOrDefaultAsync(u => u.Id == "SomeId");
          } else {
            this.validAfterSubmit(response);
          }
        },
        (error) => {
          this.validEntity = false;
          this.submitted = false;
          this.loading.hideLoading();
          this.errorMessage = error;
        }
      );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetPasswordForm.controls;
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();

      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  checkFeatsPermission() {
    return (
      this.checkHavePermission('ADM1DA1DA1') ||
      this.checkHavePermission('EST1DA6DA19') ||
      this.checkHavePermission('PRO2DA13DA26')
    );
  }

  checkPermission() {
    return (
      this.checkHavePermission('ADM0AT23CH40') ||
      this.checkHavePermission('EST1AT24CH41') ||
      this.checkHavePermission('PRO2AT25CH42')
    );
  }

  checkChatPermission() {
    return (
      this.checkHavePermission('ADM0AT23CH40') ||
      this.checkHavePermission('EST1AT24CH41') ||
      this.checkHavePermission('PRO2AT25CH42')
    );
  }

  private checkHavePermission(role: any): boolean {
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some((x) => x.keyDefault == role);
    } else {
      return false;
    }
  }

  viewNotificationListUser(content: any) {
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  viewNotification(content: any, noticeId: number, sidNotification: string) {
    this.notificationId = noticeId;
    this.sidNotification = sidNotification;
    this.modalService.dismissAll();
    this.confirmedNotification(noticeId, false);
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
}
