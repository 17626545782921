import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

declare global {
  interface Window { strum: Function; }
}

enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => {
    console.log(`Bootstrap success`)
  })
  .catch((err) => {
    console.error(err)
  });
