import { Base } from './../../shared/model/base';
import { City, State } from '.';

export class Responsible extends Base {
    name: string;
    birthdate: Date;
    email: string;
    phone: string;
    cpf: string;
    zipcode: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    
    city: City;
    cityId: number;
    
    state: State;
    stateId: number;
    
    constructor(contentResponse: any) {
        super(contentResponse);

        this.name = contentResponse.name;
        this.birthdate = new Date(contentResponse.birthdate),
        this.email = contentResponse.email;
        this.phone = contentResponse.phone;
        this.cpf = contentResponse.cpf;
        this.zipcode = contentResponse.zipcode;
        this.street = contentResponse.street;
        this.number = contentResponse.number;
        this.complement = contentResponse.complement;
        this.neighborhood = contentResponse.neighborhood;
        
        this.cityId = parseInt(contentResponse.cityId);
        if (contentResponse && contentResponse.cityId > 0) {
            this.city = contentResponse.city;
        }

        this.stateId = parseInt(contentResponse.stateId);
        if (contentResponse && contentResponse.stateId > 0) {
            this.state = contentResponse.state;
        }
    }
}
