<div class="form-group row">
    <div class="col-12">
        <form [formGroup]="studentCourseForm" novalidate class="needs-validation" (ngSubmit)="onSubmit()">
            <div class="panel-content">
                <div class="form-row">
                    <div class="col-12">
                        <input type="hidden" id="id" formControlName="id" />
                        <input type="hidden" id="createdOn" formControlName="createdOn"/>
                        <input type="hidden" id="updatedOn" formControlName="updatedOn"/>
                        <div class="form-group row mb-2">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label" for="courseId">Curso selecionado</label>
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete 
                                            [data]="courseList"  
                                            [initialValue]="course"
                                            searchKeyword="name"
                                            placeholder="Selecione o curso"
                                            (selected)='selectEventCourse($event)'
                                            [itemTemplate]="itemTemplate"
                                            [notFoundTemplate]="notFoundTemplate">                                 
                                        </ng-autocomplete>
                                        
                                        <ng-template #itemTemplate let-item>
                                            <a [innerHTML]="item.name"></a>
                                        </ng-template>
                                        
                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                    <div *ngIf="!validCourse && f.courseId.errors" class="invalid-feedback">
                                        <div *ngIf="f.courseId.errors.required">Por favor, informe o curso.</div>
                                        <div *ngIf="f.courseId.errors.invalidValue">Curso selecionado é inválido.</div>
                                    </div>
                                </div>
                                <div class="form-group row mb-2" *ngIf="flagCourseSelected == true">
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="card-title text-primary fw-700">Dados do Curso</h5>
                                                <div class="form-group row">
                                                    <div class="col-12">
                                                        <p class="card-text">
                                                            Nome do Curso: <span class="fw-700">{{course?.name}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12">
                                                        <p class="card-text">
                                                            Professor: <span class="fw-700">{{course?.teacher?.name}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-6">
                                                        <p class="card-text" [ngClass]="{ 'text-danger': course?.quantityMonth == 0 }">
                                                            Nº de meses: <span class="fw-700">{{course?.quantityMonth}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-6">
                                                        <p class="card-text" [ngClass]="{ 'text-danger': course?.quantityMonth == 0 }">
                                                            Nº de alunos: <span class="fw-700">{{course?.quantityStudent}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12">
                                                        <p class="card-text">
                                                            Pacote: <span class="fw-700">{{course?.product?.name}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12">
                                                        <p class="card-text">
                                                            Valor Total: <span class="fw-700">{{course?.product?.value | currency : 'R$'}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12" *ngIf="validCourseListMessage && !validCourse">
                                                        <hr>
                                                        <p *ngFor="let error of validCourseListMessage" class="card-text mb-1">
                                                            <small class="text-danger">{{error}}</small>
                                                        </p>
                                                        <br>
                                                        <button type="button" class="btn btn-info btn-sm" title="Revisar Curso" (click)="onReviewCourse()">
                                                            <span class="far fa-edit mr-1"></span>
                                                            Revisar Curso
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-xs-12 mt-3">
                                <div class="form-group">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="isPriceCustom" formControlName="isPriceCustom" (change)="onSetCustomPrice($event)">
                                        <label class="custom-control-label" for="isPriceCustom">Pacote/valores diferenciados?</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-xs-12 mt-3" *ngIf="flagCustomPrice">
                                <label class="form-label" for="customPriceValue">Preço customizado do pacote <span class="text-danger">*</span> </label>
                                <input type="text" id="customPriceValue" formControlName="customPriceValue" currencyMask class="form-control" placeholder="Preço customizado do pacote"
                                    [ngClass]="{ 'is-invalid': !validStudentCourse && f.customPriceValue.errors }">
                                <p class="card-text text-right mt-2 mb-1">
                                    Valor Total: <span class="fw-700">{{f.customPriceValue.value | currency : 'R$'}}</span>
                                </p>
                                <p class="card-text text-right mt-2 mb-1">
                                    Nº de meses: <span class="fw-700">{{numberOfMonth}}</span>
                                </p>
                                <p class="card-text text-right mt-2 mb-1">
                                    Valor mensal: <span class="fw-700">{{f.customPriceValue.value / numberOfMonth | currency : 'R$'}}</span>
                                </p>
                                <div *ngIf="!validStudentCourse && f.customPriceValue.errors" class="invalid-feedback">
                                    <div *ngIf="f.customPriceValue.errors.required">Por favor, informe o preço customizado do pacote.</div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form-group row mb-2">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label" for="studentId">Selecione um(a) aluno(a) <span class="text-danger">*</span> </label>
                                    <br>
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete
                                            [data]="selectStudentList"
                                            [initialValue]="student"
                                            searchKeyword="name"
                                            placeholder="Selecione um(a) aluno(a)"
                                            (selected)='selectEventStudent($event)'
                                            [itemTemplate]="itemTemplate"
                                            [notFoundTemplate]="notFoundTemplate">
                                        </ng-autocomplete>
                                        
                                        <ng-template #itemTemplate let-item>
                                            <a [innerHTML]="item.name"></a>
                                        </ng-template>
                                        
                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                    <div *ngIf="!validStudent && f.studentId.errors" class="invalid-feedback">
                                        <div *ngIf="f.studentId.errors.required">Por favor, informe o aluno.</div>
                                        <div *ngIf="f.studentId.errors.invalidValue">Aluno selecionado é inválido.</div>
                                    </div>
                                </div>
                                
                                <div class="form-group row mb-2" *ngIf="flagStudentSelected == true">
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="card-title text-primary fw-700">Dados do Aluno</h5>
                                                <div class="form-group row">
                                                    <div class="col-12">
                                                        <p class="card-text">
                                                            Nome: <span class="fw-700">{{student?.name}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <p class="card-text">
                                                            E-mail: <span class="fw-700">{{student?.email}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <p class="card-text">
                                                            Data de Nascimento: <span class="fw-700">{{student?.birthDate | date:'dd/MM/yyyy'}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <p class="card-text">
                                                            Local: <span class="fw-700">{{student?.cityName+'/'+student?.stateUf}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <p class="card-text">
                                                            Telefone: <span class="fw-700">{{student?.phone}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label class="form-label" for="googleDriveLink">Link do Google Drive <span class="text-danger">*</span> </label>
                                <input type="text" id="googleDriveLink" formControlName="googleDriveLink" class="form-control" placeholder="Link do Google Drive"
                                    [ngClass]="{ 'is-invalid': !validStudentCourse && f.googleDriveLink.errors }">
                                <div *ngIf="!validStudentCourse && f.googleDriveLink.errors" class="invalid-feedback">
                                    <div *ngIf="f.googleDriveLink.errors.required">Por favor, informe o link do Google Drive.</div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form-group row" *ngIf="!editMode">
                            <div class="col-md-3 mb-2">
                                <label class="form-label" for="startDate">Início do curso <span class="text-danger">*</span> </label>
                                <input type="date" id="startDate" formControlName="startDate" class="form-control" placeholder="Início do curso" 
                                [ngClass]="{ 'is-invalid': !validStudentCourse && f.startDate.errors }" 
                                    required (change)="onStartDateChange($event.target.value)">
                                <div *ngIf="!validStudentCourse && f.startDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.startDate.errors.required">Por favor, informe a data de início.</div>
                                </div>
                            </div>
                            <div class="col-md-3 mb-2">
                                <label class="form-label" for="endDate">Fim do curso <span class="text-danger">*</span> </label>
                                <input type="date" id="endDate" formControlName="endDate" class="form-control" placeholder="Fim do curso"
                                    [ngClass]="{ 'is-invalid': !validStudentCourse && f.endDate.errors }" required (change)="onEndDateChange($event.target.value)">
                                <div *ngIf="!validStudentCourse && f.endDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.endDate.errors.required">Por favor, informe a data de término.</div>
                                    <div *ngIf="f.endDate.errors.invalidValue">A data de término precisa ser maior que a data de início.</div>
                                </div>
                            </div>
                            <div class="col-md-3 mb-2">
                                <label class="form-label" for="installmentFirstDue">1ª mensalidade <span class="text-danger">*</span> </label>
                                <input type="date" id="installmentFirstDue" formControlName="installmentFirstDue" class="form-control" 
                                    placeholder="Vencimento da 1ª mensalidade"
                                    [ngClass]="{ 'is-invalid': !validStudentCourse && f.installmentFirstDue.errors }" required>
                                <div class="text-info small mt-1">
                                    <div>Vencimento da 1ª mensalidade</div>
                                </div>
                                <div *ngIf="!validStudentCourse && f.installmentFirstDue.errors" class="invalid-feedback">
                                    <div *ngIf="f.installmentFirstDue.errors.required">Por favor, informe o vencimento da 1ª mensalidade.</div>
                                </div>
                            </div>
                            <div class="col-md-3 mb-2">
                                <label class="form-label" for="dueDay">Dia de vencimento <span class="text-danger">*</span> </label>
                                <input type="number" id="dueDay" formControlName="dueDay" class="form-control"
                                    placeholder="Dia de vencimento das demais parcelas" minlength="1" maxlength="31" 
                                    [ngClass]="{ 'is-invalid': !validCourse && f.dueDay.errors }" required>
                                    <div class="text-info small mt-1">
                                    <div>Dia de vencimento das demais parcelas</div>
                                </div>
                                <div *ngIf="!validCourse && f.dueDay.errors" class="invalid-feedback">
                                    <div *ngIf="f.dueDay.errors.required">Por favor, informe a dia de vencimento das demais parcelas.</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="editMode">
                            <div class="col-md-3 mb-2">
                                <label class="form-label" for="startDate">Início do curso <span class="text-danger">*</span> </label>
                                <input type="date" id="startDate" formControlName="startDate" class="form-control" placeholder="Início do curso" 
                                disabled="disabled" [ngClass]="{ 'is-invalid': !validStudentCourse && f.startDate.errors }" 
                                    required (change)="onStartDateChange($event.target.value)">
                                <div *ngIf="!validStudentCourse && f.startDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.startDate.errors.required">Por favor, informe a data de início.</div>
                                </div>
                            </div>
                            <div class="col-md-3 mb-2">
                                <label class="form-label" for="endDate">Fim do curso <span class="text-danger">*</span> </label>
                                <input type="date" id="endDate" formControlName="endDate" class="form-control" placeholder="Fim do curso" disabled="disabled"
                                    [ngClass]="{ 'is-invalid': !validStudentCourse && f.endDate.errors }" required (change)="onEndDateChange($event.target.value)">
                                <div *ngIf="!validStudentCourse && f.endDate.errors" class="invalid-feedback">
                                    <div *ngIf="f.endDate.errors.required">Por favor, informe a data de término.</div>
                                    <div *ngIf="f.endDate.errors.invalidValue">A data de término precisa ser maior que a data de início.</div>
                                </div>
                            </div>
                            <div class="col-md-3 mb-2">
                                <label class="form-label" for="installmentFirstDue">1ª mensalidade <span class="text-danger">*</span> </label>
                                <input type="date" id="installmentFirstDue" formControlName="installmentFirstDue" placeholder="Vencimento da 1ª mensalidade" 
                                    class="form-control" [ngClass]="{ 'is-invalid': !validStudentCourse && f.installmentFirstDue.errors }" required>
                                <div class="text-info small mt-1">
                                    <div>Vencimento da 1ª mensalidade</div>
                                </div>
                                <div *ngIf="!validStudentCourse && f.installmentFirstDue.errors" class="invalid-feedback">
                                    <div *ngIf="f.installmentFirstDue.errors.required">Por favor, informe o vencimento da 1ª mensalidade.</div>
                                </div>
                            </div>
                            <div class="col-md-3 mb-2">
                                <label class="form-label" for="dueDay">Dia de vencimento <span class="text-danger">*</span> </label>
                                <input type="number" id="dueDay" formControlName="dueDay" placeholder="Dia de vencimento das demais parcelas" 
                                    minlength="1" maxlength="31" class="form-control" [ngClass]="{ 'is-invalid': !validCourse && f.dueDay.errors }" required>
                                    <div class="text-info small mt-1">
                                    <div>Dia de vencimento das demais parcelas</div>
                                </div>
                                <div *ngIf="!validCourse && f.dueDay.errors" class="invalid-feedback">
                                    <div *ngIf="f.dueDay.errors.required">Por favor, informe a dia de vencimento das demais parcelas.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validStudentCourse">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true"><i class="fal fa-times"></i></span>
                    </button>
                    {{errorMessage}}
                </div>
                <div *ngIf="listErrorMessage && !validStudentCourse" class="invalid-feedback">
                    <div *ngFor="let error of listErrorMessage">{{error}}</div>
                </div>
                <hr>
                <div class="row no-gutters">
                    <div class="col-12 ml-auto text-right">
                        <div class="btn-group btn-panel">
                            <button id="js-login-btn" class="btn btn-primary" [disabled]="submitted" (click)="onSubmit()">
                                <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                Salvar
                            </button>
                            <button id="js-cancel-btn" class="btn btn-default" (click)="onCancel()">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>