import { Base } from '@app/shared/model';
import { UserGroupNotification, Notification as Notice } from '.';

export class GroupNotification extends Base {
    name: string;
    email: string;
    description: string;
    userGroupNotifications: Array<UserGroupNotification>;
    notifications: Array<Notice>;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.name = contentResponse.name;

        this.email = contentResponse.email;

        this.description = contentResponse.description;

        if (contentResponse.notifications && contentResponse.notifications.lenght > 0) {
            this.notifications = contentResponse.notifications;
        }

        if (contentResponse.userGroupNotifications && contentResponse.userGroupNotifications.lenght > 0) {
            this.userGroupNotifications = contentResponse.userGroupNotifications;
        }
    }
}
