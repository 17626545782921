import { AddressService, BankService, StudentService } from '@app/admin/services';
import { Bank, State, Student, TeacherWithAvailability } from '@app/admin/model';
import { AccountType, RecurrenceDay, RecurrenceDayLabel } from '@app/shared/enum';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services';
import { UtilService } from '@app/shared/services/util.service';
import { User } from '@app/auth/model';
import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { default as swal } from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { first } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Profile } from '@app/student/model';
import { InitStudentService, ProfileService } from '@app/student/services';

@Component({ templateUrl: 'profile.component.html' })
export class ProfileComponent implements OnInit {
  private student: Student;
  public idStudent: number;

  private profile: Profile;
  
  public user: User = new User();
  public userId: any;

  submitted = false;
  public flagForeignPerson: boolean = false;
  returnUrl: string = '/student/home';

  public foreignPersonDisableFields = false;
  public profileForm: any;
  public validProfile: boolean;
  public updateProfile: boolean = false;
  public phoneMask: Array<string | RegExp>;
  public cepMask: Array<string | RegExp>;
  
  public errorMessage = '';
  public listErrorMessage: any[];

  public editMode: boolean;
  public stateList: any = [];
  public citiesList: any = [];
  public cepSearch: boolean = false;
  private datePipe = new DatePipe('pt-BR');

  requiredValidator = Validators.required;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private loading: MessageService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private studentService: StudentService,
    private initStudent: InitStudentService,
    private profileService: ProfileService,
    private addressService: AddressService
  ) {
    this.loading.showLoading();
    this.initStudent.setConfigPage(false, false);
    this.phoneMask = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.cepMask = [/[0-9]/, /\d/ ,/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    this.onInitForm();
  }

  ngOnInit(): void {
    this.getUserData();
  }

  getUserData() {
    if (this.initStudent.user != null && this.initStudent.user.id > 0) {
      this.initStudent.getStudentUser().pipe(first()).subscribe((response: any) => {
        this.student = response;
        this.user = this.initStudent.user;
        this.initialyze();
      }, (error: any) => {
        console.log(error);
        this.initialyze();
      });
    } else {
      this.initialyze();
    }
  }

  initialyze() {
    this.loadStates();
    this.editMode = false;
    this.userId = this.initStudent.user.id;
    this.loadProfile(this.student.id);
    this.loading.hideLoading();
  }

  onInitForm() {
    this.validProfile = true;
    this.flagForeignPerson = false;
    this.profileForm = this.formBuilder.group({
      teacherId: [0],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      birthDate: ['', [Validators.required]],
      userId: ['', [Validators.required]],

      email: ['', [Validators.required, Validators.maxLength(80)]],
      phone: [{value: '', disabled: this.foreignPersonDisableFields}, [Validators.required, Validators.maxLength(15)]],
      cpf: [{value: '', disabled: this.foreignPersonDisableFields}, Validators.required, Validators.maxLength(20)],
      zipCode: ['', [Validators.maxLength(8)]],
      street: ['', [Validators.required, Validators.maxLength(100)]],
      number: [''],
      complement: ['', [Validators.maxLength(20)]],
      neighborhood: ['', [Validators.required, Validators.maxLength(50)]],

      cityId: ['', [Validators.required]],
      cityName: [''],

      stateId: ['', [Validators.required]],
      stateUf: [''],

      country: [''],

      foreignPerson: [false]
    });

    this.profileForm.controls['cpf'].setErrors(null);
    this.profileForm.controls['phone'].setErrors(null);
    this.profileForm.controls['birthDate'].setErrors(null);
  }
  
  onSetEventsForm() {
    this.f.phone.valueChanges.subscribe((value: string) => {
      var strPhone = value.toString().replace('(', '').replace(')', '').replace('-', '').replace('_', '').replace(' ', '');
      var phoneNumber = parseInt(strPhone);
      if (!(phoneNumber >= 11111111111 && phoneNumber <= 99999999999)) {
        value = phoneNumber.toString();
        this.f.phone.status = "INVALID";
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.profileForm ? this.profileForm.controls : null;
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validProfile = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.profileForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validProfile = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.profile = new Profile(this.profileForm.value);
    
    this.profileService.save(this.profile)
    .pipe(first()).subscribe((dataReturn: any) => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.validProfile = true;
        this.afterSuccess();
      } else {
        this.validProfile = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn?.data?.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (let i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
        this.afterFailed();
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (ex: any) => {
      this.validProfile = false;
      this.submitted = false;
      this.loading.hideLoading();
      if (ex && ex.status == 404) {
        this.errorMessage = ex.error.message;
      } else {
        this.errorMessage = ex.ok === true ? "Erro ao salvar o perfil." : ex.error.message;
      }
      this.afterFailed();
    });
  }

  afterSuccess() {
    swal({
      type: 'success',
      title: `Perfil atualizado com sucesso!`,
      showConfirmButton: true
    }).then((result) => {
      this.loading.hideLoading();
      this.cancel();
      this.router.navigate([this.returnUrl]);
    });
  }

  afterFailed() {
    swal({
      type: 'warning',
      title: `Não foi possível atualizar o seu perfil!`,
      showConfirmButton: true,
    }).then((result) => {
      this.loading.hideLoading();
      this.cancel();
    });
  }

  cancel() {
    this.dismissModal();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  loadStates() {
    this.addressService.getStates().subscribe((response: { typeResponse: TypeResponse; data: any; }) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.stateList = response.data;
      } else {
        this.stateList = new Array<State>();
      }
    });
  }

  getCitiesByStateId(stateId: number) {
    if(stateId == null || stateId == undefined || stateId == 0) {
      stateId = this.student.stateId;
    }
    this.student.cityId = null;

    this.addressService.getCityByStateId(stateId).subscribe((response: any) => {
      this.citiesList = response;
    });
  }

  checkStudentByCpf(input: { target: { value: any; }; }) {
    if (this.f.foreignPerson.value) {
      this.profileForm.controls['cpf'].setErrors(null);
      this.validProfile = true;
    } else {
      if (!(input?.target?.value) || !this.utilService.isValidCPF(input.target.value)) {
        this.validProfile = false;
        this.profileForm.controls['cpf'].setErrors({'incorrect': true});
        this.errorMessage = "Valide todos os campos antes de prosseguir."
        return;
      }
  
      let cpf = input.target.value;
      this.studentService.checkStudentByCpf(cpf).subscribe((response: { typeResponse: TypeResponse; data: any; }) => {
        if (response.typeResponse == TypeResponse.Success && response.data == null) {
          this.profileForm.controls['cpf'].setErrors(null);
          this.validProfile = true;
        } else {
          this.profileForm.controls['cpf'].setErrors({'already_exists': true});
          this.validProfile = false;
          this.errorMessage = "Valide todos os campos antes de prosseguir."
        }
      }, (error: any) => {
        this.validProfile = false;
        this.errorMessage = "Valide todos os campos antes de prosseguir."
        this.listErrorMessage.push(error);
      });
    }
  }

  loadProfile(id: any) {
    this.loading.showLoading();
    this.profileService.getById(id)
    .subscribe((response: { typeResponse: TypeResponse; data: any; }) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validProfile = true;
        this.profile = new Profile(response.data);
        this.setFormByEntity(this.profile);
      } else {
        this.validProfile = true;
      }
      this.loading.hideLoading();
      }, (error: { error: { error: string; }; }) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
        this.loading.hideLoading();
      }
    );
  }

  loadAddressByCep(zipCode: any) {
    this.cepSearch = true;
    if (zipCode.value && parseInt(zipCode.value) && zipCode.value.length == 8) {
      let cep = zipCode.value;
      this.addressService.getAddressByCep(cep).subscribe((response: any) => {
        if (response.erro) {
          this.f.zipCode.setErrors({invalidNumber:true});
          this.errorMessage = response.message;
          return;
        }
        this.addressService.getCityByUf(response.data.uf).subscribe((p: { data: any; }) => {
          this.citiesList = p.data;
          this.profileForm.controls['street'].setValue(response.data.logradouro);
          this.profileForm.controls['neighborhood'].setValue(response.data.bairro);

          this.addressService.getCityByIbgeCode(response.data.ibge).subscribe((p: { data: { id: any; stateId: any; }; }) => {
            this.profileForm.controls['cityId'].setValue(p.data.id);
            this.profileForm.controls['stateId'].setValue(p.data.stateId);
            this.cepSearch = false;
            this.profileForm.controls['number'].focus();
          });
        });
      });
    } else {
      swal({
        type: 'warning',
        html: 'Por favor informe um CEP válido para buscar.',
        title: 'CEP inválido',
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 3000
      }).then(() => {
        this.loadStates();
        this.cepSearch = false;
      });
    }
  }

  setFormByEntity(data: any) {
    this.profileForm = this.formBuilder.group({
      studentId: new FormControl(data.studentId),
      name: new FormControl(data.name),
      birthDate: new FormControl(this.datePipe.transform(data.birthDate, "yyyy-MM-dd", '-0300', 'pt-BR')),
      email: new FormControl(data.email),
      phone: new FormControl(data.phone),
      cpf: new FormControl(data.cpf),
      zipCode: new FormControl(data.zipCode),
      street: new FormControl(data.street),
      number: new FormControl(data.number),
      complement: new FormControl(data.complement),
      neighborhood: new FormControl(data.neighborhood),

      userId: new FormControl(data.userId),
      cityId: new FormControl(data.cityId),
      cityName: new FormControl(data.cityName),
      stateId: new FormControl(data.stateId),
      stateUf: new FormControl(data.stateUf),
      country: new FormControl(data.country),
      foreignPerson: new FormControl(data.foreignPerson)
    });
    this.profileForm.controls['userId'].setValue(data.userId);
    this.profileForm.controls['stateId'].setValue(data.stateId);
    this.addressService.getCityByStateId(data.stateId).subscribe((p: { data: any; }) => {
      this.citiesList = p.data;
      this.profileForm.controls['cityId'].setValue(data.cityId);
    });

    this.setForeignPersonValues(data.foreignPerson);
  }

  onForeignPerson(input: { currentTarget: { checked: any; }; }) {
    this.setForeignPersonValues(input.currentTarget.checked);
  }

  setForeignPersonValues(uForeignPerson: boolean) {
    this.flagForeignPerson = uForeignPerson;
    this.profileForm.controls['foreignPerson'].setValue(uForeignPerson);

    let reqListFkPerson = ['cityName', 'stateUf', 'country']
    let notReqListFkPerson = ['zipCode', 'cpf', 'neighborhood', 'cityId', 'stateId', 'number', 'phone']

    if (uForeignPerson === true) {
      for (let i = 0; i < notReqListFkPerson.length; i++) {
        this.profileForm.get(notReqListFkPerson[i]).setValue('');
        this.profileForm.get(notReqListFkPerson[i]).clearValidators();
        this.profileForm.get(notReqListFkPerson[i]).updateValueAndValidity();
      }
      for (let i = 0; i < reqListFkPerson.length; i++) {
        this.profileForm.get(reqListFkPerson[i]).setValidators([Validators.required]);
        this.profileForm.get(reqListFkPerson[i]).updateValueAndValidity();
      }
    } else {
      for (let i = 0; i < reqListFkPerson.length; i++) {
        this.profileForm.get(reqListFkPerson[i]).clearValidators();
        this.profileForm.get(reqListFkPerson[i]).updateValueAndValidity();
      }
      for (let i = 0; i < notReqListFkPerson.length; i++) {
        this.profileForm.get(notReqListFkPerson[i]).setValidators([Validators.required]);
        this.profileForm.get(notReqListFkPerson[i]).updateValueAndValidity();
      }
    }
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }
}
