import { Base } from './../../shared/model/base';
import { Module, User } from '.';

export class UserModule extends Base {
    user: User;
    userId: number;

    module: Module;
    moduleId: number;

    constructor(contentResponse: any) {
        super(contentResponse);
    
        this.userId = parseInt(contentResponse.userId);
        if (contentResponse && contentResponse.userId > 0) {
            this.user = contentResponse.user;
        }

        this.moduleId = parseInt(contentResponse.moduleId);
        if (contentResponse && contentResponse.moduleId > 0) {
            this.module = contentResponse.module;
        }
    }
}
