import { Base } from '@app/shared/model';
import { Course, Student } from '.';
import { GradesValue } from '@app/shared/enum';

export class Grades extends Base {
    studentId: number;
    student: Student;

    courseId: number;
    course: Course;

    grade: GradesValue;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.grade = contentResponse.grade;

        this.studentId = parseInt(contentResponse.studentId);
        if (contentResponse && contentResponse.studentId > 0) {
            this.student = contentResponse.student;
        }

        this.courseId = parseInt(contentResponse.courseId);
        if (contentResponse && contentResponse.courseId > 0) {
            this.course = contentResponse.course;
        }
    }
}
