import { Injectable, OnInit } from '@angular/core';
declare var JitsiMeetExternalAPI: any;
import { Router } from '@angular/router'; // import router from angular router
import { UserMeet } from '../model';
import { MessageService } from '@app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class JitsiService {
  api: any;
  user: UserMeet;
  namePrincipalRoom: String;
  options: any;
  domain: string = 'meet.jit.si';

  // For Custom Controls
  isAudioMuted = true;
  isVideoMuted = true;
  private pwdClassRoom: String = 'P4sswor3';

  constructor(
    private loading: MessageService,
    private route: Router) {
    this.user = new UserMeet();
    this.namePrincipalRoom = 'EnglishCoffeeRoom';
  }

  moveRoom(nameRoom: String, subject: String, isAdmin: Boolean, pwdClass: String): void {
    this.loading.showLoading();
    const myNode = document.getElementById('jitsi-iframe');
    myNode.innerHTML = '';

    console.log('nameRoom' + nameRoom);
    console.log('prejoinPageEnabled:' + (this.user.name != '' ? true : false));

    this.options = this.getOptions();

    console.log(this.options);

    this.api = new JitsiMeetExternalAPI(this.domain, this.options);

    // if (pwdClass) {
    //   this.pwdClassRoom = pwdClass;
    // }

    this.setSubject(subject);

    this.api.addEventListeners({
      readyToClose: this.handleClose,
      participantLeft: this.handleParticipantLeft,
      participantJoined: this.handleParticipantJoined,
      videoConferenceJoined: this.handleVideoConferenceJoined,
      videoConferenceLeft: this.handleVideoConferenceLeft,
      audioMuteStatusChanged: this.handleMuteStatus,
      videoMuteStatusChanged: this.handleVideoStatus,
      participantRoleChanged: this.participantRoleChanged,
      passwordRequired: this.passwordRequired,
      endpointTextMessageReceived: this.endpointTextMessageReceived,
    });

    this.loading.hideLoading();
  }

  private getOptions(): any {
    return {
      roomName: this.namePrincipalRoom,
      width: 700,
      height: 700,
      nameRoom: this.namePrincipalRoom,
      //configOverwrite: { startWithAudioMuted: true },
      parentNode: document.querySelector('#jitsi-iframe'),
      jwt: 'eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtMDZkZTY5NzA1MzNlNGEyMzg5NGJhZDcwMzFkNjkxYjkvMzhjZmQ5LVNBTVBMRV9BUFAiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJqaXRzaSIsImlzcyI6ImNoYXQiLCJpYXQiOjE2OTE2MTUwMDksImV4cCI6MTY5MTYyMjIwOSwibmJmIjoxNjkxNjE1MDA0LCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtMDZkZTY5NzA1MzNlNGEyMzg5NGJhZDcwMzFkNjkxYjkiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOnRydWUsIm91dGJvdW5kLWNhbGwiOnRydWUsInNpcC1vdXRib3VuZC1jYWxsIjpmYWxzZSwidHJhbnNjcmlwdGlvbiI6dHJ1ZSwicmVjb3JkaW5nIjp0cnVlfSwidXNlciI6eyJoaWRkZW4tZnJvbS1yZWNvcmRlciI6ZmFsc2UsIm1vZGVyYXRvciI6dHJ1ZSwibmFtZSI6ImFkbWluIiwiaWQiOiJhdXRoMHw2NGNkNWNkNGIyYTQ3ODM2MjI1MDg3N2YiLCJhdmF0YXIiOiIiLCJlbWFpbCI6ImFkbWluQG1lcmNhbnRpbGVzaXN0ZW1hcy5jb20uYnIifX0sInJvb20iOiIqIn0.bQHyx6nkmJPIygvw66NYSEYUox-IPFvt0xUcCCIG1m_t00bWoXzKSEJ90h_uoWEaJok80Nh692-MGR4KMfdRHMRZrQd_aNYzljJlY_9S8Ei9L_volm17Ohhn8LG6eTgmG7Smcz1PY9e5kUTrOmdqyEIS1vwDXYujYen9lHP4Tl7HHz4y8PL8P7B5SotGYFIHB9lahqYPtWXByX_n0YdgVsWAiSmBxjWUulPAj2ordLseONqdVXUIR2NWybWXWMyikE4xFpIXKr-0dLZaeNGTa8zCCMAog-EnVx0OHuULkmgySMOSjhFTn6WdEdGjOSTt7hj7jF2kxmhHBILQAdsfhw',

      configOverwrite: {
        enableClosePage: true,
        enableAnalyticsLogging: true,
        prejoinPageEnabled: false,
        startWithAudioMuted: true,
        openBridgeChannel: 'datachannel',
        disableInviteFunctions: true,
        toolbarButtons: ['microphone', 'camera', 'closedcaptions', 'desktop', 'hangup', 'participants-pane']  
      },
      interfaceConfigOverwrite: {
        HIDE_INVITE_MORE_HEADER: true,
        SHOW_PROMOTIONAL_CLOSE_PAGE: true
      },
      //parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
        displayName: this.user.name,
      },
    };
  }

  changeRouterLink(value: any) {
    this.loading.showLoading();
    console.log(value);
    this.namePrincipalRoom = value;

    const myNode = document.getElementById('jitsi-iframe');
    myNode.innerHTML = '';

    this.options = this.getOptions();

    this.api = new JitsiMeetExternalAPI(this.domain, this.options);

    this.loading.hideLoading();
  }

  setSubject(subject: any) {
    this.api.executeCommand('subject', subject);
  }

  handleClose = () => {
    console.log('handleClose');
  };

  endpointTextMessageReceived = async (event: any) => {
    console.log('mensagem recebida');
    console.log(event);
    console.log(event.data.eventData.text);
    if ((event.data.eventData.text = 'mover a principal')) {
      this.moveRoom('grupo 1', 'grupo 1', true, '');
    }
  };

  passwordRequired = async () => {
    console.log('passwordRequired'); // { id: "2baa184e" }
    this.api.executeCommand('password', this.pwdClassRoom ?? 'P4sswor3');
  };

  handleParticipantLeft = async (participant: any) => {
    console.log('handleParticipantLeft', participant); // { id: "2baa184e" }
    const data = await this.getParticipants();
  };

  participantRoleChanged = async (participant: any) => {
    console.log('participantRoleChanged', participant);
    //if (participant.role === "moderator")
    {
      console.log('participantRoleChanged:', participant.role);
      this.api.executeCommand('password', this.pwdClassRoom ?? 'P4sswor3');
    }
  };

  handleParticipantJoined = async (participant: any) => {
    console.log('OJOJOJOJ  handleParticipantJoined', participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }

    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async (participant: any) => {
    console.log('handleVideoConferenceJoined', participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    /*
        displayName: "userNameTest"
        formattedDisplayName: "userNameTest (me)"
        id: "19563d97"
        roomName: "PrincipalRoom"
    */

    this.user.setName(participant.userNameTest);
    this.namePrincipalRoom = participant.roomName;

    const data = await this.getParticipants();
  };

  handleVideoConferenceLeft = () => {
    console.log('handleVideoConferenceLeft');
    this.route.navigate(['/']);
  };

  handleMuteStatus = (audio: any) => {
    console.log('handleMuteStatus', audio); // { muted: true }
  };

  handleVideoStatus = (video: any) => {
    console.log('handleVideoStatus', video); // { muted: true }
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.loading.showLoading();
        resolve(this.api.getParticipantsInfo()); // get all participants
        this.loading.hideLoading();
      }, 500);
    });
  }

  // custom events
  executeCommand(command: string) {
    this.loading.showLoading();
    this.api.executeCommand(command);
    if (command == 'hangup') {
      this.route.navigate(['/']);
      return;
    }

    if (command == 'toggleAudio') {
      this.isAudioMuted = !this.isAudioMuted;
    }

    if (command == 'toggleVideo') {
      this.isVideoMuted = !this.isVideoMuted;
    }
    this.loading.hideLoading();
  }
}
