import { InitAdminService } from '@app/admin/services/init-admin.service';
import { MessageService } from '@app/shared/services/message.service';
import { HolidayService } from '@app/audit/services/holiday.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { default as swal } from 'sweetalert2';

@Component({
  selector: 'holiday-list',
  templateUrl: './holiday-list.component.html',
  styleUrls: ['./holiday-list.component.css'],
  providers: [DatePipe],
})
export class HolidayListComponent implements OnInit {
  public list: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public columns: Array<any> = [
    { title: 'Código', name: 'id', sort: 'asc', className: 'text-center' },
    { title: 'Nome', name: 'name', sort: 'asc' },
    {
      title: 'Data',
      name: 'date',
      sort: 'asc',
      className: 'text-center text-primary fw-700 datetime onlydate',
    },
    {
      title: 'Ponto Facultativo?',
      name: 'optionalHoliday',
      className: 'text-center',
    },
    {
      title: 'Criado em',
      name: 'createdOn',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
    {
      title: 'Modificado em',
      name: 'updatedOn',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
  ];

  constructor(
    private initAdmin: InitAdminService,
    private holidayService: HolidayService,
    private loading: MessageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadHolidays();
  }

  loadHolidays() {
    this.loading.showLoading();
    this.holidayService.get().subscribe(
      (response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.list = response.data;
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.loading.hideLoading();
      },
      (error: any) => {
        this.loading.hideLoading();
        this.showTable = false;
        this.returnMessage = 'Erro ao carregar os dados de feriados.';
      }
    );
  }

  delete(target): void {
    swal({
      title: 'Excluir Feriado',
      html:
        'Confirma a exclusão <strong>' +
        target.name +
        '</strong>?<br>' +
        'Ao confirmar o feriado será excluído permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.holidayService.remove(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  //window.location.reload();
                  this.loadHolidays();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir o pacote!',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir o pacote.',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  this.loadHolidays();
                });
              }
            },
            (error) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir o pacote.',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir o feriado.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/audit/holiday', { id: row.id }]);
  }
}
