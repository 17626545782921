import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';
import { Holiday } from '../model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HolidayService {
  private route: string = "auditable";
  private controller: string = "holiday";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  get(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getById(id: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }

  remove(id: any): Observable<any> {
    return this.http.delete<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }

  save(model: Holiday, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/${model.id}`, model);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}`, model);
    }
  }
}
