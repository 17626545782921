import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Helpers
import { AuthGuard } from '../auth/helpers/auth.guard';

// Components
import { HomeComponent } from './components/home/home.component';
import { CourseComponent } from './components/course/course.component';
import { ContentListComponent } from './components/content/content-list/content-list.component';
import { ContentViewComponent } from './components/content/content-view/content-view.component';
import { ModuleType } from '@app/shared/enum/ModuleType';
import { CalendarComponent } from './components/calendar/calendar.component';
import { WritingTestComponent } from './components/writing-test/writing-test/writing-test.component';
import { WritingTestListComponent } from './components/writing-test/writing-test-list/writing-test-list.component';
import { WritingTestApplyComponent } from './components/writing-test/writing-test-apply/writing-test-apply.component';
import { WritingTestListAppliedComponent } from './components/writing-test/writing-test-list-applied/writing-test-list-applied.component';
import { ProfileComponent } from './components/profile/profile.component';
import { JitsiMeetingComponent } from './components/jitsi-meeting/jitsi-meeting.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Teacher, role: 'PRO2DA13DA26', title: 'Home' },
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Teacher,
      role: 'PRO2DA13DA26',
      title: 'Calendário',
    },
  },
  {
    path: 'course',
    component: CourseComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Teacher, role: 'PRO2AL14AL27', title: 'Curso' },
  },
  {
    path: 'contentlist',
    component: ContentListComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Teacher, role: 'PRO2AL14AL27', title: 'Cursos' },
  },
  {
    path: 'writingtest',
    component: WritingTestComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Teacher,
      role: 'PRO2WT7AL35',
      title: 'Teste de escrita',
    },
  },
  {
    path: 'writingtestlist',
    component: WritingTestListComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Teacher,
      role: 'PRO2WT7AL35',
      title: 'Teste de escrita',
    },
  },
  {
    path: 'writingtestapply',
    component: WritingTestApplyComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Teacher,
      role: 'PRO2WT7AL35',
      title: 'Aplicação do teste de escrita',
    },
  },
  {
    path: 'writingtestlistapplied',
    component: WritingTestListAppliedComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Teacher,
      role: 'PRO2WT7AL35',
      title: 'Testes de escrita aplicados',
    },
  },
  {
    path: 'contentview',
    component: ContentViewComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Teacher,
      role: 'PRO2AL14AL27',
      title: 'Visualização',
    },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { module: ModuleType.Teacher, role: 'PRO2DA13DA26', title: 'Perfil' },
  },
  {
    path: 'meeting',
    component: JitsiMeetingComponent,
    canActivate: [AuthGuard],
    data: {
      module: ModuleType.Teacher,
      role: 'PRO2AL14MT36',
      title: 'Aula ao Vivo',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TeacherRoutingRoutes {}
