import { AfterViewInit, Component, ChangeDetectorRef, EventEmitter, ViewChild, Directive, Input, ElementRef, TemplateRef, ViewContainerRef } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MessageService } from './shared/services/message.service';
import pageSettings from './_helpers/page-settings';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { Subject } from 'rxjs';
import { Observable, of as observableOf } from 'rxjs'; // since RxJs 6
import { AccountService } from './auth/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements AfterViewInit {
  public loading: Subject<boolean> = new Subject<boolean>();
  public _pageSettings: { pageContentBgTransparent: boolean; pageContentBrandGradient: boolean; pageWithoutFooter: boolean; pageEmpty: boolean; pageSidebar: boolean; module: string; };

  title = 'English Coffee';
  public mask: Array<string | RegExp>

  @ViewChild('routerOutlet') routerOutlet: ElementRef;

  public routerModuleForce: boolean = true;
  
  constructor(
    private accountService: AccountService,
    private cdr: ChangeDetectorRef,
    private titleService: Title, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService) {
      // page settings
      this._pageSettings = pageSettings;
      this.loading = this.messageService.isloading;
      this.mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }
  
  ngOnInit() {
    try {
      this.routerModuleForce = true;
      var root = this;
      setTimeout(function() {
        if (root.routerModuleForce === true)
          return root.accountService.redirectToModule(true);
      }, 2000);

      var events = this.router.events.filter((event) => event instanceof NavigationEnd);
      events.map(() => this.activatedRoute)
      .map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      })
      .filter((route) => route.outlet === 'primary')
      .mergeMap((route) => route.data)
      .subscribe(event => {
        this.routerModuleForce = false;

        this.titleService.setTitle('English Coffee | ' + event['title']);
        if (event instanceof NavigationStart) {
          window['strum']('routeChange', event.url);
        }
      }, (error) => {
        console.log(error);
      });
    } catch (error) {
      console.log(error);
    }
  }

  ngAfterContentInit() {
    this._pageSettings = pageSettings
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    var divMainContent = document.getElementById('js-page-content');
    if (divMainContent) {
      var classElements = divMainContent.classList;
        // elements with class "snake--mobile" exist
    }
    // if (!this.routerOutlet?.nativeElement.innerHTML) {
    //   return this.accountService.redirectToModule(true);
    // }
    this._pageSettings = pageSettings
    this.cdr.detectChanges();
  }

  checkLoading(): Observable<boolean> {
    if (!this.loading) {
      return observableOf(false);
    }
  }

  onActivate($event: EventEmitter<any>) {
    console.log($event);
    console.log('routerModuleForce: ' + this.routerModuleForce);
  }

  onDeactivate($event: EventEmitter<any>) {
    console.log($event);
    console.log('routerModuleForce: ' + this.routerModuleForce);
  }

  onAttach($event: EventEmitter<any>) {
    console.log($event);
    console.log('routerModuleForce: ' + this.routerModuleForce);
  }

  onDetach($event: EventEmitter<any>) {
    console.log($event);
    console.log('routerModuleForce: ' + this.routerModuleForce);
  }
}
