import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { UserGroupNotification } from '../model';

@Injectable({
  providedIn: 'root'
})
export class UserGroupNotificationService {
  private route: string = "marketing";
  private controller: string = "usergroupnotification";
  private endpointPrefix: string;

  constructor(private http: HttpClient) { 
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getall(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getById(userGroupNotificationId: string): any {
    let params = new HttpParams();
    params = params.append('id', userGroupNotificationId);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getById`, {params: params});
  }
  
  remove(id): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.delete<ResponseResult>(`${this.endpointPrefix}/remove`, {params: params});
  }
  
  save(userGroupNotification: UserGroupNotification, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/update`, userGroupNotification);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/add`, userGroupNotification);
    }
  }
}
