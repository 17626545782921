import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CourseService, TeacherService } from '@app/admin/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseTypeCss, CourseTypeLabel, StatusClass, StatusClassCss, StatusClassCssContrast, StatusClassLabel } from '@app/shared/enum';
import { StudentClassesCourseConfirmedClassesService } from '@app/reports/services';
import { StudentClassesCourseConfirmed, StudentClassesCourseConfirmedFilter, StudentClassesCourseConfirmedGroup } from '@app/reports/model';
//import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { StatusClassParamsLabel } from '@app/shared/enum/StatusClassParams';
import { ReportFormat } from '@app/shared/enum/ReportFormat';
import { MessageService } from '@app/shared/services';
import { default as swal } from 'sweetalert2'
import { Router } from '@angular/router';
import { Course } from '@app/admin/model';

@Component({
  selector: 'studentclassescourse-confirmed-classes-synthetic',
  templateUrl: './studentclassescourse-confirmed-classes-synthetic.component.html',
  providers: [DatePipe] })
export class StudentclassescourseConfirmedClassesSyntheticComponent implements OnInit {
  closeResult: string;
  //public confirmedGroup: StudentClassesCourseConfirmedGroup;
  public confirmedClassesSyntheticData: any = [];
  public confirmedClassesSyntheticGroup: any = null;

  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  public auxIcon = "fa fa-users";
  public auxTitle = "Alunos";

  public auxIcon2 = "fas fa-user-plus";
  public auxTitle2 = "Incluir Aluno";

  public courseDataList: Array<any>;
  public flagCourseSelected: boolean = false;
  public flagSearchCourse = false;
  public courseList: any = [];
  public courseId: any;
  public courseData: any;
  
  public teacherDataList: Array<any>;
  public flagTeacherSelected: boolean = false;
  public flagSearchTeacher = false;
  public teacherList: any = [];
  public teacherId: any;
  public teacherData: any;

  public statusClassList: any = [];

  public flagFiltering: boolean = false;

  public startDate: any;
  public endDate: any;
  
  public options: Options;

  public previewSyntheticData: any[];
  public previewSyntheticHeaderData: any = null;

  public reportFilter: StudentClassesCourseConfirmedFilter;
  
  public columns: Array<any> = [
    // { 
    //   title: "Teacher", 
    //   name: "teacherName", 
    //   sort: "asc",
    //   isHtmlData: true,
    //   className: "text-left"
    // },
    { 
      title: "Turma(s)", 
      name: "designCoursesHtml", 
      sort: "asc",
      className: "text-left fw-700"
    },
    // { 
    //   title: "Tipo", 
    //   name: "designCourseType", 
    //   sort: "asc",
    //   isHtmlData: true,
    //   className: "text-center"
    // },
    {
      title: "Data/Hora Aula",
      name: "expectedDate",
      sort: "asc",
      className: "text-center text-primary datetime fw-700"
    },
    {
      title: "Tempo da Aula",
      name: "designTotalTime",
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-center text-primary fw-700"
    },
    {
      title: "Alunos(as)",
      name: "students", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-info fw-700"
    },
    {
      title: "Aula<br>Concluída?",
      name: "flagDone",
      sort: "asc",
      className: "text-center"
    },
    {
      title: this.getColumnTitleStatus(StatusClass.Scheduled),
      name: "scheduled", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-primary fw-700"
    },
    {
      title: this.getColumnTitleStatus(StatusClass.Done).replace('Concluída', 'Confirmada'),
      name: "done", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-primary fw-700"
    },
    {
      title: this.getColumnTitleStatus(StatusClass.Delayed),
      name: "delayed", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-primary fw-700"
    },
    {
      title: this.getColumnTitleStatus(StatusClass.ChangedTeacher),
      name: "changedTeacher", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-primary fw-700"
    },
    {
      title: this.getColumnTitleStatus(StatusClass.Reschedule),
      name: "reschedule", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-primary fw-700"
    },
    {
      title: this.getColumnTitleStatus(StatusClass.PartialConfirmed),
      name: "partialConfirmed", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-primary fw-700"
    },
    {
      title: this.getColumnTitleStatus(StatusClass.Unchecked),
      name: "unchecked", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-primary fw-700"
    }
  ];
  
  constructor(
    private router: Router,
    private loading: MessageService,
    private initAdmin: InitAdminService,
    private courseService: CourseService,
    private teacherService: TeacherService,
    private confirmedClassesService: StudentClassesCourseConfirmedClassesService,
    private modalService: NgbModal
  ) {
    this.courseDataList = new Array<any>();
    this.teacherDataList = new Array<any>();
    //this.confirmedGroup = new StudentClassesCourseConfirmedGroup();
    this.reportFilter = new StudentClassesCourseConfirmedFilter(null);
    this.onLoadTeachers();
  }

  ngOnInit(): void {
    this.options = {
      placeholder: "Selecione uma opção",
      allowClear: true,
      width: "100%"
    };
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.getConfirmedClasses(null);
    this.confirmedClassesSyntheticData = [];
    this.confirmedClassesSyntheticGroup = null;
  }

  onLoadTeachers() {
    this.teacherService.all().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.teacherList = response.data;
        this.teacherDataList = this.getTemplateTeacher(response.data);
        this.teacherId = 0;
      } else {
        this.teacherList = new Array<any>();
        this.teacherDataList = new Array<any>();
      }
      this.flagSearchTeacher = false;
    }, (error: any) => {
      this.flagSearchTeacher = false;
    });
  }

  private getColumnTitleStatus(statusClass: StatusClass) {
    let statusClassCss = StatusClassCss.get(statusClass);
    let statusClassStr = StatusClassLabel.get(statusClass)
    return "<span class='badge badge-pill w-100 font-weight-bold "
      + statusClassCss +"'>"
      + statusClassStr +"</span>";
  }

  getTemplateTeacher(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      let arrayTeachers: Array<any> = new Array<any>();
      this.teacherList = data.sort(function(a: any, b: any) {
        var textA = a.name.toLowerCase();
        var textB = b.name.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id,
          name: this.pad(data[i].id, 6) + " | " + data[i].name + " | " + data[i].email,
        };
        arrayTeachers.push(element);
      }
      return arrayTeachers;
    }
    return new Array<any>();
  }

  getTemplateCourse(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      let arrayCourses: Array<any> = new Array<any>();
      this.courseList = data.sort(function(a: any, b: any) {
        var textA = a.name.toLowerCase();
        var textB = b.name.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id,
          name: this.pad(data[i].id, 6) + " | " + data[i].name
        };
        arrayCourses.push(element);
      }
      return arrayCourses;
    }
    return new Array<any>();
  }

  getTimeStr(time: any) {
    time.toTimeString().split(' ')[0];
  }

  pad(num: any, size: any) {
    if (num > 0) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    }
    return num;
  }

  onLoadCoursesByTeacherId(teacherId: any) {
    this.courseService.getByTeacherId(teacherId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.courseDataList = this.getTemplateCourse(response.data);
        this.courseId = 0;
      } else {
        this.courseDataList = new Array<any>();
      }
      this.flagSearchCourse = false;
    }, (error: any) => {
      this.flagSearchCourse = false;
    });
  }

  getConfirmedClasses(status: any) {
    this.showTable = false;
    if (this.teacherId > 0) {
      this.flagFiltering = true;
      this.setFilterModel(status);
      this.reportFilter.reportFormat = ReportFormat.Synthetic;
      this.confirmedClassesService.confirmedClassesSynthetic(this.reportFilter)
      .pipe(first()).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.designResult(response.data);
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.flagFiltering = false;
      }, (error: any) => {
        this.showTable = false;
        this.flagFiltering = false;
        this.returnMessage = 'Erro ao carregar os dados do relatório.';
      });
    } else {
      this.flagFiltering = false;
      this.showTable = false;
      this.returnMessage = 'Selecione as opções do filtro para listar os dados.';
    }
  }

  formatTimeDiff(date1: Date, date2: Date) {
    return Array(3)
      .fill([3600, date1.getTime() - date2.getTime()])
      .map((v, i, a) => {
        a[i+1] = [a[i][0]/60, ((v[1] / (v[0] * 1000)) % 1) * (v[0] * 1000)];
        return `0${Math.floor(v[1] / (v[0] * 1000))}`.slice(-2);
      }).join(':');
  }

  designResult(data: any) {
    this.confirmedClassesSyntheticData = data.resultGroupCourse;
    this.confirmedClassesSyntheticGroup = data.resultGroup;
    this.confirmedClassesSyntheticData.forEach(function(e: any) {
      e.designCoursesHtml = '';
      if (e.reportConfirmedClassesCourseList && e.reportConfirmedClassesCourseList.length > 0) {
        e.reportConfirmedClassesCourseList.forEach((crd: any, index: number) => {
          var courseTypeHtml = '';
          if (crd.courseType >= 0) {
            let courseTypeCss = CourseTypeCss.get(crd.courseType);
            let courseTypeStr = CourseTypeLabel.get(crd.courseType)
            courseTypeHtml = "<span class='badge badge-pill w-25 mr-2 "
              + courseTypeCss +"'>"
              + courseTypeStr +"</span>";
          }

          let classReplacementHtml = '';
          if (crd?.classReplacement === true) {
            classReplacementHtml = "<span class='badge badge-danger badge-pill text-white ml-2'> S </span>";
          }

          e.designCoursesHtml += "<span class='text-primary text-left fw-700'>"
            + courseTypeHtml + crd.courseName + classReplacementHtml + "</span>";
          
          if (e.reportConfirmedClassesCourseList.length > (index + 1)) {
            e.designCoursesHtml += "<br>";
          }
        });
      } else {
        e.designCoursesHtml = "<span class='badge badge-pill badge-secondary w-100'>Não há turmas</span>";
      }
      
      e.designTotalTime = '';

      if (e.timeStart) {
        let timeStart = e.timeStart;
        let timeEnd = e.timeEnd;
        if (timeStart != null && timeStart.length > 0 
          && timeEnd != null && timeEnd.length > 0) {
          let dtIni = new Date();
          dtIni.setMilliseconds(0);
          dtIni.setSeconds(0);
          dtIni.setMinutes(timeStart.split(':')[1]);
          dtIni.setHours(timeStart.split(':')[0]);
          let dtFim = new Date();
          dtFim.setMilliseconds(0);
          dtFim.setSeconds(1);
          dtFim.setMinutes(timeEnd.split(':')[1]);
          dtFim.setHours(timeEnd.split(':')[0]);

          if (timeEnd === '00:00') {
            dtFim.setDate(dtFim.getDate() + 1);
          }
          
          var utcDiff = Array(3)
            .fill([3600, dtFim.getTime() - dtIni.getTime()])
            .map((v, i, a) => {
              a[i+1] = [a[i][0]/60, ((v[1] / (v[0] * 1000)) % 1) * (v[0] * 1000)];
              return `0${Math.floor(v[1] / (v[0] * 1000))}`.slice(-2);
            }).join(':');

          //let utcDiff = this.formatTimeDiff(dtFim, dtIni);
          if (dtFim <= dtIni || utcDiff === '00:00:00' || utcDiff === '00:00:01') {
            e.designTotalTime = "<span class='badge badge-pill badge-secondary w-100'> - </span>";
          } else {
            e.designTotalTime += "<span class='badge border border-primary text-primary'><i class='fas fa-solid fa-clock'></i> "
            + utcDiff.split(':')[0] + ':' + utcDiff.split(':')[1] + "</span>";
          }
        } else {
          e.designTotalTime = "<span class='badge badge-pill badge-secondary w-100'> - </span>";
        }
      }
    });
    //this.confirmedGroup.studentClassesCourseConfirmed = lista;
  }

  private setFilterModel(status: any) {
    this.reportFilter = new StudentClassesCourseConfirmedFilter(null);
    if (this.teacherId) {
      this.reportFilter.teacherId = this.teacherId;
    }
    if (this.courseId) {
      this.reportFilter.courseId = this.courseId;
    }
    if (this.startDate) {
      this.reportFilter.startDate = this.startDate;
    }
    if (this.endDate) {
      this.reportFilter.endDate = this.endDate;
    }
    if (status >= 0) {
      this.reportFilter.statusClass = Number.parseInt(status);
    }
  }

  setStartDate(event: any) {
    var value = event.target.value;
    if (value) {
      var year = value.split('-')[0];
      var month = value.split('-')[1];
      var day = value.split('-')[2];
      this.startDate = new Date(year, month - 1, day, 0, 0, 0, 0);
    }
  }

  setEndDate(event: any) {
    var value = event.target.value;
    if (value) {
      var year = value.split('-')[0];
      var month = value.split('-')[1];
      var day = value.split('-')[2];
      this.endDate = new Date(year, month - 1, day, 0, 0, 0, 0);
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();
  
      console.log(reason);
  
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }

  showInfoConfirmedClasses(row: any, content: any) {
    if (row?.teacherId && row?.expectedDate) {
      this.previewSyntheticHeaderData = row;
      this.loading.showLoading();
      this.confirmedClassesService.previewSynthetic(row.teacherId, row.expectedDate)
      .pipe(first()).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.previewSyntheticData = response.data;
          if (response.data.length > 0) {
            this.previewSyntheticHeaderData.teacherId = response.data[0].teacherId;
            this.previewSyntheticHeaderData.teacherName = response.data[0].teacherName;
          }
          return this.previewSynthetic(content);
        } else {
          swal({
            type: 'warning',
            title: 'Visualização indisponível',
            text: 'Não foi possível visualizar os dados. Verifique as informações!',
            showConfirmButton: true,
          });
        }
        this.loading.hideLoading();
        }, (error: any) => {
          if (error.error.error === 'invalid_token') {
            this.router.navigate(['/auth/login']);
          }
      });
    }
  }

  previewSynthetic(content: any) {
    this.loading.hideLoading();
    this.modalService.dismissAll();
    this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  setTeacher(item: any) {
    if (item) {
      this.teacherId = item.id;
      this.teacherData = this.teacherList.find((x: { id: number; }) => x.id == item.id);
      this.teacherData.formatHourlyRate = this.formatCurrency(this.teacherData.hourlyRate);
      this.onLoadCoursesByTeacherId(item.id);
    }
  }

  formatCurrency(currency: any): string {
    if (currency) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(currency);
    }
    return '';
  }

  setCourse(item: any) {
    if (item) {
      this.courseId = item.id;
    }
  }

  getCourseTypeStr(courseType: any) {
    return CourseTypeLabel.get(courseType);
  }
  getCourseTypeCss(courseType: any) {
    return CourseTypeCss.get(courseType);
  }

  getStatusClassStr(statusClass: any) {
    return StatusClassLabel.get(statusClass);
  }

  getStatusClassCss(statusClass: any) {
    return StatusClassCss.get(statusClass);
  }
  
  getStatusClassCssContrast(statusClass: any) {
    return StatusClassCssContrast.get(statusClass);
  }

  getStatusClassParamsStr(statusClassParams: any) {
    return StatusClassParamsLabel.get(statusClassParams);
  }

  designStatusClass(status: StatusClass) {
    let statusClassCss = this.getStatusClassCss(status);
    let statusClassStr = this.getStatusClassStr(status);
    return "<span class='badge badge-pill w-100 font-weight-bold "
      + statusClassCss +"'>"
      + statusClassStr +"</span>";
  }

  getStatusClassList(classes: Array<StudentClassesCourseConfirmed>) {
    this.statusClassList = new Array<any>();
    for (var value in StatusClass) {
      if (Number.isInteger(Number.parseInt(value))
        && ((!(this.reportFilter.statusClass >= 0)) || this.reportFilter.statusClass == Number.parseInt(value))) {
        let count = 0;
        if (classes) {
          count = classes.filter(x => x.statusClass == Number.parseInt(value)).length;
        }
        this.statusClassList.push({
          count,
          value: Number.parseInt(value), 
          label: StatusClassLabel.get(Number.parseInt(value)),
          css: StatusClassCss.get(Number.parseInt(value)),
          contrast: StatusClassCssContrast.get(Number.parseInt(value))
        });
      }
    }
  }
}
