import { CourseService, InitAdminService, StudentCourseService, StudentService } from '@app/admin/services';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService, UtilService } from '@app/shared/services';
import { Course, Student, StudentCourse, StudentCourseAdd } from '@app/admin/model';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { DatePipe, formatDate } from '@angular/common';
import {LOCALE_ID} from '@angular/core';
import { default as swal } from 'sweetalert2'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentStatusCss, PaymentStatusLabel } from '@app/shared/enum';
import { Options } from 'select2';
import { StudentCourseRelocation } from '@app/admin/model/studentCourseRelocation';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'studentCourseRelocation-add',
  providers: [{
    provide: LOCALE_ID,
    useValue: "pt-BR"
  }, DatePipe],
  templateUrl: './studentCourseRelocation-add.component.html'
})
export class StudentCourseRelocationAddComponent implements OnInit {
  @Input("studentCourseIdentifier") studentCourseIdentifier: string;
  
  public studentCourseId: number = 0;
  private studentCourseRelocation: StudentCourseRelocation;
  public validStudentCourse: boolean;
  public studentCourseRelocationForm: any;
  public submitted = false;
  private returnUrl: string;
  private reviewCourseUrl: string;
  
  public errorMessage = '';
  public listErrorMessage: any[];
  public valueMask: Array<string | RegExp>;
  public dateNow = new Date();

  public googleDriveLink: string = '';

  public student: Student;
  public studentList: any;
  public validStudent: boolean = true;
  public validStudentMessage: string;
  public flagStudentSelected: boolean = false;

  public courseId: number = 0;
  public course: Course;
  public courseList: any;
  public validCourse: boolean = true;
  public validCourseListMessage: any[];
  public flagCourseSelected: boolean = false;

  public studentCourseData: any;
  public flagSearchInfo = false;
  public validInfo: boolean = true;
  public studentCourse: StudentCourse;
  public errorStudentCourseMessage: string;
  public flagStudentCourseSelected: boolean = false;

  public recurrenceList: any = [];
  public recurrenceValue: any;

  public flagCustomPrice: boolean = false;
  public numberOfMonth: number = 1;

  public editMode: boolean;

  public selectedCourse: any;
  public selectCourseList: any[];
  public showAvailableCourses: boolean = true;
  public errorMessageAvailableCourses: string = '';

  public options: Options = {
    multiple: false,
    closeOnSelect: true,
    width: '300'
  };

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private utilService: UtilService,
    private studentService: StudentService,
    private studentCourseService: StudentCourseService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private toastr: ToastrService
  ) { 
    this.loading.showLoading();
    this.valueMask = [/[0-9]/, /\d/ ,/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    this.onInitForm();
  }
  
  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/studentCourselist';
    this.reviewCourseUrl = '/admin/course';
    this.validCourseListMessage = new Array<string>();
    
    this.activatedRoute.params.subscribe(params => {
      this.loading.showLoading();

      if (this.studentCourseIdentifier) {
        this.getStudentCourseInfo(this.studentCourseIdentifier);
      }

      this.checkAllowNewStudent(this.courseId);
      
      this.courseList = new Array<Course>();
      this.studentCourseId = params['studentCourseId'];
      this.editMode = this.studentCourseId > 0;
      this.onLoadCourses();
    });

    this.loading.hideLoading();
  }

  onInitForm() {
    this.flagStudentSelected = false;
    this.validStudentCourse = true;
    this.validStudent = true;
    this.studentCourseRelocationForm = this.formBuilder.group({
      actualStudentCourseId: [0, [Validators.required]],
      newCourseId: [0, [Validators.required]],
      dueDay: [0, [Validators.required]],
      startDate: new FormControl(this.datePipe.transform(Date.now(),"yyyy-MM-dd")),
      endDate: new FormControl(this.datePipe.transform(Date.now(),"yyyy-MM-dd"))
    }, {validator: this.checkAndValidation()});
  }

  getFormatDate(date: any) {
    if (date) {
      return formatDate(new Date(date), 'dd/MM/yyyy', 'en-US');
    } else {
      return '';
    }
  }

  getStudentCourseInfo(studentCourseIdentifier: any) {
    this.flagSearchInfo = true;
    this.studentCourseService.getInfo(studentCourseIdentifier).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.studentCourseData = response.data;
        this.f.actualStudentCourseId.value = response.data.id;
        this.studentCourseRelocationForm.controls['actualStudentCourseId'].setValue(response.data.id);
        this.googleDriveLink = this.studentCourseData.googleDriveLink;
        this.studentCourseData.designStatusPayment = this.getStatusPagamento(this.studentCourseData.paymentStatus);
      } else {
        this.studentCourseData = null;
      }
      this.flagSearchInfo = false;
      this.loading.hideLoading();
    }, (error: any) => {
      this.flagSearchInfo = false;
      this.loading.hideLoading();
    });
  }

  getStatusPagamento(paymentStatus: any) {
    if (paymentStatus >= 0) {
      let paymentStatusCss = PaymentStatusCss.get(paymentStatus);
      let paymentStatusStr = PaymentStatusLabel.get(paymentStatus)
      return "<span class='badge badge-pill w-100 "
        + paymentStatusCss +"'>"
        + paymentStatusStr +"</span>";
    }
  }
  
  checkAllowNewStudent(courseId: any) {
    this.loading.showLoading();
    this.courseService.allowNewStudent(courseId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        if (!response.data) {
          this.denyAddNewStudent();
        }
      }
    }, (error: any) => {
      this.denyAddNewStudent();
    });
  }

  denyAddNewStudent() {
    swal({
      type: 'warning',
      title: 'Limite atingido!',
      html: 'Não é permitido adicionar novas matrículas na turma.<br>Selecione outra turma para continuar.',
      showConfirmButton: true,
    }).then(() => {
      this.returnUrlList();
    });
  }

  checkAndValidation() {
    return (group: FormGroup) => {
       return this.checkValidationStudent() && this.checkValidationCourse() && this.checkValidationDates();
    }
  }

  checkValidationStudent() {
    if (this.f) {
      if (!(this.f?.studentId?.value > 0)) {
        this.validStudent = false;
        return this.f?.studentId?.setErrors({invalidValue: true})   
      } else {
        this.validStudent = true;
        return this.f?.studentId?.setErrors(null);
      }
    }
    return true;
  }

  checkValidationCourse() {
    if (this.f) {
      if (!this.course || !(this.f.courseId.value > 0)) {
        this.validCourse = false;
      } else {
        this.validCourse = true;
      }
      
      if (this.course && (!(this.course.quantityMonth > 0))) {
        this.validCourse = false;
        if (!this.validCourseListMessage.some(e => e === 'A QUANTIDADE DE MESES é inválida.')) {
          this.validCourseListMessage.push('A QUANTIDADE DE MESES é inválida.');
        }
      }
      
      if (this.course && (!(this.course.quantityStudent > 0))) {
        this.validCourse = false;
        if (!this.validCourseListMessage.some(e => e === 'A QUANTIDADE DE ALUNOS é inválida.')) {
          this.validCourseListMessage.push('A QUANTIDADE DE ALUNOS é inválida.');
        }
      }
  
      if (!this.validCourse) {
        return this.f.courseId.setErrors({invalidValue: true});
      } else {
        this.validCourseListMessage = new Array<string>();
        return this.f.courseId.setErrors(null);
      }
    }
    return true;
  }

  checkValidationDates() {
    let datesObj = this.getTotalDates();
    if (datesObj) {
      if (datesObj.checkValue > 0) {
        this.validStudentCourse = true;
        return this.f.endDate.setErrors(null);
      } else {
        this.validStudentCourse = false;
        return this.f.endDate.setErrors({invalidValue: true});
      }
    }

    return true;
  }

  getTotalDates() {
    if (this.f) {
      if (this.f.endDate.value && this.f.startDate.value) {
        let endDate = new Date(this.f.endDate.value + 'GMT-0300');
        let startDate = new Date(this.f.startDate.value + 'GMT-0300');
        let checkValue = endDate.getTime() - startDate.getTime();
        let months = this.utilService.DifferenceInMonths(startDate, endDate);
        return {
          startDate,
          endDate,
          months,
          checkValue
        }
      }
    }
    return null;
  }

  onLoadStudents(courseId: any) {
    this.studentCourseService.getStudentsNotInCourseId(courseId).subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.studentList = response.data;
      } else {
        this.studentList = new Array<Student>();
      }
    });
  }

  onLoadCourses() {
    this.courseService.list(true).pipe(first()).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.courseList = response.data;
        this.getInputCourseList(this.courseList);
        this.showAvailableCourses = true;
        this.errorMessageAvailableCourses = '';
      } else {
        this.showAvailableCourses = false;
        this.courseList = new Array<Course>();
        this.errorMessageAvailableCourses = response.message;
      }
    }, (error: any) => {
      this.showAvailableCourses = false;
      this.courseList = new Array<Course>();
      this.errorMessageAvailableCourses = 'Erro ao consultar as informações de turmas.';
    });
  }

  getInputCourseList(data: Array<any>) {
    this.selectCourseList = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let item = {
          id: data[i].id.toString(),
          name: data[i].id.toString() + " | " + data[i].name,// + " | " + data[i].email,
        };
        this.selectCourseList.push(item);
      }
    }
  }

  onStudentChange(value: any) {
    if (value && parseInt(value) > 0) {
      this.flagStudentSelected = (value && parseInt(value) > 0);
      if (this.flagStudentSelected) {
        this.studentService.getById(value).subscribe((response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.student = response.data;
          } else {
            this.student = new Student(null);
          }
        });
      }
    }
  }

  onCourseChange(value: any) {
    if (value && parseInt(value) > 0) {
      this.courseId = parseInt(value);
      this.course = this.courseList.find((x: any) => x.id == this.courseId);
      this.f.newCourseId.value = this.courseId;
      this.studentCourseRelocationForm.controls['newCourseId'].setValue(this.courseId);
      this.flagCourseSelected = true;
    } else {
      this.course = new Course(null);
      this.flagCourseSelected = false;
    }
  }

  onMonthCalcChange(target: any) {
    this.numberOfMonth = 1;
    let datesObj = this.getTotalDates();
    if (datesObj) {
      this.numberOfMonth = datesObj.months;
    }
  }

  autoCalcEndDate(quantityMonth: any = 0) {
    if (this.f.endDate.value && this.f.startDate.value) {
      this.onMonthCalcChange(null);
      let endDate = new Date(this.studentCourseData.endDate);
      if (endDate) {
        let newEndDateStr = formatDate(endDate, 'yyyy-MM-dd', 'en-US');
        if (newEndDateStr) {
          this.studentCourseRelocationForm.controls['endDate'].setValue(newEndDateStr);
          this.toastr.info("Data final foi calculada automaticamente!");
        }
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.studentCourseRelocationForm ? this.studentCourseRelocationForm.controls : null;
  }

  onSubmit() {
    this.loading.showLoading();
    this.submitted = true;
    this.validStudentCourse = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    //this.checkValidationDates();

    // stop here if form is invalid
    if (this.studentCourseRelocationForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validStudentCourse = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.studentCourseRelocation = new StudentCourseRelocation(this.studentCourseRelocationForm.value);

    this.studentCourseService.relocation(this.studentCourseRelocation)
    .pipe(first()).subscribe((dataReturn: any) => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.validStudentCourse = true;
        this.submitSuccess(dataReturn.data.identifier.toString())
      } else {
        this.validStudentCourse = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (error: any) => {
      this.validStudentCourse = false;
      this.submitted = false;
      this.loading.hideLoading();
      if (error.status == 400) {
        this.errorMessage = 'Erro ao realocar o aluno a nova turma. Verifique as informações e tente novamente!';
      } else {
        this.errorMessage = error;
      }
    });
  }

  submitSuccess(regNumber: any) {
    this.loading.hideLoading();
    this.dismissModal();

    swal({
      type: 'success',
      title: `Realocação do aluno realizada com sucesso!`,
      showConfirmButton: false,
      timer: 2200
    }).then((result) => {
      window.location.reload();
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  onCancel(): void {
    this.dismissModal();
    this.returnUrlList();
  }

  onReviewCourse(): void {
    this.router.navigate([this.reviewCourseUrl, { id: this.courseId }]);
  }

  private returnUrlList() {
    this.router.navigate([this.returnUrl, { courseId: this.courseId }]);
  }

  onSetCustomPrice(input: any): void {
    this.flagCustomPrice = input.currentTarget.checked;
  }

  selectEvent(item: any) {
    // do something with selected item
    this.onCourseChange(item.id);
  }

  onChangeSearch(val: string) {
    console.log(val);
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e :any) {
    console.log(e);
    // do something when input is focused
  }
}