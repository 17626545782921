<div class="modal-header">
    <h5 class="modal-title">Disponibilidade do(a) Professor(a)</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-primary alert-dismissible">
        <div class="form-group row">
            <div class="col-md-8">
                <div class="d-flex flex-start w-100">
                    <div class="mr-2 hidden-md-down">
                        <span class="icon-stack icon-stack-lg">
                            <i class="base base-6 icon-stack-3x opacity-100 color-primary-500"></i>
                            <i class="base base-10 icon-stack-2x opacity-100 color-primary-300 fa-flip-vertical"></i>
                            <i class="fal fa-info icon-stack-1x opacity-100 color-white"></i>
                        </span>
                    </div>
                    <div class="d-flex flex-fill ml-3">
                        <div class="flex-fill">
                            <p>Selecione os horários que o(a) teacher estará disponível para aulas.</p>
                            <p>Esses horários ficarão visíveis nos locais públicos.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="mr-2 hidden-md-down">
                    <h4>Legenda & Cores</h4>
                    <div class="row mb-2">
                        <div class="col-2">
                            <span class="width-1 height-1 rounded-circle d-block bg-success m-auto"></span>
                        </div>
                        <div class="col-10">
                            Disponível
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-2">
                            <span class="width-1 height-1 rounded-circle d-block bg-white border-1 border-secondary m-auto"></span>
                        </div>
                        <div class="col-10">
                            Indisponível
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12">
            <button type="button" class="btn btn-sm btn-info waves-effect waves-themed mb-2 pull-left" (click)="cleanAvailability()">
                <i class="fa fa-eraser mr-1"></i> Limpar disponibilidade
            </button>
            <button type="button" class="btn btn-sm btn-primary waves-effect waves-themed pull-right" (click)="done()">
                <i class="fa fa-check mr-1"></i> Confirmar
            </button>
            <button type="button" class="btn btn-sm btn-secondary waves-effect waves-themed pull-right mr-2" data-dismiss="modal" (click)="cancel()">
                <i class="fa fa-times-circle mr-1"></i> Cancelar
            </button>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-12">
            <div class="table-responsive-lg">
                <table class="table table-bordered m-0">
                    <thead class="thead-dark">
                        <tr>
                            <th *ngFor="let day of (daysOfWeekDataList$ | async)" class="text-center">{{day.text}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let hour of hoursOfDay">
                            <th *ngFor="let day of (daysOfWeekDataList$ | async)" class="text-center fw-700 check-time" (click)="setTeacherAvailability(day.id, hour)"
                                [ngClass]="checkAvailability(day.id, hour) ? 'bg-success text-white' : 'bg-white text-secondary'">
                                {{hour}}
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-12">
            <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validAvailability">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
                {{errorMessage}}
            </div>
            <div *ngIf="listErrorMessage && !validAvailability" class="invalid-feedback">
                <div *ngFor="let error of listErrorMessage">{{error}}</div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="cancel()">
        <i class="fa fa-times-circle mr-1"></i> Cancelar
    </button>
    <button type="button" class="btn btn-sm btn-primary waves-effect waves-themed" (click)="done()">
        <i class="fa fa-check mr-1"></i> Confirmar
    </button>
</div> -->
