import { InitAdminService, WritingTestService } from '@app/admin/services';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WritingTest } from '@app/admin/model';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MessageService } from '@app/shared/services';
import { first } from 'rxjs/operators';
import { TypeResponse } from '@app/shared/enum';
import { default as swal } from 'sweetalert2'
//import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: { summernote: { ui: any; }; };

@Component({
  selector: 'app-writing-test-solve',
  templateUrl: './writing-test-solve.component.html',
  styleUrls: ['./writing-test-solve.component.css']
  //providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class WritingTestSolveComponent implements OnInit {
  public editorDisabled = false;
  public writingTestForm: any;
  public submitted = false;
  public returnUrl: string = '/admin/writingtestlist';
  public validWritingTest: boolean;
  public updateWritingTest: boolean = false;
  public errorMessage = '';
  public listErrorMessage: any[];
  private writingTest: WritingTest;
  public phoneMask: Array<string | RegExp>;
  public cepMask: Array<string | RegExp>;
  public writingTestValue: any;
  
  public editMode: boolean;
  public idWritingTest: number;

  public config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']]
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']]
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear'
          ]
        ]
      ]
    },
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear'
        ]
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
      ['customButtons', ['testBtn']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    fontSizes: ['8','9','10','11','12','14','18','24','36','44','56','64','76','84','96'],
    fontNames: ['Arial', 'Times New Roman','Inter', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times', 'MangCau', 'BayBuomHep','BaiSau','BaiHoc','CoDien','BucThu', 'KeChuyen', 'MayChu', 'ThoiDai', 'ThuPhap-Ivy', 'ThuPhap-ThienAn'],
    buttons: {
      testBtn: customButton
    },
    codeviewFilter: true,
    codeviewFilterRegex: /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true
  };

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private writingTestService: WritingTestService,
    private activatedRoute: ActivatedRoute,
    private initAdmin: InitAdminService,
    private loading: MessageService
  ) {
    this.phoneMask = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.cepMask = [/[0-9]/, /\d/ ,/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/writingtestlist';
    
    this.activatedRoute.params.subscribe(params => {
      this.idWritingTest = params['id'];
      if (this.idWritingTest > 0) {
        this.editMode = true;
        this.loadWritingTest(this.idWritingTest);
      } else {
        this.editMode = false;
        // Initialize the register form
        this.onInitForm();
      }
    });

    this.loading.hideLoading();
  }

  onInitForm() {
    this.validWritingTest = true;
    this.writingTestForm = this.formBuilder.group({
      id: [0],
      contentHtml: new FormControl('', Validators.required),
      name: ['', [Validators.required]],
      order: [0, [Validators.required]],
      usageCount: new FormControl(0),
      createdOn: [''],
      updatedOn: ['']
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.writingTestForm.controls;
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validWritingTest = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.writingTestForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validWritingTest = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.writingTest = new WritingTest(this.writingTestForm.value);
    
    this.writingTestService.save(this.writingTest.id, this.writingTest, this.editMode)
    .pipe(first())
    .subscribe((dataReturn: any) => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        swal({
          type: 'success',
          title: this.editMode ? 'Teste de escrita atualizado com sucesso!' : 'Teste de escrita criado com sucesso!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          this.validWritingTest = true;
          this.router.navigate([this.returnUrl]);
        });
      } else {
        this.validWritingTest = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (error: any) => {
      this.validWritingTest = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = !error.ok ? "Erro ao salvar o teste." : error;
    });
  }

  loadWritingTest(id: any) {
    this.loading.showLoading();
    this.writingTestService.getById(id)
    .subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validWritingTest = true;
        this.writingTest = new WritingTest(response.data);
        this.setFormByEntity(this.writingTest);
      } else {
        this.errorMessage = response.message;
        this.validWritingTest = false;
      }
      this.loading.hideLoading();
      }, (error: any) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          this.router.navigate([this.returnUrl]);
        }
      }
    );
  }

  setFormByEntity(data: any) {
    this.writingTestForm = this.formBuilder.group({
      id: new FormControl(data.id),
      name: new FormControl(data.name),
      order: new FormControl(data.order),
      usageCount: new FormControl(data.usageCount),
      contentHtml: new FormControl(data.contentHtml)
    });
  }

  finishFunction(){
    var a = 'finish';
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  get sanitizedHtml() {
    return this.sanitizer.bypassSecurityTrustHtml(this.writingTestForm.get('html').value);
  }

  public enableEditor() {
    this.editorDisabled = false;
  }

  public disableEditor() {
    this.editorDisabled = true;
  }

  public onBlur() {
    console.log('Blur');
  }

  public onDelete(file: any) {
    console.log('Delete file', file.url);
  }

  public summernoteInit(event: any) {
    console.log(event);
  }
}

function customButton(context: any) {
  const ui = $.summernote.ui;
  const button = ui.button({
    contents: '<i class="note-icon-magic"></i> Hello',
    tooltip: 'Custom button',
    container: '.note-editor',
    className: 'note-btn',
    click: function() {
      context.invoke('editor.insertText', 'Hello from test btn!!!');
    }
  });
  return button.render();
}
