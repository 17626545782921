import { Base } from '@app/shared/model';

export class Bank extends Base {
    code: number;
    name: string;
    
    constructor(contentResponse: any) {
        super(contentResponse);
        this.code = parseInt(contentResponse.code);
        this.name = contentResponse.name;
    }
}
