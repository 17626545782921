import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/auth/helpers';
import { ModuleType } from '@app/shared/enum';
import { StudentclassescourseConfirmedClassesSyntheticComponent } from './components/confirmed-classes/studentclassescourse-confirmed-classes-synthetic/studentclassescourse-confirmed-classes-synthetic.component';
import { StudentclassescourseConfirmedClassesAnalyticComponent } from './components/confirmed-classes/studentclassescourse-confirmed-classes-analytic/studentclassescourse-confirmed-classes-analytic.component';
import { ClassroomInProgressComponent } from './components/classroom-in-progress/classroom-in-progress.component';
import { ClassesAbsencesReportComponent } from './components/classes-absences/classes-absences-report.component';

// Components

const routes: Routes = [
  { path: 'confirmedClasses-analytic', component: StudentclassescourseConfirmedClassesAnalyticComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AD2AL2', title: 'Aulas Confirmadas' } },
  { path: 'confirmedClasses-synthetic', component: StudentclassescourseConfirmedClassesSyntheticComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AD2AL2', title: 'Aulas Confirmadas' } },
  { path: 'classroom-in-progress', component: ClassroomInProgressComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AD2AL2', title: 'Aulas em andamento' } },
  { path: 'classes-absences', component: ClassesAbsencesReportComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AD2AL2', title: 'Relatório de faltas' } },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingRoutes { }
