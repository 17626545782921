export class ResetPassword {
    email: string;
    password: string;
    confirmPassword: string;
    userid: string;
    code: string;

    constructor(contentResponse: any) {
        this.userid = contentResponse.userid;
        this.code = contentResponse.code;
        this.email = contentResponse.email;
    }
}
