import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { default as swal } from 'sweetalert2'

// Services
import { InitAuthService } from '@app/auth/services';
import { AccountService } from '@app/auth/services';
import { AlertService, IpAddressService } from '@app/shared/services'
import { MessageService } from '@app/shared/services/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
  //pageSettings = pageSettings;
  closeResult: string;
  submitted = false;
  returnUrl: string;
  loginForm;
  public validLogin = true;
  public errorMessage = '';
  public listErrorMessage: any = [];
  private ipAddress: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    private initAuth: InitAuthService,
    private loading: MessageService,
    private ip: IpAddressService
  ) {
    this.modalService.dismissAll();
    this.loginForm = this.formBuilder.group({
      username: '',
      password: '',
      rememberme: false
    });
  }

  ngOnInit() {
    this.initAuth.setConfigPage();
    swal.close();
    this.dismissModal();
    this.loading.hideLoading();
    
    // Errors Cleaning
    this.errorMessage = '';
    this.validLogin = true;

    this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
        rememberme: [false],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin/home';
  }

  //this.router.navigate(["/inspecao/home/0"]);
  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }
  
  onSubmit() {
    this.submitted = true;

    // Errors Cleaning
    this.errorMessage = '';
    this.listErrorMessage = [];
    this.validLogin = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validLogin = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }
    
    this.submitted = true;
    this.loading.showLoading();

    let ipAddress = '';//this.ip.getIPAddress();

    this.accountService.login(this.loginForm.value.username, this.loginForm.value.password, this.loginForm.value.rememberme, ipAddress)
    .pipe(first())
    .subscribe(data => {
      if (data.authenticated) {
        this.errorMessage = data.authenticated == true ? '' : 'O usuário e senha são inválidos. Tente novamente.';
        this.validLogin = data.authenticated == true;
        this.submitted = false;

        this.accountService.userRolesStore()
        .pipe(first()).subscribe(userRoleData => {
          this.submitted = false;
          this.loading.hideLoading();
          console.log('ok');
          this.router.navigate([data.route]);
        },
        error => {
          this.submitted = false;
          this.loading.hideLoading();
          this.alertService.showError(error, "Falha na autenticação!");
          this.router.navigate([data.route]);
        });
      } else {
        //O usuário e senha são inválidos. Tente novamente.
        this.validLogin = false;
        if (data.statusText == 'Unknown Error') {
          this.errorMessage = 'Não foi possível realizar o login.<br>Tente novamente.';
        } else if(data.status == 400) {
          this.setListError(data.error);
        } else {
          this.errorMessage = data.message;
        }
        this.submitted = false;
        this.loading.hideLoading();
      }
    },
    error => {
      this.submitted = false;
      this.loading.hideLoading();
      this.alertService.showError(error, "Falha na autenticação!");
    });
  }

  setListError(data: any) {
    if (data.errors != null) {
      this.errorMessage = 'Não foi possível realizar o login.<br>Tente novamente.';
      let ixList = Object.keys(data.errors);
      for (var i = 0; i < ixList.length; i++) {
        this.listErrorMessage.push(data.errors[Object.keys(data.errors)[i]]);
        //this.listErrorMessage.push(dataReturn.data.errors[i].description);
      }
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  printObject(obj: any):void{
    const keys = Object.keys(obj)
    const values = keys.map(key => `${key}: ${Reflect.get(obj,key)}`)
    console.log(values)
  }
}
