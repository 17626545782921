import { StatusClass } from "@app/shared/enum";
import { ReportFormat } from "@app/shared/enum/ReportFormat";

export class StudentClassesCourseConfirmedFilter {
    teacherId: number;
    courseId: number;
    studentId: number;
    startDate: Date;
    endDate: Date;
    statusClass: StatusClass;
    reportFormat: ReportFormat;

    constructor(contentResponse: any) {
        if (contentResponse) {
            this.teacherId = contentResponse.teacherId;
            this.courseId = contentResponse.courseId;
            this.studentId = contentResponse.studentId;
            this.startDate = contentResponse.startDate;
            this.endDate = contentResponse.endDate;
            this.statusClass = contentResponse.statusClass;
            this.reportFormat = contentResponse.reportFormat;
        } else {
            this.teacherId = 0;
            this.courseId = 0;
            this.studentId = 0;
        }
    }
}