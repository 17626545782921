import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';
import { AvgScore, WritingTestStatus } from '@app/shared/enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WritingTestApplyService {
  private route: string = 'content';
  private controller: string = 'writingtestapply';
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getAll(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getById(id: string): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }

  remove(id: any): Observable<any> {
    return this.http.delete<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }

  save(id: number, model: any, editing: boolean = false): any {
    if (editing) {
      //update
      return this.http.put<ResponseResult>(
        `${this.endpointPrefix}/${id}`,
        model
      );
    } else {
      //new
      return this.http.post<ResponseResult>(
        `${this.endpointPrefix}/add`,
        model
      );
    }
  }

  getByTeacherAppliedId(teacherId: any): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/teacherApplied/${teacherId}`
    );
  }

  getByStatus(writingTestStatus: WritingTestStatus): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/status/${writingTestStatus}`
    );
  }

  getByAvgScore(avgScore: AvgScore): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/avgScore/${avgScore}`
    );
  }

  getByWritingTestId(writingTestId: any): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/writingTest/${writingTestId}`
    );
  }

  toDoStudent(studentId: any): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/toDoStudent/${studentId}`
    );
  }

  getByWritingTestIdAndTeacherAppliedId(
    writingTestId: any,
    teacherAppliedId: any
  ): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/writingTest/${writingTestId}/teacher/${teacherAppliedId}`
    );
  }

  writingTestAppliedReview(writingTestApplyId: number, model: any): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/WritingTestAppliedReview/${writingTestApplyId}`,
      model
    );
  }

  writingTestAppliedStudent(id: number, model: any): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/writingTestAppliedStudent/${id}`,
      model
    );
  }

  toReviewByTeacher(teacherId: any): any {
    return this.http.get<ResponseResult>(
      `${this.endpointPrefix}/toReviewByTeacher/${teacherId}`
    );
  }

  saveTextReview(id: number, model: any): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/SaveTextReview/${id}`,
      model
    );
  }

  saveTextStudent(id: number, model: any): any {
    return this.http.post<ResponseResult>(
      `${this.endpointPrefix}/SaveTextStudent/${id}`,
      model
    );
  }

  writingTestReset(id: number): any {
    return this.http.patch<ResponseResult>(
      `${this.endpointPrefix}/writingTestReset/${id}`,
      null
    );
  }

  changeTeacher(id: number, newTeacherId: number): any {
    return this.http.patch<ResponseResult>(
      `${this.endpointPrefix}/changeTeacher/id/${id}/teacher/${newTeacherId}`,
      null
    );
  }
}
