import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/auth/model';
import { AccountService } from '@app/auth/services';
import { MessageQueueService } from '@app/marketing/services';
import { MessageTypeCss, MessageTypeLabel, ModuleTypeCss, ModuleTypeLabel, TypeResponse } from '@app/shared/enum';
import { MessageService } from '@app/shared/services';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

@Component({
  selector: 'notification-header-list',
  templateUrl: './notification-header-list.component.html',
  styleUrls: ['./notification-header-list.component.css']
})
export class NotificationHeaderListComponent implements OnInit {
  @Input("userId") userId: any;
  public user: User;
  
  closeResult = '';
  public returnMessage = '';
  moduleId = 0;
  notificationId = 0;
  sidNotification = '';

  notificationCount = 0;
  notificationStr = "notificação";
  
  public notificationlist = [];
  public showNotificationList = false;

  // Pagination
  currentMessage = null;
  currentIndex = -1;
  page = 1;
  pageSize = 5;
  totalPages = 0;
  totalItems = 0;

  constructor(
    private router: Router,
    private modalService: NgbModal, 
    private loading: MessageService,
    private accountService: AccountService,
    private messageQueueService: MessageQueueService
  ) {
    this.user = this.accountService.getUserData();
  }

  ngOnInit(): void {
    this.retrieveNotifications();
  }

  handlePageChange(event: any): void {
    this.page = event;
    this.retrieveNotifications();
  }

  handlePageSizeChange(event : any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveNotifications();
  }

  retrieveNotifications() {
    this.messageQueueService.getByPage(this.user.userId, this.page, this.pageSize).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.designItems(response.data.items);
        this.notificationCount = response.data.metadata.count;
        this.totalPages = response.data.metadata.totalPages;
        this.totalItems = response.data.metadata.totalItems;
        this.notificationStr = this.notificationCount > 1 ? "notificações" : "notificação";
        this.showNotificationList = true;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
        this.showNotificationList = false;
      }
    }, (error: any) => {
      this.showNotificationList = false;
      this.returnMessage = 'Erro ao carregar as notificações.';
    });
  }

  designItems(lista: any[]) {
    lista.forEach(function(e) {
      if (e.messageType >= 0) {
        let messageTypeStr = MessageTypeLabel.get(e.messageType);
        let messageTypeCss = MessageTypeCss.get(e.messageType);
        e.designMessageType = "<span class='badge w-30 pull-right "
          + messageTypeCss +" badge-pill'>"
          + messageTypeStr +"</span>";
      }

      if (e.module >= 0) {
        let moduleTypeStr = ModuleTypeLabel.get(e.module);
        let moduleTypeCss = ModuleTypeCss.get(e.module);
        e.designModule = "<span class='badge w-30 pull-right "
          + moduleTypeCss +" badge-pill'>"
          + moduleTypeStr +"</span>";
      }
    });
    this.notificationlist = lista;
  }
  
  confirmedNotification(sidNotification: any) {
    this.loading.showLoading();
    if (!this.user?.userId) {
      window.location.reload();
      return;
    }

    this.messageQueueService.confirmed(sidNotification, this.user?.userId).pipe(first()).subscribe((response: any) => {
      this.loading.hideLoading();
      this.modalService.dismissAll();
      window.location.reload();
    }, (error: any) => {
      if (error.error.error === 'invalid_token') {
        this.router.navigate(['/auth/login']);
        return;
      }
      window.location.reload();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();
  
      console.log(reason);
  
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }
  
  viewNotification(content: any, notice: any, index: number) {
    this.notificationId = notice.id;
    this.sidNotification = notice.sidNotification;
    this.modalService.dismissAll();
    this.modalService.open(content, { backdrop: 'static', size: 'lg', windowClass:'animated fade'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
