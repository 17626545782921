import { NgModule, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
//import { ListViewModule } from '@syncfusion/ej2-angular-lists';
//import { NgxDropzoneModule } from 'ngx-dropzone';
//import { NgSelect2Module } from 'ng-select2';

import { SharedModule } from '@app/shared/shared.module';
import { AdminModule } from '@app/admin/admin.module';
import { TextMaskModule } from '../_helpers/mask/text.mask';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { FinancialModule } from '@app/financial/financial.module';
import { AdminRoutingModule } from '@app/admin/admin-routing.module';
import { MessageRoutingRoutes } from './message-routing.module';
import { ChatMessageWorkspaceComponent } from './components/chat/chat-message-workspace/chat-message-workspace.component';
import { ChatMessagePrivateComponent } from './components/chat/chat-message-private/chat-message-private.component';

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
  ChatMessageWorkspaceComponent,
  ChatMessagePrivateComponent],
  imports: [
    MessageRoutingRoutes,
    AdminModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    //NgxDropzoneModule,
    ChartsModule,
    TextMaskModule,
    ArchwizardModule,
    FinancialModule,
    AdminRoutingModule,
    //ListViewModule,
    //NgSelect2Module,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
  ],
  exports: [
    ChatMessageWorkspaceComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [ DatePipe ]
})
export class MessageModule { }
