import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { default as swal } from 'sweetalert2';
import { Forget } from '@app/auth/model';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse, ModuleTypeLabel, ModuleTypeCss } from '@app/shared/enum';
import { MessageService } from '@app/shared/services/message.service';
import { UserService } from '@app/auth/services';

@Component({ templateUrl: 'user-list.component.html', providers: [DatePipe] })
export class UserListComponent implements OnInit {
  closeResult: string;
  public users: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public auxIcon = 'fa fa-key';
  public auxTitle = 'Resetar senha';

  public auxIconLock = 'fas fa-lock-alt';
  public auxTitleLock = 'Inativar Usuário';

  returnUrl: string = '/admin/userlist';

  public columns: Array<any> = [
    { title: 'Código', name: 'id', sort: 'asc', className: 'text-center' },
    { title: 'Nome', name: 'name', sort: 'asc' },
    { title: 'E-mail', name: 'email', sort: 'asc' },
    {
      title: 'Confirmado?',
      name: 'emailConfirmed',
      sort: 'asc',
      className: 'text-center',
    },
    { title: 'Kids?', name: 'kidsUser', sort: 'asc', className: 'text-center' },
    {
      title: 'Módulo',
      name: 'designModule',
      sort: 'asc',
      className: 'text-center fw-700',
      isHtmlData: true,
    },
    {
      title: 'Ativo?',
      name: 'generalStatus',
      sort: 'asc',
      className: 'text-center text-primary fw-700',
    },
    // {
    //   title: "Criado em",
    //   name: "createdOn",
    //   sort: "asc",
    //   className: "text-center text-primary datetime"
    // },
    // {
    //   title: "Modificado em",
    //   name: "updatedOn",
    //   sort: "asc",
    //   className: "text-center text-primary datetime"
    // }
  ];

  constructor(
    private initAdmin: InitAdminService,
    private userService: UserService,
    private router: Router,
    private loading: MessageService
  ) {}

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadUsers();
    this.returnUrl = '/admin/userlist';
  }

  loadUsers() {
    this.loading.showLoading();
    this.users = [];
    this.userService
      .getallwithoutme()
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.users = response.data;
            this.designModuleType(this.users);
            this.showTable = true;
            this.returnMessage = '';
          } else {
            this.returnMessage = response.message;
            this.showTable = false;
          }
          this.loading.hideLoading();
        },
        (error: any) => {
          this.loading.hideLoading();
          this.showTable = false;
          this.returnMessage = 'Erro ao carregar os dados dos usuários.';
        }
      );
  }

  designModuleType(lista: any[]) {
    lista.forEach(function (e) {
      if (e.moduleId >= 0) {
        let moduleTypeCss = ModuleTypeCss.get(e.module.moduleType);
        let moduleTypeStr = ModuleTypeLabel.get(e.module.moduleType);
        e.designModule =
          "<span class='badge badge-pill w-100 " +
          moduleTypeCss +
          "'>" +
          moduleTypeStr +
          '</span>';
      }
      e.generalStatus = e.status === 1;
    });
    this.users = lista;
  }

  delete(target: any): void {
    swal({
      title: 'Excluir Usuário',
      html:
        'Confirma a exclusão <strong>' +
        target.name +
        '</strong>?<br>' +
        'Ao confirmar o usuário será excluído permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.userService.remove(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.loadUsers();
                  //this.loadUsers();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir o usuário!',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir o usuário.',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                  this.loadUsers();
                });
              }
            },
            (error) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir o usuário.',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir o usuário.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/user', { id: row.id }]);
  }

  preview(id: any): void {}

  resetarSenha(target: {
    id: number;
    email: string;
    userGuid: string;
    name: string;
  }): void {
    if (target && target.id > 0) {
      let forget = new Forget();
      forget.email = target.email;
      forget.userid = target.userGuid;
      forget.SetPwd = false;
      forget.NewPassword = '';

      swal({
        title: 'Redefinição de senha',
        html:
          'Como deseja alterar a senha do usuário:<br><strong>' +
          target.name +
          '?</strong>',
        type: 'warning',
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Senha aleatória',
        cancelButtonText: 'Informar nova senha',
      }).then(
        (randomPwd) => {
          if (randomPwd.value === true) {
            return this.submitReset(forget);
          } else {
            return this.resetWithNewPwd(forget);
          }
        },
        (error) => {
          // expiração do token
          if (error.error.error === 'invalid_token') {
            this.router.navigate(['/auth/login']);
          } else {
            this.getErrorMsgPassword(
              'error',
              'Erro ao tentar resetar a senha',
              'Não foi possível resetar a senha do usuário. Tente novamente mais tarde.'
            );
          }
        }
      );
    } else {
      this.getErrorMsgPassword(
        'error',
        'Erro ao tentar resetar a senha',
        'Não foi possível resetar a senha do usuário. Tente novamente mais tarde.'
      );
    }
  }

  private resetWithNewPwd(forget: Forget) {
    swal({
      title: 'Nova senha',
      input: 'password',
      inputValue: '',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
      showLoaderOnConfirm: true,
      preConfirm: (password) => {
        let invalidPwdMessage = this.userService.validNewPwd(password);
        if (invalidPwdMessage) {
          swal.showValidationMessage(invalidPwdMessage);
        }
        // if (password==='' || !validPwd.valid) {
        //   swal.showValidationMessage(validPwd.message);
        // }
        else {
          forget.SetPwd = true;
          forget.NewPassword = password;
          return this.submitReset(forget);
        }
      },
    });
  }

  lockUser(row: any) {
    if (row && row.id > 0) {
      swal({
        title: 'Troca de status do usuário',
        html:
          'Deseja ATIVAR/INATIVAR o usuário:<br><strong>' +
          row.name +
          '?</strong>',
        type: 'warning',
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Sim, confirmar',
        cancelButtonText: 'Cancelar',
      }).then(
        (input) => {
          if (input.value === true) {
            this.userService.lockUser(row.userGuid).subscribe(
              (response) => {
                if (
                  'typeResponse' in response &&
                  response['typeResponse'] === TypeResponse.Success
                ) {
                  this.loading.hideLoading();
                  return this.lockUserSuccess(response.message);
                } else if (
                  'typeResponse' in response &&
                  response['typeResponse'] === TypeResponse.Alert
                ) {
                  this.getErrorMsgPassword(
                    'warning',
                    'Não foi possível trocar o status do usuário!',
                    response.message
                  );
                } else if (
                  'typeResponse' in response &&
                  response['typeResponse'] === TypeResponse.Error
                ) {
                  this.getErrorMsgPassword(
                    'error',
                    'Erro ao tentar trocar o status do usuário',
                    'Não foi possível trocar o status do usuário. Tente novamente mais tarde.'
                  );
                }
              },
              (error) => {
                this.getErrorMsgPassword(
                  'error',
                  'Erro ao tentar trocar o status do usuário',
                  'Não foi possível trocar o status do usuário. Tente novamente mais tarde.'
                );
              }
            );
          }
        },
        (error) => {
          // expiração do token
          if (error.error.error === 'invalid_token') {
            this.router.navigate(['/auth/login']);
          } else {
            this.getErrorMsgPassword(
              'error',
              'Erro ao tentar trocar o status do usuário',
              'Não foi possível trocar o status do usuário. Tente novamente mais tarde.'
            );
          }
        }
      );
    } else {
      this.getErrorMsgPassword(
        'error',
        'Erro ao tentar trocar o status do usuario',
        'Não foi possível trocar o status do usuário. Tente novamente mais tarde.'
      );
    }
  }

  private submitReset(forget: Forget) {
    this.loading.showLoading();
    this.userService.resetPasswordByForget(forget).subscribe(
      (response) => {
        if (
          'typeResponse' in response &&
          response['typeResponse'] === TypeResponse.Success
        ) {
          this.loading.hideLoading();
          return this.pwdResetSuccess();
        } else if (
          'typeResponse' in response &&
          response['typeResponse'] === TypeResponse.Alert
        ) {
          this.getErrorMsgPassword(
            'warning',
            'Não foi possível redefinir a senha do usuário!',
            response.message
          );
        } else if (
          'typeResponse' in response &&
          response['typeResponse'] === TypeResponse.Error
        ) {
          this.getErrorMsgPassword(
            'error',
            'Erro ao tentar resetar a senha',
            'Não foi possível resetar a senha do usuário. Tente novamente mais tarde.'
          );
        }
      },
      (error) => {
        this.getErrorMsgPassword(
          'error',
          'Erro ao tentar resetar a senha',
          'Não foi possível resetar a senha do usuário. Tente novamente mais tarde.'
        );
      }
    );
  }

  private lockUserSuccess(msg: any) {
    swal({
      type: 'success',
      title: msg,
      showConfirmButton: false,
      showCloseButton: true,
      timer: 1500,
    }).then((result) => {
      this.loadUsers();
    });
  }

  private pwdResetSuccess() {
    swal({
      type: 'success',
      title: 'Senha redefinida com sucesso!',
      showConfirmButton: false,
      showCloseButton: true,
      timer: 1500,
    }).then((result) => {
      this.loadUsers();
    });
  }

  getErrorMsgPassword(type: any, title: any, message: any) {
    this.loading.hideLoading();
    swal({
      type: type,
      title: title,
      text: message,
      showCloseButton: true,
      showConfirmButton: false,
      //timer: 1500
    }).then((result) => {
      this.loadUsers();
      //window.location.reload();
    });
  }
}
