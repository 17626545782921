import { Component, OnInit } from '@angular/core';
import { User } from '@app/auth/model';
//requiring path and fs modules
import { Router } from '@angular/router';
import { MessageService } from '@app/shared/services';
import { AccountService } from '@app/auth/services';
import { InitTeacherService } from '@app/teacher/services';
import { ContentJsonService } from '@app/shared/services/contentJson.service';

@Component({
  selector: 'student-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {
  public showTable: boolean = true;
  public returnMessage: string = '';
  public packageContent: Array<any>;
  public contentPath: string;
  public imgFileName: string;
  user: User;

  public series: any;

  constructor(
    private router: Router,
    private loading: MessageService,
    private accountService: AccountService,
    private initTeacher: InitTeacherService,
    private contentJsonService: ContentJsonService) {
      this.user = this.accountService.getUserData();
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initTeacher.setConfigPage();
    this.loadSeries();
    this.loading.hideLoading();
    //document.querySelector('div#graphDiv').innerHTML = '';
  }

  public loadSeries() {
    this.packageContent = new Array<any>();
    this.contentJsonService.AllSeries().subscribe((response: any) => {
      this.packageContent.push(response.find((x: any) => x.serieType == 'regular'));
      this.packageContent.push(response.find((x: any) => x.serieType == 'kids'));
      this.packageContent.push(response.find((x: any) => x.serieType == 'treinamento'));
    });
  }

  replaceNameSerie(serieType: any): string {
    return serieType === 'kids' 
      ? 'Grade 2'
      : serieType === 'regular' 
      ? 'Grade 1'
      : serieType;
  }

  showContent(type: string, itemSerie: any): void {
    this.loading.showLoading();
    this.router.navigate(['/teacher/contentlist', { type, serie: itemSerie }]);
  }

  public getContentPath(type: string, serie: string) {
    return this.contentJsonService.getContentPath(type, serie);
  }
}
