import {
  CalendarService,
  CourseService,
  InitAdminService,
  StudentClassesCourseService,
  StudentCourseService,
  TeacherService,
} from '@app/admin/services';
import {
  TypeResponse,
  PaymentStatusCss,
  PaymentStatusLabel,
  StatusClass,
  StatusClassCss,
  StatusClassLabel,
  ModuleType,
} from '@app/shared/enum';
import {
  ClassesCalendarEvent,
  Course,
  StudentCourseResume,
} from '@app/admin/model';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { MessageService } from '@app/shared/services';
import { default as swal } from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import { Options } from 'select2';

@Component({
  selector: 'studentclassescourse-list',
  templateUrl: './studentclassescourse-list.component.html',
  styleUrls: ['./studentclassescourse-list.component.css'],
  providers: [DatePipe],
})
export class StudentClassesCourseListComponent implements OnInit {
  @Input('studentCourseIdentifier') studentCourseIdentifier: any;
  @Input('studentCourseClassId') studentCourseClassId: any;
  public flagSearchInfo = false;
  public validInfo: boolean = true;
  public showTableClasses: boolean = true;
  public studentCourseData: any;
  public options: Options;
  currentPage = 1;
  itemsPerPage = 5;
  pageSize: number;
  collectionSize: number;
  closeResult: string;

  public studentClassesCourseExpectedDate: any;
  public studentClassesCourseExpectedTime: any;
  public studentClassesCourseReschedule = new Date();

  public errorMessage: string;
  public teacherDataList: Array<any>;
  public userData: any;
  public userList: any = [];
  public userId: number = 0;

  public course: Course;
  public courseId: any;
  public courseList: Array<Course> = [];
  public validCourse: boolean = true;
  public validCourseListMessage: any[];
  public flagCourseSelected: boolean = false;

  public classesCalendarEventData$ = new BehaviorSubject([]);
  public classesCalendarEventData: Array<ClassesCalendarEvent> = [];
  public returnMessage: string =
    'Não existem aulas para a matrícula selecionada.';

  public columns: Array<any> = [
    { title: 'Aula', name: 'numberClass', sort: 'asc' },
    { title: 'Professor', name: 'teacherName', sort: 'asc' },
    {
      title: 'Previsão',
      name: 'expectedDate',
      sort: 'asc',
      className: 'text-center text-primary datetime',
    },
    {
      title: 'Status Aula',
      name: 'statusClassDesign',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center text-primary',
    },
  ];

  public iconClassConfirmed = 'fa fa-calendar-check';
  public titleClassConfirmed = 'Confirmar Aula';

  public iconClassReschedule = 'fa fa-calendar-alt';
  public titleClassReschedule = 'Remarcação da aula';

  public iconClassTeacherChange = 'fa fa-user-alt';
  public titleClassTeacherChange = 'Troca de Professor';

  public iconResetStatusClass = 'fa fa-redo-alt text-secondary';
  public titleResetStatusClass = 'Restaurar Status';

  public iconClassActionsLog = 'fa fa-shield-check';
  public titleClassActionsLog = 'Log de Ações';

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private loading: MessageService,
    private initAdmin: InitAdminService,
    private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private teacherService: TeacherService,
    private calendarService: CalendarService,
    private studentCourseService: StudentCourseService,
    private studentClassesCourseService: StudentClassesCourseService
  ) {
    this.studentCourseData = new StudentCourseResume();
    this.classesCalendarEventData$ = new BehaviorSubject([]);
    this.classesCalendarEventData = new Array<ClassesCalendarEvent>();
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.courseList = new Array<Course>();
    this.activatedRoute.params.subscribe((params) => {
      this.studentCourseIdentifier = params['id'];
      if (this.studentCourseIdentifier) {
        this.getStudentCourseInfo(this.studentCourseIdentifier);
        this.getStudentCourseCalendar(this.studentCourseIdentifier);
        this.options = {
          width: '300',
        };
        this.loadTeachers();
      } else {
        return this.router.navigate(['/admin/studentCourselist']);
      }
    });
  }

  loadTeachers() {
    this.loading.showLoading();
    this.teacherService.all().subscribe(
      (response) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.teacherDataList = this.getTemplateTeacherList(response.data);
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
        }
        this.loading.hideLoading();
      },
      (error: any) => {
        this.returnMessage = 'Erro ao carregar os dados dos professores.';
        this.loading.hideLoading();
      }
    );
  }

  filterCourses(date: any, time: any) {
    this.loading.showLoading();
    this.teacherService.all().subscribe(
      (response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.teacherDataList = this.getTemplateTeacherList(response.data);
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
        }
        this.loading.hideLoading();
      },
      (error: any) => {
        this.returnMessage = 'Erro ao carregar os dados dos professores.';
        this.loading.hideLoading();
      }
    );
  }

  getTemplateTeacherList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.userList = data;
      let arrayTeacherList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id,
          text: data[i].id + ' | ' + data[i].name + ' | ' + data[i].email,
          additional: {
            email: data[i].email,
          },
        };
        arrayTeacherList.push(element);
      }
      return arrayTeacherList;
    }
    return new Array<any>();
  }

  getStudentCourseCalendar(studentCourseIdentifier: any) {
    this.flagSearchInfo = true;
    this.calendarService
      .getStudentCourseCalendarByIdentifier(studentCourseIdentifier)
      .subscribe(
        (response: any) => {
          if (
            response.typeResponse == TypeResponse.Success &&
            response.data.calendarEvents != null
          ) {
            this.classesCalendarEventData = response.data.calendarEvents;
            this.designStatusClasses(this.classesCalendarEventData);
          } else {
            this.classesCalendarEventData = new Array<ClassesCalendarEvent>();
          }
          this.classesCalendarEventData$.next(this.classesCalendarEventData);
          this.collectionSize = this.classesCalendarEventData.length;
          this.showTableClasses = this.classesCalendarEventData.length > 0;
          this.flagSearchInfo = false;
          this.loading.hideLoading();
        },
        (error: any) => {
          this.flagSearchInfo = false;
          this.loading.hideLoading();
        }
      );
  }

  designStatusClasses(lista: any[]) {
    lista.forEach(function (e) {
      e.numberClass = e.studentClassesCourse.numberClass;
      e.teacherName = e.teacher.name;
      e.expectedDate = e.studentClassesCourse.expectedDate;

      if (e.statusClass >= 0) {
        let statusClassCss = StatusClassCss.get(e.statusClass);
        let statusClassStr = StatusClassLabel.get(e.statusClass);
        e.statusClassDesign =
          "<span class='badge badge-pill w-100 font-weight-bold " +
          statusClassCss +
          "'>" +
          statusClassStr +
          '</span>';
      }
    });
    this.classesCalendarEventData = lista;
  }

  getStudentCourseInfo(studentCourseIdentifier: any) {
    this.flagSearchInfo = true;
    this.studentCourseService.getInfo(studentCourseIdentifier).subscribe(
      (response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.studentCourseData = response.data;
          this.studentCourseData.designStatusPayment = this.getStatusPagamento(
            this.studentCourseData
          );
        } else {
          this.studentCourseData = null;
        }
        this.flagSearchInfo = false;
        this.loading.hideLoading();
      },
      (error: any) => {
        this.flagSearchInfo = false;
        this.loading.hideLoading();
      }
    );
  }

  getStatusPagamento(paymentStatus: any) {
    if (paymentStatus >= 0) {
      let paymentStatusCss = PaymentStatusCss.get(paymentStatus);
      let paymentStatusStr = PaymentStatusLabel.get(paymentStatus);
      return (
        "<span class='badge badge-pill w-100 " +
        paymentStatusCss +
        "'>" +
        paymentStatusStr +
        '</span>'
      );
    }
  }

  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

  public changePagesize(num: number): void {
    this.itemsPerPage = this.pageSize + num;
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  changedClass(row: any, statusClass: StatusClass) {
    this.dismissModal();
    switch (statusClass) {
      case StatusClass.Done:
        return this.willConfirm(row?.studentClassesCourse?.id);
      // case StatusClass.Canceled:
      //   return this.willCancel(studentClassesCourseId);
      default:
        return;
    }
  }

  willChangeClass(studentClassesCourseId: any, statusClass: StatusClass) {
    this.studentClassesCourseService
      .changedClass(studentClassesCourseId, statusClass, ModuleType.Admin, 0)
      .subscribe(
        (response: any) => {
          this.loading.hideLoading();
          if (response.typeResponse == TypeResponse.Success) {
            if (response.data === true) {
              this.changedClassSuccess(statusClass);
            } else {
              this.changedClassFailed(statusClass);
            }
          }
        },
        (error: any) => {
          this.loading.hideLoading();
          this.changedClassFailed(statusClass);
        }
      );
  }

  changedClassSuccess(status: StatusClass) {
    swal({
      type: 'success',
      title: `Aula atualizada com sucesso!`,
      showConfirmButton: false,
      timer: 2200,
    }).then((willConfirm) => {
      this.getStudentCourseCalendar(this.studentCourseIdentifier);
    });
  }

  changedClassFailed(status: StatusClass) {
    swal({
      type: 'warning',
      title: `Não foi possível atualizar a aula!`,
      showConfirmButton: true,
    });
  }

  changedClassFailedUpdateDate(status: StatusClass) {
    swal({
      type: 'warning',
      title: `Não foi possível atualizar a aula. Selecione uma nova data e tente novamente!`,
      showConfirmButton: true,
    });
  }

  willConfirm(studentClassesCourseId: any): void {
    swal({
      title: 'Confirmação da Aula',
      html: 'Confirma a conclusão da aula?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, confirmar',
    }).then(
      (willConfirm) => {
        if (willConfirm.value === true) {
          this.dismissModal();
          this.loading.showLoading();
          return this.willChangeClass(studentClassesCourseId, StatusClass.Done);
        } else {
          this.loading.hideLoading();
          return;
        }
      },
      (error) => {
        this.loading.hideLoading();
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar confirmar a aula.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  // willCancel(studentClassesCourseId: any): void {
  //   swal({
  //     title: "Cancelar Aula",
  //     html: 'Confirma o cancelamento da aula?',
  //     type: 'warning',
  //     showConfirmButton: true,
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     confirmButtonText: 'Sim, cancelar'
  //   }).then(willConfirm => {
  //     if (willConfirm.value === true) {
  //       this.dismissModal();
  //       this.loading.showLoading();
  //       return this.willChangeClass(studentClassesCourseId, StatusClass.Canceled);
  //     } else {
  //       this.loading.hideLoading();
  //       return;
  //     }
  //   },
  //   error => {
  //     this.loading.hideLoading();
  //     if (error.error.error === 'invalid_token') {
  //       this.router.navigate(['/auth/login']);
  //     } else {
  //       swal({
  //         type: 'error',
  //         title: 'Erro ao tentar cancelar a aula.',
  //         showConfirmButton: false,
  //         timer: 1500
  //       }).then((result) => {
  //         this.loading.hideLoading();
  //         window.location.reload();
  //       });
  //     }
  //   });
  // }

  changedTeacher(studentClassesCourseId: any, newTeacherId: any) {
    this.dismissModal();
    return this.willChangeTeacher(studentClassesCourseId, newTeacherId);
  }

  willChangeTeacher(studentClassesCourseId: any, newTeacherId: any): void {
    swal({
      title: 'Troca do Processor',
      html: 'Confirma a troca do professor na aula?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, alterar',
    }).then(
      (willConfirm) => {
        if (willConfirm.value === true) {
          this.dismissModal();
          this.loading.showLoading();
          this.studentClassesCourseService
            .changeTeacher(studentClassesCourseId, newTeacherId)
            .subscribe(
              (response: any) => {
                this.loading.hideLoading();
                if (response.typeResponse == TypeResponse.Success) {
                  if (response.data === true) {
                    this.changedClassSuccess(StatusClass.ChangedTeacher);
                  } else {
                    this.changedClassFailed(StatusClass.ChangedTeacher);
                  }
                }
              },
              (error: any) => {
                this.loading.hideLoading();
                this.changedClassFailed(StatusClass.ChangedTeacher);
              }
            );
        } else {
          this.loading.hideLoading();
          return;
        }
      },
      (error) => {
        this.loading.hideLoading();
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar cancelar a aula.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  resetStatusClass(row: any): void {
    swal({
      title: 'Restarar Status',
      html: 'Confirma a restauração do status da aula?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, restaurar',
    }).then(
      (willConfirm) => {
        if (willConfirm.value === true) {
          this.dismissModal();
          this.loading.showLoading();
          this.studentClassesCourseService
            .resetStatusClass(row.studentClassesCourse.id)
            .subscribe(
              (response: any) => {
                this.loading.hideLoading();
                if (response.typeResponse == TypeResponse.Success) {
                  if (response.data === true) {
                    this.changedClassSuccess(StatusClass.Scheduled);
                  } else {
                    this.changedClassFailed(StatusClass.Scheduled);
                  }
                }
              },
              (error: any) => {
                this.loading.hideLoading();
                this.changedClassFailed(StatusClass.Scheduled);
              }
            );
        } else {
          this.loading.hideLoading();
          return;
        }
      },
      (error) => {
        this.loading.hideLoading();
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar restaurar a aula.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  studentCourseClassTeacherChange(row: any, content: any) {
    this.studentCourseClassId = row.studentClassesCourse.id;
    //this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  studentCourseClassActionsLog(row: any, content: any) {
    this.studentCourseClassId = row.studentClassesCourse.id;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        size: 'lg',
        backdrop: 'static',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  studentCourseClassReschedule(row: any, content: any) {
    this.studentCourseClassId = row.studentClassesCourse.id;
    this.studentClassesCourseExpectedDate =
      row.studentClassesCourse.expectedDate;
    this.modalService
      .open(content, {
        backdrop: 'static',
        //size: 'sm',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  findCoursesByDatetime() {
    if (this.studentClassesCourseExpectedDate?.length <= 10) {
      this.studentClassesCourseExpectedDate += ' 00:00:00 -03:00';
    }

    let newDate = new Date(this.studentClassesCourseExpectedDate);
    newDate.setMinutes(this.studentClassesCourseExpectedTime.split(':')[1]);
    newDate.setHours(this.studentClassesCourseExpectedTime.split(':')[0]);
    let newDateStr = formatDate(newDate, 'yyyy-MM-dd HH:mm:ss', 'en-US');
    newDateStr += ' -03:00';

    this.courseService.listByDatetime(newDateStr).subscribe(
      (response: any) => {
        this.loading.hideLoading();
        if (response.typeResponse == TypeResponse.Success) {
          this.courseList = response.data;
        }
      },
      (error: any) => {
        this.loading.hideLoading();
      }
    );
  }

  selectEventCourse(item: any) {
    this.course = item;
    this.courseId = item.id;
  }

  willChangeClassReschedule(willChangeClassReschedule: any): void {
    this.modalService.dismissAll();
    swal({
      title: 'Reagendamento da aula',
      html: 'Confirma o reagendamento da aula?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, alterar',
    }).then(
      (willConfirm) => {
        if (
          willConfirm.value === true &&
          this.studentClassesCourseExpectedDate
        ) {
          if (this.studentClassesCourseExpectedDate.length > 10) {
            this.studentClassesCourseExpectedDate =
              this.studentClassesCourseExpectedDate.substring(0, 10);
            // this.dismissModal();
            // this.changedClassFailedUpdateDate(StatusClass.ChangedTeacher);
            // return;
          }

          this.dismissModal();
          this.loading.showLoading();
          this.studentClassesCourseExpectedDate += ' 00:00:00 -03:00';
          let newDate = new Date(this.studentClassesCourseExpectedDate);
          newDate.setMinutes(
            this.studentClassesCourseExpectedTime.split(':')[1]
          );
          newDate.setHours(this.studentClassesCourseExpectedTime.split(':')[0]);
          let newDateStr = formatDate(newDate, 'yyyy-MM-dd HH:mm:ss', 'en-US');
          newDateStr += ' -03:00';

          this.studentClassesCourseService
            .reschedule(this.studentCourseClassId, newDateStr, this.courseId)
            .subscribe(
              (response: any) => {
                this.loading.hideLoading();
                if (response.typeResponse == TypeResponse.Success) {
                  if (response.data === true) {
                    this.changedClassSuccess(StatusClass.ChangedTeacher);
                  } else {
                    this.changedClassFailed(StatusClass.ChangedTeacher);
                  }
                }
              },
              (error: any) => {
                this.loading.hideLoading();
                this.changedClassFailed(StatusClass.ChangedTeacher);
              }
            );
        } else {
          this.loading.hideLoading();
          return;
        }
      },
      (error) => {
        this.loading.hideLoading();
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar cancelar a aula.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  setValueTime(input: any) {
    this.studentClassesCourseExpectedTime = input.value;
  }

  setValueDate(input: any) {
    this.studentClassesCourseExpectedDate = input.value;
  }
}
