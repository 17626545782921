import { Grades, StudentCourse } from "@app/admin/model";
import { SerieType } from "@app/shared/enum/SerieType";
import { Base } from "@app/shared/model";

export class EpisodeComment extends Base {
    number: number;
    name: string;
    description: string;
    tagName: string;
    shortName: string;
    serieType: SerieType;

    episodes: Array<Grades>;
    comments: Array<StudentCourse>;

    constructor(contentResponse: any) {
        super(contentResponse);
        
        this.number = parseInt(contentResponse.number);
        this.name = contentResponse.name;
        this.description = contentResponse.description;
        this.tagName = contentResponse.tagName;
        this.shortName = contentResponse.shortName;
        this.serieType = contentResponse.serieType;

        if(contentResponse.episodes && contentResponse.episodes.lenght > 0) {
            this.episodes = contentResponse.episodes;
        }

        if(contentResponse.comments && contentResponse.comments.lenght > 0) {
            this.comments = contentResponse.comments;
        }
    }
}
