import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { BankService, InitAdminService } from '@app/admin/services';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, MessageService } from '@app/shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Holiday } from '@app/audit/model';
import { DatePipe } from '@angular/common';
import { HolidayService } from '@app/audit/services/holiday.service';

@Component({
  selector: 'holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.css']
})
export class HolidayComponent implements OnInit {
  holidayForm;
  submitted = false;
  returnUrl: string;
  public flagCustomPrice: boolean = false;
  public validHoliday: boolean;
  public errorMessage = '';
  public listErrorMessage: any[];

  public editHoliday: boolean;
  private holiday: Holiday;
  public holidayId: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private holidayService: HolidayService,
    private alertService: AlertService,
    private initAdmin: InitAdminService,
    private loading: MessageService
  ) { }
 
  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl = '/audit/holidaylist';
    
    this.activatedRoute.params.subscribe(params => {
      this.holidayId = params['id'];
      if (this.holidayId > 0) {
        this.editHoliday = true;
        this.loadHolidayById(this.holidayId);
      } else {
        this.editHoliday = false;
        // Initialize the register form
        this.onInitForm();
      }
    });

    this.loading.hideLoading();
  }

  onInitForm() {
    this.validHoliday = true;
    this.holidayForm = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.required]],
      date: new FormControl(this.datePipe.transform(Date.now(),"yyyy-MM-dd")),
      optionalHoliday: [false],
      createdOn: [''],
      updatedOn: ['']
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.holidayForm.controls;
  }

  onSetOptionalHoliday(input: any): void {
    this.flagCustomPrice = input.currentTarget.checked;
  }

  onSubmit() {
    this.loading.showLoading();
    this.submitted = true;
    this.validHoliday = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.holidayForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validHoliday = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.holiday = new Holiday(this.holidayForm.value);
    
    this.holidayService.save(this.holiday, this.editHoliday)
    .pipe(first())
    .subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validHoliday = true;
        this.router.navigate([this.returnUrl]);
      } else {
        this.validHoliday = false;
        this.errorMessage = response.message;
        if (response.data && response.data.errors && response.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= response.data.errors.length; i++) {
            this.listErrorMessage.push(response.data.errors[i].Description);
          }
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (error: any) => {
      this.validHoliday = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = error;
    });
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  loadHolidayById(id: any) {
    this.loading.showLoading();
    this.holidayService.getById(id).pipe(first())
    .subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validHoliday = true;
        this.setFormByEntity(response.data);
      } else {
        this.validHoliday = true;
      }
      this.loading.hideLoading();
      }, error => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
      }
    );
  }

  setFormByEntity(data: any) {
    this.holidayForm = this.formBuilder.group({
      id: new FormControl(data.id),
      name: new FormControl(data.name),
      optionalHoliday: new FormControl(data.optionalHoliday),
      date: new FormControl(this.datePipe.transform(data.date,"yyyy-MM-dd")),
      createdOn: new FormControl(this.datePipe.transform(data.createdOn,"yyyy-MM-dd")),
      updatedOn: new FormControl(this.datePipe.transform(data.updatedOn,"yyyy-MM-dd")),
    });
  }
}
