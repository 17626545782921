export class TermOfUse {
    sendByMail: boolean;
    onlyPrint: boolean;
    studentId: number;

    constructor(contentResponse: any) {
        this.studentId = parseInt(contentResponse.studentId);
        this.sendByMail = contentResponse.sendByMail;
        this.onlyPrint = contentResponse.onlyPrint;
    }
}
