import { ItemEpisode } from './itemEpisode';
export class ItemSerie {
    item: string;
    name: string;
    description: string;
    folderName: string;
    order: Number;
    thumbnail: string;
    episodes: Array<ItemEpisode>;
}
