import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Register, ResponseResult } from '@app/auth/model';
import { Observable } from 'rxjs';
import { Forget, User } from '@app/auth/model';
import { catchError, map } from 'rxjs/operators';
import { ModuleType } from '@app/shared/enum/ModuleType';
import { environment } from '@environments/environment';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private route: string = "auth";
  private controller: string = "user";
  private endpointPrefix: string;

  constructor(private http: HttpClient,
    private accountService: AccountService) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }
  
  getall(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getById(id: string): any {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getById`, {params: params});
  }

  remove(id) {
    var call = this.http.delete<ResponseResult>(`${this.endpointPrefix}/${id}`);

    return call.pipe(map((response: any) => {
        return response;
    }), catchError(error => {
      if (error.status == 400) {
        if (error.errors) {
          return Observable.of(error.errors);
        }
      }
      return error;
    }));
  }

  save(user: User, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/update`, user);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/add`, user);
    }
  }

  saveByRegister(register: Register, editing: boolean = false): any {
    if (editing) { //update
      var call = this.http.put<ResponseResult>(`${this.endpointPrefix}/UpdateByRegister`, register);

      return call.pipe(map((response: any) => {
        return response;
      }), catchError(error => {
        if (error.status == 400) {
          if (error.errors) {
            return Observable.of(error.errors);
          }
          if (error.error) {
            return Observable.of(error.error);
          }
        }
        return error;
      }));
    } else { //new
      return this.accountService.register(register);
    }
  }

  getallwithoutme(): any {
    var userid = 0, username = '';
    if (this.accountService.accessIsValid()) {
      const currentUserData = this.accountService.getUserData();
        userid = currentUserData.id;
        username = currentUserData.username;

      let params = new HttpParams();
      params = params.append('userid', userid.toString());
      params = params.append('username', username);
      return this.http.get<ResponseResult>(`${this.endpointPrefix}/getwithoutme`, {params: params});
    }
  }

  addUserRole(userId: string, roleName: string): any {
    if (userId && roleName) {
      var addUserRole = {
        userId: userId,
        roleName: roleName
      };
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/addUserRole`, addUserRole);
    }
  }

  addUserRoles(roleNames, userid): any {
    if (roleNames && userid && roleNames.length > 0) {
      let userRoles: any = [];
      roleNames.forEach((role: any) => {
        userRoles.push({
          userId: userid,
          roleName: role
        });
      });
      let jsonBody = userRoles;
      let params = new HttpParams();
      params = params.append('userid', userid);

      var call = this.http.post<ResponseResult>(`${this.endpointPrefix}/addUserRoles`, jsonBody, {params: params});
      return call.pipe(map((response: any) => {
        return response;
      }), catchError(error => {
        if (error.status == 400) {
          if (error.errors) {
            return Observable.of(error.errors);
          }
        }
        return error;
      }));
    }
  }

  addRoles(roles: any, userid: string): any {
    if (roles && userid) {
      let jsonBody = roles;

      let params = new HttpParams();
      params = params.append('userid', userid);

      return this.http.post<ResponseResult>(`${this.endpointPrefix}/addRole`, jsonBody, {params: params});
    }
  }

  userRoles(userid: string): any {
    let params = new HttpParams();
    params = params.append('userid', userid);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/userRoles`, {params: params});
  }

  checkUserHaveRole(userid: string, rolename: string): any {
    let params = new HttpParams();
    params = params.append('userid', userid);
    params = params.append('rolename', rolename);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/checkUserHaveRole`, {params: params});
  }

  resetPasswordByForget(forget: Forget) {
    if (forget) {
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/resetPasswordByForget`, forget);
    }
  }

  getAllNotHaveAny(moduleType: ModuleType, valueId: string, onlyValues: boolean = false): any {
    let params = new HttpParams();
    params = params.append('moduleType', moduleType.toString());
    params = params.append('valueId', valueId);
    params = params.append('onlyValues', onlyValues.toString());
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getAllNotHaveAny`, {params: params});
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return passwordKey === passwordConfirmationKey;
  }

  lockUser(userId: any) {
    if (userId) {
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/lockUser/${userId}`, null);
    }
  }

  /*
    To check a password between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase lette
  */
  public validNewPwd(password: string) {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    return password.match(passw) ? ''
      : 'A senha deve ter entre 8 a 20 caracteres que contenham pelo menos um dígito numérico, uma letra maiúscula e uma letra minúscula.';
    // if (password.match(passw)) 
    // {
    //   //return new Validation(true, 'Senha válida.')
    // } else { 
    //   //return new Validation(false, 'A senha deve ter entre 6 a 20 caracteres que contenham pelo menos um dígito numérico, uma letra maiúscula e uma letra minúscula.')
    // }
  }
}
