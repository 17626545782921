<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/student/home']">Home</a>
    </li>
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/student/course']">Biblioteca</a>
    </li>
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" (click)="callBackList(itemSerie);">Episódios</a>
    </li>
    <li class="breadcrumb-item active">Material</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<hr>
<div class="row">
    <div class="col-12">
        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a class="nav-item nav-link active" id="nav-episode-tab" data-toggle="tab" href="#nav-episode" role="tab" aria-controls="nav-episode" aria-selected="true">Episódio</a>
                <a class="nav-item nav-link" id="nav-pdf-tab" data-toggle="tab" href="#nav-pdf" role="tab" aria-controls="nav-pdf" aria-selected="false">Assunto Pertinente</a>
                <a class="nav-item nav-link disabled" id="nav-other-tab" data-toggle="tab" href="#nav-other" role="tab" aria-controls="nav-other" aria-selected="false">Outros</a>
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-episode" role="tabpanel" aria-labelledby="nav-episode-tab">
                <div class="row">
                    <div class="col-12">
                        <!-- post picture -->
                        <div class="card mb-g">
                            <div class="card-body pb-0 px-4">
                                <div class="row hidden-xs-down">
                                    <div class="col-4">
                                        <div class="panel-tag">
                                            SÉRIE: <strong class="text-primary fw-700">{{serieName}}</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="panel-tag">
                                            EPISÓDIO: <strong class="text-primary fw-700">{{episodeName}}</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="panel-tag">
                                            <strong class="text-primary fw-700">CLASS: {{itemIndex}}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="row hidden-sm-up">
                                    <div class="col-12">
                                        <div class="panel-tag">
                                            SÉRIE: <strong class="text-primary fw-700">{{serieName}}</strong>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="panel-tag">
                                            EPISÓDIO: <strong class="text-primary fw-700">{{episodeName}}</strong>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="panel-tag">
                                            <strong class="text-primary fw-700">CLASS: {{itemIndex}}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="embed-responsive embed-responsive-16by9">
                                    <video controls="controls" crossorigin="anonymous">
                                        <source [src]="video" type="video/mp4" />
                                        <!--Suportado em IE9, Chrome 6 e Safari 5 -->
                                        O seu navegador não suporta a tag vídeo
                                        <track label="English" kind="subtitles" srclang="en" [src]="subtitleEn" default>
                                        <track label="Português" kind="subtitles" srclang="pt" [src]="subtitlePt">
                                    </video>
                                </div>
                                <!-- <div class="pb-3 pt-2 border-top-0 border-left-0 border-right-0 text-muted">
                                    <video #myvid id="my-video" class="video-js vjs-big-play-centered mini-play" [poster]="poster">
                                        <source src="{{ video }}" type="{{videoType}}">
                                        <track label="English" kind="subtitles" srclang="en" [src]="subtitleEn" default>
                                        <track label="Portugues" kind="subtitles" srclang="pt" [src]="subtitlePt">
                                    </video>
                                </div> -->
                            </div>
                        </div>
                        <!-- post picture - end -->
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="nav-pdf" role="tabpanel" aria-labelledby="nav-pdf-tab" #pdfViewer>
                <div class="row">
                    <div class="col-12">
                        <br>
                        <pdf-viewer 
                            [src]="(pdfSrcAsync$ | async)"
                            [rotation]="0"
                            [original-size]="false"
                            [show-all]="true"
                            [fit-to-page]="false"
                            [zoom]="1"
                            [zoom-scale]="'page-width'"
                            [stick-to-page]="false"
                            [render-text]="true"
                            [external-link-target]="'blank'"
                            [autoresize]="true"
                            [show-borders]="false"
                            style="width: 100%; height: 100vh;">
                        </pdf-viewer>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="nav-conothertact" role="tabpanel" aria-labelledby="nav-other-tab">...</div>
        </div>
    </div>
</div>
