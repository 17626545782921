<div class="page-wrapper">
    <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
            <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                <div class="d-flex align-items-center container p-0">
                    <header-logo></header-logo>
                    <span class="text-white opacity-50 ml-auto mr-2 hidden-sm-down">
                        Já possui uma conta?
                    </span>
                    <a href="#/auth/login" class="btn btn-sm btn-outline-primary text-white ml-sm-0">
                        Entrar
                    </a>
                </div>
            </div>
            <div class="flex-1" style="background: url(/assets/img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
                <div class="container py-4 py-lg-5 px-4 px-sm-0 mb-5">
                    <div class="row">
                        <div class="col-xl-12">
                            <h2 class="fs-xxl fw-500 mt-4 text-white text-center">
                                Preencha os dados e comece a Falar Inglês!
                                <small class="h3 fw-300 mt-3 mb-5 text-white opacity-60 hidden-sm-down">
                                    Faça uma Aula Experimental gratuita e ganhe um e-book da English Coffee.
                                </small>
                            </h2>
                        </div>
                        <div class="col-xl-6 ml-auto mr-auto">
                            <div class="card p-4 rounded-plus bg-faded">
                                <!-- <form id="js-login" novalidate="" action="#/auth/login"> -->
                                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group">
                                        <label class="form-label" for="name">Informe o seu nome</label>
                                        <input type="text" id="name" formControlName="name" class="form-control" placeholder="Nome" [ngClass]="{ 'is-invalid': !validRegister && f.name.errors }" required>
                                        <div *ngIf="!validRegister && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">Por favor, informe o seu nome.</div>
                                        </div>
                                        <div class="help-block">Informe o nome completo do usuário</div>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label class="form-label" for="email">O e-mail é necessário para verificação e recuperação de conta</label>
                                        <input type="email" id="email" formControlName="email" class="form-control" placeholder="E-mail" [ngClass]="{ 'is-invalid': !validRegister && f.email.errors }" required>
                                        <div *ngIf="!validRegister && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Por favor, informe o seu e-mail.</div>
                                        </div>
                                        <div class="help-block">Seu e-mail também será o seu usuário</div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="phone">Verifique seu número de telefone</label>
                                        <input type="tel" id="phone" name="phone" formControlName="phone" class="form-control" maxlength="17" [textMask]="{mask: phoneMask}"
                                        placeholder="Telefone" [ngClass]="{ 'is-invalid': !validRegister && f.phone.errors }" required>
                                        <div *ngIf="!validRegister && f.phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.phone.errors.required">Por favor, informe o seu número de telefone.</div>
                                        </div>
                                        <div class="help-block">Para sua segurança, precisamos ter a certeza se é realmente você. Enviaremos uma mensagem de texto com um código de verificação de seis dígitos. <i>São cobradas taxas padrão</i></div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="password">Senha: <br>Não reutilize sua senha bancária e outras já utilizadas.</label>
                                        <input type="password" id="password" formControlName="password" class="form-control" placeholder="Mínimo de 8 caracteres" 
                                        [ngClass]="{ 'is-invalid': !validRegister && f.password.errors }" maxlength="20" minlength="8" required>
                                        <div *ngIf="!validRegister && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Por favor, informe a sua senha.</div>
                                            <div *ngIf="f.password.errors.maxlength">A senha deve ter no máximo 20 caracteres.</div>
                                            <div *ngIf="f.password.errors.minlength">A senha deve ter no mínimo 8 caracteres.</div>
                                        </div>
                                        <div class="help-block">Sua senha deve ter de 8 a 20 caracteres, contendo letras e números e não deve conter espaços, caracteres especiais ou emojis.</div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="confirmpassword">Confirme sua senha: <br>Essa senha deve ser idêntica ao campo anterior.</label>
                                        <input type="password" id="confirmpassword" formControlName="confirmpassword" class="form-control" placeholder="Mínimo de 8 caracteres" 
                                        [ngClass]="{ 'is-invalid': !validRegister && f.confirmpassword.errors }" maxlength="20" minlength="8" required>
                                        <div *ngIf="!validRegister && f.confirmpassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.confirmpassword.errors.required">Por favor, informe a sua senha.</div>
                                            <div *ngIf="f.confirmpassword.errors.maxlength">A senha deve ter no máximo 20 caracteres.</div>
                                            <div *ngIf="f.confirmpassword.errors.minlength">A senha deve ter no mínimo 8 caracteres.</div>
                                            <div *ngIf="f.confirmpassword.errors.notEquivalent">A confirmação de senha não confere.</div>
                                        </div>
                                        <div class="help-block">Sua senha deve ter de 8 a 20 caracteres, contendo letras e números e não deve conter espaços, caracteres especiais ou emojis.</div>
                                    </div>
                                    <div class="alert alert-primary text-dark" role="alert">
                                        Suas informações pessoais são particulares e seguras
                                    </div>
                                    <div class="form-group demo">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" id="terms" formControlName="terms" class="custom-control-input" [ngClass]="{ 'is-invalid': !validRegister && f.terms.errors }" required>
                                            <label class="custom-control-label" for="terms"> Aceito os termos e condições</label>
                                        </div>
                                        <div *ngIf="!validRegister && f.terms.errors" class="invalid-feedback">
                                            <div *ngIf="f.terms.errors.required">Você precisa <strong>aceitar os termos</strong> antes de prosseguir.</div>
                                        </div>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" id="newsletter" formControlName="newsletter" class="custom-control-input" [ngClass]="{ 'is-invalid': !validRegister && f.newsletter.errors }">
                                            <label class="custom-control-label" for="newsletter"> Inscreva-se em newsletters informativos<br>
                                                <small>
                                                    <em>Não se preocupe, não enviaremos tantos </em>
                                                </small><i class="fa fa-smile-o text-primary"></i>
                                            </label>
                                        </div>
                                        <div *ngIf="!validRegister && f.newsletter.errors" class="invalid-feedback">
                                            <div *ngIf="f.newsletter.errors.required">Você precisa <strong>aceitar os termos</strong> antes de prosseguir.</div>
                                        </div>
                                    </div>
                                    <div class="alert alert-warning alert-dismissible text-center fade show mt-3" role="alert" *ngIf="!validRegister">
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                        </button>
                                        {{errorMessage}}
                                        <div class="invalid-feedback" style="display: block;">
                                            <div *ngFor="let error of listErrorMessage">{{error}}</div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-md-4 ml-auto text-right">
                                            <button id="js-login-btn" class="btn btn-block btn-danger btn-lg mt-3" [disabled]="submitted">
                                                <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                                Confirmar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <footer-auth></footer-auth>
            </div>
        </div>
    </div>
</div>
