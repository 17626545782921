import { Base } from "@app/shared/model";
import { ContentSerie, EpisodeComment } from ".";

export class ContentEpisode extends Base {
    number: number;
    name: string;
    subtitlePt: string;
    subtitleEn: string;
    video: string;
    subject: string;

    contentSerieId: number;
    contentSerie: ContentSerie;

    comments: Array<EpisodeComment>;

    constructor(contentResponse: any) {
        super(contentResponse);
        
        this.number = parseInt(contentResponse.number);
        this.name = contentResponse.name;
        this.subtitlePt = contentResponse.subtitlePt;
        this.subtitleEn = contentResponse.subtitleEn;
        this.video = contentResponse.video;
        this.subject = contentResponse.subject;

        this.contentSerieId = parseInt(contentResponse.contentSerieId);
        if (contentResponse && contentResponse.contentSerieId > 0) {
            this.contentSerie = contentResponse.contentSerie;
        }

        if(contentResponse.comments && contentResponse.comments.lenght > 0) {
            this.comments = contentResponse.comments;
        }
    }
}
