<!-- BEGIN Page Wrapper -->
<div class="page-wrapper">
    <div class="page-inner" [ngClass]="{ 'bg-brand-gradient': _pageSettings.pageContentBrandGradient }">
        <!-- BEGIN Left Aside -->
        <aside class="page-sidebar" *ngIf="!_pageSettings.pageEmpty && _pageSettings.pageSidebar"></aside>
        <!-- END Left Aside -->
        <div class="page-content-wrapper" [ngClass]="{ 'bg-transparent' : _pageSettings.pageContentBgTransparent }" ng-controller="app-englishcoffee-ctrl" ng-init="firstFunction(this)">

            <!-- BEGIN Page Header -->
            <header class="page-header" role="banner" *ngIf="!_pageSettings.pageEmpty"></header>
            <!-- END Page Header -->
            
            <!-- BEGIN Page Content -->
            <!-- the #js-page-content id is needed for some plugins to initialize -->
            <div id="js-page-content" role="main" [ngClass]="{ 'page-content' : !_pageSettings.pageEmpty }">
                <ngx-loading [show]="loading | async" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true}"></ngx-loading>
                <router-outlet #routerOutlet
                    (activate)='onActivate($event)'
                    (deactivate)='onDeactivate($event)'
                    (attach)='onAttach($event)'
                    (detach)='onDetach($event)'></router-outlet>
            </div>
            <!-- BEGIN Page Content -->
            
            <!-- this overlay is activated only when mobile menu is triggered -->
            <div class="page-content-overlay" data-action="toggle" data-class="mobile-nav-on" *ngIf="!_pageSettings.pageEmpty"></div> <!-- END Page Content -->
            
            <!-- BEGIN Page Footer -->
            <footer class="page-footer" role="contentinfo" *ngIf="!_pageSettings.pageWithoutFooter && !_pageSettings.pageEmpty"></footer>
            <!-- END Page Footer -->
        </div>
    </div>
</div>
<!-- END Page Wrapper -->
