import { Base } from "./base";

export class Configuration extends Base {
    npsForm: string;
    
    constructor(contentResponse: any) {
        super(contentResponse);
        this.npsForm = contentResponse.npsForm;
    }
}
