<!-- **************************************-->
<!-- **************Main Page Wrapper**************-->
<!-- **************************************-->
<div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
    <div class="d-flex align-items-center container p-0">
        <header-logo></header-logo>
    </div>
</div>
<div class="flex-1" style="background: url(/assets/img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
    <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
        <div class="row">
            <div class="col col-md-6 col-lg-7 hidden-sm-down">
                <h2 class="fs-xxl mt-4 text-white">
                    Descubra qual é o <strong>segredo do método English Coffee</strong> que pode te levar ao sucesso
                    <small class="h3 fw-300 mt-3 mb-5 text-white opacity-60">
                        Inglês Online em Poucas Horas e sem Gramática
                    </small>
                    <small class="h3 fw-300 mt-3 mb-5 text-white opacity-60">
                        Aulas ao vivo, metodologia validada, encontros descontraídos onde você aprende imediatamente o inglês passando por todos os níveis básico, intermediário e avançado.
                    </small>
                </h2>
                <a href="http://englishcoffee.com.br" target="_blank" class="fs-lg fw-500 text-white opacity-70">Veja mais &gt;&gt;</a>
                <div class="d-sm-flex flex-column align-items-center justify-content-center d-md-block">
                    <div class="px-0 py-1 mt-5 text-white fs-nano opacity-50">
                        Acompanhe nossas redes sociais
                    </div>
                    <div class="d-flex flex-row opacity-70">
                        <a href="https://www.facebook.com/englishcoffeemethod/" class="mr-2 fs-xxl text-white" target="_blank">
                            <i class="fab fa-facebook-square"></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UC7kQDtgb3FoZYuXOhLq_9jQ?view_as=subscriber" class="mr-2 fs-xxl text-white" target="_blank">
                            <i class="fab fa-youtube"></i>
                        </a>
                        <a href="https://www.instagram.com/englishcoffeemethod/?hl=pt-br" class="mr-2 fs-xxl text-white" target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a href="https://www.linkedin.com/in/english-coffee-6b32ab160/" class="mr-2 fs-xxl text-white" target="_blank">
                            <i class="fab fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4 ml-auto">
                <h1 class="text-white fw-300 mb-3 d-sm-block d-md-none">
                    Secure login
                </h1>
                <div class="card p-4 rounded-plus bg-faded">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label class="form-label" for="username">E-mail</label>
                            <input type="email" id="username" name="username" formControlName="username" 
                                class="form-control form-control-lg" placeholder="e-mail" value="" required ngModel
                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Desculpe, você esqueceu de informar o e-mail.</div>
                            </div>
                            <!-- <div class="invalid-feedback">Desculpe, você esqueceu de informar o e-mail.</div>
                            <div class="help-block">Informe seu e-mail para acesso</div> -->
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="password">Senha</label>
                            <input type="password" id="password" name="password" formControlName="password" 
                                class="form-control form-control-lg" placeholder="senha" value="" required
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Desculpe, você esqueceu de informar a senha.</div>
                            </div>
                        </div>
                        <div class="form-group text-left">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" id="rememberme" formControlName="rememberme" class="custom-control-input" [ngClass]="{ 'is-invalid': submitted && f.rememberme.errors }">
                                <label class="custom-control-label" for="rememberme"> Mantenha-me conectado</label>
                            </div>
                        </div>
                        <div class="alert alert-warning alert-dismissible text-center fade show mt-3" role="alert" *ngIf="!validLogin">
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true"><i class="fal fa-times"></i></span>
                            </button>
                            <span [innerHTML]="errorMessage"></span>
                            <div *ngIf="listErrorMessage.length > 0" class="text">
                                <br>
                                <div class="small text-danger" *ngFor="let error of listErrorMessage">{{error}}</div>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col-lg-12 pl-lg-1 my-2">
                                <button id="js-login-btn" [disabled]="submitted" class="btn btn-info btn-block">
                                    <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                    Entrar
                                </button>
                                <a routerLink="../forget" class="btn btn-link btn-pills btn-xs btn-block waves-effect waves-themed">
                                    <span>Esqueceu a senha?</span>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <footer-auth></footer-auth>
    </div>
</div>
