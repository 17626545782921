<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/checkListlist']">Checklist</a>
    </li>
    <li class="breadcrumb-item active">Novo Checklist</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div id="panel-2" class="panel">
    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
            Novo <span class="fw-300"><i>Checklist</i></span>
        </h2>
        <div class="panel-toolbar">
            <a href="javascript:void(0);" class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse">
                <i class="fal fa-minus text-white fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen">
                <i class="fal fa-expand text-primary fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Close">
                <i class="fal fa-times text-white fw-900"></i>
            </a>
        </div>
    </div>
    <div class="panel-container show">
        <div class="panel-content p-0">
            <form [formGroup]="checkListForm" novalidate class="needs-validation" (ngSubmit)="onSubmit()">
                <div class="panel-content">
                    <div class="form-row" *ngIf="editMode">
                        <div class="col-md-4 mb-3">
                            <label>Id</label>
                            <br/>
                            <label class="form-control fw-700">{{f.id.value}}</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-4 mb-3">
                            <input type="hidden" id="id" formControlName="id" />
                            <input type="hidden" id="createdOn" formControlName="createdOn" *ngIf="!editMode"/>
                            <input type="hidden" id="updatedOn" formControlName="updatedOn" *ngIf="!editMode"/>
                            
                            <label class="form-label" for="module">Módulo <span class="text-danger">*</span> </label>
                            <select class="form-control" id="module" for="module" formControlName="module" placeholder="Módulo" [(ngModel)]="moduleTypeValue"
                                [ngClass]="{ 'is-invalid': !validEntity && f.module.errors }">
                                <option *ngFor="let item of moduleTypeList" [value]="item.value" [selected]="moduleTypeValue == item.value">
                                    {{ item.name }}
                                </option>
                            </select>
                            <div *ngIf="!validEntity && f.module.errors" class="invalid-feedback">
                                <div *ngIf="f.module.errors.required">Por favor, informe o módulo.</div>
                            </div>
                        </div>
                        <div class="col-md-8 mb-3">
                            <label class="form-label" for="name">Nome do Checklist <span class="text-danger">*</span> </label>
                            <input type="text" id="name" formControlName="name" class="form-control" placeholder="Nome do checklist" maxlength="100" [ngClass]="{ 'is-invalid': !validEntity && f.name.errors }" required>
                            <div *ngIf="!validEntity && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Por favor, informe o nome do checklist.</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-12 mt-3">
                            <div id="panel-3" class="panel">
                                <div class="panel-hdr">
                                    <h2>
                                        <span class="fw-300"><i>Itens do Check-List</i></span>
                                    </h2>
                                    <div class="panel-toolbar ml-2">
                                        <button type="button" class="btn btn-success btn-xs" data-toggle="tooltip" title="Novo Item" (click)="newItemCheckList($event, mdItemCheckList)">Novo Item</button>
                                    </div>
                                </div>
                                <div class="panel-container show">
                                    <div class="panel-content">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="col-sm-12" *ngIf="!showItemsTable">
                                                    <div class="alert alert-warning m-2" role="alert">
                                                        Não existem itens cadastrados neste Check-list.
                                                    </div>
                                                </div>
                                                <div class="col-sm-12" *ngIf="showItemsTable">
                                                    <managed-table [dataTitle]="" [rows]="checkLists" [columns]="itemsColumn" [hasActions]="true" [ngTableFiltering]="true" [showFilterRow]="false"
                                                        [previewEnable]="false" [editEnable]="true" [deleteEnable]="true" (editClick)="editItem($event)" (deleteClick)="deleteItem($event)" [hasFilter]="true">
                                                    </managed-table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <ng-template #mdItemCheckList let-c="close" let-d="dismiss">
                                <div class="modal-header">
                                    <h5 class="modal-title">Item do Check-List</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
                                        <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <hr>
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <label class="form-label" for="itemCheckList.itemOrder">Ordem do item <span class="text-danger">*</span> </label>
                                            <input type="text" id="itemOrder" [(ngModel)]="itemCheckList.itemOrder" class="form-control"
                                                placeholder="Ordem do item" maxlength="5" [ngClass]="{ 'is-invalid': !validItemCheckList }" required>
                                        </div>
                                        <div class="col-12">
                                            <label class="form-label" for="itemCheckList.itemDescription">Nome do banco <span class="text-danger">*</span> </label>
                                            <input type="text" id="itemDescription" [(ngModel)]="itemCheckList.itemDescription" class="form-control"
                                                placeholder="Descrição do item" maxlength="100" [ngClass]="{ 'is-invalid': !validItemCheckList }" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Cancelar</button>
                                    <button type="button" class="btn btn-primary waves-effect waves-themed" (click)="saveItem()">Salvar</button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validEntity">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true"><i class="fal fa-times"></i></span>
                        </button>
                        {{errorMessage}}
                    </div>
                    <div *ngIf="listErrorMessage && !validEntity" class="invalid-feedback">
                        <div *ngFor="let error of listErrorMessage">{{error}}</div>
                    </div>
                    <hr>
                    <div class="row no-gutters">
                        <div class="col-md-12 ml-auto text-right">
                            <div class="btn-group btn-panel">
                                <button id="js-login-btn" class="btn btn-primary" [disabled]="submitted">
                                    <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                    Salvar
                                </button>
                                <button id="js-cancel-btn" class="btn btn-default" (click)="onCancel()">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
