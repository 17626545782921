import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CourseService, TeacherService } from '@app/admin/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusClass, StatusClassCss, StatusClassCssContrast, StatusClassLabel } from '@app/shared/enum';
import { StudentClassesCourseConfirmedClassesService } from '@app/reports/services';
import { StudentClassesCourseConfirmed, StudentClassesCourseConfirmedFilter, StudentClassesCourseConfirmedGroup } from '@app/reports/model';
//import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { StatusClassParamsLabel } from '@app/shared/enum/StatusClassParams';
import { ReportFormat } from '@app/shared/enum/ReportFormat';

@Component({
  selector: 'studentclassescourse-confirmed-classes-analytic',
  templateUrl: './studentclassescourse-confirmed-classes-analytic.component.html',
  providers: [DatePipe] })
export class StudentclassescourseConfirmedClassesAnalyticComponent implements OnInit {
  closeResult: string;
  public confirmedGroup: StudentClassesCourseConfirmedGroup;
  public confirmedClassesData: StudentClassesCourseConfirmed;

  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  public auxIcon = "fa fa-users";
  public auxTitle = "Alunos";

  public auxIcon2 = "fas fa-user-plus";
  public auxTitle2 = "Incluir Aluno";

  public courseDataList: Array<any>;
  public flagCourseSelected: boolean = false;
  public flagSearchCourse = false;
  public courseList: any = [];
  public courseId: any;
  public courseData: any;
  public reportFormat = ReportFormat.Analytic;
  
  public teacherDataList: Array<any>;
  public flagTeacherSelected: boolean = false;
  public flagSearchTeacher = false;
  public teacherList: any = [];
  public teacherId: any;
  public teacherData: any;

  public statusClassList: any = [];

  public flagFiltering: boolean = false;

  public startDate: any;
  public endDate: any;
  
  public options: Options;

  public reportFilter: StudentClassesCourseConfirmedFilter;

  public columns: Array<any> = [
    { 
      title: "Turma", 
      name: "course.name", 
      sort: "asc",
      isHtmlData: true,
      className: "text-left"
    },
    { 
      title: "Aluno", 
      name: "student.name", 
      sort: "asc",
      isHtmlData: true,
      className: "text-left"
    },
    { 
      title: "Nº da <strong>Aula</strong>",
      name: "studentClassesCourseConfirmed.numberClass", 
      sort: "asc",
      className: "text-center text-info"
    },
    {
      title: "Data/Hora Aula",
      name: "studentClassesCourseConfirmed.expectedDate",
      sort: "asc",
      className: "text-center text-primary datetime"
    },
    {
      title: "Tempo<br>da Aula",
      name: "designTotalTime",
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-center text-primary fw-700"
    },
    {
      title: "Confirmação<br>do Teacher",
      name: "studentClassesCourseConfirmed.confirmedDateByTeacher",
      sort: "asc",
      className: "text-center text-success font-weight-bold datetime"
    },
    {
      title: "Confirmação<br>do Aluno",
      name: "studentClassesCourseConfirmed.confirmedDateByStudent",
      sort: "asc",
      className: "text-center text-success font-weight-bold datetime"
    },
    {
      title: "Justificativa<br>da Confirmação",
      name: "designConfirmedClassByTeacherParamsInfo",
      sort: "asc",
      className: "text-center text-secondary"
    },
    {
      title: "Status<br>da Aula", 
      name: "designStatusClass", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-primary"
    }
  ];

  public columnsSynthetic: Array<any> = [
    { 
      title: "Turma", 
      name: "course.name", 
      sort: "asc",
      isHtmlData: true,
      className: "text-left"
    },
    { 
      title: "Aluno", 
      name: "student.name", 
      sort: "asc",
      isHtmlData: true,
      className: "text-left"
    },
    { 
      title: "Nº da <strong>Aula</strong>",
      name: "studentClassesCourseConfirmed.numberClass", 
      sort: "asc",
      className: "text-center text-info"
    },
    {
      title: "Data/Hora Aula",
      name: "studentClassesCourseConfirmed.expectedDate",
      sort: "asc",
      className: "text-center text-primary datetime"
    },
    {
      title: "Tempo<br>da Aula",
      name: "designTotalTime",
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-center text-primary fw-700"
    },
    {
      title: "Confirmação<br>do Teacher",
      name: "studentClassesCourseConfirmed.confirmedDateByTeacher",
      sort: "asc",
      className: "text-center text-success font-weight-bold datetime"
    },
    {
      title: "Confirmação<br>do Aluno",
      name: "studentClassesCourseConfirmed.confirmedDateByStudent",
      sort: "asc",
      className: "text-center text-success font-weight-bold datetime"
    },
    {
      title: "Justificativa<br>da Confirmação",
      name: "designConfirmedClassByTeacherParamsInfo",
      sort: "asc",
      className: "text-center text-secondary"
    },
    {
      title: "Status<br>da Aula", 
      name: "designStatusClass", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-primary"
    }
  ];
  
  constructor(
    private initAdmin: InitAdminService,
    private courseService: CourseService,
    private teacherService: TeacherService,
    private confirmedClassesService: StudentClassesCourseConfirmedClassesService,
    private modalService: NgbModal
  ) {
    this.courseDataList = new Array<any>();
    this.teacherDataList = new Array<any>();
    this.confirmedGroup = new StudentClassesCourseConfirmedGroup();
    this.reportFilter = new StudentClassesCourseConfirmedFilter(null);
    this.onLoadTeachers();
  }

  ngOnInit(): void {
    this.options = {
      width: '300'
    };
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.getConfirmedClasses(null);
    this.confirmedClassesData = new StudentClassesCourseConfirmed();
  }

  onLoadTeachers() {
    this.teacherService.all().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.teacherDataList = this.getTemplateTeacher(response.data);
        this.teacherId = 0;
      } else {
        this.teacherList = new Array<any>();
        this.teacherDataList = new Array<any>();
      }
      this.flagSearchTeacher = false;
    }, (error: any) => {
      this.flagSearchTeacher = false;
    });
  }

  getTemplateTeacher(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.teacherList = data;
      let arrayTeachers: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id,
          text: this.pad(data[i].id, 6) + " | " + data[i].name + " | " + data[i].email,
        };
        arrayTeachers.push(element);
      }
      return arrayTeachers;
    }
    return new Array<any>();
  }

  getTemplateCourse(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.courseList = data;
      let arrayCourses: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id,
          text: this.pad(data[i].id, 6) + " | " + data[i].name
        };
        arrayCourses.push(element);
      }
      return arrayCourses;
    }
    return new Array<any>();
  }

  getTimeStr(time: any) {
    time.toTimeString().split(' ')[0];
  }

  pad(num: any, size: any) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  onLoadCoursesByTeacherId(teacherId: any) {
    this.courseService.getByTeacherId(teacherId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.courseDataList = this.getTemplateCourse(response.data);
        this.courseId = 0;
      } else {
        this.courseDataList = new Array<any>();
      }
      this.flagSearchCourse = false;
    }, (error: any) => {
      this.flagSearchCourse = false;
    });
  }

  getConfirmedClasses(status: any) {
    this.showTable = false;
    this.confirmedGroup = new StudentClassesCourseConfirmedGroup();
    if (this.teacherId > 0) {
      this.flagFiltering = true;
      this.setFilterModel(status);
      this.confirmedClassesService.confirmedClassesAnalytic(this.reportFilter)
      .pipe(first()).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.confirmedGroup = response.data;
          this.designStatusClass(this.confirmedGroup.studentClassesCourseConfirmed);
          this.getStatusClassList(this.confirmedGroup.studentClassesCourseConfirmed);
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.flagFiltering = false;
      }, (error: any) => {
        this.showTable = false;
        this.flagFiltering = false;
        this.returnMessage = 'Erro ao carregar os dados do relatório.';
      });
    } else {
      this.flagFiltering = false;
      this.showTable = false;
      this.returnMessage = 'Selecione as opções do filtro para listar os dados.';
    }
  }

  formatTimeDiff(date1: Date, date2: Date) {
    return Array(3)
      .fill([3600, date1.getTime() - date2.getTime()])
      .map((v, i, a) => {
        a[i+1] = [a[i][0]/60, ((v[1] / (v[0] * 1000)) % 1) * (v[0] * 1000)];
        return `0${Math.floor(v[1] / (v[0] * 1000))}`.slice(-2);
      }).join(':');
  }

  designStatusClass(lista: any[]) {
    lista.forEach(function(e) {
      e.designTotalTime = '';
      if (e.statusClass >= 0) {
        let statusClassCss = StatusClassCss.get(e.statusClass);
        let statusClassStr = StatusClassLabel.get(e.statusClass)
        e.designStatusClass = "<span class='badge badge-pill w-100 font-weight-bold "
          + statusClassCss +"'>"
          + statusClassStr +"</span>";
      }

      if (e.studentClassesCourseConfirmed.confirmedClassByTeacherStatusClassParams != null) {
        e.designConfirmedClassByTeacherParamsInfo = "<span class='badge badge-pill badge-primary w-100 font-weight-bold'>"
        + StatusClassParamsLabel.get(e.studentClassesCourseConfirmed.confirmedClassByTeacherStatusClassParams) +"</span>";
      } else {
        e.designConfirmedClassByTeacherParamsInfo = "<span class='badge badge-pill badge-secondary w-100 font-weight-bold'> - </span>";
      }

      if (e.studentClassesCourseConfirmed.timeStart) {
        let timeStart = e.studentClassesCourseConfirmed.timeStart;
        let timeEnd = e.studentClassesCourseConfirmed.timeEnd;
        if (timeStart != null && timeStart.length > 0 
          && timeEnd != null && timeEnd.length > 0) {
          let dtIni = new Date();
          dtIni.setMilliseconds(0);
          dtIni.setSeconds(0);
          dtIni.setMinutes(timeStart.split(':')[1]);
          dtIni.setHours(timeStart.split(':')[0]);
          let dtFim = new Date();
          dtFim.setMilliseconds(0);
          dtFim.setSeconds(1);
          dtFim.setMinutes(timeEnd.split(':')[1]);
          dtFim.setHours(timeEnd.split(':')[0]);

          if (timeEnd === '00:00') {
            dtFim.setDate(dtFim.getDate() + 1);
          }
          
          var utcDiff = Array(3)
            .fill([3600, dtFim.getTime() - dtIni.getTime()])
            .map((v, i, a) => {
              a[i+1] = [a[i][0]/60, ((v[1] / (v[0] * 1000)) % 1) * (v[0] * 1000)];
              return `0${Math.floor(v[1] / (v[0] * 1000))}`.slice(-2);
            }).join(':');

          //let utcDiff = this.formatTimeDiff(dtFim, dtIni);
          if (dtFim <= dtIni || utcDiff === '00:00:00' || utcDiff === '00:00:01') {
            e.designTotalTime = "<span class='badge badge-pill badge-secondary w-100'> - </span>";
          } else {
            e.designTotalTime += "<span class='badge border border-primary text-primary'><i class='fas fa-solid fa-clock'></i> "
            + utcDiff.split(':')[0] + ':' + utcDiff.split(':')[1] + "</span>";
          }
        } else {
          e.designTotalTime = "<span class='badge badge-pill badge-secondary w-100'> - </span>";
        }
      }
    });
    this.confirmedGroup.studentClassesCourseConfirmed = lista;
  }

  private setFilterModel(status: any) {
    this.reportFilter = new StudentClassesCourseConfirmedFilter(null);
    if (this.teacherId) {
      this.reportFilter.teacherId = this.teacherId;
    }
    if (this.courseId) {
      this.reportFilter.courseId = this.courseId;
    }
    if (this.startDate) {
      this.reportFilter.startDate = this.startDate;
    }
    if (this.endDate) {
      this.reportFilter.endDate = this.endDate;
    }
    if (status >= 0) {
      this.reportFilter.statusClass = Number.parseInt(status);
    }
  }

  setStartDate(event: any) {
    var value = event.target.value;
    if (value) {
      var year = value.split('-')[0];
      var month = value.split('-')[1];
      var day = value.split('-')[2];
      this.startDate = new Date(year, month - 1, day, 0, 0, 0, 0);
    }
  }

  setEndDate(event: any) {
    var value = event.target.value;
    if (value) {
      var year = value.split('-')[0];
      var month = value.split('-')[1];
      var day = value.split('-')[2];
      this.endDate = new Date(year, month - 1, day, 0, 0, 0, 0);
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();
  
      console.log(reason);
  
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }

  showInfoConfirmedClasses(row: any, content: any) {
    if (row) {
      this.confirmedClassesData = row;
      this.modalService.dismissAll();
      this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  setTeacher(value: any) {
    if (value) {
      this.teacherId = value;
      this.onLoadCoursesByTeacherId(value);
    }
  }

  setCourse(value: any) {
    if (value) {
      this.courseId = value;
    }
  }

  getStatusClassStr(statusClass: any) {
    return StatusClassLabel.get(statusClass);
  }

  getStatusClassCss(statusClass: any) {
    return StatusClassCss.get(statusClass);
  }
  
  getStatusClassCssContrast(statusClass: any) {
    return StatusClassCssContrast.get(statusClass);
  }

  getStatusClassList(classes: Array<StudentClassesCourseConfirmed>) {
    this.statusClassList = new Array<any>();
    for (var value in StatusClass) {
      if (Number.isInteger(Number.parseInt(value))
        && ((!(this.reportFilter.statusClass >= 0)) || this.reportFilter.statusClass == Number.parseInt(value))) {
        let count = 0;
        if (classes) {
          count = classes.filter(x => x.statusClass == Number.parseInt(value)).length;
        }
        this.statusClassList.push({
          count,
          value: Number.parseInt(value), 
          label: StatusClassLabel.get(Number.parseInt(value)),
          css: StatusClassCss.get(Number.parseInt(value)),
          contrast: StatusClassCssContrast.get(Number.parseInt(value))
        });
      }
    }
  }
}
