import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { default as swal } from 'sweetalert2';
import { ContactBySiteService } from '@app/marketing/services/contactBySite.service';
import { TypeResponse } from '@app/shared/enum';
import { AlertService, MessageService } from '@app/shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import {
  StatusCrm,
  StatusCrmCss,
  StatusCrmLabel,
} from '@app/shared/enum/StatusCrm';
import { first } from 'rxjs/operators';
import {
  ContactBySite,
  Course,
  StudentCoursePreReg,
  Teacher,
} from '@app/admin/model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CourseService,
  InitAdminService,
  TeacherService,
} from '@app/admin/services';
import { formatDate } from '@angular/common';
import moment from 'moment';

@Component({
  selector: 'contact-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.css'],
})
export class ChangeStatusComponent implements OnInit {
  @Input('contactId') contactId: any;

  public date: any;

  changeStatusSuccess: any;
  public errorMessage: string = '';
  public submitted: boolean = false;

  public showExperimentalDate: boolean = false;
  public needPreReg: boolean = false;
  public setPreRegForm: boolean = false;
  public studentCoursePreReg: StudentCoursePreReg;
  public validStudentCoursePreReg: boolean = true;
  public errorMessagePreReg: string = '';

  public flagSearchContact = false;
  public validContact: boolean = true;
  public options: Options = {
    width: '300',
  };
  public errors: any = Array<string>();

  public contactData: ContactBySite;
  public statusCrmDataList: Array<any>;
  public statusCrmList: any = [];

  public courseId: number = 0;
  public course: Course;
  public courseList: any = [];
  public validCourse: boolean = true;
  public validCourseListMessage: any[];
  public flagCourseSelected: boolean = false;
  private reviewCourseUrl: string;
  private contactBySiteListUrl: string;

  public newStatusCrm: any;
  public newStatusCrmId: number = 0;
  public newStatusCrmStr: string;
  public newStatusCrmCss: string;
  public comments: string = '';

  public dueDay: number;
  public installmentFirstDue: any;
  public startDate: any;
  public endDate: any;

  public experimentalDateValue: any; // = '2023-03-29';
  public experimentalTimeValue: any; // = '18:20';
  public experimentalClass = new Date();
  public experimentalClassTeacherId: number;
  public teacherInitialValue: any;

  public validTeacher: boolean;
  public teacherList: any = [];
  public teacherDataList: Array<any>;
  public selectTeacherList: any[];
  public flagTeacherSelected: boolean = false;
  public teacher: Teacher;
  public selectedTeacher: any;

  constructor(
    private contactBySiteService: ContactBySiteService,
    private activatedRoute: ActivatedRoute,
    private teacherService: TeacherService,
    private courseService: CourseService,
    private initAdmin: InitAdminService,
    private alertService: AlertService,
    private loading: MessageService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.reviewCourseUrl = '/admin/course';
    this.contactBySiteListUrl = '/marketing/contactbysitelist';
    this.studentCoursePreReg = new StudentCoursePreReg(null);
    this.installmentFirstDue = new Date(
      formatDate(new Date(), 'yyyy-MM-dd', 'en-US')
    );
    this.startDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
    this.endDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
    this.dueDay = 1;
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();

    this.getStatusList();
    this.onLoadTeachers();

    this.activatedRoute.params.subscribe((params) => {
      this.contactId = params['id'];
      //this.previewMode = params['preview'] == 'true';

      if (this.contactId != null && this.contactId > 0) {
        this.getActualContactData(this.contactId);
      } else {
        this.alertService.showWarning(
          'Não foi possível carregar os dados do contato!',
          'Dados do contato'
        );
        this.router.navigate([this.contactBySiteListUrl]);
      }
      this.loading.hideLoading();
    });
  }

  onLoadTeachers() {
    this.teacherService.all().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.teacherList = response.data;
        this.teacherDataList = this.getTemplateTeacherList(this.teacherList);
        //this.getInputTeacherList(this.teacherList);
      } else {
        this.teacherList = new Array<Teacher>();
      }
    });
  }

  getTemplateTeacherList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.teacherList = data;
      let arrayTeacherList: Array<any> = new Array<any>();
      let element: any = {
        id: '0',
        name: 'Selecione um(a) Professor(a)',
      };
      arrayTeacherList.push(element);

      for (let i = 0; i < data.length; i++) {
        element = {
          id: data[i].id,
          name: data[i].id + ' | ' + data[i].name,
        };
        arrayTeacherList.push(element);
      }
      return arrayTeacherList;
    }
    return new Array<any>();
  }

  onTeacherChange(teacherId: number) {
    this.flagTeacherSelected = false;
    if (teacherId > 0) {
      this.teacherService.get(teacherId).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.experimentalClassTeacherId = teacherId;
          this.teacher = response.data;
          this.flagTeacherSelected = true;
        } else {
          this.teacher = new Teacher(null);
        }
      });
    }
  }

  getStatusList() {
    const statusList: Array<object> = [];
    for (var value in StatusCrm) {
      if (Number.isInteger(Number.parseInt(value))) {
        statusList.push({
          value: Number.parseInt(value),
          text: StatusCrmLabel.get(Number.parseInt(value)),
        });
      }
    }
    this.statusCrmDataList = this.getTemplateUserList(statusList);
  }

  getActualContactData(id: Number) {
    this.loading.showLoading();
    this.flagSearchContact = true;
    this.contactBySiteService.getById(id.toString()).subscribe(
      (response: { typeResponse: TypeResponse; data: any }) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.contactData = new ContactBySite(response.data);
          this.setNewStatusCrm(this.contactData.statusCrm);
          this.newStatusCrmId = this.contactData.statusCrm;

          if (this.contactData.experimentalClass) {
            this.experimentalDateValue = formatDate(
              this.contactData.experimentalClass,
              'yyyy-MM-dd',
              'en-US'
            );
            this.experimentalTimeValue = formatDate(
              this.contactData.experimentalClass,
              'HH:mm:ss',
              'en-US'
            );
            this.date = formatDate(
              this.contactData.experimentalClass,
              'yyyy-MM-dd',
              'en-US'
            );
            if (response.data.experimentalClassTeacherId) {
              this.selectedTeacher = String(
                response.data.experimentalClassTeacherId
              );
              this.teacherInitialValue = this.teacherDataList.find(
                (x) => x.id == response.data.experimentalClassTeacherId
              );
            }
          }
        } else {
          this.contactData = this.contactData;
        }
        this.loading.hideLoading();
        this.flagSearchContact = false;
      },
      (error: any) => {
        this.loading.hideLoading();
        this.flagSearchContact = false;
      }
    );
  }

  setNewStatusCrm(value: any) {
    this.newStatusCrmId = Number.parseInt(value);
    this.newStatusCrm = this.statusCrmList.find(
      (x: { value: number }) => x.value == this.newStatusCrmId
    );
    this.newStatusCrmStr = StatusCrmLabel.get(this.newStatusCrmId);
    this.newStatusCrmCss = StatusCrmCss.get(this.newStatusCrmId);
    this.showExperimentalDate = this.checkShowExpDateByNewStatus(
      this.newStatusCrmId
    );

    if (this.showExperimentalDate) {
      this.onLoadTeachers();
    }

    if (this.checkPreRegByNewStatus(this.newStatusCrmId)) {
      this.needPreReg = true;
      this.onLoadCourses();
    }
  }

  checkPreRegByNewStatus(newStatus: any): boolean {
    return newStatus == StatusCrm.DoneBusiness;
  }

  checkShowExpDateByNewStatus(newStatus: any): boolean {
    return newStatus == StatusCrm.ScheduledTrial;
  }

  onSetPreRegForm(input: any): void {
    this.setPreRegForm = input.currentTarget.checked;
  }

  onCourseChange(value: any) {
    if (value && parseInt(value) > 0) {
      this.courseService.getById(value).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.courseId = parseInt(value);
          this.course = response.data;
          this.flagCourseSelected = true;
        } else {
          this.course = new Course(null);
          this.flagCourseSelected = false;
        }
      });
    }
  }

  onLoadCourses() {
    this.validCourse = true;
    this.validCourseListMessage = new Array<string>();
    this.courseService.getall().subscribe((response) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.courseList = response.data;
        if (this.courseList.length > 0) {
          this.courseId = this.courseList[0].id;
        }
      } else {
        this.courseList = new Array<Course>();
      }
    });
  }

  onReviewCourse(): void {
    this.router.navigate([this.reviewCourseUrl, { id: this.courseId }]);
  }

  checkValidationDates() {
    if (this.endDate && this.startDate) {
      let endDate = new Date(this.endDate + 'GMT-0300');
      let startDate = new Date(this.startDate + 'GMT-0300');
      let checkValue = endDate.getTime() - startDate.getTime();

      if (checkValue > 0) {
        this.validStudentCoursePreReg = true;
        this.errorMessagePreReg = '';
      } else {
        this.validStudentCoursePreReg = false;
        this.errorMessagePreReg = 'As datas informadas são inválidas.';
      }
    } else {
      this.validStudentCoursePreReg = true;
      this.errorMessagePreReg = '';
    }
  }

  done() {
    this.loading.showLoading();
    this.submitted = true;
    this.validContact = true;

    if (this.newStatusCrmId && this.contactData != null) {
      let changeStatus = {
        id: this.contactData.id,
        comments: this.comments,
        newStatus: parseInt(this.newStatusCrmId.toString()),
        preRegVm: null,
        experimentalClass: null,
        experimentalClassTeacherId: null,
      };
      console.log(changeStatus);

      if (changeStatus.newStatus == StatusCrm.ScheduledTrial) {
        try {
          let dateStr = '';
          if (this.experimentalDateValue instanceof Date) {
            dateStr = formatDate(
              this.experimentalDateValue,
              'yyyy-MM-dd',
              'en-US'
            );
          } else {
            dateStr = this.experimentalDateValue;
          }

          let newDate = new Date(dateStr + ' 00:00:00 -03:00');
          newDate.setMinutes(this.experimentalTimeValue.split(':')[1]);
          newDate.setHours(this.experimentalTimeValue.split(':')[0]);
          let newDateStr = formatDate(newDate, 'yyyy-MM-dd HH:mm:ss', 'en-US');
          if (newDateStr) {
            changeStatus.experimentalClass = new Date(newDateStr);
          }
          if (this.experimentalClassTeacherId) {
            changeStatus.experimentalClassTeacherId =
              this.experimentalClassTeacherId;
          }
          console.log(newDateStr);
        } catch (ex: any) {
          console.log(ex);
        }
      }

      if (
        this.checkPreRegByNewStatus(this.newStatusCrmId) &&
        this.setPreRegForm
      ) {
        this.checkValidationDates();
        if (this.errorMessagePreReg) {
          this.loading.hideLoading();
          this.submitted = false;
          this.validContact = false;
          this.validStudentCoursePreReg = false;
          this.errorMessage = this.errorMessagePreReg;
          return;
        } else {
          this.studentCoursePreReg.startDate = new Date(
            this.startDate + 'GMT-0300'
          );
          this.studentCoursePreReg.endDate = new Date(
            this.endDate + 'GMT-0300'
          );
          this.studentCoursePreReg.installmentFirstDue = new Date(
            this.installmentFirstDue + 'GMT-0300'
          );
          this.studentCoursePreReg.dueDay = this.dueDay;
          this.studentCoursePreReg.courseId = this.courseId;
        }
        changeStatus.preRegVm = this.studentCoursePreReg;
      }
      this.contactBySiteService
        .changeStatus(changeStatus)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if (response.typeResponse == TypeResponse.Success) {
              this.finishChange(response.message);
            } else if (response.typeResponse == TypeResponse.Alert) {
              this.submitted = false;
              this.validContact = false;
              this.warningChange(response.message);
            } else {
              this.submitted = false;
              this.validContact = false;
              this.errorChange(response.message);
            }
          },
          (error: any) => {
            console.log(error);
            this.submitted = false;
            this.validContact = false;
            this.errorChange(error.message);
          }
        );
    } else {
      this.submitted = false;
      this.validContact = false;
      this.errorChange(null);
      return;
    }
  }

  errorChange(error: string) {
    if (error) {
      this.errorMessage = error;
    } else {
      this.errorMessage = 'Por favor, selecione um status válido.';
    }

    this.validContact = false;
    this.submitted = false;
    this.loading.hideLoading();
  }

  finishChange(text: string) {
    this.dismissModal();
    this.loading.hideLoading();
    swal({
      type: 'success',
      title: text,
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      return this.contactListUrl();
    });
  }

  warningChange(text: any) {
    if (text) {
      this.errorMessage = text;
    } else {
      this.errorMessage =
        'Por favor, verifique as informações antes de continuar.';
    }
    this.dismissModal();
    this.loading.hideLoading();
    swal({
      type: 'warning',
      title: 'Falha ao atualizar os dados!',
      text: text,
      showConfirmButton: true,
      //timer: 1500
    });
  }

  getTemplateUserList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.statusCrmList = data;
      let arrayUserList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].value,
          text: data[i].text,
        };
        arrayUserList.push(element);
      }
      this.setNewStatusCrm(0);

      return arrayUserList.sort(function (a: any, b: any) {
        var textA = a.text.toLowerCase();
        var textB = b.text.toLowerCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
    return new Array<any>();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  cancel() {
    return this.contactListUrl();
  }

  contactListUrl() {
    return this.router.navigate([this.contactBySiteListUrl]);
  }

  setStartDate(value: any) {
    //this.startDate = new Date(formatDate(value, 'yyyy-MM-dd', 'en-US'));
  }

  setValueTime(input: any) {
    this.experimentalTimeValue = input.value;
  }

  setValueDate(input: any) {
    this.experimentalDateValue = input.value;
  }

  updateDate(event: any) {
    this.date = this.datePipe.transform(event.target.value, 'yyyy-MM-dd');
    //this.date = new DatePipe('pt-BR').transform(this.date, 'yyyy-MM-dd');
    if (this.date) {
      this.experimentalDateValue = new Date(this.date + ' 00:00:00 -03:00');
      //this.experimentalDateValue.addHours(3);
    }
  }

  checkDate() {
    let formatedDate = new DatePipe('pt-BR').transform(this.date, 'yyyy-MM-dd');
    return formatedDate;
  }

  selectEventTeacher(item: any) {
    // do something with selected item
    this.onTeacherChange(item.id);
  }

  onChangeSearchTeacher(val: string) {
    console.log(val);
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocusedTeacher(e: any) {
    console.log(e);
    // do something when input is focused
  }

  getFormattedMessage(messageValue: string): any {
    return messageValue?.replace(/\n/g, '<br />');
  }
}
