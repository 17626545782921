import { Course, Student, StudentClassesCourse, Teacher } from '.';
import { formatDate } from '@angular/common';
import { Base } from '@app/shared/model';

export class ClassesCalendarEvent extends Base {
    date: Date;
    statusClass: number;
    
    teacher: Teacher;
    teacherId: number;
    
    student: Student;
    studentId: number;

    course: Course;
    courseId: number;

    studentClassesCourse: StudentClassesCourse;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.teacherId = parseInt(contentResponse.teacherId);
        if (contentResponse && contentResponse.teacherId > 0) {
            this.teacher = contentResponse.teacher;
        }

        this.studentId = parseInt(contentResponse.studentId);
        if (contentResponse && contentResponse.studentId > 0) {
            this.student = contentResponse.student;
        }

        this.courseId = parseInt(contentResponse.courseId);
        if (contentResponse && contentResponse.courseId > 0) {
            this.course = contentResponse.course;
        }

        this.date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        this.statusClass = parseInt(contentResponse.statusClass);

        this.studentClassesCourse = contentResponse.studentClassesCourse;
    }
}
