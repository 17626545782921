import { SerieContent } from './../model/serieContent';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { SerieType } from '../enum/SerieType';

@Injectable({ providedIn: 'root' })
export class ContentJsonService {
  private contentFile: string = `${environment.contentFiles}`;
  private structureFile: string;

  private serieContents: Array<SerieContent> = new Array<SerieContent>();

  constructor(private http: HttpClient) {
    this.structureFile = `${this.contentFile}/structure-files.json`;
  }

  public AllSeries(): any {
    this.serieContents = new Array<SerieContent>();
    return this.getJSON().pipe(map((response: any) => {
      return this.mapDataToContent(response.contents);
    }), catchError(error => {
      if (error.status == 400) {
        if (error.errors) {
          return Observable.of(error.errors);
        }
      }
      return error;
    }));
  }

  private getJSON(): Observable<any> {
    var call = this.http.get<any>(this.structureFile);
    return call.pipe(map(response => {
      return response
    }));
  }

  private mapDataToContent(data: any): Array<SerieContent> {
    if (data) {
      data.forEach((element: Object) => {
        let serieContent = new SerieContent(element);
        if (serieContent) {
          this.serieContents.push(serieContent);
        }
      });
      return this.serieContents;
    }
    return new Array<SerieContent>();
  }

  public getContentPath(type = null, serie = null, episode = null): string {
    if (type && serie && episode) {
      return `${this.contentFile}/pecm/${type}/${serie}/${episode}/`
    } else if (type && serie) {
      return `${this.contentFile}/pecm/${type}/${serie}/`
    } else {
      return '';
    }
  }

  public SerieByName(type: SerieType, itemName: string): any {
    return this.getJSON().pipe(map((response: any) => {
      let contentList = this.mapDataToContent(response.contents);
      if (contentList != null && contentList.length > 0) {
        let series = contentList.filter(x => x.serieType == type).map(y => y.series);
        if (series != null && series.length > 0) {
          let serie = series[0].filter(m => m.item == itemName);
          return serie != null && serie.length > 0 ? serie[0] : null;
        }
      }
    }), catchError(error => {
      if (error.status == 400) {
        if (error.errors) {
          return Observable.of(error.errors);
        }
      }
      return error;
    }));
  }
}
