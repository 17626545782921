import { Student } from '@app/admin/model';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { CalendarUtilService, MessageService, UtilService } from '@app/shared/services';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { InitStudentService } from '@app/student/services';
import { CalendarService } from '@app/student/services/calendar.service';
import moment from 'moment';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CourseTypeCss, CourseTypeLabel, ModuleType, StatusClass, StatusClassCss, StatusClassLabel } from '@app/shared/enum';
import { default as swal } from 'sweetalert2'
import { CourseService, StudentClassesCourseService } from '@app/admin/services';
import { first, map } from 'rxjs/operators';
import { formatDate } from '@angular/common';

import { Calendar, CalendarOptions, DateSelectArg, EventApi, EventClickArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import Locale from '@fullcalendar/core/locales/pt-br';
import { AccountService } from '@app/auth/services';
import { ContentEpisode } from '@app/content/model';
import { HttpClient } from '@angular/common/http';
import { FullCalendarComponent } from '@fullcalendar/angular';

@Component({
  selector: 'app-calendarasync',
  templateUrl: './calendarasync.component.html',
  styleUrls: ['./calendarasync.component.css']
})
export class CalendarAsyncComponent implements OnInit {
  @ViewChild('mdCalendarDetails') mdCalendarDetails: any;
  private student: Student;
  public dashboardUrl = '/student/home';

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  public messageNoContentCalendar: string = 'Validando informações do calendário... Aguarde!';

  public studentCourseClassId: any = 0;
  public courseType: any = 0;
  public studentClassesCourseExpectedDate: any;
  public studentClassesCourseReschedule = new Date();
  public studentClassesCourseExpectedTime: any;

  private userRoles: Array<any>;

  public calendarVisible: boolean = false;

  public statusClassList: any = [];
  public courseData: any;

  calendarPlugins = [dayGridPlugin]; // important!
  calendarEvents: Array<any> = [];
  closeResult: string;
  dataResult = false;
  public returnMessage = '';
  public calendar: any;
  calendarOptions: CalendarOptions = {
    plugins: [ interactionPlugin, dayGridPlugin, bootstrapPlugin ],
    themeSystem: 'bootstrap',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    dateClick: this.handleDateClick.bind(this), // MUST ensure `this` context is maintained
    navLinks: true, // can click day/week names to navigate views
    editable: true,
    initialView: 'listWeek',
    locale: Locale,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    titleFormat: { // will produce something like "Tuesday, September 18, 2018"
      month: 'long',
      year: 'numeric',
      day: 'numeric'
    },
    eventTimeFormat: { // like '14:30:00'
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false
    }
  };

  calendarOptions2$ = this.calendarService.filter(0, new Date(), new Date())
    .pipe(map((data) => ({ ...this.calendarOptions, events: data, initialDate: new Date() })));

  eventDetails: any = {
    id: 0,
    title: '',
    start: new Date(),
    end: new Date(),
    duration: new Date(),
    course: '',
    teacher: '',
    student: ''
  };

  public episode: ContentEpisode;
  public serieId: number;
  public episodeId: number;


  constructor(
    private changeDetector: ChangeDetectorRef,
    public http: HttpClient,
    private router: Router,
    private studentClassesCourseService: StudentClassesCourseService,
    private calendarUtilService: CalendarUtilService,
    private calendarService: CalendarService,
    private courseService: CourseService,
    private accountService: AccountService,
    private initStudent: InitStudentService,
    private utilService: UtilService,
    private loading: MessageService,
    private modalService: NgbModal) {
      this.initStudent.setConfigPage();
      this.student = this.initStudent.student;
      this.userRoles = this.accountService.getRolesByUser();
    }

  ngOnInit(): void {
    this.loading.showLoading();
    this.getUserData();
    this.getStatusClassList();
    this.designConfig();
  }

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: this.createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    }
  }

  createEventId() {
    let eventGuid = 0;
    return String(eventGuid++);
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  }

  handleEvents(events: EventApi[]) {
    this.loading.showLoading();
    if (this.calendarComponent) {
      let calendarApi = this.calendarComponent.getApi();
      this.testMock();
      //calendarApi.next();
    }
    var currentEvents = events;
    this.changeDetector.detectChanges();
    this.loading.hideLoading();
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }
  
  private isMobileScreen(): boolean {
    return window.screen.width < 640; // 768px portrait
  }

  public newEvents: any = [];

  testMock() {
    let calendarApi = this.calendarComponent.getApi();
    this.calendarOptions2$ = this.calendarService.filter(this.student?.id ?? 0, calendarApi.view.currentStart, calendarApi.view.currentEnd)
    .pipe(map((data) => ({ ...this.calendarOptions, events: data, initialDate: calendarApi.view.currentStart, initialView: calendarApi.currentData.currentViewType })));
  }

  handleDateClick(arg) {
    alert('date click! ' + arg.dateStr)
  }

  private designConfig() {
    // Call the below function
    this.waitForElementToDisplay("#fullCalendarStudent", function() { 
      if (window.screen.width < 640) {
        var toolCalendar = document.getElementsByClassName('fc-header-toolbar');
        if (toolCalendar) {
          toolCalendar[0]?.classList.remove('row');
          toolCalendar[0]?.classList.add('row');
          var toolCalendarDiv = document.getElementsByClassName('fc-toolbar-chunk');
          if (toolCalendarDiv) {
            for(var i = 0; i <= toolCalendarDiv.length; i++) {
              toolCalendarDiv[i]?.classList.remove('col-12');
              toolCalendarDiv[i]?.classList.add('col-12');

              if (i == 1) {
                toolCalendarDiv[i]?.classList.remove('small');
                toolCalendarDiv[i]?.classList.remove('mt-3');
                toolCalendarDiv[i]?.classList.remove('mb-3');
                toolCalendarDiv[i]?.classList.remove('text-primary');
                toolCalendarDiv[i]?.classList.add('small');
                toolCalendarDiv[i]?.classList.add('mt-3');
                toolCalendarDiv[i]?.classList.add('mb-3');
                toolCalendarDiv[i]?.classList.add('text-primary');
              }
            }
          }
        }
      }
    } , 1000, 9000);
  }

  waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
    var startTimeInMs = Date.now();
    (function loopSearch() {
      if (document.querySelector(selector) != null) {
        callback();
        return;
      }
      else {
        setTimeout(function () {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
            return;
          loopSearch();
        }, checkFrequencyInMs);
      }
    })();
  }

  getStatusClassList() {
    this.statusClassList = this.utilService.ToArray(StatusClass, StatusClassLabel);
    this.statusClassList.forEach((statusClass: any) => {
      statusClass.css = StatusClassCss.get(statusClass.value);
    });
  }
  
  private getUserData() {
    this.initStudent.getStudentUser().pipe(first()).subscribe((response: any) => {
      this.student = response;
      //this.loadCalendar();
      this.loading.hideLoading();
    }, (error: any) => {
      this.returnMessage = 'Erro ao carregar o calendário.';
      this.loading.hideLoading();
    });
  }

  eventsSet(events) {
    console.log(events);
  };

  loadCalendar() {
    if (this.student) {
      this.calendarService.getById(this.student.id).subscribe(response => {
        if (response.typeResponse === TypeResponse.Success) {
          this.addManyEvents(response.data);
          this.calendarOptions.events = this.calendarEvents;
          this.returnMessage = '';
          this.messageNoContentCalendar = '';
          this.calendarVisible = true;
        } else {
          this.calendarVisible = false;
          this.messageNoContentCalendar = response.message;
          this.returnMessage = response.message;
        }
        this.loading.hideLoading();
      }, (error: any) => {
        this.loading.hideLoading();
        this.calendarVisible = false;
        this.messageNoContentCalendar = 'Calendário não está disponível. Verifique sua turma!';
        this.returnMessage = 'Erro ao carregar os dados da agenda.';
        console.log(error);
      });
    } else {
      this.messageNoContentCalendar = 'Calendário não está disponível. Verifique sua turma!';
      this.calendarVisible = false;
      //this.router.navigate([this.dashboardUrl]);
    }
  }

  onDateClick(res: any) {
    alert('Clicked on date : ' + res.dateStr);
  }

  eventClick(arg: any) {
    this.eventDetails.id = arg.event.id;
    this.eventDetails.title = arg.event.title;
    this.eventDetails.start = arg.event.start;
    this.eventDetails.end = arg.event.end;
    this.eventDetails.description = arg.event.description;
    this.eventDetails.duration = arg.event.extendedProps.duration;
    this.eventDetails.course = arg.event.extendedProps.course;
    this.eventDetails.teacher = arg.event.extendedProps.teacher;
    this.eventDetails.student = arg.event.extendedProps.student;
    this.eventDetails.class = arg.event.extendedProps.class;
    this.eventDetails.status = arg.event.extendedProps.status;
    this.eventDetails.designStatusClass = arg.event.extendedProps.designStatusClass;
    this.eventDetails.courseType = arg.event.extendedProps.courseType;
    this.eventDetails.courseTypeCss = arg.event.extendedProps.courseTypeCss;
    this.eventDetails.courseTypeStr = arg.event.extendedProps.courseTypeStr;

    this.eventDetails.confirmedClassByStudent = arg.event.extendedProps.confirmedClassByStudent;
    this.eventDetails.confirmedDateByStudent = arg.event.extendedProps.confirmedDateByStudent;
    this.eventDetails.confirmedClassByTeacher = arg.event.extendedProps.confirmedClassByTeacher;
    this.eventDetails.confirmedDateByTeacher = arg.event.extendedProps.confirmedDateByTeacher;
    this.eventDetails.canceledClass = arg.event.extendedProps.canceledClass;
    this.eventDetails.canceledDate = arg.event.extendedProps.canceledDate;

    this.eventDetails.episode = arg.event.extendedProps.episode;

    this.eventDetails.courseIdentifier = arg.event.extendedProps.courseIdentifier;
    this.eventDetails.classRoom = arg.event.extendedProps.classRoom;

    this.modalService.dismissAll();
    this.modalService.open(this.mdCalendarDetails, { backdrop: 'static', windowClass:'animated fade'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if(result === 'Save') {
        console.log('result = save');
        // this.submitChangePassword();
      }
    }, (reason) => {
      this.closeResult = `Dismissed`;
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  };

  onEventClick(event) {
    console.log(event);
    //alert('Clicked on date : ' + res.dateStr)
  }

  addEvent() {
    this.calendarEvents = this.calendarEvents.concat(
      { title: 'event 2', date: '2019-04-02' }
    );
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
 
  addManyEvents(response: any) {
    if (response != null && response.calendarEvents != null && response.calendarEvents.length > 0) {
      
      const data = response.calendarEvents;
      // Loop por datas
      for (let d = 0; d < data.length; d++) {
        const cItem = data[d]; // Date
        if (cItem != null) {
          const dFormat = moment(cItem.date).format('YYYY-MM-DD');

          let designCourseType = this.getCourseTypeDesign(cItem.course.courseType);

          const event: any = {
            id: cItem.studentClassesCourse.id,
            classNames: this.calendarUtilService.getDesignClassCss(cItem.statusClass),
            title: `Teacher: ${cItem.teacher.name} | Turma: ${cItem.course.name} | Aula nº ${cItem.studentClassesCourse.numberClass}`,
            start: `${dFormat}T${cItem.startTime}`,//'2019-08-12T10:30:00',
            end: `${dFormat}T${cItem.endTime}`,
            description: `Aula nº ${cItem.studentClassesCourse.numberClass}`,
            extendedProps: {
              //description: `Aula nº ${cItem.studentClassesCourse.numberClass}`,
              duration: `${dFormat}T${cItem.duration}`,
              teacher: cItem.teacher.name,
              student: cItem.student.name,
              course: cItem.course.name,
              episode: cItem.studentClassesCourse.contentEpisode,
              classRoom: cItem.course.classRoom,
              courseIdentifier: cItem.course.identifier,
              status: cItem.statusClass,
              courseType: cItem.course.courseType,
              courseTypeCss: designCourseType.courseTypeCss,
              courseTypeStr: designCourseType.courseTypeStr,
              designStatusClass: this.designStatusClass(cItem.statusClass),
              confirmedClassByStudent: cItem.studentClassesCourse.confirmedClassByStudent,
              confirmedDateByStudent: formatDate(cItem.studentClassesCourse.confirmedClassByStudent ? cItem.studentClassesCourse.confirmedDateByStudent : new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
              confirmedClassByTeacher: cItem.studentClassesCourse.confirmedClassByTeacher,
              confirmedDateByTeacher: formatDate(cItem.studentClassesCourse.confirmedClassByTeacher ? cItem.studentClassesCourse.confirmedDateByTeacher : new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
              canceledClass: cItem.studentClassesCourse.canceledClass,
              canceledDate: formatDate(cItem.studentClassesCourse.canceledDate ? cItem.studentClassesCourse.canceledDate : new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US')
            }
          };
          this.calendarEvents.push(event);
        }
      }
    }
  }

  designStatusClass(status: StatusClass) {
    let statusClassCss = StatusClassCss.get(status);
    let statusClassStr = StatusClassLabel.get(status);
    return "<span class='badge badge-pill w-100 font-weight-bold "
      + statusClassCss +"'>"
      + statusClassStr +"</span>";
  }

  modifyTitle(eventIndex: number, newTitle: string) {
    let calendarEvents = this.calendarEvents.slice(); // a clone
    let singleEvent = Object.assign({}, calendarEvents[eventIndex]); // a clone
    singleEvent.title = newTitle;
    calendarEvents[eventIndex] = singleEvent;
    this.calendarEvents = calendarEvents; // reassign the array
  }

  changedClass(studentClassesCourseId: any, statusClass: StatusClass, linkAoVivo: string = '') {
    this.dismissModal();
    this.loading.showLoading();
    this.studentClassesCourseService.changedClass(studentClassesCourseId, statusClass, ModuleType.Student, 0).subscribe((response: any) => {
      this.loading.hideLoading();
      if (response.typeResponse == TypeResponse.Success) {
        if (response.data === true) {
          this.changedClassSuccess(statusClass, linkAoVivo);
        } else {
          this.changedClassFailed(statusClass);
        }
      }
    }, (error: any) => {
      this.loading.hideLoading();
      this.changedClassFailed(statusClass);
    });
  }

  changedClassSuccess(status: StatusClass, linkAoVivo: string = '') {
    if (status == StatusClass.Unchecked) {
      return this.changedClassSuccessOnlyEpisode(status);
    }

    swal({
      type: 'success',
      title: linkAoVivo ? `Presença registrada na aula com sucesso!` : `Atualização realizada com sucesso!`,
      html: linkAoVivo ? '<small>Caso a aba do seu navegador não abra automaticamente, '+
        'clique no link abaixo ou copie e cole no seu navegador para entrar na sala!</small><br><br>'+
        '<a href="'+linkAoVivo+'" target="_blank">'+linkAoVivo+'</a><br>' : '',
      showConfirmButton: true,
      confirmButtonText: 'Entrar na sala'
      //timer: 3000
    }).then((result: any) => {
      if (result.value === true && linkAoVivo) {
        window.open(linkAoVivo, '_blank');
        setTimeout(() => {
          this.loading.hideLoading();
          window.location.reload();
        }, 5000);
      }
    });
  }

  changedClassSuccessOnlyEpisode(status: StatusClass) {
    swal({
      type: 'success',
      title: `Aula atualizada com sucesso!`,
      showConfirmButton: false,
      timer: 2200
    });
  }

  changedClassFailed(status: StatusClass) {
    swal({
      type: 'warning',
      title: `Não foi possível realizar a atualização!`,
      showConfirmButton: true,
    }).then(() => {
      //window.location.reload();
    });
  }

  openClassRoom(courseIdentifier: any, content: any) {
    if (courseIdentifier) {
      this.courseService.getByIdentifier(courseIdentifier).pipe(first()).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.courseData = JSON.stringify(response.data);
          this.modalService.open(content, {
            backdrop: 'static',
            size:'lg',
            windowClass: 'animated fade modal-backdrop-transparent'
          }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });     
          this.returnMessage = '';
        } else {
          swal({
            type: 'warning',
            title: 'Problema na aula ao vivo',
            text: response.message,
            showConfirmButton: true,
          });
        }
      }, (error: any) => {
        swal({
          type: 'error',
          title: 'Problema na aula ao vivo',
          text: "Ocorreu um erro ao iniciar a aula ao vivo!",
          showConfirmButton: true,
        });
      });
    } else {
      swal({
        type: 'warning',
        title: 'Problema na aula ao vivo',
        text: "Não foi possível iniciar a aula ao vivo!",
        showConfirmButton: true,
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();
  
      console.log(reason);
  
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }
  
  getClassRoomUrl(classRoom: any) {
    return `https://meet.jit.si/ec-class-room-${classRoom}`;
  }
  //http://localhost:4200/#/student/calendar/content/classroom;nameRoom=ec-class-room-f07c2b4c;courseName=Turma%20nova%201
  //http://localhost:4200/#/content/classroom;nameRoom=ec-class-room-f07c2b4c;courseName=Turma%20nova%201
  getClassRoomUrl2(row: any) {
    return `/#/content/classroom;nameRoom=${row.classRoom};courseName=${row.course}`;
    //window.open(`/content/classroom;nameRoom=${row.classRoom};courseName=${row.course}`, '_blank');
    //var link = `/content/classroom;nameRoom=${row.classRoom};courseName=${row.course}`;
    //this.router.navigate(['/content/classroom', { nameRoom: row.classRoom, courseName: row.course }])
    //.then(result => {  window.open(link, '_blank'); });
    //this.router.navigate(['/content/classroom', { nameRoom: row.classRoom, courseName: row.course }]);
  }

  private checkHavePermission(role: any): boolean {
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some(x => x.keyDefault == role);
    } else {
      return false;
    }
  }

  checkClassRoomAccess(event: any) {
    let checkDate: Date;
    checkDate = new Date(event.start);
    checkDate.setHours(0, 0, 0, 0);

    let dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);   

    return this.checkHavePermission('EST1AL7AV44')
      && checkDate.toDateString() === dateNow.toDateString();
  }

  // checkClassRoomAccess(event: any) {
  //   return this.checkHavePermission('EST1AL7AV44')
  //     && event.start.toDateString() <= new Date().toDateString();
  // }

  private checkDateIsValid(date: Date) {
    var targetDate = date.setHours(-1);
  }

  getCourseTypeDesign(courseType: any) {
    let courseTypeCss = CourseTypeCss.get(courseType);
    let courseTypeStr = CourseTypeLabel.get(courseType);
    return {
      courseTypeStr,
      courseTypeCss
    };
  }

  private checkAllowReschedule(event: any): boolean {
    var diff = (event.start.getTime() - new Date().getTime()) / 3600000;
    return diff >= 3;
  }

  studentCourseClassReschedule(event: any, content: any) {
    if (event.status == StatusClass.Reschedule) {
      return this.rescheduleClassFailed();
    }

    if (!this.checkAllowReschedule(event))
      return this.rescheduleNotAllow(event);

    this.studentCourseClassId = event.id;
    this.studentClassesCourseExpectedDate = event.start;
    this.courseType = event.courseType;
    this.modalService.open(content, {
      backdrop: 'static',
      size:'sm',
      windowClass: 'animated fade modal-backdrop-transparent'
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  rescheduleClassFailed() {
    swal({
      type: 'warning',
      title: `Não é possível reagendar uma aula que já foi reagendada!`,
      showConfirmButton: true,
    }).then(() => {
      this.dismissModal();
    });
  }

  rescheduleNotAllow(event: any) {
    swal({
      type: 'warning',
      title: 'Limite de remarcação excedido!',
      html: `Essa aula já passou do horário limite para remarcação 😔<br><small>Remarcação de aulas são permitidas com 3 horas de antecedência.</small>`,
      showConfirmButton: true,
    })
  }

  setValueTime(input: any) {
    this.studentClassesCourseExpectedTime = input.value;
  }

  setValueDate(input: any) {
    this.studentClassesCourseExpectedDate = input.value;
  }

  willChangeClassReschedule(willChangeClassReschedule: any, courseType: any): void {
    this.modalService.dismissAll();
    if (courseType == 1) {
      return this.confirmChangeClassReschedule(courseType);
    }

    swal({
      title: "Sugerir remarcação da aula",
      html: 'Ao confirmar essa ação, será enviado uma notificação para o(a) professor(a) '+
        'solicitando a remarcação da aula conforme proposto.'+
        '<br><strong>Confirma a notificação para o(a) professor(a)?</strong>',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, notificar professor(a)'
    }).then(willConfirm => {
      if (willConfirm.value === true) {
        this.confirmChangeClassReschedule(this.courseType);
      } else {
        this.loading.hideLoading();
        return;
      }
    },
    error => {
      this.loading.hideLoading();
      if (error.error.error === 'invalid_token') {
        this.router.navigate(['/auth/login']);         
      } else {
        swal({
          type: 'error',
          title: 'Erro ao tentar cancelar a aula.',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          this.loading.hideLoading();
          //window.location.reload();
        });
      }
    });
  }

  private confirmChangeClassReschedule(courseType: any) {
    this.dismissModal();
    this.loading.showLoading();
    let newDateStr = '0';

    if (this.courseType == 0) {
      let newDate = new Date(this.studentClassesCourseExpectedDate + 'GMT-0300');
      newDate.setMinutes(this.studentClassesCourseExpectedTime.split(':')[1]);
      newDate.setHours(this.studentClassesCourseExpectedTime.split(':')[0]);
      newDateStr = formatDate(newDate, 'yyyy-MM-dd HH:mm:ss', 'en-US');
    }

    this.studentClassesCourseService.rescheduleSuggestion(this.studentCourseClassId, newDateStr).subscribe((response: any) => {
      this.loading.hideLoading();
      if (response.typeResponse == TypeResponse.Success) {
        if (response.data === true) {
          this.changedClassSuccess(courseType == 0 ? StatusClass.Reschedule : StatusClass.Unchecked);
        } else {
          this.changedClassFailed(courseType == 0 ? StatusClass.Reschedule : StatusClass.Unchecked);
        }
      }
    }, (error: any) => {
      this.loading.hideLoading();
      this.changedClassFailed(courseType == 0 ? StatusClass.Reschedule : StatusClass.Unchecked);
    });
  }

  checkConfirmClass(target: any): void {
    var link = this.getClassRoomUrl(target.classRoom);
    swal({
      title: "Confirmação da aula",
      html: 'Deseja confirmar a participação na aula ao vivo?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, confirmar minha presença',
      cancelButtonText: 'Não'
    }).then(willConfirm => {
      if (willConfirm.value === true) {
        var link = this.getClassRoomUrl(target.classRoom);
        this.changedClass(target.id, 1, link);
      } else {
        this.dismissModal();
        this.loading.hideLoading();
        // swal({
        //   type: 'info',
        //   title: `Lembre-se de confirmar a aula sempre que finalizar!`,
        //   showConfirmButton: true,
        // }).then(() => {
        //   // var linkAoVivo = this.getClassRoomUrl(target.classRoom);
        //   // window.open(linkAoVivo, '_blank');
        //   this.dismissModal();
        //   this.loading.hideLoading();
        // });
      }
    },
    error => {
      this.loading.hideLoading();
      if (error.error.error === 'invalid_token') {
        this.router.navigate(['/auth/login']);         
      } else {
        swal({
          type: 'error',
          title: 'Erro ao tentar entrar na aula. Atualize a página e tente novamente!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          this.loading.hideLoading();
          //window.location.reload();
        });
      }
    });
  }
}