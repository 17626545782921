// Angular Components
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
//import { ListViewModule } from '@syncfusion/ej2-angular-lists';
//import { NgxDropzoneModule } from 'ngx-dropzone';
//import { NgSelect2Module } from 'ng-select2';
import {RouterModule} from '@angular/router';


// Modules

// Components

// Helpers
import { TextMaskModule } from '../_helpers/mask/text.mask';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { NgxSummernoteModule } from 'ngx-summernote';
import { SafePipe } from '@app/_helpers/safe/safe.pipe';
import { SharedModule } from '@app/shared/shared.module';
import { ContentAuditComponent } from './components/contentAudit/content-audit/content-audit.component';
import { ContentAuditListComponent } from './components/contentAudit/content-audit-list/content-audit-list.component';
import { UserAuditListComponent } from './components/userAudit/user-audit-list/user-audit-list.component';
import { UserAuditComponent } from './components/userAudit/user-audit/user-audit.component';
import { AuditRoutingRoutes } from './audit-routing.module';
import { HolidayComponent } from './components/holiday/holiday/holiday.component';
import { HolidayListComponent } from './components/holiday/holiday-list/holiday-list.component';

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
    ContentAuditComponent,
    ContentAuditListComponent,
    UserAuditListComponent,
    UserAuditComponent,
    HolidayComponent,
    HolidayListComponent
  ],
  imports: [
    AuditRoutingRoutes,
    RouterModule,
    //NgxDropzoneModule,
    ChartsModule,
    TextMaskModule,
    ArchwizardModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    //ListViewModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxSummernoteModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [ DatePipe, SafePipe ]
})

export class AuditModule {
  stepDefault: any = 0;

  constructor() {
  }
}