import { CourseRecurrence, Product, Teacher } from '.';
import { Base } from '@app/shared/model';
import { CourseType } from '@app/shared/enum';

export class CourseGrid extends Base {
    name: string;
    courseType: CourseType;

    weeklyFrequency: number;
    quantityStudent: number;
    quantityMonth: number;
    registeredStudents: number;
    courseRecurrences: number;
    skypeLink: string;

    productId: number;
    product: Product;

    teacherId: number;
    teacher: Teacher;

    courseRecurrencesData: Array<CourseRecurrence>;

    constructor(contentResponse: any) {
        super(contentResponse);
        
        this.name = contentResponse.name;
        this.courseType = contentResponse.courseType ? parseInt(CourseType[contentResponse.courseType]) : CourseType.Vip;
        
        this.skypeLink = contentResponse.skypeLink;
        this.weeklyFrequency = parseInt(contentResponse.weeklyFrequency);
        this.quantityStudent = parseInt(contentResponse.quantityStudent);
        this.quantityMonth = parseInt(contentResponse.quantityMonth);
        this.registeredStudents = parseInt(contentResponse.registeredStudents);
        this.courseRecurrences = parseInt(contentResponse.courseRecurrences);

        this.productId = parseInt(contentResponse.productId);
        if (contentResponse && contentResponse.productId > 0) {
            this.product = contentResponse.product;
        }

        this.teacherId = parseInt(contentResponse.teacherId);
        if (contentResponse && contentResponse.teacherId > 0) {
            this.teacher = contentResponse.teacher;
        }

        if(contentResponse.courseRecurrencesData && contentResponse.courseRecurrencesData.lenght > 0) {
            this.courseRecurrencesData = contentResponse.courseRecurrencesData;
        }
    }
}
