import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';  
import { HttpClient  } from '@angular/common/http';  
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IpAddressService {

  constructor(private http: HttpClient) { }

  public getIPAddress(): Observable<any>
  {  
    return this.http.get("http://api.ipify.org/?format=json")
    .pipe(map((response: Response) => {
    return  <any>response.json()
    } ))
  }
}
