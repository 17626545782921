import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from '@app/auth/services';
import { Confirmation } from '@app/auth/model/confirmation';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent implements OnInit {
  confirm: any;
  loading = false;
  returnUrl: string;
  private confirmation: Confirmation;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService
  ) {
    this.returnUrl = '/auth/login';
  }

  ngOnInit(): void {
    let params: any = this.activatedRoute.snapshot.queryParams;
    if (params) {
      this.confirmation = new Confirmation(params['userid'], params['code']);
      //this.userid = params['userid'];
      //this.code = params['code'];
    }
    this.onConfirmation();
  }

  onConfirmation() {
    this.confirm = this.accountService.confirmRegister(this.confirmation);
    this.router.navigate([this.returnUrl]);
  }

  send_again() {
    this.loading = true;
    //this.loadConfirm(this.userId, this.code);
    this.loading = false;
  }
}
