import { ModuleChangeComponent } from './components/module-change/module-change.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Components
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ForgetComponent } from './components/forget/forget.component';
import { LockedComponent } from './components/locked/locked.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login'} },
  { path: 'confirmation', component: ConfirmationComponent, data: { title: 'Confirmação'} },
  { path: 'forget', component: ForgetComponent, data: { title: 'Esqueceu a Senha'} },
  { path: 'locked', component: LockedComponent, data: { title: 'Bloqueado'} },
  { path: 'register', component: RegisterComponent, data: { title: 'Registro'} },
  { path: 'resetpassword', component: ResetPasswordComponent, data: { title: 'Resetar senha'} },
  { path: 'modulechange', component: ModuleChangeComponent, data: { title: 'Módulo'} }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
