import { formatDate } from '@angular/common';
import { Base } from '@app/shared/model';

export class Holiday extends Base {
    name: string;
    date: Date;
    optionalHoliday: boolean;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.name = contentResponse.name;
        
        if (contentResponse.date) {
            this.date = new Date(formatDate(contentResponse.date, 'yyyy-MM-dd', 'en-US'));
        } else {
            this.date = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        }

        this.optionalHoliday = contentResponse.optionalHoliday;
    }
}
