import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/auth/helpers';
import { ModuleType } from '@app/shared/enum';
import { ContentAuditListComponent } from './components/contentAudit/content-audit-list/content-audit-list.component';
import { ContentAuditComponent } from './components/contentAudit/content-audit/content-audit.component';
import { UserAuditComponent } from './components/userAudit/user-audit/user-audit.component';
import { UserAuditListComponent } from './components/userAudit/user-audit-list/user-audit-list.component';
import { HolidayListComponent } from './components/holiday/holiday-list/holiday-list.component';
import { HolidayComponent } from './components/holiday/holiday/holiday.component';


const routes: Routes = [
  { path: 'ContentAuditList', component: ContentAuditListComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AU21CA48', title: 'Auditoria de Conteúdo' } },
  { path: 'ContentAudit', component: ContentAuditComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AU21CA48', title: 'Auditoria de Conteúdo' } },

  { path: 'UserAuditList', component: UserAuditListComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AU21CA48', title: 'Auditoria de Acesso' } },
  { path: 'UserAudit', component: UserAuditComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AU21CA48', title: 'Auditoria de Acesso' } },

  { path: 'holidayList', component: HolidayListComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AD2CU3', title: 'Feriados' } },
  { path: 'holiday', component: HolidayComponent, canActivate: [AuthGuard], data: { module: ModuleType.Admin, role: 'ADM0AD2CU3', title: 'Feriado' } }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuditRoutingRoutes { }
