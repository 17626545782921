import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DashboardService, InitAdminService } from '@app/admin/services';
import { GroupClassRunningByDay } from '@app/reports/model';
import { CourseType, CourseTypeCss, CourseTypeLabel, StatusClass, StatusClassCss, StatusClassLabel, TypeResponse } from '@app/shared/enum';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-classroom-in-progress',
  templateUrl: './classroom-in-progress.component.html',
  styleUrls: ['./classroom-in-progress.component.css']
})
export class ClassroomInProgressComponent {
  public groupClassRunningByDay: any = [];
  public groupClassRunningByDay$ = new BehaviorSubject([]);

  public showData: boolean;
  dataResult: boolean = false;
  public returnMessage = '';

  public classRunningDetail: any;

  closeResult: string;

  constructor(private modalService: NgbModal,
    private initAdmin: InitAdminService,
    private dashboardService: DashboardService) {
      this.initAdmin.setConfigPage();
      this.getGroupClassRunningByDay();
      this.groupClassRunningByDay$.next([]);
  }

  getGroupClassRunningByDay() {
    this.showData = false;
    this.dashboardService.classRunning(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'))
    .pipe(first()).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.groupClassRunningByDay = response.data;
        let arrayClassRunning = [];
        this.groupClassRunningByDay?.past.forEach((item: any) => {
          item.type = 'past';
          item.classesRunning.forEach((n: any) => {
            if (n.course) {
              n.course.designCourseType = this.designCourseType(n.course.courseType);
            }
          });
          arrayClassRunning.push(item);
        });
        this.groupClassRunningByDay?.present.forEach((item: any) => {
          item.type = 'present';
          item.classesRunning.forEach((n: any) => {
            if (n.course) {
              n.course.designCourseType = this.designCourseType(n.course.courseType);
            }
          });
          arrayClassRunning.push(item);
        });
        this.groupClassRunningByDay?.future.forEach((item: any) => {
          item.type = 'future';
          item.classesRunning.forEach((n: any) => {
            if (n.course) {
              n.course.designCourseType = this.designCourseType(n.course.courseType);
            }
          });
          arrayClassRunning.push(item);
        });
        this.groupClassRunningByDay$.next(arrayClassRunning);
        this.showData = true;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
        this.showData = false;
      }
    }, (error: any) => {
      this.showData = false;
      this.returnMessage = 'Erro ao carregar os dados do relatório.';
    });
  }

  viewClassroomProgressDetail(row: any, content: any) {
    this.classRunningDetail = row;
    this.modalService.dismissAll();
    this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  designStatusClass(status: StatusClass) {
    let statusClassCss = StatusClassCss.get(status);
    let statusClassStr = StatusClassLabel.get(status);
    return "<span class='badge badge-pill w-100 font-weight-bold "
      + statusClassCss +"'>"
      + statusClassStr +"</span>";
  }

  designCourseType(courseType: CourseType) {
    let courseTypeCss = CourseTypeCss.get(courseType);
    let courseTypeStr = CourseTypeLabel.get(courseType)
    return "<span class='badge badge-pill w-100 "
      + courseTypeCss +"'>"
      + courseTypeStr +"</span>";
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      console.log(reason);
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }
}
