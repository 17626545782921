<div class="modal-header">
    <h5 class="modal-title">Selecione um(a) professor(a)</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <span role="status" aria-hidden="true" class="spinner-border spinner-border-sm pull-left mr-2 text-primary" *ngIf="flagSearchTeacher"></span>
            <span class="text-primary pull-left" *ngIf="flagSearchTeacher">Carregando professores(as)</span>
        </div>
        <div class="col-12">
            <label class="form-label" for="userId">Professor(a) <span class="text-danger">*</span> </label>
            <br>
            <select class="form-control" id="teacherId" placeholder="Professor(a)" (change)="setTeacher($event?.target?.value);" [(ngModel)]="teacherId">
                <option *ngFor="let item of teacherDataList" [value]="item.id">
                    {{ item.text }}
                </option>
            </select>
            <!-- <ng-select2 class="w-100" [data]="teacherDataList" [width]="150" style="z-index: 1000;"
                (valueChanged)="setTeacher($event);" [options]="options"></ng-select2> -->
            <span *ngIf="!validTeacher" class="text-warning pull-left" style="padding-top: 10px;">
                <i class="fa fa-warning text-warning"></i> Nenhum(a) usuário(a) selecionado(a).
            </span>
        </div>
    </div>
    <!-- <hr>
    <div class="row" *ngIf="teacherId > 0">
        <div class="col-4 d-flex">
            <div class="info-card">
                <img src="assets/img/demo/avatars/avatar-admin.png" class="profile-image rounded-circle width-xs" alt="Dr. Codex Lantern">
            </div>
        </div>
        <div class="col-8 d-flex">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{teacherData.name}}</h5>
                    <p class="card-text">E-mail: {{teacherData.email}}</p>
                </div>
            </div>
        </div>
    </div> -->
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="cancel()">
        <i class="fa fa-times-circle mr-1"></i> Cancelar
    </button>
    <button type="button" class="btn btn-sm btn-success waves-effect waves-themed" (click)="done()">
        <i class="fa fa-check mr-1"></i> Confirmar
    </button>
</div>
