import { SupplierService, InitAdminService } from '@app/admin/services';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Product, Course, Supplier } from '@app/admin/model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertService, MessageService } from '@app/shared/services';
import { first } from 'rxjs/operators';
import { Unity, TypeResponse } from '@app/shared/enum';
import { DatePipe } from '@angular/common';

@Component({ templateUrl: 'supplier.component.html' })
export class SupplierComponent implements OnInit {
  supplierForm;
  submitted = false;
  returnUrl: string = '/admin/packagelist';
  public validSupplier: boolean;
  public updateSupplier: boolean = false;
  public errorMessage = '';
  public listErrorMessage: any[];
  private supplier: Supplier;
  public phoneMask: Array<string | RegExp>;
  public cepMask: Array<string | RegExp>;

  public editMode: boolean;
  public citiesList: any = [];
  public idSupplier: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private supplierService: SupplierService,
    private alertService: AlertService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private datePipe: DatePipe
  ) {
    this.phoneMask = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.cepMask = [/[0-9]/, /\d/ ,/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    
    this.activatedRoute.params.subscribe(params => {
      this.idSupplier = params['id'];
      if (this.idSupplier > 0) {
        this.editMode = true;
        this.loadProduct(this.idSupplier);
      } else {
        this.editMode = false;
        // Initialize the register form
        this.onInitForm();
      }
    });

    // Check phone valid
    this.onSetEventsForm();

    this.loading.hideLoading();
  }

  onInitForm() {
    this.validSupplier = true;
    this.supplierForm = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(15), , Validators.maxLength(15)]],
      cpf: ['', [Validators.required]],
      zipCode: ['', [Validators.required, Validators.minLength(8), , Validators.maxLength(8)]],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      complement: [''],
      neighborhood: ['', [Validators.required]],
      cityId: [0, [Validators.required]],
      stateId: [0, [Validators.required]],
      status: [''],
      createdOn: [''],
      updatedOn: ['']
    });
  }
  
  onSetEventsForm() {
    this.f.phone.valueChanges.subscribe(value => {
      var strPhone = value.toString().replace('(', '').replace(')', '').replace('-', '').replace('_', '').replace(' ', '');
      var phoneNumber = parseInt(strPhone);
      if (!(phoneNumber >= 11111111111 && phoneNumber <= 99999999999)) {
        value = phoneNumber.toString();
        this.f.phone.status = "INVALID";
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.supplierForm.controls;
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validSupplier = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.supplierForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validSupplier = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.supplier = new Supplier(this.supplierForm.value);
    //this.registerUser.UrlCallback = this.urlConfirmation;
    
    this.supplierService.save(this.supplier, this.editMode)
    .pipe(first())
    .subscribe(dataReturn => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.validSupplier = true;
        this.router.navigate([this.returnUrl]);
      } else {
        this.validSupplier = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, error => {
      this.validSupplier = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = error == "OK" ? "Erro ao salvar o aluno." : error;
    });
  }

  loadProduct(id) {
    this.loading.showLoading();
    this.supplierService.getById(id)
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validSupplier = true;
        this.supplier = new Supplier(response.data);
        this.setFormByEntity(this.supplier);
      } else {
        this.errorMessage = response.message;
        this.validSupplier = false;
      }
      this.loading.hideLoading();
      }, error => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          this.router.navigate(['/admin/supplierlist']);
        }
      }
    );
  }

  // loadCoursesByProduct(productId) {
  //   this.loading.showLoading();
  //   var courseRequest = productId > 0 
  //     ? this.courseService.getByProductId(productId)
  //     : this.courseService.getall();
      
  //   courseRequest.subscribe(response => {
  //     if (response.typeResponse == TypeResponse.Success) {
  //       this.coursesList = response.data;
  //     } else {
  //       this.coursesList = new Array<Course>();
  //     }
  //     this.loading.hideLoading();
  //     }, error => {
  //       this.coursesList = new Array<Course>();
  //       this.loading.hideLoading();
  //     }
  //   );
  // }

  setFormByEntity(data: any) {
    this.supplierForm = this.formBuilder.group({
      id: new FormControl(data.id),
      name: new FormControl(data.name),
      unity: new FormControl(data.unity),
      value: new FormControl(data.value)
    });
  }

  finishFunction(){
    var a = 'finish';
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }
}