import { DepartmentService, InitAdminService } from '@app/admin/services';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Department } from '@app/admin/model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertService, MessageService } from '@app/shared/services';
import { first } from 'rxjs/operators';
import { TypeResponse } from '@app/shared/enum/TypeResponse';


@Component({ templateUrl: 'department.component.html' })
export class DepartmentComponent implements OnInit {

  private department: Department;

  departmentForm;
  submitted = false;
  returnUrl: string;
  public validDepartment: boolean;
  public errorMessage = '';
  public listErrorMessage: any[];

  public editMode: boolean;
  public departmentId: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private departmentService: DepartmentService,
    private alertService: AlertService,
    private initAdmin: InitAdminService,
    private loading: MessageService
    //private router: Router,
  ) { }
 
  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/departmentlist';
    
    this.activatedRoute.params.subscribe(params => {
      this.departmentId = params['id'];
      if (this.departmentId > 0) {
        this.editMode = true;
        this.loadDepartmentById(this.departmentId);
      } else {
        this.editMode = false;
        // Initialize the register form
        this.onInitForm();
      }
    });

    // Check phone valid
    this.onSetEventsForm();

    this.loading.hideLoading();
  }

  onInitForm() {
    this.validDepartment = true;
    this.departmentForm = this.formBuilder.group({
      name: ['', [Validators.required]]
    });
  }

  onSetEventsForm() {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.departmentForm.controls;
  }

  onSubmit() {
    this.loading.showLoading();
    this.submitted = true;
    this.validDepartment = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.departmentForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validDepartment = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.department = new Department(this.departmentForm.value);
    
    this.departmentService.save(this.department, this.editMode)
    .pipe(first())
    .subscribe(dataReturn => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.validDepartment = true;
        //this.router.navigate([this.returnUrl]);
        this.router.navigate([this.returnUrl]);
      } else {
        this.validDepartment = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, error => {
      this.validDepartment = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = error;
    });
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  loadDepartmentById(id) {
    this.loading.showLoading();
    this.departmentService.getById(id)
    .pipe(first())
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validDepartment = true;
        this.departmentForm = this.formBuilder.group({
          id: new FormControl(response.data.id),
          name: new FormControl(response.data.name),
        });
      } else {
        //this.returnMessage = response.message;
        this.validDepartment = true;
      }
      this.loading.hideLoading();
      }, error => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
      }
    );
  }
}