import { RecurrenceDay, TimeDay } from '@app/shared/enum';

export class CourseAvailablePositionsFilter {
    teacherId: number;
    courseId: number;
    dayOfWeek: RecurrenceDay;
    timeDay: TimeDay;
    hourFilter: Date;
    onlyAvailable: boolean;

    constructor(contentResponse: any, fixDate: boolean = false) {
        if (contentResponse) {
            this.teacherId = parseInt(contentResponse.teacherId);
            this.courseId = parseInt(contentResponse.courseId);
            this.dayOfWeek = parseInt(contentResponse.dayOfWeek);
            this.timeDay = parseInt(contentResponse.timeDay);
            this.onlyAvailable = contentResponse.onlyAvailable;

            this.hourFilter = fixDate 
                ? contentResponse.hourFilter
                : this.getTimeByString(contentResponse.hourFilter);
        } else {
            this.teacherId = 0;
            this.courseId = 0;
            this.onlyAvailable = false;
        }
    }

    getTimeByString(strTime: any) {
        if (strTime != null && strTime.length > 0) {
            let dateTime = new Date();
            dateTime.setMilliseconds(0);
            dateTime.setSeconds(0);
            dateTime.setMinutes(strTime.split(':')[1]);
            dateTime.setHours(strTime.split(':')[0]);
            return dateTime;
        }
    }
}
