import { DatePipe, formatDate } from '@angular/common';
import { Component, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Student,
  WritingTestAppliedStudent,
  WritingTestApply,
} from '@app/admin/model';
import { WritingTestApplyService } from '@app/admin/services';
import { MessageService, UtilService } from '@app/shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import {
  AvgScoreCss,
  AvgScoreLabel,
  TypeResponse,
  WritingTestReviewCss,
  WritingTestReviewLabel,
  WritingTestStatus,
  WritingTestStatusCss,
  WritingTestStatusLabel,
} from '@app/shared/enum';
import { default as swal } from 'sweetalert2';
//import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { DomSanitizer } from '@angular/platform-browser';
import { SafePipe } from '@app/_helpers/safe/safe.pipe';
import { InitStudentService } from '@app/student/services';
import { interval, Subscription } from 'rxjs';

declare var $: { summernote: { ui: any } };

@Component({
  selector: 'writing-test-apply-student',
  templateUrl: './writing-test-apply-student.component.html',
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    DatePipe,
    SafePipe,
  ],
  styleUrls: ['./writing-test-apply-student.component.css'],
})
export class WritingTestApplyStudentComponent implements OnInit {
  @Input('writingTestApplyId') writingTestApplyId: any = 0;
  @Input('previewMode') previewMode: boolean = false;
  private writingTestApply: WritingTestApply;
  private writingTestAppliedStudent: WritingTestAppliedStudent;
  private student: Student;
  public preview: boolean = false;
  public writingTestContent: any = '';
  public writeContentStudentHtml: any = '';

  public htmlSafeContent: any;

  returnUrl = '/student/writingtestlist';
  public errorMessage: string = '';

  text: string = '';
  progress: number = 0;
  notification: string = '';
  notification_class: string = '';
  autosaveInterval = 10000; // 10 segundos
  private subscription: Subscription | undefined;

  public config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']],
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']],
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear',
          ],
        ],
      ],
    },
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear',
        ],
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
      ['customButtons', ['testBtn']],
      ['view', ['fullscreen', 'codeview', 'help']],
    ],
    fontSizes: [
      '8',
      '9',
      '10',
      '11',
      '12',
      '14',
      '18',
      '24',
      '36',
      '44',
      '56',
      '64',
      '76',
      '84',
      '96',
    ],
    fontNames: [
      'Arial',
      'Times New Roman',
      'Inter',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
      'MangCau',
      'BayBuomHep',
      'BaiSau',
      'BaiHoc',
      'CoDien',
      'BucThu',
      'KeChuyen',
      'MayChu',
      'ThoiDai',
      'ThuPhap-Ivy',
      'ThuPhap-ThienAn',
    ],
    buttons: {
      testBtn: customButton,
    },
    codeviewFilter: true,
    codeviewFilterRegex:
      /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
  };

  public validEntity: boolean;

  public writingTestApplyStudentForm: any;
  public editorDisabled = false;
  public submitted = false;

  public contentReviewUpdated: boolean;

  constructor(
    private writingTestApplyService: WritingTestApplyService,
    private initStudent: InitStudentService,
    private formBuilder: FormBuilder,
    private loading: MessageService,
    public datepipe: DatePipe,
    private router: Router,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal
  ) {
    this.initStudent.setConfigPage();
    this.student = this.initStudent.student;
    this.onInitForm();
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.preview = this.previewMode.toString() == 'true';
  }

  private getUserData() {
    this.initStudent
      .getStudentUser()
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.student = response;
          this.getWritingTestApply(this.writingTestApplyId);
          this.loading.hideLoading();
        },
        (error: any) => {
          this.errorMessage = 'Erro ao carregar o calendário.';
          this.loading.hideLoading();
        }
      );
  }

  onInitForm() {
    this.getUserData();
    this.validEntity = true;
    this.writingTestApplyStudentForm = this.formBuilder.group({
      writingTestApplyId: [0, [Validators.required]],
      writeContentStudentHtml: new FormControl('', Validators.required),
      finishDate: new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US')),
      writingTestStatus: [0, [Validators.required]],
      additionalInformation: [''],
    });
  }

  safeHtml(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  private getWritingTestApply(id: any) {
    this.writingTestApplyService
      .getById(id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.designData(response.data);
          this.setFormByEntity(this.writingTestApply);
          this.loading.hideLoading();
        },
        (error: any) => {
          this.errorMessage = 'Erro ao carregar os testes disponíveis.';
          this.loading.hideLoading();
        }
      );
  }

  designData(item: any) {
    let avgScoreCss = AvgScoreCss.get(item.avgScore);
    let avgScoreStr = AvgScoreLabel.get(item.avgScore);
    item.designAvgScore =
      "<span class='badge badge-pill w-100 font-weight-bold " +
      avgScoreCss +
      "'>" +
      avgScoreStr +
      '</span>';

    let writingTestStatusCss = WritingTestStatusCss.get(item.writingTestStatus);
    let writingTestStatusStr = WritingTestStatusLabel.get(
      item.writingTestStatus
    );
    item.designWritingTestStatus =
      "<span class='badge badge-pill w-100 font-weight-bold " +
      writingTestStatusCss +
      "'>" +
      writingTestStatusStr +
      '</span>';

    let writingTestReviewCss = WritingTestReviewCss.get(item.writingTestReview);
    let writingTestReviewLabel = WritingTestReviewLabel.get(
      item.writingTestReview
    );
    item.designWritingTestReview =
      "<span class='badge badge-pill w-100 font-weight-bold " +
      writingTestReviewCss +
      "'>" +
      writingTestReviewLabel +
      '</span>';

    item.writingTestReviewShow =
      item.writingTestStatus == WritingTestStatus.Done;
    if (item.writingTestReviewShow) {
      this.writingTestContent = this.safeHtml(item.contentReviewHtml);
      this.htmlSafeContent = item.contentReviewHtml;
      //this.f.writeContentStudentHtml.value = item.contentReviewHtml;
    } else {
      this.writingTestContent = this.safeHtml(item.writeContentStudentHtml);
      this.htmlSafeContent = item.writeContentStudentHtml;
      //this.writingTestContent = this.safeHtml(item.writeContentStudentHtml);
    }
    this.writingTestApply = item;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.writingTestApplyStudentForm.controls;
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validEntity = true;

    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.writingTestApplyStudentForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validEntity = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.writingTestAppliedStudent = new WritingTestAppliedStudent(
      this.writingTestApplyStudentForm.value
    );

    this.writingTestApplyService
      .writingTestAppliedStudent(
        this.writingTestAppliedStudent.writingTestApplyId,
        this.writingTestAppliedStudent
      )
      .pipe(first())
      .subscribe(
        (dataReturn: any) => {
          if (dataReturn.typeResponse == TypeResponse.Success) {
            this.dismissModal();
            swal({
              type: 'success',
              title: 'Teste de escrita realizado com sucesso!',
              text: 'O professor será notificado na imediatamente.',
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.validEntity = true;
              window.location.reload();
            });
          } else {
            this.validEntity = false;
            this.errorMessage = dataReturn.message;
          }
          this.submitted = false;
          this.loading.hideLoading();
        },
        (error: any) => {
          this.validEntity = false;
          this.submitted = false;
          this.loading.hideLoading();
          this.errorMessage = !error.ok ? 'Erro ao salvar a avaliação.' : error;
        }
      );
  }

  setFormByEntity(data: any) {
    this.validEntity = true;
    this.f.writingTestApplyId.value = data.id;
    this.f.writeContentStudentHtml.value =
      data.writingTestStatus == WritingTestStatus.Done
        ? data.contentReviewHtml
        : data.writeContentStudentHtml;
    this.f.additionalInformation.value = data.additionalInformation;
    this.f.finishDate.value = new Date(
      formatDate(data.finishDate, 'yyyy-MM-dd', 'en-US')
    );
    this.f.writingTestStatus.value = data.writingTestStatus;
    this.writeContentStudentHtml = this.safeHtml(data.writeContentStudentHtml);

    this.f?.writeContentStudentHtml?.valueChanges?.subscribe((change: any) => {
      this.startAutosave();
    });
  }

  public startAutosave(): void {
    if (this.contentReviewUpdated == true) return;

    this.contentReviewUpdated = true;
    const saveInterval = this.autosaveInterval / 100;
    this.subscription = interval(saveInterval).subscribe((n) => {
      this.progress = (n % 100) + 1;
      if (this.progress === 100) {
        this.contentReviewUpdated = false;
        this.stopAutosave();
        this.saveText();
      }
    });
  }

  public stopAutosave(): void {
    this.contentReviewUpdated = false;
    this.progress = 0;
    this.subscription?.unsubscribe();
  }

  saveText(): void {
    if (this.f?.writeContentStudentHtml?.value) {
      this.writingTestApplyService
        .saveTextStudent(this.writingTestApply.id, {
          text: this.f?.writeContentStudentHtml?.value,
        })
        .subscribe(
          (response) => {
            this.stopAutosave();
            this.notification = 'Texto salvo com sucesso!';
            this.notification_class = 'alert-success';
            this.textSaveDone();
          },
          (error) => {
            this.stopAutosave();
            this.notification = 'Erro ao salvar o texto.';
            this.notification_class = 'alert-warning';
            this.textSaveDone();
          }
        );
    } else {
      this.stopAutosave();
      this.notification = 'Erro ao salvar o texto.';
      this.notification_class = 'alert-warning';
      this.textSaveDone();
    }
  }

  textSaveDone(): void {
    setTimeout(() => {
      this.contentReviewUpdated = false;
      this.stopAutosave();
      this.notification = '';
      this.notification_class = '';
    }, 500);
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  public onBlur() {
    console.log('Blur');
  }

  public onDelete(file: any) {
    console.log('Delete file', file.url);
  }

  public summernoteInit(event: any) {
    console.log(event);
  }
}

function customButton(context: any) {
  const ui = $.summernote.ui;
  const button = ui.button({
    contents: '<i class="note-icon-magic"></i> Hello',
    tooltip: 'Custom button',
    container: '.note-editor',
    className: 'note-btn',
    click: function () {
      context.invoke('editor.insertText', 'Hello from test btn!!!');
    },
  });
  return button.render();
}
