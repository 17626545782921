<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/student/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Biblioteca</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div class="row">
    <div class="col-12">
        <div class="card text-left">
            <div class="card-body">
                <h1 class="display-4">Biblioteca de conteúdo</h1>
                <p class="card-text">Nessa seção você encontra os conteúdos para as aulas.</p>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3" *ngIf="showRegularContent">
    <hr>
    <div class="col-12">
        <h1 class="text-primary">Grade 1</h1>
        <div class="card-group">
            <div class="card border m-auto m-lg-0" style="max-width: 18rem;" *ngFor="let item of this.packageContentRegular.series; let i = index">
                <img [src]="(contentPathRegular + item.folderName +'/'+ item.thumbnail)" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">{{item.name}}</h5>
                    <p class="card-text text-justify text-muted">{{item.description}}</p>
                </div>
                <div class="card-footer">
                    <button type="button" class="btn btn-sm btn-primary btn-block waves-effect waves-themed" (click)="showContent(item.item, 1)">
                        Acessar
                        <span class="fal fa-arrow-right mr-1"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3" *ngIf="showKidsContent">
    <hr>
    <div class="col-12">
        <h1 class="text-primary">Grade 2</h1>
        <div class="card-group">
            <div class="card border m-auto m-lg-0" style="max-width: 18rem;" *ngFor="let item of this.packageContentKids.series; let i = index">
                <img [src]="(contentPathKids + item.folderName +'/'+ item.thumbnail)" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">{{item.name}}</h5>
                    <p class="card-text text-justify text-muted">{{item.description}}</p>
                </div>
                <div class="card-footer">
                    <button type="button" class="btn btn-sm btn-primary btn-block waves-effect waves-themed" (click)="showContent(item.item, 0)">
                        Acessar
                        <span class="fal fa-arrow-right mr-1"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <hr>
<div class="row">
    <div class="col-12">
        <h1>Treinamento</h1>
        <div class="card-group">
            <div class="card border m-auto m-lg-0" style="max-width: 18rem;" *ngFor="let item of this.packageContent.series; let i = index">
                <img [src]="(contentPath + item.folderName +'/'+ item.thumbnail)" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">{{item.name}}</h5>
                    <p class="card-text text-justify text-muted">{{item.description}}</p>
                </div>
                <div class="card-footer">
                    <button type="button" class="btn btn-sm btn-primary btn-block waves-effect waves-themed" (click)="showContent(item.item)">
                        Acessar
                        <span class="fal fa-arrow-right mr-1"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div> -->
