import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root'
})
export class LocalService {
 
  constructor(private storageService: StorageService) { }

  // Set the json data to local 
  setValue(key: string, value: any, keepingtoken: boolean) {
    if (keepingtoken) {
      this.storageService.secureLocalStorage.setItem(key, value);
    } else {
      this.storageService.secureSessionStorage.setItem(key, value);
    }
  }

  // Get the json value from local 
  getValue(key: string) {
    var value = this.storageService.secureSessionStorage.getItem(key);
    if (value) {
      return value
    }
    return this.storageService.secureLocalStorage.getItem(key);
  }

  // Clear the local 
  clearStorage() {
    this.storageService.secureSessionStorage.clear();
    return this.storageService.secureLocalStorage.clear();
  }
}
