import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'nps-form',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.css']
})
export class NpsComponent implements OnInit {
  //urlForm = 'https://docs.google.com/forms/d/e/1FAIpQLScwQUwwkXcMJYHrKD2ZylWdT9jKOSPLhCU2HBhUsEKoop-yiA/viewform?embedded=true';

  constructor(private fb: FormBuilder, private http: HttpClient) { }

  ngOnInit(): void {
  }

  done(): void {
  }

  cancel(): void {
  }

  dismissModal(): void {
  }
}
