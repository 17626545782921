import { formatDate } from '@angular/common';
import { TransactionCourse } from '@app/financial/model';
import { Base } from '@app/shared/model';
import { Course, CourseRecurrence, Student, StudentClassesCourse } from '.';

export class StudentCourse extends Base {
    identifier: string;

    student: Student;
    studentId: number;

    course: Course;
    courseId: number;

    googleDriveLink: string;

    startDate: Date;
    endDate: Date;

    installmentFirstDue: Date;

    dueDay: number;
    isPriceCustom: boolean;
    customPriceValue: number;

    termOfUseSent: boolean;
    termOfUseUrl: string;
    confirmTerms: boolean;
    confirmedTermsDatetime: Date;

    transactionCourse: TransactionCourse;
    courseRecurrence: CourseRecurrence;

    studentClassesCourse: Array<StudentClassesCourse>;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.identifier = contentResponse.identifier;

        this.studentId = parseInt(contentResponse.studentId);
        if (contentResponse && contentResponse.studentId > 0) {
            this.student = contentResponse.student;
        }

        this.googleDriveLink = contentResponse.googleDriveLink;

        this.courseId = parseInt(contentResponse.courseId);
        if (contentResponse && contentResponse.courseId > 0) {
            this.course = contentResponse.course;
        }

        if (contentResponse.installmentFirstDue) {
            this.installmentFirstDue = new Date(Date.parse(contentResponse.installmentFirstDue.split('T')[0] + 'GMT-0300'));
            //this.installmentFirstDue = new Date(formatDate(contentResponse.installmentFirstDue + ' GMT-0300', 'yyyy-MM-dd', 'en-US'));
        } else {
            this.installmentFirstDue = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        }

        if (contentResponse.startDate) {
            this.startDate = new Date(Date.parse(contentResponse.startDate.split('T')[0] + 'GMT-0300'));
            //this.startDate = new Date(formatDate(contentResponse.startDate + ' GMT-0300', 'yyyy-MM-dd', 'en-US'));
        } else {
            this.startDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        }

        if (contentResponse.endDate) {
            this.endDate = new Date(Date.parse(contentResponse.endDate.split('T')[0] + 'GMT-0300'));
            //this.endDate = new Date(formatDate(contentResponse.endDate + ' GMT-0300', 'yyyy-MM-dd', 'en-US'));
        } else {
            this.endDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        }

        this.dueDay = parseInt(contentResponse.dueDay);

        this.isPriceCustom = contentResponse.isPriceCustom;
        this.customPriceValue = contentResponse.customPriceValue;

        this.termOfUseSent = contentResponse.termOfUseSent;
        this.termOfUseUrl = contentResponse.termOfUseUrl;
        this.confirmTerms = contentResponse.confirmTerms;
        this.confirmedTermsDatetime = contentResponse.confirmedTermsDatetime;

        if (contentResponse && contentResponse.transactionCourse) {
            this.transactionCourse = contentResponse.transactionCourse;
        }

        if (contentResponse && contentResponse.courseRecurrence) {
            this.courseRecurrence = contentResponse.courseRecurrence;
        }

        if(contentResponse.studentClassesCourse && contentResponse.studentClassesCourse.lenght > 0) {
            this.studentClassesCourse = contentResponse.studentClassesCourse;
        }
    }
}
