<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Cursos</a>
    </li>
    <li class="breadcrumb-item active">Alunos do Curso</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>

<h4>Legenda & Cores</h4>
<div class="demo-v-spacing d-md-none mb-3">
    <h5><span class="badge badge-success p-2"><i class="fal fa-search-plus fs-md"></i></span> Visualizar</h5>
    <h5><span class="badge badge-info p-2"><i class="fal fa-pencil fs-md"></i></span> Editar</h5>
    <h5><span class="badge badge-danger p-2"><i class="fal fa-times fs-md"></i></span> Excluir</h5>
    <h5><span class="badge badge-primary p-2"><i class="fas fa-indent fs-md"></i></span> Pagamentos</h5>
    <h5><span class="badge badge-secondary p-2"><i class="fas fa-calendar-check fs-md"></i></span> Aulas</h5>
    <h5><span class="badge badge-dark p-2"><i class="fas fa-file-alt fs-md"></i></span> Remanejamento de Turma</h5>
</div>
<div class="demo-v-spacing d-none d-md-block mb-3">
    <div class="subheader">
        <div class="subheader-block d-lg-flex align-items-center">
            <div class="flex-column justify-content-center mr-3">
                <a href="javascript:void(0);" class="btn btn-sm btn-success btn-icon position-relative js-waves-off">
                    <i class="fal fa-search-plus fs-md"></i>
                </a>
                Visualizar
            </div>
        </div>
        <div class="subheader-block d-lg-flex align-items-center">
            <div class="flex-column justify-content-center mr-3">
                <a href="javascript:void(0);" class="btn btn-sm btn-info btn-icon position-relative js-waves-off">
                    <i class="fal fa-pencil fs-md"></i>
                </a>
                Editar
            </div>
        </div>
        <div class="subheader-block d-lg-flex align-items-center">
            <div class="flex-column justify-content-center mr-3">
                <a href="javascript:void(0);" class="btn btn-sm btn-danger btn-icon position-relative js-waves-off">
                    <i class="fal fa-times fs-md"></i>
                </a>
                Excluir
            </div>
        </div>
        <div class="subheader-block d-lg-flex align-items-center">
            <div class="flex-column justify-content-center mr-3">
                <a href="javascript:void(0);" class="btn btn-sm btn-primary btn-icon position-relative js-waves-off">
                    <i class="fas fa-indent fs-md"></i>
                </a>
                Pagamentos
            </div>
        </div>
        <div class="subheader-block d-lg-flex align-items-center">
            <div class="flex-column justify-content-center mr-3">
                <a href="javascript:void(0);" class="btn btn-sm btn-secondary btn-icon position-relative js-waves-off">
                    <i class="fas fa-calendar-check fs-md"></i>
                </a>
                Aulas
            </div>
        </div>
        <div class="subheader-block d-lg-flex align-items-center">
            <div class="flex-column justify-content-center mr-3">
                <a href="javascript:void(0);" class="btn btn-sm btn-dark btn-icon position-relative js-waves-off">
                    <i class="fas fa-file-alt fs-md"></i>
                </a>
                Remanejamento de Turma
            </div>
        </div>
    </div>
</div>

<div class="form-group row mb-2">
    <div class="col-xl-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>Lista de alunos vinculados ao curso</h2>
                <br>
                <div class="panel-toolbar ml-2">
                    <button type="button" class="btn btn-link btn-xs text-white mr-2" data-toggle="tooltip" title="Voltar para cursos" [routerLink]="['/admin/courselist']">
                        <i class="fas fa-angle-double-left"></i> Voltar para cursos
                    </button>
                    <br>
                    <button type="button" class="btn btn-success btn-xs" data-toggle="tooltip" title="Incluir aluno" (click)="newStudent(mdStudentCourseAdd);">Matricular</button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="col-sm-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-sm-12" *ngIf="showTable">
                        <managed-table [dataTitle]="" [rows]="(studentCourseList$ | async)" [columns]="columns" [ngTableFiltering]="true" [showFilterRow]="false" [hasFilter]="true" [hasActions]="true"
                            [editEnable]="true" (editClick)="editStudentCourse($event, mdStudentCourseAdd)"
                            [deleteEnable]="true" (deleteClick)="delete($event)"
                            [previewEnable]="true" (previewClick)="showInfoStudentCourse($event, mdInfoStudentCourse)"
                            [auxEnable]="true" [auxIcon]="iconInstallments" [titleAux]="titleInstallments" (auxClick)="viewInstallmentsStudentCourse($event, mdInstallmentsStudent)"
                            [auxEnable2]="true" [auxIcon2]="iconClasses" [titleAux2]="titleClasses" (auxClick2)="viewClassesStudentCourse($event, mdClassesStudentCourse)"
                            [auxEnable3]="true" [auxIcon3]="iconRelocation" [titleAux3]="titleRelocation" (auxClick3)="studentCourseRelocationAdd($event, mdStudentCourseRelocationAdd)"
                            [auxEnable4]="true" [auxIcon4]="iconRegistrationExtension" [titleAux4]="titleRegistrationExtension" (auxClick4)="registrationExtension($event, mdRegistrationExtension)">
                        </managed-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #mdInstallmentsStudent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Pagamentos do Aluno</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="alert alert-info mb-0" role="alert">Em breve teremos aqui os pagamentos do aluno.</div>
        <!-- <app-installments studentCourseId="{{studentCourseId}}"></app-installments> -->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Fechar</button>
    </div>
</ng-template>

<ng-template #mdClassesStudentCourse let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Aulas do Aluno</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <studentclassescourse-list studentCourseIdentifier="{{studentCourseIdentifier}}"></studentclassescourse-list>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Fechar</button>
    </div>
</ng-template>

<ng-template #mdInfoStudentCourse let-c="close" let-d="dismiss">
    <studentcourse-info courseId="{{courseId}}" studentCourseIdentifier="{{studentCourseIdentifier}}" editMode="{{studentCourseEditMode.toString()}}"></studentcourse-info>
</ng-template>

<ng-template #mdCourseselect let-c="close" let-d="dismiss">
    <app-course-select></app-course-select>
</ng-template>

<ng-template #mdStudentCourseAdd let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Nova matrícula</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <studentcourse courseId="{{courseId}}" studentCourseIdentifier="{{studentCourseIdentifier}}" editMode="{{studentCourseEditMode}}"></studentcourse>
    </div>
</ng-template>

<ng-template #mdStudentCourseRelocationAdd let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Remanejamento de Matrícula</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <studentCourseRelocation-add studentCourseIdentifier="{{studentCourseIdentifier}}"></studentCourseRelocation-add>
    </div>
</ng-template>

<ng-template #mdRegistrationExtension let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">Prorrogação de Matrícula</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <div class="col mb-2">
                <label class="form-label" for="startDate">Nova data de término <span class="text-danger">*</span> </label>
                <input type="date" id="startDate" [(ngModel)]="newEndDate" class="form-control" 
                    placeholder="Prorrogação da matrícula" required (change)="onEndDateChange($event.target.value)">
            </div>
        </div>
        <hr>
        <div class="form-row mb-3 mt-3">
            <div class="col-md-12 ml-auto text-right">
                <div class="btn-group btn-panel">
                    <button id="js-login-btn" class="btn btn-primary" (click)="onSaveRegistrationExtension()" [disabled]="submittedRegExt">
                        <span class="spinner-border spinner-border-sm mr-1" *ngIf="submittedRegExt"></span>
                        Salvar
                    </button>
                    <button id="js-cancel-btn" class="btn btn-default" (click)="onCancel()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>