import { UserModule } from './userModule';
import { ModuleType } from './../../shared/enum/ModuleType';
import { Base } from '@app/shared/model';

export class Module extends Base {
    name: string;
    moduleType: ModuleType;
    userModules: Array<UserModule>;
    
    constructor(contentResponse: any) {
        super(contentResponse);
        
        this.name = contentResponse.name;
        this.moduleType = contentResponse.moduleType;

        if(contentResponse.userModules && contentResponse.userModules.lenght > 0) {
            this.userModules = contentResponse.userModules;
        }
    }
}
