import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { Teacher, TeacherWithAvailability } from '@app/admin/model';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TypeResponse } from '@app/shared/enum';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {
  private route: string = "admin";
  private controller: string = "teacher";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  all(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  get(id: number): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }

  getWithoutMe(id: number): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getWithoutMe/${id}`);
  }

  getByUserId(userId: any): any {
    let params = new HttpParams();
    params = params.append('userId', userId.toString());
    let call = this.http.get<ResponseResult>(`${this.endpointPrefix}/getByUserId`, {params: params});

    return call.pipe(map(response => {
      if (response.typeResponse == TypeResponse.Success) {
        return response.data;
      } else {
        return null;
      }
    }, (error: any) => {
      return null;
    }));
  }
  
  save(teacher: Teacher, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/update`, teacher);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/add`, teacher);
    }
  }

  getAvailability(teacherId: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/availability/${teacherId}`);
  }

  setAvailability(teacherId: any, availability: TeacherWithAvailability): any {
    return this.http.post<ResponseResult>(`${this.endpointPrefix}/availability/${teacherId}`, availability);
  }

  remove(id: any) {
    let params = new HttpParams();
    params = params.append('id', id);
    var call = this.http.delete<ResponseResult>(`${this.endpointPrefix}/remove`, {params: params});

    return call.pipe(map((response: any) => {
        return response;
    }), catchError(error => {
      if (error.status == 400) {
          if (error.errors) {
              return Observable.of(error.errors);
          }
      }
      return error;
    }));
  }

  checkTeacherByCpf(cpf: string): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/checkTeacher/${cpf}`);
  }
}
