<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Testes de Escrita</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div class="row">
    <div class="col-xl-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>Lista de Testes de Escrita do Aluno</h2>
            </div>
            <div class="panel-container show">
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="col-sm-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-sm-12" *ngIf="showTable">
                        <managed-table [dataTitle]="" [rows]="gridToDoStudentList" [columns]="columns" [hasActions]="true" [hasFilter]="true" [ngTableFiltering]="true"
                            [previewEnable]="true" (previewClick)="preview($event, mdApplyWritingTest)" [deleteEnable]="false" [editEnable]="false"
                            [auxEnable]="true" [auxIcon]="iconApplyTest" [titleAux]="titleApplyTest" (auxClick)="toApplyStudentTest($event, mdApplyWritingTest)">
                        </managed-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #mdApplyWritingTest let-c="close" let-d="dismiss">
    <writing-test-apply-student writingTestApplyId="{{writingTestApplyId}}" previewMode="{{writingTestApplyPreviewMode}}"></writing-test-apply-student>
</ng-template>
