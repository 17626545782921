import { Injectable } from '@angular/core';

// Helpers
import pageSettings from '@helpers/page-settings';

@Injectable({ providedIn: 'root' })
export class InitAuthService {
  pageSettings = pageSettings;

  setConfigPage() {
    this.pageSettings.pageEmpty = true;
    this.pageSettings.pageSidebar = false;
    this.pageSettings.pageContentBgTransparent = true;
    this.pageSettings.pageContentBrandGradient = true;
  }
}
