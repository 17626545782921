import { Component, Input, OnInit } from '@angular/core';
import { default as swal } from 'sweetalert2';
import { ContactBySiteService } from '@app/marketing/services/contactBySite.service';
import { CourseTypeCss, CourseTypeLabel, TypeResponse } from '@app/shared/enum';
import {
  AlertService,
  MessageService,
  UtilService,
} from '@app/shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import {
  StatusCrm,
  StatusCrmCss,
  StatusCrmLabel,
} from '@app/shared/enum/StatusCrm';
import { first } from 'rxjs/operators';
import {
  ContactBySite,
  Course,
  StudentCoursePreReg,
  User,
} from '@app/admin/model';
import { Router } from '@angular/router';
import { CourseService } from '@app/admin/services';
import { DatePipe, formatDate } from '@angular/common';
import { StudentCoursePreRegService } from '@app/admin/services/studentCoursePreReg.service';

@Component({
  selector: 'contact-pre-register',
  templateUrl: './pre-register.component.html',
  styleUrls: ['./pre-register.component.css'],
})
export class ContactPreRegisterComponent implements OnInit {
  @Input('contactId') contactId: any;

  changeStatusSuccess: any;
  public errorMessage: string = '';
  public submitted: boolean = false;

  public needPreReg: boolean = false;
  public setPreRegForm: boolean = false;
  public studentCoursePreReg: StudentCoursePreReg;
  public validStudentCoursePreReg: boolean = true;
  //public errorMessagePreReg: string = '';

  public flagSearchContact = false;
  public validPreRegContact: boolean = true;
  public options: Options = {
    multiple: false,
    closeOnSelect: true,
    width: '300',
  };
  public errors: any = Array<string>();

  public contactData: ContactBySite;
  public statusCrmDataList: Array<any>;
  public statusCrmList: any = [];

  public selectedCourse: any;
  public courseId: number = 0;
  public course: Course;
  public courseList: any = [];
  public selectCourseList: any[];
  public validCourse: boolean = true;
  public validCourseListMessage: any[];
  public flagCourseSelected: boolean = false;

  private reviewCourseUrl: string;

  public newStatusCrm: any;
  public newStatusCrmId: number = 0;
  public newStatusCrmStr: string;
  public newStatusCrmCss: string;
  public comments: string = '';

  public isPriceCustom: boolean = false;
  public customPriceValue: number = 0;
  public dueDay: number;
  public installmentFirstDueFix: any;
  public installmentFirstDue: any;
  public startDate: any;
  public startDateFix: any;
  public endDate: any;
  public endDateFix: any;
  public showTable: boolean = true;

  public showAvailableCourses: boolean = true;
  public errorMessageAvailableCourses: string = '';

  public userId: any;
  public user: User;

  public flagCustomPrice: boolean = false;
  public numberOfMonth: number = 1;

  public preRegDataList: any[];
  //public returnMessagePreReg: string = '';

  constructor(
    private studentCoursePreRegService: StudentCoursePreRegService,
    private contactBySiteService: ContactBySiteService,
    private utilService: UtilService,
    private courseService: CourseService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private loading: MessageService,
    private router: Router
  ) {
    this.onLoadCourses();
    this.reviewCourseUrl = '/admin/course';
    this.studentCoursePreReg = new StudentCoursePreReg(null);
    this.installmentFirstDue = new Date(
      formatDate(new Date(), 'yyyy-MM-dd', 'en-US')
    );
    this.startDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
    this.endDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
    this.dueDay = 1;
  }

  ngOnInit(): void {
    if (this.contactId != null && this.contactId > 0) {
      this.getActualContactData(this.contactId);
      this.loadPreRegister(this.contactId);
    } else {
      this.alertService.showWarning(
        'Não foi possível carregar os dados do contato!',
        'Dados do contato'
      );
      return this.done();
    }
  }

  getActualContactData(id: Number) {
    this.loading.showLoading();
    this.flagSearchContact = true;
    this.contactBySiteService.getById(id.toString()).subscribe(
      (response: { typeResponse: TypeResponse; data: any }) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.contactData = new ContactBySite(response.data);
          this.studentCoursePreReg.contactBySiteId = this.contactData.id;
        } else {
          this.contactData = this.contactData;
        }
        this.loading.hideLoading();
        this.flagSearchContact = false;
      },
      () => {
        this.loading.hideLoading();
        this.flagSearchContact = false;
      }
    );
  }

  onSetCustomPrice(input: any): void {
    this.flagCustomPrice = input.currentTarget.checked;
  }

  loadPreRegister(contactBySiteId: any) {
    this.loading.showLoading();
    this.studentCoursePreRegService
      .getByContactBySiteId(contactBySiteId)
      .subscribe(
        (response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.showTable = true;
            this.preRegDataList = response.data;
            this.customDesignListCourse(this.preRegDataList);
            this.errorMessage = '';
          } else {
            this.showTable = false;
            this.errorMessage = response.message;
          }
          this.loading.hideLoading();
        },
        (error) => {
          this.errorMessage = 'Erro ao carregar os dados de pré-matrícula.';
          this.loading.hideLoading();
          this.showTable = false;
        }
      );
  }

  customDesignListCourse(lista: any[]) {
    lista.forEach(function (e) {
      if (e.course.courseType >= 0) {
        let courseTypeCss = CourseTypeCss.get(e.course.courseType);
        let courseTypeStr = CourseTypeLabel.get(e.course.courseType);
        e.designCourseType =
          "<span class='badge badge-pill w-100 " +
          courseTypeCss +
          "'>" +
          courseTypeStr +
          '</span>';
      }
    });
    this.preRegDataList = lista;
  }

  checkPreRegByNewStatus(newStatus: any): boolean {
    return newStatus == StatusCrm.DoneBusiness;
  }

  onSetPreRegForm(input: any): void {
    this.setPreRegForm = input.currentTarget.checked;
  }

  onCourseChange(value: any) {
    if (value && parseInt(value) > 0) {
      this.courseService.getById(value).subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.courseId = parseInt(value);
          this.course = response.data;
          this.flagCourseSelected = true;
        } else {
          this.course = new Course(null);
          this.flagCourseSelected = false;
        }
      });
    }
  }

  onLoadCourses() {
    this.courseService
      .list(true)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.courseList = response.data;
            this.getInputCourseList(this.courseList);
            this.showAvailableCourses = true;
            this.errorMessageAvailableCourses = '';
          } else {
            this.showAvailableCourses = false;
            this.courseList = new Array<Course>();
            this.errorMessageAvailableCourses = response.message;
          }
        },
        (error: any) => {
          this.showAvailableCourses = false;
          this.courseList = new Array<Course>();
          this.errorMessageAvailableCourses =
            'Erro ao consultar as informações de turmas.';
        }
      );
  }

  designCourseType(lista: any[]) {
    lista.forEach(function (e) {
      if (e.courseType >= 0) {
        let courseTypeCss = CourseTypeCss.get(e.courseType);
        let courseTypeStr = CourseTypeLabel.get(e.courseType);
        e.designCourseType =
          "<span class='badge badge-pill w-100 " +
          courseTypeCss +
          "'>" +
          courseTypeStr +
          '</span>';
      }
    });
    this.courseList = lista;
  }

  getInputCourseList(data: Array<any>) {
    this.selectCourseList = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let item = {
          id: data[i].id.toString(),
          text: data[i].id.toString() + ' | ' + data[i].name, // + " | " + data[i].email,
        };
        this.selectCourseList.push(item);
      }
    }
  }

  onReviewCourse(): void {
    this.router.navigate([this.reviewCourseUrl, { id: this.courseId }]);
  }

  checkValidationDates() {
    if (this.endDate && this.startDate) {
      let endDate = new Date(this.endDate + 'GMT-0300');
      let startDate = new Date(this.startDate + 'GMT-0300');
      let checkValue = endDate.getTime() - startDate.getTime();

      if (checkValue > 0) {
        this.validStudentCoursePreReg = true;
        this.errorMessage = '';
      } else {
        this.validStudentCoursePreReg = false;
        this.errorMessage = 'As datas informadas são inválidas.';
      }
    } else {
      this.validStudentCoursePreReg = true;
      this.errorMessage = '';
    }
  }

  done() {
    this.loading.showLoading();
    this.submitted = true;
    this.validPreRegContact = true;

    this.checkValidationDates();
    if (this.errorMessage) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validPreRegContact = false;
      this.validStudentCoursePreReg = false;
      this.errorMessage = this.errorMessage;
      return;
    } else {
      this.studentCoursePreReg.startDate = new Date(
        this.startDate + 'GMT-0300'
      );
      this.studentCoursePreReg.endDate = new Date(this.endDate + 'GMT-0300');
      this.studentCoursePreReg.installmentFirstDue = new Date(
        this.installmentFirstDue + 'GMT-0300'
      );
      this.studentCoursePreReg.dueDay = this.dueDay;
      this.studentCoursePreReg.courseId = this.courseId;
      this.studentCoursePreReg.studentId = null;
      this.studentCoursePreReg.isPriceCustom = this.isPriceCustom;
      this.studentCoursePreReg.customPriceValue = this.customPriceValue;
      this.studentCoursePreReg.userId =
        this.contactData.userId > 0 ? this.contactData.userId : null;
    }

    this.studentCoursePreRegService
      .save(this.studentCoursePreReg)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.finishChange(response.message);
          } else if (response.typeResponse == TypeResponse.Alert) {
            this.warningChange(response.message);
          } else {
            this.errorChange(response.message);
          }
        },
        (error: any) => {
          if (error.status == 400) {
            this.errorChange(
              'Erro ao salvar a pré-matrícula. Certifique que o usuário já está cadastrado.'
            );
          } else {
            this.errorChange(error.message);
          }
        }
      );
  }

  onMonthCalcChange(date: any) {
    this.numberOfMonth = 1;
    let datesObj = this.getTotalDates();
    if (datesObj) {
      this.numberOfMonth = datesObj.months;
    }
  }

  getTotalDates() {
    if (this.endDate && this.startDate) {
      let endDate = new Date(this.endDate + 'GMT-0300');
      let startDate = new Date(this.startDate + 'GMT-0300');
      let checkValue = endDate.getTime() - startDate.getTime();
      let months = this.utilService.DifferenceInMonths(startDate, endDate);
      return {
        startDate,
        endDate,
        months,
        checkValue,
      };
    }
    return null;
  }

  errorChange(error: string) {
    if (error) {
      this.errorMessage = error;
    } else {
      this.errorMessage = 'Por favor, selecione um status válido.';
    }

    this.validPreRegContact = false;
    this.submitted = false;
    this.loading.hideLoading();
  }

  finishChange(text: string) {
    this.dismissModal();
    this.loading.hideLoading();
    swal({
      type: 'success',
      title: text,
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      window.location.reload();
    });
  }

  warningChange(text: any) {
    this.dismissModal();
    this.loading.hideLoading();
    swal({
      type: 'warning',
      title: 'Falha ao atualizar os dados!',
      text: text,
      showConfirmButton: true,
      //timer: 1500
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  cancel() {
    this.dismissModal();
  }

  setStartDate(value: any) {
    //this.startDate = new Date(formatDate(value, 'yyyy-MM-dd', 'en-US'));
  }

  delete(target: any): void {
    this.dismissModal();
    swal({
      title: 'Excluir Pré-Matrícula',
      html:
        'Confirma a exclusão da pré-matrícula?<br>' +
        'Ao confirmar a Pré-Matrícula será excluída permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.studentCoursePreRegService.remove(target.id).subscribe(
            (response) => {
              this.showTable = false;
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.loading.hideLoading();
                  window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir a Pré-Matrícula!',
                  text: response.message,
                  showConfirmButton: true,
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir a Pré-Matrícula.',
                  text: response.message,
                  showConfirmButton: true,
                });
              }
              this.loadPreRegister(this.contactId);
            },
            (error) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir a Pré-Matrícula.',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir Pré-Matrícula.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  formatCurrency(currency: any): string {
    if (currency) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(currency);
    }
    return '';
  }

  updateDateStartDate(event: any) {
    this.startDateFix = new DatePipe('pt-BR').transform(
      event.target.value,
      'yyyy-MM-dd'
    );
    if (this.startDateFix) {
      this.startDate = new Date(this.startDateFix);
      this.onMonthCalcChange(this.startDateFix);
    }
  }

  updateDateEndDate(event: any) {
    this.endDateFix = new DatePipe('pt-BR').transform(
      event.target.value,
      'yyyy-MM-dd'
    );
    if (this.endDateFix) {
      this.endDate = new Date(this.endDateFix);
      this.onMonthCalcChange(this.endDateFix);
    }
  }

  updateDateInstallmentFirstDue(event: any) {
    this.installmentFirstDueFix = new DatePipe('pt-BR').transform(
      event.target.value,
      'yyyy-MM-dd'
    );
    if (this.installmentFirstDueFix) {
      this.installmentFirstDue = new Date(this.installmentFirstDueFix);
      this.onMonthCalcChange(this.installmentFirstDueFix);
    }
  }
}
