export class Register {
    id: number;
    firstName: string;
    lastName: string;
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    phone: string;
    terms: boolean;
    randomPwd: boolean;
    newsletter: boolean;
    UrlCallback: string;
    moduleType: number;
    userGuid: string;
    userKids: boolean = false;
    foreignPerson: boolean = false;
    roles: any;
    statusControlCrm: string;

    getDefaultPassword(): string {
        return 'SaPk3@MzuA';
    }

    new(contentResponse: any): Register {
        var register = new Register();

        this.id = contentResponse.id;
        this.name = contentResponse.name;
        this.firstName = contentResponse.firstName;
        this.lastName = contentResponse.lastName;
        this.email = contentResponse.email;
        this.password = contentResponse.password;
        this.confirmPassword = contentResponse.confirmPassword;
        this.phone = contentResponse.phone;
        this.terms = contentResponse.terms;
        this.newsletter = contentResponse.newsletter;
        this.UrlCallback = contentResponse.UrlCallback;
        this.randomPwd = contentResponse.randomPwd;
        this.userKids = contentResponse.userKids;
        this.foreignPerson = contentResponse.foreignPerson;
        this.userGuid = contentResponse.userId;
        this.moduleType = parseInt(contentResponse.moduleType);
        this.roles = contentResponse.roles;
        this.statusControlCrm = contentResponse.statusControlCrm;
        return register;
    }
}
