import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { AccountService } from '@app/auth/services';
import { AlertService } from '@app/shared/services'
import { ResetPassword } from '@app/auth/model/resetPassword';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';

@Component({ templateUrl: './reset-password.component.html' })
export class ResetPasswordComponent implements OnInit {
  confirm: any;
  resetSuccess: any;
  returnUrl: string;
  private resetPassword: ResetPassword;
  public validResetPassword: boolean;
  public errorMessage = '';
  public listErrorMessage: any[];
  submitted = false;
  resetPasswordForm;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private alertService: AlertService,
    private loading: MessageService
  ) {
    this.confirm = false;
  }

  ngOnInit(): void {
    this.confirm = false;
    this.resetSuccess = false;
    this.validResetPassword = true;
    this.errorMessage = '';
    this.listErrorMessage = [];
    let params: any = this.activatedRoute.snapshot.queryParams;
    if (params) {
      this.resetPassword = new ResetPassword({
        userid: params['userid'], 
        code: params['code']
      });
    }
    this.returnUrl = '/auth/login';
    this.onConfirmation();
  }

  onInitForm(data: any) {
    if (data) {
      this.resetPasswordForm = this.formBuilder.group({
        userid: new FormControl(data.userid),
        email: new FormControl(data.email),
        code: new FormControl(data.code),
        password: ['', [Validators.required, Validators.minLength(8), , Validators.maxLength(20)]],
        confirmpassword: ['', [Validators.required, Validators.minLength(8), , Validators.maxLength(20)]]
      }, {validator: this.checkIfMatchingPasswords('password', 'confirmpassword')});
    } else {
      this.validResetPassword = true;
      this.resetPasswordForm = this.formBuilder.group({
        userid: [0, [Validators.required]],
        email: ['', [Validators.required]],
        code: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(8), , Validators.maxLength(20)]],
        confirmpassword: ['', [Validators.required, Validators.minLength(8), , Validators.maxLength(20)]]
      }, {validator: this.checkIfMatchingPasswords('password', 'confirmpassword')});
    }
  }

  onConfirmation() {
    this.submitted = true;
    this.loading.showLoading();
    this.accountService.checkResetPassword(this.resetPassword)
    .subscribe(
      dataReturn => {
        if (dataReturn.typeResponse == TypeResponse.Success) {
          this.confirm = true;
          this.resetPassword = new ResetPassword(dataReturn.data);
          this.onInitForm(this.resetPassword);
          this.validResetPassword = true;
        } else {
          this.confirm = false;
          this.validResetPassword = false;
          this.errorMessage = dataReturn.message;
          if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
            this.listErrorMessage = [];
            for (var i = 0; i <= dataReturn.data.errors.length; i++) {
              this.listErrorMessage.push(dataReturn.data.errors[i].Description);
            }
          }
        }
        this.submitted = false;
        this.loading.hideLoading();
        return;
      }, error => {
        this.submitted = false;
        this.loading.hideLoading();
        this.errorMessage = error;
      });
  };

  // convenience getter for easy access to form fields
  get f() {
    return this.resetPasswordForm.controls;
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
          passwordConfirmationInput = group.controls[passwordConfirmationKey];

      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true})
      } else {
          return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  onSubmit() {
    this.loading.showLoading();
    this.submitted = true;

    // Errors Cleaning
    this.errorMessage = '';
    this.validResetPassword = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validResetPassword = false;
      return;
    }
    
    this.submitted = true;

    //resetPassword(password, confirmPassword, email, userId, code) {
    this.accountService.resetPassword(this.resetPasswordForm.value.password, this.resetPasswordForm.value.confirmpassword, this.resetPasswordForm.value.email, this.resetPasswordForm.value.userid, this.resetPasswordForm.value.code)
    .pipe(first())
    .subscribe(dataReturn => {
      if (dataReturn.resetSuccess) {
        this.resetSuccess = true;
        this.errorMessage = '';
        //this.router.navigate([this.returnUrl]);
      } else {
        this.resetSuccess = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.setMessageErrorList(dataReturn.data.errors);
        }
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, error => {
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = error;
    });
  }

  goForget() {
    this.router.navigate(['/auth/forget']);
  }
  
  setMessageErrorList(listErrors: any): void {
    this.validResetPassword = false;
    this.listErrorMessage = new Array<string>();
    for (var i = 0; i < listErrors.length; i++) {
      this.listErrorMessage.push(listErrors[i].description);
    }
  }
}
