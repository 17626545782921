<div id="jitsi-iframe"></div>

<!--For Custom Controls-->
<div class="item-center">
    <span>Custom Controls</span>
</div>
<div class="item-center"> 
    <i (click)="executeCommand('toggleAudio')" class="fas fa-2x grey-color" [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'" aria-hidden="true" title="Mute / Unmute"></i>
    <i (click)="executeCommand('hangup')" class="fas fa-phone-slash fa-2x red-color" aria-hidden="true" title="Leave"></i>
    <i (click)="executeCommand('toggleVideo')" class="fas fa-2x grey-color" [ngClass]="isVideoMuted ? 'fa-video-slash' : 'fa-video'" aria-hidden="true" title="Start / Stop camera"></i>
    <i (click)="executeCommand('toggleShareScreen')" class="fas fa-film fa-2x grey-color" aria-hidden="true" title="Share your screen"></i>
</div>


<!-- <div id="jitsi-iframe"></div> -->
<!-- <div id="jaas-container"></div> -->
<!-- <div class="item-center">
  <span>Custom Controls</span>
</div>
<div class="item-center">
  <span>&nbsp;&nbsp;</span>
  <i
  (click)="executeCommand('toggleAudio')"
  class="fas fa-2x grey-color"
  [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'"
  aria-hidden="true"
  title="Mute / Unmute"
  >mover a otra sala</i
>

  <i
    (click)="executeCommand('toggleAudio')"
    class="fas fa-2x grey-color"
    [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'"
    aria-hidden="true"
    title="Mute / Unmute"
    >toggleAudio</i
  >
  <i
    (click)="executeCommand('hangup')"
    class="fas fa-phone-slash fa-2x red-color"
    aria-hidden="true"
    title="Leave"
    >hangup</i
  >
  <i
    (click)="executeCommand('toggleVideo')"
    class="fas fa-2x grey-color"
    [ngClass]="isVideoMuted ? 'fa-video-slash' : 'fa-video'"
    aria-hidden="true"
    title="Start / Stop camera"
    >toggleVideo</i
  >
  <i
    (click)="executeCommand('toggleShareScreen')"
    class="fas fa-film fa-2x grey-color"
    aria-hidden="true"
    title="Share your screen"
    >toggleShareScreen</i
  > 
</div>-->