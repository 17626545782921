import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { StudentCourse, StudentCourseAdd } from '@app/admin/model';
import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentCourseRelocation } from '../model/studentCourseRelocation';
@Injectable({
  providedIn: 'root'
})
export class StudentCourseService {
  private route: string = "admin";
  private controller: string = "studentCourse";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getall(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getById(id: string): any {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getById`, {params: params});
  }

  getByCourseId(courseId: string): any {
    let params = new HttpParams();
    params = params.append('courseId', courseId);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getByCourseId`, {params: params});
  }

  getResultGridByCourseId(courseId: string): any {
    let params = new HttpParams();
    params = params.append('courseId', courseId);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getResultGridByCourseId`, {params: params});
  }

  getInfo(identifier: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/info/${identifier}`);
  }
  
  getStudentsNotInCourseId(courseId: string): any {
    let params = new HttpParams();
    params = params.append('courseId', courseId);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getStudentsNotInCourseId`, {params: params});
  }
  
  remove(id): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.delete<ResponseResult>(`${this.endpointPrefix}/remove`, {params: params});
  }
  
  save(studentCourse: StudentCourse, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/update`, studentCourse);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/add`, studentCourse);
    }
  }

  relocation(studentCourseRelocation: StudentCourseRelocation): any {
    return this.http.post<ResponseResult>(`${this.endpointPrefix}/relocation`, studentCourseRelocation);
  }

  saveByStudentIdCourseId(studentIdCourseId: any, studentCourseAdd: StudentCourseAdd, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/updateByStudentIdCourseId/${studentIdCourseId}/`, studentCourseAdd);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/addByStudentIdCourseId`, studentCourseAdd);
    }
  }

  getLinksByStudentId(studentId: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/links/${studentId}`);
  }

  setNewLink(id: string, studentCourseNewLink: any): any {
    return this.http.post<ResponseResult>(`${this.endpointPrefix}/newLink/${id}`, studentCourseNewLink);
  }

  newEndDate(studentCourseId: any, newEndDate: string): any {
    return this.http.post<ResponseResult>(`${this.endpointPrefix}/newEndDate/${studentCourseId}/${newEndDate}`, null);
  }
}
