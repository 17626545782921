import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";  

// Components
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { LockedComponent } from './components/locked/locked.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ForgetComponent } from './components/forget/forget.component';
import { StorageService } from './services';

// Modules
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';

// Helpers
import { TextMaskModule } from '../_helpers/mask/text.mask';
import pageSettings from '@helpers/page-settings';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ModuleChangeComponent } from './components/module-change/module-change.component';

@NgModule({
  declarations: [
    LoginComponent, 
    RegisterComponent,
    LockedComponent, 
    ConfirmationComponent, 
    ForgetComponent, 
    ResetPasswordComponent, 
    ModuleChangeComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    AuthRoutingModule,
    TextMaskModule,
    HttpClientModule
  ],
  providers: [
    StorageService
  ]
})

export class AuthModule {
  pageSettings = pageSettings;

  constructor() {
    this.pageSettings.pageEmpty = true;
    this.pageSettings.pageContentBgTransparent = true;
    this.pageSettings.pageContentBrandGradient = true;
  }
}
