export const environment = {
  name: 'prd',
  production: true,
  //urlAPI:  'https://api.englishcoffee.com.br/api',
  //urlAPI: 'https://al3imat3s5.execute-api.us-east-1.amazonaws.com/v/api',
  urlAPI: 'https://api-ec.englishcoffee.com.br/api',
  urlLocal: 'https://admin.englishcoffee.com.br',
  contentFiles: 'assets/content',
  urlConfirmation: 'https://admin.englishcoffee.com.br/#/auth/confirmation',
  sentryDsn:
    'https://6cdb0da56440439f80035db48fe7c153@o4505319674019840.ingest.sentry.io/4505319797555200',
  secretKeyEncrypt: 'gJ2RGMemfZkvvvsyw8uNQ8vFGoAM9oaf',
  meetDomain: 'meet.englishcoffee.com.br',
};
