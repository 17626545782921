import { Module } from '.';
import { Base } from '@app/shared/model';

export class RoleGroup extends Base {
    moduleId: number;
    module: Module;
    name: string;
    roles: any;

    constructor(contentResponse: any) {
        super(contentResponse);
        this.moduleId = contentResponse.moduleId;
        this.module = contentResponse.module;
        this.name = contentResponse.name;
        this.roles = contentResponse.roles;
    }
}
