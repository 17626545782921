import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private router: Router) {}

  findObjectIndex(list: any[], obj: any, key: string): number {
    return _.findIndex(list, function (item) {
      return obj[key] === item[key];
    });
  }

  findObjectByQuery(list: any[], key: string, query: string): any {
    return _.find(list, function (item) {
      return item[key].toLowerCase() === query.toLowerCase();
    });
  }

  menuClick(url: any): void {
    if (url) {
      this.router.navigate([url]);
    }
    return;
  }

  isValidCPF(cpf: any) {
    if (typeof cpf !== 'string') return false;
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
    cpf = cpf.split('');
    const validator = cpf
      .filter(
        (digit: any, index: number, array: string | any[]) =>
          index >= array.length - 2 && digit
      )
      .map((el: string | number) => +el);
    const toValidate = (pop: number) =>
      cpf
        .filter(
          (digit: any, index: number, array: string | any[]) =>
            index < array.length - pop && digit
        )
        .map((el: string | number) => +el);
    const rest = (count: number, pop: number) =>
      ((toValidate(pop).reduce(
        (soma: number, el: number, i: number) => soma + el * (count - i),
        0
      ) *
        10) %
        11) %
      10;
    return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
  }

  getSelectOptionDataList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      let arrayList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id,
          text: data[i].id + ' | ' + data[i].name,
          //text: data[i].id + " | " + data[i].name + " | " + data[i].email,
          // additional: {
          //   email: data[i].email
          // }
        };
        arrayList.push(element);
      }
      return arrayList;
    }
    return new Array<any>();
  }

  getArrayFields(input: any, field: string) {
    return input.map(function (o: any) {
      return o[field];
    });
  }

  // Turn enum into array
  ToArray(enumme: any, enumLabel: Map<number, string>) {
    const list = Object.keys(enumme).filter((e) => !isNaN(+e));

    let enumArray: Array<any> = [];
    list.forEach((a) => {
      enumArray.push({
        value: parseInt(a),
        name: enumLabel.get(parseInt(a)),
      });
    });
    return enumArray;
  }

  RandomNumber(min: number, max: number, abs: boolean = true) {
    return abs
      ? Math.round(Math.random() * (max - min) + min)
      : Math.random() * (max - min) + min;
  }

  DifferenceInMonths(startDate: Date, endDate: Date) {
    const monthDiff = endDate.getMonth() - startDate.getMonth();
    const yearDiff = endDate.getFullYear() - startDate.getFullYear();
    return monthDiff + yearDiff * 12;
  }

  getOnlyDateDefaultTimeZone(dateStr: string): Date {
    if (dateStr) {
      return new Date((dateStr += ' 00:00:00 -03:00'));
    }
    return null;
  }

  generateChartColors(numColors: number): string[] {
    const palette: string[] = [
      '#2196F3', // Azul
      '#4CAF50', // Verde
      '#FFC107', // Amarelo
      '#FF5722', // Laranja
      '#9C27B0', // Roxo
      '#607D8B', // Cinza Azulado
      '#00BCD4', // Azul Ciano
      '#F44336', // Vermelho
      '#673AB7', // Índigo
      '#8BC34A', // Verde Lima
      '#FFEB3B', // Amarelo Limão
      '#FF9800', // Laranja Padrão
      '#795548', // Marrom
      '#CDDC39', // Lima
      '#3F51B5', // Azul Escuro
      '#E91E63', // Rosa
      '#9E9E9E', // Cinza
      '#FF5722', // Laranja
      '#9C27B0', // Roxo
      '#2196F3', // Azul
    ];
    return palette;
  }

  isMoreThanAnyHours(
    startDate: Date,
    endDate: Date,
    someHours: number
  ): boolean {
    // Obtém a diferença entre as duas datas em milissegundos
    const diffInMs = endDate.getTime() - startDate.getTime();

    // Converte a diferença de milissegundos para horas
    const diffInHours = diffInMs / (1000 * 60 * 60);

    // Verifica se a diferença é maior que 3 horas
    return diffInHours > someHours;
  }
}
