import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Alert, AlertType } from '@app/shared/model';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';

    constructor(private toastr: ToastrService) { }

    // // enable subscribing to alerts observable
    // onAlert(id = this.defaultId): Observable<Alert> {
    //     return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    // }

    // // convenience methods
    // success(message: string, options?: any) {
    //     this.alert(new Alert({ ...options, type: AlertType.Success, message }));
    // }

    // error(message: string, options?: any) {
    //     this.alert(new Alert({ ...options, type: AlertType.Error, message }));
    // }

    // info(message: string, options?: any) {
    //     this.alert(new Alert({ ...options, type: AlertType.Info, message }));
    // }

    // warn(message: string, options?: any) {
    //     this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
    // }

    // // main alert method    
    // alert(alert: Alert) {
    //     alert.id = alert.id || this.defaultId;
    //     this.subject.next(alert);
    // }

    // // clear alerts
    // clear(id = this.defaultId) {
    //     this.subject.next(new Alert({ id }));
    // }

    showSuccess(message, title){
        this.toastr.success(message, title);
    }
    
    showError(message, title){
        this.toastr.error(message, title);
    }
    
    showInfo(message, title){
        this.toastr.info(message, title);
    }
    
    showWarning(message, title){
        this.toastr.warning(message, title);
    }

    clear(){
        this.toastr.clear();
    }
}