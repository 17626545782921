import { PaymentStatus } from "@app/shared/enum";
import { Course, Student, Teacher } from ".";

export class StudentCourseResume {
    id: number;
    identifier: string;

    student: Student;
    studentId: number;
    studentName: string;

    course: Course;
    courseId: number;
    courseName: string;

    teacher: Teacher;
    teacherId: number;
    teacherName: string;

    startDate: Date;
    endDate: Date;
    installmentFirstDue: Date;
    dueDay: number;
    monthTotal: number;

    paymentStatus: PaymentStatus;

    totalClasses: number;
    classesPerWeek: number;
    confirmedClasses: number;
    remainingClasses: number;

    termOfUseSent: boolean;
    termOfUseGenerated: boolean;
}
