<div class="page-wrapper">
    <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
            <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                <div class="d-flex align-items-center container p-0">
                    <header-logo></header-logo>
                    <span class="text-white opacity-50 ml-auto mr-2 hidden-sm-down">
                        Já possui uma conta?
                    </span>
                    <a href="/auth/login" class="btn btn-sm btn-outline-primary text-white ml-sm-0">
                        Entrar
                    </a>
                </div>
            </div>
            <div class="flex-1" style="background: url(/assets/img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
                <div class="container py-4 px-4 px-sm-0 text-white d-flex align-items-center justify-content-center" *ngIf="!confirm && submitted">
                    <form id="js-login" role="form" class="text-center text-white mb-5 pb-5">
                        <div class="py-3">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <h3>
                                Aguarde!
                                <small>
                                    Estamos validando os dados...
                                </small>
                            </h3>
                            <p class="text-white opacity-50">Só vai levar alguns instantes</p>
                        </div>
                        <div class="text-center">
                            <a href="/auth/login" class="text-white opacity-90">Deseja <b>cancelar</b> essa operação? Clique aqui</a>
                        </div>
                    </form>
                    <footer-auth></footer-auth>
                </div>
                <div class="container py-4 px-4 px-sm-0 text-white d-flex align-items-center justify-content-center" *ngIf="!confirm && !submitted">
                    <div class="form-group">
                        <div class="card p-4 rounded-plus bg-faded text-left">
                            <div class="alert alert-primary text-dark text-left" role="alert">
                                {{errorMessage}}
                            </div>
                            <a href="javascript:void(0);" class="h4" (click)="goForget();">
                                <i class="fal fa-chevron-right mr-2"></i> Receber um novo token
                            </a>
                        </div>
                    </div>
                    <footer-auth></footer-auth>
                </div>
                <div class="container py-4 px-4 px-sm-0" *ngIf="confirm">
                    <div class="row">
                        <div class="col-xl-12">
                            <h2 class="fs-xxl fw-500 mt-4 text-white text-center">
                                Criar uma nova senha
                                <small class="h3 fw-300 mt-3 mb-5 text-white opacity-60 hidden-sm-down">
                                    Informe abaixo a nova senha de acesso e a confirmação da senha.
                                </small>
                            </h2>
                        </div>
                        <div class="col-xl-6 ml-auto mr-auto">
                            <div class="card p-4 rounded-plus bg-faded">
                                <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" *ngIf="!resetSuccess">
                                    <input type="hidden" id="userid" formControlName="userid" />
                                    <input type="hidden" id="email" formControlName="email" />
                                    <input type="hidden" id="code" formControlName="code" />
                                    <div class="form-group">
                                        <label class="form-label" for="password">Senha: <br>
                                            <small>Não reutilize sua senha bancária e outras já utilizadas.</small>
                                        </label>
                                        <input type="password" id="password" formControlName="password" class="form-control" placeholder="Mínimo de 8 caracteres">
                                        <div *ngIf="f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Por favor, informe a sua senha.</div>
                                            <div *ngIf="f.password.errors.maxlength">A senha deve ter no máximo 20 caracteres.</div>
                                            <div *ngIf="f.password.errors.minlength">A senha deve ter no mínimo 8 caracteres.</div>
                                        </div>
                                        <div class="help-block text-primary">Sua senha deve ter de <strong>8 a 20 caracteres, contendo letras, números e caracteres especiais</strong> e não deve conter espaços ou emojis.</div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="confirmpassword">Confirme sua senha: <br>
                                            <small>Essa senha deve ser idêntica ao campo anterior.</small>
                                        </label>
                                        <input type="password" id="confirmpassword" formControlName="confirmpassword" class="form-control" placeholder="Mínimo de 8 caracteres">
                                        <div *ngIf="f.confirmpassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.confirmpassword.errors.required">Por favor, informe a sua senha.</div>
                                            <div *ngIf="f.confirmpassword.errors.maxlength">A senha deve ter no máximo 20 caracteres.</div>
                                            <div *ngIf="f.confirmpassword.errors.minlength">A senha deve ter no mínimo 8 caracteres.</div>
                                            <div *ngIf="f.confirmpassword.errors.notEquivalent">A confirmação de senha não confere.</div>
                                        </div>
                                        <div class="help-block text-primary">Sua senha deve ter de <strong>8 a 20 caracteres, contendo letras, números e caracteres especiais</strong> e não deve conter espaços ou emojis.</div>
                                    </div>
                                    <div class="alert border-primary bg-transparent text-primary alert-dismissible fade show">
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">
                                                <i class="fal fa-times"></i>
                                            </span>
                                        </button>
                                        <div class="d-flex flex-start w-100">
                                            <div class="d-flex align-items-center">
                                                <div class="alert-icon width-2">
                                                    <span class="icon-stack" style="font-size: 22px;">
                                                        <i class="base-2 icon-stack-3x color-primary-400"></i>
                                                        <i class="base-10 text-white icon-stack-1x"></i>
                                                        <i class="fal fa-shield-check color-primary-800 icon-stack-2x"></i>
                                                    </span>
                                                </div>
                                                <div class="flex-1">
                                                    <strong>Atenção! </strong>
                                                    Suas informações pessoais são particulares e seguras.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="alert alert-warning alert-dismissible text-center fade show mt-3" role="alert" *ngIf="!validResetPassword && errorMessage != ''">
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                        </button>
                                        {{errorMessage}}
                                        <div *ngIf="!validResetPassword && listErrorMessage.length > 0">
                                            <br>
                                            <div *ngFor="let error of listErrorMessage">{{error}}</div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-lg-12 my-2">
                                            <button id="js-login-btn" [disabled]="submitted" class="btn btn-info btn-block">
                                                <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                                Confirmar
                                            </button>
                                        </div>
                                    </div>
                                </form>

                                <div class="alert alert-success alert-dismissible text-center fade show mt-3" role="alert" *ngIf="resetSuccess && validResetPassword">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                    </button>
                                    Muito bem! Você criou uma nova senha.<br>
                                    <a [href]="returnUrl" class="text-primary fw-500" title="Login" target="_blank">Cliqui aqui para entrar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer-auth></footer-auth>
            </div>
        </div>
    </div>
</div>
