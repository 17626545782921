<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item">
    <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
  </li>
  <li class="breadcrumb-item active">Alunos</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol>
<div class="row">
  <!-- begin col-12 -->
  <div class="col-xl-12">
    <div id="panel-8" class="panel">
      <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>Lista de Alunos</h2>
        <div class="panel-toolbar ml-2">
          <a
            type="button"
            class="btn btn-success btn-xs"
            data-toggle="tooltip"
            title="Novo"
            href="javascript:void(0);"
            (click)="create(userselect)"
            >Novo</a
          >
        </div>
      </div>
      <div class="panel-container show">
        <div
          class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex"
        >
          <div class="col-sm-12" *ngIf="!showTable">
            <div class="alert alert-warning m-2" role="alert">
              {{ returnMessage }}
            </div>
          </div>
          <div class="col-sm-12" *ngIf="showTable">
            <managed-table
              [dataTitle]=""
              [rows]="students"
              [columns]="columns"
              [hasActions]="true"
              [hasFilter]="true"
              [ngTableFiltering]="true"
              [previewEnable]="true"
              (previewClick)="preview($event)"
              [deleteEnable]="true"
              (deleteClick)="delete($event)"
              [editEnable]="true"
              (editClick)="edit($event)"
              [auxEnable]="true"
              [auxIcon]="iconTermOfUse"
              [titleAux]="titleTermOfUse"
              (auxClick)="sendTermOfUse($event)"
              [auxEnable2]="true"
              [auxIcon2]="iconInfoStudentCourses"
              [titleAux2]="titleInfoStudentCourses"
              (auxClick2)="infoStudentCourses($event, mdInfoStudentCourses)"
            >
            </managed-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end col-12 -->
  <!-- begin col-12 -->
  <div class="col-12">
    <ng-template #mdInfoStudentCourses let-c="close" let-d="dismiss">
      <div class="modal-header bg-primary">
        <h5 class="modal-title">Detalhes do relatório</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="dismissModal()"
        >
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="panel-container show">
          <div class="panel-content" *ngFor="let cr of courseRecs">
            <h4 class="mb-3">
              Turma:
              <strong class="fw-700">{{ cr.id }} | {{ cr.name }}</strong>
              <span [innerHTML]="cr.designCourseType"></span>
              <button
                class="btn btn-danger btn-xs pull-right"
                (click)="courseView(cr.id)"
              >
                Visualizar Matrículas
              </button>
            </h4>
            <h3>Recorrências:</h3>
            <div class="row">
              <div class="col-4" [innerHTML]="cr.courseRecurrencesHtml"></div>
              <div class="col-8" [innerHTML]="cr.designTeachersHtml"></div>
            </div>
            <hr class="mb-3" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary waves-effect waves-themed"
          data-dismiss="modal"
          (click)="dismissModal()"
        >
          Fechar
        </button>
      </div>
    </ng-template>
  </div>
  <!-- end col-12 -->
</div>
<!-- end row -->

<div class="row">
  <div class="col-12">
    <ng-template #userselect let-c="close" let-d="dismiss">
      <app-user-select moduleType="{{ moduleType }}"></app-user-select>
    </ng-template>
  </div>
</div>
