export enum AvgScore {
    Master = 0,
    Advanced = 1,
    UpperIntermediate = 2,
    Intermediate = 3,
    PreIntermediate = 4,
    Basic = 5
}

export const AvgScoreLabel = new Map<number, string>([
    [AvgScore.Master, 'Proficiente'],
    [AvgScore.Advanced, 'Avançado'],
    [AvgScore.UpperIntermediate, 'Intermediário-avançado'],
    [AvgScore.Intermediate, 'Intermediário'],
    [AvgScore.PreIntermediate, 'Pré-intermediário'],
    [AvgScore.Basic, 'Básico']
]);

export const AvgScoreLabelEn = new Map<number, string>([
    [AvgScore.Master, 'master'],
    [AvgScore.Advanced, 'advanced'],
    [AvgScore.UpperIntermediate, 'upper-intermediate'],
    [AvgScore.Intermediate, 'intermediate'],
    [AvgScore.PreIntermediate, 'pre-intermediate'],
    [AvgScore.Basic, 'basic']
]);

export const AvgScoreCss = new Map<number, string>([
    [AvgScore.Master, 'score-master'],
    [AvgScore.Advanced, 'score-advanced'],
    [AvgScore.UpperIntermediate, 'score-upper-intermediate'],
    [AvgScore.Intermediate, 'score-intermediate'],
    [AvgScore.PreIntermediate, 'score-pre-intermediate'],
    [AvgScore.Basic, 'score-basic']
]);
