<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);">Home</a>
    </li>
    <li class="breadcrumb-item active">Relatório de aulas confirmadas'</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>

<div class="row">
    <div class="col-md-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr">
                <h2>Aulas realizadas, em andamento e previstas</h2>
                <!-- <div class="panel-toolbar ml-2">
                    <button class="btn btn-sm btn-primary" (onclick)="getConfirmedClasses()">Filtrar</button>
                </div> -->
            </div>
            <div class="panel-container show">
                <div class="panel-content p-0">
                    <section class="timeline_area section_padding_130">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <!-- Section Heading-->
                                    <!-- <div class="section_heading text-center">
                                        <h3>A brief stories of our 2 years company journey</h3>
                                        <div class="line"></div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="row" *ngIf="showData">
                                <div class="col-12">
                                    <!-- Timeline Area-->
                                    <div class="apland-timeline-area">
                                        <!-- Single Timeline Content-->
                                        <div class="single-timeline-area" *ngFor='let item of (groupClassRunningByDay$ | async)'>
                                            <div class="timeline-date wow fadeInLeft" [ngClass]="item.type" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                                                <h4 class="fw-700 future text-center">
                                                    {{item.timeStart}}<br>{{item.timeEnd}}
                                                    <br>
                                                    <small class="text-center" *ngIf="item.type === 'past'">(realizadas)</small>
                                                    <small class="text-center" *ngIf="item.type === 'present'">(em andamento)</small>
                                                    <small class="text-center" *ngIf="item.type === 'future'">(previstas)</small>
                                                </h4>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-4" role="button" *ngFor="let cls of item.classesRunning" (click)="viewClassroomProgressDetail(cls, mdClassroomProgressDetail)">
                                                    <div class="single-timeline-content d-flex wow fadeInLeft" [ngClass]="item.type" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                                        <div class="timeline-icon"><i class="fa fa-users" aria-hidden="true"></i></div>
                                                        <div class="timeline-text text-secondary">
                                                            <h4 class="keep-print-font fw-500 mb-0 flex-1 position-relative text-secondary">
                                                                {{cls?.course?.name}} <span [innerHTML]="cls?.course?.designCourseType"></span>
                                                            </h4>
                                                            <h6 class="keep-print-font fw-500 mb-0 flex-1 position-relative text-secondary">
                                                                {{cls?.teacher?.name}}
                                                            </h6>
                                                            <p>Alunos(as): {{cls?.students}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Timeline Area-->
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>

    <ng-template #mdClassroomProgressDetail let-c="close" let-d="dismiss">
        <div class="modal-header bg-primary">
            <h5 class="modal-title">Detalhes do relatório</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
        </div>
        <div class="modal-body">
            <div class="panel-container show">
                <div class="panel-content">
                    <h4 class="mb-3">
                        Teacher da aula: 
                        <strong class="fw-700">{{classRunningDetail?.teacher?.id}} | {{classRunningDetail?.teacher?.name}}</strong>
                    </h4>
                    <h5 class="mb-3">
                        Alunos(as): {{classRunningDetail?.students}}
                    </h5>
                    <div class="subheader mt-3">
                        <div class="subheader-block d-lg-flex align-items-center col-3 p-0">
                            <div class="d-inline-flex flex-column justify-content-center mr-3 w-100">
                                <span class="fw-300 d-block text-muted h4 mb-0">Data da aula:</span>
                                <span class='badge border border-primary text-primary w-100 fs-lg p-2'>
                                    <i class='fas fa-solid fa-calendar'></i> {{ classRunningDetail?.expectedDate | date: 'dd/MM/yyyy' }}
                                </span>
                            </div>
                        </div>
                        <div class="subheader-block d-lg-flex align-items-center col-3 p-0">
                            <div class="d-inline-flex flex-column justify-content-center mr-3 w-100">
                                <span class="fw-300 d-block text-muted h4 mb-0">Início da aula:</span>
                                <span class='badge border border-primary text-primary w-100 fs-lg p-2'>
                                    <i class='fas fa-solid fa-clock'></i> {{ classRunningDetail?.timeStart }}
                                </span>
                            </div>
                        </div>
                        <div class="subheader-block d-lg-flex align-items-center col-3 p-0">
                            <div class="d-inline-flex flex-column justify-content-center mr-3 w-100">
                                <span class="fw-300 d-block text-muted h4 mb-0">Término da aula:</span>
                                <span class='badge border border-primary text-primary w-100 fs-lg p-2'>
                                    <i class='fas fa-solid fa-clock'></i> {{ classRunningDetail?.timeEnd }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <h3>Alunos(as):</h3>
                    <div class="card border mb-4 p-3" *ngFor="let item of classRunningDetail?.studentClassesCourseList">
                        <h4 class="card-title">Nome: <strong class="fw-700">{{item?.studentCourse?.student?.name}}</strong></h4>
                        <h5 class="card-title">E-mail: <strong class="fw-700">{{item?.studentCourse?.student?.email}}</strong></h5>
                        <h5 class="card-title">Telefone: <strong class="fw-700">{{item?.studentCourse?.student?.phone}}</strong></h5>
                        <!-- <h5 class="card-title mb-2 text-center">Status:</h5> -->
                        <a href="javascript:void(0);" class="mt-1 d-block h4 fw-400 text-dark">
                            <div [innerHtml]="designStatusClass(item?.statusClass)"></div>
                        </a>
                        <div class="d-flex flex-row align-items-center">
                            <h4 class="text-primary fw-700">Conteúdo aplicado:</h4>
                        </div>
                        <a href="javascript:void(0);" class="mt-1 d-block h5 fw-400 text-secondary">
                            <i class="fal fa-video text-secondary mr-3"></i> 
                            Série: <strong class="fw-700" *ngIf="item?.contentEpisode?.contentSerie">{{item?.contentEpisode?.contentSerie?.name}}</strong>
                        </a>
                        <a href="javascript:void(0);" class="mt-1 d-block h5 fw-400 text-secondary">
                            <i class="fal fa-video text-secondary mr-3"></i> 
                            Episódio: <strong class="fw-700" *ngIf="item?.contentEpisode">{{item?.contentEpisode?.contentSerie?.name}} | {{item?.contentEpisode?.order | number: '2.' }} | {{item?.contentEpisode?.item }}</strong>
                        </a>
                        <div *ngIf="item?.confirmedClassByStudent || item?.confirmedClassByTeacher">
                            <hr>
                            <span class="badge border border-status-class-partial-confirmed w-100" *ngIf="item?.confirmedClassByStudent">
                                Aula confirmada pelo aluno
                                <span class="badge bg-status-class-partial-confirmed ml-2">{{item?.confirmedDateByStudent | date:'medium'}}</span>
                            </span>
                            <span class="badge border border-warning badge-warning mt-2 pt-2 pb-1 w-100 text-danger fw-700" *ngIf="!item?.confirmedClassByStudent">
                                ALUNO AINDA NÃO CONFIRMOU A AULA
                            </span>

                            <span class="badge border border-status-class-partial-confirmed mt-2 w-100" *ngIf="item?.confirmedClassByTeacher">
                                Aula confirmada pelo professor
                                <span class="badge bg-status-class-partial-confirmed ml-2">{{item?.confirmedDateByTeacher | date:'medium'}}</span>
                            </span>
                            <span class="badge border border-warning badge-warning mt-2 pt-2 pb-1 w-100 text-danger fw-700" *ngIf="!item?.confirmedClassByTeacher">
                                PROFESSOR AINDA NÃO CONFIRMOU A AULA
                            </span>
                            <div class="alert border-faded bg-transparent text-secondary fade show mb-2 mt-2" role="alert"
                                *ngIf="item?.confirmedClassByTeacher && item?.confirmedClassByTeacherStatusClassParams">
                                <div class="d-flex align-items-center">
                                    <div class="alert-icon">
                                        <span class="icon-stack icon-stack-md">
                                            <i class="base-7 icon-stack-3x color-success-600"></i>
                                            <i class="fal fa-check icon-stack-1x text-white"></i>
                                        </span>
                                    </div>
                                    <div class="flex-1">
                                        <span class="h5 color-success-600">Confirmado com justificativa!!</span>
                                        <br>
                                        <strong class="fw-700">{{getStatusClassParamsStr(item?.confirmedClassByTeacherStatusClassParams)}}</strong>
                                        <br>
                                        Info Adicional: {{item?.confirmedClassByTeacherAddInfo}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Fechar</button>
        </div>
    </ng-template>
</div>
