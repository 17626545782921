import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CourseService } from '@app/admin/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseGrid, CourseRecurrence } from '@app/admin/model';
import {
  CourseTypeCss,
  CourseTypeLabel,
  RecurrenceDayCss,
  RecurrenceDayLabel,
} from '@app/shared/enum';
import { default as swal } from 'sweetalert2';
import { AccountService } from '@app/auth/services';
@Component({ templateUrl: 'course-list.component.html', providers: [DatePipe] })
export class CourseListComponent implements OnInit {
  private userRoles: Array<any>;

  closeResult: string;
  public gridList: CourseGrid[];
  public recurrenceList: Array<CourseRecurrence> = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  public auxIcon = 'fa fa-users';
  public auxTitle = 'Alunos';

  public auxIcon3 = 'fa fa-video';
  public auxTitle3 = 'Sala de aula ao vivo';

  public courseId: number = 0;
  public courseClassRoom: any;
  public courseData: any;

  public columns: Array<any> = [
    { title: 'Código', name: 'id', sort: 'asc', className: 'text-center' },
    {
      title: 'Turma',
      name: 'designNameHtml',
      isHtmlData: true,
      sort: 'asc',
    },
    // { title: "Nome", name: "name", sort: "asc", isHtmlData: true },
    // {
    //   title: "Pacote",
    //   name: "product.name",
    //   sort: "asc",
    //   isHtmlData: true,
    //   className: "text-left fw-700"
    // },
    {
      title: 'Professor',
      name: 'designTeachersHtml',
      sort: 'asc',
      className: 'text-center fw-700',
    },
    {
      title: 'Recorrência',
      name: 'courseRecurrencesHtml',
      sort: 'asc',
      className: 'text-center fw-700',
    },
    {
      title: 'Tipo',
      name: 'designCourseType',
      sort: 'asc',
      isHtmlData: true,
      className: 'text-center',
    },
    {
      title: 'Total<br>de <strong>Meses</strong>',
      name: 'quantityMonth',
      sort: 'asc',
      className: 'text-center text-info fw-700',
    },
    {
      title: 'Aulas<br><strong>Semanais</strong>',
      name: 'weeklyFrequency',
      sort: 'asc',
      className: 'text-center text-info fw-700',
    },
    {
      title: '<strong>Limite</strong><br>de Alunos',
      name: 'quantityStudent',
      sort: 'asc',
      className: 'text-center text-info fw-700',
    },
    {
      title: '<strong>Alunos</strong><br>Matriculados',
      name: 'registeredStudents',
      sort: 'asc',
      className: 'text-center text-info fw-700',
    },
  ];

  constructor(
    private initAdmin: InitAdminService,
    private courseService: CourseService,
    private router: Router,
    private modalService: NgbModal,
    private loading: MessageService,
    private accountService: AccountService
  ) {
    this.gridList = new Array<CourseGrid>();
  }

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.userRoles = this.accountService.getRolesByUser();
    this.dataResult = false;
    this.loadCourses();
  }

  loadCourses() {
    this.courseService
      .grid()
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.typeResponse == TypeResponse.Success) {
            this.gridList = response.data;
            this.customDesignListCourse(this.gridList);
            this.showTable = true;
            this.returnMessage = '';
          } else {
            this.returnMessage = response.message;
            this.showTable = false;
          }
        },
        (error: any) => {
          this.showTable = false;
          this.returnMessage = 'Erro ao carregar os dados dos cursos.';
        }
      );
  }

  customDesignListCourse(lista: any[]) {
    lista.forEach(function (e) {
      if (e.courseType >= 0) {
        let courseTypeCss = CourseTypeCss.get(e.courseType);
        let courseTypeStr = CourseTypeLabel.get(e.courseType);
        e.designCourseType =
          "<span class='badge badge-pill w-100 " +
          courseTypeCss +
          "'>" +
          courseTypeStr +
          '</span>';
      }

      if (e.experimentalClass === true) {
        e.designNameHtml =
          e.name +
          "<span class='badge badge-danger ml-1 text-white'>EXP</span>";
      } else {
        e.designNameHtml = e.name;
      }

      e.courseRecurrencesHtml = '';
      e.designTeachersHtml = '';
      if (e.courseRecurrencesData && e.courseRecurrencesData.length > 0) {
        e.courseRecurrencesData.forEach((crd: any, index: number) => {
          let rec = new CourseRecurrence(crd, false);
          if (rec) {
            if (rec.dayOfWeek >= 0 && rec.timeStart instanceof Date) {
              let recurrenceDaySrt = RecurrenceDayLabel.get(rec.dayOfWeek);
              let timeStart = new Date(rec.timeStart);
              let timeEnd = new Date(rec.timeEnd);
              let duration = new Date(rec.duration);

              let timeStartStr =
                timeStart.toTimeString().split(':')[0] +
                ':' +
                timeStart.toTimeString().split(':')[1];
              let timeEndStr =
                timeEnd.toTimeString().split(':')[0] +
                ':' +
                timeEnd.toTimeString().split(':')[1];
              let durationStr =
                duration.toTimeString().split(':')[0] +
                'h' +
                duration.toTimeString().split(':')[1];

              e.courseRecurrencesHtml +=
                "<span class='badge border border-primary text-primary'><i class='fas fa-solid fa-clock'></i> " +
                recurrenceDaySrt.substring(0, 3) +
                ' | ' +
                timeStartStr +
                ' à ' +
                timeEndStr +
                ' | ' +
                durationStr +
                '</span>';

              e.designTeachersHtml +=
                "<span class='badge border border-primary text-primary fw-700 w-100'>" +
                "<i class='fas fa-solid fa-user'></i> " +
                rec.teacher?.name +
                '</span>';
              // "<span class='fw-700 text-primary'>"+rec.teacher.name+"</span>";

              if (e.courseRecurrencesData.length > index + 1) {
                e.courseRecurrencesHtml += '<br>';
                e.designTeachersHtml += '<br>';
              }
            }
          }
        });
      } else {
        e.courseRecurrencesHtml =
          "<span class='badge badge-pill badge-secondary w-100'>Não há recorrências</span>";
        e.designTeachersHtml =
          "<span class='badge badge-pill badge-secondary w-100'>Não há professor(a) vinculado(a).</span>";
      }
    });
    this.gridList = lista;
  }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/course', { id: row.id }]);
  }

  addStudentCourse(row: any, content) {
    this.courseId = row.id;
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'lg',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  manageStudents(row: any) {
    this.courseId = row.id;
    this.router.navigate([
      '/admin/studentCourselist',
      { courseId: this.courseId },
    ]);
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();

      console.log(reason);

      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  showStudents(content) {
    this.modalService.dismissAll();
    this.modalService
      .open(content, { backdrop: 'static', windowClass: 'animated fade' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'Save') {
            //this.submitChangePassword();
          }
        },
        (reason) => {
          this.closeResult = `Dismissed`;
          //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  delete(target): void {
    swal({
      title: 'Excluir Turma(a)',
      html:
        'Confirma a exclusão <strong>' +
        target.name +
        '</strong>?<br>' +
        'Ao confirmar a turma será excluída permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.courseService.remove(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.loadCourses();
                  //this.loadUsers();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir a turma!',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir a turma.',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  this.loadCourses();
                });
              }
            },
            (error) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir a turma.',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir turma.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  courseTeacherChange(row: any, content: any) {
    this.courseId = row.id;
    this.modalService
      .open(content, {
        backdrop: 'static',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  openClassRoom(course: any, content: any) {
    if (course) {
      this.loading.showLoading();
      this.courseService
        .getByIdentifier(course.identifier)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if (response.typeResponse == TypeResponse.Success) {
              this.courseData = JSON.stringify(response.data);
              this.modalService
                .open(content, {
                  backdrop: 'static',
                  size: 'lg',
                  windowClass: 'animated fade modal-backdrop-transparent',
                })
                .result.then(
                  (result) => {
                    this.closeResult = `Closed with: ${result}`;
                  },
                  (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(
                      reason
                    )}`;
                  }
                );
              this.returnMessage = '';
            } else {
              swal({
                type: 'warning',
                title: 'Problema na aula ao vivo',
                text: response.message,
                showConfirmButton: true,
              });
            }
          },
          (error: any) => {
            swal({
              type: 'error',
              title: 'Problema na aula ao vivo',
              text: 'Ocorreu um erro ao iniciar a aula ao vivo!',
              showConfirmButton: true,
            });
          }
        );
    } else {
      swal({
        type: 'warning',
        title: 'Problema na aula ao vivo',
        text: 'Não foi possível iniciar a aula ao vivo!',
        showConfirmButton: true,
      });
    }
  }

  checkHavePermission(role: any): boolean {
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some((x) => x.keyDefault == role);
    } else {
      return false;
    }
  }
}
