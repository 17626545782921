import { ModuleType } from '@app/shared/enum/ModuleType';
import { MessageType, TypeResponse } from '@app/shared/enum';
import { Base } from '@app/shared/model';
import { formatDate } from '@angular/common';
import { GroupNotification } from '.';

export class Notification extends Base {
    sid: string;
    messageType: MessageType;
    module: ModuleType;
    confirmation: boolean;
    notificationReading: Date;
    description: string;
    title: string;

    groupNotificationId: number;
    groupNotification: GroupNotification;

    constructor(contentResponse: any) {
        super(contentResponse);
        this.sid = contentResponse.sid;
        this.messageType = parseInt(contentResponse.messageType);
        this.module = parseInt(contentResponse.module);
        this.confirmation = contentResponse.confirmation;
        this.notificationReading = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        this.description = contentResponse.description;
        this.title = contentResponse.title;

        this.groupNotificationId = parseInt(contentResponse.groupNotificationId);
        if (contentResponse.groupNotificationId > 0 && contentResponse.groupNotification) {
            this.groupNotification = contentResponse.groupNotification;
        }
    }
}
