import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private route: string = "auth";
  private controller: string = "role";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getall(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getRoleGroupByModule(moduleType: number): any {
    let params = new HttpParams();
    params = params.append('module', moduleType.toString());
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getRoleGroupByModule`, {params: params});
  }

  getByRoleGroup(roleGroupId: number): any {
    let params = new HttpParams();
    params = params.append('roleGroupId', roleGroupId.toString());
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getByRoleGroup`, {params: params});
  }
}