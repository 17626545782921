import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { TypeResponse } from '@app/shared/enum/TypeResponse';
import {
  InitAdminService,
  StudentService,
  TermOfUseService,
} from '@app/admin/services';
import { default as swal } from 'sweetalert2';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleType } from '@app/shared/enum/ModuleType';
import { MessageService } from '@app/shared/services';
import { CourseRecurrence, TermOfUse } from '@app/admin/model';
import {
  CourseTypeCss,
  CourseTypeLabel,
  RecurrenceDayCss,
  RecurrenceDayLabel,
} from '@app/shared/enum';

@Component({
  templateUrl: 'student-list.component.html',
  providers: [DatePipe],
})
export class StudentListComponent implements OnInit {
  public student: any;
  public studentId: number = 0;
  public students: any = [];
  public courseRecs: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';
  closeResult: string;
  urlReloadList: string;
  public moduleType: ModuleType = ModuleType.Student;

  public columns: Array<any> = [
    { title: 'Código', name: 'id', sort: 'asc', className: 'text-center' },
    { title: 'Nome', name: 'name', sort: 'asc' },
    { title: 'E-mail', name: 'email', sort: 'asc' },
    { title: 'telefone', name: 'phone', sort: 'asc', className: 'text-center' },
    {
      title: 'Usuário Vinculado?',
      name: 'haveUser',
      sort: 'asc',
      icon: {
        iconTrue: 'user-alt',
        iconFalse: 'user-times',
      },
      className: 'text-center text-primary fw-700',
    },
    {
      title: 'Usuário Estrangeiro?',
      name: 'foreignPerson',
      sort: 'asc',
      icon: {
        iconTrue: 'user-alt',
        iconFalse: 'user-times',
      },
      className: 'text-center text-info fw-700',
    },
    {
      title: 'Termo<br>gerado?',
      name: 'termGenerated',
      sort: 'asc',
      className: 'text-center text-primary',
    },
    {
      title: 'Termo<br>enviado?',
      name: 'termOfUseSent',
      sort: 'asc',
      className: 'text-center text-primary',
    },
    {
      title: 'Matrículas',
      name: 'studentCourses.length',
      sort: 'asc',
      className: 'text-center text-info',
    },
    // {
    //   title: "Criado em",
    //   name: "createdOn",
    //   sort: "asc",
    //   className: "text-center text-primary datetime"
    // },
    // {
    //   title: "Modificado em",
    //   name: "updatedOn",
    //   sort: "asc",
    //   className: "text-center text-primary datetime"
    // }
  ];

  iconTermOfUse = 'fas fa-file-pdf';
  titleTermOfUse = 'Termo de Uso';

  iconInfoStudentCourses = 'fas fa-address-card';
  titleInfoStudentCourses = 'Turmas e matrículas do aluno';

  constructor(
    private termOfUseService: TermOfUseService,
    private studentService: StudentService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.urlReloadList = '/admin/studentlist';
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadStudents();
    this.loading.hideLoading();
  }

  loadStudents() {
    this.loading.showLoading();
    this.studentService.getall().subscribe(
      (response) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.students = response.data;
          this.showTable = true;
          this.returnMessage = '';
        } else {
          this.returnMessage = response.message;
          this.showTable = false;
        }
        this.loading.hideLoading();
      },
      (error: any) => {
        this.showTable = false;
        this.returnMessage = 'Erro ao carregar os dados dos alunos.';
        this.loading.hideLoading();
      }
    );
  }

  delete(target: any): void {
    swal({
      title: 'Excluir Aluno',
      html:
        'Confirma a exclusão <strong>' +
        target.name +
        '</strong>?<br>' +
        'Ao confirmar o aluno será excluído permanentemente!',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir',
    }).then(
      (willDelete) => {
        if (willDelete.value === true) {
          this.studentService.remove(target.id).subscribe(
            (response) => {
              if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Success
              ) {
                swal({
                  type: 'success',
                  title: 'Registro excluído com sucesso!',
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  //window.location.reload();
                  this.loadStudents();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Alert
              ) {
                swal({
                  type: 'warning',
                  title: 'Não foi possível excluir o aluno!',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  //window.location.reload();
                });
              } else if (
                'typeResponse' in response &&
                response['typeResponse'] === TypeResponse.Error
              ) {
                swal({
                  type: 'error',
                  title: 'Erro ao tentar excluir o aluno.',
                  text: response.message,
                  showConfirmButton: true,
                  //timer: 1500
                }).then((result) => {
                  this.loadStudents();
                });
              }
            },
            (error) => {
              swal({
                type: 'error',
                title: 'Erro ao tentar excluir o aluno.',
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.loading.hideLoading();
                window.location.reload();
              });
            }
          );
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar excluir o aluno.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            window.location.reload();
          });
        }
      }
    );
  }

  edit(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/student', { id: row.id }]);
  }

  preview(row: any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/student', { id: row.id, preview: true }]);
  }

  create(content: any) {
    this.modalService.dismissAll();
    this.modalService
      .open(content, {
        backdrop: 'static',
        //size: 'sm',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'Save') {
            //this.submitChangePassword();
          }
        },
        (reason) => {
          this.closeResult = `Dismissed`;
          //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  infoStudentCourses(row: any, content: any) {
    this.loading.showLoading();
    this.studentService.infoStudentCourses(row.id).subscribe(
      (response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          this.student = response.data.student;
          this.designInfoStudent(response.data.recs);
          this.loading.hideLoading();
          this.modalService.dismissAll();
          this.modalService
            .open(content, {
              backdrop: 'static',
              size: 'lg',
              windowClass: 'animated fade',
            })
            .result.then(
              (result) => {
                this.closeResult = `Closed with: ${result}`;
              },
              (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              }
            );
        }
        this.loading.hideLoading();
      },
      (error) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
        this.loading.hideLoading();
      }
    );
  }

  courseView(courseId: number): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/studentCourselist', { courseId }]);
    this.dismissModal();
  }

  designInfoStudent(courses: any[]) {
    courses.forEach(function (e) {
      if (e.courseType >= 0) {
        let courseTypeCss = CourseTypeCss.get(e.courseType);
        let courseTypeStr = CourseTypeLabel.get(e.courseType);
        e.designCourseType =
          "<span class='badge badge-pill ml-3 " +
          courseTypeCss +
          "'>" +
          courseTypeStr +
          '</span>';
      }

      if (e.experimentalClass === true) {
        e.designNameHtml =
          e.name +
          "<span class='badge badge-danger ml-1 text-white'>EXP</span>";
      } else {
        e.designNameHtml = e.name;
      }

      e.courseRecurrencesHtml = '';
      e.designTeachersHtml = '';
      if (e.courseRecurrencesData && e.courseRecurrencesData.length > 0) {
        e.courseRecurrencesData.forEach((crd: any, index: number) => {
          let rec = new CourseRecurrence(crd, false);
          if (rec) {
            if (rec.dayOfWeek >= 0 && rec.timeStart instanceof Date) {
              let recurrenceDaySrt = RecurrenceDayLabel.get(rec.dayOfWeek);
              let timeStart = new Date(rec.timeStart);
              let timeEnd = new Date(rec.timeEnd);
              let duration = new Date(rec.duration);

              let timeStartStr =
                timeStart.toTimeString().split(':')[0] +
                ':' +
                timeStart.toTimeString().split(':')[1];
              let timeEndStr =
                timeEnd.toTimeString().split(':')[0] +
                ':' +
                timeEnd.toTimeString().split(':')[1];
              let durationStr =
                duration.toTimeString().split(':')[0] +
                'h' +
                duration.toTimeString().split(':')[1];

              e.courseRecurrencesHtml +=
                "<span class='badge border border-primary text-primary'><i class='fas fa-solid fa-clock'></i> " +
                recurrenceDaySrt.substring(0, 3) +
                ' | ' +
                timeStartStr +
                ' à ' +
                timeEndStr +
                ' | ' +
                durationStr +
                '</span>';

              e.designTeachersHtml +=
                "<span class='badge border border-primary text-primary fw-700 w-100'>" +
                "<i class='fas fa-solid fa-user'></i> " +
                rec.teacher?.name +
                '</span>';
              // "<span class='fw-700 text-primary'>"+rec.teacher.name+"</span>";

              if (e.courseRecurrencesData.length > index + 1) {
                e.courseRecurrencesHtml += '<br>';
                e.designTeachersHtml += '<br>';
              }
            }
          }
        });
      } else {
        e.courseRecurrencesHtml =
          "<span class='badge badge-pill badge-secondary w-100'>Não há recorrências</span>";
        e.designTeachersHtml =
          "<span class='badge badge-pill badge-secondary w-100'>Não há professor(a) vinculado(a).</span>";
      }
    });
    this.courseRecs = courses;
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      console.log(reason);
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  downloadPdf(base64String: any, fileName: any) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }

  sendTermOfUse(target: any): void {
    if (target.termGenerated || target.termOfUseSent) {
      return this.confirmReprint(target);
    } else {
      return this.generateTermOfUse(target);
    }
  }

  generateTermOfUse(target: any): void {
    swal({
      title: 'Termo de Ativação de Matrícula',
      html:
        'Confirme abaixo como será gerado o termo de ativação<br>de matrícula do(a) aluno(a) <strong>' +
        target.name +
        '</strong>.<br><br>' +
        '<small>Caso prefira o envio por e-mail o usuário receberá um link<br>direcionando para o download do termo em PDF.</small>',
      type: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Enviar por e-mail',
      showCancelButton: true,
      cancelButtonColor: '#868e96',
      cancelButtonText: 'Somente gerar PDF',
      showCloseButton: true,
    }).then(
      (willSend) => {
        if (willSend.dismiss && willSend.dismiss != swal.DismissReason.cancel) {
          this.loading.hideLoading();
          return;
        } else if (
          willSend.dismiss &&
          willSend.dismiss == swal.DismissReason.cancel
        ) {
          this.loading.showLoading();
          return this.termGenerate(target.id, false, false);
        } else {
          this.loading.showLoading();
          return this.termGenerate(target.id, true, false);
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          return this.sendTermFailed('', TypeResponse.Error);
        }
      }
    );
  }

  confirmReprint(target: any) {
    swal({
      title: 'Termo de Ativação de Matrícula',
      html:
        'O termo do(a) aluno(a) <strong>' +
        target.name +
        '</strong> já foi gerado.<br><br>' +
        '<small>Deseja gerar novamente ou somente reimprimir?</small>',
      type: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Gerar novamente',
      showCancelButton: true,
      cancelButtonColor: '#868e96',
      cancelButtonText: 'Somente reimpressão',
      showCloseButton: true,
    }).then(
      (willSend) => {
        if (willSend.dismiss && willSend.dismiss != swal.DismissReason.cancel) {
          this.loading.hideLoading();
          return;
        } else if (
          willSend.dismiss &&
          willSend.dismiss == swal.DismissReason.cancel
        ) {
          this.loading.showLoading();
          return this.termGenerate(target.id, false, true);
        } else {
          this.loading.hideLoading();
          return this.generateTermOfUse(target);
        }
      },
      (error) => {
        // expiração do token
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          return this.sendTermFailed('', TypeResponse.Error);
        }
      }
    );
  }

  private termGenerate(
    studentId: number,
    sendMail: boolean,
    onlyPrint: boolean
  ) {
    let termOfUse: TermOfUse = {
      studentId,
      sendByMail: sendMail,
      onlyPrint: onlyPrint,
    };
    this.termOfUseService.Generate(termOfUse).subscribe(
      (response: any) => {
        this.loading.hideLoading();
        if (
          'typeResponse' in response &&
          response['typeResponse'] === TypeResponse.Success
        ) {
          let msg = onlyPrint ? 'impresso' : 'gerado';
          swal({
            type: 'success',
            title: 'Termo de ativação de matrícula ' + msg + ' com sucesso!',
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            let base64String = response.data.file;
            let fileName =
              'Termo de ativação de matrícula ' +
              response.data.termOfUse.identifier;
            this.downloadPdf(base64String, fileName);
            this.loading.hideLoading();
            window.location.reload();
          });
        } else {
          this.sendTermFailed(response.message, response['typeResponse']);
        }
      },
      () => {
        this.sendTermFailed('', TypeResponse.Error);
      }
    );
  }

  private returnUrlList() {
    this.router.navigate([this.urlReloadList]);
  }

  private sendTermFailed(message: string, type: TypeResponse) {
    if (type === TypeResponse.Alert) {
      swal({
        type: 'warning',
        title: 'Termo não foi gerado!',
        html: message,
        showConfirmButton: true,
      }).then(() => {
        this.loading.hideLoading();
      });
    } else {
      swal({
        type: 'error',
        title: 'Erro ao gerar o termo de ativação de matrícula!',
        html: 'Ocorreu um erro ao gerar o termo.<br>Valide as informações e tente novamente.',
        showConfirmButton: true,
      }).then(() => {
        this.loading.hideLoading();
      });
    }
  }
}
