import { User } from '@app/auth/model';
import { AccountService } from '@app/auth/services/account.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import pageSettings from '../../../_helpers/page-settings';

// JavaScript components
import * as initContent from 'src/assets/js/app.bundle.js';
declare function initContent(): any;

@Component({
  selector: 'aside',
  templateUrl: './sidebar.component.html'
})

export class SidebarComponent {
  returnUrl: string;
  pageSettings;
  public user: User = new User();

  constructor(
    private router: Router, 
    private accountService: AccountService) {}

  ngOnInit(): void {
    this.user = this.accountService.getUserData();
  }

  menuClick(url): void {
    if (url) {
      this.router.navigate([url]);
    }
    return;
  }

  ngAfterViewInit() {
    //We loading script on after view is loaded
    initContent();
  }
}
