import { formatDate } from "@angular/common";
import { Course, Teacher } from "@app/admin/model";
import { Base } from "@app/shared/model";

export class ClassRunningByDay extends Base {
    expectedDate: Date;
    teacher: Teacher;
    course: Course;
    timeStart: Date;
    timeEnd: Date;
    duration: Date;
    students: number;

    constructor(contentResponse: any, fixDate: boolean = false) {
        super(contentResponse);
        
        this.expectedDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
        this.timeStart = fixDate ? contentResponse.timeStart
            : this.getTimeByString(contentResponse.timeStart);
        this.timeEnd = fixDate ? contentResponse.timeEnd
            : this.getTimeByString(contentResponse.timeEnd);
        
        if (contentResponse && contentResponse.courseId > 0) {
            this.course = contentResponse.course;
        }

        if (contentResponse && contentResponse.teacherId > 0) {
            this.teacher = contentResponse.teacher;
        }

        this.students = contentResponse.students;
    }

    getTimeByString(strTime: any) {
        if (strTime != null && strTime.length > 0) {
            let dateTime = new Date();
            dateTime.setMilliseconds(0);
            dateTime.setSeconds(0);
            dateTime.setMinutes(strTime.split(':')[1]);
            dateTime.setHours(strTime.split(':')[0]);
            return dateTime;
        }
    }
}
