import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@app/shared/services';
import { environment } from '@environments/environment';
import { FinancialType } from '@app/shared/enum';
import { ResponseResult } from '@app/auth/model';

@Injectable({
  providedIn: 'root'
})
export class TransactionService extends BaseService {
  private customEndpointPrefix: string;

  constructor(private http2: HttpClient) {
      super(http2, "financial", "transaction");
      this.customEndpointPrefix = `${environment.urlAPI}/financial/transaction`;
  }

  getByFinancialType(financialType: FinancialType): any {
    return this.http2.get<ResponseResult>(`${this.customEndpointPrefix}/financialType/${financialType}`);
  }
}