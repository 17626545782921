import { CheckListService, InitAdminService } from '@app/admin/services';
import { CheckList, CheckListItem } from '@app/admin/model';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, MessageService } from '@app/shared/services';
import { TypeResponse } from '@app/shared/enum';
import { DatePipe } from '@angular/common';
import { ModuleType } from '@app/shared/enum/ModuleType';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({ templateUrl: 'checkList.component.html' })
export class CheckListComponent implements OnInit {
  checkListForm;
  submitted = false;
  returnUrl: string = '/admin/checkListlist';
  public validEntity: boolean;
  public updateCheckList: boolean = false;
  public errorMessage = '';
  public listErrorMessage: any[];
  private checkList: CheckList;
  public checkListItems: any = new Array<CheckListItem>();
  public editMode: boolean;
  public idCheckList: number;
  public moduleTypeList: any = [];
  public moduleTypeValue: any;
  showItemsTable = false;
  closeResult: string;
  public validItemCheckList: boolean = true;

  public itemCheckList: any = {
    itemOrder: 0,
    itemDescription: ''
  };

  public itemsColumn: Array<any> = [
    { title: "Ordem", name: "order", sort: "asc", className: "text-center" },
    { title: "Descrição", name: "description", sort: "asc" },
    {
      title: "Criado em",
      name: "createdOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    },
    {
      title: "Modificado em",
      name: "updatedOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private checkListService: CheckListService,
    private initAdmin: InitAdminService,
    private loading: MessageService
  ) {}

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/checkListlist';
    
    this.activatedRoute.params.subscribe(params => {
      this.idCheckList = params['id'];
      if (this.idCheckList > 0) {
        this.editMode = true;
        this.loadCheckList(this.idCheckList);
      } else {
        this.editMode = false;
        // Initialize the register form
        this.onInitForm();
      }
    });

    this.loading.hideLoading();
  }

  onInitForm() {
    this.validEntity = true;
    this.checkListForm = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.required]],
      module: [0, [Validators.required]],
      createdOn: [''],
      updatedOn: ['']
    });
    this.moduleTypeList = this.ToArray(ModuleType);
    this.moduleTypeValue = 0;
  }

  // Turn enum into array
  ToArray(enumme) {
    const list = Object.keys(enumme).filter(e => !isNaN(+e));
    
    let enumArray: Array<any> = [];
    list.forEach(a => {
      enumArray.push({
        value: parseInt(a),
        name: enumme[a]
      });
    });
    return enumArray;
  }
  
  // convenience getter for easy access to form fields
  get f() {
    return this.checkListForm.controls;
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validEntity = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.checkListForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validEntity = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.checkList = new CheckList(this.checkListForm.value);
    //this.registerUser.UrlCallback = this.urlConfirmation;
    
    this.checkListService.save(this.checkList, this.editMode)
    .pipe(first())
    .subscribe(dataReturn => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.validEntity = true;
        this.router.navigate([this.returnUrl]);
        this.submitted = false;
        this.loading.hideLoading();
      } else {
        this.validEntity = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
        this.submitted = false;
        this.loading.hideLoading();
      }
    }, error => {
      this.validEntity = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = error == "OK" ? "Erro ao salvar o aluno." : error;
    });
  }

  loadCheckList(id) {
    this.loading.showLoading();
    this.checkListService.getById(id)
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validEntity = true;
        this.checkList = new CheckList(response.data);
        this.setFormByEntity(this.checkList);
      } else {
        this.errorMessage = response.message;
        this.validEntity = false;
      }
      this.loading.hideLoading();
      }, error => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          this.router.navigate(['/admin/checkListlist']);
        }
      }
    );
  }

  setFormByEntity(data: any) {
    this.checkListForm = this.formBuilder.group({
      id: new FormControl(data.id),
      name: new FormControl(data.name),
      module: new FormControl(data.module),
      createdOn: new FormControl(new Date(data.createdOn)),
      updatedOn: new FormControl(new Date(data.updatedOn))
    });
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  deleteItem(): void {
  }

  editItem(): void {
  }

  newItemCheckList(row: any, content) {
    this.modalService.dismissAll();
    this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  saveItem(): void {
    //salvar
    let a = 0;
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();
  
      console.log(reason);
  
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }
}
