import { ClassesCalendarEvent, State } from '.';
import { Base } from '@app/shared/model';

export class ClassesCalendar extends Base {
    classesCalendarEvent: ClassesCalendarEvent;
    
    constructor(contentResponse: any) {
        super(contentResponse);

        this.classesCalendarEvent = contentResponse.classesCalendarEvent;
    }
}
