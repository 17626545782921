import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentClassesCourseActionService {
    private route: string = "admin";
    private controller: string = "StudentClassesCourseAction";
    private endpointPrefix: string;
  
    constructor(private http: HttpClient) {
      this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
    }

    getById(id: any): any {
        return this.http.get<ResponseResult>(`${this.endpointPrefix}/${id}`);
    }

    getByStudentClassesCourse(studentClassesCourseId: any): any {
        return this.http.get<ResponseResult>(`${this.endpointPrefix}/StudentClassesCourse/${studentClassesCourseId}`);
    }

    getByPage(studentClassesCourseId: any, page: number, pageSize: number): Observable<any> {
      return this.http.get<ResponseResult>(`${this.endpointPrefix}/StudentClassesCourse/${studentClassesCourseId}/page/${page}/pageSize/${pageSize}`);
    }
}
