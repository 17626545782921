import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Teacher } from '@app/admin/model';
import { User } from '@app/auth/model';
import { AccountService } from '@app/auth/services';
import { ModuleType, ModuleTypeCss, ModuleTypeLabel, ModuleTypeLabelEn } from '@app/shared/enum';
import { MessageService } from '@app/shared/services';
import { InitTeacherService } from '@app/teacher/services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'menu-teacher',
  templateUrl: './menu-teacher.component.html',
  styleUrls: ['./menu-teacher.component.css']
})
export class MenuTeacherComponent {
  public user: User = new User();
  private userRoles: Array<any>;
  public teacher: Teacher;

  constructor(
    private router: Router, 
    private loading: MessageService,
    private accountService: AccountService,
    private initTeacher: InitTeacherService) {
      initTeacher.setConfigPage();
      this.getUserData();
  }

  private getUserData() {
    this.loading.showLoading();
    this.initTeacher.getTeacherUser().pipe(first()).subscribe((response: any) => {
      this.teacher = response;
      this.user = this.initTeacher.user;
      this.userRoles = this.accountService.getRolesByUser();
      this.loading.hideLoading();
    }, (error: any) => {
      console.log('Erro ao carregar os dados do usuário.');
      this.loading.hideLoading();
    });
  }

  // private loadUserData() {
  //   this.loading.showLoading();
  //   this.user = this.accountService.getUserData();
  //   this.userRoles = this.accountService.getRolesByUser();
  //   this.loading.hideLoading();
  // }

  menuClick(url): void {
    if (url) {
      this.router.navigate([url]);
    }
    return;
  }

  checkHavePermission(role): boolean {
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some(x => x.keyDefault == role);
    } else {
      return false;
    }
  }
}
