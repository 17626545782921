<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item">
    <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
  </li>
  <li class="breadcrumb-item active">Testes de Escrita do Teacher</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol>
<div class="row">
  <div class="col-xl-12">
    <div id="panel-8" class="panel">
      <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>Filtro</h2>
      </div>
      <div class="panel-container show">
        <div
          class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex"
        >
          <div class="col-sm-12 mb-3">
            <div class="form-group">
              <label class="form-label" for="teacherId"
                >Teacher <span class="text-danger">*</span>
              </label>
              <div class="ng-autocomplete">
                <ng-autocomplete
                  [data]="teacherList"
                  searchKeyword="name"
                  placeholder="Selecione o teacher"
                  (selected)="selectEvent($event)"
                  (inputChanged)="onChangeSearch($event)"
                  (inputFocused)="onFocused($event)"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-12">
    <div id="panel-8" class="panel">
      <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>Lista de Testes de Escrita do Teacher</h2>
      </div>
      <div class="panel-container show">
        <div
          class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex"
        >
          <div class="col-sm-12" *ngIf="!showTable">
            <div class="alert alert-warning m-2" role="alert">
              {{ errorWritingTestPending }}
            </div>
          </div>
          <div class="col-sm-12" *ngIf="showTable">
            <managed-table
              [dataTitle]="'Testes de Escrita do Teacher'"
              [rows]="gridWritingTestPending$ | async"
              [columns]="columns"
              [hasActions]="true"
              [hasFilter]="true"
              [ngTableFiltering]="true"
              [auxEnable]="true"
              [auxIcon]="iconTeacherHtml"
              [titleAux]="titleTeacherHtml"
              (auxClick)="changeTeacher($event, mdChangeTeacher)"
              [previewEnable]="false"
              [deleteEnable]="false"
              [editEnable]="false"
            >
            </managed-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #mdChangeTeacher let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title">Troca de teacher do teste</h3>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="dismissModal()"
    >
      <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <label class="form-label" for="userId"
          >Professor(a) <span class="text-danger">*</span>
        </label>
        <br />
        <div class="ng-autocomplete w-100">
          <ng-autocomplete
            [data]="teacherList"
            searchKeyword="name"
            placeholder="Selecione um teacher"
            (selected)="selectEventTeacher($event)"
            (inputChanged)="onChangeSearchTeacher($event)"
            (inputFocused)="onFocusedTeacher($event)"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
          >
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
        <span
          *ngIf="!validTeacher"
          class="text-warning pull-left"
          style="padding-top: 10px"
        >
          <i class="fa fa-warning text-warning"></i> Nenhum(a) usuário(a)
          selecionado(a).
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-secondary waves-effect waves-themed"
      data-dismiss="modal"
      (click)="dismissModal()"
    >
      <i class="fa fa-times-circle mr-1"></i> Cancelar
    </button>
    <button
      type="button"
      class="btn btn-sm btn-success waves-effect waves-themed"
      (click)="doneChangeTeacher()"
    >
      <i class="fa fa-check mr-1"></i> Confirmar
    </button>
  </div>
</ng-template>
