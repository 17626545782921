import { State } from '.';
import { Base } from '@app/shared/model';

export class City extends Base {
    name: string;
    ibgeCode: string;

    stateId: number;
    state: State;
    
    constructor(contentResponse: any) {
        super(contentResponse);

        this.name = contentResponse.name;
        this.ibgeCode = contentResponse.ibgeCode;
        
        this.stateId = parseInt(contentResponse.stateId);
        if (contentResponse && contentResponse.stateId > 0) {
            this.state = contentResponse.state;
        }
    }
}
