export enum ContactStatus {
  Comercial = 0,
  Concluido = 1,
  AulaAgendada = 2,
  Finalizado = 3,
  AnalisandoValores = 4,
  NaoCompareceu = 5,
  Desmarcou = 6,
  SemRetorno = 7,
  EnviarTermo = 8,
  TermoEnviado = 9
}

export const ContactStatusLabel = new Map<number, string>([
  [ContactStatus.Comercial, 'Comercial'],
  [ContactStatus.Concluido, 'Concluído'],
  [ContactStatus.AulaAgendada, 'Aula Agendada'],
  [ContactStatus.Finalizado, 'Finalizado'],
  [ContactStatus.AnalisandoValores, 'Analisando Valores'],
  [ContactStatus.NaoCompareceu, 'Não Compareceu'],
  [ContactStatus.Desmarcou, 'Desmarcou'],
  [ContactStatus.SemRetorno, 'Sem Retorno'],
  [ContactStatus.EnviarTermo, 'Enviar Termo'],
  [ContactStatus.TermoEnviado, 'Termo Enviado']
]);

export const ContactStatusCss = new Map<number, string>([
  [ContactStatus.Comercial, 'status-comercial'],
  [ContactStatus.Concluido, 'status-concluido'],
  [ContactStatus.AulaAgendada, 'status-aula-agendada'],
  [ContactStatus.Finalizado, 'status-finalizado'],
  [ContactStatus.AnalisandoValores, 'status-analisando-valores'],
  [ContactStatus.NaoCompareceu, 'status-nao-compareceu'],
  [ContactStatus.Desmarcou, 'status-desmarcou'],
  [ContactStatus.SemRetorno, 'status-sem-retorno'],
  [ContactStatus.EnviarTermo, 'status-enviar-termo'],
  [ContactStatus.TermoEnviado, 'status-termo-enviado']
]);
