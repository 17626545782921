import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Third Modules
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2TableModule } from 'ngx-datatable';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgxPaginationModule } from 'ngx-pagination';

// Modules
import { SharedRoutingModule } from './shared-routing.module';

// Pages
import { FooterAuthComponent } from './pages/footer-auth/footer-auth.component';
import { HeaderLogoComponent } from './pages/header-logo/header-logo.component';

// Components
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterFormComponent } from './components/footer-form/footer-form.component';
import { NotificationViewComponent } from './components/notification/notification-view/notification-view.component';
import { NotificationHeaderListComponent } from './components/notification/notification-header-list/notification-header-list.component';
import { AlertComponent } from './components/alert/alert.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ManagedTableComponent } from './components/managed-table/managed-table.component';
import { MenuStudentComponent } from './components/menu-student/menu-student.component';
import { MenuTeacherComponent } from './components/menu-teacher/menu-teacher.component';
import { MenuAdminComponent } from './components/menu-admin/menu-admin.component';
import { SerieItemComponent } from './components/content/series/serie-item/serie-item.component';
import { SerieListComponent } from './components/content/series/serie-list/serie-list.component';
import { EpisodeListComponent } from './components/content/episodes/episode-list/episode-list.component';
import { EpisodeItemComponent } from './components/content/episodes/episode-item/episode-item.component';
import { PackageComponent } from './components/content/package/package.component';

// Other
import { SafePipe } from '@app/_helpers/safe/safe.pipe';
import { DateInputConverter } from './directives/date-input-converter.directive';
import { Select2Directive } from './directives/select2.directive';

@NgModule({
  declarations: [
    NotificationHeaderListComponent,
    NotificationViewComponent,
    PageNotFoundComponent,
    FooterAuthComponent,
    HeaderLogoComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    AlertComponent,
    CalendarComponent,
    ManagedTableComponent,
    FooterFormComponent,
    MenuStudentComponent,
    MenuTeacherComponent,
    MenuAdminComponent,
    SerieItemComponent,
    SerieListComponent,
    EpisodeListComponent,
    EpisodeItemComponent,
    PackageComponent,
    SafePipe,
    DateInputConverter,
    Select2Directive,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    CommonModule,
    Ng2TableModule,
    //ToastrModule.forRoot(),
    //NgxDatatableModule,
    NgxPaginationModule,
    SharedRoutingModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(122,89,173,0.5)',
      backdropBorderRadius: '4px',
      primaryColour: '#2196F3',
      secondaryColour: '#0d8aee',
      tertiaryColour: '#0c7cd5',
    }),
  ],
  exports: [
    FooterAuthComponent,
    HeaderLogoComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    AlertComponent,
    //ToastrModule,
    ManagedTableComponent,
    FooterFormComponent,
    SerieItemComponent,
    SerieListComponent,
    EpisodeListComponent,
    EpisodeItemComponent,
    PackageComponent,
    SafePipe,
    DateInputConverter,
    Select2Directive,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
