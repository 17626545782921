import { Injectable } from '@angular/core';
import { User } from '@app/auth/model';
import { AccountService } from '@app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class InitMarketingService {
  returnUrl: string;
  public user: User = new User();

  constructor(
    private accountService: AccountService) {
      this.user = this.accountService.getUserData();
  }

  async getUserData() {
    this.user = this.accountService.getUserData();
  }
}