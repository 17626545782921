import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/auth/services';
import { TypeResponse } from '@app/shared/enum';
import { ModuleType } from '@app/shared/enum/ModuleType';
import { MessageService } from '@app/shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';

@Component({
  selector: 'app-user-select',
  templateUrl: './userSelect.component.html',
  styleUrls: ['./userSelect.component.css']
})
export class UserSelectComponent implements OnInit {
  @Input("moduleType") moduleType: any;
  public flagSearchUser = false;
  public validUser: boolean = true;
  public options: Options;
  public errorMessage: string;
  //public errors: any = Array<string>();

  public userDataList: Array<any>;
  public userData: any;
  public userList: any = [];
  public userId: number = 0;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private loading: MessageService,
    private userService: UserService) {
  }

  ngOnInit(): void {
    this.options = {
      width: '300'
    };

    if (this.moduleType != null) {
      this.getUsersNotHaveAny(this.moduleType)
    }
  }

  getUsersNotHaveAny(moduleType: ModuleType) {
    this.flagSearchUser = true;
    this.userService.getAllNotHaveAny(moduleType, null, true).subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.userDataList = this.getTemplateUserList(response.data);
      } else {
        this.userDataList = new Array<any>();
      }
      this.flagSearchUser = false;
    }, (error: any) => {
      this.flagSearchUser = false;
    });
  }

  setUser(value: any) {
    this.userId = value;
    this.userData = this.userList.find(x => x.id == value);
    //this.getUserData(value);
  }

  done() {
    this.loading.showLoading();
    this.errorMessage = '';
    //this.errors = [];
    if (this.userId > 0) {
      this.validUser = true;
      if (this.moduleType != null && this.moduleType == ModuleType.Teacher) {
        this.router.navigate(['/admin/teacher', { userId: this.userId }]);
      } else {
        this.router.navigate(['/admin/student', { userId: this.userId }]);
      }
      this.cancel();
    } else {
      this.loading.hideLoading();
      this.validUser = false;
      this.errorMessage = "Nenhum usuário selecionado.";
    }    
  }

  getTemplateUserList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.userList = data;
      let arrayUserList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].id,
          text: data[i].id + " | " + data[i].name + " | " + data[i].email,
          additional: {
            email: data[i].email
          }
        };
        arrayUserList.push(element);
      }
      return arrayUserList;
    }
    return new Array<any>();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  cancel() {
    this.dismissModal();
  }
}
