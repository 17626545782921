<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Relatório de aulas confirmadas'</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>

<div class="row">
    <div class="col-md-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr">
                <h2>Filtro</h2>
            </div>
            <div class="panel-container show">
                <div class="panel-content p-0">
                    <div class="panel-content">
                        <div class="form-row">
                            <div class="col-md-4 mb-3">
                                <label class="form-label" for="startDate">Data inicial</label>
                                <input type="date" id="startDate" [(ngModel)]="startDate" class="form-control" placeholder="Data inicial" (change)="setStartDate($event)"/>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-label" for="endDate">Data final</label>
                                <input type="date" id="endDate" [(ngModel)]="endDate" class="form-control" placeholder="Data final" (change)="setEndDate($event)"/>
                            </div>
                        </div>
                    </div>
                    <div class="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row">
                        <button class="btn btn-primary ml-auto" type="button" [disabled]="flagFiltering" (click)="getClassesAbsencesReport()">
                            <span *ngIf="flagFiltering" class="spinner-border spinner-border-sm mr-1"></span>
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div id="panel-8" class="panel">
            <div class="panel-container show">
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="col-md-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-md-12 mt-3" *ngIf="showTable">
                        <managed-table [dataTitle]="" [rows]="classAbsencesData" [columns]="columns" 
                            [sorting]="false" [hasActions]="false" [ngTableFiltering]="false" [showFilterRow]="false"
                            [previewEnable]="false" [editEnable]="false" [deleteEnable]="false" [hasFilter]="false">
                        </managed-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
