<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/banklist']">Bancos</a>
    </li>
    <li class="breadcrumb-item active">Novo banco</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div id="panel-2" class="panel">
    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
            Novo <span class="fw-300"><i>Banco</i></span>
        </h2>
        <div class="panel-toolbar">
            <a href="javascript:void(0);" class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse">
                <i class="fal fa-minus text-white fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen">
                <i class="fal fa-expand text-primary fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Close">
                <i class="fal fa-times text-white fw-900"></i>
            </a>
        </div>
    </div>
    <div class="panel-container show">
        <div class="panel-content p-0">
            <form [formGroup]="bankForm" novalidate class="needs-validation" (ngSubmit)="onSubmit()">
                <div class="panel-content">
                    <div class="form-row" *ngIf="editBank">
                        <div class="col-md-4 mb-3">
                            <label>Id</label>
                            <br/>
                            <label class="form-control fw-700">{{f.id.value}}</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-4 mb-3">
                            <input type="hidden" id="id" formControlName="id" />
                            <input type="hidden" id="createdOn" formControlName="createdOn" *ngIf="!editBank"/>
                            <input type="hidden" id="updatedOn" formControlName="updatedOn" *ngIf="!editBank"/>
                            
                            <label class="form-label" for="code">Número <span class="text-danger">*</span> </label>
                            <input type="text" id="code" formControlName="code" class="form-control" placeholder="Número do banco" maxlength="5" [ngClass]="{ 'is-invalid': !validBank && f.code.errors }" required>
                            <div *ngIf="!validBank && f.code.errors" class="invalid-feedback">
                                <div *ngIf="f.code.errors.required">Por favor, informe o número do banco.</div>
                            </div>
                        </div>
                        <div class="col-md-8 mb-3">
                            <label class="form-label" for="name">Nome do banco <span class="text-danger">*</span> </label>
                            <input type="text" id="name" formControlName="name" class="form-control" placeholder="Nome do banco" maxlength="100" [ngClass]="{ 'is-invalid': !validBank && f.name.errors }" required>
                            <div *ngIf="!validBank && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Por favor, informe o nome do banco.</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validBank">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true"><i class="fal fa-times"></i></span>
                        </button>
                        {{errorMessage}}
                    </div>
                    <div *ngIf="listErrorMessage && !validBank" class="invalid-feedback">
                        <div *ngFor="let error of listErrorMessage">{{error}}</div>
                    </div>
                    <hr>
                    <div class="row no-gutters">
                        <div class="col-md-12 ml-auto text-right">
                            <div class="btn-group btn-panel">
                                <button id="js-login-btn" class="btn btn-primary" [disabled]="submitted">
                                    <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                    Salvar
                                </button>
                                <button id="js-cancel-btn" class="btn btn-default" (click)="onCancel()">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>