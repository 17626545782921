<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/teacher/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Perfil Teacher</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div id="panel-2" class="panel">
    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
            Atualização dos dados do Teacher
        </h2>
        <div class="panel-toolbar">
            <a href="javascript:void(0);" class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse" title="Minimizar painel">
                <i class="fal fa-minus text-white fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen" title="Maximizar painel">
                <i class="fal fa-expand text-primary fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Close" title="Fechar painel">
                <i class="fal fa-times text-white fw-900"></i>
            </a>
        </div>
    </div>
    <div class="panel-container show">
        <div class="panel-content p-0">
            <form [formGroup]="profileForm" novalidate class="needs-validation" (ngSubmit)="onSubmit()">
                <div class="panel-content">
                    <div class="form-row">
                        <div class="col-md-12">
                            <aw-wizard [navBarLayout]="'large-filled-symbols'" [awNavigationMode] navigateBackward="allow" [defaultStepIndex]="0" navBarLocation="top" navigateForward="allow" class="m-l-15 m-r-15">
                                <aw-wizard-step  id="basicData" stepTitle="Dados básicos" (click)="{stepIndex: 0}" [stepId]='1' >
                                    <ng-template awWizardStepTitle>
                                        <span class="hidden-sm-down">Dados básicos</span>
                                        <span class="hidden-md-up">Básico</span>
                                    </ng-template>
                                    <ng-template awWizardStepSymbol>
                                        <i class="far fa-user"></i>
                                    </ng-template>
                                    <div class="col-12">
                                        <div class="alert alert-primary alert-dismissible p-4">
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">
                                                    <i class="fal fa-times"></i>
                                                </span>
                                            </button>
                                            <div class="d-flex flex-start w-100">
                                                <div class="mr-2 hidden-sm-down">
                                                    <span class="icon-stack icon-stack-lg">
                                                        <i class="fal fa-calendar-alt icon-stack-3x text-primary"></i>
                                                    </span>
                                                </div>
                                                <div class="d-flex flex-fill">
                                                    <div class="flex-fill">
                                                        <span class="h5 text-primary fw-700">Bem-vindo à página de informações pessoais!</span>
                                                        <p class="mb-1">Aqui você pode fornecer os seus dados pessoais gerais para mantermos nossos registros atualizados e garantir uma comunicação eficaz.</p>
                                                        <p class="mb-1">Por favor, preencha os campos abaixo com suas informações, incluindo nome completo, data de nascimento, gênero, estado civil, telefone e e-mail. Garanta que todas as informações estejam corretas e atualizadas para garantir uma comunicação eficiente e para nos ajudar a oferecer o melhor suporte possível.</p>
                                                        <p class="text-primary fw-700">Se tiver alguma dúvida ou precisar de assistência, não hesite em entrar em contato conosco. Obrigado por sua colaboração!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="form-group">
                                        <div class="row d-flex justify-content-center mx-1">
                                            <input type="hidden" id="teacherId" formControlName="teacherId" />
                                            <div class="col-12">
                                                <label class="form-label" for="name">Nome <span class="text-danger">*</span> </label>
                                                <input type="text" id="name" formControlName="name" class="form-control" placeholder="Informe o nome do teacher" 
                                                    maxlength="50" [ngClass]="{ 'is-invalid': !validProfile && f.name.errors }" required>
                                                <div *ngIf="!validProfile && f.name.errors" class="invalid-feedback">
                                                    <div *ngIf="f.name.errors.required">Por favor, informe o nome do teacher.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row d-flex justify-content-center mx-1">
                                            <div class="col-md-6 col-12">
                                                <label class="form-label" for="email">E-mail <span class="text-danger">*</span> </label>
                                                <input type="email" id="email" formControlName="email" class="form-control" placeholder="exemplo@email.com.br"
                                                    maxlength="100" [ngClass]="{ 'is-invalid': !validProfile && f.email.errors }" required>
                                                <div *ngIf="!validProfile && f.email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.email.errors.required">Por favor, informe o e-mail do teacher.</div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="clearfix mb-3 d-block d-sm-none"></div>
                                                <label class="form-label" for="birthDate">Data de nascimento <span class="text-danger">*</span> </label>
                                                <input type="date" id="birthDate" formControlName="birthDate" class="form-control" placeholder="Informe a data de nascimento"
                                                    [ngClass]="{ 'is-invalid': !validProfile && f.birthDate.errors }" required>
                                                <div *ngIf="!validProfile && f.birthDate.errors" class="invalid-feedback">
                                                    <div *ngIf="f.birthDate.errors.required">Por favor, informe a data de nascimento.</div>
                                                    <div *ngIf="f.birthDate.errors.incorrect">A data informada é inválida.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row d-flex justify-content-center mx-1">
                                            <div class="col-12 mb-3">
                                                <hr>
                                                <div class="form-group">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" class="custom-control-input" id="foreignPerson" formControlName="foreignPerson" checked="" (change)="onForeignPerson($event)">
                                                        <label class="custom-control-label" for="foreignPerson">Mora no exterior ou não possui CPF?</label>
                                                    </div>
                                                    <div class="alert alert-success alert-dismissible fade show mb-0 mt-3" role="alert" *ngIf="flagForeignPerson">
                                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                                        </button>
                                                        <strong>Nice!</strong> Com essa opção ativa os campos não serão mais obrigatórios. <i class="fal fa-smile text-success"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <label class="form-label" for="cpf">CPF <span class="text-danger" *ngIf="!flagForeignPerson">*</span> </label>
                                                <input type="text" id="cpf" formControlName="cpf" class="form-control" placeholder="Informe o CPF" 
                                                    maxlength="15" [ngClass]="{ 'is-invalid': !validProfile && f.cpf.errors }" required (focusout)="checkTeacherByCpf($event)">
                                                <div *ngIf="!validProfile && f.cpf.errors" class="invalid-feedback">
                                                    <div *ngIf="f.cpf.errors.required">Por favor, informe o CPF do teacher.</div>
                                                    <div *ngIf="f.cpf.errors.incorrect">O CPF informado é inválido.</div>
                                                    <div *ngIf="f.cpf.errors.already_exists">O CPF informado já está cadastrado na base de teachers da English Coffee.</div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="clearfix mb-3 d-block d-sm-none"></div>
                                                <label class="form-label" for="phone">Telefone <span class="text-danger">*</span> </label>
                                                <input type="phone" id="phone" name="phone" formControlName="phone" class="form-control" maxlength="17"
                                                    placeholder="(00) 00000-0000" [ngClass]="{ 'is-invalid': !validProfile && f.phone.errors }" required>
                                                <div *ngIf="!validProfile && f.phone.errors" class="invalid-feedback">
                                                    <div *ngIf="f.phone.errors.required">Por favor, informe o telefone do teacher.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-0 mr-3">
                                        <div class="row">
                                            <div class="col-sm-12 text-right">
                                                <div id="btnButtonWizard" class="btn-group btn-group-sm btn-group-toggle text-right" data-toggle="buttons">
                                                    <button class="btn btn-info" data-action="toggle" data-class="nav-tabs-clean" disabled="true" awPreviousStep>Anterior</button>
                                                    <button class="btn btn-info active" data-action="toggle" data-class="nav-tabs-clean" awNextStep>Próximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aw-wizard-step>
                                <aw-wizard-step  id="addressData" stepTitle="Endereço" (click)="{stepIndex: 1}" [stepId]='2'>
                                    <ng-template awWizardStepTitle>
                                        <span class="hidden-sm-down">Endereço</span>
                                        <span class="hidden-md-up">Endereço</span>
                                    </ng-template>
                                    <ng-template awWizardStepSymbol>
                                        <i class="far fa-home"></i>
                                    </ng-template>
                                    <div class="col-12">
                                        <div class="alert alert-primary alert-dismissible p-4">
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">
                                                    <i class="fal fa-times"></i>
                                                </span>
                                            </button>
                                            <div class="d-flex flex-start w-100">
                                                <div class="mr-2 hidden-sm-down">
                                                    <span class="icon-stack icon-stack-lg">
                                                        <i class="fal fa-calendar-alt icon-stack-3x text-primary"></i>
                                                    </span>
                                                </div>
                                                <div class="d-flex flex-fill">
                                                    <div class="flex-fill">
                                                        <span class="h5 text-primary fw-700">Bem-vindo à página de informações de endereço residencial!</span>
                                                        <p class="mb-1">Aqui você pode fornecer os seus dados de endereço residencial para garantir uma comunicação eficaz e a entrega de correspondências importantes.</p>
                                                        <p class="mb-1">Por favor, preencha os campos abaixo com as suas informações de endereço, incluindo rua, número, complemento, bairro, cidade, estado e CEP. Certifique-se de que todas as informações estejam corretas para garantir uma entrega sem problemas.</p>
                                                        <p class="fw-700 text-primary mb-1">Se tiver alguma dúvida ou precisar de assistência, não hesite em entrar em contato conosco. Agradecemos pela sua colaboração!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="form-group" *ngIf="flagForeignPerson">
                                        <div class="row d-flex justify-content-center mx-1">
                                            <div class="col-12">
                                                <label class="form-label" for="street">Endereço <span class="text-danger">*</span> </label>
                                                <input type="text" id="street" formControlName="street" class="form-control" placeholder="Informe o endereço, número e complemento" 
                                                    maxlength="100" [ngClass]="{ 'is-invalid': !validProfile && f.street.errors }" required>
                                                <div *ngIf="!validProfile && f.street.errors" class="invalid-feedback">
                                                    <div *ngIf="f.street.errors.required">Por favor, informe o endereço.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="flagForeignPerson">
                                        <div class="row d-flex justify-content-center mx-1">
                                            <div class="col-md-6 col-12">
                                                <label class="form-label" for="cityName">Cidade <span class="text-danger">*</span> </label>
                                                <input type="text" id="cityName" formControlName="cityName" class="form-control" placeholder="Informe o nome da cidade" 
                                                    maxlength="100" [ngClass]="{ 'is-invalid': !validProfile && f.cityName.errors }" required>
                                                <div *ngIf="!validProfile && f.cityName.errors" class="invalid-feedback">
                                                    <div *ngIf="f.cityName.errors.required">Por favor, informe a cidade.</div>
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-12">
                                                <label class="form-label" for="stateUf">UF <span class="text-danger">*</span> </label>
                                                <input type="text" id="stateUf" formControlName="stateUf" class="form-control" placeholder="Informe a sigla do estado"
                                                    maxlength="5" [ngClass]="{ 'is-invalid': !validProfile && f.stateUf.errors }" required>
                                                <div *ngIf="!validProfile && f.stateUf.errors" class="invalid-feedback">
                                                    <div *ngIf="f.stateUf.errors.required">Por favor, informe o estado.</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-12">
                                                <label class="form-label" for="country">País <span class="text-danger">*</span> </label>
                                                <input type="text" id="country" formControlName="country" class="form-control" placeholder="Informe o país"
                                                    maxlength="50" [ngClass]="{ 'is-invalid': !validProfile && f.country.errors }" required>
                                                <div *ngIf="!validProfile && f.country.errors" class="invalid-feedback">
                                                    <div *ngIf="f.country.errors.required">Por favor, informe o país.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="!flagForeignPerson">
                                        <div class="row d-flex justify-content-center mx-1">
                                            <div class="col-lg-6 col-12">
                                                <label class="form-label" for="zipCode">CEP</label>
                                                <div class="input-group">
                                                    <input type="text" id="zipCode" formControlName="zipCode" class="form-control" placeholder="Informe o CEP" aria-label="CEP" #zipCode
                                                    maxlength="8" [ngClass]="{ 'is-invalid': !validProfile && f.zipCode.errors }" required aria-describedby="btnFindzipCode" (focusout)="loadAddressByCep(zipCode)">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-primary waves-effect waves-themed" type="button" id="btnFindzipCode" (click)="loadAddressByCep(zipCode)"><i class="fal fa-search"></i></button>
                                                        <span *ngIf="cepSearch" class="spinner-border spinner-border-sm ml-2 mt-2"></span>
                                                        <span class="mt-2 ml-2 text-primary" *ngIf="!cepSearch">
                                                            <i class="fa fa-angle-double-left"></i> Clique aqui para buscar o endereço
                                                        </span>
                                                        <span class="mt-2 ml-2 text-primary" *ngIf="cepSearch">
                                                            <i class="fa fa-angle-double-left"></i> Consultando CEP informado...
                                                        </span>
                                                    </div>
                                                </div>
                                                <div *ngIf="!validProfile && f.zipCode.errors" class="invalid-feedback">
                                                    <div *ngIf="f.zipCode.errors.required">Por favor, informe o CEP.</div>
                                                    <div *ngIf="f.zipCode.errors.invalidNumber">CEP inválido. Por favor, digite outro CEP.</div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-12">
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="!flagForeignPerson">
                                        <div class="row d-flex justify-content-center mx-1">
                                            <div class="col-md-6">
                                                <label class="form-label" for="street">Endereço <span class="text-danger">*</span> </label>
                                                <input type="text" id="street" formControlName="street" class="form-control" placeholder="Informe o endereço" 
                                                    maxlength="100" [ngClass]="{ 'is-invalid': !validProfile && f.street.errors }" required>
                                                <div *ngIf="!validProfile && f.name.errors" class="invalid-feedback">
                                                    <div *ngIf="f.street.errors.required">Por favor, informe o endereço.</div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <label class="form-label" for="number">Número <span class="text-danger">*</span> </label>
                                                <input type="text" id="number" formControlName="number" class="form-control" placeholder="Informe o número" 
                                                    maxlength="10" [ngClass]="{ 'is-invalid': !validProfile && f.number.errors }" required>
                                                <div *ngIf="!validProfile && f.number.errors" class="invalid-feedback">
                                                    <div *ngIf="f.number.errors.required">Por favor, informe o número.</div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <label class="form-label" for="complement">Complemento </label>
                                                <input type="text" id="complement" formControlName="complement" class="form-control" placeholder="Informe o complemento" 
                                                    maxlength="30" [ngClass]="{ 'is-invalid': !validProfile && f.complement.errors }">
                                                <div *ngIf="!validProfile && f.complement.errors" class="invalid-feedback">
                                                    <div *ngIf="f.complement.errors.required">Por favor, informe o complemento.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="!flagForeignPerson">
                                        <div class="row d-flex justify-content-center mx-1">
                                            <div class="col-md-6">
                                                <label class="form-label" for="neighborhood">Bairro <span class="text-danger">*</span> </label>
                                                <input type="text" id="neighborhood" formControlName="neighborhood" class="form-control" placeholder="Informe o bairro" 
                                                    maxlength="100" [ngClass]="{ 'is-invalid': !validProfile && f.neighborhood.errors }" required>
                                                <div *ngIf="!validProfile && f.name.errors" class="invalid-feedback">
                                                    <div *ngIf="f.neighborhood.errors.required">Por favor, informe o bairro.</div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label class="form-label" for="stateId">Estado <span class="text-danger">*</span> </label>
                                                <select class="form-control" id="stateId" for="stateId" formControlName="stateId" placeholder="Informe o estado"
                                                    [ngClass]="{ 'is-invalid': !validProfile && f.stateId.errors }" 
                                                    (change)="getCitiesByStateId(f.stateId.value)">
                                                    <option *ngFor="let state of stateList" [ngValue]="state.id">
                                                        {{ state.uf }}
                                                    </option>
                                                </select>
                                                <div *ngIf="!validProfile && f.stateId.errors" class="invalid-feedback">
                                                    <div *ngIf="f.stateId.errors.required">Por favor, informe o estado.</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="form-label" for="cityId">Cidade <span class="text-danger">*</span> </label>
                                                <select class="form-control" id="cityId" for="cityId" formControlName="cityId" placeholder="Informe o cidade"
                                                    [ngClass]="{ 'is-invalid': !validProfile && f.cityId.errors }">
                                                    <option *ngFor="let ct of citiesList" [ngValue]="ct.id">
                                                        {{ ct.name }}
                                                    </option>
                                                </select>
                                                <div *ngIf="!validProfile && f.cityId.errors" class="invalid-feedback">
                                                    <div *ngIf="f.cityId.errors.required">Por favor, informe a cidade.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-0 mr-3">
                                        <div class="row">
                                            <div class="col-sm-12 text-right">
                                                <div id="btnButtonWizard" class="btn-group btn-group-sm btn-group-toggle text-right" data-toggle="buttons">
                                                    <button class="btn btn-info" data-action="toggle" data-class="nav-tabs-clean" awPreviousStep>Anterior</button>
                                                    <button class="btn btn-info active" data-action="toggle" data-class="nav-tabs-clean" awNextStep>Próximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aw-wizard-step>
                                <aw-wizard-step  id="bankData" stepTitle="Dados bancários" (click)="{stepIndex: 2}" [stepId]='3'>
                                    <ng-template awWizardStepTitle>
                                        <span class="hidden-sm-down">Dados bancários</span>
                                        <span class="hidden-md-up">Dados bancários</span>
                                    </ng-template>
                                    <ng-template awWizardStepSymbol>
                                        <i class="far fa-dollar-sign"></i>
                                    </ng-template>
                                    <div class="col-12">
                                        <div class="alert alert-primary alert-dismissible p-4">
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">
                                                    <i class="fal fa-times"></i>
                                                </span>
                                            </button>
                                            <div class="d-flex flex-start w-100">
                                                <div class="mr-2 hidden-sm-down">
                                                    <span class="icon-stack icon-stack-lg">
                                                        <i class="fal fa-calendar-alt icon-stack-3x text-primary"></i>
                                                    </span>
                                                </div>
                                                <div class="d-flex flex-fill">
                                                    <div class="flex-fill">
                                                        <span class="h5 text-primary fw-700">Bem-vindo à página de informações bancárias!</span>
                                                        <br>
                                                        <p class="mb-1">Aqui você pode fornecer os seus dados bancários para receber os pagamentos das suas aulas.</p>
                                                        <p class="mb-1">Por favor, preencha os campos abaixo com as informações necessárias, incluindo o nome do banco, tipo de conta, número da agência, número da conta e chave PIX, se aplicável.</p>
                                                        <p class="mb-1">Garanta que todas as informações estejam corretas para evitar qualquer atraso no recebimento dos seus pagamentos.</p>
                                                        <p class="fw-700 text-primary mb-1">Se tiver alguma dúvida, não hesite em entrar em contato conosco. Obrigado por sua colaboração!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="form-group row">
                                        <div class="row d-flex justify-content-center mx-1">
                                            <div class="col-6">
                                                <label class="form-label" for="bankId">Banco <span class="text-danger">*</span> </label>
                                                <select class="form-control" id="bankId" for="bankId" formControlName="bankId" placeholder="Banco"
                                                    [ngClass]="{ 'is-invalid': !validProfile && f?.bankId.errors }">
                                                    <option *ngFor="let bank of bankList" [ngValue]="bank.id">
                                                        {{ bank.name + " " + bank.code }}
                                                    </option>
                                                </select>
                                                <div *ngIf="!validProfile && f?.bankId.errors" class="invalid-feedback">
                                                    <div *ngIf="f?.bankId.errors.required">Por favor, informe o banco.</div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <label class="form-label">Tipo de Conta <span class="text-danger">*</span> </label>
                                                <br>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" class="custom-control-input" formControlName="accountType" 
                                                    name="accountType" id="radioAccountTypeCc"
                                                    [ngClass]="{ 'is-invalid': !validProfile && f?.accountType.errors }" 
                                                    value="0" (change)="onAccountTypeChecked($event)"/>
                                                    <label class="custom-control-label" for="radioAccountTypeCc">Conta Corrente</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" class="custom-control-input" formControlName="accountType" 
                                                    name="accountType" id="radioAccountTypeCp"
                                                    [ngClass]="{ 'is-invalid': !validProfile && f?.accountType.errors }" 
                                                    value="1" (change)="onAccountTypeChecked($event)"/>
                                                    <label class="custom-control-label" for="radioAccountTypeCp">Conta Poupança</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" class="custom-control-input" formControlName="accountType" 
                                                    name="accountType" id="radioAccountTypeCs"
                                                    [ngClass]="{ 'is-invalid': !validProfile && f?.accountType.errors }" 
                                                    value="2" (change)="onAccountTypeChecked($event)"/>
                                                    <label class="custom-control-label" for="radioAccountTypeCs">Conta Salário</label>
                                                </div>
                                                <div *ngIf="!validProfile && f?.accountType.errors" class="invalid-feedback">
                                                    <div *ngIf="f?.accountType.errors.required">Por favor, informe o tipo de conta.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="row d-flex justify-content-center mx-1">
                                            <div class="col-4">
                                                <label class="form-label" for="agency">Agência </label>
                                                <input type="text" id="agency" formControlName="agency" class="form-control" placeholder="Agência" 
                                                    maxlength="8" [ngClass]="{ 'is-invalid': !validProfile && f?.agency.errors }">
                                                <div *ngIf="!validProfile && f?.agency.errors" class="invalid-feedback">
                                                    <div *ngIf="f?.agency.errors.required">Por favor, informe a agência.</div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <label class="form-label" for="account">Conta bancária </label>
                                                <input type="text" id="account" formControlName="account" class="form-control" placeholder="Conta bancária" 
                                                    maxlength="10" [ngClass]="{ 'is-invalid': !validProfile && f?.account.errors }">
                                                <div *ngIf="!validProfile && f?.account.errors" class="invalid-feedback">
                                                    <div *ngIf="f?.account.errors.required">Por favor, informe a conta bancária.</div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <label class="form-label" for="pix">Chave PIX </label>
                                                <input type="text" id="pix" formControlName="pix" class="form-control" placeholder="Chave PIX" 
                                                    maxlength="100" [ngClass]="{ 'is-invalid': !validProfile && f?.pix.errors }">
                                                <div *ngIf="!validProfile && f?.pix.errors" class="invalid-feedback">
                                                    <div *ngIf="f?.pix.errors.required">Por favor, informe a pix.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-0 mr-3">
                                        <div class="row">
                                            <div class="col-sm-12 text-right">
                                                <div id="btnButtonWizard" class="btn-group btn-group-sm btn-group-toggle text-right" data-toggle="buttons">
                                                    <button class="btn btn-info" data-action="toggle" data-class="nav-tabs-clean" awPreviousStep>Anterior</button>
                                                    <button class="btn btn-info active" data-action="toggle" data-class="nav-tabs-clean" awNextStep>Próximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aw-wizard-step>
                                <aw-wizard-step  id="availabilityData" stepTitle="Disponibilidade de agenda" (click)="{stepIndex: 3}" [stepId]='4'>
                                    <ng-template awWizardStepTitle>
                                        <span class="hidden-sm-down">Disponibilidade</span>
                                        <span class="hidden-md-up">Disponibilidade de agenda</span>
                                    </ng-template>
                                    <ng-template awWizardStepSymbol>
                                        <i class="far fa-calendar-alt"></i>
                                    </ng-template>
                                    <div class="col-12">
                                        <div class="alert alert-primary alert-dismissible p-4">
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">
                                                    <i class="fal fa-times"></i>
                                                </span>
                                            </button>
                                            <div class="d-flex flex-start w-100">
                                                <div class="mr-2 hidden-sm-down">
                                                    <span class="icon-stack icon-stack-lg">
                                                        <i class="fal fa-calendar-alt icon-stack-3x text-primary"></i>
                                                    </span>
                                                </div>
                                                <div class="d-flex flex-fill">
                                                    <div class="flex-fill">
                                                        <span class="h5 text-primary fw-700">Bem-vindo à página de configuração de horários de aula!</span>
                                                        <br>
                                                        <p class="mb-1">Aqui você pode selecionar os horários disponíveis para lecionar suas aulas, com intervalos de 30 minutos.</p>
                                                        <p class="mb-1">Cada dia da semana está disposto em um range de 0:00 às 23:30, representando todas as horas possíveis para agendar suas aulas. Ao selecionar um horário, lembre-se de que você estará disponível até o próximo intervalo de 30 minutos.</p>
                                                        <p class="mb-1 fw-700">Por exemplo, se você selecionar o horário de 16:00, isso indica que você está disponível das 16h às 16h30.</p>
                                                        <p class="mb-1 text-primary fw-700">Certifique-se de ajustar seus horários de acordo com sua disponibilidade e aproveite ao máximo essa ferramenta!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="col-12" *ngIf="showAvailabilityData">
                                        <div class="table-responsive-lg">
                                            <table class="table table-bordered m-0">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th *ngFor="let day of (daysOfWeekDataList$ | async)" class="text-center">{{day.text}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let hour of hoursOfDay">
                                                        <th *ngFor="let day of (daysOfWeekDataList$ | async)" class="text-center fw-700 check-time" (click)="setTeacherAvailability(day.id, hour)"
                                                            [ngClass]="checkAvailability(day.id, hour) ? 'bg-success text-white' : 'bg-white text-secondary'">
                                                            {{hour}}
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validAvailability">
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                            </button>
                                            {{errorMessageAvailability}}
                                        </div>
                                        <div *ngIf="errorMessageAvailability && !validAvailability" class="invalid-feedback">
                                            <div *ngFor="let error of listErrorMessageAvailability">{{error}}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <div class="form-group mb-0">
                                            <div class="row">
                                                <div class="col-sm-12 text-right">
                                                    <div id="btnButtonWizard" class="btn-group btn-group-sm btn-group-toggle text-right" data-toggle="buttons">
                                                        <button class="btn btn-info" data-action="toggle" data-class="nav-tabs-clean" awPreviousStep>Anterior</button>
                                                        <button class="btn btn-info active" data-action="toggle" data-class="nav-tabs-clean" disabled="true" awNextStep>Próximo</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aw-wizard-step>
                            </aw-wizard>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!validProfile">
                        <div class="row d-flex justify-content-center mx-3">
                            <div class="col-12">
                                <hr>
                                <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validProfile">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true"><i class="fal fa-times"></i></span>
                                    </button>
                                    {{errorMessage}}
                                </div>
                                <br>
                                <div *ngIf="listErrorMessage && !validProfile" class="invalid-feedback">
                                    <div *ngFor="let error of listErrorMessage">{{error}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <footer-form [auxSaveAction]="true" [auxCancelAction]="true" (saveClick)="onSubmit()" (cancelClick)="onCancel()"></footer-form>
    </div>
</div>
