<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Turmas</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>

<div class="row">
    <div class="col-md-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr">
                <h2>Filtro</h2>
                <!-- <div class="panel-toolbar ml-2">
                    <button class="btn btn-sm btn-primary" (onclick)="getConfirmedClasses()">Filtrar</button>
                </div> -->
            </div>
            <div class="panel-container show">
                <div class="panel-content p-0">
                    <div class="panel-content">
                        <div class="form-row">
                            <div class="col-6 mb-3">
                                <span role="status" aria-hidden="true" class="spinner-border spinner-border-sm pull-left mr-2 text-primary" *ngIf="flagSearchTeacher"></span>
                                <span class="text-primary pull-left" *ngIf="flagSearchTeacher">Carregando professores</span>
                                <label class="form-label" for="teacherId">Teacher <span class="text-danger">*</span> </label>
                                <div class="ng-autocomplete w-100">
                                    <ng-autocomplete 
                                        [data]="teacherDataList"
                                        searchKeyword="name"
                                        placeholder="Selecione o teacher"
                                        (selected)='setTeacher($event)'
                                        (inputCleared)='setTeacher($event)'
                                        [itemTemplate]="itemTemplate"
                                        [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>
                                    
                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.name"></a>
                                    </ng-template>
                                    
                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="col-6 mb-3">
                                <label class="form-label" for="courseId">Turma</label>
                                <div class="ng-autocomplete w-100">
                                    <ng-autocomplete 
                                        [data]="courseDataList"
                                        searchKeyword="name"
                                        placeholder="Selecione a turma"
                                        (selected)='setCourse($event)'
                                        (inputCleared)='setCourse($event)'
                                        [itemTemplate]="itemTemplate"
                                        [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>
                                    
                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.name"></a>
                                    </ng-template>
                                    
                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label class="form-label" for="dayOfWeek">Dia da Semana</label>
                                <div class="ng-autocomplete w-100">
                                    <ng-autocomplete 
                                        [data]="dayOfWeekDataList"
                                        searchKeyword="name"
                                        placeholder="Selecione o dia da semana"
                                        (selected)='setDayOfWeek($event)'
                                        (inputCleared)='setDayOfWeek($event)'
                                        [itemTemplate]="itemTemplate"
                                        [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>
                                    
                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.name"></a>
                                    </ng-template>
                                    
                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label class="form-label" for="dayOfWeek">Período do dia</label>
                                <div class="ng-autocomplete w-100">
                                    <ng-autocomplete 
                                        [data]="timeDayDataList"
                                        searchKeyword="name"
                                        placeholder="Selecione o dia da semana"
                                        (selected)='setTimeDay($event)'
                                        (inputCleared)='setTimeDay($event)'
                                        [itemTemplate]="itemTemplate"
                                        [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>
                                    
                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.name"></a>
                                    </ng-template>
                                    
                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label class="form-label" for="hourFilter">Horário específico</label>
                                <input type="time" id="hourFilter" [(ngModel)]="hourFilter" class="form-control" placeholder="Horário"/>
                            </div>
                            <div class="col-md-3 mb-3">
                                <label class="form-label">.</label>
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="onlyAvailable" formControlName="onlyAvailable" 
                                        checked="" [(ngModel)]="onlyAvailable" (change)="setOnlyAvailable($event)">
                                    <label class="custom-control-label" for="onlyAvailable">Somente posições disponíveis?</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row">
                        <button class="btn btn-primary ml-auto" type="button" [disabled]="flagFiltering" (click)="filter()">
                            <span *ngIf="flagFiltering" class="spinner-border spinner-border-sm mr-1"></span>
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>Lista de Turmas</h2>
                <!-- <div class="panel-toolbar ml-2">
                    <button type="button" class="btn btn-success btn-xs" data-toggle="tooltip" title="Nova Turma" [routerLink]="['/admin/course']">Novo</button>
                </div> -->
            </div>
            <div class="panel-container show">
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="col-sm-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-sm-12" *ngIf="showTable">
                        <managed-table [dataTitle]="" [rows]="gridList" [columns]="columns" [sorting]="false" [hasActions]="true" [ngTableFiltering]="false" [showFilterRow]="false"
                            [previewEnable]="false" [editEnable]="false" [deleteEnable]="false" [hasFilter]="false"
                            [auxEnable]="true" [auxIcon]="auxIcon" [titleAux]="auxTitle" (auxClick)="manageStudents($event)">
                        </managed-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <ng-template #mdAddStudentCourse let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h5 class="modal-title">Incluir aluno na turma</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body">
                <studentcourse-add courseId="{{courseId}}"></studentcourse-add>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Close</button>
            </div>
        </ng-template>
    </div>
</div>
