import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '@app/shared/services';
import { InitTeacherService } from '@app/teacher/services';
import { AccountService } from '@app/auth/services';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { User } from '@app/auth/model';
import { environment } from '@environments/environment';
import { ContentJsonService } from '@app/shared/services/contentJson.service';

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.css']
})
export class ContentListComponent implements OnInit {
  public itemSerie: string;
  returnUrl: string;
  public serieContent: any;
  public contentPath: string;
  public imgFileName: string;
  user: User;
  private type: string;
  public isTraining : boolean = false;
  private contentFile: string = `${environment.contentFiles}`;
  private structureFile: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private loading: MessageService,
    private initTeacher: InitTeacherService,
    private contentJsonService: ContentJsonService) {
      this.user = this.accountService.getUserData();
      this.structureFile = `${this.contentFile}/structure-files.json`;
  }

  ngOnInit(): void {
    this.loading.hideLoading();
    this.initTeacher.setConfigPage();
    this.returnUrl = '/teacher/course';

    this.activatedRoute.params.subscribe(params => {
      this.itemSerie = params['serie'];
      this.type = params['type'];
      if (this.itemSerie) {
        this.loadSerie(this.type, this.itemSerie);
      } else {
        this.router.navigate([this.returnUrl]);
      }
    });
  }

  public loadSerie(type: any, serie: any) {
    this.contentJsonService.SerieByName(type, serie).subscribe((response: any) => {
      this.serieContent = response;
      this.contentPath = `${this.contentFile}/pecm/${this.type}/${this.serieContent.folderName}/`;
    });
  }

  private getJSON(): Observable<any> {
    var call = this.http.get<any>(this.structureFile);
    return call.pipe(map(response => {
      return response
    }));
  }

  viewClass(itemSerie: string, itemEpisode: string, name: string, index: number): void {
    this.loading.showLoading();
    this.router.navigate(['/teacher/contentview', { type: this.type, serie: itemSerie, episode: itemEpisode, index: index+1 }], {state: {data: this.getEpisode(itemSerie, itemEpisode, name) }});
    this.loading.hideLoading();
  }

  private getEpisode(itemSerie: string, itemEpisode: string, name: string): any {
    this.contentPath = `${this.contentFile}/pecm/${this.type}/${this.serieContent.folderName}/`;
    const video = this.contentPath + itemEpisode + '/' + itemEpisode + '.mp4';
    const docPdf = this.contentPath + itemEpisode + '/' + itemEpisode + 'AP.pdf';
    const videoType = 'video/mp4';
    const subtitleEn = this.contentPath + itemEpisode + '/' + itemEpisode + 'EN.vtt';
    const subtitlePt = this.contentPath + itemEpisode + '/' + itemEpisode + 'PT.vtt';

    return {
      video,
      docPdf,
      videoType,
      subtitleEn,
      subtitlePt,
      serieName: this.serieContent.name,
      episodeName: this.isTraining ? name : itemEpisode
    };
  }
}
