<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Gestão de Interessados</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div class="row">
    <div class="col-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>Gestão de Interessados</h2>
                <div class="panel-toolbar ml-2">
                    <button type="button" class="btn btn-secondary btn-xs mr-2" data-toggle="tooltip" title="Restaurar Status" 
                        (click)="resetStatusPage()" *ngIf="this.statusCrm !== null">
                        <i class="fa fa-refresh"></i> Restaurar Status
                    </button>
                    <button type="button" class="btn btn-success btn-xs" data-toggle="tooltip" title="Novo Interessado" [routerLink]="['/marketing/contactbysite']">Novo</button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="col-sm-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-sm-12" *ngIf="showTable">
                        <managed-table [dataTitle]="" [rows]="contacts" [columns]="columns" [hasActions]="true" [ngTableFiltering]="true" [showFilterRow]="false" [hasFilter]="true" 
                            [previewEnable]="true" (previewClick)="preview($event)" 
                            [editEnable]="true" (editClick)="edit($event)" 
                            [deleteEnable]="true" (deleteClick)="delete($event)"
                            [auxEnable]="true" [auxIcon]="iconChangeStatus" [titleAux]="titleChangeStatus" (auxClick)="onChangeStatus($event, mdChangeStatus)"
                            [auxEnable2]="true" [auxIcon2]="iconPreRegister" [titleAux2]="titlePreRegister" (auxClick2)="newContactPreRegister($event, mdContactPreRegister)">
                        </managed-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #mdChangeStatus let-c="close" let-d="dismiss">
    <contact-change-status contactId="{{contactId}}"></contact-change-status>
</ng-template>

<ng-template #mdContactPreRegister let-c="close" let-d="dismiss">
    <contact-pre-register contactId="{{contactId}}"></contact-pre-register>
</ng-template>
