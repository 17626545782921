export enum ModuleType {
  Admin = 0,
  Student = 1,
  Teacher = 2,
  Master = 3
}

export const ModuleTypeLabel = new Map<number, string>([
  [ModuleType.Admin, 'Administrativo'],
  [ModuleType.Student, 'Aluno'],
  [ModuleType.Teacher, 'Professor'],
  [ModuleType.Master, 'Master']
]);

export const ModuleTypeLabelEn = new Map<number, string>([
  [ModuleType.Admin, 'Admin'],
  [ModuleType.Student, 'Student'],
  [ModuleType.Teacher, 'Teacher'],
  [ModuleType.Master, 'Master']
]);

export const ModuleTypeCss = new Map<number, string>([
  [ModuleType.Admin, 'module-admin'],
  [ModuleType.Student, 'module-student'],
  [ModuleType.Teacher, 'module-teacher'],
  [ModuleType.Master, 'module-master']
]);
