import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseTypeCss, CourseTypeLabel } from '@app/shared/enum';
import { ClassesAbsencesReportService } from '@app/reports/services';
import { Options } from 'select2';

@Component({
  selector: 'classes-absences-report',
  templateUrl: './classes-absences-report.component.html',
  providers: [DatePipe] })
export class ClassesAbsencesReportComponent implements OnInit {
  closeResult: string;
  public classAbsencesData: any = [];

  public flagFiltering: boolean = false;
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public startDate: any = new Date();
  public endDate: any = new Date();
  
  public options: Options;

  public columns: Array<any> = [
    { 
      title: "Aluno(a)", 
      name: "student.name", 
      sort: "asc",
      isHtmlData: true,
      className: "text-left"
    },
    { 
      title: "Telefone", 
      name: "student.phone", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center fw-700"
    },
    { 
      title: "Turma", 
      name: "course.name", 
      sort: "asc",
      isHtmlData: true,
      className: "text-left"
    },
    {
      title: "Tipo", 
      name: "designCourseType", 
      sort: "asc",
      isHtmlData: true,
      className: "text-center"
    },
    {
      title: "Aulas<br>Agendadas",
      name: "scheduleClasses",
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-center text-primary fw-700"
    },
    {
      title: "Faltas<br>Computadas",
      name: "absencesClasses",
      sort: "asc",
      isHtmlData: true,
      className: "text-center text-center text-primary fw-700"
    }
  ];
  
  constructor(
    private initAdmin: InitAdminService,
    private classesAbsencesReportService: ClassesAbsencesReportService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.options = {
      width: '300'
    };
    this.classAbsencesData = new Array<any>();
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.getClassesAbsencesReport();
  }

  getClassesAbsencesReport() {
    this.showTable = false;
    this.flagFiltering = true;
    this.classesAbsencesReportService.classesAbsences(
      formatDate(this.startDate, 'yyyy-MM-dd', 'en-US'), 
      formatDate(this.endDate, 'yyyy-MM-dd', 'en-US'))
    .pipe(first()).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.customDesignList(response.data);
        this.showTable = true;
        this.flagFiltering = false;
        this.returnMessage = '';
      } else {
        this.flagFiltering = false;
        this.returnMessage = response.message;
        this.showTable = false;
      }
    }, (error: any) => {
      this.flagFiltering = false;
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar os dados do relatório.';
    });
  }

  customDesignList(lista: any[]) {
    lista.forEach(function(e) {
      if (e.course.courseType >= 0) {
        let courseTypeCss = CourseTypeCss.get(e.course.courseType);
        let courseTypeStr = CourseTypeLabel.get(e.course.courseType)
        e.designCourseType = "<span class='badge badge-pill w-100 "
          + courseTypeCss +"'>"
          + courseTypeStr +"</span>";
      }
    });
    this.classAbsencesData = lista;
  }

  formatTimeDiff(date1: Date, date2: Date) {
    return Array(3)
      .fill([3600, date1.getTime() - date2.getTime()])
      .map((v, i, a) => {
        a[i+1] = [a[i][0]/60, ((v[1] / (v[0] * 1000)) % 1) * (v[0] * 1000)];
        return `0${Math.floor(v[1] / (v[0] * 1000))}`.slice(-2);
      }).join(':');
  }
  
  setStartDate(event: any) {
    var value = event.target.value;
    if (value) {
      var year = value.split('-')[0];
      var month = value.split('-')[1];
      var day = value.split('-')[2];
      this.startDate = new Date(year, month - 1, day, 0, 0, 0, 0);
    }
  }

  setEndDate(event: any) {
    var value = event.target.value;
    if (value) {
      var year = value.split('-')[0];
      var month = value.split('-')[1];
      var day = value.split('-')[2];
      this.endDate = new Date(year, month - 1, day, 0, 0, 0, 0);
    }
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      //this.clear();
  
      console.log(reason);
  
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return  `with: ${reason}`;
      }
    }
  }

  // showInfoConfirmedClasses(row: any, content: any) {
  //   if (row) {
  //     this.confirmedClassesData = row;
  //     this.modalService.dismissAll();
  //     this.modalService.open(content, { backdrop: 'static', size:'lg', windowClass:'animated fade'}).result.then((result) => {
  //       this.closeResult = `Closed with: ${result}`;
  //     }, (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     });
  //   }
  // }
}
