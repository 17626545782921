import { StatusCrm, StatusCrmCss, StatusCrmLabel } from '@app/shared/enum/StatusCrm';
import { ContactStatus, ContactStatusCss, ContactStatusLabel } from '@app/shared/enum/ContactStatus';
import { Base } from '@app/shared/model';
import { User } from './user';
import { Teacher } from './teacher';

export class ContactBySite extends Base {
    id: number;
    name: string;
    email: string;
    phone: string;
    message: string;
    ipAddress: string;
    contactStatus: ContactStatus;
    contactStatusStr: string;
    contactStatusCss: string;
    statusCrm: StatusCrm;
    statusCrmStr: string;
    statusCrmCss: string;    
    canGenerateUser: boolean;
    userId: number;
    user: User;
    contactBySiteStatusChanges: Array<any>;
    experimentalClass: Date;
    experimentalClassTeacherId: number;
    experimentalClassTeacher: Teacher;

    constructor(contentResponse: any) {
        super(contentResponse);
        
        this.id = contentResponse.id;
        this.name = contentResponse.name;
        this.email = contentResponse.email;
        this.phone = contentResponse.phone;
        this.ipAddress = contentResponse.ipAddress;
        this.message = contentResponse.message;
        this.contactStatus = contentResponse.contactStatus;
        this.statusCrm = contentResponse.statusCrm;
        this.canGenerateUser = contentResponse.canGenerateUser;

        if (!this.statusCrm) {
            this.statusCrm = 0;
        }

        if (!this.contactStatus) {
            this.contactStatus = 0;
        }

        this.userId = parseInt(contentResponse.userId);
        if (contentResponse && contentResponse.userId > 0) {
            this.user = contentResponse.user;
        }

        this.experimentalClassTeacherId = parseInt(contentResponse.experimentalClassTeacherId);
        if (contentResponse && contentResponse.experimentalClassTeacherId > 0) {
            this.experimentalClassTeacher = contentResponse.experimentalClassTeacher;
        }

        this.statusCrmStr = StatusCrmLabel.get(this.statusCrm);
        this.statusCrmCss = StatusCrmCss.get(this.statusCrm);

        this.contactStatusStr = ContactStatusLabel.get(this.contactStatus);
        this.contactStatusCss = ContactStatusCss.get(this.contactStatus);

        this.contactBySiteStatusChanges = this.contactBySiteStatusChanges;

        this.experimentalClass = contentResponse.experimentalClass
            ? new Date(contentResponse.experimentalClass)
            : null;
    }
}