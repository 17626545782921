<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Checklist</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div class="row">
    <!-- begin col-12 -->
    <div class="col-xl-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr bg-primary-600 bg-primary-gradient">
                <h2>Lista de Checklist</h2>
                <div class="panel-toolbar ml-2">
                    <button type="button" class="btn btn-success btn-xs" data-toggle="tooltip" title="Novo Check-List" [routerLink]="['/admin/checkList']">Novo</button>
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="col-sm-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-sm-12" *ngIf="showTable">
                        <managed-table [dataTitle]="" [rows]="checkLists" [columns]="columns" [hasActions]="true" [ngTableFiltering]="true" [showFilterRow]="false"
                            [previewEnable]="true" [editEnable]="true" [deleteEnable]="true" (editClick)="edit($event)" [hasFilter]="true">
                        </managed-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end col-12 -->
</div>
<!-- end row -->
