export class User {
    id: number;
    username: string;
    password: string;
    name: string;
    city: string;
    state: string;
    module: string;
    role: string;
    userRoles: any;
    primarysid: string;
    userId: string;
    userKids: boolean;
    refreshToken: string;
}
