export enum TypeResponse {
  Success = 0,
  Info = 1,
  Alert = 2,
  Error = 3
}

export const TypeResponseLabel = new Map<number, string>([
  [TypeResponse.Success, 'Sucesso'],
  [TypeResponse.Info, 'Informação'],
  [TypeResponse.Alert, 'Alerta'],
  [TypeResponse.Error, 'Erro']
]);