export class TokenData {
    accessToken: string;
    unique_name: string;
    jti: string;
    email: string;
    sid: string;
    given_name: string;
    role: any;
    primarysid: any;
    groupsid: string;
    aud: string;
    nbf: string;
    exp: string;
    iat: string;
    iss: string;
    nonce: string;
    at_hash: string;
    rsa: string;
}
