import { NgModule, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
//import { ListViewModule } from '@syncfusion/ej2-angular-lists';
//import { NgxDropzoneModule } from 'ngx-dropzone';
//import { NgSelect2Module } from 'ng-select2';

import { SharedModule } from '@app/shared/shared.module';
import { ContactBySiteListComponent } from './components/contactBySite/contactBySite-list/contactBySite-list.component';
import { ContactBySiteComponent } from './components/contactBySite/contactBySite/contactBySite.component';
import { ChangeStatusComponent } from './components/contactBySite/change-status/change-status.component';
import { AdminModule } from '@app/admin/admin.module';
import { TextMaskModule } from '../_helpers/mask/text.mask';
import { FinancialModule } from '@app/financial/financial.module';
import { AdminRoutingModule } from '@app/admin/admin-routing.module';
import { MarketingRoutingRoutes } from './marketing-routing.module';
import { GroupNotificationListComponent } from './components/groupNotification/groupNotification-list/groupNotification-list.component';
import { GroupNotificationComponent } from './components/groupNotification/groupNotification/groupNotification.component';
import { NotificationListComponent } from './components/notification/notification-list/notification-list.component';
import { NotificationComponent } from './components/notification/notification/notification.component';
import { MessageQueueComponent } from './components/messageQueue/messageQueue.component';
import { ContactPreRegisterComponent } from './components/contactBySite/pre-register/pre-register.component';


export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
    GroupNotificationListComponent,
    ContactPreRegisterComponent,
    GroupNotificationComponent,
    NotificationListComponent,
    NotificationComponent,
    MessageQueueComponent,
    ContactBySiteListComponent,
    ContactBySiteComponent,
    ChangeStatusComponent
  ],
  imports: [
    MarketingRoutingRoutes,
    AdminModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    //NgxDropzoneModule,
    ChartsModule,
    TextMaskModule,
    ArchwizardModule,
    FinancialModule,
    AdminRoutingModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule.forRoot(),
    //ListViewModule,
    //NgSelect2Module,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
  ],
  exports: [
    MessageQueueComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [ DatePipe ]
})
export class MarketingModule { }
