export class ChangePassword {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    userId: string;

    constructor(contentResponse: any) {
        this.userId = contentResponse.userId;
        this.oldPassword = contentResponse.oldPassword;
        this.newPassword = contentResponse.newPassword;
        this.confirmNewPassword = contentResponse.confirmNewPassword;
    }
}
