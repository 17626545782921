import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError, map } from 'rxjs/operators';
import { TypeResponse } from '@app/shared/enum';
import { ResponseResult } from '@app/auth/model';
import { Student } from '@app/admin/model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  private route: string = "admin";
  private controller: string = "student";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getall(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  infoStudentCourses(id: number): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/infoStudentCourses/${id}`);
  }

  getWithoutMe(id: number): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getWithoutMe/${id}`);
  }

  getById(studentId: string): any {
    let params = new HttpParams();
    params = params.append('id', studentId);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getById`, {params: params});
  }

  getByUserId(userId: any): any {
    let params = new HttpParams();
    params = params.append('userId', userId.toString());
    let call = this.http.get<ResponseResult>(`${this.endpointPrefix}/getByUserId`, {params: params});

    return call.pipe(map(response => {
      if (response.typeResponse == TypeResponse.Success) {
        return response.data;
      } else {
        return null;
      }
    }, (error: any) => {
      return null;
    }));
  }

  save(student: Student, editing: boolean = false, firstAccess: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/update`, student);
    } else { //new
      let params = new HttpParams();
      params = params.append('firstAccess', firstAccess.toString());
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/add`, student, {params: params});
    }
  }

  remove(id: string) {
    let params = new HttpParams();
    params = params.append('id', id);
    var call = this.http.delete<ResponseResult>(`${this.endpointPrefix}/remove`, {params: params});

    return call.pipe(map((response: any) => {
        return response;
    }), catchError(error => {
      if (error.status == 400) {
          if (error.errors) {
              return Observable.of(error.errors);
          }
      }
      return error;
    }));
  }

  checkStudentByCpf(cpf: string): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/checkStudent/${cpf}`);
  }
}
