import { Base } from '@app/shared/model';

export class WritingTest extends Base {
    name: string;
    order: number;
    usageCount: number;
    contentHtml: string;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.name = contentResponse.name;
        this.order = parseInt(contentResponse.order);
        this.usageCount = parseInt(contentResponse.usageCount);
        this.contentHtml = contentResponse.contentHtml;
    }
}
