import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { default as swal } from 'sweetalert2';
import { Router } from '@angular/router';
import moment from 'moment';
import { first, map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { CalendarOptions, EventApi } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import Locale from '@fullcalendar/core/locales/pt-br';
import { CryptoService } from '@app/shared/services';
import { environment } from '@environments/environment';
import { User } from '@app/auth/model';

import {
  CourseTypeCss,
  CourseTypeLabel,
  ModuleType,
  StatusClass,
  StatusClassCss,
  StatusClassLabel,
} from '@app/shared/enum';
import { StudentClassesCourseConfirmedParams, Teacher } from '@app/admin/model';
import {
  CalendarUtilService,
  MessageService,
  UtilService,
} from '@app/shared/services';
import { InitTeacherService } from '@app/teacher/services';
import { CalendarService } from '@app/teacher/services/calendar.service';
import {
  ContentService,
  StudentClassesCourseService,
} from '@app/admin/services';
import { AccountService } from '@app/auth/services';
import {
  StatusClassParams,
  StatusClassParamsCss,
  StatusClassParamsLabel,
} from '@app/shared/enum/StatusClassParams';
import { ContentEpisode } from '@app/content/model';
import { FullCalendarComponent } from '@fullcalendar/angular';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('mdCalendarDetails') mdCalendarDetails: any;
  private teacher: Teacher;
  public dashboardUrl = '/teacher/home';
  public allowMeeting: boolean = false;

  public user: User = new User();

  public studentCourseClassId: any = 0;
  public calendarVisible: boolean = false;
  public confirmWithParams: boolean = false;

  public messageNoContentCalendar: string =
    'Validando informações do calendário... Aguarde!';

  public statusClassParamsDataList: Array<any>;
  public statusClassParamsList: any = [];

  public newStatusClassParams: any;
  public newStatusClassParamsId: any;
  public newStatusClassParamsStr: string;
  public newStatusClassParamsCss: string;
  public statusClassParamsAddInfo: string = '';
  public needAddInfo: boolean = false;
  public validStatusClassParams: boolean = true;

  public studentClassesCourseExpectedDate: any;
  public studentClassesCourseReschedule = new Date();
  public studentClassesCourseExpectedTime: any;

  public errorStatusClassParamsMessage: string = '';
  public studentClassesCourseConfirmedParams: StudentClassesCourseConfirmedParams;

  calendarPlugins = [dayGridPlugin]; // important!
  calendarEvents: Array<any> = [];
  closeResult: string;
  dataResult = false;
  public returnMessage = '';
  public calendar: any;

  public episodeSetMessage: string = '';

  calendarOptions: CalendarOptions = {
    plugins: [interactionPlugin, dayGridPlugin, bootstrapPlugin],
    themeSystem: 'bootstrap',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    //dateClick: this.handleDateClick.bind(this), // MUST ensure `this` context is maintained
    navLinks: true, // can click day/week names to navigate views
    editable: true,
    initialView: 'listWeek',
    locale: Locale,
    //select: this.handleDateSelect.bind(this),
    eventClick: this.eventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventContent: function (info) {
      return { html: info.event.title };
    },
    titleFormat: {
      // will produce something like "Tuesday, September 18, 2018"
      month: 'long',
      year: 'numeric',
      day: 'numeric',
    },
    events: [],
    eventTimeFormat: {
      // like '14:30:00'
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
    },
  };

  private curr = new Date(); // get current date
  private first = this.curr.getDate() - this.curr.getDay(); // First day is the day of the month - the day of the week
  private last = this.first + 6; // last day is the first day + 6
  private firstday = new Date(this.curr.setDate(this.first));
  private lastday = new Date(this.curr.setDate(this.last));

  calendarOptions2$ = this.calendarService
    .filter(0, this.firstday, this.lastday)
    .pipe(
      map((data) => ({
        ...this.calendarOptions,
        events: data,
        initialDate: this.firstday,
        initialView: 'listWeek',
      }))
    );

  eventDetails: any = {
    id: 0,
    title: '',
    start: new Date(),
    end: new Date(),
    duration: new Date(),
    course: '',
    teacher: '',
    student: '',
  };

  public statusClassList: any = [];

  public episode: ContentEpisode;
  public serieId: number;
  public episodeId: number;

  public seriesDataList: Array<any>;
  public seriesList: any = [];

  public episodesDataList: Array<any>;
  public episodesList: any = [];

  private routeMeetingUrl = `${environment.urlLocal}/#/teacher/meeting`;

  constructor(
    private studentClassesCourseService: StudentClassesCourseService,
    private calendarUtilService: CalendarUtilService,
    private changeDetector: ChangeDetectorRef,
    private contentService: ContentService,
    private calendarService: CalendarService,
    private initTeacher: InitTeacherService,
    private accountService: AccountService,
    private cryptoService: CryptoService,
    private utilService: UtilService,
    private loading: MessageService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.initTeacher.setConfigPage();
    this.teacher = this.initTeacher.teacher;
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.getUserData();
    this.getStatusClassList();
    this.designConfig();
    setTimeout(() => {
      this.handleEvents(this.calendarEvents);
      setTimeout(() => {
        this.handleEvents(this.calendarEvents);
        this.designConfig();
      }, 1000);
    }, 1000);
  }

  private designConfig() {
    // Call the below function
    this.waitForElementToDisplay(
      '#fullCalendarTeacher',
      function () {
        if (window.screen.width < 640) {
          var toolCalendar =
            document.getElementsByClassName('fc-header-toolbar');
          var calendarObj =
            document.getElementsByClassName('fc-scroller-liquid');
          var btnGroupFilter = document.getElementsByClassName('btn-group');
          var rangeDataText =
            document.getElementsByClassName('fc-toolbar-title');
          if (toolCalendar?.length > 0) {
            toolCalendar[0].classList.remove('row');
            toolCalendar[0].classList.add('row');
            if (calendarObj?.length > 0) {
              calendarObj[0].classList.remove('overflow-visible');
              calendarObj[0].classList.add('overflow-visible');
            }
            if (btnGroupFilter?.length > 0) {
              for (let i = 0; i < btnGroupFilter.length; i++) {
                if (btnGroupFilter[i].children.length == 4) {
                  btnGroupFilter[i].classList.remove('w-100');
                  btnGroupFilter[i].classList.add('w-100');
                }
              }
            }
            if (rangeDataText?.length > 0) {
              rangeDataText[0].classList.remove('text-center');
              rangeDataText[0].classList.add('text-center');
            }
            var toolCalendarDiv =
              document.getElementsByClassName('fc-toolbar-chunk');
            if (toolCalendarDiv) {
              for (var i = 0; i <= toolCalendarDiv.length; i++) {
                if (toolCalendarDiv[i] && toolCalendarDiv[i]?.classList) {
                  toolCalendarDiv[i].classList.remove('col-12');
                  toolCalendarDiv[i].classList.add('col-12');

                  if (i == 1) {
                    toolCalendarDiv[i].classList.remove('small');
                    toolCalendarDiv[i].classList.remove('mt-3');
                    toolCalendarDiv[i].classList.remove('mb-3');
                    toolCalendarDiv[i].classList.remove('text-primary');
                    toolCalendarDiv[i].classList.add('small');
                    toolCalendarDiv[i].classList.add('mt-3');
                    toolCalendarDiv[i].classList.add('mb-3');
                    toolCalendarDiv[i].classList.add('text-primary');
                  }
                }
              }
            }
          }
        }
      },
      1000,
      9000
    );
  }

  waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
    var startTimeInMs = Date.now();
    (function loopSearch() {
      if (document.querySelector(selector) != null) {
        setTimeout(function () {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) return;
          callback();
        }, checkFrequencyInMs);
        return;
      } else {
        setTimeout(function () {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) return;
          loopSearch();
        }, checkFrequencyInMs);
      }
    })();
  }

  getSeries() {
    this.seriesDataList = [];
    this.serieId = null;
    this.episodesDataList = [];
    this.episodeId = null;

    this.contentService.getSeries().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        const items: Array<object> = [];
        items.push({
          value: 0,
          text: ' Selecione uma série',
        });
        response.data.forEach((element: any) => {
          items.push({
            value: Number.parseInt(element.id),
            text: `${element.name}`,
          });
        });

        this.seriesList = items;
        this.seriesDataList = this.getTemplateList(items);

        // this.seriesDataList = this.seriesDataList.sort(function(a: any, b: any) {
        //   var nameA = a.name.toLowerCase();
        //   var nameB = b.name.toLowerCase();
        //   return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        // });
      } else {
        this.seriesDataList = [];
        this.seriesList = [];
      }
    });
  }

  setEpisodeId(episodeId: any) {
    if (episodeId) {
      this.episodeId = episodeId;
    }
  }

  getEpisodesBySerieId(serieId: any) {
    if (serieId == null || serieId == undefined || serieId == 0) {
      serieId = this.episode.contentSerieId;
    }

    this.contentService
      .getEpisodesBySerie(serieId)
      .subscribe((response: any) => {
        if (response.typeResponse == TypeResponse.Success) {
          const items: Array<object> = [];
          items.push({
            value: 0,
            text: ' Selecione um episódio',
          });
          response.data.forEach((element: any) => {
            items.push({
              value: Number.parseInt(element.id),
              text: `${element.contentSerie.name} | ${(
                '00' + element.order
              ).slice(-2)}`,
            });
          });

          this.episodesList = items;
          this.episodesDataList = this.getTemplateList(items);

          // this.episodesDataList = this.episodesDataList.sort(function(a: any, b: any) {
          //   var nameA = a.name.toLowerCase();
          //   var nameB = b.name.toLowerCase();
          //   return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
          // });
        } else {
          this.episodesList = [];
          this.episodesDataList = [];
        }
      });
  }

  private getUserData() {
    this.initTeacher
      .getTeacherUser()
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.user = this.initTeacher.user;
          this.teacher = response;
          this.handleEvents(null);
          this.loading.hideLoading();
        },
        (error: any) => {
          this.returnMessage = 'Erro ao carregar o calendário.';
          this.loading.hideLoading();
        }
      );
  }

  loadCalendar() {
    if (this.teacher) {
      this.calendarService.getById(this.teacher.id).subscribe(
        (response: any) => {
          if (response.typeResponse === TypeResponse.Success) {
            this.addManyEvents(response.data);
            this.calendarOptions.events = this.calendarEvents;
            this.returnMessage = '';
            this.messageNoContentCalendar = '';
            this.calendarVisible = true;
          } else {
            this.calendarVisible = false;
            this.messageNoContentCalendar = response.message;
            this.returnMessage = response.message;
          }
          this.loading.hideLoading();
        },
        (error: any) => {
          this.loading.hideLoading();
          this.calendarVisible = false;
          this.messageNoContentCalendar =
            'Calendário não está disponível. Verifique as turmas do teacher!';
          this.returnMessage = 'Erro ao carregar os dados da agenda.';
          console.log(error);
        }
      );
    } else {
      this.calendarVisible = false;
      this.messageNoContentCalendar =
        'Calendário não está disponível. Verifique as turmas do teacher!';
    }
  }

  onDateClick(res: any) {
    alert('Clicked on date : ' + res.dateStr);
  }

  handleEvents(events: EventApi[]) {
    if (this.calendarComponent) {
      let calendarApi = this.calendarComponent.getApi();
      this.calendarOptions2$ = this.calendarService
        .filter(
          this.teacher?.id ?? 0,
          calendarApi.view.currentStart,
          calendarApi.view.currentEnd
        )
        .pipe(
          map((data) => ({
            ...this.calendarOptions,
            events: data,
            initialDate: calendarApi.view.currentStart,
            initialView: calendarApi.currentData.currentViewType,
          }))
        );
      setTimeout(() => {
        this.designConfig();
      }, 1000);
    }
    this.changeDetector.detectChanges();
  }

  eventClick(arg: any) {
    this.eventDetails.id = arg.event.id;
    this.eventDetails.title = arg.event.title;
    this.eventDetails.start = arg.event.start;
    this.eventDetails.end = arg.event.end;
    this.eventDetails.duration = arg.event.extendedProps.duration;
    this.eventDetails.course = arg.event.extendedProps.course;
    this.eventDetails.teacher = arg.event.extendedProps.teacher;
    this.eventDetails.teacherEmail = arg.event.extendedProps.teacherEmail;
    this.eventDetails.teacherPhone = arg.event.extendedProps.teacherPhone;
    this.eventDetails.student = arg.event.extendedProps.student;
    this.eventDetails.studentEmail = arg.event.extendedProps.studentEmail;
    this.eventDetails.studentPhone = arg.event.extendedProps.studentPhone;
    this.eventDetails.class = arg.event.extendedProps.class;
    this.eventDetails.status = arg.event.extendedProps.status;
    this.eventDetails.designStatusClass =
      arg.event.extendedProps.designStatusClass;

    this.eventDetails.description = arg.event.extendedProps.description;

    this.eventDetails.courseType = arg.event.extendedProps.courseType;
    this.eventDetails.courseTypeCss = arg.event.extendedProps.courseTypeCss;
    this.eventDetails.courseTypeStr = arg.event.extendedProps.courseTypeStr;

    this.eventDetails.confirmedClassByStudent =
      arg.event.extendedProps.confirmedClassByStudent;
    this.eventDetails.confirmedDateByStudent =
      arg.event.extendedProps.confirmedDateByStudent;
    this.eventDetails.confirmedClassByTeacher =
      arg.event.extendedProps.confirmedClassByTeacher;
    this.eventDetails.confirmedDateByTeacher =
      arg.event.extendedProps.confirmedDateByTeacher;
    this.eventDetails.canceledClass = arg.event.extendedProps.canceledClass;
    this.eventDetails.canceledDate = arg.event.extendedProps.canceledDate;

    this.eventDetails.episode = arg.event.extendedProps.episode;

    this.eventDetails.courseIdentifier =
      arg.event.extendedProps.courseIdentifier;
    this.eventDetails.classRoom = arg.event.extendedProps.classRoom;

    this.modalService.dismissAll();
    this.modalService
      .open(this.mdCalendarDetails, {
        backdrop: 'static',
        windowClass: 'animated fade',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          if (result === 'Save') {
            console.log('result = save');
            // this.submitChangePassword();
          }
        },
        (reason) => {
          this.closeResult = `Dismissed`;
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onEventClick(event: any) {
    console.log(event);
  }

  addEvent() {
    this.calendarEvents = this.calendarEvents.concat({
      title: 'event 2',
      date: '2019-04-02',
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  addManyEvents(response: any) {
    if (
      response != null &&
      response.calendarEvents != null &&
      response.calendarEvents.length > 0
    ) {
      const data = response.calendarEvents;
      // Loop por datas
      for (let d = 0; d < data.length; d++) {
        const cItem = data[d]; // Date
        if (cItem != null) {
          const dFormat = moment(cItem.date).format('YYYY-MM-DD');

          let designCourseType = this.getCourseTypeDesign(
            cItem.course.courseType
          );
          const tStartSplit = cItem.startTime.split(':');

          const event: any = {
            id: cItem.studentClassesCourse.id,
            classNames: this.calendarUtilService.getDesignClassCss(
              cItem.statusClass
            ),
            title:
              `<span class='ml-2'><span class='fw-700 h4'>${tStartSplit[0]}:${tStartSplit[1]}</span> ` +
              `${cItem.course.name} (Aula ${cItem.studentClassesCourse.numberClass}) ${designCourseType.courseTypeStr} <br>` +
              `Aluno(a): ${cItem.student.name}</span>`,
            start: `${dFormat}T${cItem.startTime}`, //'2019-08-12T10:30:00',
            end: `${dFormat}T${cItem.endTime}`,
            description: `Aula nº ${cItem.studentClassesCourse.numberClass}`,
            extendedProps: {
              duration: `${dFormat}T${cItem.duration}`,
              teacher: cItem.teacher.name,
              student: cItem.student.name,
              episode: cItem.studentClassesCourse.contentEpisode,
              course: cItem.course.name,
              classRoom: cItem.course.classRoom,
              courseIdentifier: cItem.course.identifier,
              class: `Aula nº ${cItem.studentClassesCourse.numberClass}`,
              status: cItem.statusClass,
              courseTypeCss: designCourseType.courseTypeCss,
              courseTypeStr: designCourseType.courseTypeStr,
              designStatusClass: this.designStatusClass(cItem.statusClass),
              confirmedClassByStudent:
                cItem.studentClassesCourse.confirmedClassByStudent,
              confirmedDateByStudent: formatDate(
                cItem.studentClassesCourse.confirmedClassByStudent
                  ? cItem.studentClassesCourse.confirmedDateByStudent
                  : new Date(),
                'yyyy-MM-dd HH:mm:ss',
                'en-US'
              ),
              confirmedClassByTeacher:
                cItem.studentClassesCourse.confirmedClassByTeacher,
              confirmedDateByTeacher: formatDate(
                cItem.studentClassesCourse.confirmedClassByTeacher
                  ? cItem.studentClassesCourse.confirmedDateByTeacher
                  : new Date(),
                'yyyy-MM-dd HH:mm:ss',
                'en-US'
              ),
              canceledClass: cItem.studentClassesCourse.canceledClass,
              canceledDate: formatDate(
                cItem.studentClassesCourse.canceledDate
                  ? cItem.studentClassesCourse.canceledDate
                  : new Date(),
                'yyyy-MM-dd HH:mm:ss',
                'en-US'
              ),
              confirmedClassByTeacherAddInfo:
                cItem.studentClassesCourse.confirmedClassByTeacherAddInfo,
              confirmedClassByTeacherStatusClassParams:
                cItem.studentClassesCourse
                  .confirmedClassByTeacherStatusClassParams,
              confirmedClassByTeacherStatusClassParamsStr:
                StatusClassParamsLabel.get(
                  cItem.studentClassesCourse
                    .confirmedClassByTeacherStatusClassParams
                ),
            },
          };
          this.calendarEvents.push(event);
        }
      }
    }
  }

  getStatusClassList() {
    this.statusClassList = this.utilService.ToArray(
      StatusClass,
      StatusClassLabel
    );
    this.statusClassList.forEach((statusClass: any) => {
      statusClass.css = StatusClassCss.get(statusClass.value);
    });
  }

  designStatusClass(status: StatusClass) {
    let statusClassCss = StatusClassCss.get(status);
    let statusClassStr = StatusClassLabel.get(status);
    return (
      "<span class='badge badge-pill w-100 font-weight-bold " +
      statusClassCss +
      "'>" +
      statusClassStr +
      '</span>'
    );
  }

  modifyTitle(eventIndex: number, newTitle: string) {
    let calendarEvents = this.calendarEvents.slice(); // a clone
    let singleEvent = Object.assign({}, calendarEvents[eventIndex]); // a clone
    singleEvent.title = newTitle;
    calendarEvents[eventIndex] = singleEvent;
    this.calendarEvents = calendarEvents; // reassign the array
  }

  studentCourseClassWithForm(
    studentCourseClassId: any,
    content: any,
    withParams: boolean = false
  ) {
    this.confirmWithParams = withParams;
    this.getStatusClassParamsList();
    this.getSeries();
    this.studentCourseClassId = parseInt(studentCourseClassId);
    this.dismissModal();
    this.modalService
      .open(content, {
        backdrop: 'static',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  changedClass(
    studentClassesCourseId: any,
    statusClass: StatusClass,
    linkAoVivo: string = ''
  ) {
    this.dismissModal();
    this.loading.showLoading();
    this.studentClassesCourseService
      .changedClass(
        studentClassesCourseId,
        statusClass,
        ModuleType.Teacher,
        this.episodeId ?? 0
      )
      .subscribe(
        (response: any) => {
          this.loading.hideLoading();
          if (response.typeResponse == TypeResponse.Success) {
            if (response.data === true) {
              this.changedClassSuccess(statusClass, linkAoVivo);
            } else {
              this.changedClassFailed(statusClass);
            }
          }
        },
        (error: any) => {
          this.loading.hideLoading();
          this.changedClassFailed(statusClass);
        }
      );
  }

  fixEpisode(studentClassesCourseId: any) {
    if (!this.episodeId) {
      this.changedClassFailed(StatusClass.Done);
    }

    this.dismissModal();
    this.loading.showLoading();
    this.studentClassesCourseService
      .fixEpisode(studentClassesCourseId, this.episodeId)
      .subscribe(
        (response: any) => {
          this.loading.hideLoading();
          if (response.typeResponse == TypeResponse.Success) {
            if (response.data === true) {
              this.changedClassSuccessGlobal();
            } else {
              this.changedClassFailed(StatusClass.Done);
            }
          }
        },
        (error: any) => {
          this.loading.hideLoading();
          this.changedClassFailed(StatusClass.Done);
        }
      );
  }

  confirmedClassWithParams(studentClassesCourseId: any) {
    if (this.newStatusClassParamsId >= 0 && studentClassesCourseId > 0) {
      if (!this.confirmWithParams && this.episodeId > 0) {
        return this.fixEpisode(studentClassesCourseId);
        //return this.changedClass(studentClassesCourseId, 1);
      }

      this.studentClassesCourseConfirmedParams =
        new StudentClassesCourseConfirmedParams();
      this.studentClassesCourseConfirmedParams.studentClassesCourseId =
        parseInt(this.studentCourseClassId);
      this.studentClassesCourseConfirmedParams.statusClassParams = parseInt(
        this.newStatusClassParamsId
      );
      this.studentClassesCourseConfirmedParams.confirmedAddInfo =
        this.statusClassParamsAddInfo;
      this.studentClassesCourseConfirmedParams.episode = this.episodeId;

      this.studentClassesCourseService
        .confirmClassByTeacherWithParams(
          this.studentClassesCourseConfirmedParams
        )
        .subscribe(
          (response: any) => {
            this.loading.hideLoading();
            if (response.typeResponse == TypeResponse.Success) {
              if (response.data === true) {
                this.changedClassSuccessGlobal();
                //this.changedClassSuccess(StatusClass.Done);
              } else {
                this.changedClassFailed(StatusClass.Done);
              }
            }
          },
          (error: any) => {
            this.loading.hideLoading();
            this.changedClassFailed(StatusClass.Done);
          }
        );
    } else {
      this.validStatusClassParams = false;
      this.errorStatusClassParamsMessage =
        'Favor informar o motivo da confirmação.';
      return;
    }
  }

  changedClassSuccess(status: StatusClass, linkAoVivo: string = '') {
    swal({
      type: 'success',
      title: linkAoVivo
        ? `Presença registrada na aula com sucesso!`
        : `Atualização realizada com sucesso!`,
      html:
        linkAoVivo && !this.allowMeeting
          ? '<small>Caso a aba do seu navegador não abra automaticamente, ' +
            'clique no link abaixo ou copie e cole no seu navegador para entrar na sala!</small><br><br>' +
            '<a href="' +
            linkAoVivo +
            '" target="_blank">' +
            linkAoVivo +
            '</a><br>'
          : '',
      showConfirmButton: true,
      confirmButtonText: 'Entrar na sala',
      //timer: 3000
    }).then((result: any) => {
      if (result.value === true && linkAoVivo) {
        window.open(linkAoVivo, '_blank');
        setTimeout(() => {
          this.loading.hideLoading();
          window.location.reload();
        }, 5000);
      } else {
        setTimeout(() => {
          this.handleEvents(null);
          this.loading.hideLoading();
        }, 1000);
      }
    });
  }

  changedClassSuccessGlobal() {
    swal({
      type: 'success',
      title: `Atualização realizada com sucesso!`,
      showConfirmButton: false,
      timer: 2200,
    }).then(() => {
      this.handleEvents(null);
    });
  }

  changedClassFailed(status: StatusClass) {
    this.dismissModal();
    swal({
      type: 'warning',
      title: `Não foi possível atualizar o status da aula!`,
      showConfirmButton: true,
    }).then(() => {
      //window.location.reload();
    });
  }

  rescheduleClassFailed() {
    swal({
      type: 'warning',
      title: `Não é possível reagendar uma aula que já foi reagendada!`,
      showConfirmButton: true,
    }).then(() => {
      this.dismissModal();
    });
  }

  studentCourseClassReschedule(studentCourseClass: any, content: any) {
    this.studentCourseClassId = studentCourseClass.id;
    this.studentClassesCourseExpectedDate = studentCourseClass.start;
    this.modalService
      .open(content, {
        backdrop: 'static',
        size: 'sm',
        windowClass: 'animated fade modal-backdrop-transparent',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  checkConfirmClass(target: any): void {
    swal({
      title: 'Confirmação da aula',
      html: 'Deseja confirmar a participação na aula ao vivo?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, confirmar minha presença',
      cancelButtonText: 'Não',
    }).then(
      (willConfirm) => {
        if (willConfirm.value === true) {
          var link = this.getClassRoomUrl(target.classRoom);
          if (
            target.teacherEmail &&
            this.initTeacher.CheckHavePermission('PRO2AL14MT36')
          ) {
            this.allowMeeting = true;
            link = this.getRouteClassRoom(target.classRoom);
          }
          this.changedClass(target.id, 1, link);
        } else {
          this.dismissModal();
          this.loading.hideLoading();
          // swal({
          //   type: 'info',
          //   title: `Lembre-se de confirmar a aula sempre que finalizar!`,
          //   showConfirmButton: true,
          // }).then(() => {
          //   // var linkAoVivo = this.getClassRoomUrl(target.classRoom);
          //   // window.open(linkAoVivo, '_blank');
          //   this.dismissModal();
          //   this.loading.hideLoading();
          // });
        }
      },
      (error) => {
        this.loading.hideLoading();
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title:
              'Erro ao tentar entrar na aula. Atualize a página e tente novamente!',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            //window.location.reload();
          });
        }
      }
    );
  }

  willChangeClassReschedule(willChangeClassReschedule: any): void {
    this.modalService.dismissAll();
    swal({
      title: 'Reagendamento da aula',
      html: 'Confirma o reagendamento da aula?',
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, alterar',
    }).then(
      (willConfirm) => {
        if (willConfirm.value === true) {
          this.dismissModal();
          this.loading.showLoading();
          let newDate = new Date(
            this.studentClassesCourseExpectedDate + 'GMT-0300'
          );
          newDate.setMinutes(
            this.studentClassesCourseExpectedTime.split(':')[1]
          );
          newDate.setHours(this.studentClassesCourseExpectedTime.split(':')[0]);
          let newDateStr = formatDate(newDate, 'yyyy-MM-dd HH:mm:ss', 'en-US');

          this.studentClassesCourseService
            .reschedule(this.studentCourseClassId, newDateStr)
            .subscribe(
              (response: any) => {
                this.loading.hideLoading();
                if (response.typeResponse == TypeResponse.Success) {
                  if (response.data === true) {
                    this.changedClassSuccessGlobal();
                    //this.changedClassSuccess(StatusClass.ChangedTeacher);
                  } else {
                    this.changedClassFailed(StatusClass.ChangedTeacher);
                  }
                }
              },
              (error: any) => {
                this.loading.hideLoading();
                this.changedClassFailed(StatusClass.ChangedTeacher);
              }
            );
        } else {
          this.loading.hideLoading();
          return;
        }
      },
      (error) => {
        this.loading.hideLoading();
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        } else {
          swal({
            type: 'error',
            title: 'Erro ao tentar cancelar a aula.',
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.loading.hideLoading();
            //window.location.reload();
          });
        }
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === 3) {
      return 'by opening next modal';
    } else {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else if (reason === 2) {
        return 'by clicking on a close button';
      } else {
        return `with: ${reason}`;
      }
    }
  }

  setValueTime(input: any) {
    this.studentClassesCourseExpectedTime = input.value;
  }

  setValueDate(input: any) {
    this.studentClassesCourseExpectedDate = input.value;
  }

  getClassRoomUrl(classRoom: any): string {
    return `https://meet.jit.si/ec-class-room-${classRoom}`;
  }

  getRouteClassRoom(classRoom: any) {
    let classRoomCrypto = this.cryptoService.encrypt(classRoom);
    return `${this.routeMeetingUrl};classRoom=${classRoomCrypto}`;
  }

  checkRescheduleButton(event: any) {
    return (
      event.status == StatusClass.ChangedTeacher ||
      event.status == StatusClass.Scheduled
    );
  }

  checkTeacherChangeButton(event: any) {
    return (
      event.status == StatusClass.ChangedTeacher ||
      event.status == StatusClass.Scheduled ||
      event.status == StatusClass.Reschedule
    );
  }

  checkClassRoomAccess(event: any) {
    var checkDate = event.start;
    checkDate.setHours(0, 0, 0, 0);
    var dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    let haveEpisode = this.haveEpisode(event);

    let checkStatus = this.checkEditByStatus(event);

    return (
      this.initTeacher.CheckHavePermission('PRO2AL14AV45') &&
      checkDate.toDateString() === dateNow.toDateString() &&
      haveEpisode &&
      checkStatus
    );
  }

  checkEditByStatus(event: any) {
    return (
      event.status == StatusClass.ChangedTeacher ||
      event.status == StatusClass.Scheduled ||
      event.status == StatusClass.PartialConfirmed ||
      event.status == StatusClass.Reschedule ||
      event.status == StatusClass.Done
    );
  }

  haveEpisode(event: any) {
    if (event.episode === null) {
      this.episodeSetMessage =
        'Não é possível entrar na aula sem informar o episódio!';
    } else {
      this.episodeSetMessage = '';
    }

    return this.episodeSetMessage === '';
  }

  getCourseTypeDesign(courseType: any) {
    let courseTypeCss = CourseTypeCss.get(courseType);
    let courseTypeStr = CourseTypeLabel.get(courseType);
    return {
      courseTypeStr,
      courseTypeCss,
    };
  }

  getStatusClassParamsList() {
    const statusList: Array<object> = [];
    for (var value in StatusClassParams) {
      if (Number.isInteger(Number.parseInt(value))) {
        if (Number.parseInt(value) == 4) continue;

        statusList.push({
          value: Number.parseInt(value),
          text: StatusClassParamsLabel.get(Number.parseInt(value)),
        });
      }
    }
    this.statusClassParamsList = statusList;
    this.statusClassParamsDataList = this.getTemplateList(statusList);
    this.setNewStatusClassParams(0);
  }

  getTemplateList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      let items: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].value,
          text: data[i].text,
        };
        items.push(element);
      }
      return items.sort(function (a: any, b: any) {
        var textA = a.text.toLowerCase();
        var textB = b.text.toLowerCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
    return new Array<any>();
  }

  setNewStatusClassParams(value: any) {
    this.newStatusClassParamsId = Number.parseInt(value);
    this.newStatusClassParams = this.statusClassParamsList.find(
      (x: { value: number }) => x.value == this.newStatusClassParamsId
    );
    this.newStatusClassParamsStr = StatusClassParamsLabel.get(
      this.newStatusClassParamsId
    );
    this.newStatusClassParamsCss = StatusClassParamsCss.get(
      this.newStatusClassParamsId
    );

    if (this.newStatusClassParamsId === StatusClassParams.Other) {
      this.needAddInfo = true;
    }
  }
}
