import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';
import { Profile } from '../model/profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private route: string = "student";
  private controller: string = "profile";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getById(studentId: string): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/${studentId}`);
  }

  save(profile: Profile): any {
    return this.http.put<ResponseResult>(`${this.endpointPrefix}/update`, profile);
  }
}
