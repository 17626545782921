import { Base } from '@app/shared/model';
import { Course, Student, StudentClassesCourse, Teacher, WritingTest } from '.';

export class WritingTestAppliedPending extends Base {
    studentClassesCourseId: number;
    studentClassesCourse: StudentClassesCourse;

    writingTestId: number;
    writingTest: WritingTest;

    teacherAppliedId: number;
    teacherApplied: Teacher;

    student: Student;
    course: Course;

    scoreResult: number;
    availabilityDate: Date;
    finishDate: Date;

    avgScore: number;
    writingTestStatus: number;

    writeContentStudentHtml: string;

    contentReviewHtml: string;
    reviewDate: Date;
    writingTestReview: number;

    additionalInformation: string;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.studentClassesCourseId = parseInt(contentResponse.studentClassesCourseId);
        if (contentResponse.studentClassesCourseId > 0 && contentResponse.studentClassesCourse) {
            this.studentClassesCourse = contentResponse.studentClassesCourse;
        }

        this.writingTestId = parseInt(contentResponse.writingTestId);
        if (contentResponse.writingTestId > 0 && contentResponse.writingTest) {
            this.writingTest = contentResponse.writingTest;
        }

        this.teacherAppliedId = parseInt(contentResponse.teacherAppliedId);
        if (contentResponse.teacherAppliedId > 0 && contentResponse.teacherApplied) {
            this.teacherApplied = contentResponse.teacherApplied;
        }

        if (contentResponse.student) {
            this.student = contentResponse.student;
        }

        if (contentResponse.course) {
            this.course = contentResponse.course;
        }

        this.scoreResult = parseFloat(contentResponse.scoreResult);
        
        this.availabilityDate = new Date(contentResponse.availabilityDate),
        this.finishDate = new Date(contentResponse.finishDate),

        this.avgScore = parseInt(contentResponse.avgScore);
        this.writingTestStatus = parseInt(contentResponse.writingTestStatus);

        this.writeContentStudentHtml = contentResponse.writeContentStudentHtml;
        this.contentReviewHtml = contentResponse.contentReviewHtml;
        this.reviewDate = new Date(contentResponse.reviewDate);
        this.writingTestReview = parseInt(contentResponse.writingTestReview);

        this.additionalInformation = contentResponse.additionalInformation;
    }
}
