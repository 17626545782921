import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { AccountService } from '@app/auth/services';
import { AlertService } from '@app/shared/services'
import { Forget } from '@app/auth/model/forget';
import { first } from 'rxjs/operators';
import { MessageService } from '@app/shared/services/message.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html'
})
export class ForgetComponent implements OnInit {
  submitted = false;
  returnUrl: string;
  forgetForm;
  forgetReturn: any;
  urlForget: string;
  private forgetPassword: Forget;
  public errorMessage = '';
  public listErrorMessage: any[];
  public validData: boolean;
  public validReset: boolean = false;;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private loading: MessageService
  ) {}

  ngOnInit() {
    // Errors Cleaning
    this.errorMessage = '';
    this.listErrorMessage = [];
    this.validData = true;

    // Initialize the register form
    this.onInitForm();

    // get return url from route parameters or default to '/'
    this.returnUrl = '/auth/login';
    let pathUrl = environment.name == 'dev' 
      ? window.location.origin.replace('localhost:4200', 'admin.englishcoffee.com.br')
      : window.location.origin;
    this.urlForget = pathUrl + window.location.pathname + window.location.hash.replace("forget", "resetpassword");
    //this.urlForget = window.location.origin +window.location.pathname+ window.location.hash.replace("forget", "resetpassword");
  }

  onInitForm() {
    this.forgetForm = this.formBuilder.group({
      'email': ['',[Validators.required,Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")]]
      //email: ['', Validators.required]
    });
  }

  get f() {
    return this.forgetForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    
    // Errors Cleaning
    this.errorMessage = '';
    this.validData = true;
    this.validReset = false;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.forgetForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validData = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.submitted = true;
    this.loading.showLoading();
    this.forgetPassword = new Forget();
    this.forgetPassword.email = this.f.email.value;
    this.forgetPassword.UrlCallback = this.urlForget;

    this.accountService.forgetPassword(this.forgetPassword)
    .pipe(first())
    .subscribe(
      dataReturn => {
        if (dataReturn.resetSuccess == true) {
          this.validData = true;
          this.validReset = true;
          //this.router.navigate([this.returnUrl]);
        } else {
          this.validData = false;
          this.validReset = false;
          if (dataReturn.error) {
            this.errorMessage = dataReturn.error.message;
          } else {
            this.errorMessage = "Não foi possível redefir sua senha. Por favor, tente novamente!";
          }
        }
        this.submitted = false;
        this.loading.hideLoading();
      }, error => {
        this.submitted = false;
        this.validReset = false;
        this.loading.hideLoading();
        this.errorMessage = error;
      });
  }

  goLogin() {
    this.router.navigate([this.returnUrl]);
  }
}
