import { Base } from '@app/shared/model';
import { User } from '@app/auth/model';

export class ChatMessage extends Base {
    recipientUserId: number;
    recipientUser: User;

    senderUserId: number;
    senderUser: User;

    textMessage: string;

    read: boolean;
    readDateTime: Date = null;

    sent: boolean;

    constructor(contentResponse: any) {
        super(contentResponse);
        this.textMessage = contentResponse.textMessage;

        this.recipientUserId = parseInt(contentResponse.recipientUserId);
        if (contentResponse && contentResponse.recipientUserId > 0) {
            this.recipientUser = contentResponse.recipientUser;
        }

        this.senderUserId = parseInt(contentResponse.senderUserId);
        if (contentResponse && contentResponse.senderUserId > 0) {
            this.senderUser = contentResponse.senderUser;
        }

        if (this.read) {
            this.readDateTime = contentResponse.readDateTime
            ? new Date(contentResponse.readDateTime)
            : null;
        }

        this.sent = contentResponse.sent;
    }
}
