<!-- BEGIN PRIMARY NAVIGATION -->
<nav id="js-primary-nav" class="primary-nav" role="navigation">
    <div class="nav-filter">
        <div class="position-relative">
            <input type="text" id="nav_filter_input" placeholder="Filter menu" class="form-control" tabindex="0">
            <a href="javascript:void(0);" onclick="return false;" class="btn-primary btn-search-close js-waves-off" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar">
                <i class="fal fa-chevron-up"></i>
            </a>
        </div>
    </div>
    <div class="info-card">
        <img src="assets/img/demo/avatars/avatar-m.png" class="profile-image rounded-circle" alt="">
        <div class="info-card-text">
            <a href="javascript:void(0);" class="d-flex align-items-center text-white">
                <span class="text-truncate text-truncate-sm d-inline-block">
                    {{user.name}}
                </span>
            </a>
            <span class="d-inline-block text-truncate text-truncate-sm mt-1 fw-300">{{teacher?.city?.name +', '+ teacher?.state?.uf}}</span>
            <br>
            <span class="d-inline-block text-truncate text-truncate-xs mt-1 fw-700 small">{{user?.module}}</span>
        </div>
        <img src="assets/img/card-backgrounds/cover-2-lg.png" class="cover" alt="cover">
        <a href="javascript:void(0);" onclick="return false;" class="pull-trigger-btn" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar" data-focus="nav_filter_input">
            <i class="fal fa-angle-down"></i>
        </a>
    </div>
    <ul id="js-nav-menu" class="nav-menu">
        <li class="active">
            <a href="javascript:void" *ngIf="checkHavePermission('PRO2DA13DA26');" title="Dashboard" data-filter-tags="dashboard" (click)="menuClick('/teacher/home')">
                <i class="fa fa-chart-line"></i>
                <span class="nav-link-text" data-i18n="nav.teacher.dashboard">Dashboard</span>
            </a>
        </li>
        <li class="active">
            <a href="javascript:void" *ngIf="checkHavePermission('PRO2DA13DA26');" title="Meu Perfil" data-filter-tags="perfil" (click)="menuClick('/teacher/profile')">
                <i class="fa fa-user-alt"></i>
                <span class="nav-link-text" data-i18n="nav.teacher.class">Meu Perfil</span>
                <span class="dl-ref label bg-success-600 text-white ml-2">Novo</span>
            </a>
        </li>
        <li class="active">
            <a href="javascript:void" *ngIf="checkHavePermission('PRO2AL14AL27');" title="Aulas" data-filter-tags="aula" (click)="menuClick('/teacher/course')">
                <i class="fa fa-book"></i>
                <span class="nav-link-text" data-i18n="nav.teacher.class">Biblioteca</span>
            </a>
        </li>
        <li class="active">
            <a href="javascript:void" *ngIf="checkHavePermission('PRO2AG16AG29');" title="Agenda" data-filter-tags="agenda" (click)="menuClick('/teacher/calendar')">
                <i class="fa fa-calendar"></i>
                <span class="nav-link-text" data-i18n="nav.teacher.schedule">Agenda</span>
                <span class="dl-ref label bg-success-600 text-white ml-2">Novo</span>
            </a>
        </li>
        <li class="active">
            <a href="javascript:void" *ngIf="checkHavePermission('PRO2AT25CH42');" title="Chat" data-filter-tags="chat" (click)="menuClick('/message/workspace')">
                <i class="fa fa-comments"></i>
                <span class="nav-link-text" data-i18n="nav.student.chat">Chat</span>
                <span class="dl-ref label bg-success-600 text-white ml-2">Novo</span>
            </a>
        </li>
        <li class="active">
            <a href="javascript:void" *ngIf="checkHavePermission('PRO2WT7AL35');" title="Teste de escrita" data-filter-tags="teste de escrita" (click)="menuClick('/teacher/writingtestlist')">
                <i class="fa fa-laptop"></i>
                <span class="nav-link-text" data-i18n="nav.teacher.writingtest">Teste de escrita</span>
                <span class="dl-ref label bg-success-600 text-white ml-2">Novo</span>
            </a>
        </li>
        <!-- <li class="disabled">
            <a href="javascript:void" *ngIf="checkHavePermission('PRO2AV15AV28');" title="Avisos" data-filter-tags="avisos">
                <i class="fal fa-bell"></i>
                <span class="nav-link-text disabled" data-i18n="nav.teacher.news">Avisos</span>
                <span class="dl-ref label bg-primary-100 text-white ml-2">Em breve</span>
            </a>
        </li>
        <li class="disabled">
            <a href="javascript:void" *ngIf="checkHavePermission('PRO2FI17FI30');" title="Financeiro" data-filter-tags="financeiro">
                <i class="fa fa-money-bill"></i>
                <span class="nav-link-text disabled" data-i18n="nav.teacher.financeiro">Financeiro</span>
                <span class="dl-ref label bg-primary-100 text-white ml-2">Em breve</span>
            </a>
        </li>
        <li class="disabled">
            <a href="javascript:void" *ngIf="checkHavePermission('PRO2BI18BI31');" title="Biblioteca" data-filter-tags="biblioteca">
                <i class="fa fa-book"></i>
                <span class="nav-link-text text-muted" data-i18n="nav.teacher.library">Biblioteca</span>
                <span class="dl-ref label bg-primary-100 text-white ml-2">Em breve</span>
            </a>
        </li>
        <li class="disabled">
            <a href="javascript:void" *ngIf="checkHavePermission('PRO2FO19FO32');" title="Fórum" data-filter-tags="forum">
                <i class="fa fa-comments"></i>
                <span class="nav-link-text text-muted" data-i18n="nav.teacher.forum">Fórum</span>
                <span class="dl-ref label bg-primary-100 text-white ml-2">Em breve</span>
            </a>
        </li> -->
    </ul>
    <div class="filter-message js-filter-message bg-success-600"></div>
</nav>
<!-- END PRIMARY NAVIGATION -->
