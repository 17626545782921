import { Base } from '@app/shared/model';
import { User } from '@app/auth/model';

export class Profile extends Base {
    imageName: string;

    userId: number;
    user: User;
    
    constructor(contentResponse: any) {
        super(contentResponse);
        this.imageName = contentResponse.imageName;

        this.userId = parseInt(contentResponse.userId);
        if (contentResponse && contentResponse.userId > 0) {
            this.user = contentResponse.user;
        }
    }
}
