import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private route: string = "admin";
  private controller: string = "city";
  private endpointPrefix: string;
  private endpointPrefixState: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
    this.endpointPrefixState = `${environment.urlAPI}/${this.route}/state`;
  }

  getCities(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getCityByUf(cityUf: string): any {
    let params = new HttpParams();
    params = params.append('uf', cityUf);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getByUf`, {params: params});
  }

  getCityById(cityId: number): any {
    let params = new HttpParams();
    params = params.append('id', cityId.toString());
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getById`, {params: params});
  }

  getCityByStateId(stateId: number): any {
    let params = new HttpParams();
    params = params.append('stateId', stateId.toString());
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getByStateId`, {params: params});
  }

  getCityByIbgeCode(ibgeCode: string): any {
    let params = new HttpParams();
    params = params.append('ibgeCode', ibgeCode);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getByIbgeCode`, {params: params});
  }

  getStates(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefixState}/get`);
  }

  getStateByUf(stateUf: string): any {
    let params = new HttpParams();
    params = params.append('uf', stateUf);
    return this.http.get<ResponseResult>(`${this.endpointPrefixState}/getByUf`, {params: params});
  }

  getStateById(stateId: number): any {
    let params = new HttpParams();
    params = params.append('id', stateId.toString());
    return this.http.get<ResponseResult>(`${this.endpointPrefixState}/getById`, {params: params});
  }

  getAddressByCep(cep: string): any {
    let params = new HttpParams();
    params = params.append('cep', cep);
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/getbycep`, {params: params});
  }
  
  getAddressByCepFromOriginal(cep: string): any {
    return this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`);
  }
}
