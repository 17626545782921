import { TeacherService, InitAdminService, AddressService, BankService } from '@app/admin/services';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Teacher, State, Bank } from '@app/admin/model';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MessageService } from '@app/shared/services';
import { first } from 'rxjs/operators';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { DatePipe } from '@angular/common';
import { UserService } from '@app/auth/services';
import { default as swal } from 'sweetalert2';
import { AccountType, GeneralStatus } from '@app/shared/enum';

@Component({ templateUrl: 'teacher.component.html' })
export class TeacherComponent implements OnInit {
  @ViewChild("inputNumber") inputNumber: ElementRef;
  //@Input("userId") userId: any;
  public userId: any;

  teacherForm;
  submitted = false;
  returnUrl: string = '/admin/teacherlist';
  
  public validTeacher: boolean;
  public errorMessage = '';
  public listErrorMessage: any[];
  private teacher: Teacher;
  public updateTeacher: boolean = false;
  public phoneMask: Array<string | RegExp>;
  public cepMask: Array<string | RegExp>;
  public enumAccountType: AccountType;
  public flagSearchCep: boolean = false;
  
  public editMode: boolean;
  public bankList: any;
  public stateList: any;
  public citiesList: any;
  public idTeacher: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private teacherService: TeacherService,
    private bankService: BankService,
    private initAdmin: InitAdminService,
    private loading: MessageService,
    private addressService: AddressService,
    private datePipe: DatePipe
  ) { 
    this.phoneMask = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.cepMask = [/[0-9]/, /\d/ ,/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    this.onInitForm();
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.initAdmin.setConfigPage();
    this.returnUrl = '/admin/teacherlist';
    
    this.activatedRoute.params.subscribe(params => {
      this.getBanks();
      this.getStates();
      
      this.idTeacher = params['id'];
      if (this.idTeacher > 0) {
        this.editMode = true;
        this.loadTeacher(this.idTeacher);
      } else {
        this.editMode = false;
        this.userId = params['userId'];
        this.getUserData(this.userId);
      }
    });

    this.onSetEventsForm();
  }

  onInitForm() {
    this.validTeacher = true;
    this.teacherForm = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      complement: [''],
      neighborhood: ['', [Validators.required]],

      userId: ['', [Validators.required]],
      cityId: ['', [Validators.required]],
      stateId: ['', [Validators.required]],
      
      status: [''],
      moreInformation: [''],
      universityGraduate: [''],
      createdOn: [''],
      updatedOn: [''],

      hourlyRate: [0, [Validators.required]],
      bankId: ['', [Validators.required]],
      accountType: [0, [Validators.required]],
      agency: ['', [Validators.required]],
      account: ['', [Validators.required]],
      pix: ['']
    });
  }
  
  onSetEventsForm() {
    this.f.phone.valueChanges.subscribe(value => {
      var strPhone = value.toString().replace('(', '').replace(')', '').replace('-', '').replace('_', '').replace(' ', '');
      var phoneNumber = parseInt(strPhone);
      if (!(phoneNumber >= 11111111111 && phoneNumber <= 99999999999)) {
        value = phoneNumber.toString();
        this.f.phone.status = "INVALID";
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.teacherForm.controls;
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validTeacher = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.teacherForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validTeacher = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.teacher = new Teacher(this.teacherForm.value);
    this.teacher.status = GeneralStatus.Active;
    this.teacherService.save(this.teacher, this.editMode)
    .pipe(first())
    .subscribe((dataReturn: any) => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.loading.hideLoading();
        swal({
          type: 'success',
          title: this.editMode ? 'Professor(a) atualizado(a) com sucesso!' : 'Novo(a) professor(a) criado(a) com sucesso!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          this.validTeacher = true;
          this.submitted = false;
          this.router.navigate([this.returnUrl]);
        });
      } else {
        this.loading.hideLoading();
        this.validTeacher = false;
        this.errorMessage = dataReturn.message;
        this.submitted = false;
        if (dataReturn.data && dataReturn.data.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (var i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
      }
      return;
    }, (error: any) => {
      this.validTeacher = false;
      this.submitted = false;
      this.loading.hideLoading();
      this.errorMessage = !error.ok ? "Erro ao salvar o professor(a)." : error;
      if (error.error) {
        this.listErrorMessage.push(error.error.message);
      }
    });
  }

  getStates() {
    this.addressService.getStates().subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.stateList = response.data;
      } else {
        this.stateList = new Array<State>();
      }
    });
  }

  getCitiesByStateId(stateId: number) {
    if(stateId == null || stateId == undefined || stateId == 0) {
      stateId = this.teacher.stateId;
    }
    this.teacherForm.controls['cityId'].setValue(0);
    this.addressService.getCityByStateId(stateId).subscribe(response => {
      this.citiesList = response.data;
    });
  }

  getUserData(userId: string) {
    if (userId) {
      this.userService.getById(userId).subscribe(response => {
        if (response.typeResponse == TypeResponse.Success) {
          this.teacherForm.controls['userId'].setValue(parseInt(userId));
          this.teacherForm.controls['name'].setValue(response.data.name);
          this.teacherForm.controls['email'].setValue(response.data.email);
        } else {
          this.teacherForm.controls['userId'].setValue(0);
          this.teacherForm.controls['name'].setValue('');
          this.teacherForm.controls['email'].setValue('');
        }
        this.loading.hideLoading();
      }, (error: any) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
        this.loading.hideLoading();
      });
    }
  }
  
  getBanks() {
    this.bankService.getall().subscribe(response => {
        if (response.typeResponse == TypeResponse.Success) {
          this.bankList = response.data;
        } else {
          this.bankList = new Array<Bank>();
        }
      }
    );
  }

  loadTeacher(id) {
    this.loading.showLoading();
    this.teacherService.get(id)
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validTeacher = true;
        this.teacher = new Teacher(response.data);
        this.setFormByEntity(this.teacher);
      } else {
        this.validTeacher = true;
      }
      this.loading.hideLoading();
      }, error => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
      }
    );
  }

  setFormByEntity(data: any) {
    this.teacherForm = this.formBuilder.group({
      id: new FormControl(data.id),
      name: new FormControl(data.name),
      birthDate: new FormControl(this.datePipe.transform(data.birthDate,"yyyy-MM-dd")),
      email: new FormControl(data.email),
      phone: new FormControl(data.phone),
      cpf: new FormControl(data.cpf),
      zipCode: new FormControl(data.zipCode),
      street: new FormControl(data.street),
      number: new FormControl(data.number),
      complement: new FormControl(data.complement),
      neighborhood: new FormControl(data.neighborhood),
      userId: new FormControl(data.userId),

      stateId: new FormControl(data.stateId),
      cityId: new FormControl(data.cityId),

      bankId: new FormControl(data.bankId),
      hourlyRate: new FormControl(data.hourlyRate),
      accountType: new FormControl(data.accountType.toString()),
      agency: new FormControl(data.agency),
      account: new FormControl(data.account),
      pix: new FormControl(data.pix),

      status: new FormControl(data.status),
      moreInformation: new FormControl(data.moreInformation),
      universityGraduate: new FormControl(data.universityGraduate),
      createdOn: new FormControl(data.createdOn),
      updatedOn: new FormControl(data.updatedOn)
    });

    this.teacherForm.controls['stateId'].setValue(data.stateId);
    this.teacherForm.controls['accountType'].setValue(data.accountType.toString());
    this.addressService.getStateById(data.stateId).subscribe(e => {
      this.addressService.getCityByUf(e.data.uf).subscribe(p => {
        this.citiesList = p.data;
        this.teacherForm.controls['cityId'].setValue(data.cityId);
      });
    });
  }

  loadAddressByCep(zipCode: any) {
    this.flagSearchCep = true;
    if (zipCode.value && parseInt(zipCode.value) && zipCode.value.length == 8) {
      let cep = zipCode.value;
      this.addressService.getAddressByCep(cep).subscribe((response: { erro: any; data: { uf: string; logradouro: any; bairro: any; ibge: string; }; }) => {
        if (response.erro) {
          this.f().age.setErrors({invalidNumber:true})
        }
        this.addressService.getCityByUf(response.data.uf).subscribe((p: { data: any; }) => {
          this.citiesList = p.data;
          this.teacherForm.controls['street'].setValue(response.data.logradouro);
          this.teacherForm.controls['neighborhood'].setValue(response.data.bairro);

          this.addressService.getCityByIbgeCode(response.data.ibge).subscribe((p: { data: { id: any; stateId: any; }; }) => {
            this.teacherForm.controls['cityId'].setValue(p.data.id);
            this.teacherForm.controls['stateId'].setValue(p.data.stateId);
            this.flagSearchCep = false;
          });
        });
      });
    } else {
      swal({
        type: 'warning',
        html: 'Por favor informe um CEP válido para buscar.',
        title: 'CEP inválido',
        allowOutsideClick: false,
        showConfirmButton: true
      }).then(() => {
        this.getStates();
        this.flagSearchCep = false;
      });
    }
  }

  finishFunction(){
    var a = 'finish';
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  onAccountTypeChecked(input: any) {
    console.log(input);
  }
}
