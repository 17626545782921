import { Base } from '@app/shared/model';
import { RoleGroup } from '.';

export class Role extends Base {
    roleGroupId: number;
    roleGroup: RoleGroup;
    keyDefault: string;
    commonName: string;
    name: string;
    normalizedName: string;
    concurrencyStamp: string;

    constructor(contentResponse: any) {
        super(contentResponse);

        this.roleGroupId = contentResponse.roleGroupId;
        this.roleGroup = contentResponse.roleGroup;
        this.keyDefault = contentResponse.keyDefault;
        this.commonName = contentResponse.commonName;
        this.name = contentResponse.name;
        this.normalizedName = contentResponse.normalizedName;
        this.concurrencyStamp = contentResponse.concurrencyStamp;
    }
}
