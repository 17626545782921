<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/writingtestlist']">Writing Tests</a>
    </li>
    <li class="breadcrumb-item active">Novo Writing Test</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div id="panel-2" class="panel">
    <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>
            Novo <span class="fw-300"><i>Writing Test</i></span>
        </h2>
        <div class="panel-toolbar">
            <a href="javascript:void(0);" class="btn btn-success btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse">
                <i class="fal fa-minus text-white fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-warning btn-sm btn-icon rounded-circle waves-effect waves-themed mr-2"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen">
                <i class="fal fa-expand text-primary fw-900"></i>
            </a>
            <a href="javascript:void(0);" class="btn btn-danger btn-sm btn-icon rounded-circle waves-effect waves-themed"
                data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Close">
                <i class="fal fa-times text-white fw-900"></i>
            </a>
        </div>
    </div>
    <div class="panel-container show">
        <div class="panel-content p-0">
            <form [formGroup]="writingTestForm" novalidate class="needs-validation" (ngSubmit)="onSubmit()">
                <div class="panel-content">
                    <div class="form-row">
                        <input type="hidden" id="id" formControlName="id" />
                        <input type="hidden" id="createdOn" formControlName="createdOn"/>
                        <input type="hidden" id="updatedOn" formControlName="updatedOn"/>
                        <input type="hidden" id="usageCount" formControlName="usageCount" />
                        <div class="col-12 mb-3">
                            <label class="form-label" for="name">Nome da avaliação <span class="text-danger">*</span> </label>
                            <input type="text" id="name" formControlName="name" class="form-control" placeholder="Nome da avaliação" maxlength="100" [ngClass]="{ 'is-invalid': !validWritingTest && f?.name?.errors }" required>
                            <div *ngIf="!validWritingTest && f?.name?.errors" class="invalid-feedback">
                                <div *ngIf="f?.name?.errors.required">Por favor, informe o nome da avaliação.</div>
                            </div>
                        </div>
                        <div class="col-3 mb-3">
                            <label class="form-label" for="order">Ordem de prioridade <span class="text-danger">*</span> </label>
                            <input class="form-control" formControlName="order" type="number" [ngClass]="{ 'is-invalid': !validWritingTest && f?.order?.errors }" required>
                            <div *ngIf="!validWritingTest && f?.order?.errors" class="invalid-feedback">
                                <div *ngIf="f?.order?.errors.required">Por favor, informe a ordem da avaliação.</div>
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <label class="form-label" for="contentHtml">Texto <span class="text-danger">*</span> </label>
                            <div formControlName="contentHtml" 
                                [ngxSummernote]="config"
                                [ngxSummernoteDisabled]="editorDisabled"
                                (blur)="onBlur()"
                                (mediaDelete)="onDelete($event)"
                                (summernoteInit)="summernoteInit($event)">
                            </div>
                            <div *ngIf="!validWritingTest && f?.episode?.errors" class="invalid-feedback">
                                <div *ngIf="f?.episode?.errors.required">Por favor, informe o episódio vinculado.</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseText" aria-expanded="false" aria-controls="collapseText">
                                Visualizar Texto
                            </button>
                            <br>
                            <div class="collapse mt-3" id="collapseText">
                                <div class="card card-body">
                                    <!-- <h3>HTML View</h3>
                                    <div [ngxSummernoteView]="f?.html?.value"></div>
                                    
                                    <h3>Code</h3>
                                    <pre>{{f?.html?.value}}</pre>
                                    <div *ngIf="f?.html?.invalid">
                                    {{f?.html??.errors | json}}
                                    </div>
                                    
                                    <h3>HTML safeHtml</h3> -->
                                    <div [innerHTML]="f?.contentHtml?.value | safe: 'html'"></div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-12 mb-3">
                            <label class="form-label" for="name">Nome da avaliação <span class="text-danger">*</span> </label>
                            <input type="text" id="name" formControlName="name" class="form-control" placeholder="Nome da avaliação" maxlength="100" [ngClass]="{ 'is-invalid': !validWritingTest && f?.name?.errors }" required>
                            <div *ngIf="!validWritingTest && f?.name?.errors" class="invalid-feedback">
                                <div *ngIf="f?.name?.errors.required">Por favor, informe o nome da avaliação.</div>
                            </div>
                        </div> -->
                    </div>
                    <div class="alert alert-warning alert-dismissible text-left fade show mt-3" role="alert" *ngIf="!validWritingTest">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true"><i class="fal fa-times"></i></span>
                        </button>
                        {{errorMessage}}
                    </div>
                    <div *ngIf="listErrorMessage && !validWritingTest" class="invalid-feedback">
                        <div *ngFor="let error of listErrorMessage">{{error}}</div>
                    </div>
                    <hr>
                    <div class="row no-gutters">
                        <div class="col-md-12 ml-auto text-right">
                            <div class="btn-group btn-panel">
                                <button id="js-login-btn" class="btn btn-primary" [disabled]="submitted">
                                    <span *ngIf="submitted" class="spinner-border spinner-border-sm mr-1"></span>
                                    Salvar
                                </button>
                                <button id="js-cancel-btn" class="btn btn-default" (click)="onCancel()">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
