import { AddressService, BankService, TeacherService } from '@app/admin/services';
import { Bank, State, Teacher, TeacherWithAvailability } from '@app/admin/model';
import { AccountType, RecurrenceDay, RecurrenceDayLabel } from '@app/shared/enum';
import { InitTeacherService, ProfileService } from '@app/teacher/services';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services';
import { UtilService } from '@app/shared/services/util.service';
import { User } from '@app/auth/model';
import { Profile } from '@app/teacher/model';
import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { default as swal } from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { first } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({ templateUrl: 'profile.component.html' })
export class ProfileComponent implements OnInit {
  private teacher: Teacher;
  public idTeacher: number;

  private profile: Profile;
  
  public bankList: any;

  public user: User = new User();
  public userId: any;

  submitted = false;
  public flagForeignPerson: boolean = false;
  returnUrl: string = '/teacher/home';

  public daysOfWeekDataList: Array<any>;
  public daysOfWeekDataList$ = new BehaviorSubject([]);
  public daysOfWeekList: Array<any> = [];
  public hoursOfDay: Array<any> = [];
  private teacherWithAvailability: TeacherWithAvailability;

  public validAvailability: boolean = true;

  public foreignPersonDisableFields = false;
  public profileForm: any;
  public validProfile: boolean;
  public updateProfile: boolean = false;
  public phoneMask: Array<string | RegExp>;
  public cepMask: Array<string | RegExp>;
  public enumAccountType: AccountType;
  
  public errorMessage = '';
  public listErrorMessage: any[];

  public errorMessageAvailability = 'Não há disponibilidade para ser exibido!'
  public listErrorMessageAvailability: any[];

  public showAvailabilityData = false;

  public editMode: boolean;
  public stateList: any = [];
  public citiesList: any = [];
  public cepSearch: boolean = false;
  private datePipe = new DatePipe('pt-BR');

  requiredValidator = Validators.required;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private loading: MessageService,
    private formBuilder: FormBuilder,
    private bankService: BankService,
    private utilService: UtilService,
    private teacherService: TeacherService,
    private initTeacher: InitTeacherService,
    private profileService: ProfileService,
    private addressService: AddressService
  ) {
    this.loading.showLoading();
    this.initTeacher.setConfigPage(false, false);
    this.phoneMask = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.cepMask = [/[0-9]/, /\d/ ,/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    this.onInitForm();
  }

  ngOnInit(): void {
    this.checkHaveTeacher();
    this.getDaysOfWeek();
    this.getHoursOfDay();
    this.showAvailabilityData = true;
  }

  checkHaveTeacher() {
    if (this.initTeacher.user != null && this.initTeacher.user.id > 0) {
      this.initTeacher.getTeacherUser().pipe(first()).subscribe((response: any) => {
        this.teacher = response;
        this.user = this.initTeacher.user;
        this.initialyze();
      }, (error: any) => {
        console.log(error);
        this.initialyze();
      });
    } else {
      this.initialyze();
    }
  }

  initialyze() {
    this.loadBanks();
    this.loadStates();
    this.editMode = false;
    this.userId = this.initTeacher.user.id;
    this.loadProfile(this.teacher.id);
    this.loadTeacherAvailability(this.teacher?.id ?? 0);
    this.loading.hideLoading();
  }

  onInitForm() {
    this.validProfile = true;
    this.flagForeignPerson = false;
    this.profileForm = this.formBuilder.group({
      teacherId: [0],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      birthDate: ['', [Validators.required]],
      userId: ['', [Validators.required]],

      email: ['', [Validators.required, Validators.maxLength(80)]],
      phone: [{value: '', disabled: this.foreignPersonDisableFields}, [Validators.required, Validators.maxLength(15)]],
      cpf: [{value: '', disabled: this.foreignPersonDisableFields}, Validators.required, Validators.maxLength(20)],
      zipCode: ['', [Validators.maxLength(8)]],
      street: ['', [Validators.required, Validators.maxLength(100)]],
      number: [''],
      complement: ['', [Validators.maxLength(20)]],
      neighborhood: ['', [Validators.required, Validators.maxLength(50)]],

      cityId: ['', [Validators.required]],
      cityName: [''],

      stateId: ['', [Validators.required]],
      stateUf: [''],

      country: [''],

      bankId: ['', [Validators.required]],
      accountType: [0, [Validators.required]],
      agency: ['', [Validators.required]],
      account: ['', [Validators.required]],
      pix: [''],

      moreInformation: ['', [Validators.maxLength(200)]],
      universityGraduate: ['', [Validators.maxLength(100)]],
      
      foreignPerson: [false]
    });

    this.profileForm.controls['cpf'].setErrors(null);
    this.profileForm.controls['phone'].setErrors(null);
    this.profileForm.controls['birthDate'].setErrors(null);
  }
  
  onSetEventsForm() {
    this.f.phone.valueChanges.subscribe((value: string) => {
      var strPhone = value.toString().replace('(', '').replace(')', '').replace('-', '').replace('_', '').replace(' ', '');
      var phoneNumber = parseInt(strPhone);
      if (!(phoneNumber >= 11111111111 && phoneNumber <= 99999999999)) {
        value = phoneNumber.toString();
        this.f.phone.status = "INVALID";
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.profileForm ? this.profileForm.controls : null;
  }

  loadBanks() {
    this.bankService.getall().subscribe(response => {
        if (response.typeResponse == TypeResponse.Success) {
          this.bankList = response.data;
        } else {
          this.bankList = new Array<Bank>();
        }
      }
    );
  }

  onSubmit(): void {
    this.loading.showLoading();
    this.submitted = true;
    this.validProfile = true;
    
    // Errors Cleaning
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.profileForm.invalid) {
      this.loading.hideLoading();
      this.submitted = false;
      this.validProfile = false;
      this.errorMessage = 'Informe todos os campos para continuar.';
      return;
    }

    this.profile = new Profile(this.profileForm.value);
    
    this.profileService.save(this.profile)
    .pipe(first()).subscribe((dataReturn: any) => {
      if (dataReturn.typeResponse == TypeResponse.Success) {
        this.validProfile = true;
        this.doneAvailability();
      } else {
        this.validProfile = false;
        this.errorMessage = dataReturn.message;
        if (dataReturn?.data?.errors && dataReturn.data.errors.length > 0) {
          this.listErrorMessage = [];
          for (let i = 0; i <= dataReturn.data.errors.length; i++) {
            this.listErrorMessage.push(dataReturn.data.errors[i].Description);
          }
        }
        this.afterFailed();
      }
      this.submitted = false;
      this.loading.hideLoading();
    }, (ex: any) => {
      this.validProfile = false;
      this.submitted = false;
      this.loading.hideLoading();
      if (ex && ex.status == 404) {
        this.errorMessage = ex.error.message;
      } else {
        this.errorMessage = ex.ok === true ? "Erro ao salvar o perfil." : ex.error.message;
      }
      this.afterFailed();
    });
  }

  doneAvailability() {
    this.loading.showLoading();
      this.submitted = true;
      this.validAvailability = true;
      
      // Errors Cleaning
      this.errorMessage = '';
  
      // stop here if form is invalid
      if (!this.validAvailability) {
        this.loading.hideLoading();
        this.submitted = false;
        this.errorMessage = 'Disponibilidade informada é inválida.';
        return;
      }
  
      this.teacherWithAvailability = this.teacherWithAvailability.teacherAvailabilityItems !== null 
        ? new TeacherWithAvailability({
          teacherId: this.teacher.id,
          teacherAvailabilityItems: this.teacherWithAvailability.teacherAvailabilityItems
        })
        : new TeacherWithAvailability(null);
  
      this.teacherService.setAvailability(this.teacher.id, this.teacherWithAvailability)
      .subscribe((response: any) => {
        this.loading.hideLoading();
        if (response.typeResponse == TypeResponse.Success) {
          this.afterSuccess();
        } else {
          this.afterFailed();
        }
      }, (error: any) => {
        this.loading.hideLoading();
        this.afterFailed();
      });
  }

  afterSuccess() {
    swal({
      type: 'success',
      title: `Perfil e disponibilidade atualizados com sucesso!`,
      showConfirmButton: true
    }).then((result) => {
      this.loading.hideLoading();
      this.cancel();
      this.router.navigate(['/teacher/home']);
    });
  }

  afterFailed() {
    swal({
      type: 'warning',
      title: `Não foi possível atualizar o seu perfil e a disponibilidade!`,
      showConfirmButton: true,
    }).then((result) => {
      this.loading.hideLoading();
      this.cancel();
    });
  }

  cancel() {
    this.dismissModal();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  loadStates() {
    this.addressService.getStates().subscribe((response: { typeResponse: TypeResponse; data: any; }) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.stateList = response.data;
      } else {
        this.stateList = new Array<State>();
      }
    });
  }

  getCitiesByStateId(stateId: number) {
    if(stateId == null || stateId == undefined || stateId == 0) {
      stateId = this.teacher.stateId;
    }
    this.teacher.cityId = null;

    this.addressService.getCityByStateId(stateId).subscribe((response: any) => {
      this.citiesList = response;
    });
  }

  loadTeacherAvailability(teacherId: any) {
    this.loading.showLoading();
    this.teacherService.getAvailability(teacherId).subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.teacherWithAvailability = response.data;
      } else {
        this.teacherWithAvailability = null;
      }
      this.daysOfWeekDataList$.next(this.daysOfWeekDataList);
      this.loading.hideLoading();
    }, (error: any) => {
      this.loading.hideLoading();
    });
  }

  setTeacherAvailability(dayOfWeek: number, hour: any) {
    if (hour && dayOfWeek >= 0) {
      let item = this.teacherWithAvailability.teacherAvailabilityItems.find(x => x.dayOfWeek == dayOfWeek);
      if (item) {
        const index = item.hours.indexOf(hour, 0);
        if (index > -1) {
          item.hours.splice(index, 1);
        } else {
          item.hours.push(hour);
        }
      } else {
        let newItem = {
          dayOfWeek: dayOfWeek,
          hours: []
        };
        newItem.hours.push(hour);
        this.teacherWithAvailability.teacherAvailabilityItems.push(newItem);
      }
    }
    this.daysOfWeekDataList$.next(this.daysOfWeekDataList);
  }

  checkAvailability(dayOfWeek: number, hour: any) {
    let item = this.teacherWithAvailability.teacherAvailabilityItems.find(x => x.dayOfWeek === dayOfWeek);
    return item?.hours?.some(y => y === hour);
  }

  randomIntFromInterval(min: number, max: number): Array<number> { // min and max included 
    let hours: Array<any> = [];
    let grade = Math.floor(Math.random() * (12 - min + 1) + min);
    for (let i = 0; i < grade; i++) {
      let hour = Math.floor(Math.random() * (max - min + 1) + min);
      let hours_str = hour < 10 ? "0"+hour : hour;
      hours.push(hours_str+':00');
    }
    return hours;
  }

  getDaysOfWeek() {
    const daysOfWeekList: Array<object> = [];
    for (var value in RecurrenceDay) {
      if (Number.isInteger(Number.parseInt(value))) {
        daysOfWeekList.push({
          value: Number.parseInt(value), 
          text: RecurrenceDayLabel.get(Number.parseInt(value))
        });
      }
    }
    this.daysOfWeekDataList = this.getTemplateDaysOfWeekList(daysOfWeekList);
  }

  getTemplateDaysOfWeekList(data: Array<any>): any[] {
    if (data != null && data.length > 0) {
      this.daysOfWeekList = data;
      let arrayDayOfWeekList: Array<any> = new Array<any>();
      for (let i = 0; i < data.length; i++) {
        const element: any = {
          id: data[i].value,
          text: data[i].text
        };
        arrayDayOfWeekList.push(element);
      }
      return arrayDayOfWeekList;
    }
    return new Array<any>();
  }

  getHoursOfDay() {
    // Loop from current hour number to 23
    for(var i = 0; i < 24; i++) {
      var hour = this.toHHMMSS(i*60*60);
      var hour_split = hour.split(':');
      // Put loop counter into array with "00" next to it
      this.hoursOfDay.push(hour);
      this.hoursOfDay.push(hour_split[0] + ':30');
    }
  }

  toHHMMSS(number: any) {
    var sec_num = parseInt(number, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    //var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    //var seconds = sec_num - (hours * 3600) - (minutes * 60);
    let hours_str = hours < 10 ? "0"+hours : hours;
    //let minutes_str = hours < 10 ? "0"+minutes : minutes+"0";
    return hours_str+':00'///+minutes_str;
  }

  checkTeacherByCpf(input: { target: { value: any; }; }) {
    if (this.f.foreignPerson.value) {
      this.profileForm.controls['cpf'].setErrors(null);
      this.validProfile = true;
    } else {
      if (!(input?.target?.value) || !this.utilService.isValidCPF(input.target.value)) {
        this.validProfile = false;
        this.profileForm.controls['cpf'].setErrors({'incorrect': true});
        this.errorMessage = "Valide todos os campos antes de prosseguir."
        return;
      }
  
      let cpf = input.target.value;
      this.teacherService.checkTeacherByCpf(cpf).subscribe((response: { typeResponse: TypeResponse; data: any; }) => {
        if (response.typeResponse == TypeResponse.Success && response.data == null) {
          this.profileForm.controls['cpf'].setErrors(null);
          this.validProfile = true;
        } else {
          this.profileForm.controls['cpf'].setErrors({'already_exists': true});
          this.validProfile = false;
        }
      }, (error: any) => {
        this.validProfile = false;
        this.errorMessage = "Valide todos os campos antes de prosseguir."
        this.listErrorMessage.push(error);
      });
    }
  }

  loadProfile(id: any) {
    this.loading.showLoading();
    this.profileService.getById(id)
    .subscribe((response: { typeResponse: TypeResponse; data: any; }) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.validProfile = true;
        this.profile = new Profile(response.data);
        this.setFormByEntity(this.profile);
      } else {
        this.validProfile = true;
      }
      this.loading.hideLoading();
      }, (error: { error: { error: string; }; }) => {
        if (error.error.error === 'invalid_token') {
          this.router.navigate(['/auth/login']);
        }
        this.loading.hideLoading();
      }
    );
  }

  loadAddressByCep(zipCode: any) {
    this.cepSearch = true;
    if (zipCode.value && parseInt(zipCode.value) && zipCode.value.length == 8) {
      let cep = zipCode.value;
      this.addressService.getAddressByCep(cep).subscribe((response: { erro: any; data: { uf: string; logradouro: any; bairro: any; ibge: string; }; }) => {
        if (response.erro) {
          this.f().age.setErrors({invalidNumber:true})
        }
        this.addressService.getCityByUf(response.data.uf).subscribe((p: { data: any; }) => {
          this.citiesList = p.data;
          this.profileForm.controls['street'].setValue(response.data.logradouro);
          this.profileForm.controls['neighborhood'].setValue(response.data.bairro);

          this.addressService.getCityByIbgeCode(response.data.ibge).subscribe((p: { data: { id: any; stateId: any; }; }) => {
            this.profileForm.controls['cityId'].setValue(p.data.id);
            this.profileForm.controls['stateId'].setValue(p.data.stateId);
            this.cepSearch = false;
            this.profileForm.controls['number'].focus();
          });
        });
      });
    } else {
      swal({
        type: 'warning',
        html: 'Por favor informe um CEP válido para buscar.',
        title: 'CEP inválido',
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 3000
      }).then(() => {
        this.loadStates();
        this.cepSearch = false;
      });
    }
  }

  setFormByEntity(data: any) {
    this.profileForm = this.formBuilder.group({
      teacherId: new FormControl(data.teacherId),
      name: new FormControl(data.name),
      birthDate: new FormControl(this.datePipe.transform(data.birthDate, "yyyy-MM-dd", '-0300', 'pt-BR')),
      email: new FormControl(data.email),
      phone: new FormControl(data.phone),
      cpf: new FormControl(data.cpf),
      zipCode: new FormControl(data.zipCode),
      street: new FormControl(data.street),
      number: new FormControl(data.number),
      complement: new FormControl(data.complement),
      neighborhood: new FormControl(data.neighborhood),

      userId: new FormControl(data.userId),
      cityId: new FormControl(data.cityId),
      cityName: new FormControl(data.cityName),
      stateId: new FormControl(data.stateId),
      stateUf: new FormControl(data.stateUf),
      country: new FormControl(data.country),

      bankId: new FormControl(data.bankId),
      accountType: new FormControl(data.accountType.toString()),
      agency: new FormControl(data.agency),
      account: new FormControl(data.account),
      pix: new FormControl(data.pix),
      foreignPerson: new FormControl(data.foreignPerson),

      moreInformation: new FormControl(data.moreInformation),
      universityGraduate: new FormControl(data.universityGraduate)
    });
    this.profileForm.controls['userId'].setValue(data.userId);
    this.profileForm.controls['accountType'].setValue(data.accountType.toString());
    this.profileForm.controls['stateId'].setValue(data.stateId);
    this.addressService.getCityByStateId(data.stateId).subscribe((p: { data: any; }) => {
      this.citiesList = p.data;
      this.profileForm.controls['cityId'].setValue(data.cityId);
    });

    this.setForeignPersonValues(data.foreignPerson);
  }

  setForeignPersonValues(uForeignPerson: boolean) {
    this.flagForeignPerson = uForeignPerson;
    this.profileForm.controls['foreignPerson'].setValue(uForeignPerson);

    let reqListFkPerson = ['cityName', 'stateUf', 'country']
    let notReqListFkPerson = ['zipCode', 'cpf', 'neighborhood', 'cityId', 'stateId', 'number', 'phone']

    if (uForeignPerson === true) {
      for (let i = 0; i < notReqListFkPerson.length; i++) {
        this.profileForm.get(notReqListFkPerson[i]).setValue('');
        this.profileForm.get(notReqListFkPerson[i]).clearValidators();
        this.profileForm.get(notReqListFkPerson[i]).updateValueAndValidity();
      }
      for (let i = 0; i < reqListFkPerson.length; i++) {
        this.profileForm.get(reqListFkPerson[i]).setValidators([Validators.required]);
        this.profileForm.get(reqListFkPerson[i]).updateValueAndValidity();
      }
    } else {
      for (let i = 0; i < reqListFkPerson.length; i++) {
        this.profileForm.get(reqListFkPerson[i]).clearValidators();
        this.profileForm.get(reqListFkPerson[i]).updateValueAndValidity();
      }
      for (let i = 0; i < notReqListFkPerson.length; i++) {
        this.profileForm.get(notReqListFkPerson[i]).setValidators([Validators.required]);
        this.profileForm.get(notReqListFkPerson[i]).updateValueAndValidity();
      }
    }
  }

  onForeignPerson(input: { currentTarget: { checked: any; }; }) {
    this.setForeignPersonValues(input.currentTarget.checked);
  }

  onCancel(): void {
    this.router.navigate([this.returnUrl]);
  }

  onAccountTypeChecked(input: any) {
    console.log(input);
  }
}
