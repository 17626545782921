import { Injectable } from '@angular/core';
import { StatusClass, StatusClassCss } from '../enum';

@Injectable({
  providedIn: 'root'
})
export class CalendarUtilService {
  getDesignClassCss(status: StatusClass): Array<string> {
    const addClasses = Array<string>();
    addClasses.push(StatusClassCss.get(status));
    addClasses.push('fs-sm');
    return addClasses;
  }
}
