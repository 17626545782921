<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/teacher/home']">Home</a>
    </li>
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/teacher/course']">Biblioteca</a>
    </li>
    <li class="breadcrumb-item active">Conteúdo</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>
<div class="row">
    <div class="col-12">
        <div class="card text-left">
            <div class="card-body">
                <div class="col">
                    <div class="bg-white flex-1 p-4 h-100">
                        <h1 class="display-4">{{serieContent?.name}}</h1>
                        <p class="card-text text-justify text-muted">{{serieContent?.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="row">
    <div class="col-12">
        <!-- post -->
        <div class="card mb-g border shadow-0" *ngFor="let item of serieContent?.episodes; let i = index">
            <div class="card-header bg-white p-0">
                <div class="p-3 d-flex flex-row">
                    <div class="d-block flex-shrink-0">
                        <img [src]="(this.contentPath + serieContent.folderName+'_thumb.jpg')" class="img-fluid img-thumbnail" alt="">
                    </div>
                    <div class="d-block ml-2">
                        <span class="h6 font-weight-bold text-uppercase d-block m-0">
                            <a href="javascript:void(0);" (click)="viewClass(serieContent.item, item.name, item.description, i)">
                                {{serieContent.name}}
                            </a> |
                            <span class="fw-300 text-primary">Episódio {{item.name}}</span> | 
                            <span class="fw-700">Class {{i+1}}</span>
                        </span>
                        <a href="javascript:void(0);" class="fs-sm text-info h6 fw-500 mb-0 d-block" (click)="viewClass(serieContent.item, item.name, item.description, i)">
                            {{serieContent.name}}
                        </a>
                        <div class="d-flex mt-1 text-warning align-items-center disabled">
                            <i class="fal fa-star mr-1"></i>
                            <i class="fal fa-star mr-1"></i>
                            <i class="fal fa-star mr-1"></i>
                            <i class="fal fa-star mr-1"></i>
                            <i class="fal fa-star mr-1"></i>
                        </div>
                    </div>
                    <a href="javascript:void(0);" class="flex-shrink-0 ml-auto text-danger fw-700" (click)="viewClass(serieContent.item, item.name, item.description, i)">
                        Assistir aula <i class="fal fa-reply ml-2"></i>
                    </a>
                </div>
            </div>
        </div>
        <!-- post -end -->
    </div>
</div>
