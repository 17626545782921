import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SupplierService } from '@app/admin/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services/message.service';

@Component({ templateUrl: 'supplier-list.component.html', providers: [DatePipe] })
export class SupplierListComponent implements OnInit {

  public suppliers: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public columns: Array<any> = [
    { title: "Código", name: "id", sort: "asc", className: "text-center" },
    { title: "Nome", name: "socialName", sort: "asc" },
    { title: "Tipo", name: "type", sort: "asc" },
    { title: "telefone", name: "phone", sort: "asc", className: "text-center" },
    { title: "Status", name: "status", sort: "asc", className: "text-center" },
    {
      title: "Criado em",
      name: "createdOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    },
    {
      title: "Modificado em",
      name: "updatedOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    }
  ];
  
  constructor(
    private initAdmin: InitAdminService,
    private supplierService: SupplierService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private loading: MessageService
  ) { }

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadBanks();
  }

  loadBanks() {
    this.loading.showLoading();
    this.supplierService.getall()
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.suppliers = response.data;
        this.showTable = true;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
        this.showTable = false;
      }
      this.loading.hideLoading();
    },
    error => {
      this.loading.hideLoading();
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar os dados dos fornecedores.';
    });
  }

  delete(id): void {
    // Swal({
    //   title: "Deseja realmente excluir este registro?",
    //   text: "Ao clicar ok, este será excluído permanentemente!",
    //   type: 'warning',
    //   showConfirmButton: true,
    //   showCancelButton: true     
    // })
    // .then((willDelete)=> {
    // });
  }

  edit(row:any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/supplier', { id: row.id }]);
  }
}
