<div class="page-wrapper">
    <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
            <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                <div class="d-flex align-items-center container p-0">
                    <header-logo></header-logo>
                    <a href="#/auth/register" class="btn btn-sm btn-outline-primary text-white ml-auto">
                        Criar uma conta
                    </a>
                </div>
            </div>
            <div class="d-flex flex-1" style="background: url(/assets/img/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
                <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0 text-white d-flex align-items-center justify-content-center">
                    <form id="js-login" action="intel_analytics_dashboard.html" role="form" class="text-center text-white mb-5 pb-5">
                        <div class="py-3">
                            <img src="/assets/img/avatars/avatar-m.png" class="img-responsive rounded-circle img-thumbnail" alt="thumbnail">
                        </div>
                        <div class="form-group">
                            <h3>
                                Mateus Ferreira
                                <small>
                                    aluno@englishcoffee.com.br
                                </small>
                            </h3>
                            <p class="text-white opacity-50">Informe sua senha para desbloquear</p>
                            <div class="input-group input-group-lg">
                                <input type="text" class="form-control" placeholder="Senha">
                                <div class="input-group-append">
                                    <button class="btn btn-success shadow-0" type="button" id="button-addon5"><i class="fal fa-key"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <a href="#/auth/login" class="text-white opacity-90">Não é <b>Mateus Ferrreira</b>? Clique aqui</a>
                        </div>
                    </form>
                    <footer-auth></footer-auth>
                </div>
            </div>
        </div>
    </div>
</div>
