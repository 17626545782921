import { BaseService } from '@app/shared/services';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';
import { AvgScore } from '@app/shared/enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WritingTestService {
  private route: string = "content";
  private controller: string = "writingtest";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  getAll(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getById(id: string): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }
  
  remove(id: any): Observable<any> {
    return this.http.delete<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }
  
  save(id: number, model: any, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/${id}`, model);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}/add`, model);
    }
  }

  getByTeacherId(teacherId: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/teacher/${teacherId}`);
  }

  calcAvgScore(score: number): AvgScore {
    return AvgScore.Basic;
  }
}
