import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from '@app/auth/model';
import { environment } from '@environments/environment';
import { ContentAudit, ContentAuditPost } from '../model';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContentAuditService {
  private route: string = "auditable";
  private controller: string = "contentAudit";
  private endpointPrefix: string;

  constructor(private http: HttpClient) {
    this.endpointPrefix = `${environment.urlAPI}/${this.route}/${this.controller}`;
  }

  get(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/get`);
  }

  getToGrid(): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/grid`);
  }

  getById(id: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/${id}`);
  }
  
  getByEpisodeId(id: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/episodio/${id}`);
  }

  getByUserId(id: any): any {
    return this.http.get<ResponseResult>(`${this.endpointPrefix}/user/${id}`);
  }

  save(model: ContentAudit, editing: boolean = false): any {
    if (editing) { //update
      return this.http.put<ResponseResult>(`${this.endpointPrefix}/${model.id}`, model);
    } else { //new
      return this.http.post<ResponseResult>(`${this.endpointPrefix}`, model);
    }
  }

  audit(auditPost: ContentAuditPost) {
    this.auditPost(auditPost).pipe(first()).subscribe((response: any) => {
      console.log(response);
    }, (error: any) => {
      console.log(error);
    });
  }

  private auditPost(model: ContentAuditPost) {
    return this.http.post<ResponseResult>(`${this.endpointPrefix}/audit`, model);
  }
}