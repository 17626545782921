export enum ExperimentalLevel {
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
  Level4 = 4,
  Level5 = 5,
  Level6 = 6,
}

export const ExperimentalLevelLabel = new Map<number, string>([
  [ExperimentalLevel.Level1, 'Sou iniciante'],
  [ExperimentalLevel.Level2, 'Sou zero, não sei nada de inglês'],
  [ExperimentalLevel.Level3, 'Sei o básico'],
  [
    ExperimentalLevel.Level4,
    'Sou intermediário, consigo conversar em alguns casos',
  ],
  [ExperimentalLevel.Level5, 'Sou avançado, preciso melhorar'],
  [ExperimentalLevel.Level6, 'Outro'],
]);
