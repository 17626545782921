<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item">
    <a href="javascript:void(0);">English Coffee</a>
  </li>
  <li class="breadcrumb-item active">Home</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol>
<div class="row mb-4">
  <div class="col-12">
    <h1 class="subheader-title">
      <i class="subheader-icon fal fa-chart-area"></i>
      <span class="fw-300">Welcome to</span> English Coffee
      <small>Portal de conteúdos e aulas do aluno</small>
    </h1>
  </div>
</div>

<div class="d-md-block d-none">
  <div class="row">
    <div class="col-9">
      <div class="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
        <h3 class="font-weight-bold mb-3">
          Estamos felizes em ter você por aqui, afinal a escolha de ser bilíngue
          é algo super importante.
        </h3>
        <h3 class="font-weight-bold mb-3">
          Veja abaixo alguns pontos essenciais para as suas aulas:
        </h3>
        <ul class="list-unstyled">
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700"
                >Como acessar as minhas aulas online ao vivo?</span
              ><br />
              Clicar em Agenda, Dia de sua aula e Botão ao vivo
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Como desmarcar minha aula do dia?</span
              ><br />
              Com 3 horas de antecedência via Agenda (plataforma). Clicar em
              Agenda, horário de aula do dia que irá faltar e desmarcar.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700"
                >Quando tenho direito à reposição de aula?</span
              ><br />
              Avise com no mínimo 3 horas de antecedência, independentemente do
              motivo. Se a aula for 7am ou 8 am, precisará ser avisada até 22h
              do dia anterior.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700"
                >É possível cancelar uma aula de reposição?</span
              ><br />
              Não, aula de remarcação não há substituição.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Onde alterar meu horário de forma fixa?</span
              ><br />
              Através da Central de Atendimento:
              <a
                href="https://api.whatsapp.com/send?phone=551941071329"
                target="_blank"
                >19 41071329</a
              >.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Como mandar algo para o meu professor?</span
              ><br />
              Via Chat na plataforma.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700"
                >Como avaliar o desempenho das aulas e o professor?</span
              ><br />
              Através do botão: Pesquisa de Satisfação
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Como ver o teste de escrita?</span><br />
              Através do botão: Writing Test
            </p>
          </li>

          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Onde assistir o seriado?</span><br />
              Botão Biblioteca
            </p>
          </li>

          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Qual seriado assistir?</span><br />
              Após a primeira aula, ficará disponível na confirmação da aula, o
              episódio e série.
            </p>
          </li>

          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Onde encontro a 2ª via do meu boleto?</span
              ><br />
              Através da Central de Atendimento:
              <a
                href="https://api.whatsapp.com/send?phone=551941071329"
                target="_blank"
                >19 41071329</a
              >.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700"
                >Quero indicar um amigo(a) para fazer aula!</span
              ><br />
              Através da Central de Atendimento:
              <a
                href="https://api.whatsapp.com/send?phone=551941071329"
                target="_blank"
                >19 41071329</a
              >
              Ou e-mail:
              <a
                href="mailto:atendimento@englishcoffee.com.br"
                target="_blank"
                >{{ "atendimento@englishcoffee.com.br" }}</a
              >.
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-3">
      <div class="card bg-primary rounded">
        <div class="card-body text-white">
          <h4 class="card-title fw-700">
            <i class="subheader-icon fal fa-window text-white"></i> Aula de Hoje
            / Próxima(s) Aula(s)
          </h4>
          <div *ngFor="let nextClassEvent of nextClassEvents; let i = index">
            <p class="text-left mb-1">
              Data/Hora:
              <span class="fw-700">{{
                nextClassEvent?.date | date : "dd/MM/yyyy HH:mm:ss"
              }}</span>
            </p>
            <p class="text-left">
              Teacher:
              <span class="fw-700">{{
                nextClassEvent?.extendedProps?.teacher
              }}</span>
            </p>
            <a
              type="button"
              [ngClass]="{
                disabled: checkClassRoomVisible(nextClassEvent) === false
              }"
              *ngIf="checkEditByStatus(nextClassEvent)"
              class="btn btn-light btn-pills btn-block waves-effect waves-themed text-primary fw-700"
              (click)="checkConfirmClass(nextClassEvent)"
              target="_blank"
            >
              <span class="fas fa-video mr-1"></span>
              Entrar na aula ao vivo
            </a>
            <span
              *ngIf="!checkEditByStatus(nextClassEvent)"
              [innerHTML]="designStatusClass(nextClassEvent)"
            ></span>
            <p
              class="text-left text-muted mt-1 mb-1"
              *ngIf="checkClassRoomVisible(nextClassEvent) === false"
            >
              <small class="text-white"
                >O link estará disponível no dia da aula.</small
              >
            </p>
            <hr *ngIf="i >= 0 && i < nextClassEvents.length - 1" />
          </div>
          <!-- <br>
            <a class="text-white" [href]="'https://meet.jit.si/ec-class-room-'+nextClassEvent?.extendedProps?.classRoom">https://meet.jit.si/ec-class-room-{{nextClassEvent?.extendedProps?.classRoom}}</a> -->
        </div>
      </div>
      <div class="card mt-3 rounded bg-danger-400">
        <div class="card-body text-white">
          <h4 class="card-title fw-700">
            <i class="subheader-icon fal fa-shopping-cart text-white"></i>
            Produtos English Coffee
          </h4>
          <h5>Adquira já um de nossos produtos!</h5>
          <p>
            Temos vários produtos, como: camisetas, canecas, ebook,
            café/cápsulas, etc.
          </p>
          <a
            target="_blank"
            class="btn btn-light btn-pills btn-block waves-effect waves-themed text-danger fw-700"
            href="https://api.whatsapp.com/send?phone=551941071329&text=Ol%C3%A1%2C+gostaria+de+conhecer+os+produtos+para+venda+da+English+Coffee%21"
          >
            <i class="fas fa-shopping-cart mr-2"></i> Comprar
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-md-none">
  <div class="row">
    <div class="col-12">
      <div class="card bg-primary rounded">
        <div class="card-body text-white">
          <h4 class="card-title fw-700">
            <i class="subheader-icon fal fa-window text-white"></i> Aula de Hoje
            / Próxima(s) Aula(s)
          </h4>
          <div *ngFor="let nextClassEvent of nextClassEvents; let i = index">
            <p class="text-left mb-1">
              Data/Hora:
              <span class="fw-700">{{
                nextClassEvent?.date | date : "dd/MM/yyyy HH:mm:ss"
              }}</span>
            </p>
            <p class="text-left">
              Teacher:
              <span class="fw-700">{{
                nextClassEvent?.extendedProps?.teacher
              }}</span>
            </p>
            <a
              type="button"
              [ngClass]="{
                disabled: checkClassRoomVisible(nextClassEvent) === false
              }"
              class="btn btn-light btn-pills btn-block waves-effect waves-themed text-primary fw-700"
              (click)="checkConfirmClass(nextClassEvent)"
              target="_blank"
            >
              <span class="fas fa-video mr-1"></span>
              Entrar na aula ao vivo
            </a>
            <p
              class="text-left text-muted mt-1 mb-1"
              *ngIf="checkClassRoomVisible(nextClassEvent) === false"
            >
              <small class="text-white"
                >O link estará disponível no dia da aula.</small
              >
            </p>
            <hr *ngIf="i >= 0 && i < nextClassEvents.length - 1" />
          </div>
        </div>
      </div>
      <div class="card mt-3 rounded bg-danger-400">
        <div class="card-body text-white">
          <h4 class="card-title fw-700">
            <i class="subheader-icon fal fa-shopping-cart text-white"></i>
            Produtos English Coffee
          </h4>
          <h5>Adquira já um de nossos produtos!</h5>
          <p>
            Temos vários produtos, como: camisetas, canecas, ebook,
            café/cápsulas, etc.
          </p>
          <a
            target="_blank"
            class="btn btn-light btn-pills btn-block waves-effect waves-themed text-danger fw-700"
            href="https://api.whatsapp.com/send?phone=551941071329&text=Ol%C3%A1%2C+gostaria+de+conhecer+os+produtos+para+venda+da+English+Coffee%21"
          >
            <i class="fas fa-shopping-cart mr-2"></i> Comprar
          </a>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
        <h3 class="font-weight-bold mb-3">
          Estamos felizes em ter você por aqui, afinal a escolha de ser bilíngue
          é algo super importante.
        </h3>
        <h3 class="font-weight-bold mb-3">
          Veja abaixo alguns pontos essenciais para as suas aulas:
        </h3>
        <ul class="list-unstyled">
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700"
                >Como acessar as minhas aulas online ao vivo?</span
              ><br />
              Clicar em Agenda, Dia de sua aula e Botão ao vivo
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Como desmarcar minha aula do dia?</span
              ><br />
              Com 3 horas de antecedência via Agenda (plataforma). Clicar em
              Agenda, horário de aula do dia que irá faltar e desmarcar.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700"
                >Quando tenho direito à reposição de aula?</span
              ><br />
              Avise com no mínimo 3 horas de antecedência, independentemente do
              motivo. Se a aula for 7am ou 8 am, precisará ser avisada até 22h
              do dia anterior.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700"
                >É possível cancelar uma aula de reposição?</span
              ><br />
              Não, aula de remarcação não há substituição.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Onde alterar meu horário de forma fixa?</span
              ><br />
              Através da Central de Atendimento:
              <a
                href="https://api.whatsapp.com/send?phone=551941071329"
                target="_blank"
                >19 41071329</a
              >.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Como mandar algo para o meu professor?</span
              ><br />
              Via Chat na plataforma.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700"
                >Como avaliar o desempenho das aulas e o professor?</span
              ><br />
              Através do botão: Pesquisa de Satisfação
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Como ver o teste de escrita?</span><br />
              Através do botão: Writing Test
            </p>
          </li>

          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Onde assistir o seriado?</span><br />
              Botão Biblioteca
            </p>
          </li>

          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Qual seriado assistir?</span><br />
              Após a primeira aula, ficará disponível na confirmação da aula, o
              episódio e série.
            </p>
          </li>

          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700">Onde encontro a 2ª via do meu boleto?</span
              ><br />
              Através da Central de Atendimento:
              <a
                href="https://api.whatsapp.com/send?phone=551941071329"
                target="_blank"
                >19 41071329</a
              >.
            </p>
          </li>
          <li class="mb-2">
            <p>
              <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
              <span class="fw-700"
                >Quero indicar um amigo(a) para fazer aula!</span
              ><br />
              Através da Central de Atendimento:
              <a
                href="https://api.whatsapp.com/send?phone=551941071329"
                target="_blank"
                >19 41071329</a
              >
              Ou e-mail:
              <a href="mailto:atendimento@englishcoffee.com.br" target="_blank"
                >atendimento@englishcoffee.com.br</a
              >.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #mdInfoDetails let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Informações importantes</h4>
  </div>
  <div class="modal-body">
    <div class="alert alert-primary text-dark" role="alert">
      Suas informações pessoais são particulares e seguras
    </div>
    <div
      class="alert alert-warning alert-dismissible text-center fade show mt-3"
      *ngIf="!validEntity"
    >
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
      <div class="d-flex align-items-center">
        <div class="alert-icon">
          <span class="icon-stack icon-stack-md">
            <i class="base-7 icon-stack-3x color-warning-900"></i>
            <i class="fal fa-info-circle icon-stack-1x text-white"></i>
          </span>
        </div>
        <div class="flex-1">
          <span class="h3">{{ errorMessage }}</span>
          <div *ngIf="listErrorMessage.length > 0" class="text">
            <br />
            <div class="small" *ngFor="let error of listErrorMessage">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="muted" />
    <div class="row no-gutters">
      <div class="col-md-12 ml-auto text-right">
        <div class="btn-group btn-panel">
          <button
            id="js-login-btn"
            class="btn btn-primary"
            [disabled]="submitted"
          >
            <span
              *ngIf="submitted"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Confirmar
          </button>
          <button
            id="js-cancel-btn"
            class="btn btn-default"
            (click)="c('Close click')"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- <ng-template #mdSchoolBreak let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="alert mb-0">
      <div class="d-flex flex-start w-100">
        <div class="d-flex flex-fill mt-2">
          <div class="flex-fill">
            <span class="h2 font-bold text-primary">
              <i class="fas fa-plane opacity-100 color-default-500"></i> Recesso
              de julho
            </span>
            <h4 class="mt-3 text-justify">
              Nosso recesso de Julho chegou! Vamos recuperar nossas energias e
              voltar com tudo, hein! 🔋
            </h4>
            <div class="mb-2">
              <div class="card-body px-0 h5">
                <div
                  class="d-flex flex-row align-items-center color-primary-500"
                >
                  <span class="icon-stack display-3 flex-shrink-0">
                    <i class="fal fa-calendar icon-stack-2x"></i>
                  </span>
                  <div class="ml-3 fw-700">
                    <strong>Recesso:</strong>
                    <br />
                    18/07 a 31/07
                  </div>
                </div>
              </div>
            </div>
            <h3 class="mt-3 text-justify color-default-500 fw-700">
              Importante:
            </h3>
            <h4 class="text-justify">
              As aulas do recesso, o aluno não perde, ele tem o direito de fazer
              a reposição no final do treinamento ⚔️🤩
            </h4>
            <h4 class="text-justify">Saiba mais em suas notificações 🔔</h4>
            <h4 class="fw-700 mt-4">Goooo ahead :)</h4>
            <h5 class="mt-3">
              See you
              <br />
              <strong>English Coffee Method</strong>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="m-0" />
  <div class="modal-footer">
    <button
      id="js-cancel-btn"
      class="btn btn-default"
      (click)="c('Close click')"
    >
      Fechar
    </button>
  </div>
</ng-template> -->

<ng-template #mdSchoolTerms let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      Hey guys! <br /><small class="mb-0"
        >Temos um recado importante para você.</small
      >
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="c('Close click')"
    >
      <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body pb-0">
    <div class="row">
      <div class="col-12">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            src="https://www.youtube.com/embed/4iGzY8TueiY?controls=0&rel=0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
    <hr />
  </div>
  <div class="modal-footer pt-0">
    <button
      id="js-cancel-btn"
      class="btn btn-default"
      (click)="c('Close click')"
    >
      Fechar
    </button>
  </div>
</ng-template>
