import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Helpers
import { AuthGuard } from '../auth/helpers/auth.guard';

import { ClassRoomComponent } from './components/classroom/classroom.component';

const routes: Routes = [
  { path: 'classroom', component: ClassRoomComponent, data: { title: 'Aula ao Vivo' } },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingRoutes { }
