<ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item">
        <a href="javascript:void(0);" [routerLink]="['/admin/home']">Home</a>
    </li>
    <li class="breadcrumb-item active">Relatório de aulas confirmadas'</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol>

<div class="row">
    <div class="col-md-12">
        <div id="panel-8" class="panel">
            <div class="panel-hdr">
                <h2>Filtro</h2>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="form-row">
                        <div class="col-12 mb-3">
                            <span role="status" aria-hidden="true" class="spinner-border spinner-border-sm pull-left mr-2 text-primary" *ngIf="flagSearchTeacher"></span>
                            <span class="text-primary pull-left" *ngIf="flagSearchTeacher">Carregando professores</span>
                            <label class="form-label" for="teacherId">Professor(a) <span class="text-danger">*</span> </label>
                            <div class="ng-autocomplete w-100">
                                <ng-autocomplete 
                                    [data]="teacherDataList"
                                    searchKeyword="name"
                                    placeholder="Selecione o teacher"
                                    (selected)='setTeacher($event)'
                                    [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>
                                
                                <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.name"></a>
                                </ng-template>
                                
                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-12 mb-3" *ngIf="teacherId > 0">
                            <hr>
                            <div class="alert alert-primary">
                                <div class="d-flex flex-start w-100">
                                    <div class="mr-2 hidden-md-down">
                                        <span class="icon-stack icon-stack-lg">
                                            <i class="base base-6 icon-stack-3x opacity-100 color-primary-500"></i>
                                            <i class="base base-10 icon-stack-2x opacity-100 color-primary-300 fa-flip-vertical"></i>
                                            <i class="ni ni-blog-read icon-stack-1x opacity-100 color-white"></i>
                                        </span>
                                    </div>
                                    <div class="d-flex flex-fill">
                                        <div class="flex-fill">
                                            <div class="col-12 mb-3">
                                                <span class="h5 mb-3">Dados do Teacher</span>
                                                <div class="form-row mb-3">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label text-muted" for="teacherName">Nome</label>
                                                            <input type="text" id="teacherName" class="form-control" disabled="" [value]="teacherData?.name">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label class="form-label text-muted" for="teacherEmail">E-mail</label>
                                                            <input type="text" id="teacherEmail" class="form-control" disabled="" [value]="teacherData?.email">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label class="form-label text-muted" for="teacherPhone">Telefone</label>
                                                            <input type="text" id="teacherPhone" class="form-control" disabled="" [value]="teacherData?.phone">
                                                        </div>
                                                    </div>
                                                </div>
                                                <span class="h5">Dados Bancários</span>
                                                <div class="form-row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label class="form-label text-muted" for="teacherAgency">Agência</label>
                                                            <input type="text" id="teacherAgency" class="form-control" disabled="" [value]="teacherData?.agency">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label class="form-label text-muted" for="teacherAccount">Conta</label>
                                                            <input type="text" id="teacherAccount" class="form-control" disabled="" [value]="teacherData?.account">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label class="form-label text-muted" for="teacherHourlyRate">Valor/Hora</label>
                                                            <input id="teacherHourlyRate" class="form-control" disabled="" [value]="teacherData?.formatHourlyRate">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <label class="form-label text-muted" for="teacherPix">Pix</label>
                                                            <input type="text" id="teacherPix" class="form-control" disabled="" [value]="teacherData?.pix">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12 mb-3">
                            <label class="form-label" for="courseId">Turma</label>
                            <div class="ng-autocomplete w-100">
                                <ng-autocomplete 
                                    [data]="courseDataList"
                                    searchKeyword="name"
                                    placeholder="Selecione a turma"
                                    (selected)='setCourse($event)'
                                    [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>
                                
                                <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.name"></a>
                                </ng-template>
                                
                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-4 mb-3">
                            <label class="form-label" for="startDate">Data inicial</label>
                            <input type="date" id="startDate" [(ngModel)]="startDate" class="form-control" placeholder="Data inicial" (change)="setStartDate($event)"/>
                        </div>
                        <div class="col-4 mb-3">
                            <label class="form-label" for="endDate">Data final</label>
                            <input type="date" id="endDate" [(ngModel)]="endDate" class="form-control" placeholder="Data final" (change)="setEndDate($event)"/>
                        </div>
                    </div>
                </div>
                <div class="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row">
                    <button class="btn btn-primary ml-auto" type="button" [disabled]="flagFiltering" (click)="getConfirmedClasses(null)">
                        <span *ngIf="flagFiltering" class="spinner-border spinner-border-sm mr-1"></span>
                        Filtrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div id="panel-8" class="panel">
            <div class="panel-container show">
                <div class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex">
                    <div class="col-md-12" *ngIf="!showTable">
                        <div class="alert alert-warning m-2" role="alert">{{returnMessage}}</div>
                    </div>
                    <div class="col-md-12 mt-3" *ngIf="showTable">
                        <h4 class="">
                            Consolidado por Teacher
                            <small>Abaixo um resumo de cada status e as aulas correspondentes</small>
                        </h4>
                        <div class="row">
                            <div class="col">
                                <div class="p-3 status-class-scheduled rounded overflow-hidden position-relative text-white mb-g"
                                    (click)="getConfirmedClasses(0)">
                                    <div class="">
                                        <h3 class="display-4 d-block l-h-n m-0 fw-500">
                                            {{ confirmedClassesSyntheticGroup?.countScheduled }}
                                            <small class="m-0 l-h-n">Agendadas</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="p-3 status-class-delayed rounded overflow-hidden position-relative text-white mb-g" style="cursor: pointer;"
                                    (click)="getConfirmedClasses(3)">
                                    <div class="">
                                        <h3 class="display-4 d-block l-h-n m-0 fw-500">
                                            {{ confirmedClassesSyntheticGroup?.countDelayed }}
                                            <small class="m-0 l-h-n">Atrasadas</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="p-3 status-class-changed-teacher rounded overflow-hidden position-relative text-white mb-g" style="cursor: pointer;"
                                    (click)="getConfirmedClasses(4)">
                                    <div class="">
                                        <h3 class="display-4 d-block l-h-n m-0 fw-500">
                                            {{ confirmedClassesSyntheticGroup?.countChangedTeacher }}
                                            <small class="m-0 l-h-n">Troca de Teacher</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="p-3 status-class-reschedule rounded overflow-hidden position-relative text-white mb-g" style="cursor: pointer;"
                                    (click)="getConfirmedClasses(5)">
                                    <div class="">
                                        <h3 class="display-4 d-block l-h-n m-0 fw-500">
                                            {{ confirmedClassesSyntheticGroup?.countReschedule }}
                                            <small class="m-0 l-h-n">Reagendadas</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="p-3 status-class-partial-confirmed rounded overflow-hidden position-relative text-white mb-g" style="cursor: pointer;"
                                    (click)="getConfirmedClasses(6)">
                                    <div class="">
                                        <h3 class="display-4 d-block l-h-n m-0 fw-500">
                                            {{ confirmedClassesSyntheticGroup?.countPartialConfirmed }}
                                            <small class="m-0 l-h-n">Parcial</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="p-3 status-class-unchecked rounded overflow-hidden position-relative text-white mb-g" style="cursor: pointer;"
                                (click)="getConfirmedClasses(7)">
                                    <div class="">
                                        <h3 class="display-4 d-block l-h-n m-0 fw-500">
                                            {{ confirmedClassesSyntheticGroup?.countUnchecked }}
                                            <small class="m-0 l-h-n">Desmarcadas</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="p-3 status-class-done rounded overflow-hidden position-relative text-white mb-g" style="cursor: pointer;"
                                    (click)="getConfirmedClasses(1)">
                                    <div class="">
                                        <h3 class="display-4 d-block l-h-n m-0 fw-500">
                                            {{ confirmedClassesSyntheticGroup?.countDone }}
                                            <small class="m-0 l-h-n">Concluídas (por turma)</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="p-3 status-class-done rounded overflow-hidden position-relative text-white mb-g">
                                    <div class="">
                                        <h3 class="display-4 d-block l-h-n m-0 fw-500">
                                            {{ confirmedClassesSyntheticGroup?.totalTimeDone }}h
                                            <small class="m-0 l-h-n">Tempo Aulas Concluídas</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="p-3 bg-success-600 rounded overflow-hidden position-relative text-white mb-g">
                                    <div class="">
                                        <h3 class="display-4 d-block l-h-n m-0 fw-500">
                                            {{ confirmedClassesSyntheticGroup?.totalAmountReceivable | currency : 'R$' }}
                                            <small class="m-0 l-h-n">Total a Receber ({{ confirmedClassesSyntheticGroup?.hourlyRate | currency : 'R$' }}/hora)</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <managed-table [dataTitle]="" [rows]="confirmedClassesSyntheticData" [columns]="columns"
                            [sorting]="false" [hasActions]="true" [ngTableFiltering]="false" [showFilterRow]="false"
                            [previewEnable]="true" (previewClick)="showInfoConfirmedClasses($event, mdInfoConfirmedClasses)"
                            [editEnable]="false" [deleteEnable]="false" [hasFilter]="false">
                        </managed-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <ng-template #mdInfoConfirmedClasses let-c="close" let-d="dismiss">
            <div class="modal-header bg-primary">
                <h5 class="modal-title">Detalhes do relatório</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="panel-container show">
                    <div class="panel-content">
                        <h4 class="mb-3">
                            Teacher da aula: 
                            <strong class="fw-700">{{previewSyntheticHeaderData?.teacherId}} | {{previewSyntheticHeaderData?.teacherName}}</strong>
                        </h4>
                        <h3>Turmas:</h3>
                        <div [innerHTML]="previewSyntheticHeaderData?.designCoursesHtml"></div>
                        <!-- <span class="badge badge-primary position-absolute pos-right d-none d-sm-block mr-4" 
                            [ngClass]="getCourseTypeCss(previewSyntheticData?.courseType)">
                            {{getCourseTypeStr(previewSyntheticData?.courseType)}}
                        </span>
                         -->
                        <div class="subheader mt-3">
                            <div class="subheader-block d-lg-flex align-items-center col-3 p-0">
                                <div class="d-inline-flex flex-column justify-content-center mr-3 w-100">
                                    <span class="fw-300 d-block text-muted h4 mb-0">Data da aula:</span>
                                    <span class='badge border border-primary text-primary w-100 fs-lg p-2'>
                                        <i class='fas fa-solid fa-calendar'></i> {{ previewSyntheticHeaderData?.expectedDate | date: 'dd/MM/yyyy' }}
                                    </span>
                                </div>
                            </div>
                            <div class="subheader-block d-lg-flex align-items-center col-3 p-0">
                                <div class="d-inline-flex flex-column justify-content-center mr-3 w-100">
                                    <span class="fw-300 d-block text-muted h4 mb-0">Início da aula:</span>
                                    <span class='badge border border-primary text-primary w-100 fs-lg p-2'>
                                        <i class='fas fa-solid fa-clock'></i> {{ previewSyntheticHeaderData?.timeStart }}
                                    </span>
                                </div>
                            </div>
                            <div class="subheader-block d-lg-flex align-items-center col-3 p-0">
                                <div class="d-inline-flex flex-column justify-content-center mr-3 w-100">
                                    <span class="fw-300 d-block text-muted h4 mb-0">Término da aula:</span>
                                    <span class='badge border border-primary text-primary w-100 fs-lg p-2'>
                                        <i class='fas fa-solid fa-clock'></i> {{ previewSyntheticHeaderData?.timeEnd }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="card border mb-2" *ngFor="let item of previewSyntheticData">
                            <div class="card-header">
                                <div class="panel-toolbar ml-2">
                                    <span class="badge badge-primary position-absolute pos-right d-none d-sm-block mr-4 mt-3" 
                                        [ngClass]="getCourseTypeCss(item?.courseType)">
                                        {{getCourseTypeStr(item?.courseType)}}
                                    </span>
                                    <span class="badge badge-danger position-absolute pos-right d-none d-sm-block mt-3"
                                        style="margin-right: 90px; color: white !important;" *ngIf="item?.classReplacement === true">
                                        Substituição
                                    </span>
                                </div>
                                Turma: <strong class="fw-700">{{item?.courseId}} | {{item?.courseName}}</strong>
                            </div>
                            <div class="card-body" *ngFor="let child of item?.studentClassesCourseConfirmedSyntheticList">
                                <h4 class="card-title">Nome: <strong class="fw-700">{{child?.student?.name}}</strong></h4>
                                <h5 class="card-title">E-mail: <strong class="fw-700">{{child?.student?.email}}</strong></h5>
                                <h5 class="card-title">Telefone: <strong class="fw-700">{{child?.student?.phone}}</strong></h5>
                                <div class="subheader">
                                    <div class="subheader-block d-lg-flex align-items-center">
                                        <div class="d-inline-flex flex-column justify-content-center mr-3">
                                            <span class="fw-300 fs-xs d-block text-muted">1ª parcela:</span>
                                            <span class="fw-500 fs-xl d-block color-info-500">{{child?.studentCourse?.installmentFirstDue | date: 'dd/MM/yyyy'}}</span>
                                        </div>
                                    </div>
                                    <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                        <div class="d-inline-flex flex-column justify-content-center mr-3">
                                            <span class="fw-300 fs-xs d-block text-muted">Demais vencimentos:</span>
                                            <span class="fw-500 fs-xl d-block color-info-500">{{child?.studentCourse?.dueDay}}</span>
                                        </div>
                                    </div>
                                    <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                        <div class="d-inline-flex flex-column justify-content-center mr-3">
                                            <span class="fw-300 fs-xs d-block text-muted">Início das aulas:</span>
                                            <span class="fw-500 fs-xl d-block color-primary-500">{{child?.studentCourse?.startDate | date: 'dd/MM/yyyy'}}</span>
                                        </div>
                                    </div>
                                    <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                        <div class="d-inline-flex flex-column justify-content-center mr-3">
                                            <span class="fw-300 fs-xs d-block text-muted">Término das aulas:</span>
                                            <span class="fw-500 fs-xl d-block color-primary-500">{{child?.studentCourse?.endDate | date: 'dd/MM/yyyy'}}</span>
                                        </div>
                                    </div>
                                    <div class="subheader-block d-lg-flex align-items-center border-faded border-right-0 border-top-0 border-bottom-0 ml-3 pl-3">
                                        <div class="d-inline-flex flex-column justify-content-center mr-3">
                                            <span class="fw-300 fs-xs d-block text-muted">Duração:</span>
                                            <span class="fw-500 fs-xl d-block color-primary-500">{{child?.studentCourse?.monthTotal}} meses</span>
                                        </div>
                                    </div>
                                </div>
                                <h5 class="card-title mb-2 text-center">Status:</h5>
                                <a href="javascript:void(0);" class="mt-1 d-block h4 fw-400 text-dark">
                                    <div [innerHtml]="designStatusClass(child?.studentClassesCourse?.statusClass)"></div>
                                </a>
                                <div *ngIf="child?.studentClassesCourse?.confirmedClassByStudent || child?.studentClassesCourse?.confirmedClassByTeacher">
                                    <span class="badge border border-status-class-partial-confirmed w-100" *ngIf="child?.studentClassesCourse?.confirmedClassByStudent">
                                        Aula confirmada pelo aluno
                                        <span class="badge bg-status-class-partial-confirmed ml-2">{{child?.studentClassesCourse?.confirmedDateByStudent | date:'medium'}}</span>
                                    </span>
                                    <span class="badge border border-warning badge-warning mt-2 pt-2 pb-1 w-100 text-danger fw-700" *ngIf="!child?.studentClassesCourse?.confirmedClassByStudent">
                                        ALUNO AINDA NÃO CONFIRMOU A AULA
                                    </span>

                                    <span class="badge border border-status-class-partial-confirmed mt-2 w-100" *ngIf="child?.studentClassesCourse?.confirmedClassByTeacher">
                                        Aula confirmada pelo professor
                                        <span class="badge bg-status-class-partial-confirmed ml-2">{{child?.studentClassesCourse?.confirmedDateByTeacher | date:'medium'}}</span>
                                    </span>
                                    <span class="badge border border-warning badge-warning mt-2 pt-2 pb-1 w-100 text-danger fw-700" *ngIf="!child?.studentClassesCourse?.confirmedClassByTeacher">
                                        PROFESSOR AINDA NÃO CONFIRMOU A AULA
                                    </span>
                                    <div class="alert border-faded bg-transparent text-secondary fade show mb-2 mt-2" role="alert"
                                        *ngIf="child?.studentClassesCourse?.confirmedClassByTeacher && child?.studentClassesCourse?.confirmedClassByTeacherStatusClassParams">
                                        <div class="d-flex align-items-center">
                                            <div class="alert-icon">
                                                <span class="icon-stack icon-stack-md">
                                                    <i class="base-7 icon-stack-3x color-success-600"></i>
                                                    <i class="fal fa-check icon-stack-1x text-white"></i>
                                                </span>
                                            </div>
                                            <div class="flex-1">
                                                <span class="h5 color-success-600">Confirmado com justificativa!!</span>
                                                <br>
                                                <strong class="fw-700">{{getStatusClassParamsStr(child?.studentClassesCourse?.confirmedClassByTeacherStatusClassParams)}}</strong>
                                                <br>
                                                Info Adicional: {{child?.studentClassesCourse?.confirmedClassByTeacherAddInfo}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal" (click)="dismissModal()">Fechar</button>
            </div>
        </ng-template>
    </div>
</div>
