import { ContactBySiteService } from '@app/marketing/services/contactBySite.service';
import { MessageService } from '@app/shared/services';
import { AccountService } from '@app/auth/services';
import { TypeResponse } from '@app/shared/enum';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/auth/model';

@Component({
  selector: 'menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.css']
})
export class MenuAdminComponent implements OnInit {
  public user: User = new User();
  private userRoles: Array<any>;
  public sdr_count: number = 0;
  public awaiting_count: number = 0;
  closeResult: string;
  
  constructor(
    private router: Router,
    private loading: MessageService,
    private contactBySiteService: ContactBySiteService,
    private accountService: AccountService) {
      this.loadUserData();
  }

  ngOnInit(): void {
    this.countStatus();
  }

  private loadUserData() {
    this.loading.showLoading();
    this.user = this.accountService.getUserData();
    this.userRoles = this.accountService.getRolesByUser();
    this.loading.hideLoading();
  }

  menuClick(url: any): void {
    if (url) {
      this.router.navigate([url]);
    }
    return;
  }

  countStatus() {
    this.contactBySiteService.countByStatus().subscribe((response: any) => {
      if (response.typeResponse == TypeResponse.Success) {
        this.sdr_count = response.data.sdr;
        this.awaiting_count = response.data.awaiting;
      } else {
        this.sdr_count = 0;
      }
    }, (error: any) => {
      console.log(error);
      this.sdr_count = 0;
    });
  }

  checkHavePermission(role: any): boolean {
    if (role && this.userRoles != null && this.userRoles.length > 0) {
      return this.userRoles.some(x => x.keyDefault == role);
    } else {
      return false;
    }
  }
}
