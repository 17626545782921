import { RecurrenceDay } from '@app/shared/enum';
import { Base } from '@app/shared/model';
import { Teacher } from '.';

export class TeacherAvailability extends Base {
    time: Date;
    dayOfWeek: RecurrenceDay;

    teacherId: Number;
    teacher: Teacher;

    constructor(contentResponse: any, fixDate: boolean = false) {
        super(contentResponse);

        this.time = fixDate ? contentResponse.time
            : this.getTimeByString(contentResponse.time);

        this.dayOfWeek = parseInt(contentResponse.dayOfWeek),

        this.teacherId = parseInt(contentResponse.teacherId ? contentResponse.teacherId : 0);
        if (contentResponse && contentResponse.teacherId > 0) {
            this.teacher = contentResponse.teacher;
        }
    }

    getTimeByString(strTime: any) {
        if (strTime != null && strTime.length > 0) {
            let dateTime = new Date();
            dateTime.setMilliseconds(0);
            dateTime.setSeconds(0);
            dateTime.setMinutes(strTime.split(':')[1]);
            dateTime.setHours(strTime.split(':')[0]);
            return dateTime;
        }
    }
}
