<ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item">
    <a href="javascript:void(0);">English Coffee</a>
  </li>
  <li class="breadcrumb-item active">Home</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol>
<div class="d-md-none">
  <div class="subheader mb-2">
    <h1 class="subheader-title">
      <i class="subheader-icon fal fa-home"></i>
      <span class="fw-300">Welcome to</span> English Coffee
    </h1>
  </div>
  <div class="subheader mb-2">
    <h1>
      <small class="mb-2"> Portal de conteúdos e gestão do professor </small>
    </h1>
  </div>
</div>

<div class="d-none d-md-block">
  <div class="subheader">
    <h1 class="subheader-title">
      <i class="subheader-icon fal fa-home"></i>
      <span class="fw-300">Welcome to</span> English Coffee
      <small> Portal de conteúdos e gestão do professor </small>
    </h1>
  </div>
</div>

<div class="row" *ngIf="checkHavePermission('PRO2WT7AL35')">
  <div class="col-12">
    <div id="panel-1" class="panel">
      <div class="panel-hdr bg-primary-600 bg-primary-gradient">
        <h2>Revisão de testes de escrita do professor</h2>
      </div>
      <div class="panel-container show">
        <div
          class="panel-content py-2 rounded-bottom border-faded border-left-0 border-right-0 border-bottom-0 text-muted d-flex"
        >
          <div class="col-sm-12" *ngIf="!showTableWritingTestPending">
            <div class="alert alert-warning m-2" role="alert">
              {{ errorWritingTestPending }}
            </div>
          </div>
          <div class="col-sm-12" *ngIf="showTableWritingTestPending">
            <managed-table
              [dataTitle]=""
              [rows]="gridWritingTestPending$ | async"
              [columns]="columns"
              [sorting]="false"
              [hasActions]="true"
              [ngTableFiltering]="false"
              [showFilterRow]="false"
              [previewEnable]="false"
              [editEnable]="false"
              [deleteEnable]="true"
              (deleteClick)="deleteWritingTest($event)"
              [hasFilter]="false"
              [pageSize]="3"
              [maxSize]="10"
              [auxEnable]="true"
              [auxIcon]="auxIcon"
              [titleAux]="auxTitle"
              (auxClick)="writingTestReview($event, mdWritingTestAppliedReview)"
              [auxEnable2]="true"
              [auxIcon2]="auxIcon2"
              [titleAux2]="auxTitle2"
              (auxClick2)="writingTestReset($event)"
            >
            </managed-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #mdWritingTestAppliedReview let-c="close" let-d="dismiss">
  <writing-test-applied-review
    writingTestApplyId="{{ writingTestApplyId }}"
    previewMode="{{ writingTestApplyView }}"
  ></writing-test-applied-review>
</ng-template>

<div class="row">
  <div class="col-12">
    <div class="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
      <h1 class="font-weight-bold mb-3">GUIA DO PROFESSOR ENGLISH COFFEE</h1>
      <br />
      <ul class="list-unstyled">
        <li class="mb-3">
          <p class="lead fw-700">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Quando houver problemas técnicos por parte do professor, não deve
            ser passado detalhes ao aluno, apenas dizendo: “devido a problemas
            técnicos, será necessário e o reagendamento...”, e proceder com o
            reagendamento da aula. Comunicando o aluno sempre pelo chat da
            plataforma.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Em caso do professor não conseguir lecionar sua aula regular em
            algum dia específico, é de total responsabilidade do mesmo, avisar
            com antecedência, procurar a Central de Atendimento e informar que
            precisa de um professor substituto para aquela aula (19-41071329
            WhatsApp Central de Atendimento).
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Em aula experimental, o professor deve esperar até 10 minutos o
            aluno, caso o mesmo não compareça, deve ser colocado 0,16 no
            controle de aula.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Devemos sempre lembrar da existência do TERMO DE SIGILO DO MÉTODO
            ENGLISH COFFEE, não sendo permitido a transferência de “ KNOW HOW ”
            e ensino fora da instituição.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Todo Professor English Coffee, é necessário fazer todo o passo a
            passo do método, em todas as aulas, não podendo sair do raciocínio
            do mesmo.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead fw-700">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            O Professor English Coffee, não indica NADA como apoio de
            aprendizagem fora do método. Pois acreditamos 100% na aprendizagem
            rápida através do método English Coffee.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            O Professor English Coffee, precisa sempre ter em mente que, todo
            aluno English Coffee fazendo todo o passo a passo do método, absorve
            100% do esperado. Caso isso não ocorra, é porque infelizmente o
            aluno não está seguindo todas as recomendações pelo método.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Toda aula é necessário ser iniciada pontualmente por parte do
            professor. Caso o aluno atrase, a aula será encerrada no horário
            esperado. Em último caso se o professor atrasar, é necessário
            encerrar a mesma completando o tempo de 100% da aula (60 minutos).
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Toda aula desmarcada e remarcada pelo aluno é através do evento do
            aluno na plataforma, mas se necessitar de algum contato via
            professor e aluno, o assunto deve ser tratado dentro da plataforma,
            para constar o histórico (não sendo permitido a comunicação através
            de outros meios). Inclusive, toda comunicação deve ser feito no chat
            da plataforma.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            O WRITING TEST sempre deve ser aplicado na primeira semana de cada
            mês. É de responsabilidade do professor liberar o teste ao aluno e
            aplicar. Se houver necessidade, há na FAQ como executar o mesmo. E
            deve ser feita a correção, referente aos seus respectivos alunos.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            As aulas (VIP) de reposição de cada aluno, precisa ser feitas dentro
            do mesmo mês, sendo necessário o acordo da data e hora entre aluno e
            professor. Não será permitido reposição de aulas fora do período e
            lembrando que não fazemos reposição da reposição.
            <br />
            Se o aluno estiver matriculado no pacote Semi VIP, ele fará a
            reposição de acordo com o alinhamento do ADM da English Coffee, se
            não for possível, a aula contabilizará para o final do treinamento.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Toda aula de reposição agendada não pode ser reagendada mais vezes,
            se houver um agendamento de reposição e o aluno avisar que não
            comparecerá naquela aula (mesmo com antecedência de 3 horas): o
            aluno não terá direito de reposição e o professor contabilizará essa
            aula, pois estava prevista.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Todo professor tem por responsabilidade o acompanhamento do
            resultado do seu respectivo aluno. Caso o mesmo não esteja na
            proporção esperada, ou com ausência acima de 2 aulas consecutivas, é
            necessário sinalizar o ADM English Coffee (Central de Atendimento)
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Todo professor deve ter a preocupação do ambiente padrão, que é
            transmitido através de sua câmera (fundo parede padrão) e o cuidado
            de não ter barulho. Sendo assim, transmitiremos total
            profissionalismo.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Qualquer situação ocorrido fora do esperado em aula, informar a
            Central da English Coffee.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Após toda aula experimental, enviar o feedback padrão para a English
            Coffee. Sendo assim, daremos continuidade passando demais
            informações.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            É necessário que todos os professores participem das reuniões de
            alinhamento e reciclagem do método que fazemos durante ao ano.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Quando aplicamos o teste escrito, e o mesmo atinge os 20 minutos
            (primeira parte) e não chega ao fim do teste, encerramos e partimos
            para a próxima parte da aula, nesse caso não aplicamos o conteúdo
            completo do arquivo. Lembrando que posicionamos dessa maneira,
            porque os parágrafos entre eles, não possuem conexão e precisamos
            apenas de alguns parágrafos para validação e análise do aluno.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            De tempos em tempos, o professor precisa relembrar o aluno da
            necessidade de verificação dos testes escritos.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Toda aula deve ser iniciada pontualmente no horário agendado, sendo
            necessário enviar uma mensagem como: Good morning, how are you doing
            (exemplo) e aguardar. Após 10 minutos, se o aluno não acessar a
            chamada, enviar via chat da plataforma uma mensagem e o link da
            aula. Além de dizer que está no aguardo do aluno. Caso não haja
            resposta, Repetir o procedimento após 20 minutos e 25 minutos do
            início da aula.
            <br />
            Aguardar os 30 minutos da aula, posteriormente, se ninguém acessar a
            sala, seguir o protocolo de aula dada.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Toda alteração de aula regular da recorrência (solicitado pelo
            aluno), o professor deve solicitar a alteração na central de
            atendimento.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Não é permitido o professor requerer do aluno informações sobre
            valores referente ao treinamento English Coffee.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Não é permitido o professor e o aluno negociarem a saída
            (descontinuação do contrato da English Coffee) para obter aula
            particular (com o professor de forma externa).
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Não é permitido, em nenhuma hipótese, o professor passar número de
            celular, e-mail particular e outros. Se acaso houver dúvida enviar
            via central de atendimento ou chat da plataforma. Sendo o único meio
            de contato entre aluno e professor English Coffee.
          </p>
        </li>
        <li class="mb-3">
          <p class="lead">
            <i class="fal fa-check text-primary font-weight-bold mr-2"></i>
            Solicitamos que 2x ou 3x no mês tirar fotos dos alunos x teacher e
            mandar para a Central de atendimento.
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
