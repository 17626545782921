import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DepartmentService } from '@app/admin/services';
import { InitAdminService } from '@app/admin/services/init-admin.service';
import { TypeResponse } from '@app/shared/enum/TypeResponse';
import { MessageService } from '@app/shared/services';

@Component({ templateUrl: 'department-list.component.html', providers: [DatePipe] })
export class DepartmentListComponent implements OnInit {

  public departments: any = [];
  public showTable: boolean;
  public dateNow = new Date();
  dataResult: boolean = false;
  public returnMessage = '';

  public columns: Array<any> = [
    { title: "Código", name: "id", sort: "asc", className: "text-center" },
    { title: "Nome", name: "name", sort: "asc" },
    {
      title: "Criado em",
      name: "createdOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    },
    {
      title: "Modificado em",
      name: "updatedOn",
      sort: "asc",
      className: "text-center text-primary datetime"
    }
  ];
  
  constructor(
    private initAdmin: InitAdminService,
    private departmentService: DepartmentService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private loading: MessageService
  ) { }

  ngOnInit(): void {
    this.initAdmin.setConfigPage();
    this.dataResult = false;
    this.loadDepartments();
  }

  loadDepartments() {
    this.departmentService.getall()
    .pipe(first())
    .subscribe(response => {
      if (response.typeResponse == TypeResponse.Success) {
        this.departments = response.data;
        this.showTable = true;
        this.returnMessage = '';
      } else {
        this.returnMessage = response.message;
        this.showTable = false;
      }
    },
    error => {
      this.showTable = false;
      this.returnMessage = 'Erro ao carregar os dados dos departamentos.';
    });
  }

  edit(row:any): void {
    this.loading.showLoading();
    this.router.navigate(['/admin/department', { id: row.id }]);
  }
}
