export class AddCourseRecurrence {
    timeStart: string;
    timeEnd: string;
    duration: string;
    dayOfWeek: number;
    teacherId: number;

    constructor(contentResponse: any, fixDate: boolean = false) {
        this.timeStart = fixDate ? contentResponse.timeStart.toLocaleTimeString()
            : this.getTimeByString(contentResponse.timeStart);
        this.timeEnd = fixDate ? contentResponse.timeEnd.toLocaleTimeString()
            : this.getTimeByString(contentResponse.timeEnd);
        this.duration = fixDate ? contentResponse.duration.toLocaleTimeString()
            : this.getTimeByString(contentResponse.duration);
        //this.timeStart = contentResponse.timeStart;
        //this.timeEnd = contentResponse.timeEnd;
        //this.duration = contentResponse.duration;
        this.dayOfWeek = parseInt(contentResponse.dayOfWeek);
        this.teacherId = parseInt(contentResponse.teacherId);
    }

    getTimeByString(strTime: any) {
        if (strTime != null && strTime.length > 0) {
            let dateTime = new Date();
            dateTime.setMilliseconds(0);
            dateTime.setSeconds(0);
            dateTime.setMinutes(strTime.split(':')[1]);
            dateTime.setHours(strTime.split(':')[0]);
            return dateTime;
        }
    }
}